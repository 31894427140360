import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { IAppState } from "model/IAppState";
import { useDispatch, useSelector } from 'react-redux';
import * as vehicleModel from 'model/IVehicle';
import * as rdHelper from 'helpers/Uri';
import * as rdEnum from 'helpers/enum';
import { filter, includes, find, isNil } from 'lodash';

const DriveawayPriceDialog = (props: any) => {

    const { source } = props

    const [isOpen, setIsOpen] = useState(false);

    const [driveAwayCost, setDriveAwayCost] = useState<any>(null);

    const handleDialogOpen = () => {
        setIsOpen(true);
        document.body.style.overflowY = 'hidden';
        document.body.style.paddingRight = '15px';
    }

    const onHide = () => {
        setIsOpen(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    };

    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const driveawayCostFromStore = useSelector((state: IAppState) => {
        return state.paymentOptions.DriveAwayCost;
    });
    const taxFromStore = useSelector((state: IAppState) => {
        return state.paymentOptions.Finance;
    });

    const optionsTemplate = (options: vehicleModel.IVehicleOptions[] | null | undefined, optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(options) && options != undefined && options.length > 0) {
            let selectedOptions = options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions.map((optionItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='details-desc' key={optionItem + "_" + index}>
                            <p>{optionItem?.OptionName}</p>
                            <span>{rdHelper.currencyFormatWithIncluded(optionItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<></>);
        }
        else {
            template.push(
                <div className='driveaway-price-card'>
                    <div className='details-subtitle'>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</div>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    return (
        <>
            {
                source == 'order-review'
                    ?
                    <div className='driveaway-price-box stat-text-link'
                        data-dtm="checkout:order review"
                        onClick={() => {
                            handleDialogOpen();
                        }}>
                        <span>View Your LYRIQ Summary</span>
                        <img alt='' src={`${getCMSAssets()}/main/arrow-black.svg`} />
                    </div>
                    :
                    <div className='driveaway-price-box'
                        onClick={() => {
                            handleDialogOpen();
                        }}>
                        <h6 className='driveaway-price'>Driveaway Price<sup>1</sup></h6>
                    </div>
            }

            <Dialog className='driveaway-price-dialog' position='bottom' visible={isOpen} draggable={false} resizable={true} onHide={() => onHide()}>
                <div className='title-panel'>
                    <h1>YOUR LYRIQ SUMMARY</h1>
                    <h6>Your Drive Away Price is calculated based on your selected Registration Type in metropolitan areas in your selected State / Territory</h6>
                </div>

                <div className='driveaway-price-container'>
                    <div className='driveaway-price-panel'>
                        <div className='driveaway-price-card'>
                            <span className='card-title'>Variant</span>
                            <div className='driveaway-price-row-item'>
                                <span>{driveawayCostFromStore?.VariantName}</span>
                                <span className='price'>{rdHelper.currencyFormatWithIncluded(driveawayCostFromStore?.VariantPrice || 0)}</span>
                            </div>
                        </div>

                        <div className='driveaway-price-card'>
                            <span className='card-title'>Exterior</span>
                            <div className='driveaway-price-row-item'>
                                <span>{driveawayCostFromStore?.ExteriorName}</span>
                                <span className='price'>{rdHelper.currencyFormatWithIncluded(driveawayCostFromStore?.ExteriorPrice || 0)}</span>
                            </div>
                        </div>

                        <div className='driveaway-price-card'>
                            <span className='card-title'>Interior</span>
                            <div className='driveaway-price-row-item'>
                                <span>{driveawayCostFromStore?.InteriorName}</span>
                                <span className='price'>{rdHelper.currencyFormatWithIncluded(driveawayCostFromStore?.InteriorPrice || 0)}</span>
                            </div>
                        </div>
                        {optionsTemplate(selectVehFromStore?.Options, rdEnum.enumVehicleOptionType.Accessories)}
                        {optionsTemplate(selectVehFromStore?.Options, rdEnum.enumVehicleOptionType.Charge)}

                        <div className='driveaway-price-card'>
                            <div className='driveaway-price-row-item bold'>
                                <span className='special-title'>Vehicle Subtotal</span>
                                <span className='total-price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.SubTotal || 0)}</span>
                            </div>
                            <span className='card-title'>Includes {rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.GST || 0)}</span>
                        </div>
                    </div>

                    <div className='driveaway-price-panel'>
                        {selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia && (
                            <React.Fragment>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Luxury Car Tax</span>
                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LuxuryCarTax || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local Stamp Duty</span>
                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LocalStampDuty || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local Registration Costs</span>
                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LocalRegistrationCost || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local CTP</span>
                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LocalCTP || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local Plate Fee</span>
                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LocalPlateFee || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra bold'>
                                    <span className='special-title'>On Road Fees Subtotal</span>
                                    <span className='special-price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.OnRoadFee || 0)}</span>
                                </div>
                            </React.Fragment>
                        )}

                        {selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand && (
                            <React.Fragment>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local Registration Costs</span>
                                    <span>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.LocalRegistrationCost || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Local Plate Fee</span>
                                    <span>{rdHelper.currencyFormatWithIncluded(driveawayCostFromStore?.LocalPlateFee || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Tyre Stewardship Fee</span>
                                    <span>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.TyreStewardshipFee || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra'>
                                    <span>Road User Charges</span>
                                    <span>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.RoadUserChargers || 0)}</span>
                                </div>
                                <div className='driveaway-price-row-item extra bold'>
                                    <span>On Road Fees Subtotal</span>
                                    <span>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.OnRoadFee || 0)}</span>
                                </div>
                            </React.Fragment>
                            )}
                    </div>

                    <div className='driveaway-price-card' style={{borderBottom: '1px solid #ddd', paddingBottom: '1rem'}}>
                        <span className='card-title'>Delivery option</span>
                        <div className='driveaway-price-row-item'>
                            <span>{selectedLocationFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup}</span>
                            {selectedLocationFromStore?.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery && (
                                <span className='price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.HomeDelivery || 0)}</span>
                            )}
                        </div>
                    </div>

                    <div className='driveaway-price-panel'>
                        <div className='driveaway-price-row-item extra bold'>
                            <span className='special-title'>Driveaway Price<sup>1</sup></span>
                            <span className='special-price'>{rdHelper.currencyFormatWithBlank(driveawayCostFromStore?.DriveawayPrice || 0)}</span>
                        </div>
                    </div>

                    <div className='disclaimer-container'>
                        <p>
                            <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. The drive away price displayed is inclusive of the vehicle purchase price, goods and services tax (GST), luxury car tax (LCT), stamp duty, compulsory third party insurance, registration, a plate fees and a GST-inclusive regional delivery fee if applicable. The final drive away price may differ depending on your individual circumstances. The price does not take into account any valid discount, rebate or promotion that may be available from time to time. Each country, state or territory may offer additional registration types which are not covered in this configurator, therefore your individual situation may vary and have an impact on the final Drive Away price. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. The final Drive Away price will be provided to you upon checkout.
                        </p>
                    </div>
                   
                </div>


            </Dialog>
        </>


    )
}

export default DriveawayPriceDialog