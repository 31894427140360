import { MainLayoutPage } from 'LayoutPages';
import CarCarousel from 'components/CarCarousel';
import PurchasingProgress from 'components/PurchasingProgress';
import { getCMSAssets, getFrontendUrl, getSelectState } from 'helpers/Uri';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionContext, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import Support from './Support';
import Documents from './Documents';
import ProgressSteps from './purchasingProgress/ProgressSteps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isNil } from 'lodash';
import moment from 'moment';

import { IAppState } from 'model/IAppState';
import * as rdEnum from 'helpers/enum';
import * as rdHelper from 'helpers/Uri';
import * as orderService from 'services/Order';
import * as orderModel from 'model/IOrder';
import * as vehicleModel from 'model/IVehicle';
import * as pipelineModel from 'model/IPipeLine';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as orderCustomerDetailsModel from 'model/IOrderCustomerDetails';
import * as paymentOptionsModel from 'model/IPaymentOptions';
import * as checkoutHelper from 'businessHelpers/CheckoutHelper';
import * as addressHelper from 'businessHelpers/AddressHelper';
import * as driveLicenseHelper from 'businessHelpers/DriverLicenseHelper';
import * as orderCustomerDetailsStore from 'stores/OrderCustomerDetails';
import * as orderDetailsStore from 'stores/OrderDetails';
import * as orderScheduleDeliveryStore from 'stores/OrderScheduleDelivery';
import * as sessionStore from 'stores/Session';
import * as orderDetailPageVersionStore from 'stores/OrderDetailPageDataVersion';
import * as docModel from 'model/IDocuments';
import * as docService from 'services/Document';

import ViewAppointment from 'pages/Global/orders/purchasingProgress/ViewAppointment';

import { useMsal } from '@azure/msal-react';
import { useAzureB2C } from 'helpers/useAzureB2C';
import { ICodeName } from 'model/ICommon';
import { useCountryState } from 'helpers/useCountryState';
import Spinner from 'components/Spinner';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { Dialog } from 'primereact/dialog';

type AccordionOpenType = {
    btn0: boolean;
    btn1: boolean;
    btn2: boolean;
    btn3: boolean;
    btn4: boolean;
    btn5: boolean;
    btn6: boolean;
};


const OrderDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const azureB2C = useAzureB2C();
    const { inProgress } = useMsal();
    //const countryState = useCountryState();

    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });

    const orderDetailPageVersionFromStore = useSelector((state: IAppState) => {
        return state.orderDetailDataVersion;
    });
    const orderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderCustomerDetails;
    });

    const isMobile = useCheckMobileScreen();

    const { yanaID, yanaNo } = useParams();

    
    const [stepsList, setStepsList] = useState<pipelineModel.IPipeLineItem[]>([]);
    const [isCancelledOrder, setIsCancelledOrder] = useState(false);
    const [ifShowViewAppointmentButton, setIfShowViewAppointmentButton] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(rdEnum.enumProcessingStatus.Processing);

    const [order, setOrder] = useState<orderModel.IOrderRequest>(checkoutHelper.getOrderInitState());
    const [paymentSummaryLink, setPaymentSummaryLink] = useState("");

    const lastStepCollapseHeightRef = useRef<any>();

    const [lastStepCollapseHeight, setLastStepCollapseHeight] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const [accordionOpen, setAccordionOpen] = useState<AccordionOpenType>(
        {
            btn0: false,
            btn1: false,
            btn2: false,
            btn3: false,
            btn4: false,
            btn5: false,
            btn6: false,
        }
    );

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const onHide = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (accordionOpen.btn6 && lastStepCollapseHeightRef.current) {
            const height = lastStepCollapseHeightRef.current?.offsetHeight;
            setLastStepCollapseHeight(height);
        }
    }, [accordionOpen.btn6]);

    useEffect(() => {
        azureB2C.isAuthAndLogin(inProgress);
    }, [inProgress]);

    useEffect(() => {
        if (!isNil(yanaID) && yanaID != "" && !isNil(yanaNo) && yanaNo != "") {
            dispatch(orderDetailPageVersionStore.clearOrderDetailPageDataVersionAction());
        }
    }, [yanaID]);


    //refresh data if update customer details, pipeline data changed
    useEffect(() => {
        doGetOrder();
    }, [orderDetailPageVersionFromStore]);



    const doGetOrder = async () => {
        setLoadingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.getOrderDetailsByYanaIDs(
            sessionFromStore?.YanaCustomerID || "",
            sessionFromStore?.YanaCustomerNo || "",
            atob(yanaID), atob(yanaNo)
        );
        setLoadingStatus(rdEnum.enumProcessingStatus.Processed);

        if (response.status) {
            let orderData = response.data;


            let driveawayCostData = orderData.driveAwayCost;
            let driveawayCost: paymentOptionsModel.IDriveAwayCost = {
                LuxuryCarTax: driveawayCostData?.luxuryCarTax,
                LocalStampDuty: driveawayCostData?.localStampDuty,
                LocalRegistrationCost: driveawayCostData?.localRegistrationCost,
                LocalCTP: driveawayCostData?.localCTP,
                LocalPlateFee: driveawayCostData?.localPlateFee,
                HomeDelivery: driveawayCostData?.homeDelivery,
                DriveawayPrice: driveawayCostData.driveawayPrice,
                OnRoadFee: driveawayCostData.onRoadFeeSubtotal,
                SubTotal: driveawayCostData.subTotal,
                VariantName: "",
                VariantID: "",
                VariantPrice: 0,
                ExteriorID: "",
                ExteriorName: "",
                ExteriorPrice: 0,
                InteriorID: "",
                InteriorName: "",
                InteriorPrice: 0,
                GST: driveawayCostData.gst,
                RoadUserChargers: driveawayCostData.roadUserChargers,
                TyreStewardshipFee: driveawayCostData.tyreStewardshipFee,
                Options: [],
            }


            let tempOrder: orderModel.IOrderRequest = {
                ...order,
                Country: orderData.orderVehicle.country,
                State: orderData.orderVehicle.state,
                OrderID: orderData.orderID,
                RDAcountID: orderData.rdAccountID,
                VariantName: orderData.orderVehicle.variantName,
                VariantPrice: orderData.orderVehicle.variantPrice,
                ExteriorName: orderData.orderVehicle.exteriorName,
                ExteriorPrice: orderData.orderVehicle.exteriorPrice,
                InteriorName: orderData.orderVehicle.interiorName,
                InteriorPrice: orderData.orderVehicle.interiorPrice,
                VehicleImages: orderData.orderVehicle.variantPicture,
                Options: getOptions(orderData.orderVehicle.options),
                DeliveryLocationState: orderData.delivery.deliveryLocationState,
                DeliveryLocationSuburb: orderData.delivery.deliveryLocationSuburb,
                DeliveryLocationPostcode: orderData.delivery.deliveryLocationPostcode,
                DeliveryLocationAddress: orderData.delivery.deliveryLocationAddress,
                DeliveryOption: orderData.delivery.deliveryOption,
                PickupLocationName: orderData.delivery.pickupLocationName,
                Available: orderData.delivery.available,
                PaymentOption: orderData.paymentOption,
                DriveawayCost: driveawayCost,
                Email: orderData.orderCustomer.email,
                FirstName: orderData.orderCustomer.firstName,
                LastName: orderData.orderCustomer.lastName,
                Mobile: orderData.orderCustomer.mobile,
                ABN: orderData.orderCustomer.abn,
                BusinessName: orderData.orderCustomer.businessName,
                CustomerType: orderData.orderCustomer.customerType,
                BillingAddress: orderData.orderCustomer.billingAddress,
                BillingSuburb: orderData.orderCustomer.billingSuburb,
                BillingPostcode: orderData.orderCustomer.billingPostCode,
                BillingState: orderData.orderCustomer.billingState,
                RegoAddress: orderData.orderCustomer.regoAddress,
                RegoPostcode: orderData.orderCustomer.regoPostCode,
                RegoSuburb: orderData.orderCustomer.regoSuburb,
                RegoState: orderData.orderCustomer.regoState,
                YanaOrderID: orderData.yanaOrderIDs.yanaOrderID,
                YanaOrderNo: orderData.yanaOrderIDs.yanaOrderNo,
                YanaCustomerID: orderData.yanaOrderIDs.yanaCustomerID,
                YanaCustomerNo: orderData.yanaOrderIDs.yanaCustomerNo,
                PaymentHistory: getPaymentHistory(orderData.orderPaymentHistory),
                DownPayment: orderData.downPayment,
                PaymentDueDate: orderData.paymentDueDate,
                FinalPaymentAmount: orderData.finalPaymentAmount,
                Total: orderData.orderVehicle.totalPrice,
                Balance: orderData.currentBalance,
            };
            setOrder(tempOrder);
            dispatch(orderDetailsStore.setOrderDetailsAction(tempOrder));

            let pipelines: pipelineModel.IPipeLineItem[] = [];
            let pipeLineData = orderData.pipeLines;
            let isCancelled = false;
            let showViewAppointmentButton = false;
            if (!isNil(pipeLineData) && pipeLineData.length > 0) {
                pipeLineData.map((item: any) => {
                    if (item.pipelineName.includes("Cancel")) {
                        isCancelled = true;
                    }

                    //show view appointment button if pick-up date&time selection is done
                    if (item.pipelineNumber == "A") {
                        if (item.pipelineBarStepNumber == 5 && item.pipelineBarStepStatus == 1) {
                            showViewAppointmentButton = true;
                        }
                    }
                    else if (item.pipelineNumber == "B") {
                        if (item.pipelineBarStepNumber == 6 && item.pipelineBarStepStatus == 1) {
                            showViewAppointmentButton = true;
                        }
                    }
                    else if (item.pipelineNumber == "C") {
                        if (item.pipelineBarStepNumber == 7 && item.pipelineBarStepStatus == 1) {
                            showViewAppointmentButton = true;
                        }
                    }
                    else if (item.pipelineNumber == "D") {
                        if (item.pipelineBarStepNumber == 8 && item.pipelineBarStepStatus == 1) {
                            showViewAppointmentButton = true;
                        }
                    }

                    pipelines.push({
                        stepNo: item.pipelineNumber,
                        step: item.pipelineBarStepNumber,
                        name: item.pipelineName,
                        desc: item.pipelineDesc,
                        date: item.completeDate,
                        pipelineBarStepStatus: item.pipelineBarStepStatus,
                        buttonID: item.buttonID,
                        buttonText: item.buttonText,
                    });
                });
            }
            setStepsList(pipelines);
            setIsCancelledOrder(isCancelled);
            setIfShowViewAppointmentButton(showViewAppointmentButton);

            //let stateList = rdEnum.constStateList; // await countryState.getStates(tempOrder.Country);

            //let billingState = getSelectState(stateList, orderData.orderCustomer.billingState);
            //console.log(billingState);

            let orderCustomerDetails: orderCustomerDetailsModel.IOrderCustomerDetails = {
                country: orderData.orderVehicle.country,
                birthday: orderData.orderCustomer.birthDay,
                //birthdayFormat: orderData.orderCustomer.birthDay,
                driverLicense: {
                    driverLicenseNo: orderData.orderCustomer.driveLicenseNumber,
                    driverLicenseExpiryDate: orderData.orderCustomer.driveLicenseExpDate
                    //driverLicenseExpiryDate: !isNil(orderData.orderCustomer.driveLicenseExpDate)
                    //    ? moment(orderData.orderCustomer.driveLicenseExpDate).toDate() : undefined,
                    // driverLicenseExpiryDateFormat: orderData.orderCustomer.driveLicenseExpDate
                },
                customerType: orderData.orderCustomer.customerType,
                firstname: orderData.orderCustomer.firstName,
                middlename: orderData.orderCustomer.middleName,
                lastname: orderData.orderCustomer.lastName,
                email: orderData.orderCustomer.email,
                phone: orderData.orderCustomer.mobile,
                secondaryPhone: "",
                abn: orderData.orderCustomer.abn,
                businessName: orderData.orderCustomer.businessName,
                billingAddress: {
                    Address1: orderData.orderCustomer.billingAddress,
                    Address2: "",
                    Postcode: orderData.orderCustomer.billingPostCode,
                    Suburb: orderData.orderCustomer.billingSuburb,
                    State: { code: orderData.orderCustomer.billingState, name: orderData.orderCustomer.billingState }
                },
                regoAddress: {
                    Address1: orderData.orderCustomer.regoAddress,
                    Address2: "",
                    Postcode: orderData.orderCustomer.regoPostCode,
                    Suburb: orderData.orderCustomer.regoSuburb,
                    State: { code: orderData.orderCustomer.regoState, name: orderData.orderCustomer.regoState }
                },
                deliveryAddress: {
                    Address1: orderData.delivery.deliveryLocationAddress,
                    Address2: "",
                    Postcode: orderData.delivery.deliveryLocationPostcode,
                    Suburb: orderData.delivery.deliveryLocationSuburb,
                    State: { code: orderData.delivery.deliveryLocationState, name: orderData.delivery.deliveryLocationState }
                },
                paymentOption: orderData.paymentOption,
                yanaOrderID: orderData.yanaOrderIDs.yanaOrderID,
                yanaOrderNo: orderData.yanaOrderIDs.yanaOrderNo,
                yanaCustomerID: orderData.yanaOrderIDs.yanaCustomerID,
                yanaCustomerNo: orderData.yanaOrderIDs.yanaCustomerNo,
                actionType: ""
            };
            dispatch(orderCustomerDetailsStore.setOrderCustomerDetailsAction(orderCustomerDetails));


            let scheduleDelivery: scheduleDeliveryModel.IOrderScheduleDelivery = {
                YanaOrderID: orderData.yanaOrderIDs.yanaOrderID,
                YanaOrderNo: orderData.yanaOrderIDs.yanaOrderNo,
                YanaCustomerID: orderData.yanaOrderIDs.yanaCustomerID,
                YanaCustomerNo: orderData.yanaOrderIDs.yanaCustomerNo,
                DeliveryLocationState: orderData.delivery.deliveryLocationState,
                DeliveryLocationSuburb: orderData.delivery.deliveryLocationSuburb,
                DeliveryLocationPostcode: orderData.delivery.deliveryLocationPostcode,
                DeliveryLocationAddress: orderData.delivery.deliveryLocationAddress,
                DeliveryOption: orderData.delivery.deliveryOption,
                DeliveryDate: orderData.delivery.deliveryDate == "" ? undefined : moment(orderData.delivery.DeliveryDate).toDate(),
                DeliveryFormatDate: orderData.delivery.deliveryDate,
                DeliveryTime: orderData.delivery.deliveryTime,
                Country: orderData.orderCustomer.customerCountry,
                PickupLocationName: orderData.delivery.pickupLocationName,
                Available: orderData.delivery.available,
            };
            dispatch(orderScheduleDeliveryStore.setOrderScheduleDeliveryAction(scheduleDelivery));
        }
        else {
            if (response.errorCode == rdEnum.enumRDErrorCode.NotSignIn) {
                azureB2C.signIn();
            }
            else {
                navigate(`${getFrontendUrl()}/error`);
            }
        }
    }

    const getOptions = (optionData: any): vehicleModel.IVehicleOptions[] | null => {
        let configs: vehicleModel.IVehicleOptions[] = [];
        optionData.map((item: any) => {
            configs.push({
                YanaOptionID: "",
                OptionType: item.optionType,
                OptionName: item.optionName,
                OptionPrice: item.optionPrice,
                OptionPicture: [],
                OptionPictureMobile: [],
                OptionDesc: item.optionDesc,
                ChargeType: item.chargeType,
                Disable: false
            });
        });
        return configs;
    }

    const getPaymentHistory = (paymentHistoryData: any): orderModel.IOrderPaymentHistory[] | null => {

        let history: orderModel.IOrderPaymentHistory[] = [];
        paymentHistoryData.map((item: any) => {
            history.push({
                PaymentDate: item.paymentDate,
                PaymentName: item.paymentName,
                PaymentAmount: item.paymentAmount,
            });
        });


        return history;
    }



    const FrqQuestionsToggle = ({ children, eventKey, callback, isOpen }: any) => {

        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => {
                if (callback) {
                    callback(eventKey); // Invoke the callback if provided
                }
                setAccordionOpen({
                    ...accordionOpen,
                    [`btn${eventKey}` as keyof AccordionOpenType]: !accordionOpen[`btn${eventKey}` as keyof AccordionOpenType]
                });
            }
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button
                type="button"
                onClick={decoratedOnClick}
                className='question-accordion-header-btn'
                aria-expanded={accordionOpen[`btn${eventKey}` as keyof AccordionOpenType]}
            >
                {
                    accordionOpen[`btn${eventKey}` as keyof AccordionOpenType]
                        ?
                        <img alt="" src={`${getCMSAssets()}/users/minus.svg`} />
                        :
                        <img alt="" src={`${getCMSAssets()}/users/plus.svg`} />
                }
                <span>{children}</span>
            </button>
        );
    }


    var currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    const showPrice = (price: number | null | undefined) => {
        if (price == null || price == undefined || price == 0)
            return "Included";
        return currencyFormatter.format(price);
    }

    const optionsTemplate = (options: vehicleModel.IVehicleOptions[] | null, optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(options) && options.length > 0) {
            let selectedOptions = options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions.map((optionItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='details-desc' key={optionType + "_" + index}>
                            <p>{optionItem?.OptionName}</p>
                            <span>{showPrice(optionItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<></>);
        }
        else {
            template.push(
                <div className='details-item'>
                    <div className='details-subtitle'>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</div>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    useEffect(() => {
        doGetDownloadPaymentSummary();

    }, [orderDetailsFromStore?.yanaCustomerID, orderDetailsFromStore?.yanaOrderID])

    const doGetDownloadPaymentSummary = async () => {
        let doc: docModel.IDocumentItem = {
            DocumentName: rdEnum.enumDocumentType.PaymentSummary,
            DocumentType: rdEnum.enumDocumentType.PaymentSummary,
            Base64: "",
            InvalidDocment: null,
            InvalidReason: null,
            DocumentKey:  null,
            DocumentUrl:  null,
            DocumentDownloadUrl: null,
        };
        let requestData: docModel.IDocument = {
            YanaCustomerID: orderDetailsFromStore?.yanaCustomerID || "",
            YanaCustomerNo: orderDetailsFromStore?.yanaCustomerNo || "",
            YanaOrderID: orderDetailsFromStore?.yanaOrderID || "",
            YanaOrderNo: orderDetailsFromStore?.yanaOrderNo || "",
            Country: orderDetailsFromStore?.country || "",
            Documents: [doc]
        };
        let response = await docService.getSpecificDocuments(requestData);
        if (response.status && response.data.length > 0) {
            setPaymentSummaryLink(response.data[0].downloadUrl);
        }
    }

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }

    }, []);

    return (

        <MainLayoutPage sceneId="container-has-header">

            <GlobalMenu />
            {loadingStatus === rdEnum.enumProcessingStatus.Processing && (
                <div className='order-details-loading-container'>
                    {/* <ProgressSpinner
                        style={{ width: '64px', height: '64px' }}
                        strokeWidth="5"
                        animationDuration=".8s"
                    /> */}
                    <Spinner spinnerWidth={64} />
                </div>
            )}

            {loadingStatus === rdEnum.enumProcessingStatus.Processed && (
                <React.Fragment>
                    <div className='order-details-container'>
                        <div className='order-details-header'>
                            <div className='header-panel'>
                                <h1>{order?.VariantName}</h1>
                            </div>
                            {
                                !isMobile
                                ?
                                <div className='header-management-panel'>
                                    <div className='order-ID-box'>
                                        Order ID: {order?.YanaOrderNo}
                                    </div>
                                    <div className="">
                                        {order?.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup && ifShowViewAppointmentButton && (
                                            <ViewAppointment buttonText={"VIEW APPOINTMENT"} />
                                            //<div className='pipeline-btn-panel'>
                                            //    <Button className='pipeline-btn'
                                            //        onClick={() => {
                                            //            //todo:
                                            //            //onShowDialog();
                                            //        }}
                                            //    >
                                            //        VIEW APPOINTMENT
                                            //</Button>
                                            //</div>
                                        )}
                                        <div className='right-options-panel'>
                                            <Support />
                                            <Documents />
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                           
                        </div>

                        <Row className='details-carousel-content'>
                            <Col lg={8} className='details-left-carousel-container'>
                                <CarCarousel variantAWD='luxury' exteriorColor="Argent Silver Metallic" interiorColor="Oxford Stone" carouselInterval={null}
                                    images={order.VehicleImages}
                                    mobileImages={order.VehicleImages}
                                    isUseImages={true}
                                />
                            </Col>

                            {
                                isMobile
                                ?
                                <div className='header-management-panel'>
                                    {/* <div className='order-ID-box'>
                                        Order ID: {order?.YanaOrderNo}
                                    </div> */}
                                    <div className="">
                                        {order?.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup && ifShowViewAppointmentButton && (
                                            <ViewAppointment buttonText={"VIEW APPOINTMENT"} />
                                            //<div className='pipeline-btn-panel'>
                                            //    <Button className='pipeline-btn'
                                            //        onClick={() => {
                                            //            //todo:
                                            //            //onShowDialog();
                                            //        }}
                                            //    >
                                            //        VIEW APPOINTMENT
                                            //</Button>
                                            //</div>
                                        )}
                                        <div className='right-options-panel'>
                                            <Support />
                                            <Documents />
                                        </div>

                                        <div className='order-steps-container'>
                                            <div className='order-steps-inner-container'>
                                                <div className='order-panel'>
                                                    <div className='order-date-panel'>
                                                        <div className='order-id'>Order {order?.YanaOrderNo}</div>
                                                        <div className='order-estimate-date'>Est. delivery {order?.Available}</div>
                                                    </div>
                                                    <div className='order-status-panel'>
                                                        {/* <div className='order-status-box'>
                                                            <div className='ordered-date'>09.09.2024</div>
                                                            <div className='order-status'>Ordered</div>
                                                        </div> */}
                                                        <div className='order-placed-info'>Your order is in progress. There are a number of important steps to complete before we can deliver your Cadillac.</div>
                                                    </div>
                                                </div>

                                                <div className='steps-panel' onClick={() => handleDialogOpen()}>
                                                    See all steps
                                                    <img alt="" className='close-icon' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            {
                                !isMobile
                                ?
                                <Col lg={4} className='details-right-steps-container'>

                                    <div className='purchasing-steps-container'>
                                        <div className='purchasing-steps-title'>
                                            <h5>ORDER {order?.YanaOrderNo}</h5>
                                            <span>Est. delivery {order?.Available}</span>
                                        </div>
                                        <ProgressSteps progressList={stepsList} />
                                    </div>

                                </Col>
                                :
                                <></>
                            }
                            
                        </Row>

                        {!isCancelledOrder && (

                            <div className='selection-details'>
                                <div className='selection-panel'>
                                    <div className='section-top-panel'>
                                        <div className='section-title'>
                                            YOUR SELECTION
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Variant</div>
                                            <div className='details-desc'>
                                                <p>{order?.VariantName}</p>
                                                <span>{rdHelper.currencyFormatWithBlank(order.VariantPrice)}</span>
                                            </div>
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Exterior</div>
                                            <div className='details-desc'>
                                                <p>{order?.ExteriorName}</p>
                                                <span>{showPrice(order?.ExteriorPrice)}</span>
                                            </div>
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Interior</div>
                                            <div className='details-desc'>
                                                <p>{order?.InteriorName}</p>
                                                <span>{showPrice(order?.InteriorPrice)}</span>
                                            </div>
                                        </div>

                                        {optionsTemplate(order?.Options, rdEnum.enumVehicleOptionType.Charge)}
                                        {optionsTemplate(order?.Options, rdEnum.enumVehicleOptionType.Accessories)}

                                        <div className='details-total-item'>
                                            <div className='details-subtitle'>Vehicle Subtotal</div>
                                            <div className='details-desc'>
                                                <span>{showPrice(order?.DriveawayCost?.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className='details-gst'>Includes {showPrice(order?.DriveawayCost?.GST)} GST</div>
                                        {order?.Country == rdEnum.enumCountryCode.Australia && (
                                            <React.Fragment>
                                                <div className='details-item' style={{ marginTop: "10px" }}>
                                                    <div className='details-desc'>
                                                        <p>Luxury Car Tax</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LuxuryCarTax)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Local Stamp Duty</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LocalStampDuty)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Local Registration Costs</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LocalRegistrationCost)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Local CTP</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LocalCTP)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Local Plate Fee</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LocalPlateFee)}</span>
                                                    </div>
                                                </div>


                          
                                            </React.Fragment>
                                        )}
                                        {order?.Country == rdEnum.enumCountryCode.NewZealand && (
                                            <React.Fragment>
                                                <div className='details-item' style={{ marginTop: "10px" }}>
                                                    <div className='details-desc'>
                                                        <p>Local Registration Costs</p>
                                                        <span>{showPrice(order?.DriveawayCost?.LocalRegistrationCost)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Local Plate Fee</p>
                                                        <span>{rdHelper.currencyFormatWithIncluded(order?.DriveawayCost?.LocalPlateFee || 0)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Tyre Stewardship Fee</p>
                                                        <span>{showPrice(order?.DriveawayCost?.TyreStewardshipFee)}</span>
                                                    </div>
                                                </div>
                                                <div className='details-item'>
                                                    <div className='details-desc'>
                                                        <p>Road User Charges</p>
                                                        <span>{showPrice(order?.DriveawayCost?.RoadUserChargers)}</span>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        <div className='details-total-item'>
                                            <div className='details-subtitle'>On Road Fee Subtotal</div>
                                            <div className='details-desc'>
                                                <span>{showPrice(order?.DriveawayCost?.OnRoadFee)}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-bottom-panel'>
                                        <div className='section-bottom'>
                                            <h6>Total</h6>
                                            <h6>{rdHelper.currencyFormatWithBlank(order.DriveawayCost?.DriveawayPrice || 0)}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='payment-summary-panel'>
                                    <div className='section-top-panel'>
                                        <div className='section-title'>
                                            PAYMENT SUMMARY
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-desc'>
                                                <p>Total Price</p>
                                                <span>{rdHelper.currencyFormatWithBlank(order?.DriveawayCost?.DriveawayPrice || 0)}</span>
                                            </div>
                                        </div>
                                        {!isNil(order.PaymentHistory) && order.PaymentHistory.map((item: orderModel.IOrderPaymentHistory, index: any) => {
                                            return (
                                                <div className='details-item' key={"" + item + index}>
                                                    <div className='details-subtitle'>{item.PaymentDate}</div>
                                                    <div className='details-desc'>
                                                        <p>Less: {item.PaymentName}</p>
                                                        <span>{rdHelper.currencyFormatWithBlank(item.PaymentAmount)}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className='section-bottom-panel'>
                                        {!isNil(paymentSummaryLink) && paymentSummaryLink != "" && (
                                        <div className='download-btn' onClick={() => {
                                            window.open(paymentSummaryLink);
                                        }}>
                                            <img alt="" src={`${getCMSAssets()}/order/download.svg`} />
                                        </div>
                                        )}
                                        <div className='section-bottom'>
                                            <h6>Current Balance</h6>
                                            <h6>{rdHelper.currencyFormatWithBlank(order.Balance)}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='finance-delivery-container'>
                                    <div className='finance-panel'>
                                        <div className='section-title'>
                                            FINANCE
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Customer type</div>
                                            <div className='details-desc'>
                                                <p>{order?.CustomerType}</p>
                                            </div>
                                        </div>

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Payment type</div>
                                            <div className='details-desc'>
                                                <p>{order?.PaymentOption}</p>
                                            </div>
                                        </div>

                                        {/*<div className='details-item'>*/}
                                        {/*    <div className='details-subtitle'>Down Payment</div>*/}
                                        {/*    <div className='details-desc'>*/}
                                        {/*        <p>{rdHelper.currencyFormatWithBlank(order?.DownPayment)}</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                    <div className='delivery-panel'>
                                        <div className='section-title'>
                                            {order.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? "DELIVERY" : "IN-STORE COLLECTION"}
                                        </div>

                                        {/* <div className='details-item'>
                                <div className='details-subtitle'>Preferred option</div>
                                <div className='details-desc'>
                                    <p>{order?.DeliveryOption}</p>
                                </div>
                            </div> */}

                                        <div className='details-item'>
                                            <div className='details-subtitle'>Location</div>
                                            <div className='details-desc'>
                                                <p>{order.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? order?.DeliveryLocationAddress : order?.DeliveryLocationAddress}</p>
                                            </div>
                                        </div>

                                        {/* <div className='details-item'>
                                <div className='details-subtitle'>Estimated date</div>
                                <div className='details-desc'>
                                    <p>{order?.Available}</p>
                                </div>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        { order?.PaymentOption == rdEnum.enumPaymentOptions.Finance && (
                            <div className='success-disclaimer-container my-order'>
                                <p>
                                    {/* <i> */}
                                    Credit subject to financier's approval. Terms, conditions, fees and charges apply. Rates, repayments and fees are estimates and are subject to change at any time. Credit provided by Plenti Finance Pty Limited ACN 636 759 861 (supported by its servicer, Plenti RE Limited ACN 166 646 635). Plenti RE Limited holds Australian Financial Services Licence number 449176 and Australian Credit Licence number 449176.
                                    {/* </i> */}
                                </p>
                            </div>
                        )}

                        <div className='questions-container'>
                            <Row>
                                <Col lg={4} className='questions-title-panel'>
                                    <h2>QUESTIONS?</h2>
                                    <p>
                                        Browse frequently asked questions for contract Cadillac Team for assistance.
                            </p>
                                </Col>
                                <Col lg={8} className='questions-accordion-panel'>
                                    <Accordion className='questions-accordion' alwaysOpen >
                                        {/* <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="0">PLATFORM¹</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Powered by Ultium - GM's next-gen battery platform</li>
                                                        <li>Dual-drive motor all-wheel drive</li>
                                                        <li>Regenerative braking with Regen on Demand</li>
                                                        <li>One Pedal Driving capability</li>
                                                        <li>Electronic Precision Shift</li>
                                                        <li>530km all-electric driving range²</li>
                                                        <li>528hp / 388kW power³</li>
                                                        <li>610Nm of near-instant torque³</li>
                                                        <li>0-100km/h in 4.6 seconds3 with a top speed of 210km/h</li>
                                                        <li>400 V lithium-ion Ultium battery with 102 kWh capacity</li>
                                                        <li>DC fast charging 10-80% in 34 minutes (up to 190 kW)&#x2074;</li>
                                                        <li>AC charging 0-100% (3-phase 230V AC) in 7 hours (11 kW)&#x2074;</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="1">INTERIOR</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <ul>
                                                        <li>33-inch diagonal LED screen, capable of 9K resolution</li>
                                                        <li>Fixed glass roof with power shade</li>
                                                        <li>Counter levered centre console with armrest</li>
                                                        <li>Glovebox with electronic release</li>
                                                        <li>Carpeted floor mats, front and rear</li>
                                                        <li>Rear cargo shade, retractable</li>
                                                        <li>Interior ambient lighting with 26 colours</li>
                                                        <li>Illuminated door sill plates with LYRIQ show car silhouette</li>
                                                        <li>Power rake and telescopic steering column</li>
                                                        <li>Tri-zone automatic climate control (including rear)</li>
                                                        <li>Cabin humidity and air quality sensors</li>
                                                        <li>Keyless entry with automatic start</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="2">SEATING COMFORT</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Automated heated steering wheel with mounted controls</li>
                                                        <li>Automatic heated front and rear (outboard) seats</li>
                                                        <li>Ventilated front seats with massage function</li>
                                                        <li>8-way power-adjustable front seats</li>
                                                        <li>4-way power lumbar adjuster, driver and front passenger</li>
                                                        <li>Rear bench seat, 1-touch flat folding</li>
                                                        <li>60/40 split-folding rear bench seat and rear fold-down centre armrest</li>
                                                        <li>Rear centre seat with manually adjustable headrest</li>
                                                        <li>Memory capability for two: driver seat, outside mirrors and steering wheel</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="3">INFOTAINMENT</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Wireless Apple Carplay and Android Auto built in</li>
                                                        <li>Cadillac-exclusive AKG Studio 19-speaker audio system</li>
                                                        <li>Active noise cancellation</li>
                                                        <li>Wireless mobile device charging (requires compatible device)</li>
                                                        <li>Digital audio broadcast (DAB/DAB+/DMB-R)</li>
                                                        <li>5 USB-C ports (3 full function, front, 2 charge-only, rear)</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="4">EXTERIOR</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Future face of Cadillac: distinctive 3D-eched crystal shield front grille design</li>
                                                        <li>Signature Adaptive LED headlamps incorporated in slimline vertical design</li>
                                                        <li>Daytime running lamps (DRLs) incorporated in slimline vertical design</li>
                                                        <li>LED rear taillights and indicators</li>
                                                        <li>21" split spoke alloy wheels</li>
                                                        <li>Heated, power-adjustable, power-folding, driver side auto dimming mirrors</li>
                                                        <li>Mirror-integrated turn signal indicators</li>
                                                        <li>Acoustic laminated and solar-absorbing front door glass</li>
                                                        <li>Privacy glass</li>
                                                        <li>Hands free power liftgate</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="5">SAFETY & SECURITY⁸</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="5">
                                                <Card.Body>
                                                    <ul>
                                                        <li>6 airbags: front and thorax, first and second row roof rail HD Surround Vision with birds-eye view</li>
                                                        <li>Rear Camera (digital) Mirror with lens-cleaning feature</li>
                                                        <li>Adaptive Cruise Control</li>
                                                        <li>Lane Keep Assist with Lane Departure Warning</li>
                                                        <li>Blind Zone Steering Assist</li>
                                                        <li>Following Distance Indicator • Forward Collision Alert</li>
                                                        <li>Enhanced Autonomous Emergency Braking Reverse Autonomous Braking</li>
                                                        <li>Rear Pedestrian Detection</li>
                                                        <li>Intersection Autonomous Emergency Braking Front Pedestrian and Cyclist Braking</li>
                                                        <li>Side Cyclist Alert</li>
                                                        <li>Rear Cross-traffic Braking</li>
                                                        <li>Safety Alert Seat</li>
                                                        <li>Locking wheel lugs</li>
                                                        <li>Driver Drowsiness Monitoring System</li>
                                                        <li>Steering column lock</li>
                                                        <li>Door shield locks and glass-break sensors Vehicle inclination tilt sensors</li>
                                                        <li>Vehicle interior movement sensor</li>
                                                        <li>Self-powered theft deterrent system</li>
                                                        <li>Tyre pressure monitoring system</li>
                                                        <li>RainsenseTM automatic wipers</li>
                                                        <li>Windshield with heated glass in wiper park position</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card> 
                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="6">DIMENSIONS</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="6">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Overall height: 1,623mm</li>
                                                        <li>Overall length: 5,005mm</li>
                                                        <li>Overall width (with mirrors): 2,207mm</li>
                                                        <li>Wheelbase: 3,094mm</li>
                                                        <li>Headroom: 980mm (front), 957mm (rear)</li>
                                                        <li>Legroom: 1,124mm (front-max), 1,006mm (rear)</li>
                                                        <li>Shoulder room: 1,497mm (front), 1,488mm (rear)</li>
                                                        <li>Hip room: 1,436mm (front), 1,372mm (rear)</li>
                                                        <li>Base Curb Weight: 2,774kg9</li>
                                                        <li>Passenger volume: 2,976L</li>
                                                        <li>Cargo volume: 793L (behind read seat), 1,722L (rear seatbacks folded)</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card> */}

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="0">ORDER</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className='qa-card'>
                                                        <p>Q: I've changed my mind. Can I cancel my order? </p>
                                                        <p>A: We understand plans change. You can cancel your order any time before your Contract of Sale has been signed and cooling off period passed. To do so, please contact the Cadillac Sales Team using the drop down at the top right of your Cadillac account.  </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: Can I update my order (change my vehicle or its specifications)? </p>
                                                        <p>A: Please contact the Cadillac Sale Team using the drop down at the top right of your Cadillac account to discuss changes to your order. Your options will be determined by your order status and individual circumstances.  </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: When do I need to make full payment for the vehicle?  </p>
                                                        <p>A: We require full payment for your Cadillac at least 3 business days prior to delivery. Regional Delivery customers are required to make full payment before a delivery date is arranged. </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: How much notice will I have to arrange payment? </p>
                                                        <p>A: The payment task will activate as soon as your Contract of Sale is signed, and cooling off period passed. You will find the payment task and instructions in the order tracking section of your account home page. </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: Can I pay the full price of the vehicle on my credit card? </p>
                                                        <p>A: No. Cadillac requires payments to be made via electronic funds transfer (EFT). You can view instructions in the payments task once it is available in your account.  </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: Does Cadillac accept Trade-ins? </p>
                                                        <p>A: Cadillac does not currently accept trade-ins. Should you have a vehicle to dispose of, please contact the Cadillac Sales Team so we can assist you in this process. </p>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="1">FINANCE</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    {/* <div className='qa-card'>
                                                        <p>Q: Can I get a Cadillac on a novated lease? </p>
                                                        <p>A: Not at this stage. Cadillac will continue to expand its purchase options in Australia and New Zealand.  </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: If my finance application gets rejected, how can I proceed? Will my deposit be refunded to me if I cannot proceed? </p>
                                                        <p>A: Your Contract of Sale is conditional upon finance approval. If your application is rejected, you can speak with our Finance partners about next steps. The Cadillac Sales Team will be available to understand your individual circumstances and next steps relating to your deposit. </p>
                                                    </div> */}
                                                    <div className='qa-card'>
                                                        <p>
                                                        Plenti and Cadillac have partnered to provide consumers with a simple and efficient financing application experience - for any finance related questions please contact Plenti on 1800 954 203.
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <FrqQuestionsToggle eventKey="2">DELIVERY</FrqQuestionsToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <div className='qa-card'>
                                                        <p>Q: Can I change the delivery location of my Cadillac? Store vs Home Delivery </p>
                                                        <p>A: Please contact the Cadillac Sales Team to discuss changes to your instore pick up or delivery location. Your options will be determined by your order status and individual circumstances.    </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: How can I reschedule the delivery date of my Cadillac?  </p>
                                                        <p>A: You can manage your instore pick up appointment in the order tracking section of your account. For any other queries relating to your pick up or delivery, please contact the Cadillac Sales Team. </p>
                                                    </div>
                                                    <div className='qa-card'>
                                                        <p>Q: Can my partner pick up my Cadillac on my behalf? </p>
                                                        <p>A: The registered owner will be required to pick up or take delivery of their vehicle, including provision of photo identification to Cadillac staff. </p>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    {
                        isMobile
                        ?
                        <Dialog className='mobile-steps-dialog' position='bottom' visible={isOpen} draggable={false} resizable={true} onHide={() => onHide()}>
                            <div className='purchasing-steps-container'>
                                {/* <div className='close-panel'>
                                    <img alt="" onClick={() => onHide()} className='download-icon' src={`${getCMSAssets()}/inventory/cross.svg`} />
                                </div> */}
                                <div className='purchasing-steps-title'>
                                    <h5>ORDER {order?.YanaOrderNo}</h5>
                                    <span>Est. delivery {order?.Available}</span>
                                </div>
                                <ProgressSteps progressList={stepsList} />
                            </div>
                        </Dialog>
                        :
                        <></>
                    }
                    
                </React.Fragment>
            )}

        </MainLayoutPage>
    )
}

export default OrderDetails

