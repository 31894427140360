import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownProps } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react'
import { Accordion, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';

import { filter, includes, find, isNil } from 'lodash';

import * as rdEnum from 'helpers/enum';
import { ICodeName } from 'model/ICommon';
import { IAppState } from "model/IAppState";
import * as paymentOptionModel from 'model/IPaymentOptions';
import * as vehicleModel from 'model/IVehicle';
//import * as selectConfigModel from 'model/IUserSelectedConfig';
import * as paymentOptionService from 'services/PaymentOptions';
import * as paymentOptionsStore from 'stores/PaymentOptions';
import * as financeHelper from 'businessHelpers/FinanceHelper';
import * as driveawayCostHelper from 'businessHelpers/DriveawayCostHelper';
import * as rdHelper from 'helpers/Uri';
import * as paymentOptionStore from 'stores/PaymentOptions';
import { getFrontendUrl, getCMSAssets } from 'helpers/Uri'

import { useCountryState } from 'helpers/useCountryState';
import { PaymentOptionsComponentProps } from 'model/IPaymentOptions';


export type ChildRef = {
    openDialog: () => void;
};



//1. if this popup is showed on landing page, subtotal, est delivery date, variant should be from landing page api result
//2. if this popup is showed on configuration page, subtotal, est delivery date, variant should be from store
const PaymentOptions = forwardRef<ChildRef, PaymentOptionsComponentProps>((props, ref) => {
    //let isMounted = useRef<boolean>();
    const dispatch = useDispatch();
    const countryState = useCountryState();

    const { isFromLandingPage, subTotal, estDeliveryDate,
        variantName, variantID, variantPrice,
        exteriorID, exteriorName, exteriorPrice,
        interiorID, interiorName, interiorPrice,
        options
    } = props;



    //store variables
    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const vehFromStore = useSelector((appState: IAppState) => {
        return appState.vehicle;
    });
    const customerTypeFromStore = useSelector((appState: IAppState) => {
        return appState.customerType;
    });
    const driveAwayCostFromStore = useSelector((appState: IAppState) => {
        return appState.paymentOptions?.DriveAwayCost;
    });
    const paymentOptionsFromStore = useSelector((appState: IAppState) => {
        return appState.paymentOptions;
    });

    //page states
    const [isOpen, setIsOpen] = useState(false);
    const [paymentOption, setPaymentOption] = useState('');
    const [selectedState, setSelectedState] = useState<ICodeName | null>(null);
    const [postCode, setPostcode] = useState<string | null>(null);
    const [suburb, setSuburb] = useState<string | null>(null);
    const [registrationType, setRegistrationType] = useState(rdEnum.enumClientTypes.Private);
    //const [downPayment, setDownPayment] = useState('0');

    const [driveawayCostRequest, setDriveawayCostRequest] = useState<paymentOptionModel.IDriveAwayRequest>(driveawayCostHelper.getInitDriveawayCostRequest());
    const [driveAwayCost, setDriveAwayCost] = useState<paymentOptionModel.IDriveAwayCost>(driveawayCostHelper.getInitDriveawayCost());

    const [states, setStates] = useState<ICodeName[]>([]);   //state dropdown list items

    const abortControllerInitialValue: any = new (
        window as any
    ).AbortController();
    const [costAbortController, setCostAbortController] = useState(
        abortControllerInitialValue,
    );

    //finance states
    const [financePageState, setFinancePageState] = useState<paymentOptionModel.IFinancePageState>(financeHelper.getInitFinancePageState());
    const [financeRequest, setFinanceRequest] = useState<paymentOptionModel.IFinanceRequest>(financeHelper.getInitFinanceRequest());


    const [termOptions, setTermOptions] = useState<paymentOptionModel.IFinanceFilter[]>();
    const [selectedTermOptions, setSelectedTermOptions] = useState<paymentOptionModel.IFinanceFilter | null>();
    const [balloonPaymentOptions, setBalloonPaymentOptions] = useState<paymentOptionModel.IFinanceFilter[]>();
    const [selectedBalloonPaymentOptions, setSelectedBalloonPaymentOptions] = useState<paymentOptionModel.IFinanceFilter | null>();
    const [frequencyOptions, setFrequencyOptions] = useState<paymentOptionModel.IFinanceFilter[]>();
    const [selectedFrequencyOption, setSelectedFrequencyOptions] = useState<paymentOptionModel.IFinanceFilter | null>();


    const initAbortCalculateFinanceValue: any = new (window as any).AbortController();
    const [financeAbortController, setFinanceAbortController] = useState(initAbortCalculateFinanceValue);

    const [cashActiveEventKey, setCashActiveKey] = useState(null);
    const [financeActiveEventKey, setFinanceActiveKey] = useState(null);

    const handleCashSelect = (eventKey: any) => {
        setCashActiveKey(cashActiveEventKey === eventKey ? null : eventKey);
    };

    const handleFinanceSelect = (eventKey: any) => {
        setFinanceActiveKey(financeActiveEventKey === eventKey ? null : eventKey);
    };

    useImperativeHandle(ref, () => ({
        openDialog: () => {
            setIsOpen(true);
            document.body.style.overflowY = 'hidden';
            document.body.style.paddingRight = '15px';
        },
    }));

    const onHide = () => {
        setIsOpen(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    };

    const onStateChange = (e: any) => {
        setSelectedState(e.value);
        setPostcode("");
        setSuburb("");
    }

    const toPrivacy = () => {
        window.open('https://www.mtf.co.nz/privacy-statement/')
    }

    const toRatesFees = () => {
        window.open('https://www.mtf.co.nz/resources/interest-rates-and-fees/')
    }

    //useEffect(() => {
    //    setPaymentOption(paymentType);

    //}, [paymentType]);

    useEffect(() => {
        if (isFromLandingPage) {
            setPaymentOption(rdEnum.enumPaymentOptions.Finance);
        }
        else {
            setPaymentOption(paymentOptionsFromStore?.PaymentOption || rdEnum.enumPaymentOptions.Cash);
        }
    }, [paymentOptionsFromStore.PaymentOption, isFromLandingPage, isOpen]);

    useEffect(() => {
        // console.log("isOpen:" + isOpen);
        //call drive away cost and calculate finance when open popup
        if (isOpen) {
            //doGetFinanceFilter();
            buildDriveawayCostRequest();

            buildFinanceRequest();
        }
    }, [isOpen,
        selectedLocationFromStore.Country?.code,
        selectedState,
        variantID, exteriorID, ,
        interiorID, options,
        registrationType, customerTypeFromStore]);

    useEffect(() => {
        doGetDriveAwayCost();
    }, [JSON.stringify(driveawayCostRequest)]);



    useEffect(() => {
        if (customerTypeFromStore == rdEnum.enumClientTypes.Private.Code) {
            setRegistrationType(rdEnum.enumClientTypes.Private);
        }
        else {
            setRegistrationType(rdEnum.enumClientTypes.Business);
        }
    }, [customerTypeFromStore]);


    useEffect(() => {
        //set default state/postcode/suburb value
        //if has not enter primary address, use the selected state on the 1st popup
        //if has primary address, use primary address's state/suburb/postcode
        let state = "";
        let postcode = "";
        let suburb = "";
        if (!isNil(selectedLocationFromStore?.PrimaryAddress) && selectedLocationFromStore?.PrimaryAddress != "") {
            state = selectedLocationFromStore.PrimaryState || "";
            postcode = selectedLocationFromStore.PrimaryPostcode || "";
            suburb = selectedLocationFromStore.PrimarySuburb || "";
        }
        else {
            state = selectedLocationFromStore?.State?.code || "";
        }
        setSelectedState({ code: state, name: state });
        setPostcode(postcode);
        setSuburb(suburb);

    }, [selectedLocationFromStore?.State, selectedLocationFromStore?.PrimaryAddress]);




    //set states dropdown list
    const doGetStates = async () => {
        let states = await countryState.getStates(selectedLocationFromStore?.Country?.code || "");
        setStates(states);
    }
    useEffect(() => {
        doGetStates()
    }, [selectedLocationFromStore?.Country?.code]);



    const getRegistrationTypes = () => {
        let types = [];
        types.push(rdEnum.enumClientTypes.Private);
        types.push(rdEnum.enumClientTypes.Business);
        return types;
    }


    //const build
    const buildDriveawayCostRequest = () => {
        //if is pickup, distance is from pickup location
        //if is delivery, distance is from DeliveryDistance
        let distance = 0;
        if (selectedLocationFromStore?.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            distance = selectedLocationFromStore?.SelectedPickupLocation?.Distance || 0;
        }
        else {
            distance = selectedLocationFromStore?.DeliveryDistance || 0;
        }

        let pickupOption = selectedLocationFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup;
        let requestData: paymentOptionModel.IDriveAwayRequest = {
            Country: selectedLocationFromStore.Country?.code || "",
            State: selectedState?.code || "",
            VariantName: variantName,  //selectVehFromStore.VariableName || "",
            VariantID: variantID,
            VariantPrice: variantPrice,
            ExteriorID: exteriorID,
            ExteriorName: exteriorName,
            ExteriorPrice: exteriorPrice,
            InteriorID: interiorID,
            InteriorName: interiorName,
            InteriorPrice: interiorPrice,
            Options: options,
            SubTotal: subTotal,   //selectVehFromStore.Subtotal || 0,
            DeliveryOption: selectedLocationFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            RegistrationType: registrationType.Code,
            Distance: distance,
            PostCode: postCode || "",
            Suburb: suburb || "",
            CallSource: "PaymentOptions",
            IfCalculateFinance: false,
            DeliveryPrice: (pickupOption == rdEnum.enumDeliveryOptions.HomeDelivery) ? selectedLocationFromStore?.DeliveryPrice : 0,
        };

        //from landing page
        if (isFromLandingPage) {
            requestData.DeliveryOption = rdEnum.enumDeliveryOptions.Pickup;
            requestData.Distance = 0;
            requestData.PostCode = "";
            requestData.Suburb = "";
        }
        setDriveawayCostRequest(requestData);

    }


    const doGetDriveAwayCost = async () => {
        const abortController = new (window as any).AbortController();
        await setCostAbortController(abortController);

        if (driveawayCostRequest.Country == ""
            || (driveawayCostRequest.Country == rdEnum.enumCountryCode.Australia && driveawayCostRequest.State == "")
            || isNil(driveawayCostRequest.VariantID) || driveawayCostRequest.VariantID == ""
            || isNil(driveawayCostRequest.ExteriorID) || driveawayCostRequest.ExteriorID == ""
            || isNil(driveawayCostRequest.InteriorID) || driveawayCostRequest.InteriorID == ""
            || driveawayCostRequest.SubTotal == 0) return;

        try {
            let request = await paymentOptionService.getDriveAwayCost(driveawayCostRequest, abortController.signal);
            if (request.status) {

                let driveAwayCostDataFromBackend = request.data.driveawayCostData;
                let driveAwayCostData: paymentOptionModel.IDriveAwayCost = {
                    LuxuryCarTax: driveAwayCostDataFromBackend.luxuryCarTax,
                    LocalStampDuty: driveAwayCostDataFromBackend.localStampDuty,
                    LocalRegistrationCost: driveAwayCostDataFromBackend.localRegistrationCost,
                    LocalCTP: driveAwayCostDataFromBackend.localCTP,
                    LocalPlateFee: driveAwayCostDataFromBackend.localPlateFee,
                    HomeDelivery: driveAwayCostDataFromBackend.homeDelivery,
                    OnRoadFee: driveAwayCostDataFromBackend.onRoadFeeSubtotal,
                    SubTotal: driveAwayCostDataFromBackend.subTotal,
                    DriveawayPrice: driveAwayCostDataFromBackend.driveawayPrice,
                    VariantName: driveAwayCostDataFromBackend.variantName,
                    VariantID: driveAwayCostDataFromBackend.variantID,
                    VariantPrice: driveAwayCostDataFromBackend.variantPrice,
                    ExteriorID: driveAwayCostDataFromBackend.exteriorID,
                    ExteriorName: driveAwayCostDataFromBackend.exteriorName,
                    ExteriorPrice: driveAwayCostDataFromBackend.exteriorColourPrice,
                    InteriorID: driveAwayCostDataFromBackend.interiorID,
                    InteriorName: driveAwayCostDataFromBackend.interiorName,
                    InteriorPrice: driveAwayCostDataFromBackend.interiorColourPrice,
                    GST: driveAwayCostDataFromBackend.gst,
                    RoadUserChargers: driveAwayCostDataFromBackend.roadUserChargers,
                    TyreStewardshipFee: driveAwayCostDataFromBackend.tyreStewardshipFee,
                    Options: getOptions(driveAwayCostDataFromBackend?.options),
                    
                }
                setDriveAwayCost(driveAwayCostData);
            }
        } catch (error: any) {
            if (error.name === 'AbortError') console.log('Request aborted!');
        } finally {
            //if (abortController.signal?.aborted)
        }

    }

    const getOptions = (optionData: any): vehicleModel.IVehicleOptions[] | [] => {
        let configs: vehicleModel.IVehicleOptions[] = [];
        optionData.map((item: any) => {
            configs.push({
                YanaOptionID: "",
                OptionType: item.optionType,
                OptionName: item.optionName,
                OptionPrice: item.optionPrice,
                OptionPicture: [],
                OptionPictureMobile: [],
                OptionDesc: item.optionDesc,
                ChargeType: item.chargeType,
                Disable: false
            });
        });
        return configs;
    }

    //finance
    const doGetTermOptions = async () => {
        let country = selectedLocationFromStore.Country?.code || "";

        let response = await paymentOptionService.getFinanceTermOptions(country);
        if (response.status) {
            let responseData = response.data;

            let defaultTerm: paymentOptionModel.IFinanceFilter | null = null;
            let tempTermOptions: paymentOptionModel.IFinanceFilter[] = responseData.map((item: any) => {
                let termItem: paymentOptionModel.IFinanceFilter = {
                    ID: item.filterID,
                    Name: item.filterName,
                    IsDefault: item.isDefault
                };
                if (item.isDefault) {
                    defaultTerm = termItem;
                }
                return termItem;
            });
            setTermOptions(tempTermOptions);
            setSelectedTermOptions(defaultTerm);


            //setFinancePageState({
            //    ...financePageState,
            //    SelectedTermOptions: defaultTerm,
            //})
        }

    }

    const doGetBallonOptions = async () => {
        let country = selectedLocationFromStore.Country?.code || "";

        let ballonOptionResponse = await paymentOptionService.getFinanceBallonOptions(
            country, selectedTermOptions?.ID || 0);
        if (ballonOptionResponse.status) {
            let defaultFinalBalloonPayments: paymentOptionModel.IFinanceFilter | null = null;
            let tempfinalBalloonPayments: paymentOptionModel.IFinanceFilter[] = ballonOptionResponse.data.map((item: any) => {
                let finalBalloonPaymentsItem: paymentOptionModel.IFinanceFilter = {
                    ID: item.filterID,
                    Name: item.filterName,
                    IsDefault: item.isDefault
                };
                if (item.isDefault) {
                    defaultFinalBalloonPayments = finalBalloonPaymentsItem;
                }
                return finalBalloonPaymentsItem;
            });
            setBalloonPaymentOptions(tempfinalBalloonPayments);
            setSelectedBalloonPaymentOptions(defaultFinalBalloonPayments);
            //setFinancePageState({
            //    ...financePageState,
            //    SelectedBalloonPaymentOptions: defaultFinalBalloonPayments,
            //})
        }
    }

    const doGetFrequencyOptions = async () => {
        let country = selectedLocationFromStore.Country?.code || "";

        let frequencyResponse = await paymentOptionService.getFrequency(country);
        if (frequencyResponse.status) {
            let defaultFrequencyPayments: paymentOptionModel.IFinanceFilter | null = null;
            let tempFrequencyPayments: paymentOptionModel.IFinanceFilter[] = frequencyResponse.data.map((item: any) => {
                let frequencyItem: paymentOptionModel.IFinanceFilter = {
                    ID: item.filterID,
                    Name: item.filterName,
                    IsDefault: item.isDefault
                };
                if (item.isDefault) {
                    defaultFrequencyPayments = frequencyItem;
                }
                return frequencyItem;
            });
            setFrequencyOptions(tempFrequencyPayments);
            setSelectedFrequencyOptions(defaultFrequencyPayments);
        }

    }



    const buildFinanceRequest = () => {
        let requestData: paymentOptionModel.IFinanceRequest = {
            Country: selectedLocationFromStore.Country?.code || "",
            Deposit: financePageState.Deposit,
            SelectedTermOptions: selectedTermOptions !== undefined ? selectedTermOptions : null,
            SelectedBalloonPaymentOptions: selectedBalloonPaymentOptions !== undefined ? selectedBalloonPaymentOptions : null,
            SelectedPaymentFrequency: selectedFrequencyOption !== undefined ? selectedFrequencyOption : null,
            DriveawayCost: driveAwayCost?.DriveawayPrice || 0,    //must be from state
            Source: "PaymentOptions"
        };
        setFinanceRequest(requestData);
    }


    const doCalculateFinance = async () => {
        //console.log(fromSource);
        if (selectedLocationFromStore.Country?.code == rdEnum.enumCountryCode.Australia) {
            if (isNil(selectedBalloonPaymentOptions)
                || isNil(selectedTermOptions)
            ) {
                return;
            }
        }
        else {
            if (isNil(selectedBalloonPaymentOptions)
                || isNil(selectedFrequencyOption)
            ) {
                return;
            }
        }

        const abortController = new (window as any).AbortController();
        await setFinanceAbortController(abortController);



        try {
            let response = await paymentOptionService.calculateFinance(financeRequest, abortController.signal);
            if (response.status) {
                let responseData = response.data;

                let updatedFinance = {
                    ...financePageState,
                    InterestRate: responseData.interestRateText,
                    ComparisonRate: responseData.comparisonRateText,
                    FinanceAmount: responseData.totalFinancedAmountText,
                    BallonAmount: responseData.balloonpaymentText,
                    LoanPayment: responseData.monthlyLoanPayment,
                    LoanPaymentText: responseData.monthlyLoanPaymentText,
                    CreditAssistanceFee: responseData.creditAssistanceFeeText,
                    MonthlyAccountFee: responseData.monthlyAccountFeeText,
                    FreqencyName: responseData.freqencyName,
                    NoOfMonth: responseData.noOfMonth,
                    InterestRateTo: responseData.interestRateTo,
                    TotalLoanPayment: responseData.totalLoanPayment,
                    PPSR: responseData.ppsr,
                };

                setFinancePageState(updatedFinance);
            }
        } catch (error: any) {
            if (error.name === 'AbortError') console.log('doCalculateFinance Request aborted!');
        } finally {
            //if (abortController.signal?.aborted)
        }
    }


    useEffect(() => {
        if (isOpen && !isNil(selectedLocationFromStore.Country?.code)
        ) {
            doGetTermOptions();

            if (selectedLocationFromStore.Country?.code == rdEnum.enumCountryCode.NewZealand) {
                doGetFrequencyOptions();
            }
        }

    }, [selectedLocationFromStore.Country?.code, isOpen]);

    useEffect(() => {

        if (isOpen && !isNil(selectedLocationFromStore.Country?.code) && selectedLocationFromStore.Country?.code != ""
            && !isNil(selectedTermOptions?.ID)
        ) {
            doGetBallonOptions();
        }

    }, [selectedTermOptions, isOpen]);



    useEffect(() => {
        //don't add financePageState.SelectedTermOptions to dependency 
        //because if SelectedTermOptions changed, SelectedBalloonPaymentOptions will be changed as well
        if (isOpen
            && !isNil(selectedTermOptions?.Name)
            && !isNil(selectedBalloonPaymentOptions?.Name)
        ) {
            buildFinanceRequest();
        }
        //financeAbortController.abort();
        //return () => {
        //    financeAbortController.abort();
        //};

    }, [
        selectedLocationFromStore.Country?.code,
        driveAwayCost?.DriveawayPrice,
        selectedTermOptions,
        selectedBalloonPaymentOptions,
        selectedFrequencyOption
    ]);

    useEffect(() => {
        if (isNil(financeRequest.Country) || financeRequest.Country == ""
            || isNil(financeRequest.SelectedTermOptions?.ID) || financeRequest.SelectedTermOptions?.ID == undefined
            || isNil(financeRequest.SelectedBalloonPaymentOptions?.ID) || financeRequest.SelectedBalloonPaymentOptions?.ID == undefined
            || financeRequest.DriveawayCost == 0) {
            return;
        }

        doCalculateFinance();

    }, [JSON.stringify(financeRequest)]);


    //selectedLocationFromStore.Country?.code,
    //financePageState.SelectedBalloonPaymentOptions,
    //    financePageState.Deposit
    const CustomFinanceToggle = ({ children, eventKey, financeActiveEventKey }: any) => {
        const isActive = financeActiveEventKey === eventKey;
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log(`Accordion with eventKey ${eventKey} is ${isActive ? 'closing' : 'opening'}!`),
        );

        return (
            <div className='driveaway-dropdown-panel'>
                <button
                    type="button"
                    onClick={decoratedOnClick}
                >
                    {children}
                </button>
                <span>{rdHelper.currencyFormatWithIncluded(driveAwayCost?.DriveawayPrice)}</span>
            </div>
        );
    }

    const showVehicleTemplate = () => {
        return (
            <div className='driveaway-price-panel'>
                <div className='driveaway-price-card'>
                    <span className='card-title'>Variant</span>
                    <div className='details-desc'>
                        <span>{driveAwayCost?.VariantName}</span>
                        <span className='price'>{rdHelper.currencyFormatWithIncluded(driveAwayCost?.VariantPrice)}</span>
                    </div>
                </div>

                <div className='driveaway-price-card'>
                    <span className='card-title'>Exterior</span>
                    <div className='details-desc'>
                        <span>{driveAwayCost?.ExteriorName}</span>
                        <span className='price'>{rdHelper.currencyFormatWithIncluded(driveAwayCost?.ExteriorPrice)}</span>
                    </div>
                </div>

                <div className='driveaway-price-card'>
                    <span className='card-title'>Interior</span>
                    <div className='details-desc'>
                        <span>{driveAwayCost?.InteriorName}</span>
                        <span className='price'>{rdHelper.currencyFormatWithIncluded(driveAwayCost?.InteriorPrice)}</span>
                    </div>
                </div>

                {optionsTemplate(driveAwayCost?.Options, rdEnum.enumVehicleOptionType.Accessories)}
                {optionsTemplate(driveAwayCost?.Options, rdEnum.enumVehicleOptionType.Charge)}

                <div className='driveaway-price-card'>
                    <div className='driveaway-price-row-item bold'>
                        <span className='special-title'>Vehicle Subtotal</span>
                        <span className='total-price'>{rdHelper.currencyFormatWithBlank(driveAwayCost?.SubTotal)}</span>
                    </div>
                    <span className='card-title'>Includes {rdHelper.currencyFormatWithBlank(driveAwayCost?.GST)} GST</span>
                </div>
            </div>
        )
    }

    const showTaxTemplate = () => {
        if (selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
            return (
                <div className='payment-display-panel'>
                    <div className='payment-row-item'>
                        <span>Luxury Car Tax</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LuxuryCarTax)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Local Stamp Duty</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LocalStampDuty)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Local Registration Costs</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LocalRegistrationCost)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Local CTP</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LocalCTP)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Local Plate Fee</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LocalPlateFee)}</span>
                    </div>
                    <div className='payment-row-item extra bold'>
                        <span>On Road Fees Subtotal</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.OnRoadFee)}</span>
                    </div>
                </div>
            );
        }
        else if (selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand) {
            return (
                <div className='payment-display-panel'>
                    <div className='payment-row-item'>
                        <span>Local Registration Costs</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.LocalRegistrationCost)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Local Plate Fee</span>
                        <span>{rdHelper.currencyFormatWithIncluded(driveAwayCost?.LocalPlateFee)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Tyre Stewardship Fee</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.TyreStewardshipFee)}</span>
                    </div>
                    <div className='payment-row-item'>
                        <span>Road User Charges</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.RoadUserChargers)}</span>
                    </div>
                    <div className='payment-row-item extra bold'>
                        <span>On Road Fees Subtotal</span>
                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.OnRoadFee)}</span>
                    </div>
                </div>
            );
        }

        return (<></>);
    }

    const optionsTemplate = (options: vehicleModel.IVehicleOptions[] | null | undefined, optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(options) && options != undefined && options.length > 0) {
            let selectedOptions = options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions.map((optionItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='details-desc' key={optionItem + "_" + index}>
                            <p>{optionItem?.OptionName}</p>
                            <span>{rdHelper.currencyFormatWithIncluded(optionItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<></>);
        }
        else {
            template.push(
                <div className='driveaway-price-card'>
                    <div className='details-subtitle'>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</div>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    const toPlenti = () => {
        window.open('https://www.plenti.com.au/compliance/');
    }




    setTimeout(() => {
        let stateDropdown = document.getElementById("state-dropdown-list");
        if (stateDropdown) {
            stateDropdown.setAttribute('data-dtm', 'modal:payment options');
            stateDropdown.classList.add('stat-text-link');
        }

        let regoTypeDropdown = document.getElementById("registration-type-dropdown-list");
        if (regoTypeDropdown) {
            regoTypeDropdown.setAttribute('data-dtm', 'modal:payment options');
            regoTypeDropdown.classList.add('stat-text-link');
        }

        let termDropdown = document.getElementById("terms-dropdown-list");
        if (termDropdown) {
            termDropdown.setAttribute('data-dtm', 'modal:payment options');
            termDropdown.classList.add('stat-text-link');
        }

        let balloonDropdown = document.getElementById("balloon-dropdown-list");
        if (balloonDropdown) {
            balloonDropdown.setAttribute('data-dtm', 'modal:payment options');
            balloonDropdown.classList.add('stat-text-link');
        }

    }, 200);

    const termsItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center stat-text-link" data-dtm="modal:payment options">
                <div>{option.Name}</div>
            </div>
        );
    };

    const balloonItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center stat-text-link" data-dtm="modal:payment options">
                <div>{option.Name}</div>
            </div>
        );
    };

    const stateItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center stat-text-link" data-dtm="modal:payment options">
                <div>{option.name}</div>
            </div>
        );
    }

    const regTypeItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center stat-text-link" data-dtm="modal:payment options">
                <div>{option.Text}</div>
            </div>
        );
    }

    return (
        <>
            <Dialog className='payment-options-dialog' position='bottom' visible={isOpen} draggable={false} resizable={true} onHide={() => onHide()}>
                <div className='payment-options-scrollbar-container'>
                    <div className='title-panel'>
                        <h1>Payment Options</h1>
                        {
                            paymentOption == rdEnum.enumPaymentOptions.Cash
                                ?
                                <></>
                                :
                                <p className='finance-desc'>Calculate estimated repayments on your favourite Cadillac using this tool. The final interest rate and additional product options will be confirmed with our Finance Partner after purchase.</p>
                        }
                    </div>
                    <div className='payment-options-btn-panel'>
                        <Button className={paymentOption == rdEnum.enumPaymentOptions.Cash ? 'stat-button-link payment-btn selected' : 'stat-button-link payment-btn'}
                            data-dtm={"modal:payment options"}
                            onClick={() => {
                                setPaymentOption(rdEnum.enumPaymentOptions.Cash)
                            }}
                        >
                            <span>{rdEnum.enumPaymentOptions.Cash}</span>
                        </Button>
                        <Button className={paymentOption == rdEnum.enumPaymentOptions.Finance ? 'stat-button-link payment-btn selected' : 'stat-button-link payment-btn'}
                            data-dtm={"modal:payment options"}
                            onClick={() => {
                                setPaymentOption(rdEnum.enumPaymentOptions.Finance)
                            }}
                        >
                            <span>{rdEnum.enumPaymentOptions.Finance}</span>
                        </Button>
                    </div>
                    {
                        paymentOption == rdEnum.enumPaymentOptions.Cash
                            ?
                            <div className='cash-container'>
                                <Row className='dropdown-container'>
                                    <Col lg={6} className='dropdown-panel' style={{ display: selectedLocationFromStore?.Country?.name == 'Australia' ? '' : 'none' }}>
                                        {/* <span>Registration / Territory</span> */}
                                        <Dropdown
                                            inputId="state-dropdown-list"
                                            value={selectedState}
                                            options={states}
                                            onChange={(e) => onStateChange(e)}
                                            className='global-location-dropdown'
                                            optionLabel="code"
                                            placeholder="State*"
                                            disabled
                                            itemTemplate={stateItemTemplate}
                                        />
                                        <label className={selectedState ? 'select-state-label selected' : 'select-state-label'}>State / Territory</label>
                                    </Col>
                                    <Col lg={selectedLocationFromStore?.Country?.name == 'Australia' ? 6 : 12} className='dropdown-panel'>
                                        {/* <span>Registration Type</span> */}
                                        <Dropdown
                                            inputId="registration-type-dropdown-list"
                                            value={registrationType}
                                            options={getRegistrationTypes()}
                                            onChange={(e) => setRegistrationType(e.value)}
                                            className='global-location-dropdown'
                                            optionLabel="Text"
                                            placeholder="Registration Type*"
                                            itemTemplate={regTypeItemTemplate}
                                        />
                                        <label className={registrationType ? 'registration-type-label selected' : 'registration-type-label'}>Registration Type</label>
                                    </Col>
                                </Row>

                                {showVehicleTemplate()}

                                {showTaxTemplate()}

                                <div className='driveaway-price-card' style={{borderBottom: '1px solid #ddd', paddingBottom: '1rem'}}>
                                    <span className='card-title'>Delivery option</span>
                                    <div className='details-desc'>
                                        <span>{selectedLocationFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup}</span>
                                        {selectedLocationFromStore?.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery && (
                                            <span className='price'>{rdHelper.currencyFormatWithBlank(driveAwayCost?.HomeDelivery)}</span>
                                        )}
                                    </div>
                                </div>

                                <div className='payment-display-panel'>
                                    <div className='payment-row-item bold'>
                                        <span>Driveaway Price<sup>1</sup></span>
                                        <span>{rdHelper.currencyFormatWithBlank(driveAwayCost?.DriveawayPrice)}</span>
                                    </div>
                                </div>
                                <div className='payment-bottom-info'>
                                    {/* <p>
                                        <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a 'rating one' driver aged 40 with a good driving record. Actual drive away price may differ depending on your individual circumstances. Price does not take into account any discount, rebate or promotion that may be available from time to time. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features.
                                    </p>
                                    <p>
                                        Vehicle registration includes 12 months' registration fees, 12 months' CTP insurance and any other associated charges in accordance with country, state and/or territory legislation. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to final prices, taxes etc. The website may vary from market to market and is subject to change without notice.
                                    </p> */}
                                    <p>
                                        <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. The drive away price displayed is inclusive of the vehicle purchase price, goods and services tax (GST), luxury car tax (LCT), stamp duty, compulsory third party insurance, registration, a plate fees and a GST-inclusive regional delivery fee if applicable. The final drive away price may differ depending on your individual circumstances. The price does not take into account any valid discount, rebate or promotion that may be available from time to time. Each country, state or territory may offer additional registration types which are not covered in this configurator, therefore your individual situation may vary and have an impact on the final Drive Away price. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. The final Drive Away price will be provided to you upon checkout.
                                    </p>
                                    {/* <p>
                                        <sup>3</sup> Availability is an estimate only and subject to change without notice. More accurate timing will be provided upon order and closer to the time of delivery.
                                    </p> */}
                                </div>
                            </div>
                            :
                            paymentOption == rdEnum.enumPaymentOptions.Finance
                                ?
                                <div className='finance-container'>
                                    <Row className='dropdown-container'>
                                        <Col lg={6} className='dropdown-panel'>
                                            <div className='input-item' style={{ margin: '0' }}>
                                                <div className="p-input-icon-left wrapper-box">
                                                    <i>$</i>
                                                    <InputText
                                                        value={financePageState.Deposit}
                                                        onChange={(e) => {
                                                            setFinancePageState({
                                                                ...financePageState,
                                                                Deposit: parseInt(e.target.value)
                                                            });
                                                        }}
                                                        keyfilter={"int"}
                                                        disabled={true}
                                                    />
                                                    <label>Deposit</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} className='dropdown-panel' >
                                            <Dropdown
                                                inputId="terms-dropdown-list"
                                                value={selectedTermOptions}
                                                options={termOptions}
                                                onChange={(e: any) => {
                                                    setSelectedTermOptions(e.value);
                                                }}
                                                className='global-location-dropdown'
                                                optionLabel="Name"
                                                itemTemplate={termsItemTemplate}
                                            />
                                            <label className={!isNil(selectedTermOptions) ? 'term-label selected' : 'term-label'}>Term</label>
                                        </Col>
                                    </Row>
                                    {selectedLocationFromStore.Country?.code == rdEnum.enumCountryCode.Australia ? (
                                        <Row className='dropdown-container'>
                                            <Col lg={3} className='dropdown-panel'>
                                                <div className='input-item'>
                                                    <div className="wrapper-box disabled">
                                                        <InputText
                                                            value={financePageState.InterestRate}
                                                            disabled
                                                        />
                                                        <label>interest rate from</label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} className='dropdown-panel'>
                                                <div className='input-item'>
                                                    <div className="wrapper-box disabled">
                                                        <InputText
                                                            value={financePageState.ComparisonRate}
                                                            disabled
                                                        />
                                                        <label style={{minWidth: '200px'}}>p.a comparison rate from<sup>2</sup></label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} className='dropdown-panel'>
                                                <div className='input-item' style={{ margin: '0' }}>
                                                    <div className="wrapper-box disabled">
                                                        {/*<i>$</i>*/}
                                                        <InputText
                                                            value={financePageState.FinanceAmount}
                                                            disabled={true}
                                                            onChange={(e) => {
                                                                // setDownPayment(e.target.value)
                                                            }}
                                                        />
                                                        <label>Financed Amount</label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} className='dropdown-panel'>
                                                <Dropdown
                                                    inputId="balloon-dropdown-list"
                                                    value={selectedBalloonPaymentOptions}
                                                    options={balloonPaymentOptions}
                                                    onChange={(e: any) => {
                                                        setSelectedBalloonPaymentOptions(e.value);
                                                        //setFinancePageState({
                                                        //    ...financePageState,
                                                        //    SelectedBalloonPaymentOptions: e.value
                                                        //})
                                                    }}
                                                    className='global-location-dropdown'
                                                    optionLabel="Name"
                                                    itemTemplate={balloonItemTemplate}
                                                // placeholder="Final Balloon Payment*"
                                                />
                                                <label className={!isNil(selectedBalloonPaymentOptions) ? 'balloon-payment-label selected' : 'balloon-payment-label'}>Final Balloon Payment</label>
                                                <span>{financePageState.BallonAmount} balloon payment</span>
                                            </Col>
                                        </Row>
                                    )
                                        :
                                        <React.Fragment>
                                            <Row className='dropdown-container'>
                                                <Col lg={6} className='dropdown-panel'>
                                                    <Dropdown
                                                        inputId="balloon-dropdown-list"
                                                        value={selectedBalloonPaymentOptions}
                                                        options={balloonPaymentOptions}
                                                        onChange={(e: any) => {
                                                            setSelectedBalloonPaymentOptions(e.value);
                                                        }}
                                                        className='global-location-dropdown'
                                                        optionLabel="Name"
                                                        itemTemplate={balloonItemTemplate}
                                                    />
                                                    <label className={!isNil(selectedBalloonPaymentOptions) ? 'balloon-payment-label selected' : 'balloon-payment-label'}>Final Balloon Payment</label>
                                                    <span>{financePageState.BallonAmount} balloon payment</span>
                                                </Col>
                                                <Col lg={6} className='dropdown-panel'>
                                                    <Dropdown
                                                        value={selectedFrequencyOption}
                                                        options={frequencyOptions}
                                                        onChange={(e: any) => {
                                                            setSelectedFrequencyOptions(e.value);
                                                        }}
                                                        className='global-location-dropdown'
                                                        optionLabel="Name"
                                                    />
                                                    <label className={!isNil(selectedFrequencyOption) ? 'payment-frequency-label selected' : 'payment-frequency-label'}>Payment Frequency</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} className="nz-note-wrapper">
                                                    <div style={{ fontSize: "12px" }}>Financed Amount</div>
                                                    <div>{financePageState.FinanceAmount}</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} className="nz-note-wrapper">
                                                    <div>This is a representative example based on a {financePageState.SelectedTermOptions?.Name} term at an interest rate of {financePageState.InterestRate}.
                                                    Payments are calculated and payable {financePageState.FreqencyName}ly under a Credit Agreement at {financePageState.LoanPaymentText} per {financePageState.FreqencyName} with a final payment of {financePageState.BallonAmount}.
                                                        Total amount repayable is {financePageState.TotalLoanPayment}. Payments include on road costs, an establishment fee of {financePageState.CreditAssistanceFee}, a monthly maintenance free of {financePageState.MonthlyAccountFee} and a PPSR fee of {financePageState.PPSR}.</div>
                                                    <br />
                                                    <div>Interest rates vary from {financePageState.InterestRate} to {financePageState.InterestRateTo} and are subject to credit criteria and affordability assessment</div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    }



                                    <p>
                                        Scroll down for important finance information
                                        </p>

                                    <div className='payment-display-panel'>
                                        <div className='payment-row-item bold'>
                                            {selectedLocationFromStore.Country?.code == rdEnum.enumCountryCode.Australia ?
                                                <span>Credit Assistance Fee</span>
                                                : <span>Loan Establishment Fee</span>
                                            }
                                            <span>{financePageState.CreditAssistanceFee}</span>
                                        </div>
                                        <Accordion defaultActiveKey="5" activeKey={financeActiveEventKey} onSelect={handleFinanceSelect}>
                                            <Card>
                                                <Accordion.Collapse eventKey="5">
                                                    <Card.Body>
                                                        {showVehicleTemplate()}
                                                        {showTaxTemplate()}

                                                        <div className='driveaway-price-card' style={{borderBottom: '1px solid #ddd', paddingBottom: '1rem'}}>
                                                            <span className='card-title'>Delivery option</span>
                                                            <div className='details-desc'>
                                                                <span>{selectedLocationFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup}</span>
                                                                {selectedLocationFromStore?.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery && (
                                                                    <span className='price'>{rdHelper.currencyFormatWithBlank(driveAwayCost?.HomeDelivery)}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                                <Card.Header>
                                                    <CustomFinanceToggle eventKey="5" activeEventKey={financeActiveEventKey}>
                                                        Driveaway Cost
                                                            <img alt=''
                                                            data-dtm={"modal:payment options"}
                                                            className={financeActiveEventKey ? 'stat-expand-icon image' : 'stat-expand-icon image active'}
                                                            src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                                                    </CustomFinanceToggle>
                                                </Card.Header>
                                            </Card>
                                        </Accordion>
                                        <div className='payment-row-item bold'>
                                            <span>Loan Payment (inc. Home Delivery)</span>
                                            <span>{financePageState.LoanPaymentText} /{financePageState.FreqencyName}<sup>2</sup></span>
                                        </div>
                                        <div className='payment-row-item bold'>
                                            <span>Estimated Delivery</span>
                                            <span>{isFromLandingPage ? estDeliveryDate : vehFromStore.SelectedInterior?.Available}</span>
                                        </div>
                                    </div>

                                    {/* {
                                        selectedLocationFromStore?.Country?.name == 'Australia'
                                            ?
                                            <div className='payment-bottom-info'>
                                                <p>
                                                    <sup>1</sup> This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. Actual drive away price may differ depending on your individual circumstances. Price does not take into account any discount, rebate or promotion that may be available from time to time. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features.
                                                    <br />
                                                    Vehicle registration includes 12 months’ registration fees, 12 months’ CTP insurance and any other associated charges in accordance with country, state and/or territory legislation. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to final prices, taxes etc. The website may vary from market to market and is subject to change without notice
                                                </p>
                                                <p>
                                                    Finance provided to approved applicants by Plenti Finance Pty Limited ABN 82 636 759 861.
                                                </p>
                                                <p>
                                                    <sup>2</sup> The comparison rate is based on a secured car loan of $30,000 repaid over 60 months at an interest rate of 1.99% p.a.. WARNING: This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Finance provided to approved applicants by Plenti Finance Pty Limited ABN 82 636 759 861. <br />Terms, conditions and fees apply to any loan. Any calculations made by this calculator are dependent on the accuracy of the information provided, are intended to be used as a guide only, must not be relied on for any reason as conclusive, and do not constitute a quote. Your application will be subject to a credit approval process and lending criteria. Cadillac will not store the information provided in this calculator. Cadillac and their finance partner do not accept any responsibility for any loss, costs, or liability directly or indirectly incurred by persons who rely on any amount calculated by this calculator.
                                                    <br />
                                                    Order and apply for finance on an eligible Cadillac vehicle by <span style={{ color: 'red' }}>31 August 2024 and take delivery by 30 September 2024</span> to qualify. Valid only for eligible purchases of new Cadillac configurations for finance loan terms up to 5 years. Not eligible with balloon repayments. Credit subject to financier's approval. Terms, conditions, fees and charges apply Interest rates are subject to change. This information does not constitute financial advice and you should consider whether it is appropriate to your circumstances before you act in reliance on it. For detailed advice and to discuss finance options tailored to your needs to determine the best finance option for your circumstances, we recommend speaking with a licensed independent financial advisor.
                                                </p>
                                                <p>
                                                    [Placeholder for Plenti Disclaimer – waiting on final version]
                                                </p>
                                                <p>
                                                    <sup>3</sup> Availability is an estimate only and subject to change without notice. More accurate timing will be provided upon order and closer to the time of delivery.
                                                </p>
                                            </div>
                                            :
                                            <div className='payment-bottom-info'>
                                                <p>
                                                    <sup>1</sup> This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. Actual drive away price may differ depending on your individual circumstances. Price does not take into account any discount, rebate or promotion that may be available from time to time. 
                                                </p>
                                                <p>
                                                    Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. Vehicle registration includes 12 months’ registration fees, 12 months’ CTP insurance and any other associated charges in accordance with country, state and/or territory legislation. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to final prices, taxes etc. The website may vary from market to market and is subject to change without notice.
                                                </p>
                                                <p>
                                                    Cadillac will not store the information provided in this calculator.
                                                </p>
                                                <p>
                                                    Finance is provided by MTF Finance subject to its approval process, lending criteria and checks. Terms, conditions and fees apply to any offer of finance. Any personal information we collect about you may be shared with MTF Finance and will be treated in accordance with <a onClick={() => toPrivacy()}>MTF Finance’s privacy statement</a>.
                                                </p>
                                                <p>
                                                    Calculator information is for illustrative purposes only and must not be relied upon for any reason. The information on this page is not financial advice or an offer of finance. Interest rates are subject to change. Your interest rate may be lower or higher depending on your credit history and other factors. For more information see <a onClick={() => toRatesFees()}>rates, fees and charges</a> to understand what borrowing may cost you. We recommend that you seek independent financial advice for your unique circumstances. Cadillac and MTF Finance do not accept any responsibility for any direct or indirect loss, cost, or liability incurred by persons who rely on any calculator information.
                                                </p>
                                                <p>
                                                    <sup>3</sup> Availability is an estimate only and subject to change without notice. More accurate timing will be provided upon order and closer to the time of delivery.
                                                </p>
                                            </div>
                                    } */}

                                    <div className='payment-bottom-info'>
                                        <p>
                                            {/* <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. Actual drive away price may differ depending on your individual circumstances. Price does not take into account any discount, rebate or promotion that may be available from time to time. Each country, state or territory may offer additional registration types which are not covered in this configurator, therefore your individual situation may vary and have an impact on the final Drive Away price. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. */}
                                            <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. The drive away price displayed is inclusive of the vehicle purchase price, goods and services tax (GST), luxury car tax (LCT), stamp duty, compulsory third party insurance, registration, a plate fees and a GST-inclusive regional delivery fee if applicable. The final drive away price may differ depending on your individual circumstances. The price does not take into account any valid discount, rebate or promotion that may be available from time to time. Each country, state or territory may offer additional registration types which are not covered in this configurator, therefore your individual situation may vary and have an impact on the final Drive Away price. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. The final Drive Away price will be provided to you upon checkout. 
                                        </p>
                                        {/* <p>
                                            <sup>2</sup>This monthly repayment has been estimated using a calculator developed by Cadillac in partnership with finance partners referenced below as a guide only. The repayment is indicative and intended for illustrative purposes only. Cadillac will not store the information provided in this calculator. This repayment estimate should not be considered a loan application or an offer of finance. This repayment estimate does not take into account your personal circumstances or any fees and charges or government taxes which may apply.

                                        </p> */}
                                        {
                                            selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                                            ?
                                            <>
                                                <p>
                                                    <sup>2</sup>Comparison rate is based on a secured car loan of $30,000 repaid over 60 months, no balloon, and interest rate of 6.79%. Rates assume a customer with an excellent credit history. Rates shown are current as at today’s date. Terms, conditions, and credit criteria apply. Fees and charges apply. Interest rates are subject to change. <b>WARNING:</b> This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. 
                                                </p>
                                                <p>
                                                    Terms, conditions and fees apply to any loan. Any calculations made by this calculator are dependent on the accuracy of the information provided, are intended to be used as a guide only, must not be relied on for any reason as conclusive, and do not constitute a quote or an offer of credit. Your application will be subject to a credit approval process and lending criteria. Cadillac will not store the information provided in this calculator.  Cadillac and their finance partner do not accept any responsibility for any loss, costs, or liability directly or indirectly incurred by persons who rely on any amount calculated by this calculator. 
                                                </p>
                                                <p>
                                                    Credit subject to financier's approval. Terms, conditions, fees and charges apply. Rates, repayments and fees are estimates and are subject to change at any time. Credit provided by Plenti Finance Pty Limited ACN 636 759 861 (supported by its servicer, Plenti RE Limited ACN 166 646 635). Plenti RE Limited holds Australian Financial Services Licence number 449176 and Australian Credit Licence number 449176.
                                                </p>
                                                <p>
                                                    This information does not constitute financial advice and you should consider whether it is appropriate to your circumstances before you act in reliance on it. For detailed advice and to discuss finance options tailored to your needs to determine the best finance option for your circumstances, we recommend speaking with a licensed independent financial advisor. Plenti's Target Market Determination is available at <a style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => toPlenti()}>www.plenti.com.au/compliance</a>.
                                                </p>
                                            </>
                                            
                                            :
                                            <p>
                                                <sup>2</sup>Finance is provided by MTF Finance subject to its approval process, lending criteria and checks. Terms, conditions and fees apply to any offer of finance. Any personal information we collect about you may be shared with MTF Finance and will be treated in accordance with MTF Finance’s privacy statement. Calculator information is for illustrative purposes only and must not be relied upon for any reason. The information on this page is not financial advice or an offer of finance. Interest rates are subject to change. Your interest rate may be lower or higher depending on your credit history and other factors. For more information see rates, fees and charges to understand what borrowing may cost you. We recommend that you seek independent financial advice for your unique circumstances. Cadillac and MTF Finance do not accept any responsibility for any direct or indirect loss, cost, or liability incurred by persons who rely on any calculator information.
                                            </p>
                                            // <p>
                                            //     [Placeholder for Plenti Disclaimer – waiting on final version]
                                            // </p>
                                            // :
                                            // <p>
                                            //     Finance is provided by MTF Finance subject to its approval process, lending criteria and checks. Terms, conditions and fees apply to any offer of finance. Any personal information we collect about you may be shared with MTF Finance and will be treated in accordance with <a onClick={() => toPrivacy()}>MTF Finance’s privacy statement</a>. Calculator information is for illustrative purposes only and must not be relied upon for any reason. The information on this page is not financial advice or an offer of finance. Interest rates are subject to change. Your interest rate may be lower or higher depending on your credit history and other factors. For more information see <a onClick={() => toRatesFees()}>rates, fees and charges</a> to understand what borrowing may cost you. We recommend that you seek independent financial advice for your unique circumstances. Cadillac and MTF Finance do not accept any responsibility for any direct or indirect loss, cost, or liability incurred by persons who rely on any calculator information.
                                            // </p>
                                        }
                                        {/* <p>
                                            <sup>3</sup>Availability and delivery dates are estimates only. Many factors impact vehicle delivery. Cadillac reserves the right to make changes to availability and delivery dates displayed throughout this configurator without notice.
                                        </p>
                                        <p>
                                            `The information presented in this configurator is based on data available at the time. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to design, specifications, final prices, taxes, delivery dates etc. The website may vary from market to market and is subject to change without notice.
                                        </p>
                                        <p>
                                            Vehicle shown on this page is a simulated overseas model. Actual production vehicles will vary. Vehicles shown here after are simulated local production vehicles intended for illustrative purposes. Cadillac has taken reasonable steps to ensure your digital configuration replicates the vehicle you receive in real life. Colours and other features may appear different in real life.
                                        </p> */}
                                    </div>

                                </div>
                                :
                                <></>
                    }
                </div>
            </Dialog>
        </>
    )
})

export default PaymentOptions