import * as variantModel from 'model/IVehicle';


export const getInitVehicleStore = (): variantModel.IVehicleStore => {
    let init: variantModel.IVehicleStore = {
        //Loading: false,
        VariantList: [],
        SelectedVariant: null,
        ExteriorList: [],
        SelectedExterior: null,
        InteriorList: null,
        SelectedInterior: null,
        Accessories: [],
        Charging: [],
    };

    return init;
}


export const getInitExterior = (): variantModel.IExterior => {
    let init: variantModel.IExterior = {
        Country: null,
        VariantID: null,
        VariantName: null,
        ExteriorID: null,
        ExteriorColorName: null,
        ExteriorColorDescTitle: null,
        ExteriorColorDesc: null,
        ExteriorPrice: null,
        ExteriorPicture: [],
        ExteriorPictureMobile: [],
        ExteriorFilterImage: null,
        ExteriorFilterMobile: null,
        IsDefault: null,
        HasStock: null,
        ExteriorOrderingNo: 0,
    };

    return init;
}