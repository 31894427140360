import React from 'react'

const PrivacyPolicyLink = ({props}: any) => {
    return (
        <span
            className="stat-text-link"
            data-dtm="privacy statement"
            onClick={()=> window.open("https://www.cadillacanz.com/au-en/privacy", "_blank")}
            style={{color: '#3A3A3A', textDecoration: 'underline', cursor: 'pointer'}}
        >
            {props}
        </span>
    )
}

export default PrivacyPolicyLink