import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, update } from 'lodash';
import IsNumberVerification from 'components/IsNumberVerification';
import { getCMSAssets } from 'helpers/Uri';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';


import { IAppState } from 'model/IAppState';

import * as rdEnum from 'helpers/enum';
import * as rdHelper from 'helpers/Uri';
import * as checkoutHelper from 'businessHelpers/CheckoutHelper';
import * as selectedDeliveryStore from 'stores/UserSelectedDelivery';
import * as paymentOptionsStore from 'stores/PaymentOptions';

import * as deliveryModel from 'model/IDelivery';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as paymentOptionsModel from 'model/IPaymentOptions';
import * as locationService from 'services/Locations';
import * as paymentOptionsService from 'services/PaymentOptions';

/*import { getAddress } from 'services/GoogleApi';*/

///update store's data once delivery selection changed as driveaway cost need to be updated as well.
const Delivery = forwardRef(({ handleDelivery }: any, ref: any) => {
    const dispatch = useDispatch();
    //store variables
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const customerTypeFromStore = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const abortControllerInitialValue: any = new (
        window as any
    ).AbortController();
    const [costAbortController, setCostAbortController] = useState(
        abortControllerInitialValue,
    );

    //state
    const [addressList, setAddressList] = useState<any>([]);            //address dropdown list
    //selected primary address
    const [primaryAddress, setPrimaryAddressAddress] = useState<any>(null);
    const [primaryAddressAvailabeDetails, setPrimaryAddressAvailabeDetails] = useState<deliveryModel.IDeliveryAddressAvailableDetails>(checkoutHelper.getInitDeliveryAddressAvailableDetails());
    //selected different delivery address
    const [differentDeliveryAddress, setDifferentDeliveryAddress] = useState<any>(null);
    const [differentDeliveryAddressAvailabeDetails, setDifferentDeliveryAddressAvailabeDetails] = useState<deliveryModel.IDeliveryAddressAvailableDetails>(checkoutHelper.getInitDeliveryAddressAvailableDetails());

    //pickup locations
    const [pickupLocations, setPickupLocations] = useState<deliveryModel.IPickupLocation[] | null>(null);



    const [pageState, setPageState] = useState<deliveryModel.IDelivery>(checkoutHelper.getDeliveryInitState());   //not in use anymore
    const [pageInvalidState, setPageInvalidState] = useState<deliveryModel.IInvalidDelivery>(checkoutHelper.getInitInvalidDelivery());
    const [pickupLocationDataLoadingStatus, setPickupLocationDataLoadingStatus] = useState(rdEnum.enumProcessingStatus.Init);


    //const [errorMessage, setErrorMessage] = useState<any>(null);


    const inputRef = useRef<HTMLInputElement>(null);
    const regionalInputRef = useRef<HTMLInputElement>(null);
    const locationCheckboxRef = useRef<HTMLInputElement>(null);
    const differentAddressCheckboxRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isRegoFocused, setIsRegoFocused] = useState(false);

    useEffect(() => {
        setPageState({
            ...pageState,
            Country: selectedDeliveryFromStore?.Country?.code || null,
            State: selectedDeliveryFromStore?.State?.code || null
        });

    }, [selectedDeliveryFromStore]);

    const validationDelivery = (): boolean => {

        let invalidDelivery = false;
        let invalidDeliveryOption = false;
        let invalidPrimaryAddress = false;
        let invalidPrimaryState = false;
        let invalidPickupLocation = false;
        let invalidDeliveryAddress = false;
        let invalidDifferentState = false;

        if (isNullAddress(primaryAddress)) {
            invalidPrimaryAddress = true;
            invalidDelivery = true;
        }
        else {
            if (pageState.Country == rdEnum.enumCountryCode.Australia) {
                if (primaryAddress.state !== pageState?.State) {
                    invalidPrimaryState = true;
                    invalidDelivery = true;
                }
            }
        }


        if (isNil(pageState?.DeliveryOption) || pageState?.DeliveryOption == "") {
            invalidDeliveryOption = true;
            invalidDelivery = true;
        }

        if (pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery) {

            if (pageState.DifferentDeliveryAddress) {
                if (isNullAddress(differentDeliveryAddress)) {
                    invalidDeliveryAddress = true;
                    invalidDelivery = true;
                }
                else {
                    if (pageState.Country == rdEnum.enumCountryCode.Australia) {
                        if (primaryAddress?.state != differentDeliveryAddress?.state) {
                            invalidDifferentState = true;
                            invalidDelivery = true;
                        }
                    }
                }
            }
        }
        else if (pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            if (!pageState?.PickupAddressConfirm) {
                invalidPickupLocation = true;
                invalidDelivery = true;
            }
        }


        setPageInvalidState({
            ...pageInvalidState,
            InvalidPrimaryAddress: invalidPrimaryAddress,
            InvalidPrimaryState: invalidPrimaryState,
            InvalidDeliveryType: invalidDeliveryOption,
            InvalidPickupLocation: invalidPickupLocation,
            InvalidDeliveryAddress: invalidDeliveryAddress,
            InvalidDeliveryState: invalidDifferentState,
        });

        if (pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery) {
            if (!pageState.DifferentDeliveryAddress) {
                //comment below code if delivery fee has error, still able to process checkout
                //if (!isNullAddress(primaryAddress)
                //    && !isNil(primaryAddressAvailabeDetails.DeliveryPriceError) && primaryAddressAvailabeDetails.DeliveryPriceError != "") {
                //    invalidDelivery = true;
                //}
            }
            else {
                //different address
                if (!isNullAddress(differentDeliveryAddress)) {
                    if (!differentDeliveryAddressAvailabeDetails.DeliveryAvailable) {
                        const deliveryError = document.getElementsByClassName("delivery-error");
                        if (deliveryError != null && deliveryError.length > 0 && deliveryError[0] != null) {
                            deliveryError[0].innerHTML = "The address you've entered is not eligible for regional delivery. It falls within the minimum distance required from an Experience Centre. Please select In-store collection or enter a different address";
                        }
                        invalidDelivery = true;
                    }

                    //comment below code if delivery fee has error, still able to process checkout
                    //if (!isNil(differentDeliveryAddressAvailabeDetails.DeliveryPriceError) && differentDeliveryAddressAvailabeDetails.DeliveryPriceError != "") {
                    //    invalidDelivery = true;
                    //}
                }
            }
        }

        return invalidDelivery;
    }

    const isNullAddress = (address: any) => {
        if (!isNil(address) && !isNil(address.fullAddress) && address.fullAddress != "")
            return false;
        return true;
    }

    const showDeliveryAddressError = () => {

        if (!isNullAddress(primaryAddress)
            && !isNullAddress(differentDeliveryAddress)
            && pageState.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery
            && pageState.DifferentDeliveryAddress
            && pageInvalidState?.InvalidDeliveryState) {
            return (<div className="text-error delivery-error" style={{ marginTop: "10px", marginBottom: "5px" }}>Different State from primary address is not allowed. Please try again or select In-store Collection.</div>);
        }


        if (pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery) {
            if (!pageState.DifferentDeliveryAddress) {
                if (!isNullAddress(primaryAddress)) {
                    let error = rdEnum.enumDeliveryPriceError.find(m => m.ErrorCode == primaryAddressAvailabeDetails.DeliveryPriceError);
                    if (error != null) {
                        return (<div className="text-error delivery-error" style={{ marginTop: "10px", marginBottom: "5px" }}>{error.ErrorMessage}</div>);
                    }
                }
            }
            else {

                //different address
                if (!isNullAddress(differentDeliveryAddress) && !isNil(differentDeliveryAddressAvailabeDetails.DeliveryAvailable)) {
                    if (!differentDeliveryAddressAvailabeDetails.DeliveryAvailable) {
                        return (<div className="text-error delivery-error" style={{ marginTop: "10px", marginBottom: "5px" }}>Your Delivery Address is not eligible for Regional Delivery.</div>);
                    }
                    else {
                        let error = rdEnum.enumDeliveryPriceError.find(m => m.ErrorCode == differentDeliveryAddressAvailabeDetails.DeliveryPriceError);
                        if (error != null) {
                            return (<div className="text-error delivery-error" style={{ marginTop: "10px", marginBottom: "5px" }}>{error.ErrorMessage}</div>);
                        }
                    }

                }
                else {
                    return <></>;
                }
            }
        }

        return <></>;
    }

    useImperativeHandle(ref, () => ({

        getValidate: () => {
            return validationDelivery();
        },

        dispatchRegoAddress: () => {
            //dispatch(selectedDeliveryStore.updateRegoAddressAction(
            //    pageState.PrimaryAddress,
            //    pageState.PrimarySuburb,
            //    pageState.PrimaryPostcode,
            //    pageState.PrimaryState));
        }

    }));


    const handleDeliveryType = async (type: string) => {
        setPageState({
            ...pageState,
            DeliveryOption: type
        });
        handleDelivery(type)

        setPageInvalidState({
            ...pageInvalidState,
            InvalidDeliveryType: false,
        });


        let updatedDelivery: selectedConfigModel.ISelectedDelivery = {
            ...selectedDeliveryFromStore,
            SelectedDeliveryOption: type,
        };

        //update delivery location in store
        if (type == rdEnum.enumDeliveryOptions.HomeDelivery) {

            //Regional Delivery
            if (pageState?.DifferentDeliveryAddress) {
                //Regional Delivery and different address
                updatedDelivery = {
                    ...updatedDelivery,
                    SelectedDeliveryFullAddress: differentDeliveryAddress?.fullAddress,
                    SelectedDeliveryPostcode: differentDeliveryAddress?.postcode,
                    SelectedDeliverySuburb: differentDeliveryAddress?.suburb,
                    SelectedDeliveryState: differentDeliveryAddress?.state,
                    DeliveryPrice: differentDeliveryAddressAvailabeDetails.DeliveryPrice || 0,
                    DeliveryDistance: differentDeliveryAddressAvailabeDetails.Distance,
                    PickupLocationName: null,
                    //DeliveryAvailable: differentDeliveryAddressAvailable,   //todo: show error
                    //DeliveryPriceErrorCode: differentDeliveryAddressErrorCode || null
                };
            }
            else {
                //Regional Delivery and primary address
                updatedDelivery = {
                    ...updatedDelivery,
                    SelectedDeliveryFullAddress: primaryAddress?.fullAddress,
                    SelectedDeliveryPostcode: primaryAddress?.postcode,
                    SelectedDeliverySuburb: primaryAddress?.suburb,
                    SelectedDeliveryState: primaryAddress?.state,
                    DeliveryDistance: primaryAddressAvailabeDetails.Distance || 0, //selectedPickuplocation?.Distance,
                    DeliveryPrice: primaryAddressAvailabeDetails.DeliveryPrice, // selectedPickuplocation?.DeliveryPrice,
                    PickupLocationName: null,
                    //DeliveryAvailable: availabe,
                    //DeliveryPriceErrorCode: errorCode,
                };

            }
        }
        else {
            if (!isNil(pickupLocations)) {
                let selectedPickuplocation = pickupLocations.find(m => m.IsSelected);
                if (!isNil(selectedPickuplocation)) {
                    //if pickup, set as pickup location address as delivery address
                    updatedDelivery = {
                        ...updatedDelivery,
                        SelectedDeliveryFullAddress: selectedPickuplocation?.LocationAddress,
                        SelectedDeliveryPostcode: selectedPickuplocation?.LocationPostcode,
                        SelectedDeliverySuburb: selectedPickuplocation?.LocationSuburb,
                        SelectedDeliveryState: selectedPickuplocation?.LocationState,
                        DeliveryDistance: 0, //selectedPickuplocation?.Distance,
                        DeliveryPrice: 0, // selectedPickuplocation?.DeliveryPrice,
                        PickupLocationName: selectedPickuplocation?.LocationName,
                        //DeliveryAvailable: selectedPickuplocation?.DeliveryAvailable,
                        //DeliveryPriceErrorCode: selectedPickuplocation?.DeliveryErrorCode,
                    };
                }
            }
        }
        dispatch(selectedDeliveryStore.updateDeliveryAction(updatedDelivery));

    }

    //get address dropdown items
    const doGetAddress = async (event: any) => {

        let keywords = event.query;

        if (keywords.length > 4) {
            let result = await locationService.getAddress(
                selectedDeliveryFromStore?.Country?.code || rdEnum.enumCountryCode.Australia,
                keywords,
            );
            if (result.status) {
                setAddressList(result.data);
            }
        }
    }

    const onAddressChange = (e: any) => {
        let invalidPrimaryState = false;
        if (pageState.Country == rdEnum.enumCountryCode.Australia) {
            if (!isNil(e.value) && !isNil(e.value.state) && pageState.State != e.value.state) {
                invalidPrimaryState = true;
            }
        }

        setPageInvalidState({
            ...pageInvalidState,
            InvalidPrimaryAddress: false,
            InvalidPrimaryState: invalidPrimaryState
        })
        setPrimaryAddressAddress(e.value);   //will trigger in useEffect to get locations and available details


        //let type = rdEnum.enumDeliveryOptions.Pickup;   //default selected as pick up
        //setPageState({
        //    ...pageState,
        //    DeliveryOption: type,
        //});

    }

    //enter different delivery address
    const onDeliveryAddressChange = async (e: any) => {
        setPageInvalidState({
            ...pageInvalidState,
            InvalidDeliveryAddress: isNil(e.value),
            InvalidDeliveryState: !isNil(e.value) && !isNil(e.value.state) && primaryAddress.state != e.value.state  //Customers is not allowed to select a delivery address that is not in the same state as their registration address
        });

        setDifferentDeliveryAddress(e.value);
    }


    //primary address change, call get pick up locations and primary address aviablable details
    useEffect(() => {
        if (isNil(primaryAddress) || isNil(primaryAddress.fullAddress) || primaryAddress.fullAddress == ""
            || pageInvalidState.InvalidPrimaryState
        ) {
            clearPrimaryState();
            return;
        }
        else {
            doGetLocations(primaryAddress.postcode || "",
                primaryAddress?.suburb || "",
                primaryAddress?.state || "",
                primaryAddress.fullAddress || "");
        }


    }, [primaryAddress,
        selectedVehicleFromStore.VariantName,
        selectedVehicleFromStore?.Exterior,
        selectedVehicleFromStore?.Interior,
        selectedVehicleFromStore?.Subtotal,
        customerTypeFromStore
    ]);

    //different address change, get different address aviablable details
    useEffect(() => {

        if (isNil(differentDeliveryAddress) || isNil(differentDeliveryAddress.fullAddress) || differentDeliveryAddress.fullAddress == "") {
            setDifferentDeliveryAddressAvailabeDetails(checkoutHelper.getInitDeliveryAddressAvailableDetails());
            return;
        }
        if (pageState.DifferentDeliveryAddress && !isNil(pickupLocations) && pickupLocations.length > 0
            && !isNil(differentDeliveryAddress) && !isNil(differentDeliveryAddress.fullAddress) && differentDeliveryAddress.fullAddress != ""
        ) {
            doGetDeliveryPrice();
        }
        else {
            setDifferentDeliveryAddress(null);
            setDifferentDeliveryAddressAvailabeDetails(checkoutHelper.getInitDeliveryAddressAvailableDetails());

            //let updateSelectedDelivery: selectedConfigModel.ISelectedDelivery = {
            //    ...selectedDeliveryFromStore,
            //    SelectedDeliveryOption: null,
            //    SelectedDeliveryFullAddress: null,
            //    SelectedDeliveryPostcode: null,
            //    SelectedDeliveryState: null,
            //    SelectedDeliverySuburb: null,
            //}
            //dispatch(selectedDeliveryStore.updateDeliveryAction(updateSelectedDelivery));
        }

    }, [differentDeliveryAddress,
        selectedVehicleFromStore.VariantName,
        selectedVehicleFromStore?.Exterior,
        selectedVehicleFromStore?.Interior,
        selectedVehicleFromStore?.Subtotal,
        customerTypeFromStore
    ]);


    //useEffect(() => {
    //    dispatch(driveawayCostDataVersionStore.setDriveawayDataVersionAction());

    //}, [selectedDeliveryFromStore?.PrimaryAddress, selectedDeliveryFromStore?.SelectedDeliveryFullAddress])



    useEffect(() => {
        if (!isNil(inputRef.current)) {
            inputRef.current.setAttribute("autocomplete", "new");
            inputRef.current.setAttribute('data-dtm', 'checkout:taking delivery');
        }
    }, [inputRef]);

    useEffect(() => {
        if (!isNil(regionalInputRef.current)) {
            regionalInputRef.current.setAttribute("autocomplete", "new");
        }
    }, [regionalInputRef, pageState.DifferentDeliveryAddress]);

    useEffect(() => {
        if (locationCheckboxRef.current) {
            locationCheckboxRef.current.setAttribute('data-dtm', 'checkout:taking delivery');
            locationCheckboxRef.current.classList.add('stat-checkbox');
        }
    }, [locationCheckboxRef.current]);

    useEffect(() => {
        if (differentAddressCheckboxRef.current) {
            differentAddressCheckboxRef.current.setAttribute('data-dtm', 'checkout:taking delivery');
            differentAddressCheckboxRef.current.classList.add('stat-checkbox');
        }
    }, [differentAddressCheckboxRef.current]);


    //must be triggered by userEffect as variant,exterior,interior,vehicle subtotal price from store
    const doGetLocations = async (postcode: string, suburb: string, state: string, address: string) => {
        if (isNil(pageState.Country) || pageState.Country == "") {
            return;
        }

        let variantName = selectedVehicleFromStore?.VariantName || "";
        let exteriorName = selectedVehicleFromStore?.Exterior || "";
        let interiorName = selectedVehicleFromStore?.Interior || "";

        let request: deliveryModel.IGetPickupLocationRequest = {
            Country: pageState.Country,
            State: pageState.State || "",
            PrimaryPostcode: postcode,
            PrimarySuburb: suburb,
            PrimaryState: state,
            PrimaryAddress: address,
            VariantName: variantName,
            ExteriorName: exteriorName,
            InteriorName: interiorName,
            CustomerType: customerTypeFromStore,
            VehicleSubTotal: selectedVehicleFromStore.Subtotal || 0
        };

        setPickupLocationDataLoadingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await locationService.getLocations(request);
        setPickupLocationDataLoadingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            let locatiions: deliveryModel.IPickupLocation[] = [];
            //let hasExistedSelected = false;
            response.data.pickupLocations.map((item: any, index: number) => {
                locatiions.push({
                    IsSelected: index==0,
                    LocationName: item.pickupLocationName,
                    LocationAddress: item.pickupLocationAddress,
                    LocationSuburb: item.pickupLocationSuburb,
                    LocationPostcode: item.pickupLocationPostcode,
                    LocationState: item.pickupLocationState,
                    LocationAvailable: item.pickupLocationAvailable,
                    Distance: item.deliveryPrice.distance,
                    DeliveryPrice: item.deliveryPrice.deliveryPrice,
                    DeliveryAvailable: item.deliveryPrice.deliveryAvailable,
                    DeliveryErrorCode: item.deliveryPrice.deliveryPriceErrorCode
                });
                //if (item.pickupLocationAvailable) {
                //    hasExistedSelected = true;
                //}
            });
            setPickupLocations(locatiions);
            //console.log(locatiions);
            let selectedPickuplocation = locatiions.find((m: deliveryModel.IPickupLocation) => m.IsSelected);
            if (!isNil(selectedPickuplocation)) {

                //if has pick up availabe, default is pick up
                let type = null;   
                if (selectedPickuplocation.LocationAvailable) {
                    type = rdEnum.enumDeliveryOptions.Pickup;                  
                }
                else {
                    //if has not pickup, but delivery is available
                    if (selectedPickuplocation.DeliveryAvailable) {
                        type = rdEnum.enumDeliveryOptions.HomeDelivery;
                    }
                }
                setPageState({
                    ...pageState,
                    PickupAddressConfirm: true,
                    DeliveryOption: type
                });

                //set primary address available details
                let primaryAddressAvailabe: deliveryModel.IDeliveryAddressAvailableDetails = {
                    PickupLocationAvailable: selectedPickuplocation.LocationAvailable,
                    DeliveryAvailable: selectedPickuplocation.DeliveryAvailable,
                    Distance: selectedPickuplocation.Distance,
                    DeliveryPrice: selectedPickuplocation.DeliveryPrice,
                    DeliveryPriceError: selectedPickuplocation.DeliveryErrorCode
                }
                setPrimaryAddressAvailabeDetails(primaryAddressAvailabe);


                let updatedDelivery: selectedConfigModel.ISelectedDelivery = {
                    ...selectedDeliveryFromStore,
                    SelectedDeliveryOption: type,
                    SelectedPickupLocation: selectedPickuplocation,
                    SelectedDeliveryFullAddress: type == rdEnum.enumDeliveryOptions.Pickup ? selectedPickuplocation?.LocationAddress : address,
                    SelectedDeliveryPostcode: type == rdEnum.enumDeliveryOptions.Pickup ? selectedPickuplocation?.LocationPostcode : postcode,
                    SelectedDeliverySuburb: type == rdEnum.enumDeliveryOptions.Pickup ? selectedPickuplocation?.LocationSuburb : suburb,
                    SelectedDeliveryState: type == rdEnum.enumDeliveryOptions.Pickup ? selectedPickuplocation?.LocationState : state,
                    PickupLocationName: selectedPickuplocation?.LocationName,
                    PrimaryAddress: response.data.homeDeliveryAddress,
                    PrimaryPostcode: response.data.homeDeliveryPostcode,
                    PrimarySuburb: response.data.homeDeliverySuburb,
                    PrimaryState: response.data.homeDeliveryState,
                    InvalidPrimaryAddress: false,
                    DeliveryPrice: type == rdEnum.enumDeliveryOptions.Pickup ? 0 : response.data.pickupLocations[0].deliveryPrice.deliveryPrice,
                    DeliveryAvailable: response.data.pickupLocations[0].deliveryPrice.deliveryAvailable,
                    DeliveryPriceErrorCode: response.data.pickupLocations[0].deliveryPrice.deliveryPriceErrorCode,
                    //DeliveryDistance: response.data.pickupLocations[0].deliveryPrice.distance,
                }
                dispatch(selectedDeliveryStore.updateDeliveryAction(updatedDelivery));
            }
            else {
                setPageState({ ...pageState, PickupAddressConfirm: false });
            }
        }
        else {
            clearPrimaryState();
        }
    }

    const clearPrimaryState = () => {

        setPickupLocations(null);
        setPrimaryAddressAvailabeDetails(checkoutHelper.getInitDeliveryAddressAvailableDetails());

        let updateSelectedDelivery: selectedConfigModel.ISelectedDelivery = {
            ...selectedDeliveryFromStore,
            SelectedPickupLocation: null,
            SelectedDeliveryOption: null,
            SelectedDeliveryFullAddress: null,
            SelectedDeliveryPostcode: null,
            SelectedDeliveryState: null,
            SelectedDeliverySuburb: null,
            PrimaryAddress: null,
            PrimaryPostcode: null,
            PrimarySuburb: null,
            PrimaryState: null,
            InvalidPrimaryAddress: null,
            DeliveryAvailable: null,
            DeliveryPrice: null,
            DeliveryPriceErrorCode: null,
            DeliveryDistance: null,
            PickupLocationName: null,
        }
        dispatch(selectedDeliveryStore.updateDeliveryAction(updateSelectedDelivery));
    }

    const doGetDeliveryPrice = async () => {
        if (isNil(pickupLocations) || pickupLocations.length == 0) return;

        let selectedLocation = pickupLocations.find(m => m.IsSelected);
        if (selectedLocation == null) return;


        let request: deliveryModel.IGetPriceRequest = {
            Country: selectedDeliveryFromStore?.Country?.code || "",
            DeliveryAddress: differentDeliveryAddress.fullAddress,
            DeliveryState: differentDeliveryAddress.state,
            DeliveryPostcode: differentDeliveryAddress.postcode,
            DeliverySuburb: differentDeliveryAddress.suburb,
            PickupAddress: selectedLocation.LocationAddress || "",
            PickupState: selectedLocation.LocationState || "",
            PickupPostcode: selectedLocation.LocationPostcode || "",
            PickupSuburb: selectedLocation.LocationSuburb || "",
            CustomerType: customerTypeFromStore || "",
            VariantName: selectedVehicleFromStore.VariantName || "",
            VehicleSubTotal: selectedVehicleFromStore?.Subtotal || 0,
        };

        let priceResponse = await locationService.getDeliveryPrice(request);
        if (priceResponse.status) {
            let priceResponseData = priceResponse.data;

            let deliveryAddressAvailabe: deliveryModel.IDeliveryAddressAvailableDetails = {
                PickupLocationAvailable: null,
                DeliveryAvailable: priceResponseData.deliveryAvailable,
                Distance: priceResponseData.distance,
                DeliveryPrice: priceResponseData.deliveryPrice || 0,
                DeliveryPriceError: priceResponseData.deliveryPriceErrorCode,
            };
            setDifferentDeliveryAddressAvailabeDetails(deliveryAddressAvailabe);

            let updatedDelivery: selectedConfigModel.ISelectedDelivery = {
                ...selectedDeliveryFromStore,
                SelectedDeliveryFullAddress: differentDeliveryAddress.fullAddress,
                SelectedDeliverySuburb: differentDeliveryAddress.suburb,
                SelectedDeliveryPostcode: differentDeliveryAddress.postcode,
                SelectedDeliveryState: differentDeliveryAddress.state,
                DeliveryPrice: priceResponseData.deliveryPrice,
                DeliveryDistance: priceResponseData.distance,
                DeliveryAvailable: priceResponseData.deliveryAvailable,
                DeliveryPriceErrorCode: priceResponse.deliveryPriceErrorCode,
            }

            dispatch(selectedDeliveryStore.updateDeliveryAction(updatedDelivery));

        }

    }



    const getHomeDeliveryPrice = () => {
        //if regional +different address, from differentDeliveryAddressPrice
        //if regional and same primary address, show the price in pickup location store
        if (pageState.DifferentDeliveryAddress) {
            if (differentDeliveryAddressAvailabeDetails.DeliveryPriceError != "") {
                return rdHelper.currencyFormatWithZeroBlank(differentDeliveryAddressAvailabeDetails.DeliveryPrice);
            }
            else {
                return rdHelper.currencyFormatWithZeroBlank(differentDeliveryAddressAvailabeDetails.DeliveryPrice);  //show include if price is 0
            }
        }
        else {
            if (primaryAddressAvailabeDetails.DeliveryPriceError != "") {
                return rdHelper.currencyFormatWithZeroBlank(primaryAddressAvailabeDetails.DeliveryPrice);
            }
            else {
                return rdHelper.currencyFormatWithZeroBlank(primaryAddressAvailabeDetails.DeliveryPrice);  //show include if price is 0
            }
        }
    }

    const homeDeliverOptionTemplate = () => {
        if (primaryAddressAvailabeDetails?.DeliveryAvailable) {
            return (
                <Button data-dtm="checkout:taking delivery"
                    className={pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? 'stat-button-link variant-btn selected' : 'stat-button-link variant-btn'}
                    onClick={() => {
                        handleDeliveryType(rdEnum.enumDeliveryOptions.HomeDelivery);
                    }}
                >
                    <span>{rdEnum.enumDeliveryOptions.HomeDelivery}</span>
                    <span>{getHomeDeliveryPrice()}</span>
                </Button>
            );
        }
        return (<></>);
    }




    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (e: any) => {
        if (e.target.value != '' ) {
            setIsFocused(true)
        } else {
            setIsFocused(false)
        }
    };

    const handleRegoFocus = () => {
        setIsRegoFocused(true);
    };

    const handleRegoBlur = (e: any) => {
        // if (isNil(differentDeliveryAddress?.fullAddress) || differentDeliveryAddress?.fullAddress == '') {
        //     setIsRegoFocused(false);
        // }
        if (e.target.value != '') {
            setIsRegoFocused(true);
        } else {
            setIsRegoFocused(false);
        }
    };

    const eligibleForPickupLocation = (): boolean => {
        let pickupAvailable: boolean = primaryAddressAvailabeDetails.PickupLocationAvailable || false;
        return pickupAvailable && !isNil(pickupLocations) && pickupLocations.length > 0;

    }



    const eligibleNoteTemplate = () => {

        let hasInStoreEligible = false;
        let hasDeliveryEligible = false

        if (pickupLocationDataLoadingStatus == rdEnum.enumProcessingStatus.Processed) {
            if (eligibleForPickupLocation()) {
                hasInStoreEligible = true;
            }
        }

        hasDeliveryEligible = primaryAddressAvailabeDetails.DeliveryAvailable || false;

        //if (pageState.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup && primaryAddressAvailabeDetails.DeliveryAvailable) {
        //    hasDeliveryEligible = true;
        //}
        //else if (pageState.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery
        //    && pageState.DifferentDeliveryAddress
        //    && differentDeliveryAddressAvailabeDetails.DeliveryAvailable) {
        //    hasDeliveryEligible = true;
        //}

        if (hasInStoreEligible && hasDeliveryEligible) {
            return (
                <p className='fine-note' style={{ marginTop: pageInvalidState.InvalidPrimaryAddress ? '1rem' : '8px' }}>You are eligible for in-store collection or regional delivery</p>
            );
        }
        else if (hasInStoreEligible && !hasDeliveryEligible) {
            return (
                <p className='fine-note' style={{ marginTop: pageInvalidState.InvalidPrimaryAddress ? '1rem' : '8px' }}>You are eligible for in-store collection</p>
            );
        }
        else if (!hasInStoreEligible && hasDeliveryEligible) {
            return (
                <p className='fine-note' style={{ marginTop: pageInvalidState.InvalidPrimaryAddress ? '1rem' : '8px' }}>This address is only eligible for Regional Delivery</p>
            );
        }

        return (<></>)

    }

    return (
        <div className='checkout-container'>
            <div className='delivery-container'>
                <h3 className='main-page-section-title'>TAKING DELIVERY</h3>
                <p className='delivery-desc'>Enter your primary address to help us determine accurate on-road pricing along with available delivery options.</p>
                {/*primary address*/}
                <span className="p-input-icon-left">
                    {
                        selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                            ?
                            <img className="image" src={`${getCMSAssets()}/checkout/AU.svg`} />
                            :
                            <img className="image" src={`${getCMSAssets()}/checkout/NZ.svg`} />
                    }
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <AutoComplete
                                {...{ autoComplete: "new" }}
                                inputRef={inputRef}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                field="fullAddress"
                                className="address-input"
                                placeholder=""
                                inputClassName="stat-input-field"
                                value={primaryAddress}
                                suggestions={addressList}
                                completeMethod={(e: any) => { doGetAddress(e); }}
                                onSelect={(e: any) => {
                                    //onAddressChange(e);
                                }}
                                onChange={(e: any) => {
                                    onAddressChange(e);
                                    //setPageInvalidState({...pageInvalidState, InvalidPickupLocation: false})
                                }}
                            />
                            {/*onChange={(e: any) => { onAddressChange(e); }}*/}
                            <label htmlFor="myInput" className={isFocused ? 'autocomplete focused' : 'autocomplete'}>Primary Address</label>
                        </span>
                        {eligibleNoteTemplate()}
                        {pageInvalidState.InvalidPrimaryAddress ? (
                            <div className="text-error" style={{ marginTop: "10px", marginBottom: "5px" }}>Primary Address is required.</div>
                        )
                            : pageInvalidState.InvalidPrimaryState ? (
                                <div className="text-error" style={{ marginTop: "10px", marginBottom: "5px" }}>You can only enter {pageState.State} address or refresh page to re-select state.</div>
                            )
                                : <></>
                        }

                    </div>
                    {!isNil(primaryAddress) && !isNil(primaryAddress.fullAddress) && primaryAddress.fullAddress != "" && (
                        <img className="image-success"
                            src={`${getCMSAssets()}/checkout/tick.svg`}
                        />
                    )}
                </span>
                {
                    pickupLocationDataLoadingStatus == rdEnum.enumProcessingStatus.Processed && !pageInvalidState.InvalidPrimaryAddress
                        ?
                        <div className='preferred-location-container'>
                            {/* <span>Preferred option</span> */}

                            <div className='variant-btn-panel'>
                                {eligibleForPickupLocation() && (
                                    <Button data-dtm="checkout:taking delivery"
                                        className={pageState?.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup ? 'stat-button-link variant-btn selected' : 'stat-button-link variant-btn'}
                                        onClick={() => {
                                            handleDeliveryType(rdEnum.enumDeliveryOptions.Pickup);
                                        }}
                                    >
                                        <span>{rdEnum.enumDeliveryOptionsDisplayName.Pickup}</span>
                                    </Button>
                                )}
                                {homeDeliverOptionTemplate()}
                            </div>

                            {
                                pageState.DeliveryOption == rdEnum.enumDeliveryOptions.Pickup && !pageInvalidState.InvalidPrimaryAddress
                                    && primaryAddressAvailabeDetails.PickupLocationAvailable && !isNil(pickupLocations) && pickupLocations.length > 0
                                    ?
                                    <>
                                        <h6>Pick-up location</h6>
                                        {pickupLocations.map((item: deliveryModel.IPickupLocation, index: number) => {
                                            return (
                                                <div className='card-info-selection' key={"location_" + index}>
                                                    <Card
                                                        style={{
                                                            padding: '.3rem',
                                                            border: pageState.PickupAddressConfirm ? '2px solid #282828' : '1px solid #d2d2d2'
                                                        }}
                                                    >
                                                        <div className='location-top'>
                                                            <div className='location-top-inner'>
                                                                <Checkbox
                                                                    inputRef={locationCheckboxRef} 
                                                                    name="chkPickupAddress"
                                                                    inputId={"location_" + item.LocationName}
                                                                    onChange={(e: any) => {
                                                                        setPageState({
                                                                            ...pageState,
                                                                            PickupAddressConfirm: e.checked,
                                                                        })

                                                                        setPageInvalidState({ ...pageInvalidState, InvalidPickupLocation: false });
                                                                    }}
                                                                    checked={pageState?.PickupAddressConfirm}
                                                                ></Checkbox>
                                                                <label htmlFor={"location_" + item.LocationName} className="location_name">{item.LocationName}</label>
                                                            </div>
                                                            <label htmlFor={"location_" + item.LocationName} className="location_distance">{item.Distance} km</label>
                                                        </div>
                                                        <label className='location-address' htmlFor={"location_" + item.LocationName}>
                                                            <span>{item.LocationAddress}</span>
                                                        </label>
                                                    </Card>
                                                </div>
                                            );

                                        })}
                                        {pageInvalidState.InvalidPickupLocation && (
                                            <div className="text-error" style={{ marginTop: "10px", marginBottom: "5px" }}>Please select pick up location.</div>
                                        )}
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                        :
                        <></>
                }
                {
                    pickupLocationDataLoadingStatus == rdEnum.enumProcessingStatus.Processed
                        && pageState.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery && !pageInvalidState.InvalidPrimaryAddress
                        ?
                        (
                            <div className="delivery-address-wrapper">
                                <div className="different-delivery-address">
                                    <Checkbox
                                        inputRef={differentAddressCheckboxRef} 
                                        name="chkDifferentDelivaryAddress"
                                        inputId="chkDifferentDelivaryAddress"
                                        onChange={(e: any) => {
                                            setPageState({
                                                ...pageState,
                                                DifferentDeliveryAddress: e.checked,
                                            })
                                            if (e.checked) {

                                                setPageInvalidState({
                                                    ...pageInvalidState,
                                                    InvalidDeliveryState: primaryAddress?.state != differentDeliveryAddress?.state
                                                });

                                                let updatedDelivery: selectedConfigModel.ISelectedDelivery = {
                                                    ...selectedDeliveryFromStore,
                                                    SelectedDeliveryFullAddress: differentDeliveryAddress?.fullAddress,
                                                    SelectedDeliveryPostcode: differentDeliveryAddress?.postcode,
                                                    SelectedDeliverySuburb: differentDeliveryAddress?.suburb,
                                                    SelectedDeliveryState: differentDeliveryAddress?.state,
                                                    DeliveryPrice: primaryAddressAvailabeDetails.DeliveryPrice,
                                                    DeliveryDistance: primaryAddressAvailabeDetails.Distance,
                                                };
                                                dispatch(selectedDeliveryStore.updateDeliveryAction(updatedDelivery));
                                            }
                                            else {
                                                setPageInvalidState({ ...pageInvalidState, InvalidDeliveryState: false });

                                                let updatedDelivery: selectedConfigModel.ISelectedDelivery = {
                                                    ...selectedDeliveryFromStore,
                                                    SelectedDeliveryFullAddress: primaryAddress?.fullAddress,
                                                    SelectedDeliveryPostcode: primaryAddress?.postcode,
                                                    SelectedDeliverySuburb: primaryAddress?.suburb,
                                                    SelectedDeliveryState: primaryAddress?.state,
                                                    DeliveryDistance: primaryAddressAvailabeDetails.Distance,
                                                    DeliveryPrice: primaryAddressAvailabeDetails.DeliveryPrice,
                                                };
                                                dispatch(selectedDeliveryStore.updateDeliveryAction(updatedDelivery));
                                            }
                                        }}
                                        checked={pageState?.DifferentDeliveryAddress}
                                    ></Checkbox>
                                    <label htmlFor="chkDifferentDelivaryAddress" className="p-checkbox-label">
                                        I would like my vehicle delivered to a location that is different to my primary address. This will re-calculate your delivery fee.
                                    </label>
                                </div>
                                {pageState.DifferentDeliveryAddress && (
                                    <span className="p-input-icon-left regional-delivery">
                                        {
                                            selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                                                ?
                                                <img className="image" src={`${getCMSAssets()}/checkout/AU.svg`} />
                                                :
                                                <img className="image" src={`${getCMSAssets()}/checkout/NZ.svg`} />
                                        }
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <AutoComplete
                                                    inputRef={regionalInputRef}
                                                    {...{ autoComplete: "new" }}
                                                    onFocus={handleRegoFocus}
                                                    onBlur={handleRegoBlur}
                                                    field="fullAddress"
                                                    className="address-input"
                                                    placeholder=""
                                                    value={differentDeliveryAddress}
                                                    suggestions={addressList}
                                                    completeMethod={(e: any) => {
                                                        doGetAddress(e);
                                                    }}
                                                    onChange={(e: any) => { onDeliveryAddressChange(e); }}
                                                />
                                                <label htmlFor="myInput" className={isRegoFocused ? 'autocomplete focused' : 'autocomplete'}>Delivery Address</label>
                                            </span>
                                            {pageInvalidState.InvalidDeliveryAddress && (
                                                <div className="text-error" style={{ marginTop: "10px", marginBottom: "5px" }}>Delivery Address is required.</div>
                                            )}
                                        </div>
                                        {!isNil(differentDeliveryAddress) && !isNil(differentDeliveryAddress.fullAddress) && differentDeliveryAddress.fullAddress != "" && (
                                            <img className="image-success" src={`${getCMSAssets()}/checkout/tick.svg`}
                                            />
                                        )}

                                    </span>
                                )}
                            </div>
                        )
                        : (<></>)
                }

            </div>
            {showDeliveryAddressError()}
        </div>
    )
});

export default Delivery