import { getCMSAssets } from 'helpers/Uri';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';

import * as rdEnum from 'helpers/enum';

const ConfidenceDialog = () => {

    const linkSourceFromStore = useSelector((state: IAppState) => {
        return state.linkSource;
    });
    const defaultConfigStore = useSelector((state: IAppState) => {
        return state.defautConfig;
    });

    // const [isOpen, setIsOpen] = useState(false);

    // const handleDialogOpen = () => {
    //     setIsOpen(true);
    //     document.body.style.overflowY = 'hidden';
    //     document.body.style.paddingRight = '15px';
    // }

    // const handleDialogHide = () => {
    //     setIsOpen(false);
    //     document.body.style.overflowY = 'scroll';
    //     document.body.style.paddingRight = '0px';
    // }

    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const isMoible = useCheckMobileScreen()

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const handleDialogHide = () => {
        setIsOpen(false);
    }

    // console.log(isOpen);

    const handleScroll = (event: any) => {
        const scrollTop = event.target.scrollTop;
        setIsScrolled(scrollTop > 50);
    };


    const handleDialogShow = () => {
        const dialogContents = document.querySelectorAll('.p-dialog-content');

        dialogContents.forEach((dialogContent) => {
            dialogContent.addEventListener('scroll', handleScroll);
        });
    };

    //This useEffect used to detect the scrollTop distance and if it larger than 100, 
    //the 'Scroll to learn more' context will disappear
    // Clean up event listeners when the dialog is hidden
    useEffect(() => {
        if (!isOpen) {
            const dialogContents = document.querySelectorAll('.p-dialog-content');
            dialogContents.forEach((dialogContent) => {
                dialogContent.removeEventListener('scroll', handleScroll);
            });
        }
    }, [isOpen]);

    const crossIcon = (
        <button className="p-dialog-titlebar-icon p-link" onClick={() => handleDialogHide()}>
            <img alt="" className='close-icon' src={`${getCMSAssets()}/main/cross-white.svg`} />
        </button>
    )

    return (
        <>
            <div className='confidence-container stat-button-link'
                data-dtm="variant"
                onClick={() => {
                    handleDialogOpen();
                }}
            >
                <img alt='cadillac logo' src={`${getCMSAssets()}/variants/Cadillac-Logo.svg`} />
                <span>Explore Cadillac Confidence.</span>
                <img alt='' className='arrow-confidence' src={`${getCMSAssets()}/main/arrow-confidence.svg`} />
                {/* <span>Discover Cadillac Confidence, included with every LYRIQ.</span> */}
            </div>

            {linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink && (
                <div className='private-link-expiry-container'>
                    <span className="text1">You are using a private configuration link.</span>
                    <span className="text2">The link will expire on {defaultConfigStore.ExpiryDateText}.</span>
                </div>
            )}

            <Dialog
                header="" icons={crossIcon} className='design-your-dialog' visible={isOpen} position='bottom'
                style={{ backgroundImage: `url(${getCMSAssets()}/global/DesignYour-Background.png)`, backgroundSize: '100% 100%', }}
                onShow={() => handleDialogShow()}
                onHide={() => handleDialogHide()}
                draggable={false} resizable={true}
            >
                <div className='design-your-container' >
                    <div className='design-your-initial-page'>
                        <img alt="" src={`${getCMSAssets()}/global/logo-white.svg`} />
                        <h4>CADILLAC <br />CONFIDENCE</h4>
                        <h5>Our commitment<br />with every cadillac</h5>
                    </div>
                    {/* <h6 className={!isScrolled ? 'scroll-reminder' : 'scroll-reminder hide'}>Scroll to learn more</h6> */}
                    <div className='design-your-second-page'>
                        <div className='panel-item'>
                            <h5>Price confidence</h5>
                            <p>Wherever you buy your Cadillac</p>
                        </div>
                        <div className='panel-item'>
                            <h5>5 years</h5>
                            <p>unlimited kilometre warranty<sup>1</sup></p>
                        </div>
                        <div className='panel-item'>
                            <h5>5 years</h5>
                            <p>Premium roadside assist<sup>1</sup></p>
                        </div>
                        <div className='panel-item'>
                            <h5>5 years</h5>
                            <p>scheduled servicing / 5 scheduled services<sup>1</sup></p>
                        </div>
                        <div className='panel-item'>
                            <h5>8 years / 160,000km</h5>
                            <p>battery warranty<br />(whichever comes first)<sup>1</sup></p>
                        </div>
                        <div className='panel-item'>
                            <h5>Charging covered</h5>
                            <p>1 year complimentary public charging, and
                                {
                                    !isMoible
                                        ?
                                        <br />
                                        :
                                        <>&nbsp;</>
                                }
                                your choice of 2 additional years public charging, or a home charger installed<sup>2</sup>
                            </p>
                        </div>
                    </div>
                    <div className='design-your-footer'>
                        {/* <span>Disclaimer*</span> */}
                        {/* <br/><br/> */}
                        <br />
                        <span>
                            <sup>1</sup> View important information and terms & conditions relating to service, roadside assistance, and warranties in the ownership section of the Cadillac Australia & New Zealand website.
                        </span>
                        <br /><br />
                        <span>
                            <sup>2</sup> Cadillac has partnered with third party providers in Australia and New Zealand to provide public and home charging services. Fair use and other policies apply. View order T&Cs when completing your purchase for more information.
                        </span>
                    </div>
                </div>

            </Dialog>

        </>
    )
}


export default ConfidenceDialog