import { InputText } from 'primereact/inputtext'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri';
import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as rdEnum from 'helpers/enum';
import * as rdUri from 'helpers/Uri';

import { IAppState } from 'model/IAppState';
import * as interestModel from 'model/IInterest';
import * as interestService from 'services/Interest';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import { useSatellite } from 'helpers/useSatellite';

const ShareDialog = () => {
    const satellite = useSatellite();

    const [shareDialog, setShareDialog] = useState(false);
    const [tooltip, setTooltip] = useState({
        copyTooltip: false,
        emailTooltip: false
    });

    const [isCopyLink, setIsCopyLink] = useState(false);
    const [isEmailSend, setIsEmailSend] = useState(false);
    

    const [pageState, setPageState] = useState({
        email: "",
        invalidEmail: false,
    });

    const [shareFailedMessage, setShareFailedMessage] = useState("");
    const [sharingStatus, setSharingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    //const [savStatus, setSharingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [webUniqueKey, setWebUniqueKey] = useState("");

    //store variables
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const vehFromStore = useSelector((appState: IAppState) => {
        return appState.vehicle;
    });
    const selectedPaymentOptions = useSelector((appState: IAppState) => {
        return appState.paymentOptions;
    });
    const customerTypeForStore = useSelector((state: IAppState) => {
        return state.customerType;
    });


    const openShareDialog = async () => {
        if (isNil(webUniqueKey) || webUniqueKey == "") {

            let newKey: string = uuidv4();
            setWebUniqueKey(newKey);
            await doShareInterest(true, newKey);
        }
        else {
            await doShareInterest(true, webUniqueKey);
        }
        setShareDialog(true);

    }

    const onShareHide = () => {
        setShareDialog(false);
    }

    const handleCopy = async () => {

        let url = rdUri.getShareLink(webUniqueKey);
        navigator.clipboard.writeText(url);

        setIsCopyLink(true);
        setTimeout(() => {
            setIsCopyLink(false);
        }, 3000)

        // showCopySuccess();
    }

    const handleSendEmail = async () => {
        let invalidEmail = false;
        const emailValidator = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pageState?.email);
        if (isNil(pageState.email) || pageState.email == "" || emailValidator) {
            invalidEmail = true;
        }

        setPageState({
            ...pageState,
            invalidEmail: invalidEmail,
        })

        if (invalidEmail) {
            return
        }

        setIsEmailSend(true);
        setTimeout(() => {
            setIsEmailSend(false);
        }, 5000)

        await doShareInterest(false, webUniqueKey);
    }

    const getDeliveryLocationState = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationState;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryState || "";
        }
    }

    const getDeliveryLocationPostcode = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationPostcode;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryPostcode || "";
        }
    }

    const getDeliveryLocationSuburb = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationSuburb;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliverySuburb || "";
        }
    }

    const getDeliveryLocationFullAddress = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationAddress || "";
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryFullAddress || "";
        }
    }

    //todo: api and sp need add primary address(RegistrationAddress)

    ///1. isShareOnly: true ===> calling when dialog is open.
    ///   In this case, we only save configuartion to DB in order to make share link working
    ///   We don't call yana API as we don't want to send link to customer
    ///2. isShareOnly: false ===> calling when click share button
    const doShareInterest = async (isShareOnly: boolean, newWebUniqueKey: string) => {
        let interestData: interestModel.IInterest = {
            Country: selectedDeliveryFromStore?.Country?.code || "",
            State: selectedDeliveryFromStore?.State?.code || "",
            VariantName: selectVehFromStore.VariantName || "",
            VariantBasePrice: selectVehFromStore.VariantBasePrice || 0,
            ExteriorName: selectVehFromStore.Exterior || "",
            ExteriorPrice: selectVehFromStore.ExteriorPrice || 0,
            InteriorName: selectVehFromStore.Interior || "",
            InteriorPrice: selectVehFromStore.InteriorPrice || 0,
            PaymentOption: selectedPaymentOptions?.PaymentOption || rdEnum.enumPaymentOptions.Cash,
            Email: pageState.email,
            FirstName: null,
            LastName: null,
            Mobile: null,
            ABN: null,
            BusinessName: null,
            CustomerType: customerTypeForStore || rdEnum.enumClientTypes.Private.Code,
            DeliveryAddress: getDeliveryLocationFullAddress() || "",
            DeliveryLocationState: getDeliveryLocationState() || "",
            DeliveryLocationSuburb: getDeliveryLocationSuburb() || "",
            DeliveryLocationPostcode: getDeliveryLocationPostcode() || "",
            DeliveryOption: selectedDeliveryFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            EstDeliveryDate: vehFromStore?.SelectedInterior?.Available || "",
            PrivacyStatement: true,
            Marketing: null,
            InterestType: rdEnum.enumInterestType.Share,
            Comment: null,
            WebUniqueKey: newWebUniqueKey,
            Options: selectVehFromStore.Options || [],
            VehicleThumbnail: ""
        };

        if (!isShareOnly) {
            setShareFailedMessage("");
            setSharingStatus(rdEnum.enumProcessingStatus.Processing);
        }

        let response = await interestService.saveInterest(interestData, !isShareOnly);
        setSharingStatus(rdEnum.enumProcessingStatus.Processed);
        if (!isShareOnly) {
            if (response.status) {
                showEmailSuccess()
            }
            else {
                setShareFailedMessage(response.message);
            }
        }

    }


    const showCopySuccess = () => {
        // toast?.current.show({severity:'success', summary: 'Message Successfully Copied', life: 3000});
        setTooltip({
            ...tooltip,
            copyTooltip: true
        });
        // Hide the tooltip after a certain duration (e.g., 2 seconds)
        setTimeout(() => {
            setTooltip({
                ...tooltip,
                copyTooltip: false
            });
        }, 2000);
    }

    const showEmailSuccess = () => {
        setTooltip({
            ...tooltip,
            emailTooltip: true
        });
        // Hide the tooltip after a certain duration (e.g., 2 seconds)
        setTimeout(() => {
            setTooltip({
                ...tooltip,
                emailTooltip: false
            });
        }, 2000);
    }

    const tooltipCopy = <Tooltip id="tooltip">Link copied to clipboard</Tooltip>;

    const tooltipEmail = <Tooltip id="tooltip">Email send successfully</Tooltip>;

    useEffect(() => {
        if (shareDialog) {
            satellite.trackEvent("config-end");
        }
    }, [shareDialog]);

    return (
        <>
            <div className='share-box stat-text-link' onClick={() => openShareDialog()} data-dtm="checkout">
                <img className="image" src={`${getCMSAssets()}/checkout/share.svg`} />
                <span>Share your LYRIQ configuration</span>
            </div>
            <Dialog className='share-dialog' visible={shareDialog} position='bottom' onHide={() => onShareHide()} appendTo={document.body} draggable={false} resizable={true}>
                <div className='share-panel'>
                    <div className='title-container'>
                        <h1>YOUR CONFIGURATION</h1>
                        <span>Copy the  link below or send your configuration to an email address of your choice.</span>
                    </div>

                    <div className='share-content-container'>
                        <div className='inner-content-container'>
                            {/* <div className='input-item'>
                                <div className='share-content-subtitle'>
                                    Your Configuration Link:
                                </div>
                                <div className='share-content'>
                                    {rdUri.getShareLink(webUniqueKey)}
                                </div>
                            </div> */}

                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="email" aria-describedby="email-help"
                                        placeholder=" "
                                        value={rdUri.getShareLink(webUniqueKey)}
                                    />
                                    <label>Your Configuration Link:</label>
                                </span>
                            </div>
                           

                            <div className='right-btn-panel'>
                                {/* <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipCopy}
                                    show={tooltip.copyTooltip}
                                    trigger={['click']}
                                >
                                    <Button onClick={() => handleCopy()}>
                                        <img alt="" src={`${getCMSAssets()}/supports/copy.svg`} />
                                        <span>COPY LINK</span>
                                    </Button>
                                </OverlayTrigger> */}
                                <Button
                                    className="stat-button-link"
                                    data-dtm="modal:review"
                                    onClick={() => handleCopy()}>
                                    <img alt="" src={`${getCMSAssets()}/supports/copy.svg`} />
                                    <span>COPY LINK</span>
                                </Button>

                                { isCopyLink && (
                                    <small id="email-success" className="success-info">Link copied successfully</small>
                                )}
                            </div>
                        </div>
                        
                        <div className='or-panel'>or</div>
                    </div>

                    <div className='share-content-container'>
                        <div className='inner-content-container'>
                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="email" aria-describedby="email-help"
                                        className={classNames({ "p-invalid": pageState?.invalidEmail })}
                                        placeholder=" "
                                        value={pageState.email}
                                        onChange={(e) => {
                                            setPageState({
                                                ...pageState,
                                                email: e.target.value,
                                                invalidEmail: isNil(e.target.value) || e.target.value == ""
                                            })
                                        }}
                                    />
                                    <label>Email</label>
                                </span>
                              
                                {pageState?.invalidEmail && (
                                    <small id="email-help" className="p-error block">Invalid email format. Please enter a valid email address.</small>
                                )}
                               
                            </div>

                            <div className='right-btn-panel'>
                                {sharingStatus == rdEnum.enumProcessingStatus.Processing && (
                                    <Button disabled={true}>
                                        <ProgressSpinner
                                            style={{ width: '16px', height: '16px' }}
                                            strokeWidth="5"
                                            animationDuration=".8s"
                                        />
                                    </Button>
                                )}

                                {sharingStatus != rdEnum.enumProcessingStatus.Processing && (
                                    <Button onClick={() => handleSendEmail()}
                                        className="stat-button-link"
                                        data-dtm="modal:review"
                                    >
                                        <img alt="" src={`${getCMSAssets()}/supports/email.svg`} />
                                        <span>SEND VIA EMAIL</span>
                                    </Button>
                                )}

                                {/* {sharingStatus !== rdEnum.enumProcessingStatus.Processing && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipEmail}
                                        show={tooltip.emailTooltip}
                                        trigger={['click']}
                                    >
                                        <Button onClick={() => handleSendEmail()}>
                                            <img alt="" src={`${getCMSAssets()}/supports/email.svg`} />
                                            <span>SEND VIA EMAIL</span>
                                        </Button>
                                    </OverlayTrigger>
                                )} */}

                                {sharingStatus !== rdEnum.enumProcessingStatus.Processing && isEmailSend && (
                                    <small id="email-success" className="success-info">Email sent successfully</small>
                                )}
                            </div>

                           
                        </div>
                               
                    </div>

                    {!isNil(shareFailedMessage) && shareFailedMessage != "" && (
                        <div className="text-error" dangerouslySetInnerHTML={{ __html: shareFailedMessage }}></div>
                    )}

                    <div>Cadillac is collecting this email address for the purpose of sending your configuration. This email will be stored temporarily to enable this function in accordance with our 
                        &nbsp;<PrivacyPolicyLink props='Privacy Policy' />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ShareDialog