//provide data to this store for below scenario
//1. when user click "Order" button on Inventory page. The link is http://localhost:3000/cadillac/?selectedVariant=LYRIQ%20SPORT%20AWD&selectedExterior=Black&selectedInterior=White
//2. when user is come from interest link in the email.
import * as defaultConfigModel from 'model/IDefaultConfig';
import * as defaultConfigHelper from 'businessHelpers/DefaultConfigHelper';

export const SET_DEFAULTCONFIG = 'setDefaultConfig';
export const setDefaultConfigAction = (defaultConfig: defaultConfigModel.IDefaultConfig) =>
({
    type: SET_DEFAULTCONFIG,
    defaultConfig,
} as const);

export const SET_DEFAULTCONFIGLOADED = 'setDefaultConfigLoaded';
export const setDefaultConfigLoadedAction = (loaded: boolean | null) =>
({
    type: SET_DEFAULTCONFIGLOADED,
    loaded,
} as const);

export type DefaultConfigActions = ReturnType<typeof setDefaultConfigAction>
    | ReturnType<typeof setDefaultConfigLoadedAction>;


export const defaultConfigReducer = (
    state = defaultConfigHelper.getInitDefaultConfig(),
    action: DefaultConfigActions,
) => {
    switch (action.type) {
        case SET_DEFAULTCONFIG: {
            return action.defaultConfig;
        }

        case SET_DEFAULTCONFIGLOADED: {
            return {
                ...state,
                Loaded: action.loaded
            }
        }

        default:
            return state;
    }
};