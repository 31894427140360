import * as paymentOptionsModel from 'model/IPaymentOptions';
import * as rdEnum from 'helpers/enum';

export const UPDATE_SELECTEDPAYMENTOPTION = 'UpdateSelectedPaymentOption';
export const updateSelectedPaymentOptionAction = (selectedPaymentOption:string) =>
({
    type: UPDATE_SELECTEDPAYMENTOPTION,
    selectedPaymentOption,
} as const);

export const UPDATE_DRIVEAWAYCOST = 'UpdateDriveawayCost';
export const updateDriveawayCostAction = (
    driveAwayCost: paymentOptionsModel.IDriveAwayCost,
    driveAwayCostRequest: paymentOptionsModel.IDriveAwayRequest) =>
({
    type: UPDATE_DRIVEAWAYCOST,
    driveAwayCost,
    driveAwayCostRequest
} as const);



export const UPDATE_FINANCE = 'UpdateFinance';
export const updateFinanceAction = (finance: paymentOptionsModel.IFinance) =>
({
    type: UPDATE_FINANCE,
    finance,
} as const);

export const CLEAR_PAYMENTOPTIONS = 'ClearPaymentOptions';
export const clearPaymentOptionsAction = () =>
({
    type: CLEAR_PAYMENTOPTIONS,
} as const);

export type PaymentOptionsActions = ReturnType<typeof updateDriveawayCostAction>
    | ReturnType<typeof updateSelectedPaymentOptionAction>
    | ReturnType<typeof updateFinanceAction>
    | ReturnType<typeof clearPaymentOptionsAction>;

export const initPaymentOptions: paymentOptionsModel.IPaymentOptions = {
    PaymentOption: rdEnum.enumPaymentOptions.Cash,
    DriveAwayCost: null,
    DriveAwayCostRequest: null,
    Finance: null
}


export const paymentOptionsReducer = (
    state = initPaymentOptions,
    action: PaymentOptionsActions,
) => {
    switch (action.type) {
        case UPDATE_SELECTEDPAYMENTOPTION: {
            return {
                ...state,
                PaymentOption: action.selectedPaymentOption
            }
        }
        case UPDATE_DRIVEAWAYCOST: {
            //console.log("UPDATE_DRIVEAWAYCOST");
            //console.log(action)
            return {
                ...state,
                DriveAwayCost: action.driveAwayCost,
                DriveAwayCostRequest: action.driveAwayCostRequest    //store request data to prevent multiple call
            }
        }
        case UPDATE_FINANCE: {
            //console.log("UPDATE_FINANCE");
            //console.log(action)
            return {
                ...state,
                Finance: action.finance
            }
        }
        case CLEAR_PAYMENTOPTIONS: {
            //console.log("CLEAR_PAYMENTOPTIONS");
            return initPaymentOptions;
        }
        default:
            return state;
    }
};