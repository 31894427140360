import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import IsNumberVerification from 'components/IsNumberVerification'
import { getCMSAssets } from 'helpers/Uri';
import { IAppState } from 'model/IAppState';
import * as orderService from 'services/Order';
import * as contractModel from 'model/IContract';
import * as docModel from 'model/IDocuments';
import * as docHelper from 'businessHelpers/DocumentHelper';
import * as docService from 'services/Document';
import * as orderDetailPageVersionStore from 'stores/OrderDetailPageDataVersion';
import { enumClientTypes, enumDocumentType } from "helpers/enum";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';

// import {Document, Page, pdfjs} from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReviewContract = (props: any) => {
    const dispatch = useDispatch();
    const { buttonText } = props;

    

    const isMobile = useCheckMobileScreen();

    const [dialogVisible, setDialogVisible] = useState(false);
    const [fullName, setFullName] = useState("");
    const [invalidFullname, setInvalidFullname] = useState(false);
    const [consent, setConsent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [pdfLink, setPDFLink] = useState("");

    const orderCustomerDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderCustomerDetails;
    });
    const orderDocumentsFromStore = useSelector((state: IAppState) => {
        return state.orderDocuments;
    });

    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }


    useEffect(() => {
        if (!isNil(orderDocumentsFromStore) && dialogVisible) {
            doGetDoc();
        }
    }, [orderDocumentsFromStore, dialogVisible]);


    const doGetDoc = async () => {
        let findDocs = orderDocumentsFromStore.filter((m: docModel.IDocumentItem) => m.DocumentType == enumDocumentType.Contract);

        if (!isNil(findDocs) && findDocs.length > 0) {
            let requestFrontData: docModel.IDocument = {
                YanaCustomerID: orderCustomerDetailsFromStore?.yanaCustomerID || "",
                YanaCustomerNo: orderCustomerDetailsFromStore?.yanaCustomerNo || "",
                YanaOrderID: orderCustomerDetailsFromStore?.yanaOrderID || "",
                YanaOrderNo: orderCustomerDetailsFromStore?.yanaOrderNo || "",
                Country: orderCustomerDetailsFromStore?.country || "",
                Documents: [findDocs[findDocs.length - 1]]
            };
            let response = await docService.getSpecificDocuments(requestFrontData);
            if (response.status) {
                let fileName = response.data[0].reportName;
                let displayUrl = response.data[0].displayUrl;
                let key = response.data[0].key;
                setPDFLink(displayUrl + "?" + key);
            }
        }
    }

    const onSignContract = async () => {
        setErrorMessage("");

        if (isNil(fullName) || fullName == "") {
            setInvalidFullname(true);
            return;
        }
        setInvalidFullname(false);
        let request: contractModel.IContract = {
            Country: orderCustomerDetailsFromStore?.country || "",
            YanaCustomerID: orderCustomerDetailsFromStore?.yanaCustomerID || "",
            YanaCustomerNo: orderCustomerDetailsFromStore?.yanaCustomerNo || "",
            YanaOrderID: orderCustomerDetailsFromStore?.yanaOrderID || "",
            YanaOrderNo: orderCustomerDetailsFromStore?.yanaOrderNo || "",
            FullName: fullName,
        };

        let response = await orderService.confirmContract(request);
        if (response.status) {
            dispatch(orderDetailPageVersionStore.setOrderDetailPageDataVersionAction());
            onHideDialog();
        }
        else {
            setErrorMessage(response.message);
        }
    }

    return (
        <>
            <div className='variant-btn-panel'>
                <Button className='variant-btn'
                    onClick={() => onShowDialog()}
                >
                    {buttonText}
                </Button>
            </div>

            <Dialog className='review-contract-dialog'
                visible={dialogVisible}
                position='bottom'
                style={{ maxWidth: '1100px' }}
                draggable={false}
                resizable={true}
                onHide={() => onHideDialog()}>
                <div className='review-contract-dialog-container'>
                    <div className='review-contract-container'>
                        {/*<object data={`${getCMSAssets()}/order/[AU] Draft - Cadillac Contract of Sale.pdf`}*/}
                        {/*    type="application/pdf" width="100%" height="100%">*/}
                        {/*</object>*/}
                        {/* <object data={pdfLink}
                            type="application/pdf" width="100%" height="100%">
                        </object> */}
                        {
                            !isMobile
                            ?
                            <object data={pdfLink}
                            type="application/pdf" width="100%" height="100%">
                            </object>
                            :
                            <div className='variant-btn-panel'>
                                <Button className='variant-btn'>
                                    <a href={pdfLink} download target="_blank" rel="noopener noreferrer">View</a>
                                </Button>
                                
                            </div>
                            // <Document file={pdfLink}>
                            //     <Page pageNumber={0} />
                            //     {/* Add more <Page> components as needed for multi-page PDFs */}
                            // </Document>
                        }
                    </div>
                    <div className="fullname-wrapper">
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="fullname"
                                    aria-describedby="fullname-help"
                                    placeholder="Buyer Full Name"
                                    value={fullName || ''}
                                    onChange={(e: any) => {
                                        setFullName(e.target.value);
                                    }}
                                />
                                <label>Buyer Full Name</label>
                            </span>
                        </div>
                        {invalidFullname && (
                            <small id="fullname-help" className="p-error block">Require Valid Full Name</small>
                        )}
                    </div>
                    <div className="consent-wrapper checkbox-wrapper">
                        <Checkbox
                            name="chkConsent"
                            inputId="chkConsent"
                            onChange={(e) => {
                                setConsent(e.checked);
                            }}
                            checked={consent}
                        ></Checkbox>
                        <label htmlFor="chkConsent" className="p-checkbox-label">I have read and reviewed my vehicle sales order contract and agree with all the T&C's mentioned in this contract.</label>
                    </div>
                    {!isNil(errorMessage) && errorMessage != "" && (
                        <div>
                            <div className="text-error">{errorMessage}</div>
                        </div>
                    )}
                    <Row>
                        <div className='btn-panel'>
                            <div className='variant-btn-panel'>
                                <Button className='variant-btn'
                                    disabled={!consent}
                                    style={{border: 'none', backgroundColor: !consent ? '#CCCCCC' : '#282828'}}
                                    onClick={(e: any) => {
                                        onSignContract();
                                    }}
                                >
                                    <span style={{color: !consent ? '#707070' : '#fff'}}>SIGN YOUR CONTRACT</span>
                            </Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </Dialog>
        </>
    )
}

export default ReviewContract