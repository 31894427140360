import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { filter, includes, find, isNil, String, clone, isNull } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayoutPage } from 'LayoutPages';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import ScrollToTop from 'components/ScrollToTop';
import { Footer } from 'scene/Layout/Footer';
import {
    getFrontendUrl,
} from 'helpers/Uri';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { GlobalPage } from 'pages/GlobalPage';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { IAppState } from 'model/IAppState';
import Spinner from 'components/Spinner';


export const NotFoundPage = () => {

    const isMobile = useCheckMobileScreen();
    const [scrollGlobalOpacity, setScrollGlobalOpacity] = useState(1.00);
    const [isGlobalDialogOpen, setIsGlobalDialogOpen] = useState(true);
    const menuGlobalBtn = useSelector((state: IAppState) => {
        return state.globalDropdown
    })
    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber
    })

    React.useEffect(() => {
        document.title = `Error`;
    })

    useEffect(() => {
        window.location.href ="https://www.cadillacanz.com/error/";
    }, []);

    return (
        <div className="auth-container">
            <Spinner spinnerWidth={64} />
        </div>
        //<MainLayoutPage sceneId="page-error container-has-header">
        //    <React.Fragment>
        //        <div className='global-options-container'>

        //            <GlobalMenu activeBtn='test' />

        //            <div className='error-page-container'>
        //                <div className="title">WE CAN'T SEEM TO FIND</div>
        //                <div className="title">WHAT YOU'RE LOOKING FOR</div>
        //                <div className="error-desc">Error Code: 404</div>
        //            </div>
        //        </div>
        //        {/*<section className='section global' data-section-id='global'*/}
        //        {/*    style={{*/}
        //        {/*        // paddingBottom: '385px',*/}
        //        {/*        display: mainPageNumber != 0 ? 'none' : "",*/}
        //        {/*        height: mainPageNumber != 0 ? '0' : 'auto',*/}
        //        {/*        opacity: `${scrollGlobalOpacity}`,*/}
        //        {/*    }}>*/}
        //        {/*    <div className='animate-item'>*/}
        //        {/*        <GlobalPage isGlobalDialogOpen={false} />*/}
        //        {/*    </div>*/}
        //        {/*</section>*/}

        //        <Footer />
        //    </React.Fragment>
        //</MainLayoutPage>

    )
}