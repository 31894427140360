import { getBackendPath } from 'helpers/Uri';
import * as docModel from 'model/IDocuments';
import * as rdHelper from 'helpers/Uri';

export const uploadDocuments = async (
    doc: docModel.IDocument
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(doc),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Documents/upload-documents`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getAllDocuments = async (
    doc: docModel.IDocument
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(doc),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Documents/get-documents`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getSpecificDocuments = async (
    doc: docModel.IDocument
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(doc),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Documents/get-specific-document`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};