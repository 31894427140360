//used by components in order schedule delivery page
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as scheduleDeliveryHelper from 'businessHelpers/OrderScheduleDeliveryHelper';

export const SET_ORDERSCHEDULEDELIVERY = 'SetOrderScheduleDelivery';
export const setOrderScheduleDeliveryAction = (scheduleDelivery: scheduleDeliveryModel.IOrderScheduleDelivery) =>
({
    type: SET_ORDERSCHEDULEDELIVERY,
    scheduleDelivery,
} as const);

export const SET_SCHEDULEDATE = 'SetScheduleDate';
export const setScheduleDateAction = (date: Date | undefined, formatDate: string | null) =>
({
    type: SET_SCHEDULEDATE,
    date,
    formatDate
} as const);


export const SET_SCHEDULETIME = 'SetScheduleTime';
export const setScheduleTimeAction = (time: string | null) =>
({
    type: SET_SCHEDULETIME,
    time
} as const);


export const CLEAR_ORDERSCHEDULEDELIVERY = "ClearOrderScheduleDelivery";
export const clearOrderScheduleDelivery = () =>
({
    type: CLEAR_ORDERSCHEDULEDELIVERY
} as const);

export type OrderScheduleDeliveryActions =
    ReturnType<typeof setOrderScheduleDeliveryAction>
    | ReturnType<typeof setScheduleDateAction>
    | ReturnType<typeof setScheduleTimeAction>
    | ReturnType<typeof clearOrderScheduleDelivery>;

export const orderScheduleDeliveryReducer = (
    state: scheduleDeliveryModel.IOrderScheduleDelivery = scheduleDeliveryHelper.getInitScheduleDelivery(),
    action: OrderScheduleDeliveryActions
): scheduleDeliveryModel.IOrderScheduleDelivery => {
    switch (action.type) {
        case CLEAR_ORDERSCHEDULEDELIVERY: {
            return scheduleDeliveryHelper.getInitScheduleDelivery();
        }
        case SET_ORDERSCHEDULEDELIVERY: {
            return action.scheduleDelivery;
        }
        case SET_SCHEDULEDATE: {
            return {
                ...state,
                DeliveryDate: action.date,
                DeliveryFormatDate: action.formatDate,
            };
        }
        case SET_SCHEDULETIME: {
            return {
                ...state,
                DeliveryTime: action.time
            };
        }
    }
    return state;
};