import { getBackendPath } from 'helpers/Uri';
import * as testDriveModel from 'model/ITestDrive';
import * as rdHelper from 'helpers/Uri';

export const getTestDriveLocations = async (
    country: string,
    model: testDriveModel.ITestDriveModel,
    variant: testDriveModel.TestDriveVariant
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            country: country,
            model: model,
            variant: variant
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/get-test-drive-locations`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getTestDriveModels = async (
    country: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: country }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/get-test-drive-models`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getTestDriveVariants = async (
    country: string,
    model: testDriveModel.ITestDriveModel
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            country: country,
            model: model
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/get-test-drive-variants`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getTestDriveAvailableDates = async (
    location: testDriveModel.ITestDriveLocation,
    model: testDriveModel.ITestDriveModel,
    variant: testDriveModel.TestDriveVariant,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            location: location,
            model: model,
            variant: variant,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/get-test-drive-available-dates`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getTestDriveAvailableTime = async (
    location: testDriveModel.ITestDriveLocation,
    model: testDriveModel.ITestDriveModel,
    variant: testDriveModel.TestDriveVariant,
    selectedDate: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            location: location,
            model: model,
            variant: variant,
            selectedDate: selectedDate
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/get-test-drive-available-time`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const saveTestDrive = async (
    testDrive: testDriveModel.ITestDrive,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(testDrive),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/TestDrive/save-test-drive`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};