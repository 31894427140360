import { getCMSAssets, getFrontendUrl } from 'helpers/Uri'
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';

import PaymentOptions, { ChildRef } from 'components/PaymentOptions';
import { setCountryStateAction } from 'stores/Session';
import { IAppState } from 'model/IAppState';
import { ICodeName } from 'model/ICommon';

import * as paymentOptionStore from 'stores/PaymentOptions';
import * as rdEnum from 'helpers/enum';
import * as rdHelper from 'helpers/Uri';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import * as vehicleStore from 'stores/Vehicle';
import * as selectedDeliveryStore from "stores/UserSelectedDelivery";
import * as paymentOptionsStore from "stores/PaymentOptions";
import * as customerTypeStore from "stores/CustomerType";
import * as defaultConfigStore from 'stores/DefaultConfig';
import * as linkSourceStore from "stores/LinkSource";

import { ILandingPage, ILandingPageRequest } from 'model/ILandingPage';
import * as defaultConfigModel from 'model/IDefaultConfig';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as availableInventoryModel from 'model/IAvailableInventory';
import * as paymentOptionsModel from 'model/IPaymentOptions';
import * as paymentOptionsService from 'services/PaymentOptions';
import * as vehicleService from 'services/Vehicle';
import * as landingPageHelper from 'businessHelpers/LandingPageHelper';

import { useLocation, useNavigate } from 'react-router-dom';

import { useCountryState } from 'helpers/useCountryState';
import ImportantInfoDialog from './landing/ImportantInfoDialog';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';
import DesignYourDialog from './landing/DesignYourDialog';
import { setMainPageNumber } from 'stores/MainPageNumber';
import { useScroll } from 'pages/Main/ScrollToSection';
import { setGlobalDropdown } from 'stores/GlobalDropdown';
import LocationPopUp from 'components/LocationPopUp';
import { useSatellite } from 'helpers/useSatellite';


interface IDialogOpen {
    isGlobalDialogOpen: boolean
}

interface IPreLoaderOpenProps {
    closePreLoader: boolean,
    isFromOtherPage: boolean,
}

const GlobalTheme = (props: IDialogOpen) => {

    const { isGlobalDialogOpen } = props

    const dispatch = useDispatch();
    const countryState = useCountryState();

    const navigate = useNavigate();
    const satellite = useSatellite();
    const paymentOpionsRef = useRef<ChildRef | null>(null);

    const [dialogVisible, setDialogVisible] = useState(true);

    const [loaderVisible, setLoaderVisible] = useState(true);

    const location = useLocation();
    const preloader = location.state as IPreLoaderOpenProps || { closeLoader: false, isFromOtherPage: false };

    const [initialLocation, setInitialLocation] = useState(location);

    const [isHovered, setIsHovered] = useState(false);

    const [loadPayment, setLoadPayment] = useState(0);

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const countryStateFromStore = useSelector((state: IAppState) => {
        return state.countryStateList;
    });
    //const customerTypeFromStore = useSelector((state: IAppState) => {
    //    return state.customerType;
    //});
    //const financeFromStore = useSelector((state: IAppState) => {
    //    return state.paymentOptions.Finance;
    //});

    useEffect(() => {
        setDialogVisible(isGlobalDialogOpen);
        dispatch(checkIsShowScrollBar(false));
    }, [isGlobalDialogOpen]);

    const menuGlobalBtn = useSelector((state: IAppState) => {
        return state.globalDropdown
    })

    //Crucial, next 2 useEffect manipulate the functions for display or hide the right scrollbar
    //Frist one, when preloader displays, hide 2.3s, until country state dialog popup
    useEffect(() => {

        const sections = document.querySelectorAll('.d2c-container');
        if (!preloader?.closePreLoader && !preloader?.isFromOtherPage) {
            sections.forEach((section: any) => {
                section.style.overflowY = 'hidden';
                // section.style.paddingRight = '15px';
            });

            const timeout = setTimeout(() => {
                sections.forEach((section: any) => {
                    section.style.overflowY = 'scroll';
                    // section.style.paddingRight = '0px';
                });
                setLoaderVisible(false);
            }, 2300); // 2.3 seconds timeout

            return () => clearTimeout(timeout); // Cleanup timeout on unmount or when loader becomes invisible
        } else if (preloader?.isFromOtherPage) {
            setLoaderVisible(false);
            setDialogVisible(false);
            sections.forEach((section: any) => {
                section.style.overflowY = 'scroll';
                // section.style.paddingRight = '0px';
            });
        } else {

            setLoaderVisible(false);
            setDialogVisible(false);
        }

    }, []);

    // console.log('Global Button' + menuGlobalBtn.vehicles, menuGlobalBtn.shop, !preloader?.closePreLoader)

    //Second one, when click global menu button and global dropdown panel collapse, hide until close the dropdown
    useEffect(() => {
        const sections = document.querySelectorAll('.d2c-container');

        if (menuGlobalBtn.vehicles || menuGlobalBtn.shop || loaderVisible) {
            sections.forEach((section: any) => {

                section.style.overflowY = 'hidden';
                // section.style.paddingRight = '0px';
            });
        } else {
            sections.forEach((section: any) => {
                section.style.overflowY = 'scroll';
                // section.style.paddingRight = '15px';
            });
        }
    }, [menuGlobalBtn]);

    const [countries, setCountries] = useState<ICodeName[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<ICodeName>();

    const [states, setStates] = useState<ICodeName[]>([]);
    const [selectedState, setSelectedState] = useState<any>(null);

    const doGetCountry = async () => {
        let country = await countryState.getCountry();
        setCountries(country);
    }

    const doGetStates = async () => {
        let states = await countryState.getStates(rdEnum.enumCountryCode.Australia);
        setStates(states);
    }


    useEffect(() => {
        doGetCountry();
    }, []);

    useEffect(() => {
        setSelectedCountry({ code: rdEnum.enumCountryCode.Australia, name: rdEnum.enumCountryName.Australia });
        doGetStates();
    }, [countryStateFromStore]);


    const [landingPage, setLandingPage] = useState<ILandingPage>(landingPageHelper.getInitLandingPage());

    const handleHover = () => {
        setIsHovered((prevIsHovered) => !prevIsHovered);
    };

    const onCountryChange = (e: any) => {
        setSelectedCountry(e.value);
    }

    const onStateChange = (e: any) => {
        setSelectedState(e.value);
    }

    const onHide = () => {
        if ((selectedCountry?.code == rdEnum.enumCountryCode.Australia && selectedState)
            || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand) {
            // document.body.style.overflowY = 'scroll';
            // document.body.style.paddingRight = '0px';
            dispatch(checkIsShowScrollBar(true));
            setDialogVisible(false);
            dispatch(setCountryStateAction({ Country: selectedCountry, State: selectedState }));
        }
    }

    const onSubmit = () => {
        if ((selectedCountry?.code == rdEnum.enumCountryCode.Australia && selectedState)
            || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand) {
            // document.body.style.overflowY = 'scroll';
            // document.body.style.paddingRight = '0px';
            dispatch(checkIsShowScrollBar(true));

            //Invoke the onHide function for 1. dispatch Country & State to store, 2. close dialog
            onHide();

            //clear selected vehicle data
            //dispatch(selectedConfigStore.clearSelectedConfigAction());

            //update country/state store before call landing page api as this api is too slow to impact on getting variant/exterior/interior
            let delivery: selectedConfigModel.ISelectedDelivery = {
                Country: selectedCountry,
                State: selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? null : selectedState,
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                SelectedDeliveryFullAddress: null,
                SelectedDeliveryPostcode: null,
                SelectedDeliveryState: null,
                SelectedDeliverySuburb: null,
                //EstDeliveryDate: null,
                PrimaryAddress: null,
                PrimaryPostcode: null,
                PrimarySuburb: null,
                PrimaryState: null,
                InvalidPrimaryAddress: null,
                DeliveryPrice: null,
                DeliveryAvailable: null,
                DeliveryPriceErrorCode: null,
                DeliveryDistance: null,
                PickupLocationName: null,
            };
            dispatch(selectedDeliveryStore.updateDeliveryAction(delivery));

            //default set payment options as cash
            dispatch(paymentOptionsStore.updateSelectedPaymentOptionAction(rdEnum.enumPaymentOptions.Cash));

            //default set customer type as private customer
            dispatch(customerTypeStore.setCustomerTypeAction(rdEnum.enumClientTypes.Private.Code));

            //Normal entry
            //if the users are from interest link or from inventory page, wont show this popup
            //so clear default config store for interest link and inventory
            let defaultConfig: defaultConfigModel.IDefaultConfig = {
                Country: null,
                State: null,
                VariantName: null,
                ExteriorName: null,
                InteriorName: null,
                Options: [],
                Loaded: false,
                LinkKey: null,
                ExpiryDateText: null
            }
            dispatch(defaultConfigStore.setDefaultConfigAction(defaultConfig));
            dispatch(linkSourceStore.setLinkSourceAction(null));

            satellite.trackEvent("config-start");
            
        }
    }

    useEffect(() => {

        if ((!isNil(selectedDeliveryFromStore?.Country?.code) && selectedDeliveryFromStore?.Country?.code == "AU"
            && !isNil(selectedDeliveryFromStore?.State?.code) && selectedDeliveryFromStore?.State?.code != "")
            || (!isNil(selectedDeliveryFromStore?.Country?.code) && selectedDeliveryFromStore?.Country?.code == "NZ")
        ) {
            //console.log("call landing page")
            doGetLandingPage();
        }
    }, [selectedDeliveryFromStore?.Country?.code, selectedDeliveryFromStore?.State?.code]);



    const doGetLandingPage = async () => {

        //country and state must be from store as users could be from interest link
        let request: ILandingPageRequest = {
            //...landingPage,
            Country: selectedDeliveryFromStore?.Country?.code || "",
            State: selectedDeliveryFromStore?.State?.code || "",
            Postcode: "",
            Suburb: "",
            CustomerType: rdEnum.enumClientTypes.Private.Code,
            Model: rdEnum.constModel
        };
        let response = await vehicleService.getLandingPage(request);
        if (response.status) {
            let landingData: ILandingPage = {
                Country: response.data.country,
                State: response.data.state,
                Postcode: response.data.postcode,
                Suburb: response.data.suburb,
                CustomerType: rdEnum.enumClientTypes.Private.Code,
                Model: request.Model,
                Subtotal: response.data.subTotal,
                DriveAwayCost: response.data.driveawayCost,
                Available: response.data.available,
                YanaVariantID: response.data.yanaVariantId,
                YanaVariantName: response.data.variantName,
                VariantPrice: response.data.variantPrice,
                ExteriorID: response.data.exteriorId,
                ExteriorPrice: response.data.exteriorPrice,
                InteriorID: response.data.interiorId,
                InteriorPrice: response.data.interiorPrice,
                LoadPaymentText: response.data.loanPaymentText,
                FrequentlyText: response.data.frequentlyText,
                Options: [{
                    YanaOptionID: null,
                    OptionType: response.data.optionType,
                    OptionName: response.data.optionName,
                    OptionPrice: response.data.optionPrice,
                    OptionPicture: [],
                    OptionPictureMobile: [],
                    OptionDesc: null,
                    ChargeType: null,
                    Disable: false,
                }]
            }
            setLandingPage(landingData);

            ////update available
            //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(response.data.available));


            ////get loan payment
            //doGetLoanPayment(response.data.driveawayCost);
        }
        else {
            setLandingPage(landingPageHelper.getInitLandingPage());
        }

    }


    const openCashFinanceDialog = () => {

        if (paymentOpionsRef.current) {
            paymentOpionsRef.current.openDialog();
            //dispatch(paymentOptionStore.updateSelectedPaymentOptionAction(rdEnum.enumPaymentOptions.Finance));
        }

    };

    const { scrollToSection } = useScroll();

    const toVariant = () => {
        setTimeout(() => {
            scrollToSection('variant')
        }, 200);
        dispatch(setMainPageNumber(0));
    }

    const toTestDrive = () => {
        // navigate(`${getFrontendUrl()}/test-drive`);
        // dispatch(setGlobalDropdown(false, false));
        // dispatch(checkIsShowScrollBar(true))
        window.open('https://www.cadillacanz.com/au-en/register');
    }

    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const globalContentBox = document.querySelector('.global-content-box');

    if (globalContentBox) {
        if (isAndroid) {
            globalContentBox.classList.add('android');
        } else if (isIOS) {
            globalContentBox.classList.add('ios');
        }
    }

    const [videoKey, setVideoKey] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            // Re-trigger the video by updating the key
            setVideoKey((prevKey: any) => prevKey + 1);
        };

        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {
                dialogVisible && loaderVisible && (
                    <div className='pre-loader'>
                        <img className="img" alt="Info icon" src={`${getCMSAssets()}/home/brand-loader.gif`} />
                    </div>
                )
            }

            {/* *****Initial Location Popup, Very Important**** */}
            {/* <Dialog header={isNil(selectedCountry?.code) || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? "SELECT YOUR COUNTRY" : 'SELECT YOUR STATE'}
                className='global-dialog' visible={dialogVisible && !loaderVisible} position='bottom' style={{ width: '560px' }} draggable={false} resizable={true}
                onHide={() => onHide()}>
                <div className='dropdown-container'>
                    <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={(e) => onCountryChange(e)}
                        className='global-location-dropdown'
                        optionLabel="name"
                        placeholder="Country"
                    />

                    <Dropdown
                        value={selectedState}
                        options={states}
                        onChange={(e) => onStateChange(e)}
                        className='global-location-dropdown'
                        style={{ display: selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? 'none' : '' }}
                        optionLabel="name"
                        placeholder="State"
                        disabled={!selectedCountry}
                    />
                </div>

                <Button className='global-location-btn'
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    onClick={() => onSubmit()}
                >
                    <span >SUBMIT</span>
                </Button>
            </Dialog> */}
            {/* *****Initial Location Popup, Very Important**** */}

            <Dialog header={"SELECT YOUR COUNTRY"}
                className='global-dialog' visible={dialogVisible && !loaderVisible} position='bottom' draggable={false} resizable={true}
                onHide={() => onHide()}>
                <Row className='temp-location-container'>
                    <Col lg={6} xs={12} className='temp-au-container'>
                        <div className='dropdown-container'>
                            <div className='country-title'>
                                AUSTRALIA
                            </div>
                            <br />
                            <div className='reminder-box'>
                                Please select your state to proceed.
                            </div>
                            {/*<Dropdown*/}
                            {/*    value={selectedCountry}*/}
                            {/*    options={countries}*/}
                            {/*    onChange={(e) => onCountryChange(e)}*/}
                            {/*    className='global-location-dropdown'*/}
                            {/*    optionLabel="name"*/}
                            {/*    placeholder="Country"*/}
                            {/*/>*/}

                            <Dropdown
                                value={selectedState}
                                options={states}
                                onChange={(e) => onStateChange(e)}
                                className='global-location-dropdown'
                                style={{ display: selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? 'none' : '' }}
                                optionLabel="name"
                                placeholder="State"

                            />

                            {/*disabled={!selectedCountry}*/}
                        </div>

                        <Button className='global-location-btn'
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                            onClick={() => onSubmit()}
                        >
                            <span >SUBMIT</span>
                        </Button>
                    </Col>

                    <Col lg={6} xs={12} className='temp-nz-container'>
                        <div className='dropdown-container'>
                            <div className='country-title'>
                                NEW ZEALAND
                            </div>
                            <div className='coming-soon'>
                                COMING SOON
                            </div>
                            <br />
                            <div className='new-zealand-info-panel'>
                                We're busy building an iconic design and build experience for New Zealand customers.
                            </div>
                            <br />
                            <div className='new-zealand-info-panel'>
                                We can't wait to welcome you. Be sure to sign up for Cadillac brand, product and order updates below.
                            </div>
                        </div>

                        <Button className='global-location-btn'
                            // onMouseEnter={handleHover}
                            // onMouseLeave={handleHover}
                            onClick={() => toTestDrive()}
                        >
                            <span >KEEP UPDATED</span>
                        </Button>
                    </Col>
                </Row>
            </Dialog>            

            {
                !dialogVisible && (
                    <div className='global-content-page'>
                        <div className='global-content-container'>
                            <div className='global-content-box'>
                                <DesignYourDialog />
                                <div className='content-brand-name'>CADILLAC LYRIQ</div>
                                <div className='content-desc'>
                                    <p> Starting at {rdHelper.currencyFormatWithBlank(landingPage?.DriveAwayCost || 0)}<sup>1</sup>&nbsp; {/*&nbsp;or {landingPage.LoadPaymentText}/{landingPage.FrequentlyText}<sup>2</sup>*/}</p>

                                    {!isNil(landingPage?.Available) && landingPage?.Available != "" && (
                                        <p>
                                            Available {landingPage?.Available}
                                            {/* <sup>3</sup> */}
                                            <sup>2</sup>
                                        </p>
                                    )}
                                </div>
                                {/*{!isNil(landingPage?.Available) && landingPage?.Available != "" && (*/}
                                {/*    <div className='estimate-time'>*/}
                                {/*        Available {landingPage?.Available}*/}
                                {/*        <sup>3</sup>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*<MoreInfo />*/}
                                <div className='global-popup-container'

                                >
                                    <Button className='calculate-finance-btn' onClick={() => {
                                        openCashFinanceDialog()
                                    }}>
                                        <h6>CALCULATE FINANCE</h6>
                                    </Button>
                                </div>

                                {/*todo:*/}
                                <PaymentOptions ref={paymentOpionsRef}
                                    isFromLandingPage={true}
                                    subTotal={landingPage?.Subtotal || 0}
                                    estDeliveryDate={landingPage?.Available || ""}
                                    variantID={landingPage?.YanaVariantID || ""}
                                    variantName={landingPage?.YanaVariantName || ""}
                                    variantPrice={landingPage?.VariantPrice || 0}
                                    exteriorID={landingPage?.ExteriorID || ""}
                                    exteriorName={""}
                                    exteriorPrice={landingPage?.ExteriorPrice || 0}
                                    interiorID={landingPage?.InteriorID || ""}
                                    interiorName={""}
                                    interiorPrice={landingPage?.InteriorPrice || 0}
                                    options={landingPage?.Options || []}
                                />
                            </div>

                            {/* <div className='video-panel'> */}
                            <video key={videoKey} className="video" preload="none" muted autoPlay playsInline>
                                <source src={`${getCMSAssets()}/home/LyriqIntroAnimationVideo.mp4`} type="video/mp4" />
                            </video>
                            {/* </div> */}

                        </div>

                        <div className='global-bottom-scroll'>
                            {/* <div className='important-info-box'>
                                <p>Important Information</p>
                            </div> */}
                            <ImportantInfoDialog />
                            <img className="img" alt="Info icon" src={`${getCMSAssets()}/home/scroll_indicator.348068d6.gif`} />
                            <div className='scroll-to-start' onClick={() => toVariant()}>Scroll to start</div>
                        </div>

                        <div className='global-bottom-panel'></div>
                    </div>
                )
            }
        </>
    )
}

export default GlobalTheme