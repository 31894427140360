///used in my order details, "provide your details" popup

import * as orderCustomerDetails from 'model/IOrderCustomerDetails';

export const SET_ORDERCUSTOMERDETAILS = 'setOrderCustomerDetails';
export const setOrderCustomerDetailsAction = (customerDetails: orderCustomerDetails.IOrderCustomerDetails | null) =>
({
    type: SET_ORDERCUSTOMERDETAILS,
    customerDetails,
} as const);

export const CLEAR_ORDERCUSTOMERDETAILS = 'clearOrderCustomerDetails';
export const clearOrderCustomerDetailsAction = () =>
({
    type: CLEAR_ORDERCUSTOMERDETAILS
} as const);

export type OrderCustomerDetailsActions =
    ReturnType<typeof setOrderCustomerDetailsAction>
    | ReturnType<typeof clearOrderCustomerDetailsAction>;

export const orderCustomerDetailsReducer = (
    state: orderCustomerDetails.IOrderCustomerDetails | null = null,
    action: OrderCustomerDetailsActions
): orderCustomerDetails.IOrderCustomerDetails | null => {
    switch (action.type) {
        case CLEAR_ORDERCUSTOMERDETAILS: {
            return null;
        }
        case SET_ORDERCUSTOMERDETAILS: {
            return action.customerDetails;
        }
    }
    return state;
};