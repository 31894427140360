
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import * as vehicleModel from 'model/IVehicle';
import { useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';

const CarColorSelected = (props: any) => {

    const { color, handleColor, colorList} = props;

    const handleChangeColor = (carColor: any ) => {
        handleColor(carColor);
    }

    const exteriorListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.ExteriorList;
    });

    if (!isNil(colorList)) {
        return (
            <>
                {!isNil(colorList) && colorList != null &&
                    colorList.length > 0 && (colorList as vehicleModel.IExterior[]).map((item: vehicleModel.IExterior, index: number) => {
                        return (
                            <div key={"exterior_" + index}
                                className={classNames({
                                    "stat-image-link": true,
                                    "color-options-item": true,
                                    "active": item.ExteriorColorName == color
                                })}
                                onClick={(e: any) => {
                                    handleChangeColor(item.ExteriorColorName)
                                }}
                                data-dtm="modal:lyriq specifications"
                            >
                                <div className='color-options-round'>
                                    <img className="image" src={item?.ExteriorFilterImage || undefined} alt={item.ExteriorColorName || ""}/>
                                </div>
                            </div>
                        );

                    })
                }
            </>
        )
    }
    else {
        return (
            <>
                {!isNil(exteriorListFromStore) && exteriorListFromStore != null &&
                    exteriorListFromStore.length > 0 && (exteriorListFromStore as vehicleModel.IExterior[]).map((item: vehicleModel.IExterior, index: number) => {
                        return (
                            <div key={"exterior_" + index}
                                className={classNames({
                                    "color-options-item": true,
                                    "active": item.ExteriorColorName == color
                                })}
                                onClick={(e: any) => {
                                    handleChangeColor(item.ExteriorColorName)
                                }}
                            >
                                <div className='color-options-round'>
                                    <img className="image" src={item?.ExteriorFilterImage || undefined} />
                                </div>
                            </div>
                        );

                    })
                }
            </>
        )
    }
}

export default CarColorSelected