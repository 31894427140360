import { ISelectedCountryState } from "model/ICountryStateList";
import { ISessionState } from "model/ISessionState";
// Session
export const GOT_SESSION = 'GotSession';
export const gotSessionAction = (session: ISessionState | null) =>
({
    type: GOT_SESSION,
    session,
} as const);
export const CLEAR_SESSION = 'ClearSession';
export const clearSessionAction = () =>
({
    type: CLEAR_SESSION
} as const);
type SessionActions = ReturnType<typeof gotSessionAction>
    | ReturnType<typeof clearSessionAction>;


export const initialSessionState: ISessionState = {
    //Memberhsipno: "",
    //FirstName: "",
    //LastName: "",
    //Address: "",
    //Suburb: "",
    //State: "",
    //Postcode: "",
    Email: "",
    //Phone: "",
    //UserType: 0,
    YanaCustomerID: "",
    YanaCustomerNo: ""
};
export const sessionReducer = (
    state: ISessionState | null = null,
    action: SessionActions,
): ISessionState | null => {
    switch (action.type) {
        case CLEAR_SESSION: {
            return null;
        }
        case GOT_SESSION: {
            return action.session;
        }
    }

    return state;
};


//Country & State
export const GOT_COUNTRY_STATE = 'GotCountryState';
export const setCountryStateAction = (countryInfo: ISelectedCountryState | null) =>
({
    type: GOT_COUNTRY_STATE,
    countryInfo,
} as const);
export const CLEAR_COUNTRY_STATE = 'ClearSession';
export const clearCountryStateAction = () =>
({
    type: CLEAR_SESSION
} as const);
type CountryStateActions = ReturnType<typeof setCountryStateAction>
    | ReturnType<typeof clearCountryStateAction>;


export const initialCountryState: ISelectedCountryState = {
    Country: {
        name: "",
        code: ""
    },
    State: {
        name: "",
        code: ""
    },
};
export const countryStateReducer = (
    state: ISelectedCountryState | null = null,
    action: CountryStateActions,
): ISelectedCountryState | null => {
    switch (action.type) {
        case CLEAR_SESSION: {
            return null;
        }
        case GOT_COUNTRY_STATE: {
            return action.countryInfo;
        }
    }

    return state;
};