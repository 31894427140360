import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { getCMSAssets, getGoogleMapAPIKey, getImageFileNameByID2 } from 'helpers/Uri';
import { isNil } from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { IAppState } from 'model/IAppState';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as deliveryModel from 'model/IDelivery';
import * as orderModel from 'model/IOrder';
import * as googleApiService from 'services/GoogleApi';
import * as locationService from 'services/Locations';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';

interface googleMapState {
    googleMapAPIKey: string,
    zoom: number,
    containerStyle: any,
    center: any,
    activeMarker: any | null,
}


const SelectLocation = () => {

    const [deliveryLocation, setDeliveryLocation] = useState<any>(null);
    const [distance, setDistance] = useState<number>(0);

    const orderScheduleDeliveryFromStore: scheduleDeliveryModel.IOrderScheduleDelivery = useSelector((state: IAppState) => {
        return state?.orderScheduleDelivery;
    });
    const orderDetailsFromStore: orderModel.IOrderRequest | null = useSelector((state: IAppState) => {
        return state?.orderDetails;
    });
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });

    const isMobile = useCheckMobileScreen();

    const initGoogleMapState: googleMapState = {
        googleMapAPIKey: getGoogleMapAPIKey(),
        zoom: 12,
        containerStyle: {
            width: '100%',
            height: '500px'
        },
        center: {
            lat: -37.840935,
            lng: 144.946457,
        },
        activeMarker: null
    };
    const [googleMapState, setGoogleMapState] = useState<googleMapState>(initGoogleMapState);

    const { isLoaded } = useJsApiLoader({
        //id: 'google-map-script',
        googleMapsApiKey: googleMapState.googleMapAPIKey,
        region: "AU",
        language: "en-AU"
    });


    useEffect(() => {

        if (!isNil(orderScheduleDeliveryFromStore)) {
            doGetGeometryLocation();
        }

    }, [orderScheduleDeliveryFromStore]);


    useEffect(() => {
        if (!isNil(orderDetailsFromStore)) {
            doGetDistance();
        }
    }, [orderDetailsFromStore]);

    const doGetGeometryLocation = async () => {
        let response = await googleApiService.getGeometryLocation(orderScheduleDeliveryFromStore?.Country, orderScheduleDeliveryFromStore.DeliveryLocationAddress);
        if (response.status) {
            setDeliveryLocation(response.data);
            setGoogleMapState({
                ...googleMapState,
                center: {
                    lat: response.data.lat,
                    lng: response.data.lng,
                }
            })
        }
    }

    const doGetDistance = async () => {
        let request: deliveryModel.IGetPriceRequest = {
            Country: orderDetailsFromStore?.Country || "",
            DeliveryAddress: orderDetailsFromStore?.DeliveryLocationAddress || "",
            DeliveryState: orderDetailsFromStore?.DeliveryLocationState || "",
            DeliveryPostcode: orderDetailsFromStore?.DeliveryLocationPostcode || "",
            DeliverySuburb: orderDetailsFromStore?.DeliveryLocationSuburb || "",
            PickupAddress: orderDetailsFromStore?.RegoAddress || "",
            PickupState: orderDetailsFromStore?.RegoState || "",
            PickupPostcode: orderDetailsFromStore?.RegoPostcode || "",
            PickupSuburb: orderDetailsFromStore?.RegoSuburb || "",
            CustomerType: orderDetailsFromStore?.CustomerType || "",
            VariantName: orderDetailsFromStore?.VariantName || "",
            VehicleSubTotal: selectedVehicleFromStore?.Subtotal || 0,
        }
        let response = await locationService.getDistance(request);
        if (response.status) {
            setDistance(response.data);
        }
        else {
            setDistance(0);
        }
    }


    return (
        <Row className='select-location-popup-container'>
            <Col lg={4} className='select-location-popup-context'>
                <div className="service-centre-items">
                    <h6>Deliver To:</h6>
                    <p>{orderScheduleDeliveryFromStore?.PickupLocationName}</p>
                    <div className="delivery-address">
                        <p>{orderScheduleDeliveryFromStore?.DeliveryLocationAddress}</p>
                        <span>{distance} km</span>
                    </div>
                    <div className="delivery-address">
                        <span>Earliest availability</span>
                        <span>{orderScheduleDeliveryFromStore?.Available}</span>
                    </div>
                </div>
                <div className='need-support-container'>
                    <h6>Need support?</h6>
                    <p>If you wish to change your delivery option</p>
                    <span>Please call 1800 CADILLAC (223455) for assistant.</span>
                </div>
            </Col>
            <Col lg={8} className='select-location-popup-map'>
                {
                    isLoaded && (
                        <GoogleMap
                            mapContainerStyle={googleMapState.containerStyle}
                            center={googleMapState.center}
                            zoom={googleMapState.zoom}
                        >
                            {!isNil(orderScheduleDeliveryFromStore?.DeliveryLocationAddress) && !isNil(deliveryLocation) && (

                                <React.Fragment>
                                    <Marker position={deliveryLocation} onClick={() => {
                                        setGoogleMapState({
                                            ...googleMapState,
                                            activeMarker: orderScheduleDeliveryFromStore?.DeliveryLocationAddress
                                        });
                                    }}>
                                        {/*{!isNil(googleMapState.activeMarker) && (*/}
                                        {/*    <InfoWindow position={deliveryLocation}*/}
                                        {/*        onCloseClick={() => {*/}
                                        {/*            setGoogleMapState({ ...googleMapState, activeMarker: null });*/}
                                        {/*        }}>*/}
                                        {/*        <div className="search-dealer-result-for-map">*/}
                                        {/*            {orderScheduleDeliveryFromStore?.DeliveryLocationAddress}*/}
                                        {/*        </div>*/}
                                        {/*    </InfoWindow>*/}
                                        {/*)}*/}
                                    </Marker>
                                </React.Fragment>

                            )}
                        </GoogleMap>
                    )
                }
            </Col>
        </Row>
    )
}

export default SelectLocation