import { getBackendPath } from 'helpers/Uri';
import * as orderModel from 'model/IOrder';
import * as checkOutViewModel from 'model/ICheckoutWaitlistViewModel';
import * as rdHelper from 'helpers/Uri';
export const payment = async (
    payment: any,
    currency: any,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            payment: payment,
            currency: currency,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/payment`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getNewPaymentIDs = async (
    orderID: any,
    Country: any,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            orderID: orderID,
            Country: Country,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Payment/get-new-payment-ids`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const savePaymentLog = async (
    order: orderModel.IOrderRequest,
    adyenSession: checkOutViewModel.IAdyenSession
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order: order,
            adyenSession: adyenSession,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Payment/save-payment-log`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};
//export const getNewRDTransactionID = async (
//    orderID: any,
//    Country: any,
//): Promise<any> => {
//    const requestOptions: any = {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json',
//        },
//        body: JSON.stringify({
//            orderID: orderID,
//            Country: Country,
//        }),
//        credentials: 'include',
//    };
//    const response = await fetch(
//        `${getBackendPath()}/api/Payment/get-new-rdtransactionid`,
//        requestOptions,
//    );
//    const json = await response.json();
//    return json;
//};