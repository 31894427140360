import * as checkOutWaitlstViewModel from 'model/ICheckoutWaitlistViewModel';
import * as deliveryModel from 'model/IDelivery';
import * as orderModel from 'model/IOrder';
import * as configModel from 'model/IUserSelectedConfig';

export const getCheckoutInitPageState = (): checkOutWaitlstViewModel.ICheckoutWaitlistProps => {
    let isDev = `${process.env.REACT_APP_DevModel}`;
    if (isDev == "1") {
        let initPageState: checkOutWaitlstViewModel.ICheckoutWaitlistProps = {
            businessname: "",
            abn: "",
            firstname: "sharon",
            lastname: "huang",
            email: "SharonH@resultsdirect.com.au",
            confirmEmail: "SharonH@resultsdirect.com.au",
            mobile: "1234567901",
            address: "43 Dalgety Street",
            address2: "ccc",
            suburb: "Oakleigh",
            states: null,
            postcode: "3166",
            agreement: true,
            comment: "",
            invalidBusinessname: false,
            invalidABN: false,
            invalidABNLength: false,
            invalidFirstname: false,
            invalidLastname: false,
            invalidEmail: false,
            invalidConfirmEmail: false,
            invalidMobile: false,
            invalidAddress: false,
            invalidSuburb: false,
            emailMismatch: false,
            emailDuplicated: false,
            invalidAgreement: false,
            invalidState: false,
            invalidPostcode: false
        };
        return initPageState;
    }
    else {
        let initPageState: checkOutWaitlstViewModel.ICheckoutWaitlistProps = {
            businessname: "",
            abn: "",
            firstname: "",
            lastname: "",
            email: "",
            confirmEmail: "",
            mobile: "",
            address: "",
            address2: "",
            suburb: "",
            states: null,
            postcode: "",
            agreement: true,
            comment: "",
            invalidBusinessname: false,
            invalidABN: false,
            invalidABNLength: false,
            invalidFirstname: false,
            invalidLastname: false,
            invalidEmail: false,
            invalidConfirmEmail: false,
            invalidMobile: false,
            invalidAddress: false,
            invalidSuburb: false,
            emailMismatch: false,
            emailDuplicated: false,
            invalidAgreement: false,
            invalidState: false,
            invalidPostcode: false
        };
        return initPageState;
    }


};

export const getOrderInitState = (): orderModel.IOrderRequest => {
    let initState: orderModel.IOrderRequest = {
        Country: "",
        State: null,
        VariantName: "",
        VariantPrice: null,
        ExteriorName: "",
        ExteriorPrice: null,
        InteriorName: "",
        InteriorPrice: null,
        VehicleImages: [],
        VehicleThumbnail: null,
        Options: null,
        DeliveryLocationState: null,
        DeliveryLocationSuburb: null,
        DeliveryLocationPostcode: null,
        DeliveryLocationAddress: null,
        DeliveryOption: null,
        PickupLocationName: null,
        Available: null,
        PaymentOption: null,
        DriveawayCost: null,
        Finance: null,
        Email: null,
        FirstName: null,
        LastName: null,
        Mobile: null,
        ABN: null,
        BusinessName: null,
        CustomerType: null,
        BillingAddress: null,
        BillingSuburb: null,
        BillingPostcode: null,
        BillingState: null,
        RegoAddress: null,
        RegoSuburb: null,
        RegoPostcode: null,
        RegoState: null,
        NameOnCreditCard: null,
        PrivacyStatement: null,
        RDAcountID: null,
        RDPaymentID: null,
        OrderID: null,
        YanaOrderReservationID: null,
        YanaOrderReservationNo: null,
        YanaOrderID: null,
        YanaOrderNo: null,
        YanaCustomerID: null,
        YanaCustomerNo: null,
        FromLinkSource: null,
        PaymentHistory: null,
        DownPayment: null,
        PaymentDueDate: null,
        FinalPaymentAmount: null,
        Total: null,
        Balance: null,
        PaymentAmount: null,
    };

    return initState;
}


export const getPaymentInitState = (): checkOutWaitlstViewModel.IPaymentProps => {
    let isDev = `${process.env.REACT_APP_DevModel}`;
    if (isDev == "1") {
        let initPaymentState: checkOutWaitlstViewModel.IPaymentProps = {
            nameOnCard: "sharon",
            cardNumber: "5555555555554444",
            expiryMonth: null,
            expiryYear: null,
            cvv: "737",
            invalidNameOnCard: false,
            invalidCardNumber: false,
            invalidExpiryMonth: false,
            invalidExpiryYear: false,
            invalidCVV: false,
        };
        return initPaymentState;

    }
    else {
        let initPaymentState: checkOutWaitlstViewModel.IPaymentProps = {
            nameOnCard: "",
            cardNumber: "",
            expiryMonth: null,
            expiryYear: null,
            cvv: "",
            invalidNameOnCard: false,
            invalidCardNumber: false,
            invalidExpiryMonth: false,
            invalidExpiryYear: false,
            invalidCVV: false,
        };
        return initPaymentState;
    }
}

export const getDeliveryInitState = (): deliveryModel.IDelivery => {
    let initState: deliveryModel.IDelivery = {
        Country: "",
        State: "",
        //PrimaryAddress: "",
        //PrimaryPostcode: "",
        //PrimarySuburb: "",
        //PrimaryState: "",
        //PickupLocations: [],
        DeliveryOption: "",
        DifferentDeliveryAddress: null,
        //DeliveryAddress: "",
        //DeliverySuburb: "",
        //DeliveryPostcode: "",
        //DeliveryState: "",
        PickupAddressConfirm: null,
        //DeliveryAvailable: null,
        //DeliveryPrice: null,
        //DeliveryDistance: null,
    };

    return initState;

}

export const getInitDeliveryAddress = (): deliveryModel.IDeliveryAddress => {
    let initState: deliveryModel.IDeliveryAddress = {
        fullAddress: "",
        postcode: "",
        suburb: "",
        state: "",
    };

    return initState;
}

export const getInitDeliveryAddressAvailableDetails = (): deliveryModel.IDeliveryAddressAvailableDetails => {
    let initState: deliveryModel.IDeliveryAddressAvailableDetails = {
        PickupLocationAvailable: null,
        DeliveryAvailable: null,
        Distance: null,
        DeliveryPrice: null,
        DeliveryPriceError: null,
    };

    return initState;
}



export const getInitInvalidDelivery = (): deliveryModel.IInvalidDelivery => {
    let initState: deliveryModel.IInvalidDelivery = {
        InvalidDeliveryType: false,
        InvalidPrimaryAddress: false,
        InvalidPrimaryState: false,
        InvalidPickupLocation: false,
        InvalidDeliveryAddress: false,
        InvalidDeliveryState: false,
    };

    return initState;
}

export const getAdyenInitState = (): checkOutWaitlstViewModel.IAdyenSession => {
    let initState: checkOutWaitlstViewModel.IAdyenSession = {
        SessionID: null,
        SessionData: null,
        SessionResult: null,
        ResultCode: null,
        ErrorMessage: null
    };

    return initState;
}
