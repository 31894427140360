import React, {
    createRef,
    useRef,
    useLayoutEffect,
    useState,
    useContext,
    createContext,
    useEffect
} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { MainLayoutPage } from 'LayoutPages';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { TabView, TabPanel } from 'primereact/tabview';

import {
    IPageLanguageState,
    getCMSAssets,
    getFrontendUrl,
} from 'helpers/Uri';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { MainMenu } from 'scene/Layout/MainMenu';

import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { Footer } from 'scene/Layout/Footer';


import { sendForgotPasswordEmail } from 'services/User';
import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';
import Spinner from 'components/Spinner';

interface pageState {
    languageState: IPageLanguageState,
}

interface SignInState {
    isSent: boolean | null,
    email: string,
    password: string,
    invalidEmail: boolean | null,
    invalidPassword: boolean | null,
}

export const ForgotPasswordPage = () => {
    const gaEventTracker = useAnalyticsEventTracker('Forgot Password');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);


    /*page state*/
    const initPageState: SignInState = {
        isSent: false,
        email: "",
        password: "",
        invalidEmail: false,
        invalidPassword: false,
    }
    const [pageState, setPageState] = useState<SignInState>(initPageState);

    const isMobile = useCheckMobileScreen();

    //Scroll to top instantly without any animation
    if (isMobile) {
    //     window.scrollTo({top: 0, behavior: 'instant'} as any);
    // } else {
        document.querySelector(".container-has-header")?.scrollTo({ top: 0, behavior: 'instant' } as any);
    }

    const handleGoBack = () => {
        navigate(`${getFrontendUrl()}/sign-in`);
    }

    const handleSent = async () => {

        const emailValidator = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pageState?.email);

        if (isNil(pageState.email) || pageState.email == "" || emailValidator) {
            setPageState({ ...pageState, invalidEmail: true });
            return;
        }

        setErrorMessage("");
        navigate(`${getFrontendUrl()}/email-verification`);
        // setLoading(true);
        // const doResetPassword = await sendForgotPasswordEmail(pageState.email);
        // setLoading(false);
        // if (doResetPassword.status) {
        //     setPageState({
        //         ...pageState,
        //         isSent: true,
        //     });
        // }
        // else {
        //     setErrorMessage(doResetPassword.message);
        // }
    }


    return (
        <>
          
                <div className="page-container-fullsize password-issue-container" >
                    <div className='user-left-panel'>
                    <img alt="" className='image' src={`${getCMSAssets()}/users/sign-in-up.png`} />
                    </div>
                    <div className='user-right-panel' >
                        <div className='right-inner-container'>
                            <div className='logo-title-container'>
                            <img alt="" className='logo-image' onClick={() => handleGoBack()} src={`${getCMSAssets()}/users/Cadillac-Logo.svg`} />
                                <h5 className='sign-in-title'>Already have an account? <a className='sign-in-link' onClick={() => handleGoBack()}>Sign in</a></h5>
                            </div>
                            
                            <div className='handle-password-container'>
                                <div className='handle-password-title'>
                                    Forgot your password?
                                </div>
                                <div className='handle-password-subtitle'>
                                    Don't worry, we'll help your. Enter your email address below to confirm your account.
                                </div>
                                <div className='input-item'>
                                    <span className="wrapper-box">
                                        <InputText id="email" aria-describedby="email-help"
                                            className={classNames({ "p-invalid": pageState?.invalidEmail })}
                                            placeholder=" "
                                            value={pageState.email}
                                            onChange={(e) => {
                                                setPageState({
                                                    ...pageState,
                                                    email: e.target.value,
                                                    invalidEmail: isNil(e.target.value) || e.target.value == ""
                                                })
                                            }}
                                        />
                                        <label>Email</label>
                                    </span>
                                    {pageState?.invalidEmail && (
                                        <small id="email-help" className="p-error block">Email is required.</small>
                                    )}
                                </div>

                                <div className='handle-password-desc'>
                                    To protect your account. We will send a verification code to your email address. Your code will expire after 30 minutes.
                                </div>
                            </div>

                            <div className='handle-password-btn-panel'>
                                {!loading && (
                                    <Button onClick={() => handleSent()}><span>SUBMIT</span></Button>
                                )}
                                {loading && (
                                    <div className="spinner-wrapper">
                                        {/* <ProgressSpinner
                                            style={{ width: '16px', height: '16px' }}
                                            strokeWidth="5"
                                            animationDuration=".8s"
                                        /> */}
                                        <Spinner spinnerWidth={64} />
                                    </div>
                                )}


                                <Button onClick={() => handleGoBack()}><span>CANCEL</span></Button>
                            
                            </div>
                        </div>
                    </div>
                </div>
           
        </>
    );
};

