import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { isNil } from 'lodash';

import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { MainLayoutPage } from 'LayoutPages';
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri';

import * as interestModel from 'model/IInterest';
import * as interestService from 'services/Interest';
import * as configModel from 'model/IUserSelectedConfig';
import * as vehicleModel from 'model/IVehicle';
import { Footer } from 'scene/Layout/Footer';
import * as orderModel from 'model/IOrder';

import * as rdEnum from 'helpers/enum';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { useDispatch } from 'react-redux';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';

const OrderWaitlisted = () => {
    //const { waitlistId, waitlistNo } = useParams();
    const { webkey } = useParams();

    const [interest, setInterest] = useState<interestModel.IInterest>();

    const isMobile = useCheckMobileScreen();

    const dispatch = useDispatch();

    var currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    useEffect(() => {
        if (!isNil(webkey) && webkey != "") {
            doGetWaitlist(webkey);
        }
    }, [webkey]);

    useEffect(() => {
        dispatch(checkIsShowScrollBar(true));
    }, []);

    const doGetWaitlist = async (webkey: any) => {
        let response = await interestService.getWaitlist(webkey);
        if (response.status) {
            let responseData = response.data;
            let interestFromBackend: interestModel.IInterest = {
                Country: responseData.country,
                State: responseData.state,
                VariantName: responseData.variantName,
                VariantBasePrice: responseData.variantBasePrice,
                ExteriorName: responseData.exteriorName,
                ExteriorPrice: responseData.exteriorPrice,
                InteriorName: responseData.interiorName,
                InteriorPrice: responseData.interiorPrice,
                FirstName: responseData.firstName,
                LastName: responseData.lastName,
                Mobile: responseData.mobile,
                Email: responseData.email,
                ABN: null,
                BusinessName: null,
                CustomerType: responseData.customerType,
                PaymentOption: responseData.paymentOption,
                DeliveryOption: responseData.deliveryOption,
                DeliveryAddress: responseData.deliveryLocationAddress,
                DeliveryLocationState: responseData.deliveryLocationState,
                DeliveryLocationSuburb: responseData.deliveryLocationSuburb,
                DeliveryLocationPostcode: responseData.deliveryLocationPostcode,
                EstDeliveryDate: responseData.estDeliveryDate,
                PrivacyStatement: true,
                Marketing: responseData.marketing,
                InterestType: responseData.interestType,
                Comment: responseData.comment,
                WebUniqueKey: null,
                Options: getInterestOptions(responseData.options),
                VehicleThumbnail: responseData.vehicleThumbnail
            };
            setInterest(interestFromBackend);
        }
    }

    const getInterestOptions = (optionData: any): vehicleModel.IVehicleOptions[] | null => {
        let configs: vehicleModel.IVehicleOptions[] = [];
        optionData.map((item: any) => {
            configs.push({
                YanaOptionID: "",
                OptionType: item.optionType,
                OptionName: item.optionName,
                OptionPrice: item.optionPrice,
                OptionPicture: [],
                OptionPictureMobile: [],
                OptionDesc: item.optionDesc,
                ChargeType: item.chargeType,
                Disable: false
            });
        });
        return configs;
    }

    const showPrice = (price: number | null | undefined) => {
        if (price == null || price == undefined || price == 0)
            return "Included";
        return currencyFormatter.format(price);
    }

    const optionsTemplate = (options: vehicleModel.IVehicleOptions[] | null | undefined, optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(options) && options != undefined && options.length > 0) {
            let selectedOptions = options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions.map((optionItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='details-desc' key={optionType + "_" + index}>
                            <p>{optionItem?.OptionName}</p>
                            <span>{showPrice(optionItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<></>);
        }
        else {
            template.push(
                <div className='details-item'>
                    <div className='details-subtitle'>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</div>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }

    }, []);


    return (

        <MainLayoutPage sceneId='container-has-header'>
            <GlobalMenu />

            <div className='purchasing-procedure-container'>
                <div className='successful-header' style={{ background: !isMobile ? '#282828' : '#fff', aspectRatio: '1440/431' }}>
                    <div className='success-title'>
                        <h1>THANK YOU</h1>
                        <h1>{interest?.FirstName?.toUpperCase()}</h1>
                    </div>
                    <div className='success-info'>
                        <p style={{ maxWidth: '400px' }}>Your configuration has been added to our waitlist, and we will be in contact as soon as we are in the position to move forward with your order.</p>
                    </div>
                    {
                        isMobile
                            ?
                            <div className='black-bg-panel waitlisted'> </div>
                            :
                            <></>
                    }

                    <img alt="" className='purchased-vehicle waitlisted' src={!isNil(interest?.VehicleThumbnail) && interest?.VehicleThumbnail != "" ? interest?.VehicleThumbnail || "" : `${getCMSAssets()}/home/Luxury-Argent Silver Metallic.png`} />
                </div>
                <div className='procedure-container'>
                    <div className='procedure-title'>
                        Waitlisted
                    </div>
                    <div className='procedure-subtitle'>
                        Here’s a short summary of your LYRIQ selections.
                    </div>
                    <div className='procedure-map'>

                    </div>
                </div>

                <div className='purchasing-details'>
                    <div className='selection-panel'>
                        <div className='section-title'>
                            YOUR SELECTION
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Variant</div>
                            <div className='details-desc'>
                                <p>{interest?.VariantName}</p>
                                <span>{showPrice(interest?.VariantBasePrice)}</span>
                            </div>
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Exterior</div>
                            <div className='details-desc'>
                                <p>{interest?.ExteriorName}</p>
                                <span>{showPrice(interest?.ExteriorPrice)}</span>
                            </div>
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Interior</div>
                            <div className='details-desc'>
                                <p>{interest?.InteriorName}</p>
                                <span>{showPrice(interest?.InteriorPrice)}</span>
                            </div>
                        </div>

                        {optionsTemplate(interest?.Options, rdEnum.enumVehicleOptionType.Accessories)}
                        {optionsTemplate(interest?.Options, rdEnum.enumVehicleOptionType.Charge)}

                        {/* <div className='details-item'>
                            <div className='details-subtitle'>Add On</div>
                            {!isNil(interest?.Options)
                                && interest?.Options.map((item: vehicleModel.IVehicleOptions, index: number) => {
                                    return (
                                        <div className='details-desc' key={"addon_" + index}>
                                            <p>{item?.OptionName}</p>
                                            <span>{showPrice(item?.OptionPrice)}</span>
                                        </div>
                                    );
                                })}
                        </div> */}
                    </div>


                    <div className='finance-panel'>
                        <div className='section-title'>
                            FINANCE
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Customer type</div>
                            <div className='details-desc'>
                                <p>{interest?.CustomerType}</p>
                            </div>
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Payment type</div>
                            <div className='details-desc'>
                                <p>{interest?.PaymentOption}</p>
                            </div>
                        </div>
                    </div>


                    <div className='delivery-panel'>
                        <div className='section-title'>
                            {interest?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? "DELIVERY" : "IN-STORE COLLECTION"}
                        </div>


                        <div className='details-item'>
                            <div className='details-subtitle'>Location</div>
                            <div className='details-desc'>
                                <p>{interest?.DeliveryAddress}</p>
                            </div>
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Preferred option</div>
                            <div className='details-desc'>
                                <p>{interest?.DeliveryOption}</p>
                            </div>
                        </div>

                        <div className='details-item'>
                            <div className='details-subtitle'>Estimated date</div>
                            <div className='details-desc'>
                                <p>{interest?.EstDeliveryDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </MainLayoutPage>
    )
}

export default OrderWaitlisted