import React from 'react'

const IsNumberVerification = (evt: any) => {
    
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if ((charCode < 48 || charCode > 57) && charCode != 8) {
        evt.preventDefault();
        return false;
    }
    return true;
}

export default IsNumberVerification