import * as defaultConfigModel from 'model/IDefaultConfig';

export const getInitDefaultConfig = (): defaultConfigModel.IDefaultConfig => {
    let init: defaultConfigModel.IDefaultConfig = {
        Country: null,
        State: null,
        VariantName: null,
        ExteriorName: null,
        InteriorName: null,
        Options: [],
        Loaded: false,
        LinkKey: null,
        ExpiryDateText: null,
    };
    return init;
}