import React, { forwardRef, useState, useImperativeHandle, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import IsNumberVerification from 'components/IsNumberVerification';
import { Button } from 'react-bootstrap';

import * as rdEnum from 'helpers/enum';

import { IAppState } from 'model/IAppState';
import * as saveDialogViewModel from 'model/ISaveDialogViewModel';
import * as interestModel from 'model/IInterest';
import * as interestService from 'services/Interest';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import PrivacyCollectionDialog from './PrivacyCollectionDialog';



const SaveDialog = ({ props }: any) => {
    const [saveDialog, setSaveDialog] = useState(false);

    const initPageState: saveDialogViewModel.SaveDetailsState = {
        firstname: "",
        lastname: "",
        email: "",
        confirmEmail: "",
        mobile: "",
        comments: "",
        checkMarketingComms: false,
        checkPrivacy: false,
        invalidFirstname: false,
        invalidLastname: false,
        invalidEmail: false,
        invalidConfirmEmail: false,
        invalidMobile: false,
        emailMismatch: false,
        invalidCheckMarketingComms: false,
        invalidCheckPrivacy: false,
    }
    const [pageState, setPageState] = useState<saveDialogViewModel.SaveDetailsState>(initPageState);

    const [saveFailedMessage, setSaveFailedMessage] = useState("");
    const [savingStatus, setSavingStatus] = useState(rdEnum.enumProcessingStatus.Init);

    const [isOverflown, setIsOverflown] = useState(false);
    const textAreaRef = useRef<any>(null);

    const checkOverflow = () => {
        const textarea = textAreaRef.current;

        if (textarea) {
            const rowHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 12);

            const maxVisibleHeight = rowHeight * 8;

            if (textarea?.scrollHeight > maxVisibleHeight) {
                setIsOverflown(true);
            } else {
                setIsOverflown(false);
            }
        }
    };

    useEffect(() => {
        checkOverflow(); // Check on component mount
    }, []);

    //store variables
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });
    //const selectVehFromStore = useSelector((appState: IAppState) => {
    //    return appState.selectedVehicle;
    //});
    const selectedPaymentOptions = useSelector((appState: IAppState) => {
        return appState.paymentOptions;
    });
    const customerTypeForStore = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const linkSourceFromStore = useSelector((state: IAppState) => {
        return state?.linkSource;
    });

    useEffect(() => {
        setSavingStatus(rdEnum.enumProcessingStatus.Init);
        return () => {
            setSavingStatus(0);
        };
    }, []);


    const openSaveDialog = () => {
        setSaveDialog(true);
    }

    const onSaveHide = () => {
        setSaveDialog(false);
        setPageState(initPageState);
    }

    const validation = (): boolean => {
        let invalid = false;

        let invalidFirstname = false;
        let invalidLastname = false;
        let invalidEmail = false;
        let invalidConfirmEmail = false;
        let emailMismatch = false;
        let invalidMobile = false;
        let invalidMarketingComms = false;
        let invalidCheckPrivacy = false;

        const emailValidator = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pageState?.email);

        if (isNil(pageState.firstname) || pageState.firstname == "") {
            invalid = true;
            invalidFirstname = true;
        }
        if (isNil(pageState.lastname) || pageState.lastname == "") {
            invalid = true;
            invalidLastname = true;
        }
        if (isNil(pageState.email) || pageState.email == "" || emailValidator) {
            invalid = true;
            invalidEmail = true;
        }

        //if (isNil(pageState.confirmEmail) || pageState.confirmEmail == "" || emailValidator) {
        //    invalid = true;
        //    invalidConfirmEmail = true;
        //}
        //if (pageState.email != pageState.confirmEmail) {
        //    invalid = true;
        //    emailMismatch = true;
        //}
        if (isNil(pageState.mobile) || pageState.mobile == "" || pageState.mobile.length < 9 || pageState.mobile.length > 13) {
            invalid = true;
            invalidMobile = true;
        }
        //if (!pageState.checkMarketingComms) {
        //    invalid = true;
        //    invalidMarketingComms = true;
        //}
        if (!pageState.checkPrivacy) {
            invalid = true;
            invalidCheckPrivacy = true;
        }

        let newPageState = {
            ...pageState,
            invalidFirstname: invalidFirstname,
            invalidLastname: invalidLastname,
            invalidEmail: invalidEmail,
            invalidConfirmEmail: invalidConfirmEmail,
            emailMismatch: emailMismatch,
            invalidMobile: invalidMobile,
            invalidCheckMarketingComms: invalidMarketingComms,
            invalidCheckPrivacy: invalidCheckPrivacy
        };
        setPageState(newPageState);

        return invalid;
    }

    const getDeliveryLocationState = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationState;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryState || "";
        }
    }

    const getDeliveryLocationPostcode = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationPostcode;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryPostcode || "";
        }
    }

    const getDeliveryLocationSuburb = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationSuburb;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliverySuburb || "";
        }
    }

    const getDeliveryLocationFullAddress = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationAddress || "";
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryFullAddress || "";
        }
    }

    const handleSubmit = async () => {

        let invalid = validation();
        if (invalid) return;

        //todo: api and sp need add primary address(RegistrationAddress)
        let interestData: interestModel.IInterest = {
            Country: selectedDeliveryFromStore?.Country?.code || "",
            State: selectedDeliveryFromStore?.State?.code || "",
            VariantName: selectedVehicleFromStore.VariantName || "",
            VariantBasePrice: selectedVehicleFromStore.VariantBasePrice || 0,
            ExteriorName: selectedVehicleFromStore.Exterior || "",
            ExteriorPrice: selectedVehicleFromStore.ExteriorPrice || 0,
            InteriorName: selectedVehicleFromStore.Interior || "",
            InteriorPrice: selectedVehicleFromStore.InteriorPrice || 0,
            PaymentOption: selectedPaymentOptions?.PaymentOption || rdEnum.enumPaymentOptions.Cash,
            Email: pageState.email,
            FirstName: pageState.firstname,
            LastName: pageState.lastname,
            Mobile: pageState.mobile,
            ABN: null,
            BusinessName: null,
            CustomerType: customerTypeForStore || rdEnum.enumClientTypes.Private.Code,
            DeliveryAddress: getDeliveryLocationFullAddress() || "",
            DeliveryLocationState: getDeliveryLocationState() || "",
            DeliveryLocationSuburb: getDeliveryLocationSuburb() || "",
            DeliveryLocationPostcode: getDeliveryLocationPostcode() || "",
            DeliveryOption: selectedDeliveryFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            EstDeliveryDate: vehicleFromStore?.SelectedInterior?.Available || "",
            PrivacyStatement: true,
            Marketing: pageState.checkMarketingComms,
            InterestType: props == 'inventory' ? rdEnum.enumInterestType.WaitingList : rdEnum.enumInterestType.Save,
            Comment: pageState.comments,
            WebUniqueKey: null,
            Options: selectedVehicleFromStore.Options || [],
            VehicleThumbnail: ""
        };

        setSaveFailedMessage("");
        setSavingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await interestService.saveInterest(interestData, true);
        if (response.status) {
            setSavingStatus(rdEnum.enumProcessingStatus.Processed);

            setTimeout(() => {
                onSaveHide();
                setSavingStatus(0)
            }, 5000);
        }
        else {
            setSaveFailedMessage(response.message);
            setSavingStatus(rdEnum.enumProcessingStatus.Init);
        }

    }

    const isNumberKey = (e: any) => {
        IsNumberVerification(e);
    }

    const submitButtonTemplate = () => {
        if (savingStatus == rdEnum.enumProcessingStatus.Processing) {
            return (<Button type="button" className='progress-btn' disabled={true}>
                <ProgressSpinner
                    style={{ width: '16px', height: '16px' }}
                    strokeWidth="5"
                    animationDuration=".8s"
                />
            </Button>);
        }
        else if (savingStatus == rdEnum.enumProcessingStatus.Init) {
            return (
                <Button className="stat-button-link"
                    data-dtm={props == 'inventory' ? "" : "submit"}
                    onClick={() => handleSubmit()} disabled={!pageState.checkPrivacy}
                    style={{ border: 'none', backgroundColor: !pageState.checkPrivacy ? '#CCCCCC' : '#282828' }}
                >
                    <span style={{ color: !pageState.checkPrivacy ? '#707070' : '#fff' }}>SUBMIT</span>
                </Button>
            );
        }
        else if (savingStatus == rdEnum.enumProcessingStatus.Processed) {
            return (
                <div className='section-save-ack'>
                    <img className="image" src={`${getCMSAssets()}/checkout/success-tick.svg`} />
                    {/* <span className="success-message">Your design has been successfully shared to the email address provided</span> */}
                    {/* {
                         props != 'inventory' && selectedVehicleFromStore.HasStock || linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                         ?
                         <span className="success-message">We’ve received your request for support. The Cadillac Sales team will reach out to you shortly.</span>
                         :
                         <span className="success-message">You’ve successfully joined the waitlist. We’ll notify you as soon as it is available to order.</span>
                    } */}

                    {
                        props == 'inventory'
                            ?
                            <span className="success-message">You’ve successfully joined the waitlist. We’ll notify you as soon as it is available to order.</span>
                            :
                            <span className="success-message">We’ve received your request for support. The Cadillac Sales team will reach out to you shortly.</span>
                    }

                </div>
            );
        }
    }

    setTimeout(() => {
        let agreeCheckbox = document.getElementsByName("cb-ack-save");
        for (let i = 0; i < agreeCheckbox.length; i++) {
            agreeCheckbox[i].setAttribute('data-dtm', 'privacy statement');
            agreeCheckbox[i].classList.add('stat-checkbox');
        }

        let marketingCheckbox = document.getElementsByName("cb-ack-save-optional");
        for (let i = 0; i < agreeCheckbox.length; i++) {
            marketingCheckbox[i].setAttribute('data-dtm', 'privacy statement');
            marketingCheckbox[i].classList.add('stat-checkbox');
        }

    }, 200);

    return (
        <div className='save-dialog-btn'>
            {
                props == 'inventory'
                    ?
                    <div className='variant-btn-panel' style={{ borderRadius: '4px' }}>
                        <Button className="variant-btn" style={{ marginTop: '0rem' }}
                            onClick={() => openSaveDialog()}
                        >JOIN OUR WAITLIST HERE</Button>
                    </div>
                    :
                    <div className='save-box stat-text-link'
                        data-dtm="checkout"
                        onClick={() => openSaveDialog()}
                    >
                        <img className="image" src={`${getCMSAssets()}/checkout/chat.svg`} />
                        <span>Get help from sales team</span>
                    </div>
            }



            <Dialog className='save-dialog' visible={saveDialog} position='right'
                onHide={() => onSaveHide()} draggable={false} resizable={true}>
                <div className='save-panel'>
                    {
                        props != 'inventory'
                            // && selectedVehicleFromStore.HasStock || linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                            ?
                            <div className='title-container'>
                                <h2>WE'RE HERE TO HELP</h2>
                                <span>A member of our Cadillac sales team will respond to your enquiry as quickly as possible.</span>
                            </div>
                            :
                            <div className='title-container'>
                                <h2>JOIN OUR WAITLIST!</h2>
                                <span>
                                    {/* Our vehicle is currently out of stock and  you will be the first to know when it's back!
                                    <br /><br />
                                    Sign up for our waitlist to receive exclusive updates and promotional offers.
                                    <br /><br />
                                    Don’t miss out—secure your spot today! */}
                                    Enter your details below and we will notify you when you are able to order your customised Lyriq.  A member of our team will also touch base to offer any support you me need.
                                </span>
                            </div>
                    }
                    {/* <div className='title-container'>
                        <h2>WE'RE HERE TO HELP</h2> */}
                    {/* <span>Cadillac sales team will assist with your saved configuration shortly.</span> */}
                    {/* <span>A member of our Cadillac sales team will respond to your enquiry as quickly as possible.</span> */}
                    {/* {
                            props != 'inventory' && selectedVehicleFromStore.HasStock || linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                            ?
                            <span>A member of our Cadillac sales team will respond to your enquiry as quickly as possible.</span>
                            :
                            <span>This particular LYRIQ configuration is  currently sold out.  Please enter your details below and to be notified when it is available to order.</span>
                        } */}

                    {/* </div> */}
                    <div className='input-container'>
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="firstname" aria-describedby="firstname-help"
                                    className={classNames({ "p-invalid": pageState?.invalidFirstname })}
                                    placeholder=" "
                                    value={pageState.firstname}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            firstname: e.target.value,
                                            invalidFirstname: isNil(e.target.value) || e.target.value == ""
                                        })
                                    }}
                                />
                                <label>First Name *</label>
                            </span>
                        </div>
                        {pageState?.invalidFirstname && (
                            <small id="fristname-help" className="p-error block">Require Valid First Name</small>
                        )}
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="lastname" aria-describedby="lastname-help"
                                    className={classNames({ "p-invalid": pageState?.invalidLastname })}
                                    placeholder=" "
                                    value={pageState.lastname}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            lastname: e.target.value,
                                            invalidLastname: isNil(e.target.value) || e.target.value == ""
                                        })
                                    }}
                                />
                                <label>Last Name *</label>
                            </span>
                        </div>
                        {pageState?.invalidLastname && (
                            <small id="lastname-help" className="p-error block">Require Valid Last Name</small>
                        )}
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="email" aria-describedby="email-help"
                                    className={classNames({ "p-invalid": pageState?.invalidEmail })}
                                    placeholder=" "
                                    value={pageState.email}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            email: e.target.value,
                                            invalidEmail: isNil(e.target.value) || e.target.value == ""
                                        })
                                    }}
                                />
                                <label>Email *</label>
                            </span>
                        </div>
                        {pageState?.invalidEmail && (
                            <small id="email-help" className="p-error block">Require Valid Email</small>
                        )}
                        {/*<div className='input-item'>*/}
                        {/*    <span className="wrapper-box">*/}
                        {/*        <InputText id="email" aria-describedby="email-help"*/}
                        {/*            className={classNames({ "p-invalid": pageState?.invalidConfirmEmail })}*/}
                        {/*            placeholder=" "*/}
                        {/*            value={pageState.confirmEmail}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setPageState({*/}
                        {/*                    ...pageState,*/}
                        {/*                    confirmEmail: e.target.value,*/}
                        {/*                    invalidConfirmEmail: isNil(e.target.value) || e.target.value == ""*/}
                        {/*                })*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        <label>Confirm Email</label>*/}
                        {/*    </span>*/}
                        {/*    {pageState?.emailMismatch && (*/}
                        {/*        <small id="email-help" className="p-error block">The email address and confirm email address fields do not match</small>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="mobile" aria-describedby="mobile-help"
                                    className={classNames({ "p-invalid": pageState?.invalidMobile })}
                                    onKeyPress={(e: any) => isNumberKey(e)}
                                    placeholder=" "
                                    value={pageState.mobile}
                                    onChange={(e) => {
                                        let mobile = e.target.value.slice(0, 11);
                                        setPageState({
                                            ...pageState,
                                            mobile: mobile,
                                            invalidMobile: isNil(mobile) || mobile == "" || mobile.length < 9 || mobile.length > 13
                                        })
                                    }}
                                />
                                <label>Mobile *</label>
                            </span>
                        </div>
                        {pageState?.invalidMobile && (
                            <small id="mobile-help" className="p-error block">Require Valid Mobile</small>
                        )}

                        {/* {
                            props != 'inventory' && selectedVehicleFromStore.HasStock || linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                                ?
                                <div className='input-item textarea'>
                                    <span className="wrapper-box">
                                        <InputTextarea id="comments" aria-describedby="comments-help"
                                            placeholder=" "
                                            rows={8}
                                            value={pageState.comments}
                                            onChange={(e: any) => {
                                                setPageState({
                                                    ...pageState,
                                                    comments: e.target.value,
                                                })
                                            }}
                                        />
                                        <label>Got a Question</label>
                                    </span>
                                </div>
                                :
                                <></>
                        } */}

                        {
                            props != 'inventory'
                                ?
                                <div className={`input-item textarea ${isOverflown ? 'overflown' : ''}`}>
                                    <span className="wrapper-box">
                                        <InputTextarea id="comments" aria-describedby="comments-help"
                                            placeholder=" "
                                            ref={textAreaRef}
                                            rows={8}
                                            value={pageState.comments}
                                            onChange={(e: any) => {
                                                setPageState({
                                                    ...pageState,
                                                    comments: e.target.value,
                                                });
                                                checkOverflow();
                                            }}
                                        />
                                        <label style={{ display: isOverflown ? "none" : '' }}>Got a Question</label>
                                    </span>
                                </div>
                                :
                                <></>
                        }

                    </div>

                    <div className='checkbox-panel'>
                        <div className="section-save-ack">
                            <Checkbox
                                name="cb-ack-save"
                                inputId="cb-ack-save"
                                onChange={(e) =>
                                    setPageState({
                                        ...pageState,
                                        checkPrivacy: e.checked,
                                        invalidCheckPrivacy: !e.checked
                                    })

                                }
                                checked={pageState?.checkPrivacy}
                            ></Checkbox>
                            <label htmlFor="cb-ack-save" className="p-checkbox-label">
                                I agree to Cadillac's  {' '}
                                <PrivacyPolicyLink props='Privacy Policy' />
                                &nbsp;and&nbsp;
                                {/* <a
                                    onClick={()=> window.open("https://www.cadillacanz.com/au-en/privacy", "_blank")}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Privacy Collection Statement.
                                </a> */}
                                <PrivacyCollectionDialog />
                            </label>
                        </div>
                        {/*{pageState?.invalidCheckMarketingComms && (*/}
                        {/*    <small id="mobile-help" className="p-error block">Require the Agreement of Guideline</small>*/}
                        {/*)}*/}
                        <div className="section-save-ack">
                            <Checkbox
                                name="cb-ack-save-optional"
                                inputId="cb-ack-save-optional"
                                onChange={(e) =>
                                    setPageState({
                                        ...pageState,
                                        checkMarketingComms: e.checked,
                                        invalidCheckMarketingComms: !e.checked
                                    })
                                }
                                checked={pageState?.checkMarketingComms}
                            ></Checkbox>
                            <label htmlFor="cb-ack-save-optional" className="p-checkbox-label">
                                I consent to receiving direct marketing materials from Cadillac.
                            </label>
                        </div>
                    </div>

                    <div className='btn-panel'>
                        {submitButtonTemplate()}
                    </div>
                    {!isNil(saveFailedMessage) && saveFailedMessage != "" && (
                        <div className="text-error" dangerouslySetInnerHTML={{ __html: saveFailedMessage }}></div>
                    )}
                </div>
            </Dialog>
        </div>
    )
}

export default SaveDialog