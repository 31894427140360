import React from 'react';
import { Footer } from 'scene/Layout/Footer';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MainLayoutPage } from 'LayoutPages';
import ScrollToTop from 'components/ScrollToTop';
import {
    getFrontendUrl,
} from 'helpers/Uri';
import { Button } from 'react-bootstrap';

export const ComingSoon = () => {

    const navigate = useNavigate();

    const handleBtnClick = () => {
        navigate(`${getFrontendUrl()}`)
    }

    return (
        <MainLayoutPage sceneId="container-has-header">
            {/* <div className=""> */}
                <div className='coming-soon-container'>
                    <div className='coming-soon-panel page-container-content page-actural-content'>
                        <div className='coming-soon-box '>
                            <div className="coming-soon-top ">COMING SOON</div>
                            <div className="coming-soon-title ">UNDER CONSTRUCTION</div>
                            <div className="coming-soon-content">
                                Our website is under construction, but we are ready to go! We are preparing somthing amazing and exciting for you.
                            </div>
                            <Button className="coming-soon-btn" onClick={() => handleBtnClick()}>Back to Home Page</Button>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            <Footer />
        </MainLayoutPage>
    );
};
