///Temporary save customer details  to store
///Show data after login redirection
import * as checkoutViewModel from 'model/ICheckoutWaitlistViewModel';


export const SET_CHECKOUTCUSTOMERDETAILS = 'setCheckoutCustomerDetails';
export const setCheckoutCustomerDetailsAction = (customerDetails: checkoutViewModel.ICheckoutWaitlistProps | null) =>
({
    type: SET_CHECKOUTCUSTOMERDETAILS,
    customerDetails,
} as const);

export const CLEAR_CHECKOUTCUSTOMERDETAILS = 'clearCheckoutCustomerDetails';
export const clearCheckoutCustomerDetailsAction = () =>
({
    type: CLEAR_CHECKOUTCUSTOMERDETAILS
} as const);

export type CheckoutCustomerDetailsActions =
    ReturnType<typeof setCheckoutCustomerDetailsAction>
    | ReturnType<typeof clearCheckoutCustomerDetailsAction>;

export const checkoutCustomerReducer = (
    state: checkoutViewModel.ICheckoutWaitlistProps | null = null,
    action: CheckoutCustomerDetailsActions
): checkoutViewModel.ICheckoutWaitlistProps | null => {
    switch (action.type) {
        case CLEAR_CHECKOUTCUSTOMERDETAILS: {
            return null;
        }   
        case SET_CHECKOUTCUSTOMERDETAILS: {
            return action.customerDetails;
        }
    }
    return state;
};