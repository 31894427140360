import * as selectedConfigModel from 'model/IUserSelectedConfig';

export const getInitSelectedDelivery = (): selectedConfigModel.ISelectedDelivery => {
    let initSelectedDelivery: selectedConfigModel.ISelectedDelivery = {
        Country: null,
        State: null,
        SelectedPickupLocation: null,
        SelectedDeliveryOption: null,
        SelectedDeliveryFullAddress: null,
        SelectedDeliveryPostcode: null,
        SelectedDeliveryState: null,
        SelectedDeliverySuburb: null,
        PrimaryAddress: null,
        PrimaryPostcode: null,
        PrimarySuburb: null,
        PrimaryState: null,
        InvalidPrimaryAddress: null,
        DeliveryAvailable: null,
        DeliveryPrice: null,
        DeliveryPriceErrorCode: null,
        DeliveryDistance: null,
        PickupLocationName: null,
    };

    return initSelectedDelivery;
}

