import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getGoogleMapAPIKey } from 'helpers/Uri';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { IAppState } from 'model/IAppState';
import moment from 'moment';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as googleApiService from 'services/GoogleApi';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';


interface googleMapState {
    googleMapAPIKey: string,
    zoom: number,
    containerStyle: any,
    center: any,
    activeMarker: any | null,
}


const PickupLocation = () => {

    const [deliveryLocation, setDeliveryLocation] = useState<any>(null);

    const isMobile = useCheckMobileScreen();

    const orderScheduleDeliveryFromStore: scheduleDeliveryModel.IOrderScheduleDelivery = useSelector((state: IAppState) => {
        return state?.orderScheduleDelivery;
    });

    const initGoogleMapState: googleMapState = {
        googleMapAPIKey: getGoogleMapAPIKey(),
        zoom: 12,
        containerStyle: {
            width: '100%',
            height: '150px'
        },
        center: {
            lat: 13.819960794599261,
            lng: 100.56316492912957,
        },
        activeMarker: null
    };
    const [googleMapState, setGoogleMapState] = useState<googleMapState>(initGoogleMapState);

    const { isLoaded } = useJsApiLoader({
        //id: 'google-map-script',
        googleMapsApiKey: googleMapState.googleMapAPIKey,
        region: "AU",
        language: "en-AU"
    });


    useEffect(() => {

        if (!isNil(orderScheduleDeliveryFromStore)) {
            doGetGeometryLocation();
        }

    }, [orderScheduleDeliveryFromStore]);

    const doGetGeometryLocation = async () => {
        let response = await googleApiService.getGeometryLocation(orderScheduleDeliveryFromStore?.Country, orderScheduleDeliveryFromStore.DeliveryLocationAddress);
        if (response.status) {
            setDeliveryLocation(response.data);
            setGoogleMapState({
                ...googleMapState,
                center: {
                    lat: response.data.lat,
                    lng: response.data.lng,
                }
            })
        }
    }


   
    return (
        <Row className='pickup-location-container'>
            <Col lg={8} className='pickup-location-panel'>
                <div className='pickup-location-map'>
                    {
                        !isMobile
                        ?
                        <span>{orderScheduleDeliveryFromStore?.DeliveryOption} Location</span>
                        :
                        <></>
                    }
                    
                    <div className='pickup-location-map-box'>
                        <div className='address-panel'>
                            {
                                isMobile
                                ?
                                <p>{orderScheduleDeliveryFromStore?.DeliveryOption} Location</p>
                                :
                                <></>
                            }
                            <span>{orderScheduleDeliveryFromStore?.DeliveryLocationAddress}</span>

                            {
                                isMobile
                                ?
                                <>
                                    <p>Date and Time</p>
                                    <div className='date-and-time-box'>
                                        <div className='date-and-time'>
                                            <span>{moment(orderScheduleDeliveryFromStore?.DeliveryDate).format("dddd, DD MMMM YYYY")}</span>
                                            <span>{orderScheduleDeliveryFromStore?.DeliveryTime}</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }
                           
                        </div>
                        <div className='map-panel'>
                            { isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={googleMapState.containerStyle}
                                    center={googleMapState.center}
                                    zoom={googleMapState.zoom}
                                >
                                    {!isNil(orderScheduleDeliveryFromStore?.DeliveryLocationAddress) && !isNil(deliveryLocation) && (

                                        <React.Fragment>
                                            <Marker position={deliveryLocation}
                                                onClick={() => {
                                                    setGoogleMapState({
                                                        ...googleMapState,
                                                        activeMarker: orderScheduleDeliveryFromStore?.DeliveryLocationAddress
                                                    });
                                                }}
                                            >
                                                {/*{googleMapState.activeMarker == item.dealerCode && (*/}
                                                {/*    <InfoWindow position={item.location}*/}
                                                {/*        onCloseClick={() => {*/}
                                                {/*            setGoogleMapState({ ...googleMapState, activeMarker: null});*/}
                                                {/*        }}>*/}
                                                {/*        <div className="search-dealer-result-for-map">*/}
                                                {/*            {dealerDetailTemplate(item)}*/}
                                                {/*        </div>*/}
                                                {/*    </InfoWindow>*/}
                                                {/*)}*/}
                                            </Marker>
                                        </React.Fragment>
                                    )}
                                   
                                </GoogleMap>
                            )
                        }
                        </div>
                    </div>
                </div>
                {
                    !isMobile
                    ?
                    <div className='pickup-location-schedule'>
                        <span>Date and Time</span>
                        <div className='date-and-time-box'>
                            <div className='date-and-time'>
                                <p>{moment(orderScheduleDeliveryFromStore?.DeliveryDate).format("dddd, DD MMMM YYYY")}</p>
                                <p>{orderScheduleDeliveryFromStore?.DeliveryTime}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }
                
            </Col>
            <Col lg={4} className='pickup-need-help-panel'>
                <div className='need-support-container'>
                    <h6>Need support?</h6>
                    <p>If you wish to change your delivery option</p>
                    <span>Please call 1800 CADILLAC (223455) for assistant.</span>
                </div>
            </Col>
        </Row>
    )
}

export default PickupLocation