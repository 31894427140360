import * as vehicleModel from 'model/IVehicle';
import * as vehicleHelper from 'businessHelpers/VehicleHelper';

export const UPDATE_VARIANT = 'UpdateVariant';
export const updateVariantAction = (variantList: vehicleModel.IVariant[],
    selectedVariant: vehicleModel.IVariant | null) =>
({
    type: UPDATE_VARIANT,
    variantList,
    selectedVariant
} as const);

export const UPDATE_SELECTEDVARIANT = 'UpdateSelectedVariant';
export const updateSelectedVariantAction = (selectedVariant: vehicleModel.IVariant | null) =>
({
    type: UPDATE_SELECTEDVARIANT,
    selectedVariant
} as const);


export const UPDATE_EXTERIOR = 'UpdateExterior';
export const updateExteriorAction = (exteriorList: vehicleModel.IExterior[],
    selectedExterior: vehicleModel.IExterior | null) =>
({
    type: UPDATE_EXTERIOR,
    exteriorList,
    selectedExterior
} as const);


export const UPDATE_SELECTEDEXTERIOR = 'UpdateSelectedExterior';
export const updateSelectedExteriorAction = (selectedExterior: vehicleModel.IExterior | null) =>
({
    type: UPDATE_SELECTEDEXTERIOR,
    selectedExterior
} as const);


export const UPDATE_INTERIOR = 'UpdateInterior';
export const updateInteriorAction = (interiorList: vehicleModel.IInterior[],
    selectedInterior: vehicleModel.IInterior | null) =>
({
    type: UPDATE_INTERIOR,
    interiorList,
    selectedInterior
} as const);
export const UPDATE_SELECTEDINTERIOR = 'UpdateSelectedInterior';
export const updateSelectedInteriorAction = (selectedInterior: vehicleModel.IInterior | null) =>
({
    type: UPDATE_SELECTEDINTERIOR,
    selectedInterior
} as const);


export const UPDATE_OPTIONS = 'UpdateOptions';
export const updateOptionsAction = (
    accessories: vehicleModel.IVehicleOptions[],
    chargingList: vehicleModel.IVehicleOptions[],
) =>
({
    type: UPDATE_OPTIONS,
    accessories,
    chargingList
} as const);

//export const SET_LOADING = 'Set_Loading';
//export const setLoadingAction = (loading: boolean) =>
//({
//    type: SET_LOADING,
//    loading
//} as const);

export const CLEAR_VEHICLE = 'Clear_Vehicle';
export const clearVehicleAction = () =>
({
    type: CLEAR_VEHICLE,
} as const);

export type VehicleActions = ReturnType<typeof updateVariantAction>
    | ReturnType<typeof updateSelectedVariantAction>
    | ReturnType<typeof updateExteriorAction>
    | ReturnType<typeof updateSelectedExteriorAction>
    | ReturnType<typeof updateInteriorAction>
    | ReturnType<typeof updateSelectedInteriorAction>
    | ReturnType<typeof updateOptionsAction>
    | ReturnType<typeof clearVehicleAction>;

export const vehicleReducer = (
    state = vehicleHelper.getInitVehicleStore(),
    action: VehicleActions,
) => {
    switch (action.type) {
        case UPDATE_VARIANT: {
            return {
                ...state,
                VariantList: action.variantList,
                SelectedVariant: action.selectedVariant,
                //Loading: true,
            }
        }
        case UPDATE_SELECTEDVARIANT: {
            return {
                ...state,
                SelectedVariant: action.selectedVariant,
            }
        }

        case UPDATE_EXTERIOR: {
            return {
                ...state,
                ExteriorList: action.exteriorList,
                SelectedExterior: action.selectedExterior,
            }
        }
        case UPDATE_SELECTEDEXTERIOR: {
            return {
                ...state,
                SelectedExterior: action.selectedExterior,
            }
        }

        case UPDATE_INTERIOR: {
            return {
                ...state,
                InteriorList: action.interiorList,
                SelectedInterior: action.selectedInterior,
            }
        }
        case UPDATE_SELECTEDINTERIOR: {
            return {
                ...state,
                SelectedInterior: action.selectedInterior,
            }
        }

        case UPDATE_OPTIONS: {
            return {
                ...state,
                Accessories: action.accessories,
                Charging: action.chargingList,
                //Loading: false,
            }
        }

        //case SET_LOADING: {
        //    return {
        //        ...state,
        //        Loading: action.loading,
        //    }
        //}

        case CLEAR_VEHICLE: {
            return vehicleHelper.getInitVehicleStore();
        }

        default:
            return state;
    }
};