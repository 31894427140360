import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import "../App.scss"
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { IAppState } from 'model/IAppState';

export type CarouselDialogRef = {
    handleOpen: (pageNumber: number) => void;
};

interface CarouselDialogProps {
    vehicle: {
        variantAWD: string,
        exteriorColor: string,
        interiorColor: string
    }
    slideNumber: number;
}

const CarCarouselDialog = forwardRef<CarouselDialogRef, CarouselDialogProps>((props , ref) => {
    const isMobile = useCheckMobileScreen();
    const {vehicle, slideNumber} = props;

    const [dialogVisible, setDialogVisible] = useState(false);
    const [carouselPage, setCarouselPage] = useState(1);

    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const getVariantImages = (index: number) => {
            return vehicleFromStore?.SelectedVariant?.VariantPicture != null && vehicleFromStore?.SelectedVariant?.VariantPicture.length > index
                ? vehicleFromStore?.SelectedVariant?.VariantPicture[index] : "";
    }
    const getExteriorImages = (index: number) => {
        return vehicleFromStore?.SelectedExterior?.ExteriorPicture != null && vehicleFromStore?.SelectedExterior?.ExteriorPicture.length > index
            ? vehicleFromStore?.SelectedExterior?.ExteriorPicture[index] : "";
    }

    const getInteriorImages = (index: number) => {
        //if (isMobile) {
        //    return vehicleFromStore?.SelectedInterior?.InteriorPictureMobile != null && vehicleFromStore?.SelectedInterior?.InteriorPictureMobile.length > index
        //        ? vehicleFromStore?.SelectedInterior?.InteriorPictureMobile[index] : "";
        //}
        //else {
            return vehicleFromStore?.SelectedInterior?.InteriorPicture != null && vehicleFromStore?.SelectedInterior?.InteriorPicture.length > index
                ? vehicleFromStore?.SelectedInterior?.InteriorPicture[index] : "";
        //}
    }

    useImperativeHandle(ref, () => ({
        handleOpen: (pageNumber: number) => {
            setDialogVisible(true);
            setCarouselPage(pageNumber);
            document.body.style.overflowY = 'hidden';
            document.body.style.paddingRight = '15px';
        },
    }));

    const handleClose = () => {
        setDialogVisible(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }

    const handleSelect = (selectedIndex: any, e: any) => {
        setCarouselPage(selectedIndex);
    };

    return (

        <Dialog className='carousel-dialog' visible={dialogVisible} position='bottom' draggable={false} resizable={true} onHide={() => handleClose()}>
            <Carousel
                defaultActiveIndex={slideNumber}
                activeIndex={carouselPage}
                onSelect={handleSelect}
                prevIcon = { 
                    carouselPage < 4
                    ?
                    <img alt="" src={`${getCMSAssets()}/main/Prev.svg`} />
                    :
                    <img alt="" src={`${getCMSAssets()}/main/Prev-white.svg`} />
                }
                nextIcon = {
                    carouselPage < 4
                    ?
                        <img alt="" src={`${getCMSAssets()}/main/Next.svg`} />
                    :
                        <img alt="" src={`${getCMSAssets()}/main/Next-white.svg`} />
                }
            >
                <Carousel.Item>
                    <img className="image" src={getExteriorImages(0)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getExteriorImages(1)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getExteriorImages(2)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getExteriorImages(3)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getInteriorImages(0)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getInteriorImages(1)} />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="image" src={getInteriorImages(2)} />
                </Carousel.Item>
            </Carousel>
            {
                carouselPage != 4 && carouselPage != 5 && carouselPage != 6
                ?
                <img alt="" className='close-icon' onClick={() => handleClose()} src={`${getCMSAssets()}/main/cross.svg`} />
                :
                <img alt="" className='close-icon' onClick={() => handleClose()} src={`${getCMSAssets()}/main/cross-white.svg`} />
            }
        </Dialog>

    )
})

export default CarCarouselDialog