import React, {
    createRef,
    useRef,
    useLayoutEffect,
    useState,
    useContext,
    createContext,
    useEffect
} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { MainLayoutPage } from 'LayoutPages';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { TabView, TabPanel } from 'primereact/tabview';

import {
    IPageLanguageState,
    getCMSAssets,
    getFrontendUrl,
} from 'helpers/Uri';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { MainMenu } from 'scene/Layout/MainMenu';

import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { Footer } from 'scene/Layout/Footer';



import { validateResetPasswordToken, updatePassword } from "services/User";
import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';

interface pageState {
    languageState: IPageLanguageState,
}

interface SignInState {
    isSent: boolean | null,
    password: string,
    confirmPassword: string,
    invalidPassword: boolean | null,
    invalidConfirmPassword: boolean | null,
    passwordNoMatched: boolean | null,
}

interface PasswordRulesProps {
    number: string,
    upperLowerCase: string,
    characters: string,
}

export const ResetPasswordPage = () => {
    const gaEventTracker = useAnalyticsEventTracker('Reset Password');
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { token } = useParams();
    const [invalidToken, setInvalidToken] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");

    const [pageProcess, setPageProcess] = useState(0);

    const [secondsRemaining, setSecondsRemaining] = useState(3);

    /*page state*/
    const initPageState: SignInState = {
        isSent: false,
        password: "",
        confirmPassword: "",
        invalidPassword: false,
        invalidConfirmPassword: false,
        passwordNoMatched: false,
    }
    const [pageState, setPageState] = useState<SignInState>(initPageState);

    const initPasswordState: PasswordRulesProps = {
        number: 'default',
        upperLowerCase: 'default',
        characters: 'default',
    }
    const [passwordRules, setPasswordRules] = useState<PasswordRulesProps>(initPasswordState);

    const isMobile = useCheckMobileScreen();

    //Scroll to top instantly without any animation
    if (isMobile) {
    //     window.scrollTo({top: 0, behavior: 'instant'} as any);
    // } else {
        document.querySelector(".container-has-header")?.scrollTo({ top: 0, behavior: 'instant' } as any);
    }

    useEffect(() => {

        const doValidateToken = async () => {
            let request = await validateResetPasswordToken(token);

            if (!request.status) {
                setInvalidToken(true);
                setErrorMessage(request.message);
            }
            else {
                setEmail(request.data);
            }
        }
        if (!isNil(token) && token != "") {
            doValidateToken();
        }

    }, [token]);

    useEffect(() => {
        // Check if pageProcess is 1
        if (pageProcess === 1) {

            const intervalId = setInterval(() => {
                setSecondsRemaining((prevSeconds) => prevSeconds - 1);
              }, 1000);

            // Set a timeout for 3 seconds
            const timeoutId = setTimeout(() => {
                // Redirect to the sign-in page
                clearInterval(intervalId);
                navigate('/cadillac/sign-in')
            }, 3000);
    
            // Clean up the timeout on component unmount
            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        }
    }, [pageProcess]);

    const [passwordVisible, setPasswordVisible] = useState({
        isShowPassword: false,
        isShowResetPassword: false,
    })

    const handleGoBack = () => {
        navigate(`${getFrontendUrl()}/sign-in`);
    }

    const handleShowPassword = () => {
        setPasswordVisible({
            ...passwordVisible,
            isShowPassword: !passwordVisible.isShowPassword,
        })
    }

    const handleShowResetPassword = () => {
        setPasswordVisible({
            ...passwordVisible,
            isShowResetPassword: !passwordVisible.isShowResetPassword,
        })
    }

    const passwordCheck = (passwordEnter: any) => {
        let password = passwordEnter.trim();
        if (isNil(password) 
            || password == "" 
            // Minimum of 1 number
            || !/\d/.test(password) 
            // Minimum of 1 uppercase letter and one lowercase letter
            || !/[A-Z]/.test(password)
            || !/[a-z]/.test(password)
            // Minimum of 8 characters
            || password.length < 8
        ) {
            return true
        } else {
            return false
        }
    }

    const handlePasswordInsert = (e: any) => { 
        setPageState({
            ...pageState,
            password: e.target.value,
            invalidPassword: isNil(e.target.value) || e.target.value == "",
            passwordNoMatched: false,
        })
        if (e.target.value.length == 0) {
            setPasswordRules({
                ...passwordRules,
                number: "default",
                upperLowerCase: "default",
                characters: "default"
            })
            return
        } 

        // Check for at least one digit
        if (/\d/.test(e.target.value)) {
            passwordRules.number = "success";
        } else {
            passwordRules.number = "invalid";
        }

        // Check for at least one uppercase and one lowercase letter
        if (/[A-Z]/.test(e.target.value) && /[a-z]/.test(e.target.value)) {
            passwordRules.upperLowerCase = "success";
        } else {
            passwordRules.upperLowerCase = "invalid";
        }

        // Check for at least 8 characters
        if (e.target.value.length >= 8) {
            passwordRules.characters = "success";
        } else {
            passwordRules.characters = "invalid";
        }

        return passwordRules;
    }

    const handleUpdate = async () => {
        let invalidNewPass = false;
        let invalidConfirmPass = false;
        let passwordNoMatched = false;

        if (passwordCheck(pageState.password)) {
            invalidNewPass = true;
        }
        if (passwordCheck(pageState.confirmPassword)) {
            invalidConfirmPass = true;
        }

        if (pageState.password != "" 
            // && pageState.password != pageState.confirmPassword
        ) {
            passwordNoMatched = true;
        }
        // console.log(passwordNoMatched);
        let invalid = invalidNewPass 
        // || invalidConfirmPass || passwordNoMatched;
        if (invalid) {
            setPageState({
                ...pageState,
                invalidPassword: invalidNewPass,
                invalidConfirmPassword: invalidConfirmPass,
                passwordNoMatched: passwordNoMatched,
            })
        }
        else {
            setPageProcess(1);
        //     let request = await updatePassword(email, pageState.tempPassword, pageState.password);
        //     if (request.status) {
        //         setPageState({
        //             ...pageState,
        //             isSent: true,
        //         });
        //     }
        //     else {
        //         setErrorMessage(request.message);
        //     }
        }

    }

    return (
        <>
                <div className="page-container-fullsize password-issue-container" >
                    <div className='user-left-panel'>
                    <img alt="" className='image' src={`${getCMSAssets()}/users/sign-in-up.png`} />
                    </div>
                    {
                        pageProcess == 0
                        ?
                        <div className='user-right-panel' >
                            <div className='right-inner-container'>
                                <div className='logo-title-container'>
                                    <img alt="" className='logo-image' onClick={() => handleGoBack()} src={`${getCMSAssets()}/users/Cadillac-Logo.svg`} />
                                    <h5 className='sign-in-title'>Already have an account? <a className='sign-in-link' onClick={() => handleGoBack()}>Sign in</a></h5>
                                </div>

                                <div className='handle-password-container'>
                                    <div className='handle-password-title new-password'>
                                        PLEASE CREATE A NEW PASSWORD
                                    </div>
                                    
                                    {
                                        invalidToken
                                            ?
                                            <>
                                                <div className='error-message'>Token is not correct.</div>
                                            </>
                                            :
                                            pageState?.isSent == false
                                                ?
                                                <>
                                                    <div className='input-item'>
                                                        <span className="p-input-icon-right wrapper-box">
                                                            <InputText id="password" aria-describedby="password-help"
                                                                className={classNames({ "p-invalid": pageState?.invalidPassword })}
                                                                placeholder=" "
                                                                type={passwordVisible.isShowPassword ? 'text' : 'password' }
                                                                value={pageState.password}
                                                                onChange={(e) => handlePasswordInsert(e)}
                                                                autoComplete="off"
                                                            />
                                                            <label>New Password</label>
                                                            <i className="password-visible-control" onClick={() => handleShowPassword()}>{passwordVisible.isShowPassword ? 'Hide' : 'Show'}</i>
                                                        </span>
                                                        {pageState?.invalidPassword && (
                                                            <small id="password-help" className="p-error block">Invalid Password</small>
                                                        )}
                                                    </div>

                                                    {/* <div className='input-item'>
                                                        <span className="p-input-icon-right wrapper-box">
                                                            <InputText id="password" aria-describedby="confirm-password-help"
                                                                className={classNames({ "p-invalid": pageState?.invalidConfirmPassword })}
                                                                placeholder=" "
                                                                type={passwordVisible.isShowResetPassword ? 'text' : 'password'}
                                                                value={pageState.confirmPassword}
                                                                onChange={(e) => {
                                                                    setPageState({
                                                                        ...pageState,
                                                                        confirmPassword: e.target.value,
                                                                        invalidConfirmPassword: isNil(e.target.value) || e.target.value == "",
                                                                        passwordNoMatched: false,
                                                                    })
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <label>Confirm Password</label>
                                                            <i className="password-visible-control" onClick={() => handleShowResetPassword()}>{passwordVisible.isShowResetPassword ? 'Hide' : 'Show'}</i>
                                                        </span>
                                                        {pageState?.invalidConfirmPassword && (
                                                            <small id="confirm-password-help" className="p-error block">Invalid Confirm Password</small>
                                                        )}
                                                        {pageState?.passwordNoMatched && (
                                                            <small id="password-unmatch-help" className="p-error block">{t(`${languageState.LanguageScreenName}:password_not_match`)}</small>
                                                        )}
                                                    </div> */}
                                                    {/* {!isNil(errorMessage) && errorMessage != "" && (
                                                        <div className='error-message'>{t(`${languageState.LanguageScreenName}:${errorMessage}`)}</div>
                                                    )} */}
                                                    <div className='insert-rules'>
                                                        <div className='insert-item'>
                                                            {
                                                                passwordRules.number == 'default'
                                                                ?
                                                                    <img alt="" className='logo-image' src={`${getCMSAssets()}/users/circle.png`} />
                                                                :
                                                                passwordRules.number == 'invalid'
                                                                ?
                                                                        <img alt="" className='logo-image' src={`${getCMSAssets()}/users/failed-cross.png`} />
                                                                :
                                                                passwordRules.number == 'success'
                                                                ?
                                                                            <img alt="" className='logo-image' src={`${getCMSAssets()}/users/success-tick.png`} />
                                                                :
                                                                <></>
                                                            }
                                                            <span>Minimum of 1 number</span>
                                                        </div>
                                                        <div className='insert-item'>
                                                            {
                                                                passwordRules.upperLowerCase == 'default'
                                                                ?
                                                                    <img alt="" className='logo-image' src={`${getCMSAssets()}/users/circle.png`} />
                                                                :
                                                                passwordRules.upperLowerCase == 'invalid'
                                                                ?
                                                                        <img alt="" className='logo-image' src={`${getCMSAssets()}/users/failed-cross.png`} />
                                                                :
                                                                passwordRules.upperLowerCase == 'success'
                                                                ?
                                                                            <img alt="" className='logo-image' src={`${getCMSAssets()}/users/success-tick.png`} />
                                                                :
                                                                <></>
                                                            }
                                                            <span>Minimum of 1 uppercase letter and a lower case letter</span>
                                                        </div>
                                                        <div className='insert-item'>
                                                            {
                                                                passwordRules.characters == 'default'
                                                                ?
                                                                    <img alt="" className='logo-image' src={`${getCMSAssets()}/users/circle.png`} />
                                                                :
                                                                passwordRules.characters == 'invalid'
                                                                ?
                                                                        <img alt="" className='logo-image' src={`${getCMSAssets()}/users/failed-cross.png`} />
                                                                :
                                                                passwordRules.characters == 'success'
                                                                ?
                                                                            <img alt="" className='logo-image' src={`${getCMSAssets()}/users/success-tick.png`} />
                                                                :
                                                                <></>
                                                            }
                                                            <span>Minimum of 8 characters</span>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <h1 className='reset_title'></h1>
                                                    <p className='back-to-signin' onClick={handleGoBack}>
                                                    </p>
                                                </>
                                    }
                                </div>

                                <div className='handle-password-btn-panel reset'>
                                    <Button onClick={handleUpdate}><span>RESET</span></Button>
                                </div>
                            </div>
                        </div>
                        :
                        pageProcess == 1
                        ?
                        <div className='user-right-panel'>
                             <div className='right-inner-container'>
                                <div className='logo-title-container'>
                                        <img alt="" className='logo-image' onClick={() => handleGoBack()} src={`${getCMSAssets()}/users/Cadillac-Logo.svg`} />
                                    <h5 className='sign-in-title'>Already have an account? <a className='sign-in-link' onClick={() => handleGoBack()}>Sign in</a></h5>
                                </div>

                                <div className='handle-password-container'>
                                    <div className='handle-password-title new-password'>
                                        PASSWORD RESET COMPLETE
                                    </div>
                                    <div className='handle-password-desc'>
                                        Your password has been reset, please login with you new password.
                                    </div>
                                </div>

                                <div className='handle-password-btn-panel reset-complete'>
                                    <Button onClick={handleGoBack}><span>SIGN IN</span></Button>
                                </div>

                                <div className='redirect-panel'>
                                    your page will redirect to Signin page within {secondsRemaining}s
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    } 
                </div>
            
        </>
    );
};


