import { getCMSAssets } from 'helpers/Uri';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { isNil } from 'lodash';
import SelectLocation from './SelectLocation';
import SelectDateTime from './SelectDateTime';
import PickupLocation from './PickupLocation';
import { classNames } from 'primereact/utils';

import { IAppState } from 'model/IAppState';
import * as orderService from 'services/Order';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { TabPanel, TabView } from 'primereact/tabview';

interface IIsRescheduleProps {
    isReschedule: boolean,
    buttonText: string | null,
}

const ScheduleDelivery = (props: IIsRescheduleProps) => {

    const orderScheduleDeliveryFromStore: scheduleDeliveryModel.IOrderScheduleDelivery = useSelector((state: IAppState) => {
        return state?.orderScheduleDelivery;
    });

    const [dialogVisible, setDialogVisible] = useState(false);

    const [scheduleStep, setScheduleStep] = useState(0);

    const { isReschedule, buttonText } = props;

    const isMobile = useCheckMobileScreen();

    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }

    const toNextStep = () => {
        if (scheduleStep != 2) {
            setScheduleStep(scheduleStep + 1);
        }
    }

    const handleConfirm = async () => {
        //save data to api
        let response = await orderService.saveScheduleAvailabeTime(orderScheduleDeliveryFromStore);
        if (response.status) {
            setDialogVisible(false);
        }

    }

    const toLocationStep = () => {
        setScheduleStep(0);
    }

    const toDateTimeStep = () => {
        if (scheduleStep >= 1) {
            setScheduleStep(1);
        }
    }

    const ifDisableButton = (): boolean => {
        if (scheduleStep == 1) {
            if (orderScheduleDeliveryFromStore.DeliveryDate == undefined
                || isNil(orderScheduleDeliveryFromStore.DeliveryTime) || orderScheduleDeliveryFromStore.DeliveryTime == "") {
                return true;
            }
        }
        return false;
    }

    const handleTabClick = (index: any) => {
        if (
            (index === 1 && scheduleStep === 0) || // Prevent clicking on "Date and time" if scheduleStep is 0
            (index === 2 && scheduleStep !== 2)    // Prevent clicking on "Summary" if scheduleStep is not 2
        ) {
            return; // Do not change the tab if it's disabled
        }
        setScheduleStep(index); // Change the active tab
    };

    return (
        <>
            <div className='variant-btn-panel'>
                <Button className='variant-btn'
                    onClick={() => onShowDialog()}
                >
                    <h6>{buttonText}</h6>
                    {/*{*/}
                    {/*    isReschedule*/}
                    {/*        ?*/}
                    {/*        <h6>RESCHEDULE</h6>*/}
                    {/*        :*/}
                    {/*        <h6>SCHEDULE DELIVERY</h6>*/}
                    {/*}*/}
                </Button>
            </div>

            <Dialog header={scheduleStep == 0 ? "SCHEDULE DELIVERY" : scheduleStep == 1 ? 'SELECT A DATE AND TIME' : 'CONFIRM YOUR DELIVERY'}
                className='schedule-steps-dialog'
                visible={dialogVisible} position='bottom'
                style={{ maxWidth: '1100px' }} draggable={false}
                resizable={true}
                onHide={() => onHideDialog()}
            >
                <div className='schedule-steps-dialog-container'>
                    {
                        !isMobile
                        ?
                        <div className='schedule-steps-container'>
                            <div className='steps-panel'>
                                <div className='step-item' onClick={() => toLocationStep()}>
                                    {
                                        scheduleStep != 0
                                            ?
                                            <img alt="" src={`${getCMSAssets()}/users/tick-circle.svg`} />
                                            :
                                            <div className='current-step-circle-panel'>
                                                <div className='inner-circle'></div>
                                            </div>
                                    }
                                    <span>Location</span>
                                </div>
                                <div className='step-line-panel'>
                                    <div className='step-line'></div>
                                </div>
                                <div className='step-item' onClick={() => toDateTimeStep()}>
                                    {
                                        scheduleStep == 2
                                            ?
                                            <img alt="" src={`${getCMSAssets()}/users/tick-circle.svg`} />
                                            :
                                            scheduleStep == 1
                                                ?
                                                <div className='current-step-circle-panel'>
                                                    <div className='inner-circle'></div>
                                                </div>
                                                :
                                                <div className='next-step-circle-panel'></div>
                                    }
                                    <span>Date and time</span>
                                </div>
                                <div className='step-line-panel'>
                                    <div className='step-line'></div>
                                </div>
                                <div className='step-item'>
                                    {
        
                                        scheduleStep == 2
                                            ?
                                            <div className='current-step-circle-panel'>
                                                <div className='inner-circle'></div>
                                            </div>
                                            :
                                            scheduleStep != 2
                                                ?
                                                <div className='next-step-circle-panel'></div>
                                                :
                                                <></>
                                    }
                                    <span>Summary</span>
                                </div>
                            </div>

                            <div className='pipeline-btn-panel' style={{ width: "200px" }}>
                                <Button className='pipeline-btn'
                                    disabled={ifDisableButton()}
                                    style={{border: 'none', backgroundColor: ifDisableButton() ? '#CCCCCC' : '#282828'}}
                                    onClick={() =>
                                        scheduleStep != 2 ? toNextStep() : handleConfirm()}>
                                    {
                                        scheduleStep != 2
                                            ?
                                            <span style={{color: ifDisableButton() ? '#707070' : '#fff'}}>NEXT</span>
                                            :
                                            <span style={{color: ifDisableButton() ? '#707070' : '#fff'}}>CONFIRM</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        :
                        <div className="schedule-steps-container">
                            <TabView activeIndex={scheduleStep} onTabChange={(e) => setScheduleStep(e.index)}>
                                <TabPanel header="Location">
                                   
                                </TabPanel>
                                <TabPanel header="Date and time" disabled={ scheduleStep == 0 ? true : false}>
                                    
                                </TabPanel>
                                <TabPanel header="Summary" disabled={ scheduleStep == 2 ? false : true}>
                                    
                                </TabPanel>
                            </TabView>

                            
                        </div>
                    }

                    {
                        scheduleStep == 0
                            ?
                            <SelectLocation />
                            :
                            scheduleStep == 1
                                ?
                                <SelectDateTime />
                                :
                                scheduleStep == 2
                                    ?
                                    <PickupLocation />
                                    :
                                    <></>
                    }

                    {
                        isMobile
                        ?
                        <div className='pipeline-btn-panel' style={{ width: "200px" }}>
                            <Button className='pipeline-btn'
                                disabled={ifDisableButton()}
                                style={{border: 'none', backgroundColor: ifDisableButton() ? '#CCCCCC' : '#282828'}}
                                onClick={() =>
                                    scheduleStep != 2 ? toNextStep() : handleConfirm()}>
                                {
                                    scheduleStep != 2
                                        ?
                                        <span style={{color: ifDisableButton() ? '#707070' : '#fff'}}>NEXT</span>
                                        :
                                        <span style={{color: ifDisableButton() ? '#707070' : '#fff'}}>CONFIRM</span>
                                }
                            </Button>
                        </div>
                        :
                        <></>
                    }
                </div>
            </Dialog>
        </>
    )
}

export default ScheduleDelivery