export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const setCustomerTypeAction = (customerType: any) =>
({
    type: SET_CUSTOMER_TYPE,
    customerType,
} as const);

type CustomerTypeActions =
    | ReturnType<typeof setCustomerTypeAction>;

export const customerTypeReducer = (
    state = <any>null,
    action: CustomerTypeActions,
) => {
    switch (action.type) {
        case SET_CUSTOMER_TYPE: {
            return action.customerType;
        }
    }

    return state;
};

