import { getCMSAssets, getFrontendUrl, getFrontendUrlWithSlash } from 'helpers/Uri'
import React, { useEffect, useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setGlobalDropdown } from 'stores/GlobalDropdown';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';

const GlobalShop = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const toConfigure = (e: any) => {
        navigate(`${getFrontendUrlWithSlash()}`, { state: { closePreLoader: false, isFromOtherPage: false } });
        dispatch(setGlobalDropdown(false, false));
        if (location.pathname == '/') {
            e.preventDefault();
            window.location.reload();
        }
    }

    const toInvertory = () => {
        navigate(`${getFrontendUrl()}/inventory?shopfront=1`, {
            state: { filterShow: true }  
        });
        dispatch(setGlobalDropdown(false, false));
        dispatch(checkIsShowScrollBar(true))
    }

    const toTestDrive = () => {
       
        let url = `${getFrontendUrl()}/test-drive`;
        navigate(url);
        dispatch(setGlobalDropdown(false, false));
        dispatch(checkIsShowScrollBar(true))
    }

    const toCenters = () => {
        window.open('https://www.cadillacanz.com/au-en/visit-a-showroom')
    }

    const onHidePanel = () => {
        // onChangeActiveBtn('')
    }
    
    return (
        <div className='global-shop-container' >
            <div className='shop-left-selection-panel'>
                <div className='shop-select-item stat-text-link' data-dtm="global nav" onClick={(e) => toConfigure(e)}>
                    <div className='left-blue-bar'></div>
                    <h6>BUILD & PRICE</h6>
                </div>
                <div className='shop-select-item stat-text-link' data-dtm="global nav" onClick={() => toInvertory()}>
                    <div className='left-blue-bar'></div>
                    <h6>EXPLORE INVENTORY</h6>
                </div>
                <div className='shop-select-item stat-text-link' data-dtm="global nav" onClick={() => toTestDrive()}>
                    <div className='left-blue-bar'></div>
                    <h6>TEST DRIVE</h6>
                </div>
            </div>
            
            <div className='shop-right-selection-panel'>
                <div className='shop-select-title'>
                    {/* <div className='left-blue-bar'></div> */}
                    <h6>VISIT US</h6>
                </div>
                <div className='shop-select-item'>
                    <div className='left-blue-bar'></div>
                    <h6 onClick={() => toCenters()}>Cadillac Experience Centres</h6>
                </div>
            </div>
            
        </div>
    )
}

export default GlobalShop