
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { currencyFormat } from 'helpers/Uri';

import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import * as selectedDeliveryStore from "stores/UserSelectedDelivery";
import * as vehicleStore from "stores/Vehicle";

import { IAppState } from 'model/IAppState';
import * as vehicleModel from 'model/IVehicle';
import * as vehicleService from 'services/Vehicle';
import * as defaultConfigStore from 'stores/DefaultConfig';
import * as defaultConfigHelper from 'businessHelpers/DefaultConfigHelper';

const Interior = () => {
    const dispatch = useDispatch();
    //const location = useLocation();
    //const searchParams = new URLSearchParams(location.search);
    //const selectedInteriorNameFromUrl = searchParams.get('selectedInterior');


    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const vehicleFromStore = useSelector((appState: IAppState) => {
        return appState.vehicle;
    });
    const defaultConfigFromStore = useSelector((state: IAppState) => {
        return state.defautConfig;
    });
    const interiorListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.InteriorList;
    });


    const [selectedInterior, setSelectedInterior] = useState<vehicleModel.IInterior | null>(null);
    const [interiors, setInteriors] = useState<vehicleModel.IInterior[]>([]);


    const [isPaidVisible, setIsPaidVisible] = useState(false);




    useEffect(() => {
        // Trigger fade-in effect when chooseColor.paid changes
        setIsPaidVisible(false);

        // Clear the visibility after the transition duration
        const timeoutId = setTimeout(() => {
            setIsPaidVisible(true)
        }, 260); // Adjust the duration based on your transition time

        // Clear the timeout to avoid unnecessary updates
        return () => clearTimeout(timeoutId);
    }, [vehicleFromStore?.SelectedInterior]);

    useEffect(() => {
        if (!isNil(vehicleFromStore?.SelectedExterior?.ExteriorID) && vehicleFromStore?.SelectedExterior?.ExteriorID != "") {
            doGetInterior();
        }
    }, [vehicleFromStore?.SelectedExterior])

    useEffect(() => {
        if (!isNil(vehicleFromStore?.SelectedInterior?.InteriorID) && vehicleFromStore?.SelectedInterior?.InteriorID != "") {
            doGetOptions();
        }
    }, [vehicleFromStore?.SelectedInterior])

    useEffect(() => {
        if (!isNil(interiors) && interiors.length > 0) {
            //set default interior from interest link/inventory link
            if (!isNil(defaultConfigFromStore?.InteriorName) && defaultConfigFromStore?.InteriorName != "") {
                let updatedInteriors = interiors.map((item: vehicleModel.IInterior) => {
                    if (defaultConfigFromStore?.InteriorName == item.InteriorColorName) {
                        return {
                            ...item,
                            IsDefault: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            IsDefault: false
                        };
                    }
                });

                let isDefaultInterior = updatedInteriors.find(m => m.IsDefault);
                if (isDefaultInterior != null && isDefaultInterior != undefined) {
                    setSelectedInterior(isDefaultInterior);

                    //update Est. delivery
                    //console.log("isDefaultInterior-" + isDefaultInterior.Available);
                    //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(isDefaultInterior.Available));

                    //update selected interior and stock
                    dispatch(selectedConfigStore.updateInteriorAction(
                        isDefaultInterior.InteriorID || "",
                        isDefaultInterior.InteriorColorName || "",
                        isDefaultInterior?.InteriorPrice || 0,
                        isDefaultInterior.HasStock || false));
                }
               
                dispatch(vehicleStore.updateInteriorAction(updatedInteriors, isDefaultInterior || null));

                dispatch(defaultConfigStore.setDefaultConfigLoadedAction(true));
            }
            else {
                //set default interior from interior api
                let isDefaultInterior = interiors.find(m => m.IsDefault);
                if (isDefaultInterior != null && isDefaultInterior != undefined) {
                    setSelectedInterior(isDefaultInterior);

                    //update Est. delivery
                    //console.log("isDefaultInterior-" + isDefaultInterior.Available);
                    //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(isDefaultInterior.Available));

                    //update selected interior and stock
                    dispatch(selectedConfigStore.updateInteriorAction(
                        isDefaultInterior?.InteriorID || "",
                        isDefaultInterior.InteriorColorName || "",
                        isDefaultInterior?.InteriorPrice || 0,
                        isDefaultInterior.HasStock || false));
                }
                dispatch(vehicleStore.updateInteriorAction(interiors, isDefaultInterior || null));
            }
        }
        else {
            setSelectedInterior(null);
            //console.log("isDefaultInterior-null");
            //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(null));
            dispatch(selectedConfigStore.updateInteriorAction("", "", 0, false));
            dispatch(vehicleStore.updateInteriorAction([], null));
        }

    }, [interiors, defaultConfigFromStore?.InteriorName])

    const doGetInterior = async () => {
        let interiorRequest: vehicleModel.IInterior = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            State: selectedDeliveryFromStore.State?.code || null,
            VariantID: vehicleFromStore.SelectedVariant?.VariantID || null,
            VariantName: vehicleFromStore.SelectedVariant?.VariantName || null,
            ExteriorID: vehicleFromStore.SelectedExterior?.ExteriorID || null,
            ExteriorName: vehicleFromStore.SelectedExterior?.ExteriorColorName || null,
            InteriorID: null,
            InteriorColorName: null,
            InteriorColorDescTitle: null,
            InteriorColorDesc: null,
            InteriorPrice: null,
            InteriorPicture: null,
            InteriorPictureMobile: null,
            InteriorFilterImage: null,
            InteriorFilterMobile: null,
            IsDefault: null,
            Available: null,
            HasStock: null,
            InteriorOrderingNo: 0,
        };

        if (isNil(interiorRequest.VariantID) || interiorRequest.VariantID == ""
            || isNil(interiorRequest.ExteriorID) || interiorRequest.ExteriorID == "")
            return;

        let response = await vehicleService.getInteriors(interiorRequest);
        if (response.status) {
            let resData: vehicleModel.IInterior[] = [];
            if (response.data.length > 0) {
                resData = response.data.map((item: any) => {
                    return {
                        ...interiorRequest,
                        InteriorID: item.interiorID,
                        InteriorColorName: item.interiorColorName,
                        InteriorColorDescTitle: item.interiorColorDescTitle,
                        InteriorColorDesc: item.interiorColorDesc,
                        InteriorPrice: item.interiorColorPrice,
                        InteriorPicture: item.interiorPicture,
                        InteriorPictureMobile: item.interiorPictureMobile,
                        InteriorFilterImage: item.interiorFilter,
                        InteriorFilterMobile: item.interiorFilterMobile,
                        IsDefault: item.isDefault,
                        Available: item.available,
                        HasStock: item.hasStock
                    };
                });
            }
            setInteriors(resData);
        }
        else {
            setInteriors([]);
            setSelectedInterior(null);
            //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(null));
            dispatch(selectedConfigStore.updateInteriorAction("", "", 0, false));
            dispatch(vehicleStore.updateInteriorAction([], null));
        }
    }

    const doGetOptions = async () => {
        let requestData: vehicleModel.IVehicleOptionRequest = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            VariantID: vehicleFromStore.SelectedVariant?.VariantID || null,
            ExteriorID: vehicleFromStore.SelectedExterior?.ExteriorID || null,
            InteriorID: vehicleFromStore.SelectedInterior?.InteriorID || null,
        };
        let response = await vehicleService.getOptions(requestData);
        if (response.status) {
            let accessories: vehicleModel.IVehicleOptions[] = [];
            if (!isNil(response.data.accessories) && response.data.accessories.length > 0) {
                response.data.accessories.map((item: any) => {
                    accessories.push({
                        YanaOptionID: item.yanaOptionID,
                        OptionType: item.optionType,
                        OptionName: item.optionName,
                        OptionPrice: item.optionPrice,
                        OptionPicture: item.optionPicture,
                        OptionPictureMobile: item.optionPictureMobile,
                        OptionDesc: item.optionDesc,
                        ChargeType: item.chargeType,
                        Disable: false
                    });
                });
            }

            let chargingList: vehicleModel.IVehicleOptions[] = [];
            if (!isNil(response.data.charging) && response.data.charging.length > 0) {
                response.data.charging.map((item: any) => {
                    chargingList.push({
                        YanaOptionID: item.yanaOptionID,
                        OptionType: item.optionType,
                        OptionName: item.optionName,
                        OptionPrice: item.optionPrice,
                        OptionPicture: item.optionPicture,
                        OptionPictureMobile: item.optionPictureMobile,
                        OptionDesc: item.optionDesc,
                        ChargeType: item.chargeType,
                        Disable: false
                    });
                });
            }

            //dispatch to store
            dispatch(vehicleStore.updateOptionsAction(accessories, chargingList));
            dispatch(selectedConfigStore.updateAllOptionsAction([]));
        }
        //else {
        //    dispatch(vehicleStore.setLoadingAction(true));
        //}
    }

    const interiorTemplate = (item: vehicleModel.IInterior, index: number) => {
        return (
            <div key={"interior_" + index}
                data-dtm="interior"
                className={classNames({
                    "stat-image-link": true,
                    "color-options-item": true,
                    "active": selectedInterior?.InteriorID == item.InteriorID
                })}
                onClick={() => {
                    setSelectedInterior(item);

                    dispatch(vehicleStore.updateSelectedInteriorAction(item || null));

                    //update Est. delivery
                    //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(item.Available));

                    //update selected interior and stock
                    dispatch(selectedConfigStore.updateInteriorAction(
                        item?.InteriorID || "",
                        item?.InteriorColorName || "",
                        item?.InteriorPrice || 0,
                        item.HasStock || false));

                    //clear selected accessories & charging when color changed
                    dispatch(selectedConfigStore.updateAllOptionsAction([]));
                }}
            >
                <div className='color-options-round'>
                    <img className="image" src={item?.InteriorFilterImage || undefined} alt={item.InteriorColorName || ""} />
                </div>
            </div>
        );

    }

    return (
        <div className='interior-container'>
            <h3 className='main-page-section-title'>INTERIOR</h3>

            <div className='title-panel'>
                <p style={{ opacity: isPaidVisible ? 1 : 0, transition: 'opacity .26s ease-in-out' }}>{isPaidVisible && selectedInterior?.InteriorColorName || ''}</p>
                <span className='extra-paid' style={{ opacity: isPaidVisible ? 1 : 0, transition: 'opacity .26s ease-in-out' }}>
                    {isPaidVisible && currencyFormat(selectedInterior?.InteriorPrice || 0)}
                </span>
            </div>

            <div className='interior-options-panel'>
                {!isNil(interiorListFromStore) && interiorListFromStore != null &&
                    interiorListFromStore.length > 0 && (interiorListFromStore as vehicleModel.IInterior[]).map((item: vehicleModel.IInterior, index: number) => {
                        if (!isNil(defaultConfigFromStore.InteriorName) && defaultConfigFromStore.InteriorName != ""
                            && (isNil(defaultConfigFromStore.LinkKey) || defaultConfigFromStore.LinkKey == "")) {
                            //if from inventory page
                            if (item.InteriorColorName == defaultConfigFromStore.InteriorName) {
                                return interiorTemplate(item, index);
                            }
                            else {
                                return (<></>);
                            }
                        }
                        else {
                            return interiorTemplate(item, index);
                        }
                    })}
            </div>

            <div className='desc-content'><span>{selectedInterior?.InteriorColorDescTitle}</span> {selectedInterior?.InteriorColorDesc}</div>
        </div>
    )
}

export default Interior