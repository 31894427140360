import { MainLayoutPage } from 'LayoutPages'
import CarCarousel from 'components/CarCarousel'
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri'
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { GlobalMenu } from 'scene/Layout/GlobalMenu'
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { IAppState } from 'model/IAppState';
import { ISessionState } from "model/ISessionState";
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as rdEnum from 'helpers/enum';
import * as orderModel from 'model/IOrder';
import * as orderService from 'services/Order';
import * as userService from 'services/User';
import * as sessionStore from 'stores/Session';
import { useMsal } from '@azure/msal-react';
import { useAzureB2C } from 'helpers/useAzureB2C';
import Spinner from 'components/Spinner';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';

const MyOrders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const azureB2C = useAzureB2C();
    const { inProgress } = useMsal();


    const [myOrderList, setMyOrderList] = useState<orderModel.IMyOrderListingItemViewModel[] | null>(null);
    const [loadingStatus, setLoadingStatus] = useState(rdEnum.enumProcessingStatus.Processing);

    //store variables
    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });


    useEffect(() => {
        if (azureB2C.isAuthAndLogin(inProgress)) {
            doGetMyOrders(sessionFromStore?.YanaCustomerID || "",
                sessionFromStore?.YanaCustomerNo || "");
        }
    }, [inProgress]);


    const doGetMyOrders = async (yanaCustomerID: string, yanaCustomerNo: string) => {
        setLoadingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.getOrderByYanaCustomerID(yanaCustomerID, yanaCustomerNo);
        setLoadingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            let orderList: orderModel.IMyOrderListingItemViewModel[] = [];
            if (!isNil(response.data) && response.data.length > 0) {
                response.data.map((item: any) => {
                    orderList.push({
                        OrderID: item.yanaOrderIDs.yanaOrderID,
                        OrderNo: item.yanaOrderIDs.yanaOrderNo,
                        VariantName: item.orderVehicle.variantName,
                        VariantImage: item.orderVehicle.variantPicture,
                        //VariantMobileImage: item.orderVehicle.variantPictureMobile,
                        ExteriorName: item.orderVehicle.exteriorName,
                        InteriorName: item.orderVehicle.interiorName,
                        OrderDate: item.orderDate,
                        OrderStatus: item.orderStatus,
                        IsShowActionRequiredWording: item.isShowActionRequiredWording,
                    });

                });
            }
            setMyOrderList(orderList);
        }
        else {
            if (response.errorCode == rdEnum.enumRDErrorCode.NotSignIn) {
                azureB2C.signIn();
            }
        }

    }

    const toOrderDetails = (orderId: any, orderNo: any) => {
        let url = `${getFrontendUrl()}/my-orders/${btoa(orderId)}/${btoa(orderNo)}`;
        navigate(url);
    }

    const containerRef = useRef<HTMLDivElement | null>(null); 

    useEffect(() => {
        dispatch(checkIsShowScrollBar(true));
    }, []);

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }

    }, []);

    return (

        <MainLayoutPage sceneId="container-has-header">

            <GlobalMenu />

            <div className='my-orders-container'>
                <div className='my-orders-title'>
                    <h2>MY ORDERS</h2>
                </div>

                <Row className='orders-container'>
                    {loadingStatus === rdEnum.enumProcessingStatus.Processing && (
                        // <ProgressSpinner
                        //     style={{ width: '64px', height: '64px' }}
                        //     strokeWidth="5"
                        //     animationDuration=".8s"
                        // />
                        <Spinner spinnerWidth={64} />
                    )}
                    {
                        loadingStatus === rdEnum.enumProcessingStatus.Processed && !isNil(myOrderList) && myOrderList.length > 0 && myOrderList.map((orderItem: orderModel.IMyOrderListingItemViewModel, index: any) => {
                            return (
                                <Col lg={4} className='order-item' key={"orderItem_" + orderItem + index}>
                                    <div className='order-item-inner'>
                                        <div className='order-item-header'>
                                            <div className='order-item-title'>
                                                <h6>{orderItem.VariantName.toUpperCase()}</h6>
                                                <p>Order No: {orderItem.OrderNo} (Ordered on: {orderItem?.OrderDate})</p>
                                            </div>
                                            <div className='action-reminder-box' >
                                                <p style={{ height:"18px", visibility: orderItem.IsShowActionRequiredWording ? 'visible' : 'hidden' }}>Action Required</p>
                                                <img alt='' style={{ visibility: orderItem.IsShowActionRequiredWording ? 'visible' : 'hidden' }}
                                                    src={`${getCMSAssets()}/users/exclamation-mark-red.svg`}
                                                />
                                            </div>
                                        </div>
                                        <div className='carousel-panel' style={{ opacity: orderItem.OrderStatus == rdEnum.enumOrderStatus.Cancelled ? .5 : 1 }}>
                                            <CarCarousel variantAWD='luxury' exteriorColor="Argent Silver Metallic"
                                                interiorColor="Oxford Stone"
                                                carouselInterval={null}
                                                images={orderItem.VariantImage}
                                                mobileImages={orderItem.VariantImage}
                                                isUseImages={true}
                                            />
                                        </div>
                                        <div className='order-status' style={{height: "40px"}}>
                                            {orderItem.OrderStatus}
                                        </div>
                                        <div className='variant-btn-panel'>
                                            <Button className={orderItem.OrderStatus == rdEnum.enumOrderStatus.PreparingToShip || orderItem.OrderStatus == rdEnum.enumOrderStatus.Delivered || orderItem.OrderStatus == rdEnum.enumOrderStatus.Cancelled ? 'variant-btn' : 'variant-btn view-details'}
                                                style={{
                                                    backgroundColor: orderItem.OrderStatus == rdEnum.enumOrderStatus.Ordered || orderItem.OrderStatus == rdEnum.enumOrderStatus.Confirmed ? '#282828' : '#fff',
                                                    color: orderItem.OrderStatus == rdEnum.enumOrderStatus.Ordered || orderItem.OrderStatus == rdEnum.enumOrderStatus.Confirmed ? '#fff' : '#282828'
                                                }}
                                                onClick={() => {
                                                    toOrderDetails(orderItem.OrderID, orderItem.OrderNo);
                                                }}
                                            >
                                                <h6>
                                                    VIEW DETAILS
                                                {/*{*/}
                                                    {/*    orderItem.OrderStatus == rdEnum.enumOrderStatus.PreparingToShip || orderItem.OrderStatus == rdEnum.enumOrderStatus.Delivered || orderItem.OrderStatus == rdEnum.enumOrderStatus.Cancelled*/}
                                                    {/*        ?*/}
                                                    {/*        'VIEW DETAILS'*/}
                                                    {/*        :*/}
                                                    {/*        orderItem.OrderStatus == rdEnum.enumOrderStatus.Ordered*/}
                                                    {/*            ?*/}
                                                    {/*            'COMPLETE ACCOUNT SETUP'*/}
                                                    {/*            :*/}
                                                    {/*            orderItem.OrderStatus == rdEnum.enumOrderStatus.Confirmed*/}
                                                    {/*                ?*/}
                                                    {/*                'SCHEDULE DELIVERY'*/}
                                                    {/*                :*/}
                                                    {/*                'VIEW DETAILS'*/}
                                                    {/*}*/}
                                                </h6>
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
        </MainLayoutPage>
    )
}

export default MyOrders