import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Navbar, Accordion } from 'react-bootstrap';

import {
    getImageFileNameByID2,
    IImagesState,
    getImageLinkByID2,
    getMobileImageLinkByID2,
    getCMSAssets,
    getServiceUrlPara
} from 'helpers/Uri';
import { clearSessionAction } from "stores/Session";
import { IAppState } from 'model/IAppState';
import { enumOrdersTabName } from "helpers/enum";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { AiOutlineSearch, AiOutlineLogin, AiOutlineUser  } from "react-icons/ai";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { logout } from 'services/User';

//import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';
import { Menu } from 'primereact/menu';
import { UserPopUp } from 'scene/Users/UserPopUp';
import { setScrollPosition } from 'stores/ScrollPosition';
import { setMainPageNumber } from 'stores/MainPageNumber';
import { useScroll } from 'pages/Main/ScrollToSection';
import { useSatellite } from 'helpers/useSatellite';
// interface IMenuClickToScrollProps {
//     scrollToSection: (section: string) => void;
// }

export const MainMenu 
// : React.FC<IMenuClickToScrollProps> 
= () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const satellite = useSatellite();
    const {scrollToSection} = useScroll();

    //const gaEventTracker = useAnalyticsEventTracker('Menu');

    const loginSession = useSelector((state: IAppState) => {
        return state.session;
    });

    const scrollPosition = useSelector((state: IAppState) => {
        return state.scrollPosition;
    });

    const checkMainSection = useSelector((state: IAppState) => {
        return state.mainSection
    })
    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber
    })


    const [activeButton, setActiveButton] = useState('variant');

    const [blackEffectMenu, setBlackEffectMenu] = useState<any>([]);

    // if (activeButton == 'payment') {
    //     setBlackEffectMenu(['variant', 'exterior', 'interior', 'accessories'])
    // } else if (activeButton == 'accessories') {
    //     setBlackEffectMenu(['variant', 'exterior', 'interior'])
    // } else if (activeButton == 'interior') {
    //     setBlackEffectMenu(['variant', 'exterior'])
    // } else if (activeButton == 'exterior') {
    //     setBlackEffectMenu(['variant'])
    // } else if (activeButton == 'variant') {
    //     setBlackEffectMenu([])
    // }

    useEffect(() => {
        setActiveButton(checkMainSection.mainSection);
        setScrollPosition('global');
    }, [checkMainSection.mainSection]);



    const isMobile = useCheckMobileScreen();

    const onGotoFavouritesPage = () => {
        navigate(`/favourites`);
    }
    const onGotoOrdersManagementPage = (tab:number) => {
        //buttonClickLog("Menu", "Orders");
        navigate(`/member-orders/${tab}`);
    }
    const onGotoAdminOrdersManagementPage = (tab: number) => {
        navigate(`/manager-orders/${tab}`);
    }
    const onGotoUpdateProfilePage = () => {
        navigate(`/update-member-details`);
    }
    const onGotoUpdatePasswordPage = () => {
        navigate(`/update-password`);
    }

    const onLogOut = async () => {
        const result = await logout();
        // dispatch(clearSessionAction());
        // onResetToAllVehicles();
        showSignPage();
    }

    const showSignPage = () => {
        navigate(`/sign-in`);
    }


    // console.log(`${getImageLinkByID2(languageState.LanguageScreenName, images.imageList, "logo")}`);

    const element = document.getElementById('sectionBtn');
    if (element) {
        element.addEventListener('click', () => {
            element.classList.add('clicked');
        });
    }

    return (
        <div className="main-menu-container">
           
            <div className='main-menu-panel '>

                <Row className="main-menu page-container-content ">
                    <Col sm={12} className="menu-items">
                        <div className='logo-container'>
                            <button className={
                                `certainty-panel stat-text-link ${activeButton === 'variant' 
                                ? 
                                'active' 
                                : 
                                ['exterior', 'interior', 'accessories', 'charging', 'payment'].includes(activeButton)
                                ?
                                'black'
                                :
                                        ''}`} 
                                data-dtm="step nav"
                                style={{cursor: mainPageNumber == 1 ? 'auto' : 'pointer'}}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('variant')
                                        setActiveButton('variant');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>VARIANT</button>
                            <button className={
                                `certainty-panel stat-text-link ${activeButton === 'exterior'
                                ? 
                                'active' 
                                : 
                                ['interior', 'accessories', 'charging', 'payment'].includes(activeButton)
                                ?
                                'black'
                                :
                                        ''}`} 
                                data-dtm="step nav"
                                style={{cursor: mainPageNumber == 1 ? 'auto' : 'pointer'}}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('exterior');
                                        setActiveButton('exterior');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>EXTERIOR</button>
                            <button className={
                                `certainty-panel stat-text-link ${activeButton === 'interior' 
                                ? 
                                'active' 
                                : 
                                ['accessories', 'charging', 'payment'].includes(activeButton)
                                ?
                                'black'
                                :
                                        ''}`} 
                                data-dtm="step nav"
                                style={{cursor: mainPageNumber == 1 ? 'auto' : 'pointer'}}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('interior');
                                        setActiveButton('interior');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>INTERIOR</button>
                            <button className={
                                `certainty-panel stat-text-link ${activeButton === 'accessories' 
                                ? 
                                'active' 
                                : 
                                ['charging', 'payment'].includes(activeButton)
                                ?
                                'black'
                                :
                                        ''}`} 
                                data-dtm="step nav"
                                style={{cursor: mainPageNumber == 1 ? 'auto' : 'pointer'}}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('accessories');
                                        setActiveButton('accessories');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>ACCESSORIES</button>
                             <button className={
                                `certainty-panel stat-text-link ${activeButton === 'charging' 
                                ? 
                                'active' 
                                : 
                                activeButton === 'payment'
                                ?
                                'black'
                                :
                                        ''}`} 
                                data-dtm="step nav"
                                style={{cursor: mainPageNumber == 1 ? 'auto' : 'pointer'}}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('charging');
                                        setActiveButton('charging');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>CHARGING</button>
                            <button className={`certainty-panel stat-text-link ${activeButton === 'payment' || activeButton === 'delivery' || activeButton === 'checkout' || activeButton === 'checkoutForm' ? 'active' : ''}`}
                                data-dtm="step nav"
                                style={{ cursor: mainPageNumber == 1 ? 'auto' : 'pointer' }}
                                disabled={mainPageNumber == 1 ? true: false}
                                onClick={() => {
                                    setTimeout(() => {
                                        scrollToSection('payment');
                                        setActiveButton('payment');
                                    }, 200);
                                    dispatch(setMainPageNumber(0));
                                }
                            }>CHECK OUT</button>
                        </div>

                        {/* <UserPopUp isClickable={true} /> */}
                        
                    </Col>
                </Row>
                

                {/* <Row className="main-menu page-container-content">
                    <div className="menu-items">
                        <div className='left-panel'>
                            <a className="menu-item"
                                onClick={() => {
                                    // return showProducts();
                                }}
                            >
                                RESERVE YOUR LYRIQ
                            </a>
                        </div>

                        <div className='right-panel'>
                            <a className='menu-item'
                                onClick={() => {
                                    // return showPartsFinderPage();
                                    return showComingSoon();
                                }}
                            >
                                TRIM
                            </a>
                            <a className="menu-item"
                                onClick={() => {
                                    // return showDownloads();
                                    return showComingSoon();
                                }}
                            >
                                DETAILS
                            </a>
                            <a className="menu-item"
                                onClick={() => {
                                    // return showWarranty();
                                    return showComingSoon();
                                }}
                            >
                                PAYMENT
                            </a>
                            <a className='menu-item'
                                onClick={() => {
                                    // return showTDs();
                                    return showComingSoon();
                                }}
                            >
                                CONFIRMATION
                            </a>
                        </div>
                        
                    </div>
                </Row> */}
            </div>
          
        </div>
    );
        
};


