import moment from 'moment';
import { isNil } from 'lodash';
import { ICodeName } from 'model/ICommon';
import { enumCountryCode, enumRDErrorCode } from 'helpers/enum';

export const getBackendOrigin = () => {
    return `${process.env.REACT_APP_BACKEND_ORIGIN}`;
};

export const getBackendVirtualDir = () => {
    return process.env.REACT_APP_VIRTUAL_DIR_BACKEND;
};

export const getBackendPath = () => {
    return `${process.env.REACT_APP_BACKEND_ORIGIN}/${process.env.REACT_APP_VIRTUAL_DIR_BACKEND}`;
};

export const getFrontendOrigin = () => {
    return `${process.env.REACT_APP_FRONTEND_ORIGIN}`;
};

export const getFrontendUrl = () => {
    let virtualDir = process.env.REACT_APP_VIRTUAL_DIR_FRONTEND;
    if (virtualDir != "") {
        return `/${process.env.REACT_APP_VIRTUAL_DIR_FRONTEND}`;
        //return `${process.env.REACT_APP_FRONTEND_ORIGIN}/${process.env.REACT_APP_CMS_VIRTUAL_DIR}`;
    }
    else {
        return ``;
    }
};


export const getFrontendUrlWithSlash = () => {
    let virtualDir = process.env.REACT_APP_VIRTUAL_DIR_FRONTEND;
    if (virtualDir != "") {
        return `/${process.env.REACT_APP_VIRTUAL_DIR_FRONTEND}`;
    }
    else {
        return `/`;
    }
};




export const getCMSAssets = () => {
    let virtualDir = process.env.REACT_APP_CMS_VIRTUAL_DIR;
    if (virtualDir != "") {
        return `${process.env.REACT_APP_FRONTEND_ORIGIN}/${process.env.REACT_APP_CMS_VIRTUAL_DIR}/cms`;
    }
    else {
        return `${process.env.REACT_APP_FRONTEND_ORIGIN}/cms`;
    }
};

//export const getOnlineCatalogueName = () => {
//    return `${process.env.REACT_APP_ONLINE_CATALOGUE_NAME}`;
//};

//export const getImgURL = () => {
//    return `${process.env.REACT_APP_IMAGE_URL}`;
//};


//export const getPMPURL = () => {
//    return `${process.env.REACT_APP_PMP_URL}`;
//};

//export const getPublicURL = () => {
//    return `${process.env.PUBLIC_URL}`;
//};

//export const getLanguageBackendPath = () => {
//    return `${process.env.REACT_APP_LANGUAGE_API}`;
//};

//export const getLanguage = () => {
//    return `${process.env.REACT_APP_LANGUAGE}`;
//};


export const getCountry = () => {
    return `${process.env.REACT_APP_COUNTRY}`;
};


export const getGoogleMapAPIKey = () => {
    return `${process.env.REACT_APP_GOOGLEMAP_APIKEY}`;
};

export const getRDGoogleAnalyticsMeasureID = () => {
    return `${process.env.REACT_APP_RD_GOOGLE_ANALYTICS}`;
};


export const getAdyenEnv = (country: any) => {
    let env = `${process.env.REACT_APP_AdyenEnv}`;
    if (env == "test") {
        return env;
    }
    if (country == "NZ") {
        return "live-au";
    }
    return "live-au";
};

export const getAdyenKey = (country: string) => {
    if (country == enumCountryCode.Australia) {
        return `${process.env.REACT_APP_Adyen}`;
    }
    else if (country == enumCountryCode.NewZealand) {
        return `${process.env.REACT_APP_AdyenNZ}`;
    }
};

export const getShareLink = (webUniqueKey: string) => {
    return `${process.env.REACT_APP_ShareLink}${webUniqueKey}`;
};

export const checkBookingTimeout = (bookingStoreLastUpdateTime: Date | null) => {
    if (!isNil(bookingStoreLastUpdateTime) && moment(bookingStoreLastUpdateTime).add(30, "minutes") < moment(new Date())) {
        return true;
    }
    return false;
}

export const getImageFileNameByID2 = (imageList: any, id2: string) => {
    if (!isNil(imageList)) {
        let findImage = imageList.find((m: any) => m.id2 == id2);
        if (!isNil(findImage)) {
            return findImage.fileName
        }
    }
    return null;
}

export const getImageLinkByID2 = (screenName: string, imageList: any, id2: string) => {

    return `${getCMSAssets()}/${screenName}/${getImageFileNameByID2(imageList, id2)}`;
}

export const getMobileImageLinkByID2 = (screenName: string, imageList: any, id2: string) => {
    return `${getCMSAssets()}/${screenName}/${"m-" + getImageFileNameByID2(imageList, id2)}`;
}

export const getServiceUrlPara = (id2: string, index: number, value: string) => {
    let urlPara = id2.replace("-" + value, "");
    return urlPara;
}


export const getSelectState = (stateList: ICodeName[], selectedStateCode: string): ICodeName | null => {
    let findState = stateList.find(m => m.code == selectedStateCode);
    if (isNil(findState)) {
        return null;
    }
    return findState;
}

export interface IPageLanguageState {
    LanguageScreenName: string;
    LanguageResourceLoad: boolean | null;
}

export interface IImagesState {
    imageLoaded: boolean,
    imageList: any
}

export interface IPDFState {
    pageName: string,
    pdfList: Array<object>
}

export const currencyFormat = (value: number | null) => {
    let currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });


    if (isNil(value))
        return "";
    if (value == 0)
        return "included"
    return "+ " + currencyFormatter.format(value);
}

export const currencyPlainFormat = (value: number | null) => {
    let currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    if (isNil(value))
        return "0";

    return currencyFormatter.format(value);
}


export const currencyFormatWithIncluded = (value: number | null) => {
    let currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });


    if (isNil(value) || value == 0)
        return "included"
    return currencyFormatter.format(value);
}



export const currencyFormatWithBlank = (value: number | null) => {
    let currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });


    if (isNil(value))
        return "";
    return currencyFormatter.format(value);
}


export const currencyFormatWithZeroBlank = (value: number | null) => {
    let currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });


    if (isNil(value) || value == 0)
        return "";
    return currencyFormatter.format(value);
}


export const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const getFullAddress = (address: any, suburb: any, state: any, postcode: any) => {
    return address + ", " + suburb + " " + state + " " + postcode;
}


export const noActivityRedirectHomePage = (resposne: any) => {
    if (resposne.errorCode == enumRDErrorCode.NoActivity) {
        let url = getFrontendOrigin();
        window.location.href = url;
    }
}

//declare global {
//    interface Window {
//        dataLayer: any[];
//    }
//}
//export const pushToDataLayer = (data: object): void => {
//    // Check if the dataLayer exists on the window object
//    if (window.dataLayer) {
//        window.dataLayer.push(data);
//    } else {
//        // If dataLayer doesn't exist, initialize it and push the event
//        window.dataLayer = [data];
//    }
//};