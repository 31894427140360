import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card';
import {Button} from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat } from 'helpers/Uri';

import * as rdEnum from 'helpers/enum';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as vehicleModel from 'model/IVehicle';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { IAppState } from 'model/IAppState';

import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';

const Accessories = () => {
    const dispatch = useDispatch();

    const accessories: vehicleModel.IVehicleOptions[] = useSelector((state: IAppState) => {
        return state.vehicle.Accessories;
    });

    const selectedOptionsFromStore: vehicleModel.IVehicleOptions[] | null = useSelector((state: IAppState) => {
        return state.selectedVehicle.Options;
    });

    const handleSelect = (item: vehicleModel.IVehicleOptions) => {
        dispatch(selectedConfigStore.updateOptionAction(item));
    };

    const isSelectedOption = (item: vehicleModel.IVehicleOptions): boolean => {
        if (isNil(selectedOptionsFromStore) || selectedOptionsFromStore.length == 0) return false;
        let findSelectedOption = selectedOptionsFromStore.find((selectedItems: vehicleModel.IVehicleOptions) => {
            //SP does not store yana option id, so when users come from interest link, there is only has option name
            return selectedItems?.YanaOptionID == item.YanaOptionID || selectedItems?.OptionName == item.OptionName; 
        });
        return !isNil(findSelectedOption);
    }

    setTimeout(() => {
        let accessoriesCheckbox = document.getElementsByName("accessories");
        for (let i = 0; i < accessoriesCheckbox.length; i++) {
            accessoriesCheckbox[i].setAttribute('data-dtm', 'accessories');
            accessoriesCheckbox[i].classList.add('stat-checkbox');
        }
    }, 200);


    return (
        <div className='additional-container'>
            <h3 className='main-page-section-title'>ACCESSORIES</h3>
            <p className='additional-desc'>Customise your LYRIQ by adding the extras you need.</p>

            {
                !isNil(accessories) && accessories.length > 0 && accessories.map((item: vehicleModel.IVehicleOptions, index: any) => {
                    const header = <img alt="Card" src={item.OptionPicture.length>0 ? item.OptionPicture[0] : ""} />;
                    return (
                        <Card id={item + index} key={item + index}
                            style={{
                                padding: '.3rem',
                                border: isSelectedOption(item) ? '2px solid #282828' : '1px solid #d2d2d2'
                            }}    
                        >
                            <div className='card-inner'>
                                <label className='card-header-img' htmlFor={`accessories + ${item + index}`}>
                                    <img alt="Card" src={item.OptionPicture.length>0 ? item.OptionPicture[0] : ""} />
                                </label>
                                <div className='checkbox-panel'>
                                    <Checkbox
                                        name="accessories"
                                        inputId={`accessories + ${item + index}`}
                                        onChange={() => {
                                            handleSelect(item);
                                        }}
                                        checked={isSelectedOption(item)}
                                    ></Checkbox>
                                    <label className='card-text-panel' htmlFor={`accessories + ${item + index}`}>
                                        <p>{item.OptionName}</p>
                                        <div className='card-info-selection'>
                                            <span>{currencyFormat(item.OptionPrice || 0)}</span>
                                        </div>
                                    </label>
                                </div>
                                
                            </div>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default Accessories