import { MainLayoutPage } from 'LayoutPages'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { Button, Col, Row } from 'react-bootstrap';
import { InputSwitch } from 'primereact/inputswitch';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';


import { IAppState } from 'model/IAppState';
import * as rdEnum from 'helpers/enum';
//import { useGetActiveAccount } from 'helpers/useAzureB2C';
import * as profileModel from 'model/IUserProfile';
import * as profileService from 'services/User';
import * as profileHelper from 'businessHelpers/ProfileHelper';
import * as sessionStore from 'stores/Session';

import { useMsal } from '@azure/msal-react';
import { useAzureB2C } from 'helpers/useAzureB2C';
import Spinner from 'components/Spinner';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';

const Privacy = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const azureB2C = useAzureB2C();
    const {  inProgress } = useMsal();

    const [profile, setProfile] = useState<profileModel.IUserProfile>(profileHelper.getInitProfile());
    const [privacy, setPrivacy] = useState<profileModel.IUserPrivacy>(profileHelper.getInitPrivacy());
    const [communicationsHistory, setCommunicationsHistory] = useState<profileModel.ICommunicationHistory[]>([]);
    const [submitMessage, setSubmitMessage] = useState<string>("");
    const [submittingStatus, setSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [submitResult, setSubmitResult] = useState<boolean | null>(null);
    
    const isMobile = useCheckMobileScreen();

    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });

    useEffect(() => {
        if (azureB2C.isAuthAndLogin(inProgress)) {
            doGetProfile();
        }
    }, [inProgress]);

    const doGetProfile = async () => {
        
        //let account = getActiveAccount();

        let response = await profileService.getProfile(
            sessionFromStore?.YanaCustomerID || "",
            sessionFromStore?.YanaCustomerNo || "");
        if (response.status) {
            let profileFromBackend: profileModel.IUserProfile = {
                country: response.data.country,
                yanaProfileID: response.data.yanaProfileID,
                yanaCustomerID: response.data.yanaCustomerID,
                yanaCustomerNo: response.data.yanaCustomerNo,
                customerType: response.data.customerType,
                abn: response.data.abn,
                businessname: response.data.businessName,
                firstname: response.data.firstName,
                middlename: response.data.middleName,
                lastname: response.data.lastName,
                birthday: undefined,   //todo:
                usename: response.data.email,
                phone1: response.data.phoneNumber1,
                phone2: response.data.phoneNumber2,
            };
            setProfile(profileFromBackend);

            let privacyFromBackend: profileModel.IUserPrivacy = {
                GMCommunicationEmail: response.data.gmCommunicationEmail,
                GMCommunicationMobile: response.data.gmCommunicationMobile,
                GMPartnerCommunicationEmail: response.data.gmPartnerCommunicationEmail,
                GMPartnerCommunicationMobile: response.data.gmPartnerCommunicationMobile,
            };
            setPrivacy(privacyFromBackend);

            let history: profileModel.ICommunicationHistory[] = [];
            if (response.data.communicationHistory != null && response.data.communicationHistory.length > 0) {
                response.data.communicationHistory.map((item: any, index: number) => {
                    history.push({
                        Communications: item.communicationDescription,
                        Email: item.email,
                        Type: item.type,
                        SentDate: item.sent,
                    });
                });
            }
            setCommunicationsHistory(history);

        }
        else {
            if (response.errorCode == rdEnum.enumRDErrorCode.NotSignIn) {
                azureB2C.signIn();
            }
        }
    }


    const onSavePrivacy = async () => {

        setSubmitMessage("");
        setSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await profileService.saveProfile(profile, privacy, false);
        setSubmittingStatus(rdEnum.enumProcessingStatus.Processed);

        setSubmitResult(response.status);
        if (response.status) {
            // setSubmitMessage("Submit success.")
            setSubmitMessage("Your preferences have been successfully updated")
            setTimeout(() => {
                setSubmitMessage('')
            }, 5000)
        }
        else {
            setSubmitMessage(response.message);
        }

    }
    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }
    }, []);


    return (
        <MainLayoutPage sceneId='container-has-header'>

            <div className='global-options-container'>
                <GlobalMenu />

                <div className='privacy-container'>
                    <div className='privacy-header'>
                        <h1>DATA & PRIVACY</h1>
                        <span>
                            Please be aware that if you have subscribed to a particular email newsletter or other communication, you should follow the instructions in the email or on a related website to unsubscribe. If you opt out of receiving marketing communications from Cadillac, your personal information will not be used for marketing, but may still be used for the other purposes described in this Privacy Policy (such as vehicle order status and tasks, support and service).
                        </span>
                    </div>

                    <div className='input-switch-container'>
                        <Row>
                            <Col lg={6} className='input-swtich-panel'>
                                <div className='input-switch-box'>
                                    <div className='item-title-desc'>
                                        <h5>CADILLAC COMMUNICATIONS</h5>
                                        <p>Get updates from Cadillac about our latest offers, events and news</p>
                                    </div>
                                    <div className='input-swtich-box'>
                                        <div className='input-swtich-item'>
                                            <span>Email</span>
                                            <InputSwitch
                                                checked={privacy.GMCommunicationEmail}
                                                onChange={(e) => setPrivacy({ ...privacy, GMCommunicationEmail: e.value })}
                                            />
                                        </div>
                                        <div className='input-swtich-item'>
                                            <span>Mobile</span>
                                            <InputSwitch checked={privacy.GMCommunicationMobile}
                                                onChange={(e) => setPrivacy({ ...privacy, GMCommunicationMobile: e.value })} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={6} className='input-swtich-panel'>
                                <div className='input-switch-box'>
                                    <div className='item-title-desc'>
                                        <h5>CADILLAC COMMUNICATIONS</h5>
                                        <p>Get updates from Cadillac about our latest offers, events and news</p>
                                    </div>
                                    <div className='input-swtich-box'>
                                        <div className='input-swtich-item'>
                                            <span>Email</span>
                                            <InputSwitch checked={privacy.GMPartnerCommunicationEmail}
                                                onChange={(e) => setPrivacy({ ...privacy, GMPartnerCommunicationEmail: e.value })} />
                                        </div>
                                        <div className='input-swtich-item'>
                                            <span>Mobile</span>
                                            <InputSwitch checked={privacy.GMPartnerCommunicationMobile}
                                                onChange={(e) => setPrivacy({ ...privacy, GMPartnerCommunicationMobile: e.value })} />
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </div>

                    <div className='personl-data-container'>
                        <span>Your personal data will be treated in accordance with the <PrivacyPolicyLink props="privacy policy" />. 
                            {/* Visit this page to learn more about our practices, including how to request deletion of your personal information. */}
                        </span>
                        <div className='variant-btn-panel'>
                            <Button className='variant-btn'
                                disabled={submittingStatus == rdEnum.enumProcessingStatus.Processing}
                                style={{border: 'none', backgroundColor: submittingStatus == rdEnum.enumProcessingStatus.Processing ? '#CCCCCC' : '#282828', }}
                                onClick={() => {
                                    onSavePrivacy();
                                }}
                            >
                                {/* <span style={{color: submittingStatus == rdEnum.enumProcessingStatus.Processing ? '#707070' : '#fff'}}>Save</span> */}
                                {
                                    submittingStatus == rdEnum.enumProcessingStatus.Processing
                                    ?
                                    <span style={{color:'#707070'}}><Spinner spinnerWidth={26} /></span>
                                    :
                                    <span style={{color:'#fff'}}>SAVE</span>
                                }
                            </Button>
                        </div>
                        {submitMessage != "" && submittingStatus == rdEnum.enumProcessingStatus.Processed && (
                            <div className={classNames({
                                "text-error": !submitResult,
                                "text-success": submitResult
                            })}>{submitMessage}</div>
                        )}
                    </div>
                </div>

                {/*todo: styling */}
                {
                    !isMobile
                    ?
                    <div className='communcation-history-container'>
                        <div className='communcation-history-header'>
                            <h1>COMMUNICATIONS HISTORY</h1>
                        </div>
                        <div className="communcation-wrapper">
                        <table className="communcation-history-content">
                            <tr className="communcation-history-content-header">
                                <th>COMMUNICATIONS</th>
                                <th>EMAIL</th>
                                <th>TYPE</th>
                                <th>SENT TYPE</th>
                            </tr>
                            
                            {communicationsHistory.length > 0 && communicationsHistory.map((item: profileModel.ICommunicationHistory, index: number) => {
                                console.log(item);
                                return (
                                    <tr key={index}>
                                        <td>{item.Communications}</td>
                                        <td>{item.Email}</td>
                                        <td>{item.Type}</td>
                                        <td>{item.SentDate}</td>
                                    </tr>
                                );
                            })}
                            </table>
                        </div>
                    </div>
                    :
                    <></>
                }
               

            </div>

        </MainLayoutPage>
    )
}

export default Privacy