import React, { useEffect, useRef, useState } from 'react';
import {
    getCMSAssets
} from 'helpers/Uri';
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'model/IAppState';
import CarCarousel from 'components/CarCarousel';
import { useCheckMobileScreen } from "helpers/useCheckMobileScreen";
import { isNil } from 'lodash';
import { v1 } from 'uuid';

const MainLeftImageGrid = (props: any) => {
    const isMobile = useCheckMobileScreen();
    const [imgExpand, setImgExpand] = useState<Boolean>(false);

    const topPanel = document.querySelector('.top-panel') as HTMLElement;
    const bottomPanel = document.querySelector('.bottom-panel') as HTMLElement;

    const [expanded, setExpanded] = useState({
        topExpand: false,
        bottomExpand: false,
    });

    //todo: remove it later
    //Customise Exterior & Interior & Variant AWD of Cadillac
    const [customize, setCustomize] = useState({
        variantAWD: 'Luxury',
        exteriorColor: 'Argent Silver Metallic',
        interiorColor: 'Juniper Nappa',
        addsOn: [],
        charging: [],
        customerType: 'business',
        paymentType: 'Cash',
        postcode: '',
        invalidPostcode: false,
        deliveryType: 'Pick Up',
    });

    const customerType = useSelector((state: IAppState) => {
        return state.customerType;
    });

    const [activeImage, setActiveImage] = useState(1);
    // const [shouldRevert, setShouldRevert] = useState(false);

    const sectionName = useSelector((state: IAppState) => {
        return state.mainSection;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber;
    });

    const getVariantImages = (index: number) => {
        return vehicleFromStore?.SelectedVariant?.VariantPicture != null && vehicleFromStore?.SelectedVariant?.VariantPicture.length > index
            ? vehicleFromStore?.SelectedVariant?.VariantPicture[index] : "";
    }

    const getExteriorImages = (index: number) => {
        return vehicleFromStore?.SelectedExterior?.ExteriorPicture != null && vehicleFromStore?.SelectedExterior?.ExteriorPicture.length > index
            ? vehicleFromStore?.SelectedExterior?.ExteriorPicture[index] : "";
    }



    const getInteriorImages = (index: number) => {
        //interior does not have mobile vehicle image
        //if (isMobile) {
        //    return vehicleFromStore?.SelectedInterior?.InteriorPictureMobile != null && vehicleFromStore?.SelectedInterior?.InteriorPictureMobile.length > index
        //        ? vehicleFromStore?.SelectedInterior?.InteriorPictureMobile[index] : "";
        //}
        //else {
        return vehicleFromStore?.SelectedInterior?.InteriorPicture != null && vehicleFromStore?.SelectedInterior?.InteriorPicture.length > index
            ? vehicleFromStore?.SelectedInterior?.InteriorPicture[index] : "";
        //}
    }

    const [initialImage, setInitialImage] = useState({
        fullView: getExteriorImages(0) || '',
        frontView: getExteriorImages(1) || '',
        backView: getExteriorImages(2) || '',
        sideView: getExteriorImages(3) || '',
    });

    const [initialInteriorImage, setInitialInteriorImage] = useState({
        interirorFullView: getInteriorImages(0) || '',
        interirorFrontView: getInteriorImages(1) || '',
        interirorBackView: getInteriorImages(2) || '',
    });

    //Why set another set of carImage besides of initialImage, and why align 2 <img> within a image div?
    //Because one covers another and purpose to provide color transition 
    const [carImage, setCarImage] = useState({
        fullView: getExteriorImages(0) || '',
        frontView: getExteriorImages(1) || '',
        backView: getExteriorImages(2) || '',
        sideView: getExteriorImages(3) || '',
    });
    const [carInteriorImage, setCarInteriorImage] = useState({
        interirorFullView: getInteriorImages(0) || '',
        interirorFrontView: getInteriorImages(1) || '',
        interirorBackView: getInteriorImages(2) || '',
    });

    //***Core code***
    //Manipulates left image grid's transition effect could operate smoothly when Exterior or Interior color were changed
    useEffect(() => {

        if (vehicleFromStore?.SelectedExterior?.ExteriorColorName
            || (vehicleFromStore?.SelectedInterior?.VariantName)
        ) {
            setInitialImage({
                ...initialImage,
                fullView: initialImage.fullView,
                frontView: initialImage.frontView,
                backView: initialImage.backView,
                sideView: initialImage.sideView,
            });



            setCarImage({
                ...carImage,
                fullView: getExteriorImages(0),
                frontView: getExteriorImages(1),
                backView: getExteriorImages(2),
                sideView: getExteriorImages(3),
            });
            setActiveImage(2);


            setTimeout(() => {
                setInitialImage({
                    ...initialImage,
                    fullView: getExteriorImages(0),
                    frontView: getExteriorImages(1),
                    backView: getExteriorImages(2),
                    sideView: getExteriorImages(3),
                });
                setActiveImage(1);
            }, 400);
        }

    }, [vehicleFromStore?.SelectedExterior?.ExteriorColorName, vehicleFromStore?.SelectedInterior?.VariantName]);

    useEffect(() => {
        if (vehicleFromStore?.SelectedInterior?.InteriorColorName) {
            setInitialInteriorImage({
                interirorFullView: initialInteriorImage.interirorFullView,
                interirorFrontView: initialInteriorImage.interirorFrontView,
                interirorBackView: initialInteriorImage.interirorBackView,
            });


            setCarInteriorImage({
                ...carImage,
                interirorFullView: getInteriorImages(0),
                interirorFrontView: getInteriorImages(1),
                interirorBackView: getInteriorImages(2),
            });

            setActiveImage(2);

            setTimeout(() => {
                setInitialInteriorImage({
                    interirorFullView: getInteriorImages(0),
                    interirorFrontView: getInteriorImages(1),
                    interirorBackView: getInteriorImages(2),
                });

                setActiveImage(1);
            }, 550);
        }

    }, [vehicleFromStore?.SelectedInterior?.InteriorColorName]);

    const handleImgExpand = (selectedClassName: any) => {
        const imageDivs = document.querySelectorAll('.transition-group-full');

        imageDivs.forEach((element) => {
            if (selectedClassName == 'cadillac-full-0' || selectedClassName == 'cadillac-front-1' && topPanel && bottomPanel) {
                // topPanel.style.setProperty('--row-0-flex-basis', '100%');
                // bottomPanel.style.setProperty('--row-1-flex-basis', '0%');
                topPanel.classList.add('expand');
                topPanel.classList.remove('collapse');
                bottomPanel.classList.add('collapse');
                bottomPanel.classList.remove('expand');
                setExpanded({
                    topExpand: true,
                    bottomExpand: false,
                })
            } else if (selectedClassName == 'cadillac-back-2' || selectedClassName == 'cadillac-side-3' && topPanel && bottomPanel) {
                // topPanel.style.setProperty('--row-0-flex-basis', '0%');
                // bottomPanel.style.setProperty('--row-1-flex-basis', '100%');
                topPanel.classList.add('collapse');
                topPanel.classList.remove('expand');
                bottomPanel.classList.add('expand');
                bottomPanel.classList.remove('collapse');
                setExpanded({
                    topExpand: false,
                    bottomExpand: true,
                })
            }

            let tileIndex = parseInt(selectedClassName.split('-').pop());

            for (let i = 0; i <= 3; i++) {
                const value = i === tileIndex ? '100%' : '0%';
                document.documentElement.style.setProperty(`--tile-${i}-flex-basis`, value);
            }
        });

        setImgExpand(true);
    }

    const handleClose = () => {

        setExpanded({
            topExpand: false,
            bottomExpand: false,
        })
        setImgExpand(false);

        if (sectionName.mainSection == 'variant') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '75%');
            document.documentElement.style.setProperty(`--tile-1-flex-basis`, '25%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '60%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '40%');
        } else if (sectionName.mainSection == 'exterior') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '60%');
            document.documentElement.style.setProperty(`--tile-1-flex-basis`, '40%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '50%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '50%');
        } else if (sectionName.mainSection == 'interior') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '100%');
            document.documentElement.style.setProperty(`--tile-1-flex-basis`, '0%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '60%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '40%');
        }
    }

    useEffect(() => {

        window.scrollTo(0, 0);

        return () => { }
    }, [])

    useEffect(() => {
        handleClose();
    }, [sectionName.mainSection])

    useEffect(() => {
        setCustomize({
            ...customize,
            customerType: customerType
        })
    }, [customerType]);



    return (
        <div className='left-slider-container' style={{ position: 'sticky' }}
        // style={{ position: mainPageNumber == 0 ? 'sticky' : 'fixed' }}
        >
            {
                imgExpand == true && sectionName.mainSection != 'interior'
                    ?
                    <div className='close-panel'>
                        <img onClick={() => handleClose()} className="img stat-icon-link"
                            data-dtm="gallery"
                            src={`${getCMSAssets()}/main/cross.svg`} alt="expand" />
                    </div>
                    :
                    imgExpand == true && sectionName.mainSection == 'interior'
                        ?
                        <div className='close-panel'>
                            <img onClick={() => handleClose()} className="img stat-icon-link"
                                data-dtm="gallery"
                                src={`${getCMSAssets()}/main/cross-white.svg`} alt="expand" />
                        </div>
                        :
                        <></>
            }

            {
                (sectionName.mainSection != 'accessories' && sectionName.mainSection != 'charging' && sectionName.mainSection != 'payment' && sectionName.mainSection != 'checkout' && sectionName.mainSection != 'delivery' && sectionName.mainSection != 'checkoutForm') && (
                    <>
                        <div className={`top-panel ${expanded.topExpand && !expanded.bottomExpand ? 'expand' : !expanded.topExpand && expanded.bottomExpand ? 'collapse' : ''}`}>
                            <div className='cadillac-full-0'>

                                <div className='transition-group-full'>
                                    <div className={`${sectionName.mainSection != 'interior' ? 'img-panel' : imgExpand ? 'interior-enlarged' : 'interior'} ${activeImage === 2 ? 'active' : ''}`}>
                                        {
                                            sectionName.mainSection != 'interior'
                                                ?

                                                <>
                                                    {initialImage.fullView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-full-0')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={initialImage.fullView}
                                                            alt="Vehicle Full View"
                                                        />
                                                    )}
                                                    {carImage.fullView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-full-0')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={carImage.fullView || getExteriorImages(0)}
                                                            alt="Vehicle Full View"
                                                        />
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {initialInteriorImage.interirorFrontView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-full-0')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={initialInteriorImage.interirorFrontView}
                                                            alt="Vehicle Front View"
                                                        />
                                                    )}

                                                    {carInteriorImage.interirorFrontView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-full-0')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={carInteriorImage.interirorFrontView || getExteriorImages(1)}
                                                            alt="Vehicle Front View"
                                                        />
                                                    )}
                                                </>
                                        }
                                    </div>
                                    {
                                        !imgExpand && sectionName.mainSection != 'interior'
                                            ?
                                            <div className='close-panel'>
                                                <img onClick={() => handleImgExpand('cadillac-full-0')}
                                                    className="img stat-icon-link"
                                                    data-dtm="gallery"
                                                    src={`${getCMSAssets()}/main/expand.svg`}
                                                    alt="expand"
                                                />
                                            </div>
                                            :
                                            !imgExpand && sectionName.mainSection == 'interior'
                                                ?
                                                <div className='close-panel'>
                                                    <img onClick={() => handleImgExpand('cadillac-full-0')}
                                                        data-dtm="gallery"
                                                        className="img stat-icon-link"
                                                        src={`${getCMSAssets()}/main/expand-white.svg`}
                                                        alt="expand"
                                                    />
                                                </div>
                                                :
                                                <></>

                                    }
                                </div>
                            </div>
                            {
                                sectionName.mainSection == 'global'
                                    ?
                                    <div className='cadillac-front-1'>
                                        <div className='transition-group-variant'>
                                            <div className='counter-container'>
                                            <div className='counter-item'>

                                                <div className='counter-title'>Range<sup>1</sup></div>
                                                    <div className='counter-box'>
                                                        <div className="counter">{vehicleFromStore?.SelectedVariant?.Range}</div>

                                                    </div>
                                                </div>
                                                <div className='counter-item'>
                                                    {/* <div className='counter-title'>15min charge at 190kW⁶</div> */}
                                                    <div className='counter-title'>Charge to 80%<sup>2</sup></div>

                                                    <div className='counter-box'>
                                                        <div className="counter">{vehicleFromStore?.SelectedVariant?.Power}</div>

                                                    </div>
                                                </div>
                                                <div className='counter-item'>
                                                    <div className='counter-title'>0-100 km/h<sup>3</sup></div>
                                                        <div className='counter-box'>
                                                            <div className="counter">{vehicleFromStore?.SelectedVariant?.KmHour}</div>

                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    sectionName.mainSection == 'variant'
                                        ?
                                        <div className='cadillac-front-1'>
                                            <div className='transition-group-variant'>
                                                <div className='counter-container'>
                                                    <div className='counter-item'>

                                                        <div className='counter-title'>Range<sup>1</sup></div>
                                                        <div className='counter-box'>
                                                            <div className="counter">{vehicleFromStore?.SelectedVariant?.Range}</div>

                                                        </div>
                                                    </div>
                                                    <div className='counter-item'>
                                                        {/* <div className='counter-title'>15min charge at 190kW⁶</div> */}
                                                        <div className='counter-title'>Charge to 80%<sup>2</sup></div>

                                                        <div className='counter-box'>
                                                            <div className="counter">{vehicleFromStore?.SelectedVariant?.Power}</div>

                                                        </div>
                                                    </div>
                                                    <div className='counter-item'>
                                                        <div className='counter-title'>0-100 km/h<sup>3</sup></div>
                                                        <div className='counter-box'>
                                                            <div className="counter">{vehicleFromStore?.SelectedVariant?.KmHour}</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        sectionName.mainSection == 'exterior'
                                            ?
                                            <div className='cadillac-front-1' >
                                                <div className='transition-group-full' >


                                                    <div
                                                        className={`img-panel ${imgExpand ? 'expanded' : ''} ${activeImage === 2 ? 'active' : ''}`}
                                                        onClick={() => (imgExpand ? handleClose() : handleImgExpand('cadillac-front-1'))}

                                                    >
                                                        {initialImage.frontView && (
                                                            <img
                                                                className='image stat-image-link'
                                                                data-dtm="gallery"
                                                                src={initialImage.frontView}
                                                                alt="Vehicle Front View"
                                                            />
                                                        )}
                                                        {carImage.frontView && (
                                                            <img
                                                                className='image stat-image-link'
                                                                data-dtm="gallery"
                                                                src={carImage.frontView}
                                                                alt="Vehicle Front View"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {
                                                    !imgExpand && sectionName.mainSection != 'interior'
                                                        ?
                                                        <div className='close-panel'>
                                                            <img onClick={() => handleImgExpand('cadillac-front-1')}
                                                                data-dtm="gallery"
                                                                className="img stat-icon-link"
                                                                src={`${getCMSAssets()}/main/expand.svg`}
                                                                alt="Expand"
                                                            />
                                                        </div>
                                                        :
                                                        !imgExpand && sectionName.mainSection == 'interior'
                                                            ?
                                                            <div className='close-panel'>
                                                                <img onClick={() => handleImgExpand('cadillac-full-0')}
                                                                    data-dtm="gallery"
                                                                    className="img stat-icon-link"
                                                                    src={`${getCMSAssets()}/main/expand-white.svg`}
                                                                    alt="Expand"
                                                                />
                                                            </div>
                                                            :
                                                            <></>

                                                }
                                            </div>
                                            :
                                            sectionName.mainSection == 'interior'
                                                ?
                                                <div className='cadillac-front-1' style={{ margin: '0' }}>
                                                    <div className='transition-group-full' >
                                                        <div
                                                            className={`img-panel ${imgExpand ? 'expanded' : ''} ${activeImage === 2 ? 'active' : ''}`}
                                                        >
                                                            {initialImage.frontView && (
                                                                <img
                                                                    className='image stat-image-link'
                                                                    data-dtm="gallery"
                                                                    src={initialImage.frontView}
                                                                    alt="Vehicle Front View"
                                                                />
                                                            )}
                                                            {carImage.frontView && (
                                                                <img
                                                                    className='image stat-image-link'
                                                                    data-dtm="gallery"
                                                                    src={carImage.frontView}
                                                                    alt="Vehicle Front View"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                            }
                        </div>

                        <div className={`bottom-panel ${!expanded.topExpand && expanded.bottomExpand ? 'expand' : expanded.topExpand && !expanded.bottomExpand ? 'collapse' : ''}`}>
                            <div className='cadillac-back-2'>
                                <div className='transition-group-full'>
                                    <div className={`${sectionName.mainSection != 'interior' ? 'img-panel' : imgExpand ? 'interior-enlarged' : 'interior'} ${activeImage === 2 ? 'active' : ''}`}>
                                        {
                                            sectionName.mainSection != 'interior'
                                                ?

                                                <>
                                                    {initialImage.backView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-back-2')}
                                                            data-dtm="gallery"
                                                            className="image stat-image-link"
                                                            src={initialImage.backView}
                                                            alt="Vehicle Back View"
                                                        />
                                                    )}
                                                    {carImage.backView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-back-2')}
                                                            data-dtm="gallery"
                                                            className="image stat-image-link"
                                                            src={carImage.backView}
                                                            alt="Vehicle Back View"
                                                        />
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {initialInteriorImage.interirorBackView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-back-2')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={initialInteriorImage.interirorBackView}
                                                            alt="Vehicle Back View"
                                                        />
                                                    )}
                                                    {carInteriorImage.interirorBackView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-back-2')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={carInteriorImage.interirorBackView}
                                                            alt="Vehicle Back View"
                                                        />
                                                    )}
                                                </>
                                        }
                                    </div>
                                    {
                                        !imgExpand && sectionName.mainSection != 'interior'
                                            ?
                                            <div className='close-panel'>
                                                <img onClick={() => handleImgExpand('cadillac-back-2')} className="img stat-icon-link"
                                                    alt="Expand" data-dtm="gallery"
                                                    src={`${getCMSAssets()}/main/expand.svg`} />
                                            </div>
                                            :
                                            !imgExpand && sectionName.mainSection == 'interior'
                                                ?
                                                <div className='close-panel'>
                                                    <img onClick={() => handleImgExpand('cadillac-full-0')} className="img stat-icon-link"
                                                        alt="Expand" data-dtm="gallery"
                                                        src={`${getCMSAssets()}/main/expand-white.svg`} />
                                                </div>
                                                :
                                                <></>

                                    }
                                </div>
                            </div>
                            <div className='cadillac-side-3'>
                                <div className='transition-group-full'>
                                    <div className={`${sectionName.mainSection != 'interior' ? 'img-panel' : imgExpand ? 'interior-enlarged' : 'interior'} ${activeImage === 2 ? 'active' : ''}`}>
                                        {
                                            sectionName.mainSection != 'interior'
                                                ?
                                                <>
                                                    {initialImage.sideView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-side-3')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={initialImage.sideView}
                                                            alt="Vehicle Side View"
                                                        />
                                                    )}
                                                    {carImage.sideView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-side-3')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={carImage.sideView}
                                                            alt="Vehicle Side View"
                                                        />
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {initialInteriorImage.interirorFullView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-side-3')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={initialInteriorImage.interirorFullView}
                                                            alt="Vehicle Full View"
                                                        />
                                                    )}
                                                    {carInteriorImage.interirorFullView && (
                                                        <img
                                                            onClick={() => imgExpand ? handleClose() : handleImgExpand('cadillac-side-3')}
                                                            className="image stat-image-link"
                                                            data-dtm="gallery"
                                                            src={carInteriorImage.interirorFullView}
                                                            alt="Vehicle Full View"
                                                        />
                                                    )}
                                                </>
                                        }
                                    </div>
                                    {
                                        !imgExpand && sectionName.mainSection != 'interior'
                                            ?
                                            <div className='close-panel'>
                                                <img onClick={() => handleImgExpand('cadillac-side-3')} className="img stat-icon-link"
                                                    data-dtm="gallery"
                                                    alt="Expand"
                                                    src={`${getCMSAssets()}/main/expand.svg`} />
                                            </div>
                                            :
                                            !imgExpand && sectionName.mainSection == 'interior'
                                                ?
                                                <div className='close-panel'>
                                                    <img onClick={() => handleImgExpand('cadillac-full-0')} className="img stat-icon-link"
                                                        data-dtm="gallery"
                                                        alt="Expand"
                                                        src={`${getCMSAssets()}/main/expand-white.svg`} />
                                                </div>
                                                :
                                                <></>

                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {
                (sectionName.mainSection == 'accessories' || sectionName.mainSection == 'charging' || sectionName.mainSection == 'payment' || sectionName.mainSection == 'delivery' || sectionName.mainSection == 'checkout' || sectionName.mainSection == 'checkoutForm') && (
                    <CarCarousel variantAWD={customize.variantAWD} exteriorColor={customize.exteriorColor} interiorColor={customize.interiorColor} carouselInterval={3000} />
                )
            }
        </div>
    )
}

export default MainLeftImageGrid