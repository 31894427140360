import * as profileModel from 'model/IUserProfile';

export const getInitProfile = (): profileModel.IUserProfile => {
	let init: profileModel.IUserProfile = {
		country: "",
		yanaProfileID: "",
		yanaCustomerID: "",
		yanaCustomerNo: "",
		customerType: "",
		abn: "",
		businessname: "",
		firstname: "",
		middlename: "",
		lastname: "",
		birthday: undefined,
		usename: "",
		phone1: "",
		phone2: "",
    };
    return init;
}

export const getInitInvalidProfile = (): profileModel.IInvalidUserProfile => {
	let init: profileModel.IInvalidUserProfile = {
		invalidABN: false,
		invalidBusinessName: false,
		invalidFirstname: false,
		invalidLastname: false,
		invalidBirthday: false,
		invalidUsername: false,
		invalidPhone: false,
	};
	return init;
}

export const getInitPrivacy = (): profileModel.IUserPrivacy => {
	let init: profileModel.IUserPrivacy = {
		GMCommunicationEmail: false,
		GMCommunicationMobile: false,
		GMPartnerCommunicationEmail: false,
		GMPartnerCommunicationMobile: false,
	};
	return init;
}