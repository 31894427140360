import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux';

import * as rdEnum from 'helpers/enum';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as vehicleModel from 'model/IVehicle';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import { Checkbox } from 'primereact/checkbox';
import ChargingDialog from 'components/ChargingDialog';
import { IAppState } from 'model/IAppState';

import { isNil, template } from 'lodash';
import { classNames } from 'primereact/utils';
import { currencyFormat } from 'helpers/Uri';

const Charging = () => {
    const dispatch = useDispatch();

    //the charge items be shown on the page
    const [chargeList, setChargeList] = useState<vehicleModel.IVehicleOptions[]>();

    //All charge items got from APIs
    const chargingListFromStore: vehicleModel.IVehicleOptions[] = useSelector((state: IAppState) => {
        return state.vehicle.Charging;
    });

    //uses selected options
    const selectedOptionsFromStore: vehicleModel.IVehicleOptions[] | null = useSelector((state: IAppState) => {
        return state.selectedVehicle.Options;
    });

    //const [selected, setSelected] = useState<vehicleModel.IVehicleOptions[]>([]);

    useEffect(() => {
        //business rules:
        //if public selected, home must have price
        //if home no price selected, public is showing and disabled
        //if home price selected, public is showing and enabled
        if (!isNil(chargingListFromStore) && chargingListFromStore.length > 0) {
            if (!isNil(selectedOptionsFromStore) && selectedOptionsFromStore.length > 0) {
                let selectedPublicCharge = selectedOptionsFromStore.find(m =>
                    m.OptionType == rdEnum.enumVehicleOptionType.Charge && m.ChargeType == rdEnum.enumChargeType.Public);
                let selectedHomeWithoutPriceCharge = selectedOptionsFromStore.find(m =>
                    m.OptionType == rdEnum.enumVehicleOptionType.Charge && m.ChargeType == rdEnum.enumChargeType.HomeWithoutPrice);
                let selectedHomeHasPriceCharge = selectedOptionsFromStore.find(m =>
                    m.OptionType == rdEnum.enumVehicleOptionType.Charge && m.ChargeType == rdEnum.enumChargeType.HomeHasPrice);

                if (selectedPublicCharge == undefined && selectedHomeHasPriceCharge != undefined) {
                    let tempList: vehicleModel.IVehicleOptions[] = [];
                    chargingListFromStore.map((item: vehicleModel.IVehicleOptions) => {
                        if (item.ChargeType == rdEnum.enumChargeType.Public || item.ChargeType == rdEnum.enumChargeType.HomeWithoutPrice) {
                            tempList.push({ ...item, Disable: false });
                        }
                    });
                    setChargeList(tempList);
                }
                else if (selectedPublicCharge == undefined && selectedHomeHasPriceCharge == undefined && selectedHomeWithoutPriceCharge == undefined) {
                    //nothings selected
                    let tempList: vehicleModel.IVehicleOptions[] = [];
                    chargingListFromStore.map((item: vehicleModel.IVehicleOptions) => {
                        if (item.ChargeType == rdEnum.enumChargeType.Public || item.ChargeType == rdEnum.enumChargeType.HomeWithoutPrice) {
                            tempList.push({ ...item, Disable: false });
                        }
                    });
                    setChargeList(tempList);
                }
                else if (selectedPublicCharge != undefined) {
                    let tempList: vehicleModel.IVehicleOptions[] = [];
                    chargingListFromStore.map((item: vehicleModel.IVehicleOptions) => {
                        if (item.ChargeType == rdEnum.enumChargeType.Public || item.ChargeType == rdEnum.enumChargeType.HomeHasPrice) {
                            tempList.push({ ...item, Disable: false });
                        }
                        //else if (item.ChargeType == rdEnum.enumChargeType.HomeHasPrice) {
                        //    tempList.push(item);
                        //}
                    });
                    setChargeList(tempList);
                }
                else if (selectedHomeWithoutPriceCharge != undefined) {
                    let tempList: vehicleModel.IVehicleOptions[] = [];
                    chargingListFromStore.map((item: vehicleModel.IVehicleOptions) => {
                        if (item.ChargeType == rdEnum.enumChargeType.Public) {
                            tempList.push({ ...item, Disable: true });
                        } else if (item.ChargeType == rdEnum.enumChargeType.HomeWithoutPrice) {
                            tempList.push(item);
                        }
                    });
                    setChargeList(tempList);
                }

            }
            else {
                let tempList: vehicleModel.IVehicleOptions[] = [];
                chargingListFromStore.map((item: vehicleModel.IVehicleOptions) => {
                    if (item.ChargeType == rdEnum.enumChargeType.Public) {
                        tempList.push({ ...item, Disable: false });
                    } else if (item.ChargeType == rdEnum.enumChargeType.HomeWithoutPrice) {
                        tempList.push(item);
                    }
                });
                setChargeList(tempList);
            }
        }
        else {
            setChargeList([]);
        }

    }, [chargingListFromStore, selectedOptionsFromStore]);


    const handleSelect = (item: vehicleModel.IVehicleOptions) => {
        dispatch(selectedConfigStore.updateOptionAction(item));
    };

    const isSelectedOption = (item: vehicleModel.IVehicleOptions): boolean => {
        if (isNil(selectedOptionsFromStore) || selectedOptionsFromStore.length == 0) return false;
        let findSelectedOption = selectedOptionsFromStore.find((selectedItems: vehicleModel.IVehicleOptions) => {
            return selectedItems?.YanaOptionID == item.YanaOptionID || selectedItems?.OptionName == item.OptionName;
        });
        return !isNil(findSelectedOption);
    }

    setTimeout(() => {
        let chargingCheckbox = document.getElementsByName("charging");
        for (let i = 0; i < chargingCheckbox.length; i++) {
            chargingCheckbox[i].setAttribute('data-dtm', 'charging');
            chargingCheckbox[i].classList.add('stat-checkbox');
        }

    }, 200);

    return (
        <div className='additional-container'>
            <h3 className='main-page-section-title'>CHARGING</h3>
            <p className='additional-desc'>LYRIQ comes with a standard charging cable and 1 year complimentary public charging. You can choose between an additional 2 years complimentary public charging, or a home charger installed.</p>

            {
                !isNil(chargeList) && chargeList.length > 0 && chargeList.map((item: vehicleModel.IVehicleOptions, index: any) => {
                    const header = <img alt="Card" src={item.OptionPicture.length > 0 ? item.OptionPicture[0] : ""} />;
                    let optionItem: vehicleModel.IVehicleOptions = {
                        YanaOptionID: item.YanaOptionID,
                        OptionType: rdEnum.enumVehicleOptionType.Charge,
                        OptionName: item.OptionName,
                        OptionPrice: item.OptionPrice,   //todo: from api, must be number type
                        OptionPicture: [],
                        OptionPictureMobile: [],
                        OptionDesc: item.OptionDesc,
                        ChargeType: item.ChargeType,
                        Disable: item.Disable
                    };
                    return (
                        <Card id={item + index} key={item + index}
                            style={{
                                padding: '.3rem',
                                border: isSelectedOption(item) ? '2px solid #282828' : '1px solid #d2d2d2'
                            }}
                        >
                            <div className={classNames({
                                "card-inner": true,
                                "charging": true,
                                "disabled-wrapper": item.Disable,
                            })}>
                                <label className='card-header-img' htmlFor={`charging + ${item + index}`}>
                                    <img alt="Card" src={item.OptionPicture.length > 0 ? item.OptionPicture[0] : ""} />
                                </label>
                                <div className='checkbox-panel'>
                                    <Checkbox
                                        name="charging"
                                        inputId={`charging + ${item + index}`}
                                        disabled={item.Disable}
                                        onChange={() => {
                                            handleSelect(optionItem);
                                        }}
                                        checked={isSelectedOption(item)}
                                    ></Checkbox>
                                    <label className='card-text-panel' htmlFor={`charging + ${item + index}`}>
                                        <p>{item.OptionName}</p>
                                        <div className='card-info-selection'>

                                            <span>{currencyFormat(item.OptionPrice || 0)}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='card-details'>
                                <ChargingDialog  {...item} />
                            </div>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default Charging