import { MainLayoutPage } from 'LayoutPages'
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import ReSchedulePanel from './ReSchedulePanel'
import IsNumberVerification from 'components/IsNumberVerification'
import { fontFamily, fontSize } from 'Styles'
import moment from 'moment';
import * as rdEnum from 'helpers/enum';
import * as testDriveModel from 'model/ITestDrive';
import { ICodeName } from 'model/ICommon';
import * as testDriveService from 'services/TestDrive';
import * as testDriveHelper from 'businessHelpers/TestDriveHelper';
import { Page } from '../../../Page'

import { IAppState } from 'model/IAppState';
import { ISubmitMessage } from 'model/ICommon';
import { useCountryState } from 'helpers/useCountryState';
import { useFieldArray } from 'react-hook-form'
import PrivacyPolicyLink from 'components/PrivacyPolicyLink'
import { Dialog } from 'primereact/dialog'
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen'
import PrivacyCollectionDialog from 'pages/Main/checkout/PrivacyCollectionDialog'

const TestDrive = () => {
    const countryState = useCountryState();

    const [locations, setLocations] = useState<testDriveModel.ITestDriveLocation[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<testDriveModel.ITestDriveLocation>(testDriveHelper.getInitLocation());

    const [models, setModels] = useState<testDriveModel.ITestDriveModel[]>([]);
    const [selectedModel, setSelectedModel] = useState<testDriveModel.ITestDriveModel>(testDriveHelper.getInitModel());

    const [variants, setVariants] = useState<testDriveModel.TestDriveVariant[]>([]);
    const [selectedVariants, setSelectedVariants] = useState<testDriveModel.TestDriveVariant>(testDriveHelper.getInitVariant());

    const [countries, setCountries] = useState<ICodeName[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<ICodeName>({ code: "", name: "" });



    const isFirstRender = useRef(true);
    const isFirstRenderCountry = useRef(true);

    //const [inAvailableDates, setInavailableDates] = useState<Date[]>([]);
    //const [availableMinDate, setAvailableMinDate] = useState<Date>();
    //const [availableMaxDate, setAvailableMaxDate] = useState<Date>();
    //const [disableDateCalendar, setDisableDateCalendar] = useState<boolean>(true);

    //const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const [pageState, setPageState] = useState<testDriveModel.ITestDrive>(testDriveHelper.getInitTestDrive());
    const [invalidPageState, setInvalidPageState] = useState<testDriveModel.IInvalidTestDrive>(testDriveHelper.getInitInvalidTestDrive());
    const [submitMessage, setSubmitMessage] = useState<ISubmitMessage>();

    const [isOpen, setIsOpen] = useState(false);
    const [submittingStatus, setSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Init);

    const handleDialogOpen = () => {
         setIsOpen(true);
     }
 
     const handleDialogHide = () => {
         setIsOpen(false);
     }

     const isMobile = useCheckMobileScreen();

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });


    useEffect(() => {
        if (isFirstRenderCountry.current) {
            doGetCountry();
            isFirstRenderCountry.current = false;
        }
    }, []);

    useEffect(() => {
        if (!isNil(selectedDeliveryFromStore?.Country)) {
            setSelectedCountry(selectedDeliveryFromStore?.Country);
        }
    }, [selectedDeliveryFromStore?.Country?.code]);

    useEffect(() => {
        if (!isNil(selectedCountry?.code) && selectedCountry?.code != "") {
            doGetModels();
        }
    }, [selectedCountry?.code]);



    useEffect(() => {
        if (!isNil(selectedModel?.ModelID) && selectedModel?.ModelID != "") {
            doGetVariants(selectedModel || null);
        }
        else {
            setVariants([]);
            setSelectedVariants(testDriveHelper.getInitVariant());
        }
    }, [selectedModel?.ModelID]);

    useEffect(() => {
        if (
            !isNil(selectedCountry?.code) && selectedCountry?.code != ""
            && !isNil(selectedVariants?.VariantID) && selectedVariants?.VariantID != "") {
            doGetLocations();
        }
    }, [selectedCountry?.code, selectedVariants?.VariantID]);


    const doGetLocations = async () => {
        let response = await testDriveService.getTestDriveLocations(
            selectedCountry?.code || "",
            selectedModel,
            selectedVariants
        );
        if (response.status) {
            let responseData = response.data;
            let tempLocations: testDriveModel.ITestDriveLocation[] = [];
            if (!isNil(responseData) && responseData.length > 0) {
                responseData.map((item: any) => {
                    tempLocations.push({
                        Country: selectedDeliveryFromStore?.Country?.code || "",
                        Name: item.name,
                        Address: item.address
                    });
                });

            }
            //console.log(tempLocations);
            setLocations(tempLocations);
            setSelectedLocations(testDriveHelper.getInitLocation());
        }
        else {
            setLocations([]);
            setSelectedLocations(testDriveHelper.getInitLocation());
        }
    }

    const doGetModels = async () => {
        let response = await testDriveService.getTestDriveModels(selectedCountry?.code || "");
        if (response.status) {
            let responseData = response.data;
            let tempModels: testDriveModel.ITestDriveModel[] = [];
            if (!isNil(responseData) && responseData.length > 0) {
                responseData.map((item: any) => {
                    tempModels.push({
                        ModelID: item.modelID,
                        Model: item.model
                    });
                });
            }
            setModels(tempModels);

            if (tempModels.length > 0) {
                setSelectedModel({
                    ModelID: tempModels[0].ModelID,
                    Model: tempModels[0].Model
                });
            }
            else {
                setSelectedModel(testDriveHelper.getInitModel());
            }

        }
        else {
            setModels([]);
            setSelectedModel(testDriveHelper.getInitModel());
        }
    }


    const doGetCountry = async () => {
        let country = await countryState.getCountry();
        setCountries(country);
    }

    const doGetVariants = async (model: testDriveModel.ITestDriveModel | null) => {
        if (model == null) return;
        let response = await testDriveService.getTestDriveVariants(selectedCountry?.code || "", model);
        if (response.status) {
            let responseData = response.data;
            let tempVariant: testDriveModel.TestDriveVariant[] = [];
            if (!isNil(responseData) && responseData.length > 0) {
                responseData.map((item: any) => {
                    tempVariant.push({
                        ModelID: item.modelID,
                        VariantID: item.variantID,
                        Variant: item.variantName,
                    });
                });

            }
            setVariants(tempVariant);
            setSelectedVariants(testDriveHelper.getInitVariant());
        }
        else {
            setVariants([]);
            setSelectedVariants(testDriveHelper.getInitVariant());
        }

    }


    const onCountryChange = (e: any) => {
        setSelectedCountry(e.value);
        setInvalidPageState({
            ...invalidPageState,
            invalidCountry: isNil(e.value)
        });
    }

    const onLocationChange = (e: any) => {
        setSelectedLocations(e.value);

        setInvalidPageState({
            ...invalidPageState,
            invalidLocation: isNil(e.value)
        });
    }


    const onVariantChange = (e: any) => {
        setSelectedVariants(e.value);
        setInvalidPageState({
            ...invalidPageState,
            invalidVariant: isNil(e.value)
        });
    }


    const isNumberKey = (e: any) => {
        IsNumberVerification(e);
    }


    //const dateTemplate = (date: any) => {
    //    let dtDate = moment(date).format("DD/MM/YYYY");
    //    if (availableDates.includes(dtDate)) {
    //        return date.day;
    //    }
    //    return (
    //            <strong style={{ color: "red" }}>{date.day}</strong>
    //    );

    //}

    const validation = () => {
        if (isNil(pageState)) return;
        let invalid = false;

        let invalidFirstName = false;
        if (isNil(pageState.firstname) || pageState.firstname == "") {
            invalidFirstName = true;
            invalid = true;
        }

        let invalidLastName = false;
        if (isNil(pageState.lastname) || pageState.lastname == "") {
            invalidLastName = true;
            invalid = true;
        }

        let invalidEmail = false;
        if (isNil(pageState.email) || pageState.email == "") {
            invalidEmail = true;
            invalid = true;
        }
        else {

        }

        let invalidMobile = false;
        if (isNil(pageState.mobile) || pageState.mobile == "") {
            invalidMobile = true;
            invalid = true;
        }

        let invalidCountry = false;
        if (isNil(selectedCountry?.code) || selectedCountry?.code == "") {
            invalidCountry = true;
            invalid = true;
        }

        let invalidLocation = false;
        if (isNil(selectedLocations?.Name) || selectedLocations?.Name == "") {
            invalidLocation = true;
            invalid = true;
        }

        let invalidModel = false;
        if (isNil(selectedModel?.ModelID) || selectedModel?.ModelID == "") {
            invalidModel = true;
            invalid = true;
        }


        let invalidVariant = false;
        if (isNil(selectedVariants?.VariantID) || selectedVariants?.VariantID == "") {
            invalidVariant = true;
            invalid = true;
        }

        //let invalidDate = false;
        //if (isNil(pageState?.date)) {
        //    invalidDate = true;
        //    invalid = true;
        //}


        //let invalidTime = false;
        //if (isNil(pageState?.selectedTime) || pageState?.selectedTime == "") {
        //    invalidTime = true;
        //    invalid = true;
        //}

        let invalidComfirm = false;
        if (!pageState?.confirm) {
            invalidComfirm = true;
            invalid = true;
        }

        setInvalidPageState({
            ...invalidPageState,
            invalidFirstname: invalidFirstName,
            invalidLastname: invalidLastName,
            invalidEmail: invalidEmail,
            invalidMobile: invalidMobile,
            invalidCountry: invalidCountry,
            invalidLocation: invalidLocation,
            invalidModel: invalidModel,
            invalidVariant: invalidVariant,
            //invalidDate: invalidDate,
            //invalidTime: invalidTime,
            invalidConfirm: invalidComfirm
        });

        return invalid;
    }

    const onBookTestDrive = async () => {
        if (isNil(pageState)) return;
        setSubmitMessage({ Status: null, Message: null });
        let requestData: testDriveModel.ITestDrive = {
            ...pageState,
            transaction: rdEnum.enumTestDriveTransaction.Book,
            yanaCustomerID: sessionFromStore?.YanaCustomerID || "",
            yanaCustomerNo: sessionFromStore?.YanaCustomerNo || "",
            country: selectedCountry,
            location: selectedLocations,
            model: selectedModel,
            variant: selectedVariants
        };
        if (validation()) return;


        setSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await testDriveService.saveTestDrive(requestData);
        setSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            setSubmitMessage({ Status: response.status, Message: "Submit successfully." });

            let responseData = response.data;
            requestData = {
                ...requestData,
                yanaTestDriveID: responseData.yanaTestDriveID,
                yanaTestDriveNo: responseData.yanaTestDriveNo
            }
            setPageState(requestData);
            handleDialogOpen();
        }
        else {
            setSubmitMessage({ Status: response.status, Message: response.message });
        }

    }

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: { pageName: "" },
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }
    }, []);

    return (
        <MainLayoutPage
            sceneId='container-has-header'
        >
            <div className='global-options-container'>

                <GlobalMenu activeBtn='test' />
                {/*<ReSchedulePanel />*/}

                <div className='test-drive-container'>
                    <div className='test-drive-title-box'>
                        <div className='test-drive-title'>
                            {/* <h1>REQUEST A</h1> */}
                            <h1>REQUEST TEST DRIVE</h1>
                        </div>
                        <div className='test-drive-subtitle'>
                            <p>Please complete the information below</p>
                            <p>
                                and we will notify you when LYRIQ is available for test drive.
                            </p>
                            <br/>
                            <p style={{fontWeight: 'bold'}}>
                                Early 2025
                            </p>
                        </div>
                    </div>

                    <div className='test-drive-img'>
                        <img className="image" src={`${getCMSAssets()}/testDrive/test-drive-main-theme.png`} />
                    </div>

                    <div className='test-dirve-selection-container'>
                        <Row className='select-country-container'>
                            <Col lg={4} className='left-panel'>
                                <h6>TEST DRIVE COUNTRY</h6>
                            </Col>
                            <Col lg={8} className='dropdown-panel choose-country'>
                                <Dropdown
                                    value={selectedCountry}
                                    options={countries}
                                    onChange={(e) => onCountryChange(e)}
                                    className='global-location-dropdown'
                                    optionLabel="name"
                                    placeholder=""
                                />
                                <label className={selectedCountry?.code ? 'location-option-label selected' : 'location-option-label'}>Select Country</label>
                                {invalidPageState?.invalidCountry && (
                                    <div id="location-help" className="p-error block text-left">Require Country</div>
                                )}
                            </Col>
                        </Row>
                        
                        <Row className='model-trim-container'>
                            <Col lg={4} className='left-panel'>
                                <h6>MODEL & TRIM</h6>
                            </Col>
                            {/* <Col lg={4} className='dropdown-panel choose-country'>
                                <Dropdown
                                    value={selectedCountry}
                                    options={countries}
                                    onChange={(e) => onCountryChange(e)}
                                    className='global-location-dropdown'
                                    optionLabel="name"
                                    placeholder=""
                                />
                                <label className={selectedCountry?.code ? 'location-option-label selected' : 'location-option-label'}>Select Country</label>
                                {invalidPageState?.invalidCountry && (
                                    <div id="location-help" className="p-error block text-left">Require Country</div>
                                )}
                            </Col> */}
                            <Col lg={4} className='input-container'>
                                <div className='input-item no-margin'>
                                    <div className="wrapper-box">
                                        <InputText
                                            value={selectedModel.Model}
                                            className='car-brand-input'
                                            disabled
                                            style={{ background: '#fff', fontFamily: 'CadillacGothic-NarrowRegular', color: 'black' }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <Dropdown
                                    value={selectedVariants}
                                    options={variants}
                                    onChange={(e) => onVariantChange(e)}
                                    className='select-trim-dropdown'
                                    optionLabel="Variant"
                                    placeholder="Select trim"
                                    style={{ border: '2px black solid', fontFamily: 'CadillacGothic-NarrowRegular', color: 'black' }}
                                />
                                {invalidPageState?.invalidVariant && (
                                    <div id="model-help" className="p-error block text-left">Require Trim</div>
                                )}
                            </Col>
                        </Row>

                        <Row className='location-container'>
                            <Col lg={4} className='left-panel'>
                                <h6>TEST DRIVE LOCATION</h6>
                            </Col>
                            
                            <Col lg={8} className='dropdown-panel'>
                                <Dropdown
                                    value={selectedLocations}
                                    options={locations}
                                    onChange={(e) => onLocationChange(e)}
                                    className='global-location-dropdown'
                                    optionLabel="Name"
                                    placeholder=""
                                />
                                <label className={selectedLocations.Name ? 'location-option-label selected' : 'location-option-label'}>Select location</label>
                                {invalidPageState?.invalidLocation && (
                                    <div id="location-help" className="p-error block text-left">Require location</div>
                                )}
                            </Col>
                        </Row>

                        <Row className='personal-info-container'>
                            <Col lg={4} className='left-panel'>
                                <h6>PERSONAL INFORMATION</h6>
                            </Col>
                            <Col lg={8}>
                                <Row className="">
                                    <Col lg={6} className='input-container'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="firstname" aria-describedby="firstname-help"
                                                    className={classNames({ "p-invalid": invalidPageState?.invalidFirstname })}
                                                    placeholder=" "
                                                    value={pageState?.firstname}
                                                    onChange={(e) => {
                                                        //if (isNil(pageState)) return;
                                                        setPageState({
                                                            ...pageState,
                                                            firstname: e.target.value,
                                                        });

                                                        setInvalidPageState({
                                                            ...invalidPageState,
                                                            invalidFirstname: isNil(e.target.value) || e.target.value == ""
                                                        });
                                                    }}
                                                />
                                                <label>First Name *</label>
                                            </span>
                                        </div>
                                        {invalidPageState?.invalidFirstname && (
                                            <small id="fristname-help" className="p-error block">Require Valid First Name</small>
                                        )}
                                    </Col>
                                    <Col lg={6} className='input-container'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="lastname" aria-describedby="lastname-help"
                                                    className={classNames({ "p-invalid": invalidPageState?.invalidLastname })}
                                                    placeholder=" "
                                                    value={pageState?.lastname}
                                                    onChange={(e) => {
                                                        //if (isNil(pageState)) return;
                                                        setPageState({
                                                            ...pageState,
                                                            lastname: e.target.value,
                                                        });
                                                        setInvalidPageState({
                                                            ...invalidPageState,
                                                            invalidLastname: isNil(e.target.value) || e.target.value == ""
                                                        });
                                                    }}
                                                />
                                                <label>Last Name *</label>
                                            </span>

                                        </div>
                                        {invalidPageState?.invalidLastname && (
                                            <small id="lastname-help" className="p-error block">Require Valid Last Name</small>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} className='input-container'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="email" aria-describedby="email-help"
                                                    className={classNames({ "p-invalid": invalidPageState?.invalidEmail })}
                                                    placeholder=" "
                                                    value={pageState?.email}
                                                    onChange={(e) => {
                                                        //if (isNil(pageState)) return;
                                                        setPageState({
                                                            ...pageState,
                                                            email: e.target.value,
                                                        });
                                                        setInvalidPageState({
                                                            ...invalidPageState,
                                                            invalidEmail: isNil(e.target.value) || e.target.value == ""
                                                        });
                                                    }}
                                                />
                                                <label>Email *</label>
                                            </span>

                                        </div>
                                        {invalidPageState?.invalidEmail && (
                                            <small id="email-help" className="p-error block">Require Valid Email</small>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12} className='input-container'>
                                        {/*onKeyPress={(e: any) => isNumberKey(e)}*/}
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="phone" aria-describedby="phone-help"
                                                    className={classNames({ "p-invalid": invalidPageState?.invalidMobile })}
                                                    placeholder=" "
                                                    value={pageState?.mobile || ''}
                                                    onKeyPress={(e: any) => isNumberKey(e)}
                                                    onInput={(event: any) => {
                                                        let phone = event.target.value.slice(0, 11);
                                                        setPageState({
                                                            ...pageState,
                                                            mobile: phone,
                                                        });
                                                        setInvalidPageState({
                                                            ...invalidPageState,
                                                            invalidMobile: isNil(event.target.value) || event.target.value == ""
                                                        });
                                                    }}
                                                />
                                                <label>Phone Number *</label>
                                            </span>

                                        </div>
                                        {invalidPageState?.invalidMobile && (
                                            <small id="lastname-help" className="p-error block">Require Valid Phone Number</small>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <div className='checkbox-privacy-container'>
                            <div className='checkbox-container'>
                                <div className='checkbox-item'>
                                    <div className='checkbox-content'>
                                        <Checkbox
                                            name="cb-ack-agreement"
                                            inputId="cb-ack-agreement"
                                            onChange={(e) => {
                                                //if (isNil(pageState)) return;
                                                setPageState({
                                                    ...pageState,
                                                    confirm: e.checked,
                                                });
                                                setInvalidPageState({
                                                    ...invalidPageState,
                                                    invalidConfirm: !e.checked
                                                });
                                            }}
                                            checked={pageState?.confirm}
                                        ></Checkbox>
                                        {/* <label htmlFor="cb-ack" className="p-checkbox-label">
                                            I confirm that I have a valid driver's licence.
                                        </label> */}
                                        <label htmlFor="cb-ack-agreement" className="p-checkbox-label">
                                            By registering my interest in a test drive, I agree to Cadillac's <PrivacyPolicyLink props='Privacy Policy' /> and <PrivacyCollectionDialog />. 
                                        </label>
                                    </div>
                                    {invalidPageState?.invalidConfirm && (
                                        <small id="lastname-help" className="p-error block">Require the confirmation of valid agreement</small>
                                    )}
                                </div>

                                <div className='checkbox-item'>
                                    <div className='checkbox-content'>
                                        <Checkbox
                                            name="cb-ack-test-drive-optional"
                                            inputId="cb-ack-test-drive-optional"
                                            onChange={(e) => {
                                                //if (isNil(pageState)) return;
                                                setPageState({
                                                    ...pageState,
                                                    agreement: e.checked,
                                                })
                                            }}
                                            checked={pageState?.agreement}
                                        ></Checkbox>
                                        {/* <label htmlFor="cb-ack" className="p-checkbox-label">
                                            Please send me offers, news and events of GM and its partners via email, and SMS.
                                        </label> */}
                                        <label htmlFor="cb-ack-test-drive-optional" className="p-checkbox-label">
                                            I consent to receiving direct marketing materials from Cadillac. 
                                        </label>
                                    </div>
                                </div>

                            </div>
                            {/* <div className='privacy-container'>
                                <p>Cadillac does not sell, trade or disclose your contact information to independent third parties for their independent use
                                without your permission.</p>
                                <p>
                                    By continuing your test drive booking, you have accepted the&nbsp;
                                    
                                    <PrivacyPolicyLink props="Privacy Policy" />
                                    &nbsp;and&nbsp;
                                    <a style={{fontWeight: '400', color: '#3A3A3A'}}
                                         onClick={()=> window.open("https://www.cadillacanz.com/au-en/privacy", "_blank")}
                                    >Test Drive Terms and Conditions</a>
                                    &nbsp;of Cadillac.
                                </p>
                            </div> */}
                            {/* <a style={{color: '#3A3A3A', textDecoration: 'none'}} href={`/${getFrontendVirtualDir()}/privacy`} target="_blank">Privacy Policy</a> */}
                        </div>


                        <div className='variant-btn-panel'>
                            {submittingStatus == rdEnum.enumProcessingStatus.Processing ? (
                                <Button type="button" className='progress-btn' disabled={true}>
                                    <ProgressSpinner
                                        style={{ width: '16px', height: '16px' }}
                                        strokeWidth="5"
                                        animationDuration=".8s"
                                    />
                                </Button>

                            ) : (
                                    <React.Fragment>
                                        <Button className='variant-btn' disabled={!pageState?.confirm || false}
                                            style={{ border: 'none', background: !pageState?.confirm || false ? '#CCCCCC' : '#282828' }}
                                            onClick={() => {
                                                onBookTestDrive();
                                            }}
                                        >
                                            <h6 style={{ color: !pageState?.confirm || false ? '#707070' : '#fff' }}>REQUEST TEST DRIVE</h6>
                                        </Button>
                                        {/*{!isNil(submitMessage) && (*/}
                                        {/*    <div className={classNames({*/}
                                        {/*        "text-success": submitMessage.Status,*/}
                                        {/*        "text-error": !submitMessage.Status*/}
                                        {/*    })}>{submitMessage?.Message}</div>*/}
                                        {/*)}*/}
                                    </React.Fragment>
                                )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog className='test-drive-success-dialog' visible={isOpen} onHide={() => handleDialogHide()} draggable={false} resizable={true}>
                <div className='test-drive-success-container'>
                    <div className='successful-details-panel'>
                        <div className='successful-details-title'>
                            <h1>Request Successful!</h1>
                            {/* <p>You will receive a confirmation email shortly, and a member of our Cadillac Concierge team will be in touch.</p> */}
                            <p>We will be in touch when LYRIQ is available to test drive.</p>
                        </div>
                        <div className='successful-details-content'>
                            {/*<div className='booking-item'>*/}
                            {/*    <div className='item-name'>Test Drive ID</div>*/}
                            {/*    <div className='item-value'>{pageState?.yanaTestDriveNo}</div>*/}
                            {/*</div>*/}

                            <div className='booking-item'>
                                <div className='item-name'>Model & Trim</div>
                                <div className='item-value'>{pageState?.model.Model} {pageState?.variant.Variant}</div>
                            </div>

                            <div className='booking-item'>
                                <div className='item-name'>Test Drive Location</div>
                                <div className='item-value'>{pageState?.location?.Name}</div>
                            </div>

                            <div className='booking-item'>
                                <div className='item-name'>First Name</div>
                                <div className='item-value'>{pageState?.firstname}</div>
                            </div>

                            <div className='booking-item'>
                                <div className='item-name'>Last Name</div>
                                <div className='item-value'>{pageState?.lastname}</div>
                            </div>

                            <div className='booking-item'>
                                <div className='item-name'>Email Address</div>
                                <div className='item-value'>{pageState?.email}</div>
                            </div>

                            <div className='booking-item last-item'>
                                <div className='item-name'>Phone Number</div>
                                <div className='item-value'>{pageState?.mobile}</div>
                            </div>
                        </div>
                    </div>
                    {
                        !isMobile
                        ?
                        <div className='successful-img-panel'>
                            <img className="image" src={`${getCMSAssets()}/testDrive/test-drive-success.png`} />
                        </div>
                        :
                        <></>
                    }
                    
                </div>
                <div className='cross-box' onClick={() => handleDialogHide()}>
                    <img alt="" className='close-icon' src={`${getCMSAssets()}/main/cross.svg`} />
                </div>
            </Dialog>
        </MainLayoutPage>
    )
}

export default TestDrive