import { ILandingPage } from 'model/ILandingPage';
import * as rdEnum from 'helpers/enum';
export const getInitLandingPage = (): ILandingPage => {
    let init: ILandingPage = {
        Country: "",
        State: "",
        Postcode: "",
        Suburb: "",
        CustomerType: rdEnum.enumClientTypes.Private.Code,
        Model: rdEnum.constModel,
        Subtotal: null,
        DriveAwayCost: null,
        Available: "",
        YanaVariantID: "",
        YanaVariantName: "",
        VariantPrice: 0,
        ExteriorID: "",
        ExteriorPrice: 0,
        InteriorID: "",
        InteriorPrice: 0,
        Options: [],
        LoadPaymentText: "",
        FrequentlyText: "",
    };

    return init;
}