import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { checkMainSection, setScrollPosition } from 'stores/ScrollPosition';
import { GlobalPage } from 'pages/GlobalPage';
import { MainPage } from 'pages/MainPage';
import { IAppState } from 'model/IAppState';
import { hideGlobalPage } from 'stores/MainRightPage';

import * as rdEnum from 'helpers/enum';
import * as rdHelper from 'helpers/Uri';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as defaultConfigModel from 'model/IDefaultConfig';
import * as interestModel from 'model/IInterest';
import * as interestService from 'services/Interest';
import * as defaultConfigStore from 'stores/DefaultConfig';
import * as defaultHelper from 'businessHelpers/DefaultConfigHelper';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import * as selectedDeliveryStore from "stores/UserSelectedDelivery";
import * as paymentOptionsStore from "stores/PaymentOptions";
import * as customerTypeStore from "stores/CustomerType";
import * as linkSourceStore from "stores/LinkSource";
import * as vehicleModel from 'model/IVehicle';
import { MainLayoutPage } from 'LayoutPages';
import MainLeftImageGrid from 'pages/Main/MainLeftImageGrid';
import GlobalSelectVehicle from 'scene/Layout/GlobalSelectVehicle';
import { setGlobalDropdown } from 'stores/GlobalDropdown';
import GlobalShop from 'scene/Layout/GlobalShop';
import { setMainPageNumber } from 'stores/MainPageNumber';
import { useScroll } from 'pages/Main/ScrollToSection';
import { Button } from 'react-bootstrap';
import ScrollToTop from 'helpers/ScrollToTop';
import { Footer } from 'scene/Layout/Footer';
import { useCheckTabletScreen } from 'helpers/useCheckTabletScreen';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
interface IPreLoaderOpenProps {
    closePreLoader: boolean
}

export const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isToPayment, setIsToPayment] = useState(false);

    const [isGlobalDialogOpen, setIsGlobalDialogOpen] = useState(true);
    const [expiryedLink, setExpiryedLink] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectPage = searchParams.get('redirectPage');
    const selectedCountry = searchParams.get('selectedCountry');
    const selectedState = searchParams.get('selectedState');
    const selectedVariant = searchParams.get('selectedVariant');
    const selectedExterior = searchParams.get('selectedExterior');
    const selectedInterior = searchParams.get('selectedInterior');

    const isCheckoutPaymentPage = redirectPage && redirectPage == rdEnum.enumRedirectPage.CheckoutPaymentPage;

    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabletScreen();

    const { linkKey } = useParams();

    const isHideGlobal = useSelector((state: IAppState) => {
        return state.mainRightPage;
    });

    const sectionName = useSelector((state: IAppState) => {
        return state.mainSection;
    });
    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber
    })
    const defaultConfigFromStore = useSelector((state: IAppState) => {
        return state.defautConfig;
    });
    const menuGlobalBtn = useSelector((state: IAppState) => {
        return state.globalDropdown
    })

    const scrollToSectionRef = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                // behavior: 'instant', 
                block: 'start', // or 'center', 'end', 'nearest'
                inline: 'center', // or 'start', 'center', 'end'
            });
        }

        //dispatch(hideGlobalPage(true));
        // dispatch(setScrollPosition('global'));
        dispatch(checkMainSection('global'));
        // setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
        setScrollGlobalOpacity(1.00);
    };

    useEffect(() => {

        if (
            (!isNil(linkKey) && linkKey != "" || isCheckoutPaymentPage)
            || (!isNil(selectedVariant) && selectedVariant != "")
        ) {
            setIsGlobalDialogOpen(false);
        }

        // if (!isNil(linkKey) && linkKey != "") {
        //     dispatch(hideGlobalPage(false));
        //     dispatch(setScrollPosition('main'));
        //     dispatch(checkMainSection('variant'));
        //     document.body.style.overflowY = "scroll";
        //     document.body.style.paddingRight = "0px";
        //     // return;
        // }

        let animatedElements = document.querySelectorAll('.animate-item');

        //Scroll to top instantly without any animation
        // if (isMobile) {
        //     window.scrollTo({top: 0, behavior: 'instant'} as any);
        // } else {
        // document.querySelector("body")?.scrollTo({ top: 0, behavior: 'instant' } as any);
        // }

        scrollToSectionRef(containerRef);

        const thresholdValue = 0.5;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-active');
                } else {
                    entry.target.classList.remove('animate-active');
                }
            })
        }, {
            threshold: thresholdValue,
        });

        for (let i = 0; i < animatedElements.length; i++) {
            const el = animatedElements[i];
            observer.observe(el);
        }
    }, [isMobile]);

    const containerRef = useRef<any>(null);

    // const [y, setY] = useState(window.scrollY);
    // const [isScrollDown, setIsScrollDown] = useState(0);

    // const [pageScroll, setPageScroll] = useState(0);

    const [scrollGlobalOpacity, setScrollGlobalOpacity] = useState(1.00);

    const [scrollOpacity, setScrollOpacity] = useState(0.00);

    useEffect(() => {
        if (isCheckoutPaymentPage) {
            dispatch(setScrollPosition('payment'));
            dispatch(setMainPageNumber(1));
        }
    }, [])


    useEffect(() => {
        //from inventory page
        if (!isNil(selectedVariant) && selectedVariant != "") {
            let defaultConfig: defaultConfigModel.IDefaultConfig = {
                Country: selectedCountry,
                State: selectedState,
                VariantName: selectedVariant,
                ExteriorName: selectedExterior,
                InteriorName: selectedInterior,
                Options: [],
                Loaded: false,
                LinkKey: null,
                ExpiryDateText: null,
            }
            dispatch(defaultConfigStore.setDefaultConfigAction(defaultConfig));
            dispatch(linkSourceStore.setLinkSourceAction(null));

            let delivery: selectedConfigModel.ISelectedDelivery = {
                Country: {
                    name: selectedCountry == rdEnum.enumCountryCode.Australia ? "Australia" : "New Zealand",
                    code: selectedCountry || ""
                },
                State: {
                    name: selectedState || "",
                    code: selectedState || ""
                },
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                SelectedDeliveryFullAddress: null,
                SelectedDeliveryPostcode: null,
                SelectedDeliveryState: null,
                SelectedDeliverySuburb: null,
                //EstDeliveryDate: null,
                PrimaryAddress: null,
                PrimaryPostcode: null,
                PrimarySuburb: null,
                PrimaryState: null,
                InvalidPrimaryAddress: null,
                DeliveryAvailable: null,
                DeliveryPrice: null,
                DeliveryPriceErrorCode: null,
                DeliveryDistance: null,
                PickupLocationName: null,
            };
            dispatch(selectedDeliveryStore.updateDeliveryAction(delivery));

            //clear payment options
            dispatch(paymentOptionsStore.clearPaymentOptionsAction());

            //default set customer type as private customer
            dispatch(customerTypeStore.setCustomerTypeAction(rdEnum.enumClientTypes.Private.Code));
        }
        else {
            if (!isNil(linkKey) && linkKey != "") {
                if (!isNil(linkKey) && linkKey != "") {
                    doGetInterest();
                }
                else {
                    dispatch(defaultConfigStore.setDefaultConfigAction(defaultHelper.getInitDefaultConfig()));
                    dispatch(linkSourceStore.setLinkSourceAction(null));
                }
            }
        }

    }, [selectedVariant, linkKey]);


    //useEffect(() => {
    //    //from interest link from email
    //    if (!isNil(linkKey) && linkKey != "") {
    //        doGetInterest();
    //    }
    //    else {
    //        dispatch(defaultConfigStore.setDefaultConfigAction(defaultHelper.getInitDefaultConfig()));
    //        dispatch(linkSourceStore.setLinkSourceAction(null));
    //    }
    //}, [linkKey]);

    const doGetInterest = async () => {
        let response = await interestService.getInterest(linkKey || "");
        if (response.status) {

            let interestBackendData = response.data;
            if (interestBackendData.isExpired) {
                //expiry redirect to normal configuration page
                setExpiryedLink(true);
                setTimeout(() => {
                    let url = rdHelper.getFrontendOrigin();
                    window.location.href = url;
                }, 3000);

                return;
            }
            setExpiryedLink(false);
            if (defaultConfigFromStore?.LinkKey != linkKey) {
                let defaultConfig: defaultConfigModel.IDefaultConfig = {
                    Country: interestBackendData.country,
                    State: interestBackendData.state,
                    VariantName: interestBackendData.variantName,
                    ExteriorName: interestBackendData.exteriorName,
                    InteriorName: interestBackendData.interiorName,
                    Options: getInterestOptions(interestBackendData.options),
                    Loaded: false,
                    LinkKey: linkKey,
                    ExpiryDateText: interestBackendData.expiryDateText,
                }
                dispatch(defaultConfigStore.setDefaultConfigAction(defaultConfig));

                //set link source type
                dispatch(linkSourceStore.setLinkSourceAction(interestBackendData.typeOfLink));
            }


            //updating country/state must happened after updating defaultConfig value 
            //because getting variant / exterior / interior / options list happened once country/state store has data
            let delivery: selectedConfigModel.ISelectedDelivery = {
                Country: {
                    name: interestBackendData.country == rdEnum.enumCountryCode.Australia ? "Australia" : "New Zealand",
                    code: interestBackendData.country
                },
                State: {
                    name: interestBackendData.state,
                    code: interestBackendData.state
                },
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                SelectedDeliveryFullAddress: null,
                SelectedDeliveryPostcode: null,
                SelectedDeliveryState: null,
                SelectedDeliverySuburb: null,
                //EstDeliveryDate: null,
                PrimaryAddress: null,
                PrimaryPostcode: null,
                PrimarySuburb: null,
                PrimaryState: null,
                InvalidPrimaryAddress: null,
                DeliveryAvailable: null,
                DeliveryPrice: null,
                DeliveryPriceErrorCode: null,
                DeliveryDistance: null,
                PickupLocationName: null,
            };
            dispatch(selectedDeliveryStore.updateDeliveryAction(delivery));

            //clear payment options
            dispatch(paymentOptionsStore.clearPaymentOptionsAction());

            //default set customer type as private customer
            dispatch(customerTypeStore.setCustomerTypeAction(rdEnum.enumClientTypes.Private.Code));
        }


    }

    const getInterestOptions = (optionData: any): vehicleModel.IVehicleOptions[] | null => {
        let configs: vehicleModel.IVehicleOptions[] = [];
        optionData.map((item: any) => {
            configs.push({
                YanaOptionID: "",
                OptionType: item.optionType,
                OptionName: item.optionName,
                OptionPrice: item.optionPrice,
                OptionPicture: [],
                OptionPictureMobile: [],
                OptionDesc: item.optionDesc,
                ChargeType: item.chargeType,
                Disable: false
            });
        });
        return configs;
    }

    useEffect(() => {
        const sections = document.querySelectorAll('[data-section-id]');
        const observers: IntersectionObserver[] = [];

        sections.forEach((section) => {
            const sectionElement = section as HTMLElement;
            const sectionHeight = sectionElement.offsetHeight;
            const viewportHeight = window.innerHeight;
            //let thresholdValue;
            //// = sectionHeight < 500 ? 0.39 : Math.min(sectionHeight / viewportHeight, 1);
            //if (isMobile) {
            //    thresholdValue = sectionHeight < 500 
            //        ? 0.35
            //        : thresholdValue = sectionHeight >= 550 && sectionHeight < 1550 
            //        ? 0.35 
            //        : Math.min(sectionHeight / viewportHeight, 0.76); 
            //} else if (isTablet) {
            //    thresholdValue = sectionHeight < 600 
            //        ? 0.38 
            //        : Math.min(sectionHeight / viewportHeight, 0.85); 
            //} else {
            //    thresholdValue = sectionHeight < 550 
            //        ? 0.90
            //        : thresholdValue = sectionHeight >= 550 && sectionHeight < 1550 
            //        ? 0.70
            //        : thresholdValue = sectionHeight >= 1550 
            //        ? 0.16
            //        : Math.min(sectionHeight / viewportHeight, 0.20); 
            //}

            // const observerOptions = {
            //     root: null,
            //     rootMargin: '0px',
            //     threshold: thresholdValue,
            // };


            // const observer = new IntersectionObserver((entries) => {
            //     entries.forEach(entry => {
            //         const sectionId = entry.target.getAttribute('data-section-id');
            //         console.log(entry, sectionId)
            //         if (entry.isIntersecting) {

            //             if (sectionId) {
            //                 dispatch(checkMainSection(sectionId));
            //             }
            //         }
            //     });
            // }, observerOptions);

            // observer.observe(section);
            // observers.push(observer);
            //console.log(sectionElement.getAttribute('data-section-id'));
            let thresholdValue = 0.39;
            let sid = sectionElement.getAttribute('data-section-id');
            if (sid == rdEnum.enumScrollSection.Accessories) {
                thresholdValue = 0.25;
            }
            else if (sid == rdEnum.enumScrollSection.Exterior || sid == rdEnum.enumScrollSection.Interior) {
                thresholdValue = 0.9;
            }
            const observerOptions = {
                root: null,
                rootMargin: '0px 0px 0px 0px', // Large margin to ensure detection even if slightly offscreen
                threshold: thresholdValue, // A low threshold to capture any slight intersection
            };

            //console.log(sid + "-" + thresholdValue);
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    const sectionId = entry.target.getAttribute('data-section-id');
                    //console.log(sectionId);
                    if (entry.isIntersecting) {
                        //console.log('Intersecting:', sectionId); // Log when an element is intersecting
                        if (sectionId) {
                            dispatch(checkMainSection(sectionId));
                        }
                    }
                });
            }, observerOptions);

            observer.observe(section);
            observers.push(observer);

            //const sections = document.querySelectorAll('.nav_item');
            //sections.forEach(section => {
            //    observer.observe(section);
            //    observers.push(observer);
            //});
        });

        // Cleanup all observers on unmount
        return () => {
            observers.forEach(observer => observer.disconnect());
        };
    }, [dispatch, isTablet, isMobile]);

    const [isShowBottomPanel, setIsShowBottomPanel] = useState(false);

    let scrollTimeout: number;

    useEffect(() => {
        dispatch(setGlobalDropdown(false, false));
    }, []);

    // const [isScrolled, setIsScrolled] = useState(false);

    // const handleScroll = (event: any) => {
    //     const scrollTop = event.target.scrollTop;
    //     setIsScrolled(scrollTop > 50); 
    // };

    // useEffect(() => {
    //     const globalContainer = document.querySelector('.global-container');
    //     globalContainer?.addEventListener('scroll', handleScroll);
    //     return () => globalContainer?.removeEventListener('scroll', handleScroll);
    // }, []);

    // console.log(isScrolled)

    const { scrollToSection } = useScroll();

    // const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
    const [lastScroll, setLastScroll] = useState(0); // Track the last scroll position

    useEffect(() => {
        const container = document.querySelector('.d2c-container') as HTMLElement;
        // const variantSection = document.querySelector('[data-section-id="variant"]') as HTMLElement;

        const handleScroll = () => {
            // const thresholdStart = 100;
            // const thresholdEnd = 350;
            const scrollPosition = container?.scrollTop || 0;

            // Detect scroll direction
            // const direction = scrollPosition > lastScroll ? 'down' : 'up';
            // setScrollDirection(direction);
            // setLastScroll(scrollPosition); // Update last scroll position
            if (!isMobile) {
                if (scrollPosition <= 750 && mainPageNumber == 0) {
                    // dispatch(setScrollPosition('global'));
                    dispatch(checkMainSection('global'))
                    // setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
                    const globalOpacityRatio = parseFloat(((670 - scrollPosition) / 200).toFixed(2)); // Opacity from 1 to 0
                    setScrollGlobalOpacity(globalOpacityRatio >= 0 ? globalOpacityRatio : 0);
                } else if (scrollPosition > 750 && scrollPosition <= 1150 && mainPageNumber == 0) {
                    // dispatch(setScrollPosition('main'));
                    dispatch(checkMainSection('variant'));
                    // dispatch(setScrollPosition('main'));
                    // container.scrollTo({ top: 0, behavior: 'smooth' }); 
                    // scrollToSectionRef(containerRef);

                    // dispatch(setScrollPosition('global')); // Dispatch action to set scroll position to global
                    // setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
                    // setScrollGlobalOpacity(1.00);

                    // const opacityPercentage = parseFloat(((scrollPosition - 950) / 450).toFixed(2)); // Opacity from 0 to 1
                    // setScrollOpacity(opacityPercentage <= 1 ? opacityPercentage : 1); // Ensure opacity doesn't exceed 1
                } else if (mainPageNumber != 0) {
                    dispatch(checkMainSection('checkoutForm'));
                }
            } else {
                setIsShowBottomPanel(true);

                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }

                scrollTimeout = window.setTimeout(() => {
                    setIsShowBottomPanel(false);
                }, 500);

                if (scrollPosition <= 750 && mainPageNumber == 0) {
                    // dispatch(setScrollPosition('global'));
                    dispatch(checkMainSection('global'))
                    // setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
                    const globalOpacityRatio = parseFloat(((670 - scrollPosition) / 200).toFixed(2)); // Opacity from 1 to 0
                    setScrollGlobalOpacity(globalOpacityRatio >= 0 ? globalOpacityRatio : 0);
                } else if (scrollPosition > 750 && scrollPosition <= 1050 && mainPageNumber == 0) {
                    // dispatch(setScrollPosition('main'));
                    dispatch(checkMainSection('variant'));
                    // dispatch(setScrollPosition('main'));
                    // container.scrollTo({ top: 0, behavior: 'smooth' }); 
                    // scrollToSectionRef(containerRef);

                    // dispatch(setScrollPosition('global')); // Dispatch action to set scroll position to global
                    // setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
                    // setScrollGlobalOpacity(1.00);

                    // const opacityPercentage = parseFloat(((scrollPosition - 950) / 450).toFixed(2)); // Opacity from 0 to 1
                    // setScrollOpacity(opacityPercentage <= 1 ? opacityPercentage : 1); // Ensure opacity doesn't exceed 1
                } else if (mainPageNumber != 0) {
                    dispatch(checkMainSection('checkoutForm'));
                }
            }

            // else if (scrollPosition > 1150) {
            //     setScrollGlobalOpacity(0.00); // Fully hide global section
            //     setScrollOpacity(1.00);       // Fully show main section
            // }

            // Scroll down to variant when scrolling between 100px and 200px and if scrolling down
            // if (scrollPosition >= thresholdStart && scrollPosition <= thresholdEnd && scrollDirection === 'down') {
            //     // scrollToSection('variant');
            //     setTimeout(() => {
            //         scrollToSection('variant');
            //     }, 100);
            // }

            // Get the position of variant section relative to the scrollable container

            // Check if the variant section is more than 300px from the top of the container and scroll up
            // if (containerToTop > 900 && containerToTop < 1000 
            //     // && scrollDirection == 'up'
            // ) {
            //     console.log('Scrolling back to global');

            // }
        };

        // Attach scroll event listener to the container
        container?.addEventListener('scroll', handleScroll);

        // Cleanup event listener
        return () => {
            container?.removeEventListener('scroll', handleScroll);

            if (isMobile && scrollTimeout) {
                clearTimeout(scrollTimeout);
            }
        };
    }, [mainPageNumber]);



    const updateDeviceRatioClass = () => {
        const width: number = window.screen.width;
        const height: number = window.screen.height;
        const ratio: number = width / height; // Calculate and round the ratio
        // const ratioClass = `device-ratio-${Math.round(ratio * 100)}`; // Convert to class like .device-ratio-177

        const globalContainer = document.querySelector('.global-container');

        // Remove previous ratio classes
        globalContainer?.classList.forEach(className => {
            if (className.startsWith('device-ratio-')) {
                globalContainer?.classList.remove(className);
            }
        });

        if (ratio >= 1.75 && ratio <= 1.79) {
            globalContainer?.classList.add('device-ratio-177');
        } else if (ratio >= 1.58 && ratio <= 1.62) {
            globalContainer?.classList.add('device-ratio-160');
        } else {
            // Add other conditions as needed for different aspect ratios
            const roundedRatio = Math.round(ratio * 100);
            globalContainer?.classList.add(`device-ratio-${roundedRatio}`);
        }
    };

    useEffect(() => {
        // Initial check
        updateDeviceRatioClass();

    }, []);


    return (
        <MainLayoutPage>
            {expiryedLink && (
                <div>The link has expired... You will be redirected to LYRIQ configurator …</div>
            )}
            {!expiryedLink && (
                <React.Fragment>
                    <nav className="nav-menu"
                        style={{
                            position: 'fixed', top: 0, zIndex: 1001,
                            width: menuGlobalBtn.vehicles == true || menuGlobalBtn.shop == true || isMobile ? '100%' : 'calc(100% - 15px)'
                        }}
                    >
                        <GlobalMenu activeBtn='configure' />
                    </nav>

                    <section className='section global' data-section-id='global'
                        style={{
                            // paddingBottom: '385px',
                            display: mainPageNumber != 0 ? 'none' : "",
                            height: mainPageNumber != 0 ? '0' : 'auto',
                            opacity: `${scrollGlobalOpacity}`,
                        }}>
                        <div className='animate-item'>
                            <GlobalPage isGlobalDialogOpen={isGlobalDialogOpen} />
                        </div>
                    </section>

                    <MainPage
                        linkKey={linkKey}
                        selectedCountry={selectedCountry}
                        selectedState={selectedState}
                        selectedVariant={selectedVariant}
                        selectedExterior={selectedExterior}
                        selectedInterior={selectedInterior}
                        isShowBottomPanel={isShowBottomPanel}
                    />

                    <Footer />

                </React.Fragment>
            )}
        </MainLayoutPage>
    );
};











 // useEffect(() => {
    //     const options = {
    //         root: null, // relative to the viewport
    //         rootMargin: '0px',
    //         threshold: .7 // 70% of the section must be visible
    //     };

    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 const sectionId = entry.target.getAttribute('data-section-id');
    //                 if (sectionId) {
    //                     dispatch(checkMainSection(sectionId));

    //                 }
    //             }
    //         });
    //     }, options);

    //     //Because the last Checkout Form Section height is too large, threshold: 0.8 will cause many issues
    //     // like nav-menu not display etc. so set last section threshold individually is necessary
    //     const lastChildObserver = new IntersectionObserver((entries, observer) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 const sectionId = entry.target.getAttribute('data-section-id');
    //                 if (sectionId) {
    //                     dispatch(checkMainSection(sectionId));
    //                 }
    //             }
    //         });
    //     }, { threshold: 0.3 });

    //     const sections = document.querySelectorAll('.d2c-container section');
    //     // sections.forEach(section => observer.observe(section));

    //     // return () => {
    //     //     sections.forEach(section => observer.unobserve(section));
    //     // };

    //     sections.forEach((section, index) => {
    //         if (index === sections.length - 1) {
    //             lastChildObserver.observe(section);
    //         } else {
    //             observer.observe(section);
    //         }
    //     });

    //     return () => {
    //         sections.forEach((section, index) => {
    //             if (index === sections.length - 1) {
    //                 lastChildObserver.unobserve(section);
    //             } else {
    //                 observer.unobserve(section);
    //             }
    //         });
    //     };
    // }, [dispatch]);


     // useEffect(() => {
    //     // const container = containerRef.current;

    //     containerRef.current = document.body;

    //     let shouldHandleScroll = false;

    //     // setTimeout(() => {
    //     //     window.scrollTo(0, 0);
    //     // }, 200)

    //     // document.body.style.overflowY = 'hidden';
    //     // document.body.style.paddingRight = '15px';

    //     // Set the flag to true after a delay to allow time for the scroll to complete
    //     // setTimeout(() => {
    //     //     shouldHandleScroll = true;

    //     // }, 200);

    //     shouldHandleScroll = true;

    //     // Control d2c container stop scrolling for 2.3s, for display loader and location pop-up
    //     // containerRef.current.style.overflow = 'hidden';
    //     // const enableScrollTimeout = setTimeout(() => {
    //     //     containerRef.current.style.overflowY = 'auto';
    //     // }, 2300);

    //     let scrollPosition = 0;

    //     const changeScrollMenuPanel = (e: any) => {
    //         if (shouldHandleScroll) {
    //             // scrollPosition = (e.target as Document).documentElement.scrollTop;
    //             scrollPosition = window.scrollY;
    //             // console.log(window.scrollY)
    //             // console.log(scrollPosition);
    //             if (scrollPosition <= 400) {
    //                 dispatch(setScrollPosition('global'))
    //                 setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
    //                 setScrollGlobalOpacity(1.00);
    //                 const globalOpacityRatio = parseFloat(((370 - scrollPosition) / 200).toFixed(2)); // Opacity from 0 to 1
    //                 setScrollGlobalOpacity(globalOpacityRatio);
    //             } else if (scrollPosition > 400 && scrollPosition <= 550) {
    //                 dispatch(setScrollPosition('global'));
    //                 setScrollGlobalOpacity(0);
    //                 setScrollOpacity(0);
    //             } else if (scrollPosition > 550 && scrollPosition <= 1000) {
    //                 dispatch(setScrollPosition('main'));
    //                 const opacityPercentage = parseFloat(((scrollPosition - 550) / 200).toFixed(2)); // Opacity from 0 to 1
    //                 setScrollOpacity(opacityPercentage);
    //             }
    //             else if (scrollPosition > 1000) {
    //                 setScrollGlobalOpacity(0.00);
    //                 setScrollOpacity(1.00);
    //                 // dispatch(setScrollPosition('main'))
    //             }
    //             // if (y > e.target.scrollTop) {
    //             //     dispatch(checkScrollUpDown(2))
    //             // } else if (y < e.target.scrollTop) {
    //             //     dispatch(checkScrollUpDown(1))
    //             // } else {
    //             //     dispatch(checkScrollUpDown(0))
    //             // }
    //         } else {
    //             return;
    //         }
    //     }

    //     window.addEventListener('scroll', changeScrollMenuPanel);

    //     return () => {
    //         window.removeEventListener('scroll', changeScrollMenuPanel)
    //         // clearTimeout(enableScrollTimeout);

    //         // Enable scrolling immediately if the component is unmounted before the timeout
    //         if (containerRef.current) {
    //             containerRef.current.style.overflow = 'auto';
    //             dispatch(checkMainSection('global'));
    //             setScrollOpacity(0.00); // Reset opacity to 0 if less than 400
    //             setScrollGlobalOpacity(1.00);
    //             shouldHandleScroll = false;
    //         }
    //     }

    // }, []);
    // }, [handleScrollDown]); 



    // const [hasScrolledToVariant, setHasScrolledToVariant] = useState(false); 
    // const [hasScrolledBackToGlobal, setHasScrolledBackToGlobal] = useState(false);
    // const [scrollLock, setScrollLock] = useState(false); // Temporary flag to disable scroll handling

    // useEffect(() => {
    //     const container = document.querySelector('.d2c-container') as HTMLElement;
    //     const variantSection = document.querySelector('[data-section-id="variant"]') as HTMLElement;

    //     const handleScroll = () => {
    //         if (scrollLock) return; // If scrollLock is true, skip scroll handling

    //         const thresholdStart = 100;
    //         const thresholdEnd = 200;
    //         const scrollPosition = container?.scrollTop || 0;

    //         // Scroll to variant when scrolling between 100px and 200px
    //         if (scrollPosition >= thresholdStart && scrollPosition <= thresholdEnd && !hasScrolledToVariant) {
    //             scrollToSection('variant');
    //             setHasScrolledToVariant(true);
    //             setHasScrolledBackToGlobal(false);
    //         }

    //         // Get the position of variant section relative to the scrollable container
    //         const variantTopRelativeToContainer = variantSection.offsetTop - container.scrollTop;

    //         // Check if the variant section is more than 300px from the top of the container
    //         if (variantTopRelativeToContainer > 300 && !hasScrolledBackToGlobal) {
    //             console.log('Scrolling back to global');
    //             dispatch(setScrollPosition('global')); // Dispatch action to set scroll position to global
    //             setScrollLock(true); // Lock scroll handling
    //             window.scrollTo(0, 0); // Scroll back to top

    //             // Unlock scroll handling after scroll is completed
    //             setTimeout(() => {
    //                 setScrollLock(false); // Unlock scroll handling after 100ms
    //                 setHasScrolledBackToGlobal(true);
    //                 setHasScrolledToVariant(false);
    //             }, 100);
    //         }
    //     };

    //     container?.addEventListener('scroll', handleScroll);

    //     return () => {
    //         container?.removeEventListener('scroll', handleScroll);
    //     };
    // }, [hasScrolledToVariant, hasScrolledBackToGlobal, scrollLock, scrollToSection]);