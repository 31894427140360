import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { getCMSAssets, getGoogleMapAPIKey, getImageFileNameByID2 } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { IAppState } from 'model/IAppState';
import * as orderService from 'services/Order';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as googleApiService from 'services/GoogleApi';
import PickupLocation from './PickupLocation';

const ViewAppointment = (props: any) => {
    const { buttonText } = props;


    const [dialogVisible, setDialogVisible] = useState(false);


    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }



    return (
        <>
            <div className='pipeline-btn-panel'>
                <Button className='pipeline-btn'
                    onClick={() => onShowDialog()}
                >
                    <h6>{buttonText}</h6>
                </Button>
            </div>

            <Dialog header={"VIEW APPOINTMENT"}
                className='order-details-dialog'
                visible={dialogVisible} position='bottom'
                style={{ maxWidth: '1100px' }} draggable={false}
                resizable={true}
                onHide={() => onHideDialog()}
            >
                <div className='schedule-steps-dialog-container'>
                    <PickupLocation />
                </div>
            </Dialog>
        </>
    )
}

export default ViewAppointment