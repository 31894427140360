import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import { ProgressSpinner } from 'primereact/progressspinner';

import * as callBackModel from 'model/ICallBackRequest';
import * as orderService from 'services/Order';
import * as rdEnum from 'helpers/enum';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';

const Support = () => {

    const [requestDialogVisible, setRequestDialogVisible] = useState(false);
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
    const [requestStatus, setRequestStatus] = useState('');
    const [cancelStatus, setCancelStatus] = useState('');
    const [callBackType, setCallBackType] = useState('');
    const [processingState, setProcessingState] = useState(rdEnum.enumProcessingStatus.Init);
    const orderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderDetails
    });



    const successMessage = "We have received your request. A member of the Cadillac Team will call you shortly to finalise your request.";
    const failMessage = "We're sorry, there was an issue processing your request. Please try again or call 1800 CADILLAC (223455) for assistance. ";

    const isMobile = useCheckMobileScreen();


    const handleCallBackRequest = async () => {
        let request: callBackModel.ICallBackRequest = {
            YanaCustomerID: orderDetailsFromStore?.YanaCustomerID || "",
            YanaCustomerNo: orderDetailsFromStore?.YanaCustomerNo || "",
            YanaOrderID: orderDetailsFromStore?.YanaOrderID || "",
            YanaOrderNo: orderDetailsFromStore?.YanaOrderNo || "",
            Country: orderDetailsFromStore?.Country || "",
            RequestType: callBackType
        };
        setProcessingState(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.callBackRequest(request);
        setProcessingState(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            setRequestStatus('success');
        }
        else {
            setRequestStatus('failure');
        }
    }


    const onShowRequestDialog = (callBackType: string) => {
        setRequestDialogVisible(true);
        setCallBackType(callBackType);
    }

    const onHideRequestDialog = () => {
        setRequestDialogVisible(false);
        //clear request status to initial ''
        setRequestStatus('')
    }

    const onShowCancelDialog = () => {
        setCancelDialogVisible(true);
    }

    const onHideCancelDialog = () => {
        setCancelDialogVisible(false);
        //clear cancel status to initial ''
        setCancelStatus('')
    }

    return (
        <>
            {
                !isMobile
                ?
                <Dropdown data-bs-theme="dark" className='download-dropdown-container'>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                        <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/Question_mark.png`} />
                        <span>Support</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-2" onClick={() => onShowRequestDialog(rdEnum.enumCallBackRequest.CallBack)}
                            style={{ backgroundColor: 'transparent', color: '#282828' }} >
                            <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/phone.svg`} />
                            <span>Request a callback</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3" onClick={() => onShowRequestDialog(rdEnum.enumCallBackRequest.CancelOrder)}
                            style={{ backgroundColor: 'transparent', color: '#282828' }} >
                            <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/cross.svg`} />
                            <span>Cancel order</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Accordion className='download-dropdown-container'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/Question_mark.png`} />
                            <span>Support</span>
                        </Accordion.Header>
                        <Accordion.Body >
                                <div>
                                    <Button
                                        variant="transparent"
                                        onClick={() => onShowRequestDialog(rdEnum.enumCallBackRequest.CallBack)}
                                        style={{ display: 'flex', alignItems: 'center', color: '#282828' }}
                                    >
                                        <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/phone.svg`} />
                                        <span>Request a callback</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        variant="transparent"
                                        onClick={() => onShowRequestDialog(rdEnum.enumCallBackRequest.CancelOrder)}
                                        style={{ display: 'flex', alignItems: 'center', color: '#282828' }}
                                    >
                                        <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/cross.svg`} />
                                        <span>Cancel order</span>
                                    </Button>
                                </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
          


            <Dialog header={callBackType === rdEnum.enumCallBackRequest.CallBack ? "REQUEST A CALLBACK" : "CANCEL ORDER"}
                className='request-cancel-dialog'
                visible={requestDialogVisible}
                style={{ maxWidth: '400px' }} draggable={false}
                resizable={true}
                onHide={() => onHideRequestDialog()}
            >
                {
                    requestStatus == 'success'
                        ?
                        <p className='success-text'>{successMessage}</p>
                        :
                        <div className='request-cancel-container'>
                            <p>A member of the Cadillac Team will call you shortly to finalise your request. Do you wish to proceed?</p>
                            <div className='variant-btn-panel'>
                                {
                                    processingState == rdEnum.enumProcessingStatus.Processing ? (
                                        <Button className='variant-btn'
                                            disabled={true}
                                        >
                                            <ProgressSpinner
                                                style={{ width: '16px', height: '16px' }}
                                                strokeWidth="5"
                                                animationDuration=".8s"
                                            />
                                        </Button>
                                    ) : (
                                            <Button className='variant-btn'
                                                onClick={() => handleCallBackRequest()}
                                            >
                                                <span>YES</span>
                                            </Button>
                                        )
                                }
                                <Button className='variant-btn'
                                    onClick={() => onHideRequestDialog()}
                                >
                                    <span>NO</span>
                                </Button>
                            </div>

                            {
                                requestStatus == 'failure'
                                    ?
                                    <p className='error-text'>{failMessage}</p>
                                    :
                                    <></>
                            }
                        </div>
                }

            </Dialog>



        </>
    )
}

export default Support