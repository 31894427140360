import { MainLayoutPage } from 'LayoutPages'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Button, Col, Row } from 'react-bootstrap';
import { Calendar } from 'primereact/calendar';
import WhyCollecting from 'components/WhyCollecting';
import ChangeUsername from './ChangeUsername';
import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from 'model/IAppState';
import IsNumberVerification from 'components/IsNumberVerification';

import * as rdEnum from 'helpers/enum';

import * as profileModel from 'model/IUserProfile';
import * as profileService from 'services/User';

import * as profileHelper from 'businessHelpers/ProfileHelper';
import * as sessionStore from 'stores/Session';

import { useMsal } from '@azure/msal-react';
import { useAzureB2C } from 'helpers/useAzureB2C';
import Spinner from 'components/Spinner';

const Profiles = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const azureB2C = useAzureB2C();
    const { inProgress } = useMsal();

    const [pageState, setPageState] = useState<profileModel.IUserProfile>(profileHelper.getInitProfile());
    const [invalidState, setInvalidState] = useState<profileModel.IInvalidUserProfile>(profileHelper.getInitInvalidProfile());
    const [privacy, setPrivacy] = useState<profileModel.IUserPrivacy>(profileHelper.getInitPrivacy());

    const [submitMessage, setSubmitMessage] = useState<string>("");
    const [submittingStatus, setSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [submitResult, setSubmitResult] = useState<boolean | null>(null);

    const [invalidANBLength, setInvalidANBLength] = useState(false);

    useEffect(() => {
        if (azureB2C.isAuthAndLogin(inProgress)) {
            doGetProfile();
        }

    }, [inProgress]);

    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });
    const countries = useSelector((state: IAppState) => {
        return state.countryInfo
    });

    const doGetProfile = async () => {
        let response = await profileService.getProfile(
            sessionFromStore?.YanaCustomerID || "",
            sessionFromStore?.YanaCustomerNo || "");
        if (response.status) {
            let profile: profileModel.IUserProfile = {
                country: response.data.country,
                yanaProfileID: response.data.yanaProfileID,
                yanaCustomerID: response.data.yanaCustomerID,
                yanaCustomerNo: response.data.yanaCustomerNo,
                customerType: response.data.customerType,
                abn: response.data.abn,
                businessname: response.data.businessName,
                firstname: response.data.firstName,
                middlename: response.data.middleName,
                lastname: response.data.lastName,
                birthday: undefined,   //todo:
                usename: response.data.email,
                phone1: response.data.phoneNumber1,
                phone2: response.data.phoneNumber2,
            };
            setPageState(profile);

            let privacyFromBackend: profileModel.IUserPrivacy = {
                GMCommunicationEmail: response.data.gmCommunicationEmail,
                GMCommunicationMobile: response.data.gmCommunicationMobile,
                GMPartnerCommunicationEmail: response.data.gmPartnerCommunicationEmail,
                GMPartnerCommunicationMobile: response.data.gmPartnerCommunicationMobile,
            };
            setPrivacy(privacyFromBackend);
        }
        else {
            if (response.errorCode == rdEnum.enumRDErrorCode.NotSignIn) {
                azureB2C.signIn();
            }
        }
    }

    const onSubmit = async () => {

        let invalidForm = false;
        let invalidFirstName = false;
        let invalidLastName = false;
        let invalidBirthday = false;
        let invalidUsername = false;
        let invalidPhone = false;
        let invalidABN = false;

        if (isNil(pageState.firstname) || pageState.firstname == '') {
            invalidFirstName = true;
            invalidForm = true;
        }
        if (isNil(pageState.lastname) || pageState.lastname == '') {
            invalidLastName = true;
            invalidForm = true;
        }
        //if (isNil(pageState.birthday) || pageState.birthday == undefined) {
        //    invalidBirthday = true;
        //    invalidForm = true;
        //}

        if (isNil(pageState.usename) || pageState.usename == '') {
            invalidUsername = true;
            invalidForm = true;
        }
        if (isNil(pageState.phone1) || pageState.phone1 == '') {
            invalidPhone = true;
            invalidForm = true;
        }

        if (pageState.customerType == rdEnum.enumClientTypes.Business.Code) {
            if (isNil(pageState.abn) || pageState.abn == "") {
                invalidForm = true;
                invalidABN = true;
            } 
            if (pageState.country == rdEnum.enumCountryCode.Australia && pageState.abn.length != 11) {
                setInvalidANBLength(true);
                invalidForm = true;
            } else if (pageState.country == rdEnum.enumCountryCode.NewZealand && pageState.abn.length != 13) {
                setInvalidANBLength(true);
                invalidForm = true;
            }
        }

        setInvalidState({
            ...invalidState,
            invalidFirstname: invalidFirstName,
            invalidLastname: invalidLastName,
            invalidBirthday: invalidBirthday,
            invalidUsername: invalidUsername,
            invalidPhone: invalidPhone,
            invalidABN: invalidABN,
        });

        if (invalidForm) return;

        setSubmitMessage("");
        setSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await profileService.saveProfile(pageState, privacy, true);
        setSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
        setSubmitResult(response.status);

        if (response.status) {
            setSubmitMessage("Your Details have been successfully updated")
            setTimeout(() => {
                setSubmitMessage('')
            }, 5000)
        }
        else {
            setSubmitMessage(response.message);
        }
    }

    console.log(invalidState?.invalidABN)

    const isNumberKey = (e: any) => {
        IsNumberVerification(e);
    }

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }
    }, []);

    return (
        <MainLayoutPage sceneId='container-has-header'>

            <div className='global-options-container'>
                <GlobalMenu />

                <div className='profiles-container'>
                    <div className='profiles-header'>
                        <h2>PROFILE</h2>
                        <span>View and take action on order notifications, and manage your contact preferences.</span>
                    </div>

                    <div className='profiles-userinfo-container'>
                        <Row className='form-table'>
                            {pageState.customerType == rdEnum.enumClientTypes.Business.Code && (
                                <React.Fragment>
                                    <Col lg={6}>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="abn" aria-describedby="abn-help"
                                                    className={classNames({ "p-invalid": invalidState?.invalidABN })}
                                                    placeholder=" "
                                                    style={{ borderBottom: invalidState.invalidABN ? '3px solid red' : '2px solid #d2d2d2' }}
                                                    value={pageState.abn}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            abn: e.target.value,
                                                        });

                                                        // if (isNil(e.target.value) || e.target.value == "") {
                                                            setInvalidState({
                                                                ...invalidState,
                                                                invalidABN: false
                                                            });
                                                            setInvalidANBLength(false)
                                                        // }
                                                    }}
                                                />
                                                <label>{pageState.country == rdEnum.enumCountryCode.Australia ? "ABN" : "NZBN"} *</label>
                                            </span>
                                            {invalidState?.invalidABN && (
                                                <small id="abn-help" className="p-error block">Require Valid {pageState.country == rdEnum.enumCountryCode.Australia ? "ABN" : "NZBN"}</small>
                                            )}
                                            {invalidANBLength && (
                                                <small id="abn-help" className="p-error block">{pageState.country == rdEnum.enumCountryCode.Australia ? "ABN" : "NZBN"} mismatch, require {pageState.country == rdEnum.enumCountryCode.Australia ? "11" : "13"} digits.</small>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="businessname" aria-describedby="businessname-help"
                                                    className={classNames({ "p-invalid": invalidState?.invalidBusinessName })}
                                                    placeholder=" "
                                                    value={pageState.businessname}
                                                    style={{ borderBottom: invalidState.invalidBusinessName ? '3px solid red' : '2px solid #d2d2d2' }}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            businessname: e.target.value,
                                                        });
                                                        setInvalidState({
                                                            ...invalidState,
                                                            invalidBusinessName: isNil(e.target.value) || e.target.value == ""
                                                        });
                                                    }}
                                                />
                                                <label>Business Name *</label>
                                            </span>
                                            {invalidState?.invalidBusinessName && (
                                                <small id="businessname-help" className="p-error block">Require Valid Business Name</small>
                                            )}
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )}
                            <Col lg={6} >
                                <div className='input-item'>
                                    <span className="wrapper-box">
                                        <InputText id="firstname" aria-describedby="firstname-help"
                                            className={classNames({ "p-invalid": invalidState?.invalidFirstname })}
                                            placeholder=" "
                                            style={{ borderBottom: invalidState.invalidFirstname ? '3px solid red' : '2px solid #d2d2d2' }}
                                            value={pageState.firstname}
                                            onChange={(e) => {
                                                setPageState({
                                                    ...pageState,
                                                    firstname: e.target.value,
                                                });

                                                setInvalidState({
                                                    ...invalidState,
                                                    invalidFirstname: isNil(e.target.value) || e.target.value == ""
                                                });
                                            }}
                                        />
                                        <label>First Name *</label>
                                    </span>
                                    {invalidState?.invalidFirstname && (
                                        <small id="fristname-help" className="p-error block">Require Valid First Name</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='input-item'>
                                    <span className="wrapper-box">
                                        <InputText id="lastname" aria-describedby="lastname-help"
                                            className={classNames({ "p-invalid": invalidState?.invalidLastname })}
                                            placeholder=" "
                                            value={pageState.lastname}
                                            style={{ borderBottom: invalidState.invalidLastname ? '3px solid red' : '2px solid #d2d2d2' }}
                                            onChange={(e) => {
                                                setPageState({
                                                    ...pageState,
                                                    lastname: e.target.value,
                                                });
                                                setInvalidState({
                                                    ...invalidState,
                                                    invalidLastname: isNil(e.target.value) || e.target.value == ""
                                                });
                                            }}
                                        />
                                        <label>Last Name *</label>
                                    </span>
                                    {invalidState?.invalidLastname && (
                                        <small id="lastname-help" className="p-error block">Require Valid Last Name</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='input-item'>
                                    <span className="wrapper-box">
                                        <InputText id="middlename" aria-describedby="middlename-help"
                                            placeholder=" "
                                            value={pageState.middlename}
                                            onChange={(e) => {
                                                setPageState({
                                                    ...pageState,
                                                    middlename: e.target.value,
                                                })
                                            }}
                                        />
                                        <label>Middle Name (Optional)</label>
                                    </span>
                                </div>
                            </Col>
                            {/*<Col lg={6}>*/}
                            {/*    <div className='input-item'>*/}
                            {/*        <span className="wrapper-box">*/}
                            {/*            <Calendar id="calendar" placeholder="" showIcon*/}
                            {/*                value={pageState.birthday}*/}
                            {/*                onChange={(e: any) => {*/}
                            {/*                    //setDate1(e.value)*/}
                            {/*                    setPageState({*/}
                            {/*                        ...pageState,*/}
                            {/*                        birthday: e.value*/}
                            {/*                    });*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>
                        {/* <WhyCollecting /> */}
                    </div>

                    <Row>
                        <Col lg={6}>
                            <div className='change-username-container'>
                                <div className='profile-section-title'>
                                    USERNAME
						    </div>
                                {/*<ChangeUsername />*/}
                                <div className="desc-input-wrapper">
                                    <div className='section-desc'>
                                        You'll use your email address to sign into your account, and it's where Cadillac sends you updates and other information depending on your preferences. This email may also be used to verify your identity.
						            </div>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="username" aria-describedby="username-help"
                                                className={classNames({ "p-invalid": invalidState.invalidUsername })}
                                                placeholder=" "
                                                style={{ borderBottom: invalidState.invalidUsername ? '3px solid red' : '2px solid #d2d2d2' }}
                                                value={pageState.usename}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        usename: e.target.value,
                                                    });
                                                }}
                                            />
                                            <label>User Name *</label>
                                        </span>
                                        {invalidState.invalidUsername && (
                                            <small id="username-help" className="p-error block">Require valid User Name *</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className='contact-phone-container'>
                                <div className='profile-section-title'>
                                    PHONE
						    </div>
                                <div className="desc-input-wrapper">
                                    <div className='section-desc'>
                                        By providing your telephone number(s), you grant permission for Cadillac team members to deliver updates via phone or SMS. 
						            </div>
                                    <div className='phone-list-container'>
                                        <div className='phone-item-box'>
                                            <div className='input-item'>
                                                <span className="wrapper-box">
                                                    <InputText id="phone1" aria-describedby="phone1-help"
                                                        className={classNames({ "p-invalid": invalidState.invalidPhone })}
                                                        placeholder=" "
                                                        style={{ borderBottom: invalidState.invalidPhone ? '3px solid red' : '2px solid #d2d2d2' }}
                                                        onKeyPress={(e: any) => isNumberKey(e)}
                                                        value={pageState.phone1}
                                                        onChange={(e) => {
                                                            let mobile = e.target.value.slice(0, 11);
                                                            setPageState({
                                                                ...pageState,
                                                                phone1: mobile,
                                                            });
                                                        }}
                                                    />
                                                    <label>Phone Number 1 *</label>
                                                </span>
                                                {invalidState.invalidPhone && (
                                                    <small id="phone1-help" className="p-error block">Require valid Phone Number 1 *</small>
                                                )}
                                            </div>
                                            {/*<div className='input-item'>*/}
                                            {/*    <span className="wrapper-box">*/}
                                            {/*        <InputText id="phone2" aria-describedby="phone2-help"*/}
                                            {/*            placeholder=" "*/}
                                            {/*            style={{ borderBottom: invalidState.invalidPhone ? '3px solid red' : '2px solid #d2d2d2' }}*/}
                                            {/*            onKeyPress={(e: any) => isNumberKey(e)}*/}
                                            {/*            value={pageState.phone2}*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                let mobile = e.target.value.slice(0, 11);*/}
                                            {/*                setPageState({*/}
                                            {/*                    ...pageState,*/}
                                            {/*                    phone2: mobile,*/}
                                            {/*                });*/}
                                            {/*            }}*/}
                                            {/*        />*/}
                                            {/*        <label>Phone Number 2</label>*/}
                                            {/*    </span>*/}
                                            {/*</div>*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className='btn-update-wrapper'>   {/*variant-btn-panel*/}
                        <Button className='btn-update'
                            disabled={submittingStatus == rdEnum.enumProcessingStatus.Processing}
                            style={{border: 'none', backgroundColor: submittingStatus == rdEnum.enumProcessingStatus.Processing ? '#CCCCCC' : '#282828'}}
                            onClick={() => {
                                onSubmit()
                            }}
                            data-pr-tooltip="Luxury"
                        >
                            {
                                submittingStatus == rdEnum.enumProcessingStatus.Processing
                                ?
                                <span style={{color:'#707070'}}><Spinner spinnerWidth={26} /></span>
                                :
                                <span style={{color:'#fff'}}>UPDATE</span>
                            }
                            
						</Button>
                        {submitMessage != "" && submittingStatus == rdEnum.enumProcessingStatus.Processed && (
                                <div className={classNames({
                                    "text-error": !submitResult,
                                    "text-success": submitResult
                                })}>{submitMessage}</div>
                            
                        )}
                    </div>
                </div>

            </div>

        </MainLayoutPage>
    )
}

export default Profiles