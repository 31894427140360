//This stores Main Page's page number, page 1 is variant, exterior, interior etc. While page 2 is checkout pagement page
import { IMainPageNumber } from "helpers/interface";

export const SET_MAIN_PAGE_NUMBER = 'showMainPageNumber';
export const setMainPageNumber = (mainPageNumber: number) =>
({
    type: SET_MAIN_PAGE_NUMBER,
    mainPageNumber
} as const);

export type MainPageNumberActions = ReturnType<typeof setMainPageNumber>;
export const initMMainPageNumberState: IMainPageNumber = {
    mainPageNumber: 0
}

export const mainPageNumberReducer = (
    state = initMMainPageNumberState,
    action: MainPageNumberActions,
) => {
    switch (action.type) {
        case SET_MAIN_PAGE_NUMBER: {
            return {
                ...state,
                mainPageNumber: action.mainPageNumber,
            };
        }
        default:
        return state;
    }
};
