import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Navbar, Accordion } from 'react-bootstrap';
import { RiHomeLine } from 'react-icons/ri';
import { Button } from 'primereact/button';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from 'helpers/authConfig';



import {
    getFrontendUrl,
    getImageFileNameByID2,
    IImagesState,
    getImageLinkByID2,
    getMobileImageLinkByID2,
    getCMSAssets,
    getServiceUrlPara
} from 'helpers/Uri';

import { clearSessionAction } from "stores/Session";
import { IAppState } from 'model/IAppState';
import { enumOrdersTabName } from "helpers/enum";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { AiOutlineSearch, AiOutlineLogin, AiOutlineUser } from "react-icons/ai";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { logout } from 'services/User';

//import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { setTimeout } from 'timers';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
//import { InteractionStatus } from "@azure/msal-browser";
//import { loginRequest, b2cPolicies } from 'helpers/authConfig';
import { useAzureB2C } from 'helpers/useAzureB2C';
import { useClearUserDataStore } from 'helpers/useClearUserDataStore';

import * as userService from 'services/User';


export const UserPopUp = (props: any) => {
    const { isClickable } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const azureB2C = useAzureB2C();
    const clearUserData = useClearUserDataStore();

    const [userVisible, setUserVisible] = useState(false);

    //const gaEventTracker = useAnalyticsEventTracker('Menu');

    const loginSession = useSelector((state: IAppState) => {
        return state.session;
    });

    const scrollPosition = useSelector((state: IAppState) => {
        return state.scrollPosition;
    });

    //images
    const initImage: IImagesState = {
        imageLoaded: false,
        imageList: []
    }
    const [images, setImages] = useState<IImagesState>(initImage);


    const isMobile = useCheckMobileScreen();

    const [activeButton, setActiveButton] = useState('lyriq');

    const toSignIn = () => {
        azureB2C.signIn();
    }

    const toMyOrders = () => {
        navigate(`${getFrontendUrl()}/my-orders`);
        setUserVisible(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }

    const toProfiles = () => {
        navigate(`${getFrontendUrl()}/profile`);
        setUserVisible(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }

    const toPrivacy = () => {
        navigate(`${getFrontendUrl()}/privacy`);
        setUserVisible(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }

    const onGotoOrdersManagementPage = (tab: number) => {
        //buttonClickLog("Menu", "Orders");
        navigate(`/member-orders/${tab}`);
    }
    const onGotoAdminOrdersManagementPage = (tab: number) => {
        navigate(`/manager-orders/${tab}`);
    }
    const onGotoUpdateProfilePage = () => {
        navigate(`/update-member-details`);
    }
    const onGotoUpdatePasswordPage = () => {
        navigate(`/update-password`);
    }

    const onLogOut = async () => {
        console.log(1);
        let response = await userService.clearSession();
        if (response.status) {
            setUserVisible(false);
            document.body.style.overflowY = 'scroll';
            document.body.style.paddingRight = '0px';
            clearUserData.clearData();
            azureB2C.signOut();
        }
    }

    const onUserOpen = () => {
        setUserVisible(true);
        document.body.style.overflowY = 'hidden';
        document.body.style.paddingRight = '15px';
    }

    const onUserHide = () => {
        setUserVisible(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }


    const menu = useRef(null);


    //const showSignPage = () => {
    //    navigate(`/sign-in`);
    //}

    const customHeader = (
        <div className='pop-up-dialog-header'>
            <div className='dialog-btn-panel'>
                <Button
                    // Replace 'pi-custom-close-icon' with the desired icon class
                    onClick={() => onUserHide()}
                    className="p-dialog-titlebar-close"
                >
                    <img alt="" src={`${getCMSAssets()}/users/cross.svg`} />
                </Button>
            </div>
            <div className='account-main'>
                <img alt="" src={`${getCMSAssets()}/menu/div.gn-misc.svg`} />
                <span>Access your Cadillac <br/>vehicle orders and settings.</span>
            </div>
        </div>
    );

    return (
        <>
            <div className='search-wrapper-container'>

                {isNil(loginSession) && (
                    <a className={scrollPosition.positionStatus == 'main' ?
                        // "main-online-wrapper" 
                        "online-wrapper stat-text-link"
                        :
                        "online-wrapper stat-text-link"}
                        onClick={() => {
                            if (isClickable) {
                                onUserOpen()
                            }
                        }}
                        data-dtm="global nav"
                        data-dtm2="my account"
                    >
                        <img src={`${getCMSAssets()}/menu/div.gn-misc.svg`} className="icon-user-unsigned"></img>
                        {/* {scrollPosition.positionStatus == 'main' ? <span>ACCOUNT</span> : <></>} */}
                        {/* <span>Account</span> */}
                    </a>
                )}

                {!isNil(loginSession) && (
                    <a className={scrollPosition.positionStatus == 'main' ? "main-online-wrapper" : "online-wrapper"}
                        onClick={() => {
                            if (isClickable) {
                                onUserOpen()
                            }
                            // if (!isNil(menu)) {
                            //     (menu as any).current.toggle(event);
                            // }
                        }}
                    >
                        {/* <AiOutlineUser size="30" className="icon-user" color='black'/> */}
                        <img src={`${getCMSAssets()}/menu/div.gn-misc.svg`} className="icon-user-unsigned"></img>
                        {/* <span>
                            {loginSession?.FirstName && loginSession?.FirstName.charAt(0).toUpperCase() + loginSession?.FirstName.slice(1)}
                        </span> */}
                        {/*<Menu model={loginSession?.UserType == 1 ? userItems : adminUserItems} popup ref={menu} className="user-popup" />*/}
                        {/* <img src={`${getCMSAssets()}/${languageState.LanguageScreenName}/Arrow-Down.png`}  className="icon-arrow"/> */}
                    </a>
                )}
            </div>

            <Dialog header={customHeader} position='top-right' style={{ position: 'absolute', top: '0', margin: '0', width: '340px' }} draggable={false} resizable={true} closable={false}
                visible={userVisible} dismissableMask={true}
                onHide={() => onUserHide()}
                className={`user-dialog ${!isNil(loginSession) ? 'logged-in-height' : ''}`}
            >
                <AuthenticatedTemplate>
                    <div className='sign-in-off-panel'>
                        <div className='sign-in-off-options-panel'>
                            <div className='sign-in-off-card' onClick={() => toMyOrders()}>
                                <img src={`${getCMSAssets()}/menu/Vector.svg`}></img>
                                <span>ORDERS</span>
                            </div>
                            <div className='sign-in-off-card' onClick={() => toProfiles()}>
                                <img src={`${getCMSAssets()}/menu/div.gn-misc.svg`}></img>
                                <span>PROFILE</span>
                            </div>
                            <div className='sign-in-off-card' onClick={() => toPrivacy()}>
                                <img src={`${getCMSAssets()}/users/Lock.svg`}></img>
                                <span>PRIVACY</span>
                            </div>
                        </div>
                        <div className='sign-in-off-quit-panel'>
                            <div className='sign-in-off-card' onClick={() => onLogOut()}>
                                <img src={`${getCMSAssets()}/users/hide.svg`} className="icon-user-unsigned"></img>
                                <span>SIGN OUT</span>
                            </div>
                        </div>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className='sign-in-off-card stat-text-link' data-dtm="my account" onClick={() => toSignIn()}>
                        Sign In
                    </div>
                </UnauthenticatedTemplate>
            </Dialog>
        </>
    );
};


