
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';

import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { IAppState } from 'model/IAppState';
import moment from 'moment';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import * as Uri from 'helpers/Uri';
import * as rdEnum from 'helpers/enum';
import * as checkoutHelper from 'businessHelpers/CheckoutHelper';

import * as orderModel from 'model/IOrder';
import * as checkOutViewModel from 'model/ICheckoutWaitlistViewModel';

import * as orderService from 'services/Order';
import * as paymentService from 'services/AdyenPayment';


const RetryPayment = (props: any) => {
    const dispatch = useDispatch();
    const { buttonText } = props;
    const paymentErrorMessage = "Your credit card details have been refused, please re-enter your details.";
    const [dialogVisible, setDialogVisible] = useState(false);

    const paymentContainer = useRef<HTMLDivElement>(null);
    const [adyenSession, setAdyenSession] = useState<checkOutViewModel.IAdyenSession>(checkoutHelper.getAdyenInitState());
    const [customCardInstance, setCustomCardInstance] = useState<any>();

    const [placeOrderMessage, setPlaceOrderMessage] = useState<string>("");
    const [adyenFailedMessage, setAdyenFailedMessage] = useState("");
    const [confirmOrderSubmittingStatus, setConfirmOrderSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Processing);
    const [isShowAdyenRetryButton, setIsShowAdyenRetryButton] = useState(false);

    const [showButton, setShowButton] = useState(false);


    //store variables
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const selectedOrderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderDetails;
    });


    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }

    setTimeout(() => {
        const streetRef = document.getElementsByName("street");
        if (streetRef != null && streetRef.length > 0) {
            streetRef[0]?.setAttribute("placeHolder", "Address");
        }
        const cityRef = document.getElementsByName("city");
        if (cityRef != null && cityRef.length > 0) {
            cityRef[0]?.setAttribute("placeHolder", "Suburb");
        }

        const postcodeRef = document.getElementsByName("postalCode");
        if (postcodeRef != null && postcodeRef.length > 0) {
            postcodeRef[0]?.setAttribute("placeHolder", "PostCode");
        }

    }, 1000);


    const translations = {
        "en_AU": {
            "creditCard.numberField.title": "",
            /* "creditCard.numberField.title": "Card Number *",*/
            "creditCard.numberField.placeholder": "1234 5678 9012 3456",
            "creditCard.expiryDateField.title": "",
            "creditCard.expiryDateField.placeholder": "MM/YY",
            "creditCard.cvcField.title": "",
            "creditCard.cvcField.placeholder": "CVV *",
            "creditCard.cvcField.placeholder.4digits": "CVV *",
            "creditCard.cvcField.placeholder.3digits": "CVV *",
            "creditCard.holderName": "",
            "creditCard.holderName.placeholder": "Name on card",
            "billingAddress": "",
            "country": "",
            "city": "",
            "city.errors.incomplete": "Enter Suburb",
            "state": "",
            "postalCode": "",
            "postalCode.errors.incomplete": "Enter Postcode",
            "address": "",
            "address.enterManually": "Address",
            "address.errors.incomplete": "Enter Address to continue",
        },
        "en_NZ": {
            "creditCard.numberField.title": "",
            "creditCard.numberField.placeholder": "1234 5678 9012 3456",
            "creditCard.expiryDateField.title": "",
            "creditCard.expiryDateField.placeholder": "MM/YY",
            "creditCard.cvcField.title": "",
            "creditCard.cvcField.placeholder": "CVV *",
            "creditCard.cvcField.placeholder.4digits": "CVV *",
            "creditCard.cvcField.placeholder.3digits": "CVV *",
            "creditCard.holderName": "",
            "creditCard.holderName.placeholder": "Name on card",
            "billingAddress": "",
            "country": "",
            "city": "",
            "city.errors.incomplete": "Enter Suburb",
            "state": "",
            "postalCode": "Postcode",
            "postalCode.errors.incomplete": "Enter Postcode",
            "address": "",
            "street": "",
            "address.enterManually": "Address",
            "address.errors.incomplete": "Enter Address to continue",
        }
    };


    const doGetAdyenConfig = () => {

        //let env = Uri.getAdyenEnv();
        //if (env != "test") {
        //    if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand) {
        //        env = "live-au";
        //    }
        //}


        let configuration = {
            locale: selectedOrderDetailsFromStore?.Country == rdEnum.enumCountryCode.NewZealand ? "en_NZ" : "en_AU",
            environment: Uri.getAdyenEnv(selectedOrderDetailsFromStore?.Country),   // Australia: 	live-au
            clientKey: Uri.getAdyenKey(selectedOrderDetailsFromStore?.Country || ""),
            translations: translations,
            session: {
                id: adyenSession.SessionID, // Unique identifier for the payment session.
                sessionData: adyenSession.SessionData // The payment session data.
            },
            //setStatusAutomatically: false,  //Do not use this flag. We have to a get new session id as RD reference id need be changed for each pay action
            onPaymentCompleted: async (result: any, component: any) => {
                setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
                let localBilling = localStorage.getItem("billing");
                let paymentID = localStorage.getItem("paymentID");

                let postAdyenSession: checkOutViewModel.IAdyenSession = {
                    SessionID: adyenSession.SessionID,
                    SessionData: result.sessionData,
                    SessionResult: result.sessionResult,
                    ResultCode: result.resultCode,
                    ErrorMessage: null
                };

                if (!isNil(result)) {
                    if (result.resultCode == "Authorised") {
                        setAdyenFailedMessage("");

                        if (isNil(localBilling) || localBilling == "") return;

                        let billingData: checkOutViewModel.IAdyenTempPaymentData = JSON.parse(localBilling);
                        if (!isNil(selectedOrderDetailsFromStore)) {
                            let postOrder: orderModel.IOrderRequest = {
                                ...selectedOrderDetailsFromStore,
                                BillingAddress: billingData.Address1,
                                BillingPostcode: billingData.PostCode,
                                BillingState: billingData.State,
                                BillingSuburb: billingData.Suburb,
                                RDPaymentID: paymentID,
                                NameOnCreditCard: billingData.NameOnCard
                            };
                            await saveRetryPayment(postOrder, postAdyenSession);

                            //   doPlaceOrder(postOrder, postAdyenSession);
                        }

                    }
                    else if (result.resultCode == "Refused") {
                        if (!isNil(selectedOrderDetailsFromStore)) {
                            let postOrder: orderModel.IOrderRequest = {
                                ...selectedOrderDetailsFromStore,
                                RDPaymentID: paymentID,
                            };
                            await doSavePaymentLog(postOrder, postAdyenSession);

                            await doGetPaymentIDs(postOrder?.OrderID, postOrder?.Country);
                            setAdyenFailedMessage(paymentErrorMessage);
                            setIsShowAdyenRetryButton(true);
                        }
                    }
                }
            },
            beforeSubmit: (data: any, component: any, actions: any): Promise<void> => {
                //state or store changed here, are not updated in onPaymentCompleted
                //so have to use local storage to temp store billing data and card holder name.
                let tempData: checkOutViewModel.IAdyenTempPaymentData = {
                    Address1: data.billingAddress.street,
                    Address2: data.billingAddress.houseNumberOrName,
                    PostCode: data.billingAddress.postalCode,
                    Suburb: data.billingAddress.city,
                    State: data.billingAddress.stateOrProvince,
                    NameOnCard: data.paymentMethod.holderName,
                };

                localStorage.setItem("billing", JSON.stringify(tempData));
                setAdyenFailedMessage("");
                setPlaceOrderMessage("");
                return actions.resolve(data);
            },
            onError: async (error: any, component: any) => {
                let paymentID = localStorage.getItem("paymentID");
                if (!isNil(selectedOrderDetailsFromStore)) {
                    let postOrder: orderModel.IOrderRequest = {
                        ...selectedOrderDetailsFromStore,
                        RDPaymentID: paymentID,
                    };

                    let postAdyenSession: checkOutViewModel.IAdyenSession = {
                        SessionID: adyenSession.SessionID,
                        SessionData: adyenSession.SessionData,
                        SessionResult: null,
                        ResultCode: "Error",
                        ErrorMessage: error.message
                    };
                    await doSavePaymentLog(postOrder, postAdyenSession);

                    await doGetPaymentIDs(selectedOrderDetailsFromStore?.OrderID, selectedOrderDetailsFromStore?.Country);
                    setAdyenFailedMessage(paymentErrorMessage);
                    //setIsShowAdyenRetryButton(true);
                }
            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: true,
                    data: {
                        billingAddress: {
                            "country": selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand ? "NZ" : "AU",
                            placeholder: {
                                "postalCode.placeholder": "Postcode",
                            },
                        },
                    },
                    brands: ['mc', 'visa', 'amex']
                },
                //threeDS2: { // Web Components 4.0.0 and above: sample configuration for the threeDS2 action type
                //    challengeWindowSize: '05'
                //    // Set to any of the following:
                //    // '02': ['390px', '400px'] -  The default window size
                //    // '01': ['250px', '400px']
                //    // '03': ['500px', '600px']
                //    // '04': ['600px', '400px']
                //    // '05': ['100%', '100%']
                //},
            },
            showPayButton: false
        };

        return configuration;

    }

    const doSavePaymentLog = async (order: orderModel.IOrderRequest, adyenSession: checkOutViewModel.IAdyenSession) => {
        let response = await paymentService.savePaymentLog(order, adyenSession);
        if (response.status) {

        }
        else {

        }
    }

    const doGetPaymentIDs = async (orderID: any, country: any) => {
        let response = await paymentService.getNewPaymentIDs(orderID, country);
        if (response.status) {
            let responseData = response.data;
            setAdyenSession({
                ...adyenSession,
                SessionID: responseData.adyenSessionID,
                SessionData: responseData.adyenSessionData
            });
            localStorage.setItem("paymentID", responseData.rdPaymentTransactionID);
            //setShowButton(true);
        }
        else {
            localStorage.setItem("paymentID", "");
            setAdyenFailedMessage(response.message);
            setAdyenSession(checkoutHelper.getAdyenInitState());
            //setShowButton(false);
        }
    }

    const saveRetryPayment = async (order: orderModel.IOrderRequest, adyenSession: checkOutViewModel.IAdyenSession) => {
        let response = await orderService.saveRetryPayment(order, adyenSession);
        if (response.status) {
            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
        }

    }


    useEffect(() => {
        let ignore = false;
        const doInitPayment = async () => {
            let adyenConfig = doGetAdyenConfig();
            const checkout = await AdyenCheckout(adyenConfig);
            if (!isNil(paymentContainer?.current) && !ignore) {
                let customCard = checkout.create('dropin', {    //securedfields, card,dropin
                    onReady: () => {
                        setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Init);
                    },
                }).mount(paymentContainer.current);
                setCustomCardInstance(customCard);
            }
        }

        if (!isNil(adyenSession?.SessionID) && adyenSession?.SessionID != ""
            && !isNil(adyenSession?.SessionData) && adyenSession?.SessionData != "") {
            doInitPayment();
        }

        return () => {
            ignore = true;
        }
    }, [paymentContainer.current, adyenSession]);


    useEffect(() => {
        if (!isNil(paymentContainer.current) && !isNil(adyenSession?.SessionID) && adyenSession?.SessionID != "") {
            //not show "Pay" button before payment section shown up

            setTimeout(() => {
                setShowButton(true);
            }, 500);
        }
        else {
            setShowButton(false);
        }
    }, [paymentContainer.current]);


    useEffect(() => {
        if (dialogVisible) {
            doGetPaymentIDs(selectedOrderDetailsFromStore?.OrderID, selectedOrderDetailsFromStore?.Country);

            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Init);
            setIsShowAdyenRetryButton(false);
            setAdyenFailedMessage("");
        }
        else {
            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
            //setShowButton(false);
        }
    }, [selectedOrderDetailsFromStore, dialogVisible]);

    const toThirdParty = () => {
        window.open('https://www.adyen.com/en_AU/')
    }

    return (
        <>
            <div className='variant-btn-panel'>
                <Button className='variant-btn'
                    onClick={() => onShowDialog()}
                >
                    <h6>{buttonText}</h6>
                </Button>
            </div>
            <Dialog header="Re-try Payment"
                className='order-details-dialog' visible={dialogVisible}
                position='bottom' style={{ maxWidth: '1100px' }}
                draggable={false}
                resizable={true} onHide={() =>
                    onHideDialog()}>
                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div id="customCard-container" ref={paymentContainer} style={{ height: "auto" }}>
                        </div>
                        <div className='third-party-reminder'>
                            Cadillac uses a third party payment provider called Adyen to facilitate payment of your deposit and Cadillac will not collect or store your card information. <a className='thrid-party-link' onClick={() => toThirdParty()}>Learn more</a> about how Adyen processes your transaction and manages your data.
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        {!isNil(adyenFailedMessage) && adyenFailedMessage != "" && (
                            <div className="text-error">{adyenFailedMessage}</div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className='pipeline-btn-panel'>
                            {isShowAdyenRetryButton && (
                                <div className="retry-payment-button-wrapper">
                                    <Button className="pipeline-btn"
                                        onClick={() => {
                                            customCardInstance.update();
                                            customCardInstance.remount();
                                            setIsShowAdyenRetryButton(false);
                                            setAdyenFailedMessage("");

                                            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Init);
                                        }}
                                    >
                                        <span>Re-try Payment</span>
                                    </Button>
                                </div>
                            )}

                            {confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Processing && (
                                <Button type="button" disabled={true} className="pipeline-btn">
                                    <ProgressSpinner
                                        style={{ width: '16px', height: '16px' }}
                                        strokeWidth="5"
                                        animationDuration=".8s"
                                    />
                                </Button>
                            )}

                            {confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Init && showButton && (
                                <Button
                                    className="pipeline-btn"
                                    onClick={() => {
                                        if (customCardInstance.isValid) {
                                            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
                                        }
                                        customCardInstance.submit();
                                    }}
                                >
                                    <span>Pay</span>
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </Dialog>
        </>
    )
}

export default RetryPayment