import React, { useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { IAppState } from 'model/IAppState';

/*import { buttonClickLog } from 'services/Member';*/

import {
    IImagesState,
    getImageLinkByID2,
    getMobileImageLinkByID2,
    getCMSAssets,
    getServiceUrlPara
} from 'helpers/Uri';

import { isNil } from 'lodash';
import moment from 'moment';


export const Footer = () => {
    const navigate = useNavigate();
    
    const [isOpen, setIsOpen] = useState(false);



    const handleActionOpen = () => {
        setIsOpen(!isOpen);
    }

    /*language*/
    useEffect(() => {
    
    }, []);

    //images
    const initImage: IImagesState = {
        imageLoaded: false,
        imageList: []
    }
    const [images, setImages] = useState<IImagesState>(initImage);


    const loginSession = useSelector((state: IAppState) => {
        return state.session;
    });

    const toLatestNews = () => {
        window.open('https://www.cadillacanz.com/au-en/news', '_blank');
    }
    const toRegister = () => {
        window.open('https://www.cadillacanz.com/au-en/register', '_blank');
    } 
    const towhyCadillac = () => {
        window.open('https://www.cadillacanz.com/au-en/why-cadillac', '_blank');
    } 
    
    const toEVLife = () => {
        window.open('https://www.cadillacanz.com/au-en/ev-life', '_blank');
    } 
    const toContactUs = () => {
        window.open('https://www.cadillacanz.com/au-en/contact-us', '_blank');
    } 


    const toWorld = () => {
        window.open('https://www.cadillac.com/worldwide-websites')
    }
    const toCopyright = () => {
        window.open('https://www.gm.com/copyright-trademark')
    }
    const toPrivacyPolicy = () => {
        window.open('https://www.cadillacanz.com/au-en/privacy')
    }

    const toFacebook = () => {
        window.open('https://www.facebook.com/people/Cadillac/100093522254859/')
    }
    const toInstagram = () => {
        window.open(' https://www.instagram.com/cadillacanz/')
    }

    return (
        <div className="footer-container">
                                        
            <div className="footer ">

                <div className='footer-logo-container page-container-content'>
                    <img alt="" src={`${getCMSAssets()}/footer/Cadillac.svg`} />
                </div>

                <div className='footer-content-container'>

                    <div className='footer-get-updates-container'>
                        <div className='checkout-btn-panel'>
                            <Button className='checkout-btn' onClick={() => toRegister()}>
                                GET UPDATES
                            </Button>
                        </div>
                    </div>

                    <div className='footer-links-icons-container'>
                        <div className='footer-links-container'>
                            <a className='link-item' onClick={() => towhyCadillac()}>
                                WHY CADILLAC
                            </a>
                            <a className='link-item' onClick={() => toRegister()}>
                                REGISTER INTEREST
                            </a>
                            <a className='link-item' onClick={() => toEVLife()}>
                                YOUR EV LIFE
                            </a>
                            <a className='link-item' onClick={() => toContactUs()}>
                                CONTACT US
                            </a>
                            <a className='link-item' onClick={() => toLatestNews()}>
                                CADILLAC NEWS
                            </a>
                        </div>
                        <div className='footer-icons-container'>
                            <p>FOLLOW US</p>
                            <div className='footer-icons-panel'>
                                <div className='icon-item' onClick={() => toInstagram()}>
                                    <img alt="" src={`${getCMSAssets()}/footer/Instagram.svg`} />
                                </div> 
                                <div className='icon-item' onClick={() => toFacebook()}>
                                    <img alt="" src={`${getCMSAssets()}/footer/Facebook.svg`} />
                                </div> 

                            </div>
                            
                            {/* <div className='icon-item'>
                                <img alt="" src={`${getCMSAssets()}/footer/Youtube.svg`} />
                            </div>  */}
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='disclaimer-container'>
                <div className='disclaimer-content-container'>
                    <div className='disclaimer-title'>Disclaimers</div>
                    <div className='disclaimer-card-container'>
                        <div className='disclaimer-card'>
                            <div className='disclaimer-card-title'>
                                Range<sup>1</sup>
                            </div>
                            <div className='disclaimer-content'>
                                Based on a full charge. The range of electric vehicles is determined using a uniform measurement procedure (WLTP). Range and energy consumption may vary under real-world conditions depending on driving style and various external factors. It is therefore not always possible to achieve the range according to WLTP. 
                            </div>
                        </div>
                        <div className='disclaimer-card'>
                            <div className='disclaimer-card-title'>
                                Charge to 80%<sup>2</sup>
                            </div>
                            <div className='disclaimer-content'>
                                Based on a 2025 LYRIQ charging with a compatible 190kW DC charger. Actual charge times will vary based on battery condition, output of charger, vehicle settings and outside temperature. See the vehicle's Owner's Manual for additional limitations. 
                            </div>
                        </div>
                        <div className='disclaimer-card'>
                            <div className='disclaimer-card-title'>
                                0-100 km/h<sup>3</sup>
                            </div>
                            <div className='disclaimer-content'>
                                <p style={{marginBottom: '.5rem'}}>Figures based on GM testing in the United States in controlled environments. Actual figures may vary and are subject to environmental conditions.</p>
                                Vehicles shown throughout this experience are simulated local production vehicles intended for illustrative purposes. Cadillac has taken reasonable steps to ensure your digital configuration replicates the vehicle you receive in real life. Colours and other features may appear different in real life.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright-container">
                {/* <p className="copyright">© 2024 GENERAL MOTORS. ALL RIGHTS RESERVED.</p> */}
                <p className="copyright">
                    <span className='copyright-link'>
                        © 2024 GENERAL MOTORS. ALL RIGHTS RESERVED.
                    </span>
                    &nbsp;
                    <span className='copyright-link' onClick={() => toWorld()}>
                        CADILLAC WORLDWIDE
                    </span> &nbsp;
                    <span className='copyright-link' onClick={() => toPrivacyPolicy()}> 
                        PRIVACY POLICY
                    </span> &nbsp;
                    <span className='copyright-link' onClick={() => toCopyright()}>
                        COPYRIGHT &amp; TRADEMARK
                    </span> 
                    
                    {/* <span className='copyright-link' onClick={() => toWorld()}>
                        CADILLAC WORLDWIDE
                    </span> | &nbsp;
                    <span className='copyright-link' onClick={() => toCopyright()}>
                        COPYRIGHT &amp; TRADEMARK
                    </span> |&nbsp;
                    <span className='copyright-link' onClick={() => toPrivacyPolicy()}> 
                        PRIVACY POLICY
                    </span>  */}
                </p>
            </div>
{/* 
            <div className="footer page-container-content">
                <Row className='footer-items-container'>
                    <Col lg={4} className='footer-items-img-box'>
                    </Col>
                    <Col lg={1}>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col lg={4} className='footer-item-card'>
                                <h5>VEHICLE</h5>

                                <p>LYRIQ</p>
                                <p>Configure</p>
                                <p>Test Drive</p>
                                <p>Charge</p>
                            </Col>
                            <Col lg={4} className='footer-item-card'>
                                <h5>CADILLAC</h5>

                                <p>About us</p>
                                <p>Business offering</p>
                            </Col>
                            <Col lg={4} className='footer-item-card'>
                                <h5>CONTACT</h5>

                                <p>Help & Support</p>
                                <p>Newsroom</p>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>

                <Row className='footer-copyright-container'>

                </Row>
                
                <Row className='footer-disclaimers-container'>

                </Row>
            </div> */}
                
        </div>
    );
        
    
};




