import * as countryStateModel from "model/ICountryStateList";

export const SET_COUNTRYSTATES = 'SET_COUNTRYSTATES';
export const setCountryStatesAction = (countryStates: countryStateModel.ICountryStates[]) =>
({
    type: SET_COUNTRYSTATES,
    countryStates,
} as const);


type CountryStatesActions =
    | ReturnType<typeof setCountryStatesAction>;

export const countryStatesReducer = (
    state = <any>null,
    action: CountryStatesActions,
) => {
    switch (action.type) {
        case SET_COUNTRYSTATES: {
            return action.countryStates;
        }
    }

    return state;
};
