import { getBackendPath } from 'helpers/Uri';
import { ILandingPage, ILandingPageRequest } from 'model/ILandingPage';
import * as availableInventoryModel from 'model/IAvailableInventory';
import * as variantModel from 'model/IVehicle';
import * as rdHelper from 'helpers/Uri';

export const getAvailableInventoryFilters = async (
    country: string,
    needDefaultValue: boolean,
    variantRequest: variantModel.IVariantYanaRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Country: country,
            NeedDefaultValue: needDefaultValue,
            Variant: variantRequest
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-available-inventory-filters`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getAvailableInventory = async (
    availableInventory: availableInventoryModel.IAvailableInventoryRequest,
    abortSignal?: any,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(availableInventory),
        credentials: 'include',
        signal: abortSignal,
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-available-inventory-item`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getLandingPage = async (
    landing: ILandingPageRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(landing),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-landing-page`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};

export const getVariants = async (
    requestData: variantModel.IVariantYanaRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-variants`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getExteriors = async (
    requestData: variantModel.IExterior
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-exterior`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getInteriors = async (
    requestData: variantModel.IInterior
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-interior`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getOptions = async (
    requestData: variantModel.IVehicleOptionRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Vehicle/get-vehicle-options`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};