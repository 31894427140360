import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'

const AccountGuidelines = () => {

    const [isShowDialog, setIsShowDialog] = useState(false);

    const onDialogOpen = () => {
        setIsShowDialog(true);
    }

    const onDialogHide = () => {
        setIsShowDialog(false);
    }

    const toPrivacy = () => {
        window.open('https://www.cadillacanz.com/au-en/privacy')
    }

    return (
        <>
            <div className='checkbox-privacy-btn stat-text-link' data-dtm="checkout" onClick={() => onDialogOpen()}>
                <span>Account Guidelines</span>
                <img alt='' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
            </div>
               
             <Dialog className='account-guidelines-dialog' visible={isShowDialog} position='bottom' onHide={() => onDialogHide()} draggable={false} resizable={true}>
                <div className='account-guidelines-container'>
                    {/* <h6>IMPORTANT INFORMATION</h6> */}
                    <div className='account-guidelines-title'>
                        <h1>ACCOUNT GUIDELINES</h1>
                    </div>
                  
                    <div className='account-guidelines-content'>
                        <p>
                            These Guidelines govern your use of accounts across certain websites, mobile apps, and connected vehicle services provided by General Motors Australia and New Zealand Pty Ltd (Australia) 
                            and General Motors New Zealand Ltd (New Zealand), companies incorporated under the laws of Australia and New Zealand, and its affiliates (collectively “GM”, “our”,  “we”, or “Cadillac.”)  
                            By creating or using an account, you agree and accept the following, subject to our <i>Privacy Statement</i> found at  <a onClick={() => toPrivacy()}>www.cadillacanz.com/au-en/privacy</a>. If you do not agree you may not create or use an account.
                        </p>
                        <div className='account-guidelines-section'>
                            <h5>Account</h5>
                            <p>
                                In order to access secure features on our websites, mobile apps, and through connected vehicle services you may be asked to create an account.
                            </p>
                            <p>
                                Please provide accurate and complete information when creating an account. During the creation process, we may send you notifications to verify your e-mail address and telephone number. We may also use your contact information to send you notices and to verify purchases and send you important alerts. Data or messaging rates may apply when receiving notifications via SMS.  For more details, see our Privacy Statement at  <a onClick={() => toPrivacy()}>https://www.cadillacanz.com/au-en/privacy</a>.   
                            </p>
                            <p>
                                You are solely responsible for any activity that occurs with your account, and you must keep your account password secure.  
                            </p>
                            <p>
                                After creating your account, you should notify GM immediately of any breach of security or unauthorized use. GM will not be liable for any losses caused by any unauthorized use of your account. However, you may be liable for losses to GM or other parties due to such unauthorized use.
                            </p>
                            <p>
                                Accounts may be unavailable from time to time and functionally may vary depending on the country where you are attempting to access your account. If you change your country of residence you should notify us and may need to setup a new account where available.
                            </p>
                            <p>
                                If GM reasonably suspects a risk of fraud or misuse by a third party accessing your account, GM may immediately suspend your access.
                            </p>
                        </div>
                        <div className='account-guidelines-section'>
                            <h5>Eligibility</h5>
                            <p>
                                By creating an account, you represent and warrant that you have reached the age of 18 and have full legal capacity to enter into binding contracts. If you do not satisfy these eligibility requirements, you may not create or use an account. You are not permitted to use any account until such time as you satisfy these eligibility requirements. 
                            </p>                         
                        </div>
                        <div className='account-guidelines-section'>
                            <h5>Security and Customer Identity</h5>
                            <p>
                                You may need to set up security passwords, passwords, security questions, and similar information ("Security Information").
                            </p>
                            <span>You agree to:</span>
                            <ul>
                                <li>not share your security information with anyone;</li>
                                <li>not write down your safety information in a way that could be understood by others; and</li>
                                <li>ensure no one sees you enter your security details when making a payment on any website or application, which is associated with GM.</li>
                            </ul>
                            <span>Let us know as soon as possible if:</span>
                            <ul>
                                <li>someone else knows your security information or you believe they do;</li>
                                <li>you discover errors or unauthorized payments; or</li>
                                <li>you believe someone else used or tried to use your payment method.</li>
                            </ul>
                        </div>
                        <div className='account-guidelines-section'>
                            <h5>Additional Terms</h5>
                            <p>
                                On occasion, GM may provide additional terms for products and services that you may obtain through websites, mobile apps, or connected vehicle services provided by GM. When you accept these additional terms, they will govern products and services that you receive through your account.  
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>License</h5>
                            <p>
                                Subject to your compliance with these Guidelines, GM grants you a limited, personal, noncommercial (unless a permitted use in connection with Fleet Vehicles), non-transferable, non-sublicensable, revocable, non-exclusive license to create and lawfully use an account for its intended purpose as described in these Guidelines.  You agree to abide by this license when creating and using an account. GM reserves all rights not expressly granted.
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Restrictions </h5>
                            <ul>
                                <li>You agree not to access an account through any technology or any unauthorized means other than your personal user interactions with our websites, mobile apps, and connected vehicle services;</li>
                                <li>You agree not to use or launch any automated systems, including without limitation, "robots," "spiders," or "offline readers," to accesses our websites, mobile apps, or connected vehicle services through an account; </li>
                                <li>You agree not to use communication systems provided through an account (e.g. SMS text notifications, email alerts, or user comments) to harass other users or for any commercial solicitation; </li>
                                <li>You agree not to circumvent, disable or otherwise interfere with any security-related features or features designed to prevent unauthorized use of an account; and</li>
                                <li>You agree to comply with these Guidelines and all applicable local, national, and international laws and regulations.</li>
                            </ul>
                            <p>
                                Violations of these Guidelines may result in a warning or termination of your account in our sole discretion. 
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Feedback</h5>
                            <p>
                                To help improve the quality of our websites, mobile apps, and connected vehicle services we may provide user feedback channels to allow you to share your experiences.
                            </p>
                            <span>
                                By submitting feedback, you accept the following and agree that you will not use or attempt to use feedback channels for any unlawful purpose, including any purpose that:
                            </span>
                            <ul>
                                <li>Promotes violence, abuse, or harassment to an individual or group of people;</li>
                                <li>Transmits any advertisements, solicitations, schemes, or other unsolicited commercial communications;</li>
                                <li>Encourages child exploitation or otherwise harms minors in any way;</li>
                                <li>Impersonates or otherwise misrepresents your affiliation with any person or entity;</li>
                                <li>Incites illegal or unlawful activities;</li>
                                <li>Violates any third party's intellectual property rights;</li>
                                <li>Infringes any third party's right of privacy; or</li>
                                <li>Unlawfully transmits or uploads any confidential, proprietary or trade secret information.</li>
                            </ul>
                            <p>
                                By submitting feedback, you grant GM a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such feedback in any and all media or distribution methods now known or later developed. You agree that this license includes the right for GM to promote and improve our services and make any feedback you submit available to our affiliates and third parties for syndication, broadcast, distribution, promotion, and publication. No compensation will be paid to you with respect to any feedback you submit and your use of our websites, mobile apps, and connected vehicle services are hereby agreed as being sufficient compensation for the rights granted.
                            </p>
                            <p>
                                You acknowledge and agree that you are solely responsible for any feedback you provide, including compliance with applicable laws, rules, and regulations. 
                            </p>
                            <p>
                                GM does not take responsibility for any user feedback and does not endorse any user feedback or opinions expressed.
                            </p>
                            <p>
                                GM reserves the right at all times, in its sole discretion and without notice to you, to remove any objectionable feedback and to revoke your account for violating these Guidelines.
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Third-Party Links</h5>
                            <p>
                                Certain services provided through your account may contain links to third-party websites that are not owned or controlled by GM, including but not limited to links to websites for GM dealers (collectively “Third-Party Website(s).”) GM has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any Third-Party Website. By clicking on links to third-party websites, you expressly relieve GM from all liability arising from your use of any Third-Party Website, or from the content of any Third-Party Website.
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Warranty Disclaimer</h5>
                            <p>
                                SUBJECT TO ANY NON-EXCLUDABLE CONSUMER GUARANTEES AND OTHER CONSUMER PROTECTION PROVISIONS SET OUT IN THE COMPETITION AND CONSUMER ACT 2010 (CTH) (AUSTRALIA) OR THE CONSUMER GUARANTEES ACT 1993 (NEW ZEALAND) (“NON-EXCLUDABLE CONSUMER GUARANTES”) OR SIMILAR LEGISLATION WHICH CANNOT BE EXCLUDED OR LIMITED, YOU AGREE THAT YOUR USE OF AN ACCOUNT SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW OR ANY NON-EXCLUDABLE CONSUMER GUARANTEES GM AND THEIR RESPECTIVE AFFILIATES AND THE EMPLOYEES, OFFICERS, DIRECTORS, AND AGENTS THEREOF (COLLECTIVELY “PROVIDER PARTY”) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH ACCOUNT SERVICES AND YOUR USE THEREOF. GM MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT ACCOUNT SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF ACCOUNT SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND ANY AND ALL PERSONAL INFORMATION AND FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED THROUGH ACCOUNT SERVICES BY ANY THIRD-PARTY, OR (V) ANY ERRORS OR OMISSIONS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH ACCOUNT SERVICES. 
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Limitation of Liability</h5>
                            <p>
                                SUBJECT TO ANY NON-EXCLUDABLE CONSUMER GUARANTEES AND OTHER APPLICABLE LAW, PROVIDER PARTY SHALL NOT BE LIABLE IN ANY WAY IN CONNECTION WITH ANY ACCOUNT SERVICES FOR ANY INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR AMOUNTS FOR LOSS OF INCOME, PROFITS, OR SAVINGS ARISING OUT OF OR RELATED TO ACCOUNT SERVICES.  
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Indemnity</h5>
                            <p>
                                You agree to defend, indemnify and hold harmless GM, and their parent corporations, subsidiaries, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, reasonable costs or debt, and reasonable expenses (including but not limited to attorney's fees) arising from: (i) your improper, inappropriate or unlawful use  of an account; (ii) your violation of any term of these Guidelines; or (iii) your violation of any third party rights, including without limitation any copyright, trademark, property, publicity or privacy right. This defense and indemnification obligation will survive these Guidelines.
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Assignment</h5>
                            <p>
                                These Guidelines, and any rights granted hereunder, may not be transferred or assigned by you, but may be assigned by GM without restriction.
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>General</h5>
                            <p>
                                If any provision of these Guidelines is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Guidelines, which shall remain in full force and effect. No waiver of any term of these Guidelines shall be deemed a further or continuing waiver of such term, and GM's failure to assert any right or provision under these Guidelines shall not constitute a waiver of such right or provision. GM reserves the right to amend these Guidelines at any time and without notice, and it is your responsibility to review these Guidelines for any changes. Your use of an account following any amendment of these Guidelines will signify your assent to and acceptance of any revised terms. 
                            </p>
                        </div> 
                        <div className='account-guidelines-section'>
                            <h5>Governing Law and Jurisdiction</h5>
                            <p>
                                These Guidelines are governed by the laws of Australia and New Zealand and the Parties agree that the courts of Australia and New Zealand shall have exclusive jurisdiction.
                            </p>
                        </div> 
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default AccountGuidelines