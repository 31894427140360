// This component defines a global scroll function that provides every component which import to use 'useScroll' 
// can redirect to HomePage(with multiple sections) and then automatically scroll to the specific section.
// The necessary for display the preLoader or landing country & state selection Dialog depend on requirements.
// Notice 'd2c-container' div may lose its scrollbar, and need to handle to let it overflow-y: scroll again.
import React, { createContext, useRef, useContext } from 'react';
import * as rdEnum from 'helpers/enum';

interface ScrollContextType {
    scrollToSection: (section: string) => void;
    variantRef: React.RefObject<HTMLDivElement>;
    exteriorRef: React.RefObject<HTMLDivElement>;
    interiorRef: React.RefObject<HTMLDivElement>;
    financeRef: React.RefObject<HTMLDivElement>;
    chargingRef: React.RefObject<HTMLDivElement>;
    paymentRef: React.RefObject<HTMLElement>;
    deliveryComponentRef: React.RefObject<any>;
    deliveryRef: React.RefObject<HTMLDivElement>;
    checkoutRef: React.RefObject<HTMLDivElement>;
    watchlistRef: React.RefObject<HTMLDivElement>;
}

const ScrollToSection = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider = ({ children }: any) => {
    const variantRef = useRef<HTMLDivElement>(null);
    const exteriorRef = useRef<HTMLDivElement>(null);
    const interiorRef = useRef<HTMLDivElement>(null);
    const financeRef = useRef<HTMLDivElement>(null);
    const chargingRef = useRef<HTMLDivElement>(null);
    const paymentRef = useRef<HTMLDivElement>(null);
    const deliveryComponentRef = useRef<any>(null);
    const deliveryRef = useRef<HTMLDivElement>(null);
    const checkoutRef = useRef<HTMLDivElement>(null);
    const watchlistRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (section: string) => {
        switch (section) {
            case rdEnum.enumScrollSection.Variant:
                variantRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Exterior:
                exteriorRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Interior:
                interiorRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Accessories:
                financeRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Charging:
                chargingRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Payment:
                paymentRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Delivery:
                deliveryRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.Checkout:
                checkoutRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case rdEnum.enumScrollSection.CheckoutForm:
                watchlistRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    return (
        <ScrollToSection.Provider value={{ scrollToSection, variantRef, exteriorRef, interiorRef, financeRef, chargingRef, paymentRef, deliveryComponentRef, deliveryRef, checkoutRef, watchlistRef }}>
            {children}
        </ScrollToSection.Provider>
    );
};

export const useScroll = (): ScrollContextType => {
    const context = useContext(ScrollToSection);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
};


