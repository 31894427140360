import { IAddress, IInvalidAddress } from 'model/IAddress';


export const getInitAddress = (): IAddress => {
    let initAddress: IAddress = {
        Address1: "",
        Address2: "",
        Suburb: "",
        State: null,
        Postcode: "",
    };

    return initAddress;
}

export const getInitInvalidAddress = (): IInvalidAddress => {
    let initAddress: IInvalidAddress = {
        InvalidAddress1: null,
        InvalidAddress2: null,
        InvalidSuburb:  null,
        InvalidState:  null,
        InvalidPostcode:  null,
    };

    return initAddress;
}