import { getBackendPath } from 'helpers/Uri';
import * as rdHelper from 'helpers/Uri';

export const getAddress = async (keywords: any): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            keywords: keywords,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/GoogleAPIs/get-address`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getGeometryLocation = async (country: any, address: any): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            country: country,
            address: address,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/GoogleAPIs/get-geometry-location`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};