import { css } from '@emotion/react';
import './App.scss';
import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './stores/Store';
import { HomePage } from './HomePage';
import { ScrollProvider } from './pages/Main/ScrollToSection';
import { fontFamily, fontSize, gray2 } from './Styles';

import {
    BrowserRouter,
    Route,
    useLocation,
    Routes,
    Navigate,
} from 'react-router-dom';


//import { TransitionGroup, CSSTransition } from 'react-transition-group';


import { getFrontendUrl, getFrontendUrlWithSlash } from 'helpers/Uri';
import { ErrorPage } from 'scene/Info/ErrorPage';
import { NotFoundPage } from 'scene/Info/NotFoundPage';

import { setupIonicReact, IonApp } from '@ionic/react';

import { propTypes } from 'react-bootstrap/esm/Image';

//users
import { SignInPage } from 'scene/Users/SignInPage';
import { ComingSoon } from 'pages/Support/ComingSoon';
//import { JoinUsPage } from 'scene/Users/JoinUsPage';
import { ForgotPasswordPage } from 'scene/Users/ForgotPasswordPage';
import { ResetPasswordPage } from 'scene/Users/ResetPasswordPage';
import { EmailVerficationPage } from 'scene/Users/EmailVerficationPage';
import { UpdatePasswordPage } from "scene/Users/UpdatePasswordPage";
import { GoogleAutoCompletePage } from 'scene/Playground/GoogleAutoCompletePage';
//import { AdyenPayment } from 'scene/Playground/AdyenPayment';
import { AuthPage } from 'scene/Users/AuthPage';

/* Theme variables */
import './variables.css';
import { getUserIP } from "services/UserIP";

import ScrollToTop from 'components/ScrollToTop';
import { InventoryPage } from 'pages/InventoryPage';
import OrderPlacedSuccess from 'pages/OrderProgress/OrderPlacedSuccess';
import OrderWaitlisted from 'pages/OrderProgress/OrderWaitlisted';
import NewVehicleInventory from 'pages/Global/inventory/NewVehicleInventory';
import TestDrive from 'pages/Global/testDrive/TestDrive';
import MyOrders from 'pages/Global/orders/MyOrders';
import Profiles from 'pages/Global/profiles/Profiles';
import Privacy from 'pages/Global/privacy/Privacy';
import OrderDetails from 'pages/Global/orders/OrderDetails';


import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from 'helpers/authConfig';
import { compareIssuingPolicy } from 'helpers/claimUtils';
//import GoogleAutoCompletePage from 'scene/Playground/GoogleAutoCompletePage';

const store = configureStore();
setupIonicReact();

declare global {
    interface Window {
        digitalData?: any;
        _satellite: {
            track: (event: string) => void;
            getVar: (name: string) => any;
            [key: string]: any; // Include any other methods you may use
        };
    }
}

function App() {
    //const dispatch = useDispatch();
    const { instance } = useMsal();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event: any) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                //if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                //    // retrieve the account from initial sing-in to the app
                //    const originalSignInAccount = instance
                //        .getAllAccounts()
                //        .find(
                //            (account) =>
                //                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                //                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                //                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                //        );

                //    let signUpSignInFlowRequest = {
                //        authority: b2cPolicies.authorities.signUpSignIn.authority,
                //        account: originalSignInAccount,
                //    };

                //    // silently login again with the signUpSignIn policy
                //    instance.ssoSilent(signUpSignInFlowRequest);
                //}

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                //console.log("app msal");
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                   
                    //console.log("forgotpassword");
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.apiTodoList.scopes.read,
                            ...protectedResources.apiTodoList.scopes.write,
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    //reset password
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);                   
                }
                else if (event.error && event.error.errorMessage.includes('AADB2C90091')) {
                    //cancel reset password, go to home page
                    window.location.href = getFrontendUrl();
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    //useEffect(() => {
    //    // Create a script element
    //    const script = document.createElement("script");
    //    script.type = "text/javascript";

    //    // Optionally, you can add JavaScript code directly within the script tag
    //    script.innerHTML = `_satellite.pageBottom();`;

    //    // Append script to body (near the </body>)
    //    document.body.appendChild(script);

    //    // Cleanup: remove the script when the component is unmounted
    //    return () => {
    //        document.body.removeChild(script);
    //    };
    //}, []);

    return (
        <MsalProvider instance={instance}>
            <Provider store={store}>
                <ScrollProvider>
                    <BrowserRouter>
                        <div
                            css={css`
                        font-family: ${fontFamily};
                        font-size: ${fontSize};
                        color: ${gray2};
                    `}
                        >
                            <ScrollToTop />

                            {process.env.REACT_APP_VIRTUAL_DIR_FRONTEND != "" && (
                                <Routes>
                                    <Navigate to={`${getFrontendUrlWithSlash()}`} />
                                </Routes>
                            )}
                            {/*basename={`${getFrontendUrl()}`}*/}
                            <Routes basename={process.env.REACT_APP_VIRTUAL_DIR_FRONTEND == "" ? undefined : getFrontendUrlWithSlash()} >
                                <Route path="/" element={<HomePage />} />
                                <Route path="/configure" element={<HomePage />} />
                                <Route path="/auth" element={<AuthPage />} />   {/*used by Azure b2c*/}

                                <Route path="/links/:linkKey" element={<HomePage />} />

                                <Route path="coming-soon" element={<ComingSoon />} />
                                <Route path="google-address-autocomplete" element={<GoogleAutoCompletePage />} />
{/*                                <Route path="payment" element={<AdyenPayment />} />*/}

                                <Route path="test-drive" element={<TestDrive />} />
                                <Route path="inventory" element={<InventoryPage />} />
                                {/*<Route path="inventory/:variant/:exterior/:interior/:outOfStockBoxLocation" element={<InventoryPage />} />*/}

                                <Route path="new-vehicle-inventory" element={<NewVehicleInventory />} />

                                <Route path="order-success/:yanaCustomerID/:yanaCustomerNo/:yanaOrderID/:yanaOrderNo/:rdRefNo" element={<OrderPlacedSuccess />} />
                                {/*<Route path="order-waitlisted/:waitlistId/:waitlistNo" element={<OrderWaitlisted />} />*/}
                                <Route path="order-waitlisted/:webkey" element={<OrderWaitlisted />} />

                                <Route path="my-orders" element={<MyOrders />} />
                                <Route path="my-orders/:yanaID/:yanaNo" element={<OrderDetails />} />
                                <Route path="profile" element={<Profiles />} />
                                <Route path="privacy" element={<Privacy />} />

                                <Route path="sign-in" element={<SignInPage />} />    {/*we are using Azure B2C login, this page is not in use anymore*/}
                                {/*<Route path="join-us" element={<JoinUsPage />} />*/}
                                <Route path='forgot-password' element={<ForgotPasswordPage />} />
                                <Route path='email-verification' element={<EmailVerficationPage />} />
                                <Route path='reset-password' element={<ResetPasswordPage />} />

                                <Route path='test' element={<GoogleAutoCompletePage />} />
                                <Route path="error" element={<NotFoundPage />} />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </div>
                    </BrowserRouter>
                </ScrollProvider>
            </Provider>
        </MsalProvider>
    );
}

export default App;

