import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as vehicleModel from 'model/IVehicle';
import { enumChargeType, enumVehicleOptionType } from '../helpers/enum';

export const SET_DEFAULTVEHICLE = 'setDefaultVehicle';
export const setDefaultVehicleAction = (defaultVehicle: selectedConfigModel.ISelectedVehicle ) =>
({
    type: SET_DEFAULTVEHICLE,
    defaultVehicle,
} as const);

export const UPDATE_VARIANT = 'updateVariant';
export const updateVariantAction = (variantID: string, variant: string, variantBasePrice: number) =>
({
    type: UPDATE_VARIANT,
    variantID,
    variant,
    variantBasePrice,
} as const);


export const UPDATE_EXTERIOR = 'updateExterior';
export const updateExteriorAction = (exteriorID: string, exterior: string, exteriorPrice: number) =>
({
    type: UPDATE_EXTERIOR,
    exteriorID,
    exterior,
    exteriorPrice,
} as const);

export const UPDATE_INTERIOR = 'updateInterior';
export const updateInteriorAction = (interiorID: string, interior: string, interiorPrice: number, hasStock: boolean) =>
({
    type: UPDATE_INTERIOR,
    interiorID,
    interior,
    interiorPrice,
    hasStock
} as const);

export const UPDATE_ALLOPTIONS = "updateAllOptions";
export const updateAllOptionsAction = (options: vehicleModel.IVehicleOptions[] | null) =>
({
    type: UPDATE_ALLOPTIONS,
    options,
} as const);

export const UPDATE_OPTION = "updateOption";
export const updateOptionAction = (option: vehicleModel.IVehicleOptions) =>
({
    type: UPDATE_OPTION,
    option,
} as const);

export const CLEAR_SELECTEDCONFIG = "clearSelectedConfig";
export const clearSelectedConfigAction = () =>
({
    type: CLEAR_SELECTEDCONFIG,
} as const);

export type SelectedVehicleActions = ReturnType<typeof setDefaultVehicleAction>
    | ReturnType<typeof updateVariantAction>
    | ReturnType<typeof updateAllOptionsAction>
    | ReturnType<typeof updateOptionAction>
    | ReturnType<typeof updateExteriorAction> | ReturnType<typeof updateInteriorAction>
    | ReturnType<typeof clearSelectedConfigAction>;
export const initSelectedVehicle: selectedConfigModel.ISelectedVehicle = {
    VariantID: null,
    VariantName: null,
    VariantBasePrice: 0,
    ExteriorID: null,
    Exterior: null,
    ExteriorPrice: null,
    InteriorID: null,
    Interior: null,
    InteriorPrice: null,
    Options: null,
    Subtotal: 0,
    HasStock: null,
}

export const selectedVehicleReducer = (
    state = initSelectedVehicle,
    action: SelectedVehicleActions,
) => {
    switch (action.type) {
        case SET_DEFAULTVEHICLE: {
            return action.defaultVehicle;
        }
        case UPDATE_VARIANT: {
            
            let newState: selectedConfigModel.ISelectedVehicle = {
                ...initSelectedVehicle,
                VariantID: action.variantID,
                VariantName: action.variant,
                VariantBasePrice: action.variantBasePrice,
            };
            newState = calculateSubtotal(newState || initSelectedVehicle);
            return newState;
        }
        case UPDATE_EXTERIOR: {
            let newState: selectedConfigModel.ISelectedVehicle = {
                ...state,
                ExteriorID: action.exteriorID,
                Exterior: action.exterior,
                ExteriorPrice: action.exteriorPrice,
            };
            newState = calculateSubtotal(newState || initSelectedVehicle);
            return newState;
        }
        case UPDATE_INTERIOR: {
            let newState: selectedConfigModel.ISelectedVehicle = {
                ...state,
                InteriorID: action.interiorID,
                Interior: action.interior,
                InteriorPrice: action.interiorPrice,
                HasStock: action.hasStock
            };
            newState = calculateSubtotal(newState || initSelectedVehicle);
            return newState;
        }
        case UPDATE_ALLOPTIONS: {
            let newState: selectedConfigModel.ISelectedVehicle = {
                ...state,
                Options: action.options
            };
            newState = calculateSubtotal(newState || initSelectedVehicle);
            return newState;
        }
        case UPDATE_OPTION: {
            let options: vehicleModel.IVehicleOptions[] = state.Options || [];

            const index = options.findIndex((value: vehicleModel.IVehicleOptions) => value.YanaOptionID === action.option.YanaOptionID);
            if (index === -1) {
                options = [...options, action.option];
            }
            else {
                //if unselected public charge option, remove all charge options in the store
                if (action.option.OptionType == enumVehicleOptionType.Charge && action.option.ChargeType == enumChargeType.Public) {
                    options = options.filter((value: vehicleModel.IVehicleOptions) => value?.OptionType != enumVehicleOptionType.Charge);
                }
                else {
                    options = options.filter((value: vehicleModel.IVehicleOptions) => value?.YanaOptionID !== action.option.YanaOptionID);
                }
            }

            let newState: selectedConfigModel.ISelectedVehicle = {
                ...state,
                Options: options
            };

            newState = calculateSubtotal(newState || initSelectedVehicle);

            return newState;
        }
        case CLEAR_SELECTEDCONFIG: {
            return initSelectedVehicle;
        }
        default:
            return state;
    }
};

const calculateSubtotal = (state: selectedConfigModel.ISelectedVehicle): selectedConfigModel.ISelectedVehicle => {
    let subtotal = 0;
    subtotal = (state.VariantBasePrice || 0) + (state.ExteriorPrice || 0) + (state.InteriorPrice || 0);
    if (state.Options != null) {
        state.Options.map((item: vehicleModel.IVehicleOptions) => {
            subtotal = subtotal + (item.OptionPrice || 0);
            //console.log(item);
            //console.log(subtotal);
        });
    }
    return { ...state, Subtotal: subtotal };
    
}