import { IScrollPosition, IMainSection } from "helpers/interface";

export const SET_SCROLL_POSITION = 'SetScrollPosition';
export const setScrollPosition = (positionStatus: string) =>
({
    type: SET_SCROLL_POSITION,
    positionStatus
} as const);

export type ScrollPositionActions = ReturnType<typeof setScrollPosition>;
export const initScrollPositionState: IScrollPosition = {
    positionStatus: 'global'
}

export const scrollPositionReducer = (
    state = initScrollPositionState,
    action: ScrollPositionActions,
) => {
    switch (action.type) {
        case SET_SCROLL_POSITION: {
            return {
                ...state,
                positionStatus: action.positionStatus,
            };
        }
        default:
        return state;
    }
};

export const SET_MAIN_SECTION = 'checkMainSection';
export const checkMainSection = (mainSection: string) =>
({
    type: SET_MAIN_SECTION,
    mainSection
} as const);

export type MainSectionActions = ReturnType<typeof checkMainSection>;
export const initMainSectionState: IMainSection = {
    mainSection: 'global'
}

export const mainSectionReducer = (
    state = initMainSectionState,
    action: MainSectionActions,
) => {
    switch (action.type) {
        case SET_MAIN_SECTION: {
            return {
                ...state,
                mainSection: action.mainSection,
            };
        }
        default:
        return state;
    }
};