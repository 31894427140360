import { getCMSAssets, getFrontendUrl } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import PaymentOptions, { ChildRef } from "components/PaymentOptions";
import { IAppState } from 'model/IAppState';

interface IPaymentTypeProps {
    paymentType: string,
}

const CashFinanceDescDialog = (props: IPaymentTypeProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const paymentOpionsRef = useRef<ChildRef | null>(null);
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const paymentOptionFromStore = useSelector((state: IAppState) => {
        return state.paymentOptions;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const handleDialogHide = () => {
        setIsOpen(false);
    }

    const openCashFinanceDialog = () => {
        if (paymentOpionsRef.current) {
            paymentOpionsRef.current.openDialog();
        }
    };

    return (
        <>
            <div className='cash-finance-desc-container stat-button-link'
                data-dtm="checkout"
                onClick={() => {
                    handleDialogOpen();
                }}
            >
                <span>
                    <img alt='' src={`${getCMSAssets()}/main/File.png`} />
                    What is {props.paymentType}?
                </span>
                <img alt='' src={`${getCMSAssets()}/main/arrow-right-dark-black.svg`} />
            </div>

            <div className='more-about-price stat-text-link' onClick={() => { openCashFinanceDialog(); }} data-dtm="checkout">
                Calculate Finance
                <img alt='' src={`${getCMSAssets()}/main/arrow-black.svg`} />
            </div>

            <Dialog className='cash-finance-desc-dialog' visible={isOpen} position='bottom' onHide={() => handleDialogHide()} draggable={false} resizable={true}>
                {
                    props.paymentType == 'Cash'
                    ?
                    <div className='cash-finance-desc-inner'>
                        <div className='title-panel'>
                            <h1>{props.paymentType.toUpperCase()} INFORMATION</h1>
                            <h3 className='sub-title'>WHAT IS {props.paymentType.toUpperCase()}?</h3>
                            <h6>By choosing a Cash purchase, you will be placing an order to buy the vehicle outright. If you are financing your vehicle with your own bank or financier, you should select cash and we will support you with required paperwork.</h6>
                            <p>To place an order, you will be asked to pay a deposit. When the vehicle is ready for delivery, you will be invoiced to pay the final balance via wire transfer.</p>
                        </div>
                        <div className='features-panel'>
                            <h3 className='sub-title'>HOW DOES A CASH PURCHASE WORK?</h3>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/variants/vehicle.svg`} />
                                <span>1. Vehicle selection: Design and select your Cadillac.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/wallet.svg`} />
                                <span>2. Payment type: Select cash purchase.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/coins.svg`} />
                                <span>3. Deposit: You will be required to pay a deposit, which is deducted from the final payment.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/text-icon.svg`} />
                                <span>4. Final Payment: When the car is ready for delivery, you will be sent a final invoice to pay the remaining balance via wire transfer</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/file.svg`} />
                                <span>5. Vehicle Registration & Delivery: The final step is to register your vehicle and confirm delivery appointment details.</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='cash-finance-desc-inner'>
                        <div className='title-panel'>
                            <h1>{props.paymentType.toUpperCase()} INFORMATION</h1>
                            <h3 className='sub-title'>WHAT IS {props.paymentType.toUpperCase()}?</h3>
                            <p>
                                Finance is also known as a car loan to help you get the keys to your Cadillac. Our finance is powered by trusted lenders Plenti (Australia) and MTF Finance (New Zealand) and together we’ve kept it simple to apply and track your loan. 
                            </p>
                        </div>
                        <div className='features-panel'>
                            <h3 className='sub-title'>HOW DOES FINANCE WORK?</h3>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/variants/vehicle.svg`} />
                                <span>1. Vehicle selection: Design and select your Cadillac.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/clock.svg`} />
                                <span>2. Payment type: Select finance purchase.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/driving.svg`} />
                                <span>3. Deposit: You will be required to pay a deposit, which is deducted from the final amount owed.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/coins.svg`} />
                                <span>4. Application: You will have the opportunity to apply for your loan online, with the support of our trusted lenders. Finance is subject to an approval process, and terms and conditions apply.</span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/checkout/file.svg`} />
                                <span>
                                    5. Payment: When the car is ready for delivery, we will work with you and our trusted partners to arrange final payment ahead of delivery.
                                    {/* <ul>
                                        <li>Depreciation: Difference between the car's initial value and its expected value at the end of the lease term.</li>
                                        <li>Interest or Financing Costs: Interest on that the financing you obtained from the leasing company.</li>
                                        <li>Various taxes and administrative fees.</li>
                                        <li>Maintenance and Insurance: If applicable to your lease, to ensure the car remains in good condition.</li>
                                    </ul> */}
                                </span>
                            </div>
                            <div className='feature-item'>
                                <img alt='' src={`${getCMSAssets()}/variants/vehicle.svg`} />
                                <span>6. Vehicle Registration & Delivery: The final step is to register your vehicle and confirm delivery appointment details.</span>
                            </div>
                        </div>

                        <div className='brand-logos-panel'>
                            <img alt='' src={`${getCMSAssets()}/checkout/brand-logos.png`} />
                        </div>
                    </div>
                }
               
                
            </Dialog>


            <PaymentOptions ref={paymentOpionsRef}
                isFromLandingPage={true}
                subTotal={selectedVehicleFromStore?.Subtotal || 0}
                estDeliveryDate={vehicleFromStore?.SelectedInterior?.Available || ""}
                variantID={selectedVehicleFromStore?.VariantID || ""}
                variantName={selectedVehicleFromStore?.VariantName || ""}
                variantPrice={selectedVehicleFromStore?.VariantBasePrice || 0}
                exteriorID={selectedVehicleFromStore?.ExteriorID || ""}
                exteriorName={selectedVehicleFromStore?.Exterior || ""}
                exteriorPrice={selectedVehicleFromStore?.ExteriorPrice || 0}
                interiorID={selectedVehicleFromStore?.InteriorID || ""}
                interiorName={selectedVehicleFromStore?.Interior || ""}
                interiorPrice={selectedVehicleFromStore?.InteriorPrice || 0}
                options={selectedVehicleFromStore?.Options || []}
            />
        </>
    )
}

export default CashFinanceDescDialog
