import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'

const PrivacyCollectionDialog = ({props}: any) => {

    const [isShowDialog, setIsShowDialog] = useState(false);

    const onDialogOpen = () => {
        setIsShowDialog(true);
    }

    const onDialogHide = () => {
        setIsShowDialog(false);
    }

    const toPrivacy = () => {
        window.open('https://www.cadillacanz.com/au-en/privacy')
    }

    return (
        <>
            {
                props == 'checkout-payment'
                ?
                    <div className='checkbox-privacy-btn stat-text-link' onClick={() => onDialogOpen()} data-dtm="checkout">
                    <span>Cadillac's Privacy Collection Statement</span>
                    <img alt='' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                </div>
                :
                    <span className='test-drive-privacy stat-text-link' onClick={() => onDialogOpen()}
                        data-dtm="privacy statement">Privacy Collection Statement</span>
            }
           

             <Dialog className='checkout-privacy-dialog' visible={isShowDialog} position='bottom' onHide={() => onDialogHide()} draggable={false} resizable={true}>
                <div className='checkout-privacy-container'>
                    <div className='checkout-privacy-title'>
                        <h1>PRIVACY COLLECTION STATEMENT</h1>
                    </div>
                  
                    <div className='checkout-privacy-content'>
                        <p>
                            Cadillac is collecting your personal information for the primary purpose of sending you information in relating to the order of a Cadillac product.
                        </p>
                        <p>
                            If you choose not to provide your personal information to us, we may not be able to provide products to you or answer your enquiries.
                        </p>
                        <p>
                            In some cases, we may disclose your personal information to our related companies, contractors, agents or third party service providers in countries outside of Australia, including United States, the European Economic Area (EEA), and other locations where we or our service providers maintain servers.
                        </p>
                        <p>
                            For further details about our data privacy practices, including your rights to access or correct your information, and the procedures for privacy complaints, please refer to our privacy policy at <a onClick={() => toPrivacy()}>https://www.cadillacanz.com/au-en/privacy</a>.
                        </p>
                        <p>
                            Cadillac’s privacy policy explains how your personal information will be used and disclosed, how you can access or correct your personal information, how you can complain about a privacy breach and how Cadillac will deal with that complaint, and how you can opt out of receiving marketing materials from Cadillac.
                        </p>
                        <p>
                            You can also contact the Cadillac Australia Team at 1800 CADILLAC (223455) / au.care@cadillac.com or New Zealand Team at 0800 CADILLAC (223455) / nz.care@cadillac.com.
                        </p>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default PrivacyCollectionDialog