import React, { useEffect, useState } from 'react'
import { Accordion, Button, Carousel, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils';
import { getCMSAssets } from 'helpers/Uri';
import ImageComparison from 'pages/Main/variant/ImageComparison';
import ComparisonDialog from './ComparisonDialog';
import { currencyFormat, getFrontendUrl } from 'helpers/Uri';
import { isNil } from 'lodash';

import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import * as selectedDeliveryStore from "stores/UserSelectedDelivery";

import { IAppState } from 'model/IAppState';
import * as availableInventoryModel from 'model/IAvailableInventory';
import * as variantModel from 'model/IVehicle';
import * as vehicleService from 'services/Vehicle';
import * as vehicleStore from 'stores/Vehicle';


import ConfidenceDialog from './ConfidenceDialog';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';

const Variant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const vehicleFromStore = useSelector((appState: IAppState) => {
        return appState.vehicle;
    });
    const selectedCustomerType = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const defaultConfigFromStore = useSelector((state: IAppState) => {
        return state.defautConfig;
    });
    const variantListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.VariantList;
    });
    const getExteriorImages = (index: number) => {
        return vehicleFromStore?.SelectedExterior?.ExteriorPicture != null && vehicleFromStore?.SelectedExterior?.ExteriorPicture.length > index
            ? vehicleFromStore?.SelectedExterior?.ExteriorPicture[index] : "";
    }

    const isMobile = useCheckMobileScreen();

    const [selectedVariant, setSelectedVariant] = useState<variantModel.IVariant | null>(null);
    const [variants, setVariants] = useState<variantModel.IVariant[]>([]);


    const [isTypeVisible, setIsTypeVisible] = useState(false);

    useEffect(() => {
        
        if (!isNil(selectedDeliveryFromStore.Country?.code)
            && selectedDeliveryFromStore.Country?.code != "") {
            doGetVariants();
        }

    }, [JSON.stringify({ country: selectedDeliveryFromStore.Country?.code, state: selectedDeliveryFromStore?.State?.code})]);

    useEffect(() => {
        if (!isNil(variants) && variants.length > 0) {

            if (!isNil(defaultConfigFromStore?.VariantName) && defaultConfigFromStore?.VariantName != "") {
                //set default variant from interest link/inventory link
                let updatedVariants = variants.map((item: variantModel.IVariant) => {
                    if (defaultConfigFromStore?.VariantName == item.VariantName) {
                        return {
                            ...item,
                            IsDefault: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            IsDefault: false
                        };
                    }
                });

                let isDefaultVariant = updatedVariants.find(m => m.IsDefault);
                if (isDefaultVariant != null && isDefaultVariant != undefined) {
                    setSelectedVariant(isDefaultVariant);
                    dispatch(selectedConfigStore.updateVariantAction(
                        isDefaultVariant?.VariantID || "",
                        isDefaultVariant?.VariantName || "",
                        isDefaultVariant?.VariantPrice || 0));
                }
                dispatch(vehicleStore.updateVariantAction(updatedVariants, isDefaultVariant || null));
            }
            else {
                //set default variant from variant api
                let isDefaultVariant = variants.find(m => m.IsDefault);
                if (isDefaultVariant != null && isDefaultVariant != undefined) {
                    setSelectedVariant(isDefaultVariant);
                    dispatch(selectedConfigStore.updateVariantAction(
                        isDefaultVariant?.VariantID || "",
                        isDefaultVariant?.VariantName || "",
                        isDefaultVariant?.VariantPrice || 0));
                }
                dispatch(vehicleStore.updateVariantAction(variants, isDefaultVariant || null));
            }
        }
        else {
            setSelectedVariant(null);
            dispatch(selectedConfigStore.updateVariantAction("", "", 0));
            dispatch(vehicleStore.updateVariantAction([], null));
        }

    }, [variants, defaultConfigFromStore?.VariantName])

    useEffect(() => {
        // Trigger fade-in effect when chooseColor.paid changes
        setIsTypeVisible(false);

        // Clear the visibility after the transition duration
        const timeoutId = setTimeout(() => {
            setIsTypeVisible(true)
        }, 260); // Adjust the duration based on your transition time

        // Clear the timeout to avoid unnecessary updates
        return () => clearTimeout(timeoutId);

        ImageComparison();
    }, [selectedVariant]);

    const doGetVariants = async () => {
        let requestData: variantModel.IVariantYanaRequest = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            State: selectedDeliveryFromStore.State?.code || null,
            Postcode: selectedDeliveryFromStore.SelectedDeliveryPostcode,
            Suburb: selectedDeliveryFromStore.SelectedDeliverySuburb || null,
            CustomerType: selectedCustomerType,
        };
        let response = await vehicleService.getVariants(requestData);
        if (response.status) {
            let resData: variantModel.IVariant[] = [];
            if (response.data.length > 0) {
                resData = response.data.map((item: any) => {
                    let returnVariant = {
                        VariantID: item.variantID,
                        VariantName: item.variantName,
                        VariantPrice: item.variantPrice,
                        DriveawayPrice: item.driveawayPrice,
                        Range: item.range,
                        Power: item.power,
                        KmHour: item.kmHour,
                        VariantPicture: item.variantPicture,
                        VariantPictureMobile: item.variantPictureMobile,
                        VariantDescription: item.variantDescription,
                        IsDefault: item.isDefault,
                        HasStock: null,
                    };
                    return returnVariant;
                });
            }
            setVariants(resData);
        }
        else {
            setVariants([]);
            setSelectedVariant(null);
            dispatch(selectedConfigStore.clearSelectedConfigAction());
            dispatch(vehicleStore.clearVehicleAction());
            //dispatch(selectedDeliveryStore.updateEstDeliveryDateAction(null));
        }
    }

    const variantItemTemplate = (item: variantModel.IVariant, index: number) => {

        return (
            <Button
                data-dtm="variant"
                key={"variant_" + index}
                className={classNames({
                    "stat-button-link": true,
                    "variant-btn": true,
                    "selected": item.VariantID == selectedVariant?.VariantID
                })}
                onClick={async () => {

                    setSelectedVariant(item);

                    dispatch(vehicleStore.updateSelectedVariantAction(item));
                    dispatch(selectedConfigStore.updateVariantAction(
                        item?.VariantID || "",
                        item?.VariantName || "",
                        item?.VariantPrice || 0));

                }}
            >
                <h6>{item.VariantName}</h6>
                <span>from {currencyFormat(item.DriveawayPrice)}</span>
            </Button>
        );
    }

    const isFromInventoryPage = (): boolean => {
        if (!isNil(defaultConfigFromStore.VariantName) && defaultConfigFromStore.VariantName != ""
            && (isNil(defaultConfigFromStore.LinkKey) || defaultConfigFromStore.LinkKey == "")) {
            return true;
        }
        return false;
    }

    const onGoToInventory = () => {
        let url = `${getFrontendUrl()}/inventory?shopfront=1`;
        //let url = `${getFrontendUrl()}/inventory?variant=${defaultConfigFromStore.VariantName}`;
        //url += `&exterior=${defaultConfigFromStore.ExteriorName}&interior=${defaultConfigFromStore.InteriorName}`;
        //url += `&shopfront=1`;
        //url += `&selectedCountry=${selectedDeliveryFromStore.Country?.code}&selectedState=${selectedDeliveryFromStore.State?.code}`;
        navigate(url);
    }

    const directionButtons = (direction: any) => {
        return (
            <span
                aria-hidden="true"
                className={direction === "Next" ? "button-next" : "button-prev"}
            >
                <img className="image" src={`${getCMSAssets()}/main/${direction}-round.svg`} />
            </span>
        );
    };

    setTimeout(() => {
        let indicators = document.getElementsByClassName("carousel-indicators");
        for (let i = 0; i < indicators.length; i++) {
            indicators[i].setAttribute('data-dtm', 'gallery');
            indicators[i].classList.add('stat-dots"');
        }
    }, 200);

    return (
        <div className='variant-container'>
            {isFromInventoryPage() && (
                <div className="link-inventory-wrapper stat-button-link"
                    data-dtm="checkout"
                    onClick={() => {
                    onGoToInventory();
                }}>
                    <img alt="" src={`${getCMSAssets()}/inventory/arrow.svg`} />
                    <span>INVENTORY</span>
                </div>
            )}
            {
                isMobile
                ?
                <div className='mobile-variant-header-container'>
                        <Carousel
                        nextIcon={directionButtons("Next")}
                        prevIcon={directionButtons("Prev")}
                        interval={3000}
                    >
                        <Carousel.Item 
                            // onClick={() => handleOpen(0)}
                        >
                            <img className="image" src={getExteriorImages(0)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(1)}
                        >
                            <img className="image" src={getExteriorImages(2)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(2)}
                        >
                            <img className="image" src={getExteriorImages(3)} />
                        </Carousel.Item>
                    </Carousel>
                    <div className='counter-container'>
                        <div className='counter-item'>
                            <div className='counter-title'>Range<sup>1</sup></div>
                            <div className='counter-box'>
                                <div className="counter">{vehicleFromStore?.SelectedVariant?.Range}</div>
                            </div>
                        </div>
                        <div className='counter-item'>
                            <div className='counter-title'>Charge to 80%<sup>2</sup></div>
                            <div className='counter-box'>
                                <div className="counter">{vehicleFromStore?.SelectedVariant?.Power}</div>
                            </div>
                        </div>
                        <div className='counter-item'>
                            <div className='counter-title'>0-100 km/h<sup>3</sup></div>
                            <div className='counter-box'>
                                <div className="counter">{vehicleFromStore?.SelectedVariant?.KmHour}</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <div className='brand-name'>
                <h6>2025</h6>
                <h1>LYRIQ</h1>
            </div>
            <div className='electric-panel'>
                <div className='electric-image-panel'>
                    <img alt='' src={`${getCMSAssets()}/variants/charger.svg`} />
                </div>
                <span>All-Electric</span>
            </div>

            <ConfidenceDialog />

            <h3 className='main-page-section-title'>VARIANT</h3>
            <div className='variant-btn-panel'>
                {!isNil(variantListFromStore) && variantListFromStore != null &&
                    variantListFromStore.length > 0 && (variantListFromStore as variantModel.IVariant[]).map((item: variantModel.IVariant, index: number) => {
                        if (isFromInventoryPage()) {
                            //if from inventory page
                            if (item.VariantName == defaultConfigFromStore.VariantName) {
                                return variantItemTemplate(item, index);
                            }
                            else {
                                return (<></>);
                            }
                        }
                        else {
                            return variantItemTemplate(item, index);
                        }

                    })}
            </div>
            <ComparisonDialog pageName="Configure" />
        </div>
    )
}

export default Variant