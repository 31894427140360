import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';

import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import * as selectedDeliveryStore from "stores/UserSelectedDelivery";
import * as vehicleStore from 'stores/Vehicle';

import * as rdUri from 'helpers/Uri';
import { IAppState } from 'model/IAppState';
import * as vehicleModel from 'model/IVehicle';
import * as vehicleService from 'services/Vehicle';
import { currencyFormat } from 'helpers/Uri';

const Exterior = () => {
    const dispatch = useDispatch();

    //const location = useLocation();
    //const searchParams = new URLSearchParams(location.search);
    //const selectedExteriorNameFromUrl = searchParams.get('selectedExterior');

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const vehicleFromStore = useSelector((appState: IAppState) => {
        return appState.vehicle;
    });
    const defaultConfigFromStore = useSelector((state: IAppState) => {
        return state.defautConfig;
    });
    const exteriorListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.ExteriorList;
    });


    const [selectedExterior, setSelectedExterior] = useState<vehicleModel.IExterior | null>(null);
    const [exteriors, setExteriors] = useState<vehicleModel.IExterior[]>([]);


    const [isPaidVisible, setIsPaidVisible] = useState(false);


    useEffect(() => {
        if (!isNil(vehicleFromStore?.SelectedVariant?.VariantID) && vehicleFromStore?.SelectedVariant?.VariantID != "") {
            doGetExterior();
        }
    }, [vehicleFromStore?.SelectedVariant?.VariantID]);

    useEffect(() => {
        if (!isNil(exteriors) && exteriors.length > 0) {

            if (!isNil(defaultConfigFromStore?.ExteriorName) && defaultConfigFromStore?.ExteriorName != "") {
                //set default exterior from interest link/inventory link
                let updatedExteriors = exteriors.map((item: vehicleModel.IExterior) => {
                    if (defaultConfigFromStore?.ExteriorName == item.ExteriorColorName) {
                        return {
                            ...item,
                            IsDefault: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            IsDefault: false
                        };
                    }
                });

                let isDefaultExterior = updatedExteriors.find(m => m.IsDefault);
                if (isDefaultExterior != null && isDefaultExterior != undefined) {
                    setSelectedExterior(isDefaultExterior);
                    dispatch(selectedConfigStore.updateExteriorAction(
                        isDefaultExterior.ExteriorID || "",
                        isDefaultExterior.ExteriorColorName || "",
                        isDefaultExterior?.ExteriorPrice || 0));
                }
                dispatch(vehicleStore.updateExteriorAction(updatedExteriors, isDefaultExterior || null));

            }
            else {
                //set default exterior from exterior api
                let isDefaultExterior = exteriors.find(m => m.IsDefault);
                //console.log(isDefaultExterior);

                if (isDefaultExterior != null && isDefaultExterior != undefined) {
                    setSelectedExterior(isDefaultExterior);
                    dispatch(selectedConfigStore.updateExteriorAction(
                        isDefaultExterior.ExteriorID || "",
                        isDefaultExterior.ExteriorColorName || "",
                        isDefaultExterior?.ExteriorPrice || 0));
                }
                dispatch(vehicleStore.updateExteriorAction(exteriors, isDefaultExterior || null));
            }
        }
        else {
            dispatch(selectedConfigStore.updateExteriorAction("", "", 0));
            dispatch(vehicleStore.updateExteriorAction([], null));
            setSelectedExterior(null);
        }

    }, [exteriors, defaultConfigFromStore?.ExteriorName])

    const doGetExterior = async () => {
        let requestData: vehicleModel.IExterior = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            VariantID: vehicleFromStore.SelectedVariant?.VariantID || null,
            VariantName: null,
            ExteriorID: null,
            ExteriorColorName: null,
            ExteriorColorDescTitle: null,
            ExteriorColorDesc: null,
            ExteriorPrice: null,
            ExteriorPicture: null,
            ExteriorPictureMobile: null,
            ExteriorFilterImage: null,
            ExteriorFilterMobile: null,
            IsDefault: null,
            HasStock: null,
            ExteriorOrderingNo: 0,
        };

        if (isNil(requestData.VariantID) || requestData.VariantID == "")
            return;

        let response = await vehicleService.getExteriors(requestData);
        if (response.status) {
            let resData: vehicleModel.IExterior[] = [];
            if (response.data.length > 0) {
                resData = response.data.map((item: any) => {
                    return {
                        Country: item.country,
                        VariantID: item.variantID,
                        VariantName: null,
                        ExteriorID: item.exteriorID,
                        ExteriorColorName: item.exteriorColorName,
                        ExteriorColorDescTitle: item.exteriorColorDescTitle,
                        ExteriorColorDesc: item.exteriorColorDesc,
                        ExteriorPrice: item.exteriorPrice,
                        ExteriorPicture: item.exteriorPicture,
                        ExteriorPictureMobile: item.exteriorPictureMobile,
                        ExteriorFilterImage: item.exteriorFilter,
                        ExteriorFilterMobile: item.exteriorFilterMobile,
                        IsDefault: item.isDefault,
                        HasStock: true
                    };
                });
            }
            setExteriors(resData);
        }
        else {
            setExteriors([]);
            setSelectedExterior(null);
            dispatch(selectedConfigStore.updateExteriorAction("", "", 0));
            dispatch(vehicleStore.updateExteriorAction([], null));
            
        }
    }

    useEffect(() => {
        // Trigger fade-in effect when chooseColor.paid changes
        setIsPaidVisible(false)

        // Clear the visibility after the transition duration
        const timeoutId = setTimeout(() => {
            setIsPaidVisible(true);
        }, 260); // Adjust the duration based on your transition time

        // Clear the timeout to avoid unnecessary updates
        return () => clearTimeout(timeoutId);
    }, [selectedExterior?.ExteriorColorName]);


    const exteriorTemplate = (item: vehicleModel.IExterior, index: number) => {
        return (
            <div key={"exterior_" + index}
                data-dtm="exterior"
                className={classNames({
                    "stat-image-link": true,
                    "color-options-item": true,
                    "active": item.ExteriorID == selectedExterior?.ExteriorID
                })}
                onClick={(e: any) => {
                    setSelectedExterior(item);

                    dispatch(vehicleStore.updateSelectedExteriorAction(item || null));
                    dispatch(selectedConfigStore.updateExteriorAction(
                        item?.ExteriorID || "",
                        item?.ExteriorColorName || "",
                        item?.ExteriorPrice || 0));

                    //clear selected accessories & charging when color changed
                    dispatch(selectedConfigStore.updateAllOptionsAction([]));
                }}
            >
                <div className='color-options-round'>
                    <img className="image" src={item?.ExteriorFilterImage || undefined} alt={item.ExteriorColorName || ""} />
                </div>
            </div>
        );
    }


    return (
        <div className='exterior-container'>
            <h3 className='main-page-section-title'>EXTERIOR</h3>

            <div className='title-panel' >
                <p style={{ opacity: isPaidVisible ? 1 : 0, transition: 'opacity .26s ease-in' }}>{isPaidVisible && selectedExterior?.ExteriorColorName || ''}</p>
                <span className='extra-paid' style={{ opacity: isPaidVisible ? 1 : 0, transition: 'opacity .26s ease-in' }}>
                    {isPaidVisible && currencyFormat(selectedExterior?.ExteriorPrice || 0)}
                </span>
            </div>

            <div className='exterior-options-panel'>
                {!isNil(exteriorListFromStore) && exteriorListFromStore != null &&
                    exteriorListFromStore.length > 0 && (exteriorListFromStore as vehicleModel.IExterior[]).map((item: vehicleModel.IExterior, index: number) => {
                        if (!isNil(defaultConfigFromStore.ExteriorName) && defaultConfigFromStore.ExteriorName != ""
                            && (isNil(defaultConfigFromStore.LinkKey) || defaultConfigFromStore.LinkKey == "")) {
                            //if from inventory page
                            if (item.ExteriorColorName == defaultConfigFromStore.ExteriorName) {
                                return exteriorTemplate(item, index);
                            }
                            else {
                                return (<></>);
                            }
                        }
                        else {
                            return exteriorTemplate(item, index);
                        }
                    })}

            </div>

            <div className='desc-content'><span>{selectedExterior?.ExteriorColorDescTitle} </span>{selectedExterior?.ExteriorColorDesc}</div>

        </div>
    )
}

export default Exterior