import { includes, isNil } from 'lodash';

export const getSessionStorageKey = (key: any) =>
    `${key}`;

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(getSessionStorageKey('state'));
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(getSessionStorageKey('state'), serializedState);
    } catch (err) {
        // ignore write error
        //throw new Error("Can't save changes in local storage");
    }
};

export const clearStorageOnDeployment = (
    newVersion: number,
) => {
    let storeData = loadState();
    console.log(storeData);

    //let oldVersions = localStorage.getItem(getSessionStorageKey('storageVersion'));
    //if (isNil(oldVersions)) {
    //    localStorage.clear();
    //    localStorage.setItem(getSessionStorageKey('storageVersion'), newVersion.toString());
    //}
    //else {
    //    let numOlderVersion = parseInt(oldVersions) || 0;
    //    if (numOlderVersion < newVersion) {
    //        localStorage.clear();
    //        localStorage.setItem(getSessionStorageKey('storageVersion'), newVersion.toString());
    //    }
    //    else {
    //        let storeData = loadState();
    //        console.log(storeData);

    //    }
    //}
};

