import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { IAppState } from 'model/IAppState';
import { useDispatch, useSelector } from 'react-redux';
import * as rdEnum from 'helpers/enum';
import * as Uri from 'helpers/Uri';
import { isNil } from 'lodash';

import * as orderService from 'services/Order';
import * as financeStatusModel from 'model/IFinanceStatus';
import * as financeStatusHelper from 'businessHelpers/FinanceHelper';


const FinanceApplication = () => {

    const orderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderDetails;
    });
    //const [dialogVisible, setDialogVisible] = useState(false);
    //const { buttonText } = props;

    const [financeStatus, setFinanceStatus] = useState<financeStatusModel.IFinanceStatus>(financeStatusHelper.getInitFinanceStatus());
    const [isOpen, setIsOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const onHide = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        doGetFinanceStatus();
    }, [orderDetailsFromStore?.YanaCustomerID,
    orderDetailsFromStore?.YanaCustomerNo,
    orderDetailsFromStore?.YanaOrderID,
    orderDetailsFromStore?.YanaOrderNo]);


    const doGetFinanceStatus = async () => {
        let requestData: financeStatusModel.IFinanceStatus = {
            Country: orderDetailsFromStore?.Country || "",
            YanaCustomerID: orderDetailsFromStore?.YanaCustomerID || "",
            YanaCustomerNo: orderDetailsFromStore?.YanaCustomerNo || "",
            YanaOrderID: orderDetailsFromStore?.YanaOrderID || "",
            YanaOrderNo: orderDetailsFromStore?.YanaOrderNo || "",
            FinanceApplicationStepName: null,
            FinanceApplicationStepLink: null
        };

        if (requestData.YanaCustomerID === "" || requestData.YanaCustomerNo === "" || requestData.YanaOrderID === "" || requestData.YanaOrderNo === "")
            return;

        let response = await orderService.getFinanceStatus(requestData);
        if (response.status) {

            requestData = {
                ...requestData,
                FinanceApplicationStepName: response.data.financeApplicationStepName,
                FinanceApplicationStepLink: response.data.financeApplicationStepLink
            };
            setFinanceStatus(requestData);
        }

    }

    return (
        <>
            {/* <div className='variant-btn-panel'>
                <a href={financeStatus.FinanceApplicationStepLink || ""} target="_blank">{financeStatus?.FinanceApplicationStepName}</a>
            </div> */}
            {
                !isNil(financeStatus.FinanceApplicationStepLink) && financeStatus.FinanceApplicationStepLink != ''
                    ?
                    <>
                        <div className='variant-btn-panel'>
                            <Button className='variant-btn'
                                onClick={() => handleDialogOpen()
                                    // if (!isNil(financeStatus.FinanceApplicationStepLink) && financeStatus.FinanceApplicationStepLink != '') {
                                    //     window.open(financeStatus.FinanceApplicationStepLink)
                                    // }
                                }
                            >
                                {financeStatus?.FinanceApplicationStepName}
                            </Button>
                        </div>

                        <Dialog header='NOTICE' className='finance-application-dialog' position='bottom' visible={isOpen} draggable={false} resizable={true} onHide={() => onHide()}>
                            <div className='title-panel'>
                                {
                                    orderDetailsFromStore?.Country == rdEnum.enumCountryCode.Australia
                                        ?
                                        <p>To commence your application. we'll be transferring you to our finance partner, Plenti. This next page is managed by Plenti.</p>
                                        :
                                        <p>To commence your application, we'll be transferring you to our finance partner, MTF. This page is managed by MTF.</p>
                                }
                            </div>
                            <div className='variant-btn-panel'>
                                <Button className='variant-btn'
                                    onClick={() => {
                                        if (!isNil(financeStatus.FinanceApplicationStepLink) && financeStatus.FinanceApplicationStepLink != '') {
                                            window.open(financeStatus.FinanceApplicationStepLink)
                                        }
                                    }}
                                >
                                    CONTINUE
                            </Button>
                            </div>
                        </Dialog>
                    </>
                    :
                    <></>
            }

        </>
    )
}

export default FinanceApplication