import { enumDocumentType } from "helpers/enum";

import * as docModel from 'model/IDocuments';


export const getInitDriveLicenseImage = (): docModel.IDocumentItem => {
    let init: docModel.IDocumentItem = {
        DocumentName: "",
        DocumentType: "",
        Base64: null,
        InvalidDocment: false,
        DocumentKey: null,
        DocumentUrl: null,
        DocumentDownloadUrl: null,
        InvalidReason: null
    };
    return init;
}

export const getInitDriveLicenseImages = (): docModel.IDocumentItem[] => {
    let init: docModel.IDocumentItem[] = [{
        DocumentName: "",
        DocumentType: enumDocumentType.DriveLicenseFront,
        Base64: null,
        InvalidDocment: false,
        InvalidReason: null,
        DocumentKey: null,
        DocumentUrl: null,
        DocumentDownloadUrl: null,
    },
    {
        DocumentName: "",
        DocumentType: enumDocumentType.DriveLicenseBack,
        Base64: null,
        InvalidDocment: false,
        InvalidReason: null,
        DocumentKey: null,
        DocumentUrl: null,
        DocumentDownloadUrl: null,
    }
    ];
    return init;
}