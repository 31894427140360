import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const DesignYourDialog = () => {
    
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const handleDialogHide = () => {
        setIsOpen(false);
    }

   // console.log(isOpen);

    const handleScroll = (event: any) => {
        const scrollTop = event.target.scrollTop;
        setIsScrolled(scrollTop > 50);
      };

   
    const handleDialogShow = () => {
        const dialogContents = document.querySelectorAll('.p-dialog-content');
    
        dialogContents.forEach((dialogContent) => {
            dialogContent.addEventListener('scroll', handleScroll);
        });
    };
    
    //This useEffect used to detect the scrollTop distance and if it larger than 100, 
    //the 'Scroll to learn more' context will disappear
    // Clean up event listeners when the dialog is hidden
    useEffect(() => {
        if (!isOpen) {
        const dialogContents = document.querySelectorAll('.p-dialog-content');
        dialogContents.forEach((dialogContent) => {
            dialogContent.removeEventListener('scroll', handleScroll);
        });
        }
    }, [isOpen]);

    const crossIcon = (
        <button className="p-dialog-titlebar-icon p-link" onClick={() => handleDialogHide()}>
            <img alt="" className='close-icon' src={`${getCMSAssets()}/main/cross-white.svg`} />
        </button>
    )

    return (
        <>
            <div className='design-your-box' onClick={() => {handleDialogOpen()}}>
                <span>DESIGN YOUR</span>
            </div>
        </>
    )
  }

export default DesignYourDialog