import { MainLayoutPage } from 'LayoutPages';
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import NotifyMe from './NotifyMe';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';

const NewVehicleInventory = () => {

    const navigate = useNavigate();

    const [pageState, setPageState] = useState({
        email: '',
        invalidEmail: false
    })

    const handleToHome = () => {
        navigate('/');
    }

    const emailValidator = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pageState?.email);

    if (window.digitalData) {
        window.digitalData = {
            pageInfo: {},
            internalCampaignInfo: {},
            articleInfo: {},
            visitorProfile: {},
            vehicleInfo: {},
            toolsInfo: {}
        };
    }

    return (

        <MainLayoutPage
             sceneId='container-has-header'
        >
            <div className='global-options-container'>
                
                <GlobalMenu activeBtn='inventory' />

                <div className='new-vehicle-inventory-container'>
                    <div className='new-vehicle-inventory-title'>
                        <h1>NEW VEHICLE INVENTORY</h1>
                        <p>We are currently out of stock. Please check back later.</p>
                    </div>
                    <Row className='customize-panel'>
                        {/* <h5>CUSTOMIZE</h5>
                        <h5>YOUR LYRIQ</h5>
                        <div className='variant-btn-panel'>
                            <Button className='variant-btn' 
                                onClick={() => handleToHome()}
                            >
                                <span>BUILD YOURS</span>
                            </Button>
                            <NotifyMe />
                        </div> */}
                        <Col md={5} className='notify-title-panel'>
                            <h4>NOTIFY ME</h4>
                            <p>You will receive an emial when this vehicle arrives to our inventory.</p>
                        </Col>
                        <Col md={7} className='notify-email-panel'>
                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="email" aria-describedby="email-help"
                                        className={classNames({ "p-invalid": pageState?.invalidEmail })}
                                        placeholder=" "
                                        value={pageState.email}
                                        onChange={(e) => {
                                            setPageState({
                                                ...pageState,
                                                email: e.target.value,
                                                invalidEmail: isNil(e.target.value) || e.target.value == "" || emailValidator
                                            })
                                        }}
                                    />
                                    <label>Email *</label>
                                </span>
                                {pageState?.invalidEmail && (
                                    <small id="email-help" className="p-error block">Require Valid Email</small>
                                )}
                            </div>
                            <div className='checkout-btn-panel'>
                                <Button 
                                    onClick={() => {
                                       
                                    }}
                                    data-pr-tooltip="Luxury"
                                >
                                    <h6 >SUBMIT</h6> 
                                    {/* {
                                        drawerShow == true 
                                        ?
                                        <h6>LUXURY AWD</h6>
                                        :
                                        <h6>LUXURY</h6> 
                                    } */}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </MainLayoutPage>
    )
}

export default NewVehicleInventory