import React, { useEffect, useRef } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { css } from '@emotion/react';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';
import { useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';

interface Props {
    title?: string;
    children: React.ReactNode;
    sceneId?: string;
    langScreenID?: string;
    metaKeywords?: string;
    canonicalHref?: string;
}

export const MainLayoutPage = ({ title, children, sceneId, langScreenID, metaKeywords, canonicalHref }: Props) => {

    
    const isScrollbarVisible = useSelector((state: IAppState) => {
        return state.isShowScrollBar.isShowScrollBar
    });


    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.style.overflowY = isScrollbarVisible ? 'scroll' : 'hidden';
            // containerRef.current.style.paddingRight = isScrollbarVisible ? '15px' : '0px';
        }
    }, [isScrollbarVisible]);


    return (
        <HelmetProvider>
            <Helmet>
                {/*<Helmet meta={[{ name: "keywords", content: metaKeywords }]}*/}
                {/*    link={[{ rel: "canonical", href: canonicalHref }]}*/}
                {/*>*/}
                <title>{title}</title>
            </Helmet>
            {/* <div id="divPage" css={css``} className={sceneId}> */}
                {/* <nav className="nav-menu" style={{ height: '106px', position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                    <GlobalMenu  />
                </nav> */}
                <div className='d2c-container' ref={containerRef} >
                    {children}
                </div>
            {/* </div> */}
        </HelmetProvider>
    );

}
