import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as deliveryModel from 'model/IDelivery';
import * as selectedDeliveryHelper from 'businessHelpers/SelectedDeliveryhelper';
import { ICodeName } from 'model/ICommon';

export const UPDATE_DELIVERY = 'UpdateDelivery';
export const updateDeliveryAction = (delivery: selectedConfigModel.ISelectedDelivery) =>
({
    type: UPDATE_DELIVERY,
    delivery,
} as const);

//export const UPDATE_ESTDELIVERYDATE = 'updateEstDeliveryDate';
//export const updateEstDeliveryDateAction = (estDeliveryDate: string | null) =>
//({
//    type: UPDATE_ESTDELIVERYDATE,
//    estDeliveryDate,
//} as const);

export const UPDATE_COUNTRYSTATE = 'updateCountryState';
export const updateCountryAndStateAction = (countryCode: ICodeName, stateCode: ICodeName) =>
({
    type: UPDATE_COUNTRYSTATE,
    countryCode,
    stateCode
} as const);

export const UPDATE_POSTCODE = 'updatePostcode';
export const updatePostcodeAction = (postcode: string | null, suburb: string | null) =>
({
    type: UPDATE_POSTCODE,
    postcode,
    suburb
} as const);

export const UPDATE_SELECTEDPICKUPLOCATION = 'updateSelectedPickupLocation';
export const updateSelectedPickupLocationAction = (location: deliveryModel.IPickupLocation) =>
({
    type: UPDATE_SELECTEDPICKUPLOCATION,
    location,
} as const);

export const UPDATE_SELECTEDHOMEDELIVERY = 'updateSelectedHomeDelivery';
export const updateSelectedHomeDeliveryAction = (homeDeliveryAddress: string,
    homeAddressPostcode: string, homeAddressSuburb: string, homeAddressState: string) =>
({
    type: UPDATE_SELECTEDHOMEDELIVERY,
    homeDeliveryAddress,
    homeAddressPostcode,
    homeAddressSuburb,
    homeAddressState
} as const);

export const UPDATE_DELIVERYOPTIONS = 'updateDeliveryOptions';
export const updateDeliveryOptionsAction = (deliveryOption: string) =>
({
    type: UPDATE_DELIVERYOPTIONS,
    deliveryOption,
} as const);


//export const UPDATE_ESTDELIVERYMONTHYEAR = 'updateEstDeliveryMonthYear';
//export const updateEstDeliveryMonthYearAction = (date: string | null) =>
//({
//    type: UPDATE_ESTDELIVERYMONTHYEAR,
//    date,
//} as const);

//export const VALIDATE_DELIVERY = 'Validate_delivery';
//export const validateDeliveryAction = (invalidOptions: boolean, invalidLocation: boolean) =>
//({
//    type: VALIDATE_DELIVERY,
//    invalidOptions,
//    invalidLocation
//} as const);

//export const VALIDATE_DELIVERY_ADDRESS = 'ValidateDeliveryAddress';
//export const validateDeliveryAddressAction = (invalidLocation: boolean) =>
//({
//    type: VALIDATE_DELIVERY_ADDRESS,
//    invalidLocation
//} as const);

//export const UPDATE_REGOADDRESS = 'updateRegoAddress';
//export const updateRegoAddressAction = (
//    regoAddress: string | null,
//    regoSuburb: string | null,
//    regoPostcode: string | null,
//    regoState: string | null) =>
//({
//    type: UPDATE_REGOADDRESS,
//    regoAddress,
//    regoSuburb,
//    regoPostcode,
//    regoState
//} as const);

export const CLEAR_DELIVERYOPTIONS = 'clearDeliveryOptions';
export const clearDeliveryOptionsAction = () =>
({
    type: CLEAR_DELIVERYOPTIONS,
} as const);


export type SelectedDeliveryActions = ReturnType<typeof updateDeliveryAction>
    //| ReturnType<typeof updateEstDeliveryDateAction>
    | ReturnType<typeof updateCountryAndStateAction>
    | ReturnType<typeof updatePostcodeAction>
    | ReturnType<typeof updateSelectedPickupLocationAction>
    | ReturnType<typeof updateSelectedHomeDeliveryAction>
    | ReturnType<typeof updateDeliveryOptionsAction>
    | ReturnType<typeof clearDeliveryOptionsAction>;

//| ReturnType<typeof updateEstDeliveryMonthYearAction>
//export const initSelectedLocation: selectedConfigModel.ISelectedDelivery = {
//    Country: null,
//    State: null,
//    SelectedPickupLocation: null,
//    SelectedDeliveryOption: null,
//    SelectedDeliveryFullAddress: null,
//    SelectedDeliveryPostcode: null,
//    SelectedDeliveryState: null,
//    SelectedDeliverySuburb: null,
//    //EstDeliveryDate: null,
//    //InvalidLocation: null,
//    //InvalidDeliveryOption: null,
//    PrimaryAddress: null,
//    PrimaryPostcode: null,
//    PrimarySuburb: null,
//    PrimaryState: null,
//    InvalidPrimaryAddress: null,
//    DeliveryAvailable: null,
//    DeliveryPrice: null,
//    DeliveryPriceErrorCode: null,
//    DeliveryDistance: null,
//    PickupLocationName: null,
//}

export const selectedDeliveryReducer = (
    state = selectedDeliveryHelper.getInitSelectedDelivery(),
    action: SelectedDeliveryActions,
) => {
    switch (action.type) {
        case UPDATE_DELIVERY: {
            return action.delivery;
        }
        //case UPDATE_ESTDELIVERYDATE: {
        //    return {
        //        ...state,
        //        EstDeliveryDate: action.estDeliveryDate
        //    };
        //}
        case UPDATE_COUNTRYSTATE: {
            return {
                ...state,
                Country: action.countryCode,
                State: action.stateCode,
                PostCode: null,
                Suburb: null,
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                DeliveryPrice: null,
            };
        }
        case UPDATE_POSTCODE: {
            return {
                ...state,
                PostCode: action.postcode,
                Suburb: action.suburb,
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                DeliveryPrice: null,
            };
        }
        case UPDATE_SELECTEDPICKUPLOCATION: {
            return {
                ...state,
                SelectedPickupLocation: action.location,
                //SelectedHomeDeliveryFullAddress: null,
            };
        }
        case UPDATE_SELECTEDHOMEDELIVERY: {
            return {
                ...state,
                //SelectedPickupLocation: null,
                SelectedHomeDeliveryFullAddress: action.homeDeliveryAddress,
                SelectedHomeDeliveryPostcode: action.homeAddressPostcode,
                SelectedHomeDeliverySuburb: action.homeAddressSuburb,
                SelectedHomeDeliveryState: action.homeAddressState,
            };
        }
        case UPDATE_DELIVERYOPTIONS: {
            return {
                ...state,
                SelectedDeliveryOption: action.deliveryOption,
            };
        }

        //case UPDATE_ESTDELIVERYMONTHYEAR: {
        //    return {
        //        ...state,
        //        EstDeliveryDate: action.date,
        //    };
        //}

        //case UPDATE_REGOADDRESS: {
        //    return {
        //        ...state,
        //        RegoAddress: action.regoAddress,
        //        RegoPostcode: action.regoPostcode,
        //        RegoSuburb: action.regoSuburb,
        //        RegoState: action.regoState,
        //    };
        //}

        case CLEAR_DELIVERYOPTIONS: {
            return selectedDeliveryHelper.getInitSelectedDelivery();
        }
        default:
            return state;
    }
};