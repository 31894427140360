import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap'
import { Calendar } from 'primereact/calendar'
import moment from 'moment';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';

import { IAppState } from 'model/IAppState';
import * as orderService from 'services/Order';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as scheduleDeliveryStore from 'stores/OrderScheduleDelivery';
import * as rdEnum from 'helpers/enum';

const SelectDateTime = () => {
    const dipatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedTime, setSelectedTime] = useState<string | null>(null);
    const [timeList, setTimeList] = useState<string[]>([]);
    const [timeListLoadingStatus, setTimeListLoadingStatus] = useState(rdEnum.enumProcessingStatus.Init);

    //get delivery address, delivery options and yanaIDs
    const orderScheduleDeliveryFromStore: scheduleDeliveryModel.IOrderScheduleDelivery = useSelector((state: IAppState) => {
        return state?.orderScheduleDelivery;
    });

    const doGetTime = async () => {
        let scheduleDelivery: scheduleDeliveryModel.IOrderScheduleDelivery = {
            ...orderScheduleDeliveryFromStore,
            DeliveryFormatDate: moment(selectedDate).format("DD.MM.YYYY"),
        };
        setTimeListLoadingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.getScheduleAvailabeTime(scheduleDelivery || null);
        setTimeListLoadingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            setTimeList(response.data);
        }
    }

    useEffect(() => {
        if (!isNil(orderScheduleDeliveryFromStore) && selectedDate != undefined) {
            doGetTime();
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!isNil(orderScheduleDeliveryFromStore)) {
            if (!isNil(orderScheduleDeliveryFromStore.DeliveryDate) && orderScheduleDeliveryFromStore.DeliveryDate != undefined) {
                setSelectedDate(orderScheduleDeliveryFromStore.DeliveryDate);
            }
            else {
                setSelectedDate(moment(new Date()).add("d", 20).toDate());
            }
            setSelectedTime(orderScheduleDeliveryFromStore.DeliveryTime);
        }
    }, [orderScheduleDeliveryFromStore]);
   
    return (
        <Row className='select-date-time-container'>
            <Col lg={7} className='select-calendar'>
                <Calendar
                    value={selectedDate}
                    minDate={moment(new Date()).add("d", 20).toDate()}
                    onChange={(e: any) => {
                        setSelectedDate(e.value);
                        dipatch(scheduleDeliveryStore.setScheduleDateAction(
                            e.value,
                            moment(selectedDate).format("DD.MM.YYYY"),
                        ));
                    }}
                    inline showWeek
                />
            </Col>
            <Col lg={5} className='select-date-time-context'>
                <div className='schedule-selected-date-container'>
                    <h5>{moment(selectedDate).format("dddd, DD MMMM YYYY")}</h5>
                </div>
                <div className='schedule-options-container'>
                    {(timeListLoadingStatus == rdEnum.enumProcessingStatus.Processed && (isNil(timeList) || timeList.length == 0)) && (
                        <div className="text-error">No available time found.</div>
                    )}
                    {!isNil(timeList) && timeList.length > 0 && timeList.map((time: any, index: number) => {
                        return (
                            <div className='schedule-options-item' key={"time_" + index.toString()}>
                                <div className={classNames({
                                    "schedule-options-inner": true,
                                    "selected": time == selectedTime
                                })}
                                    onClick={() => {
                                        setSelectedTime(time);
                                        dipatch(scheduleDeliveryStore.setScheduleTimeAction(time));
                                    }}
                                >
                                    <span>{time}</span>
                                </div>
                            </div>
                        );

                    })}
                </div>
                <div className='need-support-container'>
                    <h6>Need support?</h6>
                    <p>If you wish to change your delivery option</p>
                    <span>Please call 1800 CADILLAC (223455) for assistant.</span>
                </div>
            </Col>
        </Row>

    )
}

export default SelectDateTime