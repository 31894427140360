import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { IAppState } from 'model/IAppState';
import { useDispatch, useSelector } from 'react-redux';
import { enumCountryCode, enumDeliveryOptions } from 'helpers/enum';
import * as Uri from 'helpers/Uri';
import { isNil } from 'lodash';
import * as rdEnum from 'helpers/enum';

const ArrangePayment = (props: any) => {

    const orderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderDetails;

    });

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const [dialogVisible, setDialogVisible] = useState(false);
    const { buttonText } = props;

    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }

    const dueDateTemplate = () => {
        if (orderDetailsFromStore?.DeliveryOption == enumDeliveryOptions.HomeDelivery) {
            return (<span>Full payment is required prior to scheduling your regional delivery.</span>)
        }
        else if (orderDetailsFromStore?.DeliveryOption == enumDeliveryOptions.Pickup) {
            if (isNil(orderDetailsFromStore?.PaymentDueDate) || orderDetailsFromStore?.PaymentDueDate == "") {
                return (<span>Full payment is required at least 3 working days before your scheduled delivery date.</span>)
            }
            else {
                return (<span>You have until {orderDetailsFromStore?.PaymentDueDate} to complete the payment. Kindly ensure the payment is made by the end of the day to avoid any delays in your order.</span>)
            }
        }
        return (<></>);
    }


    return (
        <>
            <div className='variant-btn-panel'>
                <Button className='variant-btn' 
                    onClick={() => onShowDialog()}
                >
                    {buttonText}
                </Button>
            </div>

            <Dialog header="ARRANGE PAYMENT" className='order-details-dialog' visible={dialogVisible} position='bottom' style={{ maxWidth: '1100px' }} draggable={false} resizable={true} onHide={() => onHideDialog()}>
                {
                     selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                     ?
                     <div>
                        {/* <div className='title-panel'>
                            <h1>ARRANGE PAYMENT</h1>
                        </div> */}
                        <div className='bank-details-container'>
                            <h6 className='arrange-title'>Cadillac’s Bank Details: </h6>
                            <p>Account Name: GM ANZ Pty Ltd</p>
                            <p>BSB Number: 232001</p>
                            <p>Account Number: 17641050</p>
                            <p>Bank Name: Bank of America NA</p>
                            <p>Payment Reference: Please include your Order Number (1132 00 899) as the payment reference to ensure smooth processing. </p>
                        </div>
                        <p>Note: Cadillac is a business of General Motors Australia & New Zealand (GM ANZ Pty Ltd).</p>
                        {/* <div className='detail-section'>
                            <h6>Payment Reference:</h6>
                            <span>Please include your Order Number (1132 00 899) as the payment reference to ensure smooth processing.</span>
                        </div> */}
                        <div className='detail-section'>
                            <h6>Due Date:</h6>
                            {/* {dueDateTemplate()} */}
                            <span>Full payment is required a minimum of 3 business days prior to your scheduled delivery date.  Please ensure you have made your payment, including processing days, prior to this deadline so we can deliver your Cadillac.. </span>
                        </div>
                        <div className='detail-section'>
                            <h6>Final Payment Amount</h6>
                            {/* <span>{Uri.currencyPlainFormat(orderDetailsFromStore?.FinalPaymentAmount || 0)}</span> */}
                            <span>$20,000</span>
                        </div>
                        <div className='detail-section'>
                            <h6>Payment Method:</h6>
                            {/* <span>{orderDetailsFromStore?.PaymentOption}</span> */}
                            <span>Finance or Cash</span>
                        </div>
                        <p>If you have any questions relating to your payment,  you can request a call from our Cadillac Sales team. (Clickable link)</p>
                    </div>
                    :
                    <div>
                        {/* <div className='title-panel'>
                            <h1>ARRANGE PAYMENT</h1>
                        </div> */}
                        <div className='bank-details-container'>
                            <h6 className='arrange-title'>Cadillac’s Bank Details: </h6>
                            <p>Account Name: GM NZ Ltd </p>
                            <p>BSB Number: 312840</p>
                            <p>Account Number: 0044304-06</p>
                            <p>Bank Name: Citibank NA</p>
                            <p>Payment Reference: Please include your Order Number (1132 00 899) as the payment reference to ensure smooth processing. </p>
                        </div>
                        <p>Note: Cadillac is a business of General Motors New Zealand (GM NZ Ltd).</p>
                        {/* <div className='detail-section'>
                            <h6>Payment Reference:</h6>
                            <span>Please include your Order Number (1132 00 899) as the payment reference to ensure smooth processing.</span>
                        </div> */}
                        <div className='detail-section'>
                            <h6>Due Date:</h6>
                            {/* {dueDateTemplate()} */}
                            <span>Full payment is required a minimum of 3 business days prior to your scheduled delivery date.  Please ensure you have made your payment, including processing days, prior to this deadline so we can deliver your Cadillac.. </span>
                        </div>
                        <div className='detail-section'>
                            <h6>Final Payment Amount</h6>
                            {/* <span>{Uri.currencyPlainFormat(orderDetailsFromStore?.FinalPaymentAmount || 0)}</span> */}
                            <span>$20,000</span>
                        </div>
                        <div className='detail-section'>
                            <h6>Payment Method:</h6>
                            {/* <span>{orderDetailsFromStore?.PaymentOption}</span> */}
                            <span>Finance or Cash</span>
                        </div>
                        <p>If you have any questions relating to your payment,  you can request a call from our Cadillac Sales team. (Clickable link)</p>
                    </div>
                }
               
            </Dialog>
        </>
    )
}

export default ArrangePayment