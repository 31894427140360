import { IGlobalDropdown } from "helpers/interface";

export const SET_GLOBAL_DROPDOWN = 'setGlobalDropdown';
export const setGlobalDropdown = (vehicles: boolean, shop: boolean) =>
({
    type: SET_GLOBAL_DROPDOWN,
    vehicles,
    shop,
} as const);

export type GlobalDropdownActions = ReturnType<typeof setGlobalDropdown>;
export const initGlobalDropdownState: IGlobalDropdown = {
    vehicles: false,
    shop: false
}

export const globalDropdownReducer = (
    state = initGlobalDropdownState,
    action: GlobalDropdownActions,
) => {
    switch (action.type) {
        case SET_GLOBAL_DROPDOWN: {
            return {
                ...state,
                vehicles: action.vehicles,
                shop: action.shop
            };
        }
        default:
        return state;
    }
};