import { getCMSAssets } from 'helpers/Uri';
import { IChargingDialogProps } from 'model/IChargingDialogProps';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Button, Carousel, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as vehicleModel from 'model/IVehicle';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import { IAppState } from 'model/IAppState';
import { currencyFormat } from 'helpers/Uri';
import { isNil } from 'lodash';

const ChargingDialog = (props: vehicleModel.IVehicleOptions) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    let removeFirstItemList = props.OptionPicture?.length > 1 ?  props.OptionPicture.slice(1) : props.OptionPicture;

    // const [tempProps, setTempProps] = useState<string[]>([]);

    // useEffect(() => {
    //     if (props.OptionPicture?.length > 1) {
    //         setTempProps(props.OptionPicture?.splice(1, 2));
    //     } else {
    //         setTempProps(tempProps);
    //     }
    // }, [])

    const selectedOptionsFromStore: vehicleModel.IVehicleOptions[] | null = useSelector((state: IAppState) => {
        return state.selectedVehicle.Options;
    });

    const isAddOrRemove = selectedOptionsFromStore?.some((option: any) =>
        option !== props && option.OptionName === props.OptionName
    );

    const handleDialogOpen = () => {
        setIsOpen(true);
        document.body.style.overflowY = 'hidden';
        document.body.style.paddingRight = '15px';
    }

    const handleDialogHide = () => {
        setIsOpen(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
    }

    const handleAdd = (item: vehicleModel.IVehicleOptions) => {

        dispatch(selectedConfigStore.updateOptionAction(item));
        setIsOpen(false);
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0px';
       
    }

    const directionButtons = (direction: any) => {
        return (
            <span
                aria-hidden="true"
                className={"stat-arrow-prev " + direction === "Next" ? "button-next" : "button-prev"}
                data-dtm={"modal:" + props.OptionName}
            >
                <img className="image" src={`${getCMSAssets()}/main/${direction}-round.svg`} />
            </span>
        );
    };

    return (
        <>
            <div className='charging-popup-container stat-text-link'
                data-dtm={"charging:" + props.OptionName?.toLocaleLowerCase()}
                onClick={() => {
                    handleDialogOpen();
                }}
            >
                <span>Details</span>
                <img alt="" src={`${getCMSAssets()}/main/arrow-right-gray.svg`} />
                {/*<img alt='' src={removeFirstItemList.length > 0 ? removeFirstItemList[0] : ""} />*/}
            </div>

            <Dialog header={props.OptionName?.includes('Public Charging') 
                            || props.OptionName?.includes('Public Charger')
                            ? 'PUBLIC CHARGING' 
                            : props.OptionName?.includes('Home Charging') 
                            || props.OptionName?.includes('Home Charger')
                            ? 'HOME CHARGING' 
                            : 'CHARGING'}
                className='charging-popup-dialog'
                visible={isOpen}
                position='bottom'
                onHide={() => handleDialogHide()}
                draggable={false} resizable={true}>
                <Row className='charging-popup-content-container'>
                    <Col md={7} className='charging-popup-carousel-wrapper'>
                    {
                        // tempProps?.length > 1
                        removeFirstItemList?.length > 1
                        ?
                        <Carousel
                            nextIcon={directionButtons("Next")}
                            prevIcon={directionButtons("Prev")}
                        >
                        {removeFirstItemList.map((item: any, index: number) => {
                            return (
                                <div key={item + index} className='carousel-item'>
                                    <img className="image" src={item} />
                                </div>
                            );
                        })}
                        </Carousel>
                        :
                        // tempProps?.length == 1
                        removeFirstItemList?.length == 1
                        ?
                        <div className='img-item'>
                            <img className="image" src={removeFirstItemList?.[0]} />
                        </div>
                        :
                        <></>
                    }
                       
                        
                    </Col>

                    <Col md={5} className='charging-popup-content-wrapper'>
                        <div className='content-title-desc-container'>
                            <h5>{props.OptionName}</h5>
                            <p>{props.OptionDesc}</p>
                        </div>
                        <div className='bottom-mgmt-container'>
                            {/* <p>Disclaimer</p> */}
                            <div className='price-panel'>
                                <span>Price</span>
                                <span>{currencyFormat(props.OptionPrice || 0)}</span>
                            </div>
                            <Button className='general-btn stat-button-link' onClick={() => handleAdd(props)}
                                style={{ backgroundColor: isAddOrRemove ? '#fff' : '#282828' }}
                                data-dtm={"modal:" + props.OptionName?.toLocaleLowerCase()}
                            >
                                {
                                    isAddOrRemove
                                    ?
                                    <span style={{color: '#282828'}}>REMOVE</span>
                                    :
                                    <span>ADD</span>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                {
                    props.OptionName?.includes('Public Charging') 
                    || props.OptionName?.includes('Public Charger')
                    ?
                    <div className='charging-disclaimer'>
                        The public charging offer is provided by Chargefox. The use of the Chargefox charging network is subject to Chargefox Network T&Cs. Fair use policy applies. View full T&Cs when placing your order
                    </div>
                    :
                    props.OptionName?.includes('Home Charging') 
                    || props.OptionName?.includes('Home Charger')
                    ?
                    <div className='charging-disclaimer'>
                        Hardware and installation is provided by JET Charge. View order T&Cs when completing your purchase for more information.
                    </div>
                    :
                    <></>
                }
                
            </Dialog>
        </>
    )
}

export default ChargingDialog