import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import { ICodeName } from 'model/ICommon';
import * as countryStateModel from "model/ICountryStateList";
import { isNil } from 'lodash';
import { getCountryAndState } from 'services/CountryState';
import * as countryStateStore from 'stores/CountryStateList';
import { useEffect, useState } from "react";
import * as rdEnum from 'helpers/enum';

export const useCountryState = () => {
    const dispatch = useDispatch();

    const countryAndStates = useSelector((state: IAppState) => {
        return state.countryStateList;
    });

    const doGetCountryAndState = async (): Promise<countryStateModel.ICountryStates[]> => {
        let dataInStore: countryStateModel.ICountryStates[] = [];
        let response = await getCountryAndState();
        if (response.status) {
           
            let countryListState: ICodeName[] = [];
            if (!isNil(response.data) && response.data.length > 0) {
                response.data.map((itemData: any) => {
                    let states: ICodeName[] = [];
                    if (!isNil(itemData.state) && itemData.state.length > 0) {
                        itemData.state.map((stateData: any) => {
                            states.push({
                                name: stateData,
                                code: stateData,
                            });
                        });
                    }
                    else {
                        states = rdEnum.constStateList;   //if api does not return any states, use hardcode states
                    }
                    let countryName = itemData.country == "AU" ? "Australia" : "New Zealand";
                    dataInStore.push({
                        Country: {
                            name: countryName,
                            code: itemData.country
                        },
                        State: states,
                    });

                    countryListState.push({
                        name: countryName,
                        code: itemData.country
                    });
                });

            }
            //setCountry(countryListState);
            dispatch(countryStateStore.setCountryStatesAction(dataInStore));
        }
        return dataInStore;

    }


    const getCountry = async() => {
        let returnCountry: ICodeName[] = [];
        if (isNil(countryAndStates) || countryAndStates.length == 0) {
            let allCountryAndStates= await doGetCountryAndState();
            if (!isNil(allCountryAndStates) && allCountryAndStates.length > 0) {
                allCountryAndStates.map((item: countryStateModel.ICountryStates) => {
                    returnCountry.push(item.Country);
                });
            }

        }
        else {
            countryAndStates.map((item: countryStateModel.ICountryStates) => {
                returnCountry.push(item.Country);
            });
        }
        return returnCountry;
    }

    const getStates = async(countryCode: string) => {
        let stateList: ICodeName[] = [];
        if (!isNil(countryAndStates)) {
            let findCountry = countryAndStates.find(m => m.Country.code == countryCode);

            if (findCountry != null) {
                return findCountry.State || [];
            }
        }
        //else {
        /// do not add below code back as it could be called multiple times
        //    let allCountryAndStates = await doGetCountryAndState();
        //    let findCountry = allCountryAndStates.find(m => m.Country.code == countryCode);

        //    if (findCountry != null) {
        //        return findCountry.State || [];
        //    }
        //}
        return stateList;
    }

    return {
        getCountry,
        getStates
    };
}
