import React, { useEffect, useRef, useState } from 'react';
import { Footer } from 'scene/Layout/Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayoutPage } from 'LayoutPages';
import {
    getCMSAssets,
    getFrontendUrl,
    getFrontendUrlWithSlash
} from 'helpers/Uri';
import { IAppState } from 'model/IAppState';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { MainMenu } from 'scene/Layout/MainMenu';
import { InventoryMenu } from 'scene/Layout/InventoryMenu';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import CarCarousel from 'components/CarCarousel';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { includes, isNil } from 'lodash';

import * as rdEnum from 'helpers/enum';
import { ICodeName } from 'model/ICommon';
import * as availableInventoryModel from 'model/IAvailableInventory';
import * as vehicleModel from 'model/IVehicle';
import * as interestModel from 'model/IInterest';
import * as variantModel from 'model/IVehicle';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as defaultConfigModel from 'model/IDefaultConfig';
import * as vehicleSerivce from "services/Vehicle";
import * as interestService from 'services/Interest';
import * as availableInventoryHelper from "businessHelpers/AvailableInventoryHelper";


import * as selectedDeliveryStore from "stores/UserSelectedDelivery";
import * as paymentOptionsStore from "stores/PaymentOptions";
import * as customerTypeStore from "stores/CustomerType";
import * as defaultConfigStore from 'stores/DefaultConfig';
import * as linkSourceStore from "stores/LinkSource";

import { setScrollPosition } from 'stores/ScrollPosition';
import { setMainPageNumber } from 'stores/MainPageNumber';
import { useScroll } from './Main/ScrollToSection';
import { hideGlobalPage } from 'stores/MainRightPage';
import { Dialog } from 'primereact/dialog';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';
import { setCountryStateAction } from 'stores/Session';
import { useCountryState } from 'helpers/useCountryState';
import SaveDialog from './Main/checkout/SaveDialog';
import ComparisonDialog from './Main/variant/ComparisonDialog';

import { BsXCircle } from "react-icons/bs";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { setGlobalDropdown } from 'stores/GlobalDropdown';

export const InventoryPage = () => {

    const navigate = useNavigate();

    //const { variant, exterior, interior, outOfStockBoxLocation } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const defaultSelectedVariant = searchParams.get('variant');     //name
    const defaultSelectedExterior = searchParams.get('exterior');   //name
    const defaultSelectedInterior = searchParams.get('interior');   //name
    const isShopFront = searchParams.get('shopfront');              //if is from shopfront
    const outOfStockBoxLocation = searchParams.get('outOfStockBoxLocation');
    const isMobile = useCheckMobileScreen();

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectedCustomerType = useSelector((state: IAppState) => {
        return state.customerType;
    });

    const [drawerShow, seDdrawerShow] = useState(true);


    const [selectedVariants, setSelectedVariants] = useState<availableInventoryModel.IAISelectedVariant[]>([]);
    const [selectedExteriors, setSelectedExteriors] = useState<availableInventoryModel.IAISelectedExterior[]>([]);
    const [selectedInteriors, setSelectedInteriors] = useState<availableInventoryModel.IAISelectedInterior[]>([]);

    const [availableInventoryList, setAvailableInventoryList] = useState<availableInventoryModel.IAvailableInventory[]>([]);
    const [availableRequest, setAvailabeRequest] = useState<availableInventoryModel.IAvailableInventoryRequest>(availableInventoryHelper.getAvailableRequest());



    const [sort, setSort] = useState<ICodeName>();

    const [filterQty, setFilterQty] = useState(0);

    const [variants, setVariants] = useState<vehicleModel.IVariant[]>([]);
    const [exteriors, setExteriors] = useState<vehicleModel.IExterior[]>([]);
    const [interiors, setInteriors] = useState<vehicleModel.IInterior[]>([]);
    // const [exteriors, setExteriors] = useState<vehicleModel.IExterior[]>([]);

    const [stock, setStock] = useState<availableInventoryModel.IAvailableInventoryStock[]>([]);


    const [searchResultLoadingStatus, setSearchResultLoadingStatus] = useState(rdEnum.enumProcessingStatus.Init);

    const [notifyEmail, setNotifyEmail] = useState("");
    const [invalidNotifyEmail, setInvalidNotifyEmail] = useState(false);
    const [notifyProcessStatus, setNotifyProcessStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [notifyMeMessage, setNotifyMeMessage] = useState("");
    const [notifyMeError, setNotifyMeError] = useState(false);

    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const abortControllerInitialValue: any = new (
        window as any
    ).AbortController();
    const [inventoryAbortController, setInventoryAbortController] = useState(
        abortControllerInitialValue,
    );

    var currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    //const handleBtnClick = () => {
    //    navigate(`/${getFrontendVirtualDir()}`)
    //}

    const handleDrawerOpen = () => {
        seDdrawerShow(true);
    }

    const handleDrawerClose = () => {
        seDdrawerShow(false);
    }

    const handleVariant = (variantID: string, variantName: string) => {
        let updateSelectedVariants: availableInventoryModel.IAISelectedVariant[] = [];
        const index = selectedVariants.findIndex((value: availableInventoryModel.IAISelectedVariant) => value.SelectVariantID === variantID);
        if (index === -1) {
            let newItem: availableInventoryModel.IAISelectedVariant = {
                SelectVariantID: variantID || "",
                SelectVariantName: variantName || ""
            };
            updateSelectedVariants = [...selectedVariants, newItem];
        }
        else {
            updateSelectedVariants = selectedVariants.filter((value: availableInventoryModel.IAISelectedVariant) => value.SelectVariantID != variantID);
        }
        setSelectedVariants(updateSelectedVariants);


        //exteriors
        let exteriorList: vehicleModel.IExterior[] = getExteriorList(stock, updateSelectedVariants);
        setExteriors(exteriorList);
        setSelectedExteriors([]);

        //interiors
        let interiorList: vehicleModel.IInterior[] = getInteriorList(stock, updateSelectedVariants, []);
        setInteriors(interiorList);
        setSelectedInteriors([]);



    }


    const handleExteriorColor = (exteriorName: string) => {

        let updateSelectedExteriors: availableInventoryModel.IAISelectedExterior[] = [];
        const index = selectedExteriors.findIndex((value: availableInventoryModel.IAISelectedExterior) => value.SelectExteriorName === exteriorName);
        if (index === -1) {
            let newItem: availableInventoryModel.IAISelectedExterior = {
                SelectExteriorID: "",
                SelectExteriorName: exteriorName
            };
            updateSelectedExteriors = [...selectedExteriors, newItem];
        }
        else {
            updateSelectedExteriors = selectedExteriors.filter((value: availableInventoryModel.IAISelectedExterior) => value.SelectExteriorName != exteriorName);
        }
        setSelectedExteriors(updateSelectedExteriors);


        let interiorList: vehicleModel.IInterior[] = getInteriorList(stock, selectedVariants, updateSelectedExteriors);
        setInteriors(interiorList);
        setSelectedInteriors([]);
    }

    const handleInteriorColor = (interiorName: any) => {

        let updateSelectedInteriors: availableInventoryModel.IAISelectedInterior[] = [];
        const index = selectedInteriors.findIndex((value: availableInventoryModel.IAISelectedInterior) => value.SelectInteriorName === interiorName);
        if (index === -1) {
            let newItem: availableInventoryModel.IAISelectedInterior = {
                SelectInteriorID: "",
                SelectInteriorName: interiorName
            };
            updateSelectedInteriors = [...selectedInteriors, newItem];
        }
        else {
            updateSelectedInteriors = selectedInteriors.filter((value: availableInventoryModel.IAISelectedInterior) => value.SelectInteriorName !== interiorName);
        }
        setSelectedInteriors(updateSelectedInteriors);
    }

    const handleRestFilter = () => {

        setSelectedVariants([]);
        setSelectedExteriors([]);
        setSelectedInteriors([]);

    }

    const handleRemoveVariantFilter = () => {

    }

    const handleMobileFilterClick = () => {
        setIsButtonClicked(true);
        handleFilterDialogClose();
    };

    const handleFilterDialogOpen = () => {
        setFilterDialogVisible(true)
    }

    useEffect(() => {
        //To estimate the quantity of filter conditions
        //let score = selectedFilter.SelectedVariantsID.length;

        //if (selectedFilter.SelectedExteriorName !== '') {
        //    score += 1;
        //}
        //if (selectedFilter.SelectedInteriorName !== '') {
        //    score += 1;
        //}
        let score = selectedVariants.length + selectedExteriors.length + selectedInteriors.length;
        setFilterQty(score);
    }, [selectedVariants, selectedExteriors, selectedInteriors]);

    const onSortChange = (e: any) => {
        setSort(e.value);
    }

    //useEffect(() => {
    //    //1st loading page, get filers
    //    if (!isNil(selectedDeliveryFromStore.Country?.code) && selectedDeliveryFromStore.Country?.code != "") {
    //        setDialogVisible(false);
    //        doGetAvailableInventoryFilter();
    //    }
    //    else {
    //        setDialogVisible(true);
    //    }
    //}, [selectedDeliveryFromStore]);

    useEffect(() => {
        if (isMobile) {
            if (isButtonClicked) {
                if (!isNil(selectedDeliveryFromStore.Country?.code) && selectedDeliveryFromStore.Country?.code != "") {
                    buildAvailableRequest(); // doGetAvailableInventory();
                }
                setIsButtonClicked(false);
            }
        } else {
            if (selectedVariants || selectedExteriors || selectedInteriors || sort) {
                if (!isNil(selectedDeliveryFromStore.Country?.code) && selectedDeliveryFromStore.Country?.code != "") {
                    buildAvailableRequest(); // doGetAvailableInventory();
                }
            }
        }
       
    }, [selectedVariants, selectedExteriors, selectedInteriors, sort, isButtonClicked, isMobile]);

    useEffect(() => {
        doGetAvailableInventory();

        inventoryAbortController.abort();
        return () => {
            inventoryAbortController.abort();
        };
    }, [JSON.stringify(availableRequest)]);


    useEffect(() => {
        if (!isNil(defaultSelectedVariant) || !isNil(isShopFront)) {
            
            if (!isNil(selectedDeliveryFromStore.Country?.code) && selectedDeliveryFromStore.Country?.code != "") {
                setDialogVisible(false);
                doGetAvailableInventoryFilter();
            }
            else {
                setDialogVisible(true);
            }
        }
        else {
            //from other website
            if (!isNil(selectedDeliveryFromStore.Country?.code) && selectedDeliveryFromStore.Country?.code != "") {
                setDialogVisible(false);
                doGetAvailableInventoryFilter();
            }
            else {
                setDialogVisible(true);
            }
        }
    }, [defaultSelectedVariant, isShopFront, selectedDeliveryFromStore]);

    const doGetAvailableInventoryFilter = async () => {
        let country = selectedDeliveryFromStore.Country?.code || "";
        let needDefaultValue = isNil(defaultSelectedVariant) || defaultSelectedVariant == "";
        let variantRequestData: variantModel.IVariantYanaRequest = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            State: selectedDeliveryFromStore.State?.code || null,
            Postcode: selectedDeliveryFromStore.SelectedDeliveryPostcode,
            Suburb: selectedDeliveryFromStore.SelectedDeliverySuburb || null,
            CustomerType: selectedCustomerType,
        };

        let response = await vehicleSerivce.getAvailableInventoryFilters(country, needDefaultValue, variantRequestData);
        if (response.status) {

            //stocks List
            let stockList: availableInventoryModel.IAvailableInventoryStock[] = [];
            let stockFromBackend = response.data.stocks;
            if (stockFromBackend.length > 0) {
                stockFromBackend.map((item: any) => {
                    stockList.push({
                        VariantID: item.variantID,
                        VariantName: item.variantName,
                        ExteriorID: item.exteriorID,
                        ExteriorName: item.exteriorName,
                        ExteriorFilterImage: item.exteriorFilterImage,
                        InteriorID: item.interiorID,
                        InteriorName: item.interiorName,
                        InteriorFilterImage: item.interiorFilterImage,
                        ExteriorOrderingNo: item.exteriorDisplayOrderingNo,
                        InteriorOrderingNo: item.interiorDisplayOrderingNo,
                        HasStock: item.interiorID,
                    })
                });
            }
            setStock(stockList);

            //variants
            let variantsList: vehicleModel.IVariant[] = [];
            let variantsFromBackend = response.data.variants;
            let defaultVariantID = "";
            let defaultVariantName = "";

            // let defaultVariant: availableInventoryModel.IAISelectedVariant = { SelectVariantID: "", SelectVariantName: "" };

            if (variantsFromBackend.length > 0) {
                variantsFromBackend.map((item: any) => {

                    if (!isNil(!isNil(defaultSelectedVariant) && defaultSelectedVariant != "") && item.variantName == defaultSelectedVariant) {
                        //default selected variant name coming from config page
                        defaultVariantID = item.yanaVariantId;
                        defaultVariantName = item.variantName;
                    }
                    else {
                        if (item.isDefaultSelected) {
                            defaultVariantID = item.yanaVariantId;
                            defaultVariantName = item.variantName;
                        }
                    }

                    let variantHasSock = stockList.find(m => m.VariantID == item.yanaVariantId && m.HasStock);
                    variantsList.push({
                        VariantID: item.yanaVariantId,
                        VariantName: item.variantName,
                        DriveawayPrice: null,
                        VariantPrice: null,
                        Range: null,
                        Power: null,
                        KmHour: null,
                        VariantPicture: null,
                        VariantPictureMobile: null,
                        VariantDescription: null,
                        IsDefault: defaultVariantID == item.yanaVariantId,
                        HasStock: variantHasSock != null ? true : false
                    });
                });
            }
            setVariants(variantsList);

            if (!isNil(defaultVariantID) && defaultVariantID != "") {
                let defaultVariant: availableInventoryModel.IAISelectedVariant[] = [{ SelectVariantID: defaultVariantID, SelectVariantName: defaultVariantName }];
                setSelectedVariants(defaultVariant);
            }

            //exteriors
            let exteriorList: vehicleModel.IExterior[] = getExteriorList(stockList, []);
            setExteriors(exteriorList);
            let defaultExteriors: availableInventoryModel.IAISelectedExterior[] = [];
            if (!isNil(defaultSelectedExterior) && defaultSelectedExterior != "") {
                defaultExteriors = [{
                    SelectExteriorID: "",
                    SelectExteriorName: defaultSelectedExterior || "",
                }]
            }
            setSelectedExteriors(defaultExteriors);

            //interiors
            let interiorList: vehicleModel.IInterior[] = getInteriorList(stockList, [], defaultExteriors);
            setInteriors(interiorList);
            let defaultInteriors: availableInventoryModel.IAISelectedInterior[] = [];
            if (!isNil(defaultSelectedInterior) && defaultSelectedInterior != "") {
                defaultInteriors = [{
                    SelectInteriorID: "",
                    SelectInteriorName: defaultSelectedInterior || "",
                }]
            }

            setSelectedInteriors(defaultInteriors);
        }
    }

    const getExteriorList = (stockList: availableInventoryModel.IAvailableInventoryStock[], variants: availableInventoryModel.IAISelectedVariant[]): vehicleModel.IExterior[] => {
        let exteriorList: vehicleModel.IExterior[] = [];
        let tempstockList = stockList.filter((stockItem: availableInventoryModel.IAvailableInventoryStock) => {
            if (variants.length == 0) {
                return stockItem;  //if no variants selected, return all exteriors
            }
            else {
                return variants.some((selectedVariant: availableInventoryModel.IAISelectedVariant) => {
                    return stockItem.VariantID == selectedVariant.SelectVariantID;
                });
            }
        });
        tempstockList.map((stockItem: availableInventoryModel.IAvailableInventoryStock) => {
            let exteriorHasStock = stockList.find(m => m.VariantID == stockItem.VariantID && m.ExteriorID == stockItem.ExteriorID && m.HasStock);
            exteriorList.push({
                Country: null,
                VariantID: stockItem.VariantID || "",
                VariantName: stockItem.VariantName || "",
                ExteriorID: stockItem.ExteriorID || "",
                ExteriorColorName: stockItem.ExteriorName || "",
                ExteriorColorDescTitle: null,
                ExteriorColorDesc: null,
                ExteriorPrice: null,
                ExteriorPicture: null,
                ExteriorPictureMobile: null,
                ExteriorFilterImage: stockItem.ExteriorFilterImage || "",
                ExteriorFilterMobile: null,
                IsDefault: null,
                HasStock: exteriorHasStock != null ? true : false,
                ExteriorOrderingNo: stockItem.ExteriorOrderingNo
            });
        });

        //distinct data by name
        if (exteriorList.length > 0) {
            exteriorList = exteriorList.filter((value: vehicleModel.IExterior, index: any, self: vehicleModel.IExterior[]) => {
                return self.findIndex((v: vehicleModel.IExterior) => v.ExteriorColorName === value.ExteriorColorName) === index;
            }).sort(m => m.ExteriorOrderingNo);
        }
        return exteriorList;
    }


    const getInteriorList = (
        stockList: availableInventoryModel.IAvailableInventoryStock[],
        variants: availableInventoryModel.IAISelectedVariant[],  //variantID: string,
        exteriors: availableInventoryModel.IAISelectedExterior[],  // exteriorName: string | null
    ): vehicleModel.IInterior[] => {
        let interiorList: vehicleModel.IInterior[] = [];
        let tempstockList = stockList.filter((stockItem: availableInventoryModel.IAvailableInventoryStock) => {
            //if (variants.length == 0 && exteriors.length == 0) {
            //    return stockItem;  //if no variants and exteriors selected, return all exteriors
            //}
            //else {
            let hasVariant = true;
            if (variants.length > 0) {
                hasVariant = variants.some((selectedVariant: availableInventoryModel.IAISelectedVariant) => {
                    return stockItem.VariantID == selectedVariant.SelectVariantID;
                });
            }

            let hasExteriant = true;
            if (exteriors.length > 0) {
                hasExteriant = variants.some((selectedVariant: availableInventoryModel.IAISelectedVariant) => {
                    return stockItem.VariantID == selectedVariant.SelectVariantID;
                });
            }

            //console.log(hasVariant);
            return hasVariant || hasExteriant;
            //}
        });

        tempstockList.map((stockItem: availableInventoryModel.IAvailableInventoryStock) => {
            let interiorHasStock = stockList.find(m => m.VariantID == stockItem.VariantID
                && m.ExteriorName == stockItem.ExteriorName
                && m.InteriorID == stockItem.InteriorID
                && m.HasStock);

            interiorList.push({
                Country: null,
                State: null,
                VariantID: stockItem.VariantID,
                VariantName: stockItem.VariantName,
                ExteriorID: null,
                ExteriorName: stockItem.ExteriorName,
                InteriorID: stockItem.InteriorID,
                InteriorColorName: stockItem.InteriorName,
                InteriorColorDescTitle: null,
                InteriorColorDesc: null,
                InteriorPrice: null,
                InteriorPicture: null,
                InteriorPictureMobile: null,
                InteriorFilterImage: stockItem.InteriorFilterImage,
                InteriorFilterMobile: null,
                IsDefault: null,
                Available: null,
                HasStock: interiorHasStock != null ? true : false,
                InteriorOrderingNo: stockItem.InteriorOrderingNo,
            });
        });

        //distinct data by name
        if (interiorList.length > 0) {
            interiorList = interiorList.filter((value: vehicleModel.IInterior, index: any, self: vehicleModel.IInterior[]) => {
                return self.findIndex((v: vehicleModel.IInterior) => v.InteriorColorName === value.InteriorColorName) === index;
            }).sort(m => m.InteriorOrderingNo);
        }
        return interiorList;
    }

    const buildAvailableRequest = () => {
        // window.dataLayer.push({})
        let requestData: availableInventoryModel.IAvailableInventoryRequest = {
            Country: selectedDeliveryFromStore.Country?.code || null,
            State: selectedDeliveryFromStore.State?.code || null,
            Postcode: selectedDeliveryFromStore.SelectedDeliveryPostcode,
            Suburb: selectedDeliveryFromStore.SelectedDeliverySuburb || null,
            CustomerType: selectedCustomerType,
            Variants: selectedVariants,
            Exteriors: selectedExteriors,
            Interiors: selectedInteriors,
            DeliveryOption: selectedDeliveryFromStore.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            Distance: selectedDeliveryFromStore.SelectedPickupLocation?.Distance || null,
            Sort: sort?.code || "0",
        };
        setAvailabeRequest(requestData);

    }

    const doGetAvailableInventory = async () => {
        setAvailableInventoryList([]);
        if (isNil(availableRequest.Country) || availableRequest.Country == "") return;

        const abortController = new (window as any).AbortController();
        await setInventoryAbortController(abortController);

        try {
            setSearchResultLoadingStatus(rdEnum.enumProcessingStatus.Processing);
            let response = await vehicleSerivce.getAvailableInventory(availableRequest, abortController.signal);
            if (response.status) {

                //search results
                let lst: availableInventoryModel.IAvailableInventory[] = [];
                let availableList = response.data.availableInventorys;
                if (availableList.length > 0) {
                    availableList.map((item: any) => {
                        lst.push({
                            Country: item.country,
                            State: item.state,
                            VariantID: item.yanaVariantId,
                            VariantName: item.variantName,
                            VariantPicture: item.variantPicture,
                            Available: item.available,
                            BasePrice: item.basePrice,
                            DriveawayPrice: item.driveawayPrice,
                            ExteriorID: item.exteriorID,
                            ExteriorName: item.exteriorName,
                            InteriorID: item.interiorID,
                            InteriorName: item.interiorName,
                        })

                    });
                }
                setAvailableInventoryList(lst);
            }
            setSearchResultLoadingStatus(rdEnum.enumProcessingStatus.Processed);

        } catch (error: any) {
            if (error.name === 'AbortError') console.log('Request aborted!');
        } finally {
            //if (abortController.signal?.aborted)
        }
    }



    const onGoToOrder = (variantName: string, exteriorName: string, interiorName: string) => {
        let url = `${getFrontendUrl()}/?selectedVariant=${variantName}&selectedExterior=${exteriorName}&selectedInterior=${interiorName}`;
        url += `&selectedCountry=${selectedDeliveryFromStore.Country?.code}&selectedState=${selectedDeliveryFromStore.State?.code}`;
        navigate(url);
    }

    const dispatch = useDispatch();

    const { scrollToSection } = useScroll();

    const toConfigure = () => {
        let currentUrl = window.location.href;
        navigate(`${getFrontendUrlWithSlash()}`, { state: { closePreLoader: false, isFromOtherPage: true, isFromHomePage: false } })
        // if (currentUrl.includes(`?redirectPage=${rdEnum.enumRedirectPage.CheckoutPaymentPage}`)) {

        //     currentUrl = currentUrl.replace(`?redirectPage=${rdEnum.enumRedirectPage.CheckoutPaymentPage}`, '');

        //     // Update the browser's URL without reloading the page
        //     window.history.replaceState({}, '', currentUrl);
        // }

        dispatch(hideGlobalPage(false))
        setTimeout(() => {
            scrollToSection('variant')
        }, 300);

        // setPaymentPageStatus(0);
        // dispatch(setMainPageNumber(0));

        // navigate(`/${getFrontendVirtualDir()}`, { state: { closePreLoader: false } });
        // dispatch(setScrollPosition('variant'));
        // const sections = document.querySelectorAll('.d2c-container');

        //     sections.forEach((section: any) => {
        //         section.style.overflowY = 'scroll';
        //         // section.style.paddingRight = '15px';
        //     });
    }

    const formatPrice = (value: number | null) => {
        if (isNil(value))
            return "";
        return currencyFormatter.format(value);
    }

    const [dialogVisible, setDialogVisible] = useState(false);
    const [countries, setCountries] = useState<ICodeName[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<ICodeName>();
    const [states, setStates] = useState<ICodeName[]>([]);
    const [selectedState, setSelectedState] = useState<any>(null);
    const [isHovered, setIsHovered] = useState(false);

    const [filterDialogVisible, setFilterDialogVisible] = useState(true);
    
    const countryState = useCountryState();

    const doGetCountry = async () => {
        let country = await countryState.getCountry();
        setCountries(country);
    }

    const doGetStates = async () => {
        let states = await countryState.getStates(rdEnum.enumCountryCode.Australia);
        setStates(states);
    }

    useEffect(() => {
        doGetCountry();
    }, []);

    useEffect(() => {
        setSelectedCountry({ code: rdEnum.enumCountryCode.Australia, name: rdEnum.enumCountryName.Australia });
        doGetStates();
        //if (!isNil(selectedCountry?.code) && selectedCountry?.code != "") {
        //    doGetStates()
        //}
    }, [selectedCountry?.code]);

    useEffect(() => {
        if (isMobile) {
            setFilterDialogVisible(true);
        }
    }, [location.state]);

    useEffect(() => {
        dispatch(checkIsShowScrollBar(true));
    }, []);

    const onCountryChange = (e: any) => {
        setSelectedCountry(e.value);
    }

    const onStateChange = (e: any) => {
        setSelectedState(e.value);
    }

    const onHide = () => {
        if ((selectedCountry?.code == rdEnum.enumCountryCode.Australia && selectedState)
            || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand) {
            // document.body.style.overflowY = 'scroll';
            // document.body.style.paddingRight = '0px';
            dispatch(checkIsShowScrollBar(true));
            setDialogVisible(false);
            //dispatch(setCountryStateAction({ Country: selectedCountry, State: selectedState }));
        }
    }

    const handleHover = () => {
        setIsHovered((prevIsHovered) => !prevIsHovered);
    };

    const toTestDrive = () => {
        // navigate(`${getFrontendUrl()}/test-drive`);
        // dispatch(setGlobalDropdown(false, false));
        // dispatch(checkIsShowScrollBar(true))
        window.open('https://www.cadillacanz.com/au-en/register');
    }

    const onSubmit = () => {
        if ((selectedCountry?.code == rdEnum.enumCountryCode.Australia && selectedState)
            || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand) {
            // document.body.style.overflowY = 'scroll';
            // document.body.style.paddingRight = '0px';
            dispatch(checkIsShowScrollBar(true));

            //Invoke the onHide function for 1. dispatch Country & State to store, 2. close dialog
            onHide();

            //update country/state store before call landing page api as this api is too slow to impact on getting variant/exterior/interior
            let delivery: selectedConfigModel.ISelectedDelivery = {
                Country: selectedCountry,
                State: selectedState,
                SelectedPickupLocation: null,
                SelectedDeliveryOption: null,
                SelectedDeliveryFullAddress: null,
                SelectedDeliveryPostcode: null,
                SelectedDeliveryState: null,
                SelectedDeliverySuburb: null,
                //EstDeliveryDate: null,
                PrimaryAddress: null,
                PrimaryPostcode: null,
                PrimarySuburb: null,
                PrimaryState: null,
                InvalidPrimaryAddress: null,
                DeliveryPrice: null,
                DeliveryAvailable: null,
                DeliveryPriceErrorCode: null,
                DeliveryDistance: null,
                PickupLocationName: null,
            };
            dispatch(selectedDeliveryStore.updateDeliveryAction(delivery));

            //clear payment options
            dispatch(paymentOptionsStore.clearPaymentOptionsAction());

            //default set customer type as private customer
            dispatch(customerTypeStore.setCustomerTypeAction(rdEnum.enumClientTypes.Private.Code));

            //if the users are from interest link or from inventory page, wont show this popup
            //so clear default config store
            let defaultConfig: defaultConfigModel.IDefaultConfig = {
                Country: null,
                State: null,
                VariantName: null,
                ExteriorName: null,
                InteriorName: null,
                Options: [],
                Loaded: false,
                LinkKey: null,
                ExpiryDateText: null,
            }
            dispatch(defaultConfigStore.setDefaultConfigAction(defaultConfig));
            dispatch(linkSourceStore.setLinkSourceAction(null));

        }
    }


    const topPanel = () => {
        return (
            <div className='top-control-container'>
                <div className='top-control-panel'>
                    <div className='filter-panel'>
                        <span>
                            <span className='filter-title'>Filters</span>
                            <div className='filter-quantity'>
                                <span>{filterQty}</span>
                            </div>
                        </span>

                        {
                            isMobile
                            ?
                            <img alt="" className='cross' onClick={() => handleFilterDialogClose()} src={`${getCMSAssets()}/main/cross.svg`} />
                            :
                            <></>
                        }
                        
                    </div>

                    <div className='reset-panel' onClick={() => handleRestFilter()}>
                        <img alt="" className='control-icon' data-pr-tooltip="Reset"
                            src={`${getCMSAssets()}/inventory/reset.svg`}
                        />
                        <span>Reset</span>
                        {/* {
                            drawerShow == true 
                            ?
                            <span>Reset</span>
                            :
                            <></>
                        } */}
                    </div>
                    <div className='hide-drawer' onClick={() => drawerShow == true ? handleDrawerClose() : handleDrawerOpen()}>
                        {
                            drawerShow == true && !isMobile
                                ?
                                <img alt="" className='control-icon' data-pr-tooltip="Open"
                                    src={`${getCMSAssets()}/inventory/hide.svg`}
                                />
                                :
                                <></>
                        }
                        {
                            drawerShow == true && !isMobile
                                ?
                                <span>Hide</span>
                                :
                                drawerShow == false && !isMobile
                                ?
                                <span>Show</span>
                                :
                                <></>
                        }
                        {
                            drawerShow != true && !isMobile
                                ?
                                <img alt="" className='control-icon' data-pr-tooltip="Open"
                                    src={`${getCMSAssets()}/inventory/hide.svg`}
                                    style={{ transform: 'rotate(180deg)', marginLeft: '.5rem' }}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>

                <div className={classNames({
                    'table-list-header': true,
                    "align-end": isNil(outOfStockBoxLocation) || outOfStockBoxLocation == "",
                    "align-around": !isNil(outOfStockBoxLocation) && outOfStockBoxLocation != ""
                })}>
                    {/*if initally from configuration page*/}
                    {!isNil(outOfStockBoxLocation) && outOfStockBoxLocation != "" && (
                        <div className='back-to-configure' onClick={() => {
                            onGoToOrder(defaultSelectedVariant || "", defaultSelectedExterior || "", defaultSelectedInterior || "");
                        }}>
                            <img alt="" src={`${getCMSAssets()}/inventory/arrow.svg`} />
                            <span>Return to configuration</span>
                        </div>
                    )}

                    <div className='dropdown-panel'>
                        <Dropdown
                            value={sort || ''}
                            options={rdEnum.enumInventorySort}
                            onChange={(e) => onSortChange(e)}
                            className='list-category'
                            optionLabel="name"
                            placeholder=""
                        />
                        <label className={sort ? 'sort-by-label selected' : 'sort-by-label'}>Sort by</label>
                    </div>
                </div>
            </div>
        )
    }

    const filterPanel = () => {
        return (
            <div className={drawerShow == true ? 'inventory-drawer' : 'inventory-drawer close'}>
                <div className='main-drawer'
                    style={{ display: drawerShow == true ? '' : 'none' }} //Hide the main drawer when close
                >
                    <div className='variant-section'>
                        <div className='section-title'>
                            VARIANT
                        </div>

                        {
                            !isMobile
                            ?
                            <div className='checkbox-panel'>
                                {variants.length > 0 && variants.map((item: vehicleModel.IVariant, index: number) => {
                                    return (
                                        <div className='inventory-variant-btn-panel' key={"variant_filter_" + item.VariantID + index}>
                                            <Button
                                                className={classNames({
                                                    "inventory-variant-btn": true,
                                                    "disabled-wrapper": !item.HasStock,
                                                    "selected": selectedVariants.some((selectedItem: availableInventoryModel.IAISelectedVariant) => { return selectedItem.SelectVariantID === item.VariantID })
                                                })}
                                                onClick={() => {
                                                    handleVariant(item.VariantID || "", item.VariantName || "");
                                                }}
                                            >
                                                <h6>{item.VariantName}</h6>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className='checkbox-panel'>
                                {variants.length > 0 && variants.map((item: vehicleModel.IVariant, index: number) => {
                                    return (
                                        <div className='inventory-variant-btn-panel' key={"variant_filter_" + item.VariantID + index}>
                                            <Checkbox
                                                // className={classNames({
                                                //     "inventory-variant-btn": true,
                                                //     "disabled-wrapper": !item.HasStock,
                                                //     "selected": selectedVariants.some((selectedItem: availableInventoryModel.IAISelectedVariant) => { return selectedItem.SelectVariantID === item.VariantID })
                                                // })}
                                                // onClick={() => {
                                                //     handleVariant(item.VariantID || "", item.VariantName || "");
                                                // }}
                                                name={`cb-ack + ${item.VariantID}`}
                                                inputId={`cb-ack + ${item.VariantID}`}
                                                onChange={() => {
                                                    handleVariant(item.VariantID || "", item.VariantName || "")
                                                }}
                                                checked={selectedVariants.some((selectedItem: availableInventoryModel.IAISelectedVariant) => { return selectedItem.SelectVariantID === item.VariantID })}
                                            >
                                                {/* <h6>{item.VariantName}</h6> */}
                                            </Checkbox>
                                            <label htmlFor={`cb-ack + ${item.VariantID}`} className="p-checkbox-label">
                                                {item.VariantName}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    {
                        !isMobile
                        ?
                        <>
                            {/*exterior*/}
                            <Accordion defaultActiveKey="0" className='exterior-section'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='section-title'>
                                        EXTERIOR
                                    </Accordion.Header>

                                    <Accordion.Body className='options-container' >
                                        {exteriors.length > 0 && exteriors.map((item: vehicleModel.IExterior, index: number) => {
                                            //use exterior name to compare as exterior id is not correct
                                            return (
                                                <div className={classNames({
                                                    "color-options-item": true,
                                                    "active": selectedExteriors.some((selectedItem: availableInventoryModel.IAISelectedExterior) => { return selectedItem.SelectExteriorName === item.ExteriorColorName }),
                                                    "disabled-wrapper": !item.HasStock
                                                })}
                                                    key={"ai_e_" + item + index}
                                                    onClick={() => {
                                                        if (item.HasStock) {
                                                            handleExteriorColor(item.ExteriorColorName || "");
                                                        }
                                                    }}
                                                    data-pr-tooltip={item.ExteriorColorName}
                                                >
                                                    <div className='color-options-round'>
                                                        <img className="image" src={item.ExteriorFilterImage || ""} />
                                                    </div>
                                                    <div className='options-content'>
                                                        {item.ExteriorColorName}
                                                    </div>
                                                </div>
                                            );

                                        })}

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            {/*interior*/}
                            <Accordion defaultActiveKey="0" className='interior-section'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='section-title'>
                                        INTERIOR
                                    </Accordion.Header>

                                    <Accordion.Body className='options-container' >
                                        {interiors.length > 0 && interiors.map((item: vehicleModel.IInterior, index: number) => {
                                            return (
                                                <div className={classNames({
                                                    "color-options-item": true,
                                                    "active": selectedInteriors.some((selectedItem: availableInventoryModel.IAISelectedInterior) => { return selectedItem.SelectInteriorName === item.InteriorColorName }),
                                                    "disabled-wrapper": !item.HasStock
                                                })}
                                                    key={"ai_i_" + item + index}
                                                    onClick={() => {
                                                        if (item.HasStock) {
                                                            handleInteriorColor(item.InteriorColorName);
                                                        }
                                                    }}
                                                    data-pr-tooltip={item.InteriorColorName}
                                                >
                                                    <div className='color-options-round'>
                                                        <img className="image" src={item.InteriorFilterImage || ""} />
                                                    </div>
                                                    <div className='options-content'>
                                                        {item.InteriorColorName}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion >
                        </>
                        :
                        <>
                        {/*exterior*/}
                        <div className='exterior-section'>
                            
                            <div className='section-title'>
                                EXTERIOR
                            </div>

                            <div className='options-container' >
                                {exteriors.length > 0 && exteriors.map((item: vehicleModel.IExterior, index: number) => {
                                    //use exterior name to compare as exterior id is not correct
                                    return (
                                        <div className={classNames({
                                            "color-options-item": true,
                                            "active": selectedExteriors.some((selectedItem: availableInventoryModel.IAISelectedExterior) => { return selectedItem.SelectExteriorName === item.ExteriorColorName }),
                                            "disabled-wrapper": !item.HasStock
                                        })}
                                            key={"ai_e_" + item + index}
                                            onClick={() => {
                                                if (item.HasStock) {
                                                    handleExteriorColor(item.ExteriorColorName || "");
                                                }
                                            }}
                                            data-pr-tooltip={item.ExteriorColorName}
                                        >
                                            <div className='color-options-round'>
                                                <img className="image" src={item.ExteriorFilterImage || ""} />
                                            </div>
                                            <div className='options-content'>
                                                {item.ExteriorColorName}
                                            </div>
                                        </div>
                                    );

                                })}

                            </div>
                        </div>

                        {/*interior*/}
                        <div className='interior-section'>
                            <div className='section-title'>
                                INTERIOR
                            </div>

                            <div className='options-container' >
                                {interiors.length > 0 && interiors.map((item: vehicleModel.IInterior, index: number) => {
                                    return (
                                        <div className={classNames({
                                            "color-options-item": true,
                                            "active": selectedInteriors.some((selectedItem: availableInventoryModel.IAISelectedInterior) => { return selectedItem.SelectInteriorName === item.InteriorColorName }),
                                            "disabled-wrapper": !item.HasStock
                                        })}
                                            key={"ai_i_" + item + index}
                                            onClick={() => {
                                                if (item.HasStock) {
                                                    handleInteriorColor(item.InteriorColorName);
                                                }
                                            }}
                                            data-pr-tooltip={item.InteriorColorName}
                                        >
                                            <div className='color-options-round'>
                                                <img className="image" src={item.InteriorFilterImage || ""} />
                                            </div>
                                            <div className='options-content'>
                                                {item.InteriorColorName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div >

                        <div className='variant-btn-panel'>
                            <Button className='variant-btn' onClick={() => {
                                handleMobileFilterClick()
                            }}>
                                <h6>SHOW RESULTS</h6>
                            </Button>
                        </div>
                    </>
                    }
                </div>

                {
                    drawerShow != true
                        ?
                        <>
                            <Tooltip target=".control-icon" />
                            <Tooltip target=".variant-btn" />
                            <Tooltip target=".color-options-item" style={{ maxWidth: '300px' }}>
                            </Tooltip>
                        </>
                        :
                        <></>
                }

            </div>
        )
    }

    const handleFilterDialogClose = () => {
        setFilterDialogVisible(false);
    }

    const isScrollbarVisible = useSelector((state: IAppState) => {
        return state.isShowScrollBar.isShowScrollBar
    });



    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }
    }, []);

    return (
        <MainLayoutPage sceneId="container-has-fixed-header">

            {/* <InventoryMenu /> */}

            <GlobalMenu />

            {
                isMobile
                ?
                <Dialog className='filter-dialog' visible={filterDialogVisible} position='bottom' draggable={false} resizable={true} onHide={() => handleFilterDialogClose()}>
                    {
                        topPanel()
                    }
                    {
                        filterPanel()
                    }
                </Dialog>
                :
                <></>
            }

            {
                !isMobile
                ?
                topPanel()
                :
                <></>
            }

            <div className='inventory-container'>
                {
                    !isMobile
                    ?
                    filterPanel()
                    :
                    <></>
                }
                {searchResultLoadingStatus != rdEnum.enumProcessingStatus.Processed && (
                    <div className='inventory-list-container' style={{ marginLeft: drawerShow == true ? '8vw' : '0' }}>
                        <div className='pre-loader'>
                            <img className="img" alt="Info icon" src={`${getCMSAssets()}/home/brand-loader.gif`} />
                        </div>
                    </div>
                )}
                {
                    isMobile
                    ?
                    <div className='filter-to-exapnd-container'>
                        <div className='filter-to-exapnd-box' onClick={() => handleFilterDialogOpen()}>
                            <img className="img" alt="Info icon" src={`${getCMSAssets()}/inventory/setting.svg`} />
                            <div className='filter-quantity'>
                                <span>{filterQty}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }
                {searchResultLoadingStatus == rdEnum.enumProcessingStatus.Processed && (

                    <div className={drawerShow == true ? 'inventory-list-container' : 'inventory-list-container expand'} style={{ marginLeft: drawerShow == true ? '8vw' : '0' }}>
                        <Row className='inventory-table-list'>
                            {availableInventoryList.length == 0 && (
                                <div className="filter-reset-wrapper">
                                    <h1>NO MATCHES FOUND</h1>
                                    <p>Try removing Filters</p>
                                    <div className='selected-filters-wrapper'>
                                        {selectedVariants.map((item: availableInventoryModel.IAISelectedVariant, index: number) => {
                                            return (
                                                <div className="selected-filter-item" key={"selected_filter_v_" + index}
                                                    onClick={() => handleRemoveVariantFilter()}
                                                >
                                                    <span>Variant: {item.SelectVariantName}</span>
                                                    <BsXCircle className="icon" size="20" />
                                                </div>
                                            );
                                        })}

                                        {selectedExteriors.map((item: availableInventoryModel.IAISelectedExterior, index: number) => {
                                            return (
                                                <div className="selected-filter-item" key={"selected_filter_e_" + index}
                                                    onClick={() => {
                                                        handleExteriorColor(item.SelectExteriorName || "");
                                                    }}
                                                >
                                                    <span>Exterior: {item.SelectExteriorName}</span>
                                                    <BsXCircle className="icon" size="20" />
                                                </div>
                                            );
                                        })}

                                        {selectedInteriors.map((item: availableInventoryModel.IAISelectedInterior, index: number) => {
                                            return (
                                                <div className="selected-filter-item" key={"selected_filter_i_" + index}
                                                    onClick={() => {
                                                        handleInteriorColor(item.SelectInteriorName || "");
                                                    }}
                                                >
                                                    <span>Interior: {item.SelectInteriorName}</span>
                                                    <BsXCircle className="icon" size="20" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="link-remove-all" onClick={() => handleRestFilter()}>
                                        <BsXCircle className="icon" size="20" />
                                        <span>Remove All</span>
                                    </div>
                                </div>
                            )}

                            {availableInventoryList.length > 0 && availableInventoryList.map((item: availableInventoryModel.IAvailableInventory, index: number) => {
                                return (
                                    <Col lg={drawerShow != true ? 3 : 4}
                                        className={drawerShow == true ? 'inventory-card' : 'inventory-card full'}
                                        key={"ai_" + item + index}
                                    >
                                        <div className='inventory-card-inner'>
                                            <div className='inventory-header'>
                                                <div className='name-type'>
                                                    <h6>{rdEnum.constModel}</h6>
                                                    <h6>{formatPrice(item?.DriveawayPrice)}</h6>
                                                </div>
                                                <div className='price-payment'>
                                                    <div className='inner-panel'>
                                                        <span>{item.VariantName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='vehicle-image-panel'>
                                                <img src={item.VariantPicture || undefined} />
                                            </div>
                                            <div className='color-panel'>
                                                <div className='exterior-box'>
                                                    <span>Exterior</span>
                                                    <div className='color-options-item' >
                                                        <div className='color-options-round'>
                                                            {/* <img className="image" src={item.ExteriorPicture || undefined} />*/}
                                                        </div>
                                                        <div className='options-content'>
                                                            {item.ExteriorName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='interior-box'>
                                                    <span>Interior</span>
                                                    <div className='color-options-item' >
                                                        <div className='color-options-round'>
                                                            {/*<img className="image" src={item.InteriorPicture || undefined} />*/}
                                                        </div>
                                                        <div className='options-content'>
                                                            {item.InteriorName}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='est-delivery-time'>
                                                <p>{item.Available}</p>
                                                <ComparisonDialog pageName="Inventory" />
                                            </div>
                                            <div className='variant-btn-panel'>
                                                <Button className='variant-btn' onClick={() => {
                                                    onGoToOrder(item.VariantName || "", item.ExteriorName || "", item.InteriorName || "");
                                                }}>
                                                    <h6>ORDER</h6>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>

                        <div className='inventory-join-waitlist-panel'>
                            <div className='inventory-join-waitlist-card'>
                                <div className='join-waitlist-context'>
                                    STILL NOT WHAT YOU'RE LOOKING FOR?
                                </div>
                                <SaveDialog props='inventory' />
                                {/* <div className='variant-btn-panel' style={{ borderRadius: '4px' }}>
                                    <Button className="variant-btn" style={{ marginTop: '0rem' }}
                                        // onClick={() => toJoinWaitlistCheckoutForm()}
                                    >JOIN OUR WAITLIST HERE</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>


            {/* <Dialog header={isNil(selectedCountry?.code) || selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? "SELECT YOUR COUNTRY" : 'SELECT YOUR STATE'}
                className='global-dialog' visible={dialogVisible} position='bottom' style={{ width: '560px' }} draggable={false} resizable={true}
                onHide={() => onHide()}>
                <div className='dropdown-container'>
                    <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={(e) => onCountryChange(e)}
                        className='global-location-dropdown'
                        optionLabel="name"
                        placeholder="Country"
                    />

                    <Dropdown
                        value={selectedState}
                        options={states}
                        onChange={(e) => onStateChange(e)}
                        className='global-location-dropdown'
                        style={{ display: selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? 'none' : '' }}
                        optionLabel="name"
                        placeholder="State"
                        disabled={!selectedCountry}
                    />
                </div>

                <Button className='global-location-btn'
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    onClick={() => onSubmit()}
                >
                    <span>SUBMIT</span>
                </Button>
            </Dialog> */}

            <Dialog header={"SELECT YOUR COUNTRY"}
                className='global-dialog' visible={dialogVisible} position='bottom' draggable={false} resizable={true}
                onHide={() => onHide()}>
                <Row className='temp-location-container'>
                    <Col lg={6} xs={12} className='temp-au-container'>
                        <div className='dropdown-container'>
                            <div className='country-title'>
                                AUSTRALIA
                            </div>
                            <br/>
                            <div className='reminder-box'>
                                Please select your state to proceed.
                            </div>
                            {/*<Dropdown*/}
                            {/*    value={selectedCountry}*/}
                            {/*    options={countries}*/}
                            {/*    onChange={(e) => onCountryChange(e)}*/}
                            {/*    className='global-location-dropdown'*/}
                            {/*    optionLabel="name"*/}
                            {/*    placeholder="Country"*/}
                            {/*/>*/}

                            <Dropdown
                                value={selectedState}
                                options={states}
                                onChange={(e) => onStateChange(e)}
                                className='global-location-dropdown'
                                style={{ display: selectedCountry?.code == rdEnum.enumCountryCode.NewZealand ? 'none' : '' }}
                                optionLabel="name"
                                placeholder="State"
                            />
                        </div>

                        <Button className='global-location-btn'
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                            onClick={() => onSubmit()}
                        >
                            <span >SUBMIT</span>
                        </Button>
                    </Col>

                    <Col lg={6} xs={12}className='temp-nz-container'>
                        <div className='dropdown-container'>
                            <div className='country-title'>
                                NEW ZEALAND
                            </div>
                            <div className='coming-soon'>
                                COMING SOON
                            </div>
                            <br />
                            <div className='new-zealand-info-panel'>
                                We're busy building an iconic design and build experience for New Zealand customers.
                            </div>
                            <br/>
                            <div className='new-zealand-info-panel'>
                                We can't wait to welcome you. Be sure to sign up for Cadillac brand, product and order updates below.
                            </div>
                        </div>

                        <Button className='global-location-btn'
                            // onMouseEnter={handleHover}
                            // onMouseLeave={handleHover}
                            onClick={() => toTestDrive()}
                        >
                            <span >KEEP UPDATED</span>
                        </Button>
                    </Col>
                </Row>
            </Dialog>

        </MainLayoutPage>
    );
};
