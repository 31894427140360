export const SET_LINKSOURCE = 'SET_LINKSOURCE';
export const setLinkSourceAction = (source: any) =>
({
    type: SET_LINKSOURCE,
    source,
} as const);


type LinkSourceActions =
    | ReturnType<typeof setLinkSourceAction>;

export const linkSourceReducer = (
    state = <any>null,
    action: LinkSourceActions,
) => {
    switch (action.type) {
        case SET_LINKSOURCE: {
            return action.source;
        }
    }

    return state;
};
