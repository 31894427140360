import { getCMSAssets } from 'helpers/Uri'
import React, { useEffect, useState } from 'react'
import { Accordion, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import { isNil } from 'lodash';
import * as docService from 'services/Document';
import * as docModel from 'model/IDocuments';
import * as orderDocsStore from 'stores/OrderDocuments';
import { Page } from '../../../Page';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';



const Documents = () => {
    const dispatch = useDispatch();
    const orderDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderCustomerDetails;
    });
    const orderDetailPageVersionFromStore = useSelector((state: IAppState) => {
        return state.orderDetailDataVersion;
    });


    const [orderDocs, setOrderDocs] = useState<docModel.IDocumentItem[]>();

    const isMobile = useCheckMobileScreen();

    const doGetAllDocs = async () => {
        let requestData: docModel.IDocument = {
            YanaCustomerID: orderDetailsFromStore?.yanaCustomerID || "",
            YanaCustomerNo: orderDetailsFromStore?.yanaCustomerNo || "",
            YanaOrderID: orderDetailsFromStore?.yanaOrderID || "",
            YanaOrderNo: orderDetailsFromStore?.yanaOrderNo || "",
            Country: orderDetailsFromStore?.country || "",
            Documents: []
        };
        let response = await docService.getAllDocuments(requestData);
        let tempDocs: docModel.IDocumentItem[] = [];
        let allDocs: docModel.IDocumentItem[] = [];
        if (response.status) {
            let responseData = response.data;
            if (!isNil(responseData) && responseData.length > 0) {
                responseData.map((item: any) => {
                    allDocs.push({
                        DocumentName: item.reportName,
                        DocumentType: item.reportType,
                        Base64: null,
                        InvalidDocment: null,
                        InvalidReason: null,
                        DocumentKey: item.key,
                        DocumentUrl: item.url,
                        DocumentDownloadUrl: item.downloadUrl,
                    });

                    if (!item.reportType.includes("Drive Licence")) {
                        tempDocs.push({
                            DocumentName: item.reportName,
                            DocumentType: item.reportType,
                            Base64: null,
                            InvalidDocment: null,
                            InvalidReason: null,
                            DocumentKey: item.key,
                            DocumentUrl: item.url,
                            DocumentDownloadUrl: item.downloadUrl,
                        });
                    }
                });
            }
        }
        dispatch(orderDocsStore.setOrderDocumentsAction(allDocs));
        setOrderDocs(tempDocs);
    }

    useEffect(() => {
        if (!isNil(orderDetailsFromStore)) {
            doGetAllDocs();
        }
    }, [orderDetailsFromStore, orderDetailPageVersionFromStore]);


    // const onDownload = async (doc: docModel.IDocumentItem) => {
    //     let requestData: docModel.IDocument = {
    //         YanaCustomerID: orderDetailsFromStore?.yanaCustomerID || "",
    //         YanaCustomerNo: orderDetailsFromStore?.yanaCustomerNo || "",
    //         YanaOrderID: orderDetailsFromStore?.yanaOrderID || "",
    //         YanaOrderNo: orderDetailsFromStore?.yanaOrderNo || "",
    //         Country: orderDetailsFromStore?.country || "",
    //         Documents: [doc]
    //     };
    //     let response = await docService.getSpecificDocuments(requestData);
    //     if (response.status) {
    //         window.open(`${response.data[0].downloadUrl}`);
    //     }
    // }

    const handleDownloadClick = async (doc: docModel.IDocumentItem) => {
        await onDownload(doc);
    };

    const onDownload = async (doc: docModel.IDocumentItem) => {
        let requestData: docModel.IDocument = {
            YanaCustomerID: orderDetailsFromStore?.yanaCustomerID || "",
            YanaCustomerNo: orderDetailsFromStore?.yanaCustomerNo || "",
            YanaOrderID: orderDetailsFromStore?.yanaOrderID || "",
            YanaOrderNo: orderDetailsFromStore?.yanaOrderNo || "",
            Country: orderDetailsFromStore?.country || "",
            Documents: [doc]
        };
        let response = await docService.getSpecificDocuments(requestData);
        if (response.status) {
            const link = document.createElement('a');
            link.href = response.data[0].downloadUrl;
            link.download = doc.DocumentName || 'download';
            link.click();
        }
    };

    return (
        <>
            {
                !isMobile
                ?
                <Dropdown data-bs-theme="dark" className='download-dropdown-container'>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                        <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/document.png`} />
                        <span>Documents</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {!isNil(orderDocs) && orderDocs.length > 0 && orderDocs.map((item: docModel.IDocumentItem, index: number) => {
                            return (

                                <div className='download-item' key={"" + item + index}>
                                    <button onClick={() => {
                                        handleDownloadClick(item);
                                    }}>
                                        <span className="break-word">
                                            {item.DocumentName}
                                        </span>
                                        <img alt="" className='download-icon' src={`${getCMSAssets()}/documents/download.svg`} />
                                    </button>
                                </div>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Accordion className='download-dropdown-container'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <img alt="" className='download-icon' src={`${getCMSAssets()}/inventory/document.png`} />
                            <span>Documents</span>
                        </Accordion.Header>
                        <Accordion.Body >
                            {!isNil(orderDocs) && orderDocs.length > 0 && orderDocs.map((item: docModel.IDocumentItem, index: number) => {
                                return (

                                    <div className='download-item' key={"" + item + index}>
                                        <button onClick={() => {
                                            handleDownloadClick(item);
                                        }}>
                                            <span className="break-word">
                                                {item.DocumentName}
                                    </span>
                                            <img alt="" className='download-icon' src={`${getCMSAssets()}/documents/download.svg`} />
                                        </button>
                                    </div>
                                );
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
        </>
    )
}

export default Documents