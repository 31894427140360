import * as paymentOptionModel from 'model/IPaymentOptions';
import { IVehicleOptions } from 'model/IVehicle';

export const getInitDriveawayCostRequest = (): paymentOptionModel.IDriveAwayRequest => {
    let init: paymentOptionModel.IDriveAwayRequest = {
        Country: "",
        State:  "",
        VariantName:  "",
        VariantID:  "",
        VariantPrice:  0,
        ExteriorID: "",
        ExteriorName: "",
        ExteriorPrice:  0,
        InteriorID: "",
        InteriorName: "",
        InteriorPrice:  0,
        Options: [],
        SubTotal:  0,
        DeliveryOption: "",
        RegistrationType: "",
        Distance: 0,
        PostCode: "",
        Suburb:  "",
        CallSource: "Main Page",
        IfCalculateFinance: true,
        DeliveryPrice: 0,
    };

    return init;
}

export const getInitDriveawayCost = (): paymentOptionModel.IDriveAwayCost => {
    let init: paymentOptionModel.IDriveAwayCost = {
        LuxuryCarTax: 0,
        LocalStampDuty: 0,
        LocalRegistrationCost: 0,
        LocalCTP: 0,
        LocalPlateFee: 0,
        HomeDelivery: 0,
        DriveawayPrice: 0,
        OnRoadFee: 0,
        SubTotal: 0,
        VariantName: "",
        VariantID: "",
        VariantPrice: 0,
        ExteriorID: "",
        ExteriorName: "",
        ExteriorPrice: 0,
        InteriorID: "",
        InteriorName: "",
        InteriorPrice: 0,
        GST: 0,
        RoadUserChargers: 0,
        TyreStewardshipFee: 0,
        Options: [],
    };

    return init;
}