import { getBackendPath } from 'helpers/Uri';
import * as orderModel from 'model/IOrder';
import * as checkOutViewModel from 'model/ICheckoutWaitlistViewModel';
import * as orderCustomerDetailsModel from 'model/IOrderCustomerDetails';
import * as contractModel from 'model/IContract';
import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
import * as docModel from 'model/IDocuments';
import * as callBackRequestModel from 'model/ICallBackRequest';
import * as financeStatusModel from 'model/IFinanceStatus';
import * as rdHelper from 'helpers/Uri';

export const placeReservation = async (
    order: orderModel.IOrderRequest,
    hasLogin: boolean,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order: order,
            hasLogin: hasLogin
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/place-reservation`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const placeOrder = async (
    order: orderModel.IOrderRequest,
    adyenSession: checkOutViewModel.IAdyenSession
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order: order,
            adyenSession: adyenSession
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/place-order`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getOrderByYanaCustomerID = async (
    customerID: string,
    customerNo: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            customerID: customerID,
            customerNo: customerNo
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-orders-by-customerID`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getMyOrderByYanaCustomerID = async (
    customerID: string,
    customerNo: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            customerID: customerID,
            customerNo: customerNo
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-my-orders-by-customerID`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getOrderByRDOrderID = async (
    rdOrderID: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            rdOrderID: rdOrderID,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-orders-by-rd-orderid`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getOrderDetailsByYanaIDs = async (
    yanaCustomerID: string,
    yanaCustomerNo: string,
    yanaOrderID: string,
    yanaOrderNo: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            yanaCustomerID: yanaCustomerID,
            yanaCustomerNo: yanaCustomerNo,
            yanaOrderID: yanaOrderID,
            yanaOrderNo: yanaOrderNo,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-order-details-by-yana-ids`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getMyOrderDetailsByYanaIDs = async (
    yanaCustomerID: string,
    yanaCustomerNo: string,
    yanaOrderID: string,
    yanaOrderNo: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            yanaCustomerID: yanaCustomerID,
            yanaCustomerNo: yanaCustomerNo,
            yanaOrderID: yanaOrderID,
            yanaOrderNo: yanaOrderNo
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-my-order-details-by-yana-ids`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const updateOrderDetails = async (
    customerDetails: orderCustomerDetailsModel.IOrderCustomerDetails,
    docs: docModel.IDocumentItem[],
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            customerDetails: customerDetails,
            docs: docs
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/update-order-details`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const confirmContract = async (
    contract: contractModel.IContract
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(contract),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/sign-contract`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getScheduleAvailabeTime = async (
    scheduleDelivery: scheduleDeliveryModel.IOrderScheduleDelivery
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(scheduleDelivery),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-available-delivery-time`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const saveScheduleAvailabeTime = async (
    scheduleDelivery: scheduleDeliveryModel.IOrderScheduleDelivery
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(scheduleDelivery),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/save-schedule-delivery`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


//export const retryPayment = async (
//    order: orderModel.IOrderRequest
//): Promise<any> => {
//    const requestOptions: any = {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json',
//        },
//        body: JSON.stringify({
//            order: order,
//        }),
//        credentials: 'include',
//    };
//    const response = await fetch(
//        `${getBackendPath()}/api/Order/retry-payment`,
//        requestOptions,
//    );
//    const json = await response.json();
//    return json;
//};


export const saveRetryPayment = async (
    order: orderModel.IOrderRequest,
    adyenSession: checkOutViewModel.IAdyenSession
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order: order,
            adyenSession: adyenSession,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/save-retry-payment`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getLMCT = async (
    Country: string,
    State: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Country: Country,
            State: State,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-lmct`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const callBackRequest = async (
    requestData: callBackRequestModel.ICallBackRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/call-back-request`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getFinanceStatus = async (
    requestData: financeStatusModel.IFinanceStatus
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-finance-status`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getReservationStatus = async (
    orderID: any
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderID: orderID}),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/get-reservation-status`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};



export const setReservationCountdown = async (
    country: any
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: country }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/set-reservation-countdown`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const extendReservationCountdown = async (
    country: any
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: country }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Order/extend-reservation-countdown`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};