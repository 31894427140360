import { useDispatch, useSelector } from 'react-redux';
import { logoutClearAction } from 'stores/Store';
import { IAppState } from 'model/IAppState';

import { initPaymentOptions } from 'stores/PaymentOptions';
import { initSelectedVehicle } from 'stores/UserSelectedVehicleConfig';

import * as scheduleDeliveryHelper from 'businessHelpers/OrderScheduleDeliveryHelper';
import * as vehicleHelper from 'businessHelpers/VehicleHelper';
import * as defaultConfigHelper from 'businessHelpers/DefaultConfigHelper';
import * as selectedDeliveryHelper from 'businessHelpers/SelectedDeliveryhelper';

export const useClearUserDataStore = () => {
    const dispatch = useDispatch();


    const storeData = useSelector((appState: IAppState) => {
        return appState;
    });

    const clearData = () => {
        let newData: IAppState = {
            ...storeData,
            session: null,
            customerType: null,
            checkoutCustomer: null,
            defautConfig: defaultConfigHelper.getInitDefaultConfig(),
            orderCustomerDetails: null,
            orderDetails: null,
            orderDocuments:null,
            orderScheduleDelivery: scheduleDeliveryHelper.getInitScheduleDelivery(),
            paymentOptions: initPaymentOptions,
            selectedDelivery: selectedDeliveryHelper.getInitSelectedDelivery(),
            selectedVehicle: initSelectedVehicle,
            //vehicle: vehicleHelper.getInitVehicleStore()   //todo: do we need to clear this?
        }
        dispatch(logoutClearAction(newData));
    }


    return {
        clearData
    }

}