import { getCMSAssets, getFrontendUrl, getFrontendUrlWithSlash } from 'helpers/Uri'
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen'
import { IAppState } from 'model/IAppState'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setGlobalDropdown } from 'stores/GlobalDropdown'

const GlobalSelectVehicle = ({onChangeActiveBtn}: any) => {

    const [selectVehicleCategory, setselectVehicleCategory] = useState<any>('ELECTRIC SUVS');

    const [isVehicleSelectionDisplay, setIsVehicleSelectionDisplay] = useState<any>(false);

    const isMobile = useCheckMobileScreen();

    const menuGlobalBtn = useSelector((state: IAppState) => {
        return state.globalDropdown
    });

    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onHidePanel = () => {
        onChangeActiveBtn('')
    }

    const handleVehicleSelectionDisplay = (category: string) => {
        setIsVehicleSelectionDisplay(true);
        setselectVehicleCategory(category);
    }

    const onHideVehicleSelectionPanel = () => {
        setIsVehicleSelectionDisplay(false);
    }

    const toConfigurate = (e: any) => {
        navigate(`${getFrontendUrlWithSlash()}`, { state: { closePreLoader: false, isFromOtherPage: false } });
        dispatch(setGlobalDropdown(false, false));
        if (location.pathname == '/') {
            e.preventDefault();
            window.location.reload();
        }
    }

    const toMOVPage = () => {
        window.open('https://www.cadillacanz.com/au-en/lyriq-electric-suv', '_blank');
    }

    const toShowroom = () => {
        window.open('https://www.cadillacanz.com/au-en/visit-a-showroom')
    }

    useEffect(() => {
        setIsVehicleSelectionDisplay(false);
    }, [menuGlobalBtn])

    return (
        <div className='global-select-vehicle-container'>
            {
                !isMobile
                ?
                <div className='global-select-header-bg-box'>
                    <h6>BE ICONIC</h6>
                </div>
                :
                <></>
            }


            <div className='global-vehicles-inner-container'>
                <Row>
                    <Col md={4}>
                        <div className='global-vehicles-left-sidebar-container'>
                            <div className='current-vehicles-options-card'>
                                <div className={selectVehicleCategory != 'ELECTRIC SUVS' ? 'vehicle-type-category' : 'vehicle-type-category selected'} onClick={() => handleVehicleSelectionDisplay('ELECTRIC SUVS')}>
                                    <div className='left-blue-bar'></div>
                                    <h6>ELECTRIC SUVS</h6>
                                </div>
                                {
                                    isMobile
                                    ?
                                    <img src={`${getCMSAssets()}/global/arrow-black.svg`} />
                                    :
                                    <></>
                                }
                            </div>
                            {/* <div className='current-vehicles-options-card'>
                                <div className={selectVehicleCategory != 'V SERIES' ? 'vehicle-type-category' : 'vehicle-type-category selected'} onClick={() => handleVehicleSelectionDisplay('V SERIES')}>
                                    <div className='left-blue-bar'></div>
                                    <h6>V SERIES</h6>
                                </div>
                                {
                                    isMobile
                                    ?
                                    <img src={`${getCMSAssets()}/global/arrow-black.svg`} />
                                    :
                                    <></>
                                }
                            </div> */}
                            <div className='other-options-panel'>
                                <div className='other-options-item'>
                                    <div className='left-blue-bar'></div>
                                    <h6 className="stat-text-link" data-dtm="global nav" onClick={() => toShowroom()}>CADILLAC EXPERIENCE CENTRES</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={8} className={!isMobile || !isVehicleSelectionDisplay ? 'global-vehicles-selection-right-grid-container' : 'global-vehicles-selection-right-grid-container active'}>
                        {
                            isMobile
                            ?
                            <div className='mobile-back-to-panel' onClick={() => onHideVehicleSelectionPanel()}>
                                <img className='arrow-icon' src={`${getCMSAssets()}/global/arrow-black.svg`} />
                                Back
                            </div>
                            :
                            <></>
                        }
                        <Row>
                            <Col xs={12} md={4}>
                                <div className='global-vehicle-card' >
                                    <div className='vehicle-img-box' onClick={(e) => toConfigurate(e)}>
                                        <img src={`${getCMSAssets()}/global/RED - sport - 1.png`} />
                                    </div>
                                    <p className='vehicle-brand' onClick={(e) => toConfigurate(e)}>BUILD & PRICE</p>
                                    {/* <p className='vehicle-price'>From: $102,990</p> */}
                                    <div className='vehicle-card-divider'></div>
                                    <div className='vehicle-bottom-status'>
                                        <a className='vehicle-status-box' onClick={() => toMOVPage()}>
                                            <h6>LEARN MORE</h6>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
              
           
            
            {/* <div className='close-panel-btn-container'>
                <Button className='close-panel-btn' onClick={() => onHidePanel() }>
                    <h6>Close Flyout</h6>
                </Button>
            </div> */}
        </div>
    )
}

export default GlobalSelectVehicle