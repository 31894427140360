import React, {
    createRef,
    useRef,
    useLayoutEffect,
    useState,
    useContext,
    createContext,
    useEffect
} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import {
    IPageLanguageState,
    IImagesState,
    getImageLinkByID2,
    getCMSAssets,
    getFrontendUrl
} from 'helpers/Uri';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isNil } from 'lodash';
import { IAppState } from 'model/IAppState';
import { ISessionState } from "model/ISessionState";
import * as signInViewModel from 'model/ISignViewModel';
import * as userService from 'services/User';
import { gotSessionAction } from "stores/Session";
import { loginRequest } from 'helpers/authConfig';
import * as rdEnum from "helpers/enum";
import * as orderService from 'services/Order';
import Spinner from 'components/Spinner';

export const AuthPage = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeAccount = instance.getActiveAccount();


    const checkoutCustomerFromStore = useSelector((state: IAppState) => {
        return state.checkoutCustomer;
    });

    const doSetupSession = async () => {

        let email: string = activeAccount?.idTokenClaims?.email as string;
        if (email == "") return;

        //let yanaCustomerID = "649ed38a-9995-409a-8222-c791767b49d5";
        //let yanaCustomerNo = "CST20240812033513";
        let user: ISessionState = {
            Email: email || "",
            YanaCustomerID: "",
            YanaCustomerNo: ""
        };

        let response = await userService.setSession(email);
        if (response.status) {
            //for testing
            user = {
                ...user,
                YanaCustomerID: response.data.yanaCustomerID, //, "649ed38a-9995-409a-8222-c791767b49d5";
                YanaCustomerNo: response.data.yanaCustomerNo,   //"CST20240812033513";
            }

            dispatch(gotSessionAction(user));

            if (!isNil(checkoutCustomerFromStore)) {
                //go to customer checkout
                navigate(`${getFrontendUrl()}/?redirectPage=${rdEnum.enumRedirectPage.CheckoutPaymentPage}`);
            }
            else {
                let orderResponse = await orderService.getOrderByYanaCustomerID(user.YanaCustomerID, user.YanaCustomerNo);
                if (orderResponse.status) {
                    if (orderResponse.data.length == 1) {
                        let url = `${getFrontendUrl()}/my-orders/${btoa(orderResponse.data[0].yanaOrderIDs.yanaOrderID)}/${btoa(orderResponse.data[0].yanaOrderIDs.yanaOrderNo)}`;
                        navigate(url);
                    }
                    else {
                        navigate(`${getFrontendUrl()}/my-orders`);
                    }
                }
                else {
                    navigate(`${getFrontendUrl()}/my-orders`);
                }
            }
        }

    }

    useEffect(() => {
        if (!isNil(activeAccount)) {
            doSetupSession()
        }
    }, [activeAccount]);

    useEffect(() => {
        if (window.digitalData) {
            window.digitalData = {
                pageInfo: {},
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
        }
    }, []);

    return (
        <div className="auth-container">
            {/* <ProgressSpinner
                style={{ width: '64px', height: '64px' }}
                strokeWidth="5"
                animationDuration=".8s"
            /> */}
            <Spinner spinnerWidth={64} />
        </div>
        //<div className='pre-loader'>
        //    <img className="img" alt="Info icon" src={`${getCMSAssets()}/home/brand-loader.gif`} />
        //</div>
    );
}