import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { filter, includes, find, isNil } from 'lodash';

import { AutoComplete } from 'primereact/autocomplete';
//import { getAddress } from 'services/GoogleApi';

import * as locationService from 'services/Locations';
export const GoogleAutoCompletePage = () => {
    //const [value, setValue] = useState('');
    //const [items, setItems] = useState([]);
    const [address, setAddress] = useState("");
    const [addressList, setAddressList] = useState<any>(null);
    //const [addressList, setAddressList] = useState<any>([
    //    {
    //        "fullAddress": "4 Rochester Street, Homebush NSW 2140",
    //        "name": "4 Rochester Street, Homebush NSW 2140",
    //        "postcode": "2140",
    //        "suburb": "Homebush"
    //    },
    //    {
    //        "fullAddress": "4 Roche Street, Hawthorn VIC 3122",
    //        "name": "4 Roche Street, Hawthorn VIC 3122",
    //        "postcode": "3122",
    //        "suburb": "Hawthorn"
    //    },
    //    {
    //        "fullAddress": "4 Rochester Street, Botany NSW 2019",
    //        "name": "4 Rochester Street, Botany NSW 2019",
    //        "postcode": "2019",
    //        "suburb": "Botany"
    //    },
    //    {
    //        "fullAddress": "4 Rochester Street, Gregory Hills NSW 2557",
    //        "name": "4 Rochester Street, Gregory Hills NSW 2557",
    //        "postcode": "2557",
    //        "suburb": "Gregory Hills"
    //    },
    //    {
    //        "fullAddress": "4 Roches Terrace, Williamstown VIC 3016",
    //        "name": "4 Roches Terrace, Williamstown VIC 3016",
    //        "postcode": "3016",
    //        "suburb": "Williamstown"
    //    }
    //]);

    const doGetAddress = async (event: any) => {
       // return addressList;
        //return addressList[0];
        let returnAddressList:any=[]
        let keywords = event.query;
        if (keywords.length > 4) {
            let result = await locationService.getAddress("au", keywords);
            if (result.status) {
                setAddressList(result.data);
                return;
            }
        }
        setAddressList(returnAddressList);
    }

    const addressItemTemplate = (item: any) => {
        if (!isNil(item)) {
            return (
                <div>{item.fullAddress}</div>
            )
        }
        return (<></>);

    }

    const selectedItemTemplate = (item: any) => {
        if (!isNil(item)) {
            return (
                <div>{item.fullAddress}</div>
            )
        }
        return (<></>);
    }

    return (
        <div className="address-wrapper">Address: 
            <AutoComplete
                field="fullAddress"
                className="address-input"
                placeholder="Please enter at least 4 characters"
                value={address}
                suggestions={addressList}
                completeMethod={doGetAddress}
                onChange={(e) => setAddress(e.value)}
            />
            {/*<AutoComplete*/}
            {/*    className="address-input"*/}
            {/*    placeholder="Please enter at least 4 characters"*/}
            {/*    value={value}*/}
            {/*    suggestions={items}*/}
            {/*    completeMethod={search}*/}
            {/*    onChange={(e) => setValue(e.value)} />*/}
        </div>
    );

}