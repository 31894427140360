import React, {
    createRef,
    useRef,
    useLayoutEffect,
    useState,
    useContext,
    createContext,
    useEffect
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { MainLayoutPage } from 'LayoutPages';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';

import {
    getFrontendUrl,
} from 'helpers/Uri';
import { ISessionState } from "model/ISessionState";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { gotSessionAction } from "stores/Session";

import * as signInViewModel from 'model/ISignViewModel';
import * as userService from 'services/User';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useAzureB2C } from 'helpers/useAzureB2C';

export const SignInPage = () => {
    const azureB2C = useAzureB2C();
    //const gaEventTracker = useAnalyticsEventTracker('SignIn');

    //const dispatch = useDispatch();
    //const navigate = useNavigate();
    //const [searchParams] = useSearchParams();
    //const redirectPage = searchParams.get('redirectPage') || '';

    //const { instance } = useMsal();  
    //const activeAccount = instance.getActiveAccount();

    //useEffect(() => {
    //    console.log("sign-in");
    //    if (isNil(activeAccount)) {
    //        console.log(activeAccount);
    //        azureB2C.signIn();
    //    }
    //    else {
    //        navigate(`${getFrontendUrl()}/auth`);
    //    }
    //}, [activeAccount]);

    //useEffect(() => {
    //    azureB2C.signIn();
    //}, []);

    setTimeout(() => {
        azureB2C.signIn();
    }, 1000);

    return (
        <></>
    );
};

