import { getCMSAssets } from 'helpers/Uri'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import CarCarouselDialog, { CarouselDialogRef } from '../../components/CarCarouselDialog'
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { IAppState } from 'model/IAppState';

const MobileImageCarousel = (props: any) => {

    const { variantAWD, exteriorColor, interiorColor, carouselInterval, images, mobileImages, isUseImages, sectionStatus } = props;

    const isMobile = useCheckMobileScreen();


    const [vehicle, setVehicle] = useState({
        variantAWD: "",
        exteriorColor: "",
        interiorColor: ""
    });

    const [mobileCarouselPopup, setMobileCarouselPopup] = useState({
        isShowExteriorPopup: false,
        currentExteriorIndex: 0,
        isShowInteriorPopup: false,
        currentInteriorIndex: 0,
        isShowSummaryPopup: false,
        currentSummaryIndex: 0,
    })

    useEffect(() => {
        setVehicle({
            variantAWD: variantAWD,
            exteriorColor: exteriorColor,
            interiorColor: interiorColor
        })
    }, [])

    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const sectionName = useSelector((state: IAppState) => {
        return state.mainSection.mainSection;
    });

    const getVariantImages = (index: number) => {
            return vehicleFromStore?.SelectedVariant?.VariantPicture != null && vehicleFromStore?.SelectedVariant?.VariantPicture.length > index
                ? vehicleFromStore?.SelectedVariant?.VariantPicture[index] : "";
    }
    const getExteriorImages = (index: number) => {
        return vehicleFromStore?.SelectedExterior?.ExteriorPicture != null && vehicleFromStore?.SelectedExterior?.ExteriorPicture.length > index
            ? vehicleFromStore?.SelectedExterior?.ExteriorPicture[index] : "";
    }


    const getInteriorImages = (index: number) => {
        //if (isMobile) {
        //    return vehicleFromStore?.SelectedInterior?.InteriorPictureMobile != null && vehicleFromStore?.SelectedInterior?.InteriorPictureMobile.length > index
        //        ? vehicleFromStore?.SelectedInterior?.InteriorPictureMobile[index] : "";
        //}
        //else {
            return vehicleFromStore?.SelectedInterior?.InteriorPicture != null && vehicleFromStore?.SelectedInterior?.InteriorPicture.length > index
                ? vehicleFromStore?.SelectedInterior?.InteriorPicture[index] : "";
        //}
    }


    const carouselDialogRef = useRef<CarouselDialogRef | null>(null);

    const directionButtons = (direction: any) => {
        return (
            <span
                aria-hidden="true"
                className={direction === "Next" ? "button-next" : "button-prev"}
            >
                <img className="image" src={`${getCMSAssets()}/main/${direction}-round.svg`} />
            </span>
        );
    };

    const handleOpen = (pageNumber: number) => {
        if (carouselDialogRef.current) {
            carouselDialogRef.current.handleOpen(pageNumber);
        }
    };

    const handleMobileCarouselPopup = (index: any, sectionName: any) => {
        if (sectionName == 'exterior') {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowExteriorPopup: true,
                currentExteriorIndex: index
            })
        } else if (sectionName == 'interior') {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowInteriorPopup: true,
                currentInteriorIndex: index
            })
        } else {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowSummaryPopup: true,
                currentSummaryIndex: index
            })
        }
    }

    const handleCloseMobileCarousel = (sectionName: any) => {
        if (sectionName == 'exterior') {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowExteriorPopup: false,
            })
        } else if (sectionName == 'interior') {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowInteriorPopup: false,
            })
        } else {
            setMobileCarouselPopup({
                ...mobileCarouselPopup,
                isShowSummaryPopup: false,
            })
        }
    } 

    // const [ vehicleList, setVehicleList ] = useState<any>([]);

    // const productTemplate = (product: any) => {
    //     return (
    //         <div className="product-item">
    //             <div className="product-item-content">
    //                 <img className="image" src={product} />
    //             </div>
    //         </div>
    //     );
    // }

    // useEffect(() => {
    //     const updateVehicleList = async () => {
    //         let list = [];
            
    //         list.push(await getExteriorImages(0));
    //         list.push(await getExteriorImages(1));
    //         list.push(await getExteriorImages(2));
    //         list.push(await getExteriorImages(3)); // Awaiting completion before pushing
            
    //         setVehicleList(list); 
    //       };
          
    //       updateVehicleList();
    // }, [])

    console.log(mobileCarouselPopup.currentSummaryIndex)

    return (
        <>
            {
                sectionName == 'exterior' && !mobileCarouselPopup.isShowExteriorPopup
                ?
                <div className='mobile-main-section-images-container'>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(0, sectionName)}>
                        <img className="image" src={getExteriorImages(0)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(1, sectionName)}>
                        <img className="image" src={getExteriorImages(1)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(2, sectionName)}>
                        <img className="image" src={getExteriorImages(2)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(3, sectionName)}>
                        <img className="image" src={getExteriorImages(3)} />
                    </div>
                </div>
                :
                sectionName == 'exterior' && mobileCarouselPopup.isShowExteriorPopup
                ?
                <div className='mobile-main-section-images-container carousel'>
                    <div className='mobile-main-section-images-inner'>
                        <Carousel
                            defaultActiveIndex={mobileCarouselPopup.currentExteriorIndex}
                            prevIcon = { <img alt="" src={`${getCMSAssets()}/main/Prev.svg`} /> }
                            nextIcon = { <img alt="" src={`${getCMSAssets()}/main/Next.svg`} /> }     
                        >
                            <Carousel.Item 
                                    // onClick={() => handleOpen(0)}
                            >
                                <img className="image" src={getExteriorImages(0)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(1)}
                            >
                                <img className="image" src={getExteriorImages(1)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(2)}
                            >
                                <img className="image" src={getExteriorImages(2)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(3)}
                            >
                                <img className="image" src={getExteriorImages(3)} />
                            </Carousel.Item>
                        </Carousel>
                        <img alt="" className='close-icon' onClick={() => handleCloseMobileCarousel(sectionName)} src={`${getCMSAssets()}/main/cross.svg`} />
                    </div>
                </div>
                :
                sectionName == 'interior' && !mobileCarouselPopup.isShowInteriorPopup
                ?
                <div className='mobile-main-section-images-container'>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(0, sectionName)}>
                        <img className="image" src={getInteriorImages(0)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(1, sectionName)}>
                        <img className="image" src={getInteriorImages(1)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(2, sectionName)}>
                        <img className="image" src={getInteriorImages(2)} />
                    </div>
                </div>
                 :
                 sectionName == 'interior' && mobileCarouselPopup.isShowInteriorPopup
                 ?
                 <div className='mobile-main-section-images-container carousel'>
                     <div className='mobile-main-section-images-inner'>
                         <Carousel
                             defaultActiveIndex={mobileCarouselPopup.currentInteriorIndex}
                             prevIcon = { <img alt="" src={`${getCMSAssets()}/main/Prev-white.svg`} /> }
                             nextIcon = {<img alt="" src={`${getCMSAssets()}/main/Next-white.svg`} /> }
                         >
                             <Carousel.Item 
                                     // onClick={() => handleOpen(0)}
                             >
                                 <img className="image" src={getInteriorImages(0)} />
                             </Carousel.Item>
                             <Carousel.Item 
                                 // onClick={() => handleOpen(1)}
                             >
                                 <img className="image" src={getInteriorImages(1)} />
                             </Carousel.Item>
                             <Carousel.Item 
                                 // onClick={() => handleOpen(2)}
                             >
                                 <img className="image" src={getInteriorImages(2)} />
                             </Carousel.Item>
                         </Carousel>
                         <img alt="" className='close-icon' onClick={() => handleCloseMobileCarousel(sectionName)} src={`${getCMSAssets()}/main/cross-white.svg`} />
                     </div>
                 </div>
                :
                sectionName != 'global' && sectionName != 'variant' && !mobileCarouselPopup.isShowSummaryPopup
                ?
                <div className='mobile-main-section-images-container'>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(0, 'summary')}>
                        <img className="image" src={getExteriorImages(0)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(1, 'summary')}>
                        <img className="image" src={getExteriorImages(1)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(2, 'summary')}>
                        <img className="image" src={getExteriorImages(2)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(3, 'summary')}>
                        <img className="image" src={getExteriorImages(3)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(4, 'summary')}>
                        <img className="image" src={getInteriorImages(0)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(5, 'summary')}>
                        <img className="image" src={getInteriorImages(1)} />
                    </div>
                    <div className='mobile-vehicle-image-card' onClick={() => handleMobileCarouselPopup(6, 'summary')}>
                        <img className="image" src={getInteriorImages(2)} />
                    </div>
                </div>
                :
                sectionName != 'global' && sectionName != 'variant' && mobileCarouselPopup.isShowSummaryPopup
                ?
                <div className='mobile-main-section-images-container carousel'>
                    <div className='mobile-main-section-images-inner'>
                        <Carousel
                            defaultActiveIndex={mobileCarouselPopup.currentSummaryIndex}
                            prevIcon = { 
                                mobileCarouselPopup.currentSummaryIndex < 4
                                ?
                                <img alt="" src={`${getCMSAssets()}/main/Prev.svg`} /> 
                                :
                                <img alt="" src={`${getCMSAssets()}/main/Prev-white.svg`} /> 
                            }
                            nextIcon = {
                                mobileCarouselPopup.currentSummaryIndex < 4
                                ?
                                <img alt="" src={`${getCMSAssets()}/main/Next.svg`} /> 
                                :
                                <img alt="" src={`${getCMSAssets()}/main/Next-white.svg`} /> 
                            }
                        >
                             <Carousel.Item 
                                    // onClick={() => handleOpen(0)}
                                    accessKey='0'
                            >
                                <img className="image" src={getExteriorImages(0)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(1)}
                                 accessKey='1'
                            >
                                <img className="image" src={getExteriorImages(1)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(2)}
                                 accessKey='2'
                            >
                                <img className="image" src={getExteriorImages(2)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(3)}
                                 accessKey='3'
                            >
                                <img className="image" src={getExteriorImages(3)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                    // onClick={() => handleOpen(0)}
                                     accessKey='4'
                            >
                                <img className="image" src={getInteriorImages(0)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(1)}
                                 accessKey='5'
                            >
                                <img className="image" src={getInteriorImages(1)} />
                            </Carousel.Item>
                            <Carousel.Item 
                                // onClick={() => handleOpen(2)}
                                 accessKey='6'
                            >
                                <img className="image" src={getInteriorImages(2)} />
                            </Carousel.Item>
                        </Carousel>
                        <img alt="" className='close-icon' onClick={() => handleCloseMobileCarousel('summary')} src={`${getCMSAssets()}/main/cross-white.svg`} />
                    </div>
                </div>
                :
                <></>
            }

            <CarCarouselDialog slideNumber={0} vehicle={vehicle} ref={carouselDialogRef} />
        </>  
    )
}

export default MobileImageCarousel



            {/* {
                sectionStatus == 'all'
                ?
                <Carousel
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Prev")}
                    interval={carouselInterval}
                >
                    <Carousel.Item 
                        // onClick={() => handleOpen(0)}
                    >
                        <img className="image" src={getExteriorImages(0)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(1)}
                    >
                        <img className="image" src={getExteriorImages(1)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(2)}
                    >
                        <img className="image" src={getExteriorImages(2)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(3)}
                    >
                        <img className="image" src={getExteriorImages(3)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(4)}
                    >
                        <img className="image" src={getInteriorImages(0)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(5)}
                    >
                        <img className="image" src={getInteriorImages(1)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(6)}
                    >
                        <img className="image" src={getInteriorImages(2)} />
                    </Carousel.Item>
                </Carousel>
                :
                sectionStatus == 'exterior'
                ?
                <Carousel
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Prev")}
                    interval={carouselInterval}
                >
                    <Carousel.Item 
                        // onClick={() => handleOpen(0)}
                    >
                        <img className="image" src={getExteriorImages(0)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(1)}
                    >
                        <img className="image" src={getExteriorImages(1)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(2)}
                    >
                        <img className="image" src={getExteriorImages(2)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(3)}
                    >
                        <img className="image" src={getExteriorImages(3)} />
                    </Carousel.Item>
                </Carousel>
                :
                sectionStatus == 'interior'
                ?
                <Carousel
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Prev")}
                    interval={carouselInterval}
                >
                    <Carousel.Item 
                        // onClick={() => handleOpen(0)}
                    >
                        <img className="image" src={getExteriorImages(0)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(1)}
                    >
                        <img className="image" src={getExteriorImages(1)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(2)}
                    >
                        <img className="image" src={getExteriorImages(2)} />
                    </Carousel.Item>
                    <Carousel.Item 
                        // onClick={() => handleOpen(3)}
                    >
                        <img className="image" src={getExteriorImages(3)} />
                    </Carousel.Item>
                </Carousel>
                :
                <></>
            } */}


 {/* {
                    sectionStatus == 'exterior' || sectionStatus == 'all'
                    ?
                    <>
                        <Carousel.Item 
                            // onClick={() => handleOpen(0)}
                        >
                            <img className="image" src={getExteriorImages(0)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(1)}
                        >
                            <img className="image" src={getExteriorImages(1)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(2)}
                        >
                            <img className="image" src={getExteriorImages(2)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(3)}
                        >
                            <img className="image" src={getExteriorImages(3)} />
                        </Carousel.Item>
                    </>
                    :
                    <></>
                }
                {
                    sectionStatus == 'interior' || sectionStatus == 'all'
                    ?
                    <>
                        <Carousel.Item 
                            // onClick={() => handleOpen(4)}
                        >
                            <img className="image" src={getInteriorImages(0)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(5)}
                        >
                            <img className="image" src={getInteriorImages(1)} />
                        </Carousel.Item>
                        <Carousel.Item 
                            // onClick={() => handleOpen(6)}
                        >
                            <img className="image" src={getInteriorImages(2)} />
                        </Carousel.Item>
                    </>
                    :
                    <></>
                } */}