import { getBackendPath } from 'helpers/Uri';
import * as deliveryModel from 'model/IDelivery';
import * as rdHelper from 'helpers/Uri';

export const getLocations = async (request: deliveryModel.IGetPickupLocationRequest): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Location/get-pickup-locations`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getAddress = async (country: string, keywords: string): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            country: country,
            keywords: keywords,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Location/get-address`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getDeliveryPrice = async (
    delivery: deliveryModel.IGetPriceRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(delivery),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/Location/get-delivery-price`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getDistance = async (
    delivery: deliveryModel.IGetPriceRequest
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(delivery),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/GoogleAPIs/get-distance`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};