import React, { useEffect, useRef, useState } from 'react';
import { Footer } from 'scene/Layout/Footer';
import { useNavigate } from 'react-router-dom';
import { MainLayoutPage } from 'LayoutPages';
import {
    currencyFormatWithBlank
} from 'helpers/Uri';
import { Button, Carousel, Container } from 'react-bootstrap';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { MainMenu } from 'scene/Layout/MainMenu';
import Variant from './Main/variant/Variant';
import Exterior from './Main/exterior/Exterior';
import Interior from './Main/interior/Interior';
import Accessories from './Main/accessories/Accessories';
import Checkout from './Main/checkout/Checkout';
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'model/IAppState';
import { checkMainSection, setScrollPosition } from 'stores/ScrollPosition';
import CustomerPayment from './Main/checkout/CustomerPayment';
import Delivery from './Main/checkout/Delivery';
import PaymentOptions, { ChildRef } from '../components/PaymentOptions';
import CarCarousel from 'components/CarCarousel';
import CheckoutWaitlist from './Main/checkout/CheckoutWaitlist';
//import CheckoutWaitlistNoPCICompliant from './Main/checkout/CheckoutWaitlistNoPCICompliant';
//import CheckoutWaitlistDropin from './Main/checkout/CheckoutWaitlistCheckin';
import NoStorageReminder from 'components/NoInventoryReminder';
import { setTimeout } from 'timers';
import { hideGlobalPage } from 'stores/MainRightPage';
import { isNil } from 'lodash';
import * as selectedDeliveryStore from 'stores/UserSelectedDelivery';

import { useScroll } from './Main/ScrollToSection';


import * as rdEnum from 'helpers/enum';
import * as rdHelper from 'helpers/Uri';
import * as paymentOptionModel from 'model/IPaymentOptions';
import * as paymentOptionService from 'services/PaymentOptions';
import * as paymentOptionsStore from 'stores/PaymentOptions';
import * as paymentOptionStore from 'stores/PaymentOptions';
import * as driveawaycostHelper from 'businessHelpers/DriveawayCostHelper';

import Charging from './Main/charging/Charging';
import MainLeftImageGrid from './Main/MainLeftImageGrid';
import { setMainPageNumber } from 'stores/MainPageNumber';
import DriveawayPriceDialog from '../components/DriveawayPriceDialog';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import MobileImageCarousel from './Main/MobileImageCarousel';
import { useMsal } from "@azure/msal-react";
import { useScreenSize } from 'helpers/useSreenSize';
import { useSatellite } from 'helpers/useSatellite';

interface MainPageProps {
    // pageScroll: number;
    // isToPayment: boolean;
    linkKey: string | null,
    selectedCountry: string | null,
    selectedState: string | null,
    selectedVariant: string | null,
    selectedExterior: string | null,
    selectedInterior: string | null,
    isShowBottomPanel: boolean | null,
}

export const MainPage: React.FC<MainPageProps> = ({ linkKey, selectedCountry, selectedState, selectedVariant, selectedExterior, selectedInterior, isShowBottomPanel }) => {
    // : React.FC<MainPageProps> = ({ pageScroll, isToPayment, linkKey, selectedVariant, selectedExterior, selectedInterior }) => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const screenSize = useScreenSize();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const satellite = useSatellite();
    const rightSectionRef = useRef<HTMLDivElement>(null);

    // const variantRef = useRef<HTMLDivElement>(null);
    // const exteriorRef = useRef<HTMLDivElement>(null);
    // const interiorRef = useRef<HTMLDivElement>(null);
    // const financeRef = useRef<HTMLDivElement>(null);
    // const chargingRef = useRef<HTMLDivElement>(null);
    // const paymentRef = useRef<HTMLDivElement>(null);
    // const deliveryComponentRef = useRef<any>(null);
    // const deliveryRef = useRef<HTMLDivElement>(null);
    // const checkoutRef = useRef<HTMLDivElement>(null);
    // const watchlistRef = useRef<HTMLDivElement>(null);

    const [imgExpand, setImgExpand] = useState<Boolean>(false);

    const topPanel = document.querySelector('.top-panel') as HTMLElement;
    const bottomPanel = document.querySelector('.bottom-panel') as HTMLElement;

    const [expanded, setExpanded] = useState({
        topExpand: false,
        bottomExpand: false,
    });

    const isMobile = useCheckMobileScreen();

    const isHideGlobal = useSelector((state: IAppState) => {
        return state.mainRightPage;
    });

    const paymentOpionsRef = useRef<ChildRef | null>(null);

    //Customise Exterior & Interior & Variant AWD of Cadillac
    const [customize, setCustomize] = useState({
        variantAWD: 'Luxury',
        exteriorColor: 'Argent Silver Metallic',
        interiorColor: 'Juniper Nappa',
        addsOn: [],
        charging: [],
        customerType: 'business',
        paymentType: 'Cash',
        postcode: '',
        invalidPostcode: false,
        deliveryType: 'Pick Up',
    });

    const customerType = useSelector((state: IAppState) => {
        return state.customerType;
    });



    //paymentPageStatus shows the Visa payment page status, 
    //if the first variant, exterior... section, its status is 0 and does not appear
    //if has inventory, it shows Visa input panel, its status is 1
    //if no inventory, click join the waitlist from the first page, its status is 2 and Visa input disappear.
    // const [paymentPageStatus, setPaymentPageStatus] = useState(0);
    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber;
    });

    const [activeImage, setActiveImage] = useState(1);
    // const [shouldRevert, setShouldRevert] = useState(false);

    //store variables
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const paymentOptions = useSelector((state: IAppState) => {
        return state.paymentOptions;
    });
    const sectionName = useSelector((state: IAppState) => {
        return state.mainSection;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });
    const customerTypeFromStore = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const linkSourceFromStore = useSelector((state: IAppState) => {
        return state?.linkSource;
    });


    const [driveawayRequestData, setDriveawayRequestData] = useState<paymentOptionModel.IDriveAwayRequest>(driveawaycostHelper.getInitDriveawayCostRequest());
    const abortControllerInitialValue: any = new (
        window as any
    ).AbortController();
    const [costAbortController, setCostAbortController] = useState(
        abortControllerInitialValue,
    );

    var currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });



    const handleCustomer = (type: any) => {
        setCustomize({
            ...customize,
            customerType: type
        })
    }

    const handlePayment = (type: any) => {
        setCustomize({
            ...customize,
            paymentType: type
        })
    }

    //const handlePostcode = (postcode: any) => {
    //    setCustomize({
    //        ...customize,
    //        postcode: postcode
    //    })
    //}

    const handleDelivery = (type: any) => {
        setCustomize({
            ...customize,
            deliveryType: type
        })
    }

    const handleImgExpand = (selectedClassName: any) => {
        const imageDivs = document.querySelectorAll('.transition-group-full');

        imageDivs.forEach((element) => {
            if (selectedClassName == 'cadillac-full-0' || selectedClassName == 'cadillac-front-1' && topPanel && bottomPanel) {
                // topPanel.style.setProperty('--row-0-flex-basis', '100%');
                // bottomPanel.style.setProperty('--row-1-flex-basis', '0%');
                topPanel.classList.add('expand');
                topPanel.classList.remove('collapse');
                bottomPanel.classList.add('collapse');
                bottomPanel.classList.remove('expand');
                setExpanded({
                    topExpand: true,
                    bottomExpand: false,
                })
            } else if (selectedClassName == 'cadillac-back-2' || selectedClassName == 'cadillac-side-3' && topPanel && bottomPanel) {
                // topPanel.style.setProperty('--row-0-flex-basis', '0%');
                // bottomPanel.style.setProperty('--row-1-flex-basis', '100%');
                topPanel.classList.add('collapse');
                topPanel.classList.remove('expand');
                bottomPanel.classList.add('expand');
                bottomPanel.classList.remove('collapse');
                setExpanded({
                    topExpand: false,
                    bottomExpand: true,
                })
            }

            let tileIndex = parseInt(selectedClassName.split('-').pop());

            for (let i = 0; i <= 3; i++) {
                const value = i === tileIndex ? '100%' : '0%';
                document.documentElement.style.setProperty(`--tile-${i}-flex-basis`, value);
            }
        });

        setImgExpand(true);
    }

    const handleClose = () => {

        setExpanded({
            topExpand: false,
            bottomExpand: false,
        })
        setImgExpand(false);

        if (sectionName.mainSection == 'variant') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '75%');
            document.documentElement.style.setProperty(`--tile-1-flex-basis`, '25%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '60%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '40%');
        } else if (sectionName.mainSection == 'exterior') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '60%');
            document.documentElement.style.setProperty(`--tile-1-flex-basis`, '40%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '50%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '50%');
        } else if (sectionName.mainSection == 'interior') {
            document.documentElement.style.setProperty(`--tile-0-flex-basis`, '100%');
            document.documentElement.style.setProperty(`--tile-2-flex-basis`, '50%');
            document.documentElement.style.setProperty(`--tile-3-flex-basis`, '50%');
            topPanel?.style.setProperty('--row-0-flex-basis', '60%');
            bottomPanel?.style.setProperty('--row-1-flex-basis', '40%');
        }

        // topPanel?.classList.remove('expand', 'collapse');
        // bottomPanel?.classList.remove('expand', 'collapse');

    }

    const scrollToSectionRef = (ref: React.RefObject<HTMLDivElement>) => {
        // const refArray = [variantRef, exteriorRef, interiorRef, financeRef, deliveryRef, checkoutRef];
        // refArray.forEach((refItem: any, index: any) => {
        //     console.log(ref.current, refItem.current);
        //     if (ref.current != null && refItem) {
        //         if (ref.current == refItem.current) {
        //             ref.current.style.marginTop = '80px';
        //         } else {
        //             ref.current.style.marginTop = '0px';
        //         }
        //     }
        // }); 
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // or 'center', 'end', 'nearest'
                inline: 'center', // or 'start', 'center', 'end'
            });
        }
    };

    //const [count, setCount] = useState({
    //    maxRange: 126,
    //    quarterCharge: 110,
    //    zeroToHundredTime: 2.8,
    //});
    //const milestones = [
    //    {
    //        maxRange: 195,
    //        quarterCharge: 110,
    //        zeroToHundredTime: 2.8,
    //    },
    //    {
    //        maxRange: 288,
    //        quarterCharge: 135,
    //        zeroToHundredTime: 3.4,
    //    },
    //    {
    //        maxRange: 357,
    //        quarterCharge: 158,
    //        zeroToHundredTime: 3.9,
    //    },
    //    {
    //        maxRange: 473,
    //        quarterCharge: 180,
    //        zeroToHundredTime: 4.6,
    //    },
    //    {
    //        maxRange: 530,
    //        quarterCharge: 200,
    //        zeroToHundredTime: 5.3,
    //    }
    //];
    //const [index, setIndex] = useState(0);

    // useEffect(() => {
    //     if (shouldRevert) {
    //       setTimeout(() => {
    //         setActiveImage(1);
    //         setShouldRevert(false);
    //       }, 500); // Adjust the timing as needed
    //     }
    //   }, [shouldRevert]);

    // Function to open the dialog in the child component
    const openCashFinanceDialog = () => {
        if (paymentOpionsRef.current) {
            paymentOpionsRef.current.openDialog();
            //dispatch(paymentOptionStore.updateSelectedPaymentOptionAction(rdEnum.enumPaymentOptions.Cash));
        }
    };

    useEffect(() => {

        window.scrollTo(0, 0);

        return () => { }
    }, [])

    //useEffect(() => {

    //    if (sectionName.mainSection != 'variant') {
    //        setCount({
    //            maxRange: 126,
    //            quarterCharge: 110,
    //            zeroToHundredTime: 2.8,
    //        });
    //        setIndex(0);
    //        return () => { }; // Cleanup function
    //    }

    //    const interval = setInterval(() => {
    //        if (index < milestones.length - 1) {
    //            setCount(milestones[index]);
    //            setIndex(index + 1);
    //        } else if (index === milestones.length - 1) {
    //            setCount(milestones[milestones.length - 1]);
    //            clearInterval(interval);
    //        }
    //    }, 30); // Change the interval duration as needed

    //    return () => {
    //        clearInterval(interval);
    //    }// Cleanup on unmount or index change
    //}, [sectionName.mainSection, index]); // Only trigger when index or milestones change

    useEffect(() => {
        handleClose();
    }, [sectionName.mainSection])

    useEffect(() => {
        setCustomize({
            ...customize,
            customerType: customerType
        })
    }, [customerType]);


    useEffect(() => {
        if (
            (!isNil(linkKey) && linkKey != "")
            || (!isNil(selectedCountry) && selectedCountry != "")
            || (!isNil(selectedVariant) && selectedVariant != "")
            || (!isNil(selectedExterior) && selectedExterior != "")
            || (!isNil(selectedInterior) && selectedInterior != "")
        ) {
            dispatch(hideGlobalPage(false));
            // dispatch(setScrollPosition('main'));
            // dispatch(checkMainSection('variant'));
            // setPaymentPageStatus(0);
            dispatch(setMainPageNumber(0));
            toVariantWithLinkKey();
            // document.body.style.overflowY = "scroll";
            // document.body.style.paddingRight = "0px";

        } else if (sectionName != 'checkoutForm') {
            // setPaymentPageStatus(0);
            dispatch(setMainPageNumber(0));
        }

        else {
            dispatch(hideGlobalPage(true));
            dispatch(checkMainSection('checkoutForm'));
            // setPaymentPageStatus(1);
            dispatch(setMainPageNumber(1));
            // scrollToSection('checkoutForm');
            // document.body.style.overflowY = 'scroll';
            // document.body.style.paddingRight = '0px';
        }

    }, [linkKey, selectedVariant, selectedExterior, selectedInterior])

    const { scrollToSection, variantRef, exteriorRef, interiorRef, financeRef, chargingRef, paymentRef, deliveryComponentRef, deliveryRef, checkoutRef, watchlistRef } = useScroll();

    const toVariantWithLinkKey = () => {

        dispatch(hideGlobalPage(false))
        setTimeout(() => {
            scrollToSection('variant')
        }, 300);

        // setPaymentPageStatus(0);
        dispatch(setMainPageNumber(0));
    }

    const toConfiguration = () => {
        let currentUrl = window.location.href;

        if (currentUrl.includes(`?redirectPage=${rdEnum.enumRedirectPage.CheckoutPaymentPage}`)) {

            currentUrl = currentUrl.replace(`?redirectPage=${rdEnum.enumRedirectPage.CheckoutPaymentPage}`, '');

            // Update the browser's URL without reloading the page
            window.history.replaceState({}, '', currentUrl);
        }

        dispatch(hideGlobalPage(false))
        setTimeout(() => {
            scrollToSection('payment')
        }, 300);

        // setPaymentPageStatus(0);
        dispatch(setMainPageNumber(0));
    }

    //const handleDeliveryValidation = () => {
    //    console.log("handleValidation");
    //}

    const toWaitlistPage = () => {
        if (isNil(deliveryComponentRef?.current)) return;
        let invalidDelivery = deliveryComponentRef?.current?.getValidate();
        if (invalidDelivery) {
            //SetTimeout to avoid click checkout button, validate function contains
            //a setErrorMessage to cause re-render, then need to click checkout button
            //twice to get to Delivery section.
            setTimeout(() => {
                scrollToSection(rdEnum.enumScrollSection.Delivery);
                dispatch(checkMainSection('delivery'));
            }, 350);
            return;
        }

        deliveryComponentRef?.current?.dispatchRegoAddress();

        if (linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink) {
            dispatch(setMainPageNumber(rdEnum.enumPage.CheckoutPayment));
        }
        else {
            if (selectedVehicleFromStore.HasStock) {
                // setPaymentPageStatus(rdEnum.enumPage.CheckoutPayment);
                dispatch(setMainPageNumber(rdEnum.enumPage.CheckoutPayment));
            } else {
                // setPaymentPageStatus(rdEnum.enumPage.JoinWaitList);
                dispatch(setMainPageNumber(rdEnum.enumPage.JoinWaitList));
            }
        }

        dispatch(checkMainSection('checkoutForm'));
        dispatch(hideGlobalPage(true));
        if (watchlistRef.current)
            watchlistRef.current.style.display = 'block';

        setTimeout(() => {
            scrollToSection(rdEnum.enumScrollSection.CheckoutForm);
        }, 100);
    }

    //get drive away cost
    useEffect(() => {
        buildDriveawayCostRequest();

    }, [selectedDeliveryFromStore,
        selectedVehicleFromStore,
        customerTypeFromStore,
    ]);



    useEffect(() => {

        //to prevent multiple call
        if (driveawayRequestData.Country == ""
            || driveawayRequestData.VariantID == "" || driveawayRequestData.ExteriorID == "" || driveawayRequestData.InteriorID == ""
            || driveawayRequestData.SubTotal == 0
        ) {
            return;
        }
        doGetDriveAwayCost();
    }, [JSON.stringify(driveawayRequestData)])

    const buildDriveawayCostRequest = () => {
        //if is pickup, distance is from pickup location
        //if is delivery, distance is from DeliveryDistance
        let distance = 0;
        if (selectedDeliveryFromStore?.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            distance = 0;
        }
        else {
            distance = selectedDeliveryFromStore?.DeliveryDistance || 0;
        }

        //default state is selected state on 1st popup
        //if has primary address, use primary address's state, postcode and suburb
        let state = "";
        let postcode = "";
        let suburb = "";
        if (!isNil(selectedDeliveryFromStore?.PrimaryAddress) && selectedDeliveryFromStore?.PrimaryAddress != "") {
            state = selectedDeliveryFromStore.PrimaryState || "";
            postcode = selectedDeliveryFromStore.PrimaryPostcode || "";
            suburb = selectedDeliveryFromStore.PrimarySuburb || "";
        }
        else {
            state = selectedDeliveryFromStore?.State?.code || "";
            postcode = "";
            suburb = "";
        }

        let requestData: paymentOptionModel.IDriveAwayRequest = {
            Country: selectedDeliveryFromStore.Country?.code || "",
            State: state || "",
            VariantName: selectedVehicleFromStore?.VariantName || "",
            VariantID: selectedVehicleFromStore?.VariantID || "",
            VariantPrice: selectedVehicleFromStore?.VariantBasePrice || 0,
            ExteriorID: selectedVehicleFromStore?.ExteriorID || "",
            ExteriorName: selectedVehicleFromStore?.Exterior || "",
            ExteriorPrice: selectedVehicleFromStore?.ExteriorPrice || 0,
            InteriorID: selectedVehicleFromStore?.InteriorID || "",
            InteriorName: selectedVehicleFromStore?.Interior || "",
            InteriorPrice: selectedVehicleFromStore?.InteriorPrice || 0,
            Options: selectedVehicleFromStore?.Options || [],
            SubTotal: selectedVehicleFromStore.Subtotal || 0,
            DeliveryOption: selectedDeliveryFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            RegistrationType: customerTypeFromStore,
            Distance: distance,
            PostCode: postcode || "",
            Suburb: suburb || "",
            CallSource: "Main Page",
            IfCalculateFinance: true,
            DeliveryPrice: selectedDeliveryFromStore?.DeliveryPrice || 0,
            //VehicleLoading: vehicleFromStore.Loading,
        };
        setDriveawayRequestData(requestData);

    }


    const doGetDriveAwayCost = async () => {
        const abortController = new (window as any).AbortController();
        await setCostAbortController(abortController);

        try {
            let request = await paymentOptionService.getDriveAwayCost(driveawayRequestData, abortController.signal);
            if (request.status) {
                let driveAwayCostData = request.data.driveawayCostData;
                let driveAwayCost: paymentOptionModel.IDriveAwayCost = {
                    LuxuryCarTax: driveAwayCostData.luxuryCarTax,
                    LocalStampDuty: driveAwayCostData.localStampDuty,
                    LocalRegistrationCost: driveAwayCostData.localRegistrationCost,
                    LocalCTP: driveAwayCostData.localCTP,
                    LocalPlateFee: driveAwayCostData.localPlateFee,
                    HomeDelivery: driveAwayCostData.homeDelivery,
                    DriveawayPrice: driveAwayCostData.driveawayPrice,
                    OnRoadFee: driveAwayCostData.onRoadFeeSubtotal,
                    SubTotal: driveAwayCostData.subTotal,
                    VariantName: driveAwayCostData.variantName,
                    VariantID: driveAwayCostData.variantID,
                    VariantPrice: driveAwayCostData.variantPrice,
                    ExteriorID: driveAwayCostData.exteriorID,
                    ExteriorName: driveAwayCostData.exteriorName,
                    ExteriorPrice: driveAwayCostData.exteriorColourPrice,
                    InteriorID: driveAwayCostData.interiorID,
                    InteriorName: driveAwayCostData.interiorName,
                    InteriorPrice: driveAwayCostData.interiorColourPrice,
                    GST: driveAwayCostData.gst,
                    RoadUserChargers: driveAwayCostData.roadUserChargers,
                    TyreStewardshipFee: driveAwayCostData.tyreStewardshipFee,
                    Options: [],
                }
                dispatch(paymentOptionsStore.updateDriveawayCostAction(driveAwayCost, driveawayRequestData));

                //get loan payment
                let financeData = request.data.financeData;
                let updatedFinanceStore: paymentOptionModel.IFinance = {
                    DownPayment: 0,
                    FinalBalloonPayment: financeData.balloonAmount,
                    ComparisonRate: financeData.comparisonRate,
                    LoanPayment: financeData.loanPayment,
                    Term: financeData.term,
                    FrequencyName: financeData.frequencyName
                };
                dispatch(paymentOptionsStore.updateFinanceAction(updatedFinanceStore));

            }
        } catch (error: any) {
            if (error.name === 'AbortError') console.log('Request aborted!');
        } finally {
            //if (abortController.signal?.aborted)
        }
    }


    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        // Function to update the state with current window size
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup to avoid memory leaks
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setWindowDigitalData();
    }, [selectedDeliveryFromStore.Country?.code,
    selectedVehicleFromStore?.VariantName, selectedVehicleFromStore.VariantBasePrice,
    selectedVehicleFromStore?.Exterior, selectedVehicleFromStore?.Interior,
            paymentOptions.DriveAwayCost?.DriveawayPrice, windowSize, sectionName]);


    const [trackEvent, setTrackEvent] = useState("");
    useEffect(() => {
        if (sectionName.mainSection == rdEnum.enumScrollSection.Global) {
            return;
        }
        else if (sectionName.mainSection == rdEnum.enumScrollSection.Payment
            || sectionName.mainSection == rdEnum.enumScrollSection.Delivery
            || sectionName.mainSection == rdEnum.enumScrollSection.CheckoutForm) {
            setTrackEvent("checkout");
        }
        else {
            setTrackEvent(sectionName.mainSection);
        }
        
    }, [sectionName.mainSection])


    useEffect(()=> {
        satellite.trackEvent("config-step-load");
    }, [trackEvent]);


    const setWindowDigitalData = () => {
        let level3 = sectionName.mainSection;      
        if (sectionName.mainSection == rdEnum.enumScrollSection.Global) {
            window.digitalData = {
                pageInfo: { pageName: "" },
                internalCampaignInfo: {},
                articleInfo: {},
                visitorProfile: {},
                vehicleInfo: {},
                toolsInfo: {}
            };
            return;
        }
        else if (sectionName.mainSection == rdEnum.enumScrollSection.Payment
            || sectionName.mainSection == rdEnum.enumScrollSection.Delivery
            || sectionName.mainSection == rdEnum.enumScrollSection.CheckoutForm) {
            level3 = "checkout";
        }

        let pageName = "";
        if (selectedDeliveryFromStore.Country?.code == rdEnum.enumCountryCode.Australia) {
            pageName = "ca:oc:au:en:t1:lyriq-configurator:2025-lyriq:" + level3;
        }
        else if (selectedDeliveryFromStore.Country?.code == rdEnum.enumCountryCode.Australia) {
            pageName = "ca:oc:nz:en:t1:lyriq-configurator:2025-lyriq:" + level3;
        }

        

        let email: string = activeAccount?.idTokenClaims?.email as string;
        window.digitalData = {
            pageInfo: {
                pageName: pageName,
                siteSectionsLevel1: "lyriq-configurator",
                siteSectionsLevel2: "2025-lyriq",
                siteSectionsLevel3: level3,
                siteSectionsLevel4: "undefined",
                siteSectionsLevel5: "lyriq-configurator:2025-lyriq:" + level3,
                universalID: "AB52350",
                url: window.location.href,
                seoStrategyPageName: "Shop Now | Cadillac",
                pageType: "tool",
                languageSelected: "english",
                siteName: "quantum_au",
                brand: "Cadillac",
                country: selectedDeliveryFromStore.Country?.code,
                region: "oc",
                renderedExperience: screenSize,
                viewport: window.innerWidth + 'x' + window.innerHeight,
                orientation: window.innerWidth > window.innerHeight ? "landscape" : "portrait"
            },
            internalCampaignInfo: {},
            articleInfo: {},
            visitorProfile: {
                userProfileStateAuthentication: !isNil(email) && email != "" ? 'loggedIn' : 'loggedOut',
                userLoginStart: !isNil(email) && email != "" ? true : false,
                configProducts: null,
                configExtColor: "",
                configIntColorUpholstery: "",
                configModelPackageVersion: "",
                engine: "",
                transmission: ""
            },
            vehicleInfo: {
                vehicleType: "suv",
                modelName: "lyriq",
                modelYear: "2025",
                bodyStyle: "lyriq",
                trimLevel: selectedVehicleFromStore?.VariantName,
                condition: "new",
                vin: "",
            },
            toolsInfo: {
                configProducts: selectedVehicleFromStore?.VariantName,
                configExtColor: selectedVehicleFromStore?.Exterior,
                configIntColorUpholstery: selectedVehicleFromStore?.Interior || "",
                configModelPackageVersion: "",
                engine: "",
                transmission: "",
                wheels: "AWD",
                options: "",
                dealerName: "",
                dealerId: "",
                individualProductPrice: "",
                configuratorStartPrice: vehicleFromStore.SelectedVariant?.DriveawayPrice,
                configuratorEndPrice: paymentOptions.DriveAwayCost?.DriveawayPrice,
            }
        };

    }

    return (
        // <div className='main-page-container'>
        //     <div className='nav-menu'>
        //         <MainMenu scrollToSection={scrollToSection} />
        //     </div>

        //     <div className='main-layout-container' style={{ paddingTop: !isHideGlobal.isHideGlobal ? '385px' : '0' }}>
        <div className='main-page-container' style={{ marginTop: mainPageNumber == 0 ? '0' : '188px' }}>

            <div className={sectionName?.mainSection == 'global' ? 'nav-menu' : 'nav-menu collapsed'} >
                <MainMenu />
            </div>

            {
                !isMobile
                    ?
                    <MainLeftImageGrid />
                    :
                    <MobileImageCarousel sectionStatus="exterior" />
            }

            {/* <div className='right-section-container' ref={rightSectionRef}> */}
            <div className='right-sections-container'>
                <section ref={variantRef} className='nav_item'
                    data-section-id="variant"
                    style={{
                        display: mainPageNumber == 0 ? '' : 'none',
                        // paddingTop: !isHideGlobal.isHideGlobal ? '385px' : '0'
                        // paddingBottom: '180px',
                        // marginBottom: '160px'
                    }}>
                    <Variant />
                </section>
                <section ref={exteriorRef} className='nav_item exterior' data-section-id="exterior" style={{ display: mainPageNumber == 0 ? '' : 'none', paddingTop: !isMobile ? '0px' : '20px' }}>
                    <Exterior />
                </section>
                <section ref={interiorRef} className='nav_item interior' data-section-id="interior" style={{ display: mainPageNumber == 0 ? '' : 'none' }}>
                    <Interior />
                    {
                        !selectedVehicleFromStore.HasStock && linkSourceFromStore != rdEnum.enumLinkSource.PrivateInterestLink
                            ?
                            <NoStorageReminder />
                            :
                            <></>
                    }
                </section>

                <section ref={financeRef} className='nav_item accessories' data-section-id="accessories"
                    style={{
                        display: mainPageNumber == 0 ? '' : 'none',
                        // paddingBottom: '380px',
                    }}>
                    <Accessories />
                </section>
                <section ref={chargingRef} className='nav_item charging' data-section-id="charging"
                    style={{
                        display: mainPageNumber == 0 ? '' : 'none',
                        // paddingBottom: '380px',
                    }}>
                    <Charging />
                </section>
                <section ref={paymentRef} className='nav_item section' data-section-id="payment" style={{
                    display: mainPageNumber == 0 ? '' : 'none',
                    // paddingBottom: '15rem' 
                }}>
                    <CustomerPayment handleCustomer={handleCustomer} handlePayment={handlePayment} />
                </section>
                <section ref={deliveryRef} className='nav_item section' data-section-id="delivery" style={{
                    display: mainPageNumber == 0 ? '' : 'none',
                    // paddingBottom: '12rem' 
                }}>
                    <Delivery handleDelivery={handleDelivery} ref={deliveryComponentRef} />
                </section>
                <section ref={checkoutRef} className='nav_item section' data-section-id="checkout" style={{ display: mainPageNumber == 0 ? '' : 'none', paddingBottom: !selectedVehicleFromStore.HasStock ? '6rem' : '9rem' }}>
                    <Checkout checkoutFormPage={toWaitlistPage} scrollToSection={scrollToSection} />
                    {
                        !selectedVehicleFromStore.HasStock && linkSourceFromStore != rdEnum.enumLinkSource.PrivateInterestLink
                            ?
                            <div className='bottom-unavailable-panel' style={{ display: mainPageNumber == 0 ? '' : 'none' }}>
                                {/* <div className='no-storage-panel'>
                                <div className='or-panel'>or</div>
                            </div> */}
                                <NoStorageReminder />
                            </div>
                            :
                            <></>
                    }
                </section>

                <section ref={watchlistRef} className='nav_item checkoutForm section' data-section-id="checkoutForm" style={{ display: mainPageNumber == 0 ? 'none' : '', scrollMarginTop: '286px' }}>
                    {/*<CheckoutWaitlistDropin customize={customize} handleCustomer={handleCustomer}*/}
                    {/*    toConfiguration={toConfiguration}*/}
                    {/*    paymentPageStatus={paymentPageStatus} />*/}

                    {/*paymentPageStatus={paymentPageStatus}*/}
                    <CheckoutWaitlist customize={customize} handleCustomer={handleCustomer}
                        toConfiguration={toConfiguration} paymentPageStatus={mainPageNumber}
                    />
                </section>
            </div>

            {/* General Submit Btn ( Known as 'Join our waitlist' or 'Continue to Checkout'), 
                place in an upper level of each section, especially Delivery component, in order to validate easily */}

            {/* !isMobile || !isShowBottomPanel ? 'total-amount-container' : 'total-amount-container hide' */}
            <div className={'total-amount-container'} style={{ display: mainPageNumber != 0 || sectionName?.mainSection == 'global' ? 'none' : '' }}>
                <div className='total-amount-box'>
                    <div className='price-panel'>
                        <div className='inner-div'>
                            {/* <h6>Driveaway Price</h6> */}
                            <DriveawayPriceDialog />
                            {!isNil(paymentOptions?.DriveAwayCost?.DriveawayPrice) && paymentOptions?.DriveAwayCost?.DriveawayPrice != undefined ? (
                                <span className='total-price'>{currencyFormatter.format(paymentOptions?.DriveAwayCost?.DriveawayPrice)}</span>
                            ) : (<span></span>)}
                        </div>
                    </div>
                    {/* <div className='finance-panel'>
                        <div className='inner-div'>
                            <h6>Finance</h6>
                            <span>or {!isNil(paymentOptions?.Finance?.LoanPayment) && paymentOptions?.Finance?.LoanPayment != undefined ? currencyFormatWithBlank(paymentOptions?.Finance?.LoanPayment) : ""}/month</span>
                        </div>
                    </div> */}
                    {/* {
                        paymentOptions?.PaymentOption == 'Cash'
                            ?
                            <>
                                <div className='price-panel'>
                                    <div className='inner-div'>
                                        <DriveawayPriceDialog />
                                        {!isNil(paymentOptions?.DriveAwayCost?.DriveawayPrice) && paymentOptions?.DriveAwayCost?.DriveawayPrice != undefined ? (
                                            <span className='total-price'>{currencyFormatter.format(paymentOptions?.DriveAwayCost?.DriveawayPrice)}</span>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className='finance-panel'>
                                    <div className='inner-div'>
                                        <h6>Finance</h6>
                                        <span>or {!isNil(paymentOptions?.Finance?.LoanPayment) && paymentOptions?.Finance?.LoanPayment != undefined ? currencyFormatWithBlank(paymentOptions?.Finance?.LoanPayment) : ""}/{paymentOptions?.Finance?.FrequencyName}</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='price-panel'>
                                    <div className='inner-div'>
                                        <h6>Finance</h6>
                                        <span>or {!isNil(paymentOptions?.Finance?.LoanPayment) && paymentOptions?.Finance?.LoanPayment != undefined ? currencyFormatWithBlank(paymentOptions?.Finance?.LoanPayment) : ""}/{paymentOptions?.Finance?.FrequencyName}</span>
                                    </div>
                                </div>
                                <div className='finance-panel'>
                                    <div className='inner-div'>
                                        <DriveawayPriceDialog />
                                        {!isNil(paymentOptions?.DriveAwayCost?.DriveawayPrice) && paymentOptions?.DriveAwayCost?.DriveawayPrice != undefined ? (
                                            <span className='total-price'>{currencyFormatter.format(paymentOptions?.DriveAwayCost?.DriveawayPrice)}</span>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                            </>
                    } */}

                    <div className='delivery-panel'>
                        <div className='inner-div'>
                            <h6>Estimated Delivery
                                {/* <sup>3</sup> */}
                            </h6>
                            {
                                !selectedVehicleFromStore.HasStock && linkSourceFromStore != rdEnum.enumLinkSource.PrivateInterestLink
                                    ?
                                    <span>Waitlisted</span>
                                    :
                                    <span>{vehicleFromStore?.SelectedInterior?.Available}</span>
                            }
                        </div>
                    </div>
                </div>
                <a className='delivery-pricing-details stat-text-link' data-dtm="driveaway price"
                    onClick={() => openCashFinanceDialog()}>Pricing & Finance Details</a>
            </div>

            {/* </div> */}
            <PaymentOptions ref={paymentOpionsRef}
                isFromLandingPage={false}
                subTotal={selectedVehicleFromStore?.Subtotal || 0}
                estDeliveryDate={vehicleFromStore?.SelectedInterior?.Available || ""}
                variantID={selectedVehicleFromStore?.VariantID || ""}
                variantName={selectedVehicleFromStore?.VariantName || ""}
                variantPrice={selectedVehicleFromStore?.VariantBasePrice || 0}
                exteriorID={selectedVehicleFromStore?.ExteriorID || ""}
                exteriorName={selectedVehicleFromStore?.Exterior || ""}
                exteriorPrice={selectedVehicleFromStore?.ExteriorPrice || 0}
                interiorID={selectedVehicleFromStore?.InteriorID || ""}
                interiorName={selectedVehicleFromStore?.Interior || ""}
                interiorPrice={selectedVehicleFromStore?.InteriorPrice || 0}
                options={selectedVehicleFromStore?.Options || []}
            />
        </div>
        //     </div>

        // </div>
    );
};





