import { ICodeName } from 'model/ICommon';
export const enumProcessingStatus = {
    Init: 0,
    Processing: 1,
    Processed: 2
};

export const constModel = "LYRIQ";


export const enumOrdersTabName = {
    Orders: 0,
    Returns: 1,
    // Refunds: 2,
    Items: 2,
    Return_Details: 11,
    Item_Details: 22,
    // Refund_Details: 22,
};

//////---------------------useCountryState hook to get data
//export const constCountryList = [
//    { name: 'Australia', code: 'AU' },
//    { name: 'New Zealand', code: 'NZ' },
//];

export const constStateList: ICodeName[] = [
    { name: 'ACT', code: 'ACT' },
    { name: 'NSW', code: 'NSW' },
    { name: 'NT', code: 'NT' },
    { name: 'QLD', code: 'QLD' },
    { name: 'SA', code: 'SA' },
    { name: 'TAS', code: 'TAS' },
    { name: 'VIC', code: 'VIC' },
    { name: 'WA', code: 'WA' },
];

export const constGst: number = 0.1;



export const enumPaymentStatus = {
    Success: "Success",
    Fail: "Fail",
    Incomplete: "Incomplete",
    Cancel: "Cancel",
    Unknow: "Unknow",
};

//delivery option's value
//RD and Yana are sharing these value. Do not change them unless get Yana confirmed.
export const enumDeliveryOptions = {
    Pickup: "Pick Up",
    HomeDelivery: "Regional Delivery",
}
//delivery option's display name
//These value are used to show label, and can be changed as scope request.
export const enumDeliveryOptionsDisplayName = {
    Pickup: "In-store Collection",
    HomeDelivery: "Regional Delivery",
}

//RD and Yana are sharing these value. Do not change them unless get Yana confirmed.
export const enumPaymentOptions = {
    Cash: "Cash",
    Finance: "Finance",
}

//RD and Yana are sharing these value. Do not change them unless get Yana confirmed.
export const enumCountryCode = {
    Australia: "AU",
    NewZealand: "NZ",
}

export const enumCountryName = {
    Australia: "Australia",
    NewZealand: "New Zealand",
}

//RD and Yana are sharing the code value. Do not change the code unless get Yana confirmed.
export const enumClientTypes = {
    Private: {
        Code: "Private Customer",
        Text: "I'm a private customer",
    },
    Business: {
        Code: "Business Customer",
        Text: "I'm a business customer",
    },
};


export const enumMonth = [
    { name: 'JAN', code: '01' },
    { name: 'FEB', code: '02' },
    { name: 'MAR', code: '03' },
    { name: 'APR', code: '04' },
    { name: 'MAY', code: '05' },
    { name: 'JUE', code: '06' },
    { name: 'JUL', code: '07' },
    { name: 'AUG', code: '08' },
    { name: 'SEP', code: '09' },
    { name: 'OCT', code: '10' },
    { name: 'NOV', code: '11' },
    { name: 'DEC', code: '12' },
];

//RD and Yana are sharing these value. Do not change them unless get Yana confirmed.
export const enumVehicleOptionType = {
    Accessories: "Accessories",
    Charge: "Charge"
}


//These value are used to show label, and can be changed as scope request.
export const enumVehicleOptionTypeName = {
    Accessories: "Accessories",
    Charge: "Charging"
}

export const enumChargeType = {
    Public: 1,
    HomeWithoutPrice: 2,
    HomeHasPrice: 3
}

export const enumPage = {
    CheckoutPayment: 1,
    JoinWaitList: 2
}

export const enumInterestType = {
    Share: "Share",
    Save: "Save",
    Notify: "Notify",
    WaitingList: "Waitlist"
}

export const enumRDErrorCode = {
    ExistedEmail: "RD0001",
    NotSignIn: "RD0002",
    MaxExtensionLimit: "RD0003",
    UnauthorisedAccess: "UnauthorisedAccess",
    OutOfStock: "ECM0010",
    NoActivity: "NoActivity",
}

export const enumRedirectPage = {
    CheckoutPaymentPage: "CheckoutPaymentPage"
}

export const enumOrderStatus = {
    Ordered: "Ordered",
    Confirmed: "Ordered Confirmed",
    Cancelled: "Cancelled",
    Delivered: "Delivered",
    PreparingToShip: "Preparing To Ship"
}

export const enumScrollSection = {
    Variant: "variant",
    Exterior: "exterior",
    Interior: "interior",
    Accessories: "accessories",
    Charging: "charging",
    Payment: "payment",
    Delivery: "delivery",
    Checkout: "checkout",
    CheckoutForm: "checkoutForm",
    Global: "global"
}

export const enumInstockPipelineStep = {
    OrderPlace: 1,
    ProvideDetail: 2,
    SignContract: 3,
    OrderConfirmed: 4,
    ScheduleDelivery: 5,
    ArrangePayment: 6,
    Delivered: 7
}

export const enumPipelineStep = {
    OrderPlace: 1,
    ProvideDetail: 2,
    SignContract: 3,
    OrderConfirmed: 4,
    VehicleInProduction: 5,
    PrepareToShip: 6,
    Shipped: 7,
    ScheduleDelivery: 8,
    ArrangePayment: 9,
    Delivered: 10
}

export const enumPipelineButtonID = {
    RetryPayment: 1,
    ProvideDetail: 2,
    StartYourApplication: 3,
    DownloadContract: 4,
    ReviewSignYourContract: 5,
    SchedulePickup: 6,
    ArranagePayment: 7,
    ViewAppointment: 8,
    ViewDelivery: 9
}

export const enumDocumentType = {
    DriveLicenseFront: "Drive License Front",
    DriveLicenseBack: "Drive License Back",
    Contract: "Contract",
    PaymentSummary: "PaymentSummary"
}

export const enumInventorySort: ICodeName[] = [
    { name: 'Lowest price', code: "0" },
    { name: 'Highest price', code: "1" },
];


export const enumTestDriveTransaction = {
    Book: "Book",
    Update: "Update",
    Cancel: "Cancel"
}


export const enumDeliveryPriceError = [
    {
        ErrorCode: "1",
        ErrorMessage: "We were unable to provide an accurate estimate for this delivery address. You can either try a different address or proceed with your order, and a member of our sales team will reach out to provide an estimate.",
    },
    {
        ErrorCode: "2",
        ErrorMessage: "We are unable to calculate your regional deliver cost estimate at this time.You can try again later or call our Cadillac Sales team on 1800***** for support through this step",  //todo: phone no
    }
];

export const enumErrorMessage = {
    AUReservationError: "Your reservation has expired. Please contact our Cadillac Sales Team on 1800 223455",
    NZReservationError: "Your reservation has expired. Please contact our Cadillac Sales Team on 0800 223455",
}

export const enumLinkSource = {
    RegularInterestLink: "Regular",
    PrivateInterestLink: "Private",
};

export const enumCallBackRequest = {
    CallBack: "Call Back",
    CancelOrder: "Cancel Order",
}

