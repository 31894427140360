import * as paymentOptionModel from 'model/IPaymentOptions';
import * as financeStatusModel from 'model/IFinanceStatus';

export const getInitFinancePageState = (): paymentOptionModel.IFinancePageState => {
    let init: paymentOptionModel.IFinancePageState = {
        Country: null,
        State: null,
        RegistrationType: null,
        SelectedTermOptions: null,
        SelectedBalloonPaymentOptions: null,
        SelectedPaymentFrequency: null,
        Deposit: 1000,
        DriveawayCost: 0,
        InterestRate: "",
        ComparisonRate: "",
        FinanceAmount: "",
        BallonAmount: "",
        LoanPayment: 0,
        LoanPaymentText: "",
        CreditAssistanceFee: "",
        MonthlyAccountFee: "",
        FreqencyName: "",
        NoOfMonth: "",
        InterestRateTo: "",
        TotalLoanPayment: "",
        PPSR: "",
    };

    return init;
}


export const getInitFinanceRequest = (): paymentOptionModel.IFinanceRequest => {
    let init: paymentOptionModel.IFinanceRequest = {
        Country: null,
        SelectedTermOptions: null,
        SelectedBalloonPaymentOptions: null,
        SelectedPaymentFrequency: null,
        Deposit: 1000,
        DriveawayCost: 0,
        Source: null
    };

    return init;
}

export const getInitFinanceStatus = (): financeStatusModel.IFinanceStatus => {
    let init: financeStatusModel.IFinanceStatus = {
        Country: null,
        YanaCustomerID: null,
        YanaCustomerNo: null,
        YanaOrderID: null,
        YanaOrderNo: null,
        FinanceApplicationStepName: null,
        FinanceApplicationStepLink: null
    };

    return init;
}