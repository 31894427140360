import * as driverLicenseModel from 'model/IDriverLicense';

export const getInitDriverLicense = (): driverLicenseModel.IDriverLicense => {
    let init: driverLicenseModel.IDriverLicense = {
        driverLicenseNo: "",
        driverLicenseExpiryDate: "", //undefined,
        //driverLicenseExpiryDateFormat: "",
        //driverLicenseUploadDoc1YanaID: null,
        //driverLicenseUploadDoc1YanaNo: null,
        //driverLicenseUploadDoc2YanaID: null,
        //driverLicenseUploadDoc2YanaNo: null,
    };

    return init;
}

export const getInitInvalidDriverLicense = (): driverLicenseModel.IInvalidDriverLicense => {
    let init: driverLicenseModel.IInvalidDriverLicense = {
        invalidNo: null,
        invalidExpiryDate: null,
        invalidUploadDoc1: null,
        invalidUploadDoc2: null,
    };

    return init;
}