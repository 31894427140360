import { IMainRightPage } from "helpers/interface";

export const SET_MAIN_RIGHT_PAGE = 'showMainRightPage';
export const hideGlobalPage = (isHideGlobal: boolean) =>
({
    type: SET_MAIN_RIGHT_PAGE,
    isHideGlobal
} as const);

export type MainRightPageActions = ReturnType<typeof hideGlobalPage>;
export const initMainRightPageState: IMainRightPage = {
    isHideGlobal: false
}

export const mainRightPageReducer = (
    state = initMainRightPageState,
    action: MainRightPageActions,
) => {
    switch (action.type) {
        case SET_MAIN_RIGHT_PAGE: {
            return {
                ...state,
                isHideGlobal: action.isHideGlobal,
            };
        }
        default:
        return state;
    }
};