import { getCMSAssets } from 'helpers/Uri';
import React, { useEffect, useState } from 'react';
import CarColorVariantViewSwitch from '../../../components/CarColorVariantViewSwitch';
import CarColorSelected from 'pages/Main/exterior/CarColorSelected';
import { Button } from 'react-bootstrap';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import { isNil } from 'lodash';
import * as vehicleModel from 'model/IVehicle';
import * as vehicleService from 'services/Vehicle';
import * as vehicleHelper from 'businessHelpers/VehicleHelper';

import CarColorSelectedComparisonSport from '../exterior/CarColorSelectedComparisonSport';
import CarColorSelectedMobile from '../exterior/CarColorSelectedMobile';


interface CustomizeType {
    variantLeft: string,
    variantRight: string,
    colorLeft: string,
    colorRight: string,
    leftImageUrlSet: string[],
    rightImageUrlSet: string[],
    totalPriceLeft: string,
    totalPriceRight: string,
    payByMonthLeft: string,
    payByMonthRight: string,
    viewPosition: string,
}

const VariantComparison = () => {

    const variantListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.VariantList;
    });
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const exteriorListFromStore = useSelector((appState: IAppState) => {
        return appState?.vehicle?.ExteriorList;
    });


    const [leftExteriorList, setLeftExteriorList] = useState<vehicleModel.IExterior[]>([]);
    const [rightExteriorList, setRightExteriorList] = useState<vehicleModel.IExterior[]>([]);

    const [selectedLeftExteriorList, setSelectedLeftExteriorList] = useState<vehicleModel.IExterior>(vehicleHelper.getInitExterior());
    const [selectedRightExteriorList, setSelectedRightExteriorList] = useState<vehicleModel.IExterior>(vehicleHelper.getInitExterior());


    const [leftVariantName, setLeftVariantName] = useState("");
    const [rightVariantName, setRightVariantName] = useState("");
    const [leftVariantDesc, setLeftVariantDesc] = useState("");
    const [rightVariantDesc, setRightVariantDesc] = useState("");

    const [customize, setCustomize] = useState<CustomizeType>({
        variantLeft: 'Luxury',
        variantRight: 'Sport',
        colorLeft: 'Argent Silver Metallic',
        colorRight: 'Argent Silver Metallic',
        leftImageUrlSet: [], // vehicleFromStore.ExteriorList?.[0]?.ExteriorPicture || [],
        rightImageUrlSet: [], //variantListFromStore?.[1].VariantPicture || [],
        totalPriceLeft: '102990',
        totalPriceRight: '102990',
        payByMonthLeft: '1298',
        payByMonthRight: '1298',
        viewPosition: '',
    });

    const [initialImage, setInitialImage] = useState({
        initLeftColor: 'Argent Silver Metallic',
        initLeftImageUrlSet: [], //vehicleFromStore.ExteriorList?.[0]?.ExteriorPicture,
        initLeftVariant: 'Luxury',
        initRightColor: 'Argent Silver Metallic',
        initRightImageUrlSet: [], //variantListFromStore?.[1].VariantPicture,
        initRightVariant: 'Sport',
    });


    const [activeLeftImage, setActiveLeftImage] = useState(1);
    const [activeRightImage, setActiveRightImage] = useState(1);

    const isMobile = useCheckMobileScreen();

    useEffect(() => {

        if (!isNil(variantListFromStore) && variantListFromStore.length > 0) {
            doGetExterior(variantListFromStore?.[0].VariantID || null, true);
            doGetExterior(variantListFromStore?.[1].VariantID || null, false);

            setLeftVariantName(variantListFromStore[0]?.VariantName || "");
            setRightVariantName(variantListFromStore[1]?.VariantName || "");

            setLeftVariantDesc(variantListFromStore[0]?.VariantDescription || "")
            setRightVariantDesc(variantListFromStore[1]?.VariantDescription || "")
        }

    }, [variantListFromStore]);

    const doGetExterior = async (variantID: any, isLeft: boolean) => {
        let requestData: vehicleModel.IExterior = {
            Country: selectedDeliveryFromStore?.Country?.code || null,
            VariantID: variantID,
            VariantName: null,
            ExteriorID: null,
            ExteriorColorName: null,
            ExteriorColorDescTitle: null,
            ExteriorColorDesc: null,
            ExteriorPrice: null,
            ExteriorPicture: null,
            ExteriorPictureMobile: null,
            ExteriorFilterImage: null,
            ExteriorFilterMobile: null,
            IsDefault: null,
            HasStock: null,
            ExteriorOrderingNo: 0,
        };

        let response = await vehicleService.getExteriors(requestData);
        if (response.status) {
            let exteriorList: vehicleModel.IExterior[] = [];
            exteriorList = response.data.map((item: any) => {

                let exteriorTempItem: vehicleModel.IExterior = {
                    Country: item.country,
                    VariantID: item.variantID,
                    VariantName: null,
                    ExteriorID: item.exteriorID,
                    ExteriorColorName: item.exteriorColorName,
                    ExteriorColorDescTitle: item.exteriorColorDescTitle,
                    ExteriorColorDesc: item.exteriorColorDesc,
                    ExteriorPrice: item.exteriorPrice,
                    ExteriorPicture: item.exteriorPicture,
                    ExteriorPictureMobile: item.exteriorPictureMobile,
                    ExteriorFilterImage: item.exteriorFilter,
                    ExteriorFilterMobile: item.exteriorFilterMobile,
                    IsDefault: item.isDefault,
                    HasStock: true,
                    ExteriorOrderingNo: 0
                }

                if (item.isDefault) {
                    if (isLeft) {
                        setSelectedLeftExteriorList(exteriorTempItem);
                    }
                    else {
                        setSelectedRightExteriorList(exteriorTempItem);
                    }
                }

                return exteriorTempItem;
            });

            if (isLeft) {
                setLeftExteriorList(exteriorList)
            }
            else {
                setRightExteriorList(exteriorList)
            }
        }
    }


    const handleColorLeft = (color: any) => {
        leftExteriorList.map((item: vehicleModel.IExterior) => {
            if (item.ExteriorColorName === color) {
                setSelectedLeftExteriorList(item);
            }
        });

        setActiveLeftImage(2);

        setTimeout(() => {
            setActiveLeftImage(1)
        }, 380);

        //const index = leftExteriorList?.findIndex(
        //    (item: any) => item.ExteriorColorName === color
        //);
        //setInitialImage({
        //    ...initialImage,
        //    initLeftColor: customize.colorLeft,
        //    initLeftImageUrlSet: customize.leftImageUrlSet || []
        //});
        //setCustomize({
        //    ...customize,
        //    colorLeft: color,
        //    leftImageUrlSet: leftExteriorList[index]?.ExteriorPicture || [],
        //})
        //setActiveLeftImage(2)
        //setTimeout(() => {
        //    setInitialImage({
        //        ...initialImage,
        //        initLeftColor: color,
        //        initLeftImageUrlSet: leftExteriorList[index]?.ExteriorPicture || []
        //    })
        //    setActiveLeftImage(1)
        //}, 380);
    }

    const handleColorRight = (color: any) => {
        rightExteriorList.map((item: vehicleModel.IExterior) => {
            if (item.ExteriorColorName === color) {
                setSelectedRightExteriorList(item);
            }
        });

        setActiveRightImage(2);

        setTimeout(() => {
            setActiveRightImage(1)
        }, 380);


        //const index = vehicleFromStore.ExteriorList?.findIndex(
        //    (item: any) => item.ExteriorColorName === color
        //);



        //setInitialImage({
        //    ...initialImage,
        //    initRightColor: customize.colorRight,
        //    initRightImageUrlSet: customize.rightImageUrlSet
        //});
        //setCustomize({
        //    ...customize,
        //    colorRight: color,
        //    rightImageUrlSet: vehicleFromStore.ExteriorList?.[index]?.ExteriorPicture || [],
        //});
        //setActiveRightImage(2)
        //setTimeout(() => {
        //    setInitialImage({
        //        ...initialImage,
        //        initRightColor: color,
        //        initRightImageUrlSet: vehicleFromStore.ExteriorList?.[index]?.ExteriorPicture || [],
        //        // initRightImageUrlSet: sportExteriorList?.[index]?.exteriorPicture || []
        //    })
        //    setActiveRightImage(1)
        //}, 550);
    }

    const [isWhichSide, setIsWhichSide] = useState('Left');

    //const [ selectMobileVariant, setSelectMobileVariant ] = useState('Luxury');

    useEffect(() => {
        const initComparisons = () => {
            const images = document.getElementsByClassName("img-comp-overlay") as HTMLCollectionOf<HTMLElement>;

            const compareImages = (img: HTMLElement) => {
                let slider: HTMLDivElement, clicked = 0, w: number, h: number;

                const updateWidth = () => {
                    w = img.offsetWidth;
                };

                window.addEventListener("resize", updateWidth);

                updateWidth();

                w = img.offsetWidth;
                h = img.offsetHeight;
                img.style.width = `${w / 2}px`;

                // console.log(img.offsetWidth);

                slider = document.createElement("div");
                slider.setAttribute("class", "img-comp-slider");
                img.parentElement!.insertBefore(slider, img);
                slider.style.top = `${h / 2 - slider.offsetHeight / 2}px`;
                slider.style.left = `${w / 2 - slider.offsetWidth / 2}px`;

                const slideReady = (e: MouseEvent | TouchEvent) => {
                    e.preventDefault();
                    clicked = 1;
                    window.addEventListener("mousemove", slideMove);
                    window.addEventListener("touchmove", slideMove);
                };

                const slideFinish = () => {
                    clicked = 0;
                };

                const slideMove = (e: MouseEvent | TouchEvent) => {
                    let pos;
                    if (clicked === 0) return false;
                    pos = getCursorPos(e);
                    if (pos < 0) pos = 0;
                    if (pos > w) pos = w;
                    slide(pos);
                };

                slider.addEventListener("mousedown", slideReady);
                window.addEventListener("mouseup", slideFinish);
                slider.addEventListener("touchstart", slideReady);
                window.addEventListener("touchend", slideFinish);

                const getCursorPos = (e: MouseEvent | TouchEvent) => {
                    let a, x = 0;

                    if ('changedTouches' in e) {
                        // TouchEvent
                        const touch = e.changedTouches[0];
                        a = img.getBoundingClientRect();
                        x = touch.pageX - a.left;
                        x = x - window.pageXOffset;
                    } else {
                        // MouseEvent
                        a = img.getBoundingClientRect();
                        x = (e as MouseEvent).pageX - a.left;
                        x = x - window.pageXOffset;
                    }

                    if (x > 300) {
                        setIsWhichSide('Right')
                    } else {
                        setIsWhichSide('Left')
                    }

                    return x;
                };

                const slide = (x: number) => {
                    img.style.width = `${x}px`;
                    slider.style.left = `${img.offsetWidth - slider.offsetWidth / 2}px`;
                };
            };

            for (let i = 0; i < images.length; i++) {
                compareImages(images[i]);
            }
        }
        initComparisons();
    }, []);

    const options = { maximumFractionDigits: 2 }




    return (
        <>
            {
                // !isMobile
                // ?
                <div className='comparison-img-container'>
                    <div className='comparison-detail-container'>
                        <div className='variant-name-price-section'>
                            <div className='left-container'>
                                <div className={`img-left ${activeLeftImage === 2 ? 'active' : ''} `}>
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[0]} />
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[0]} />
                                </div>
                                {
                                    !isMobile
                                        ?
                                        <div className='exterior-options-panel'>
                                            <CarColorSelected color={selectedLeftExteriorList?.ExteriorColorName}
                                                handleColor={handleColorLeft}
                                                colorList={leftExteriorList} />
                                        </div>
                                        :
                                        <></>
                                        // <div className='exterior-options-panel'>
                                        //     <CarColorSelectedMobile color={selectedLeftExteriorList?.ExteriorColorName}
                                        //         handleColor={handleColorLeft}
                                        //         colorList={leftExteriorList} />
                                        // </div>
                                }
                                <div className='left-detail-name-price-section'>
                                    <h4>{leftVariantName}</h4>
                                    <p>Colour</p>
                                    <h5>{selectedLeftExteriorList?.ExteriorColorName}</h5>
                                </div>
                            </div>

                            <div className='right-container'>
                                <div className={`img-right ${activeRightImage === 2 ? 'active' : ''}`}>
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[0]} />
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[0]} />
                                </div>
                                {
                                    !isMobile
                                        ?
                                        <div className='exterior-options-panel'>
                                            <CarColorSelected color={selectedRightExteriorList?.ExteriorColorName}
                                                handleColor={handleColorRight}
                                                colorList={rightExteriorList} />
                                        </div>
                                        :
                                        <></>
                                        // <div className='exterior-options-panel'>
                                        //     <CarColorSelectedMobile color={selectedRightExteriorList?.ExteriorColorName}
                                        //         handleColor={handleColorRight}
                                        //         colorList={rightExteriorList} />
                                        // </div>
                                }
                                <div className='right-detail-name-price-section'>
                                    <h4>{rightVariantName}</h4>
                                    <p>Colour</p>
                                    <h5>{selectedRightExteriorList?.ExteriorColorName}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='variant-desc-section'>
                            <div className='variant-left-desc'>
                                <h6>{leftVariantName}</h6>
                                <p>{leftVariantDesc}</p>
                            </div>
                            <div className='variant-right-desc'>
                                <h6>{rightVariantName}</h6>
                                <p>{rightVariantDesc}</p>
                            </div>
                        </div>
                        <div className='exterior-front-section'>
                            <div className='exterior-front-left-panel'>
                                <div className={`img-left ${activeLeftImage === 2 ? 'active' : ''} `}>
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[1]} />
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[1]} />
                                </div>
                                <h6>Grille design</h6>
                                <p>Angled linear 3D etched crystal shield </p>
                            </div>
                            <div className='exterior-front-right-panel'>
                                <div className={`img-right ${activeRightImage === 2 ? 'active' : ''}`}>
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[1]} />
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[1]} />
                                </div>
                                <h6>Grille design</h6>
                                <p>Interlocking triangular 3D etched crystal shield </p>
                            </div>
                        </div>
                        <div className='wheel-section'>
                            <div className='wheel-left-panel'>
                                <div className='img-left'>
                                    <img alt="" className='image' src={`${getCMSAssets()}/variants/wheel-luxury.svg`} />
                                </div>
                                <h6>Rims</h6>
                                {/* <p>Sport</p> */}
                                <p>21" multi-spoke aluminium wheels with diamond cut Manoogian Silver finish.</p>
                            </div>
                            <div className='wheel-right-panel'>
                                <div className='img-right'>
                                    <img alt="" className='image' src={`${getCMSAssets()}/variants/wheel-sports.svg`} />
                                </div>
                                <h6>Rims</h6>
                                <p>21" multi-spoke aluminum wheels, polished and with Satin Dark Android finish</p>
                            </div>
                        </div>
                        <div className='exterior-side-section'>
                            <div className='exterior-side-left-panel'>
                                <div className={`img-left ${activeLeftImage === 2 ? 'active' : ''} `}>
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[3]} />
                                    <img alt="" className='image' src={selectedLeftExteriorList.ExteriorPicture?.[3]} />
                                </div>
                                <h6>Exterior accents</h6>
                                <p>Luxury Silver Chrome </p>
                            </div>
                            <div className='exterior-side-right-panel'>
                                <div className={`img-right ${activeRightImage === 2 ? 'active' : ''}`}>
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[3]} />
                                    <img alt="" className='image' src={selectedRightExteriorList.ExteriorPicture?.[3]} />
                                </div>
                                <h6>Exterior accents</h6>
                                <p>Obsidian Chrome & dark accents</p>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
};

export default VariantComparison;
