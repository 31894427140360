import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import PaymentOptions, { ChildRef } from '../../../components/PaymentOptions';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerTypeAction } from 'stores/CustomerType';
import { IAppState } from 'model/IAppState';
import * as rdEnum from 'helpers/enum';
import * as paymentOptionStore from 'stores/PaymentOptions';
import CashFinanceDescDialog from './CashFinanceDescDialog';

const CustomerPayment = ({ handlePayment }: any) => {

    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });
    const [pageState, setPageState] = useState({
        customerType: rdEnum.enumClientTypes.Private.Code,
        paymentType: rdEnum.enumPaymentOptions.Cash,
    });

    const dispatch = useDispatch();

    const customerType = useSelector((state: IAppState) => {
        return state.customerType;
    });

    const handleCustomerType = (type: any) => {
        setPageState({
            ...pageState,
            customerType: type
        });
        dispatch(setCustomerTypeAction(type));
    }

    const handlePaymentType = (type: any) => {
        setPageState({
            ...pageState,
            paymentType: type
        });
        handlePayment(type);
        dispatch(paymentOptionStore.updateSelectedPaymentOptionAction(type));
    }

    useEffect(() => {
        setPageState(({
            ...pageState,
            customerType: customerType || rdEnum.enumClientTypes.Private.Code
        }))
    }, [customerType])

    const childRef = useRef<ChildRef | null>(null);

    // Function to open the dialog in the child component
    const openCashFinanceDialog = () => {
        if (childRef.current) {
            childRef.current.openDialog();
        }
    };

    return (
        <div className='checkout-container'>
            <div className='customer-type-container'>
                <h3 className='main-page-section-title'>CHECKOUT</h3>
                <p className='checkout-desc'>Select the option that works best for you.</p>
                <h5 className='checkout-subtitle'>CUSTOMER TYPE</h5>
                <div className='customer-type-selection'>
                    <div className='variant-btn-panel'>
                        <Button className={pageState?.customerType == rdEnum.enumClientTypes.Private.Code ? 'stat-button-link variant-btn selected' : 'stat-button-link variant-btn'}
                            data-dtm="checkout"
                            onClick={() => {
                                handleCustomerType(rdEnum.enumClientTypes.Private.Code);
                            }}
                        >
                            <span>{rdEnum.enumClientTypes.Private.Text}</span>
                        </Button>
                        <Button className={pageState?.customerType == rdEnum.enumClientTypes.Business.Code ? 'stat-button-link variant-btn selected' : 'stat-button-link variant-btn'}
                            data-dtm="checkout"
                            onClick={() => {
                                handleCustomerType(rdEnum.enumClientTypes.Business.Code);
                            }}
                        >
                            <span>{rdEnum.enumClientTypes.Business.Text}</span>
                        </Button>
                    </div>
                </div>

            </div>

            <div className='payment-container'>
                <h3 className='checkout-subtitle'>PAYMENT TYPE</h3>
                {/* <p className='payment-desc'>Please indicate how you intend to pay for your Cadillac. You will complete this process in your account. You can amend your selection in your account.</p> */}

                <div className='cash-finance-btn-panel'>
                    <Button className={pageState?.paymentType == rdEnum.enumPaymentOptions.Cash ? 'stat-button-link cash-finance-btn selected' : 'stat-button-link cash-finance-btn'}
                        data-dtm="checkout"
                        onClick={() => {
                            handlePaymentType(rdEnum.enumPaymentOptions.Cash)
                        }}
                    >
                        <span>{rdEnum.enumPaymentOptions.Cash}</span>
                    </Button>
                    <Button className={pageState?.paymentType == rdEnum.enumPaymentOptions.Finance ? 'stat-button-link cash-finance-btn selected' : 'stat-button-link cash-finance-btn'}
                        data-dtm="checkout"
                        onClick={() => {
                            handlePaymentType(rdEnum.enumPaymentOptions.Finance)
                        }}
                    >
                        <span>Finance</span>
                    </Button>
                    <PaymentOptions ref={childRef}
                        isFromLandingPage={false}
                        subTotal={selectedVehicleFromStore?.Subtotal || 0}
                        estDeliveryDate={vehicleFromStore?.SelectedInterior?.Available || ""}
                        variantID={selectedVehicleFromStore?.VariantID || ""}
                        variantName={selectedVehicleFromStore?.VariantName || ""}
                        variantPrice={selectedVehicleFromStore?.VariantBasePrice || 0}
                        exteriorID={selectedVehicleFromStore?.ExteriorID || ""}
                        exteriorName={selectedVehicleFromStore?.Exterior || ""}
                        exteriorPrice={selectedVehicleFromStore?.ExteriorPrice || 0}
                        interiorID={selectedVehicleFromStore?.InteriorID || ""}
                        interiorName={selectedVehicleFromStore?.Interior || ""}
                        interiorPrice={selectedVehicleFromStore?.InteriorPrice || 0}
                        options={selectedVehicleFromStore?.Options || []}
                    />

                </div>

                <CashFinanceDescDialog paymentType={pageState?.paymentType} />
            </div>
        </div>
    )
}

export default CustomerPayment