import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'

const TermsConditionsDialog = ({props}: any) => {

    const [isShowDialog, setIsShowDialog] = useState(false);

    const onDialogOpen = () => {
        setIsShowDialog(true);
    }

    const onDialogHide = () => {
        setIsShowDialog(false);
    }

    const toJetchange = () => {
        window.open('https://jetcharge.com.au/standard-installation/');
    }

    const toChargeFox = () => {
        window.open('https://www.chargefox.com/legal/terms-conditions');
    }

    return (
        <>
            {
                props == 'special'
                ?
                    <div className='checkbox-privacy-btn stat-text-link' onClick={() => onDialogOpen()} data-dtm="checkout">
                    <span>Order Terms & Conditions</span>
                    <img alt='' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                </div>
                    :
                    <a style={{ cursor: 'pointer', textDecoration: 'underline' }} className="stat-text-link" data-dtm="checkout"
                        onClick={() => onDialogOpen()}>Order Terms & Conditions</a>
            }

             <Dialog className='terms-conditions-dialog' visible={isShowDialog} position='bottom' onHide={() => onDialogHide()} draggable={false} resizable={true}>
                <div className='terms-conditions-container'>
                    <h6>IMPORTANT INFORMATION</h6>
                    <div className='terms-conditions-title'>
                        <h1>ORDER TERMS </h1>
                        <h1>& CONDITIONS </h1>
                    </div>
                  
                    <div className='terms-conditions-content'>
                        <p>
                            These order terms (“Terms”) should be reviewed before you submit your order for the vehicle you have configured in the vehicle configurator (“Configured Vehicle”). 
                        </p>
                        <p>
                            Subject to availability and these Terms, General Motors Australia and New Zealand Pty Ltd / General Motors New Zealand Limited (hereinafter referred to as “Cadillac”) is offering to let you reserve the Configured Vehicle (“Order”).
                        </p>
                        <div className='terms-conditions-section'>
                            <h5>DEPOSIT</h5>
                            <p>
                                A deposit of $1,000.00 (“Security Deposit”) is payable to Cadillac at the time of submitting your Order. The Deposit will be processed when you submit your Order. 
                            </p>
                        </div>
                        <div className='terms-conditions-section'>
                            <h5>CANCELLATIONS</h5>
                            <p>
                                If you change your mind, you may cancel your Order by contacting Cadillac on 1800 CADILLAC (223 455) / au.care@cadillac.com (Australia) or 0800 CADILLAC (223 455) / nz.care@cadillac.com directly or via your account once your account has been set up and before you sign the Contract of Sale.
                            </p>
                            <p>
                                The Security Deposit is fully refundable until you sign the Contract of Sale, after which the Security Deposit will be subject to the terms of the Contract of Sale. Security Deposits will only be refunded to the same credit or debit card that was used to pay the Security Deposit. Once a refund request is confirmed as received by Cadillac, please allow up to 5 business days for a cancellation and refund to be processed. 
                            </p>
                            <span>
                                Cadillac reserves the right to cancel your Order if:
                            </span>
                            <ul>
                                <li>(a)	you do not complete the setup of your account within 10 days of you receiving account set up details; or</li>
                                <li>(b)	you do not sign the Contract of Sale within 10 days of receiving the final Contract of Sale.</li>
                            </ul>
                            <p>
                                If Cadillac cancels your Order pursuant to these Terms, Cadillac will take reasonable steps to inform you and will refund the Security Deposit to you in full within 10 business days.
                            </p>
                        </div>
                        <div className='terms-conditions-section'>
                            <h5>ACCOUNT SET UP</h5>
                            <p>
                                By proceeding with and submitting your Order you acknowledge that you will be required to provide Cadillac with your information and set up an account. Details for how you can set up an account will be sent to your nominated email address, or can be obtained by contacting Cadillac on 1800 CADILLAC (223 455) / au.care@cadillac.com (Australia) or 0800 CADILLAC (223 455) / nz.care@cadillac.com. The details in this account are required to track the status of your vehicle order and prepare and issue you with a Contract of Sale.
                            </p>
                        </div>
                        <div className='terms-conditions-section'>
                            <h5>SUBJECT TO CHANGE</h5>
                            <p>
                                Vehicle design, equipment, features, specifications, options and availability of the Configured Vehicle may be subject to change and are not guaranteed at the time of placing your Order.
                            </p>
                        </div> 
                        <div className='terms-conditions-section'>
                            <h5>DELIVERY</h5>
                            <p>
                                The estimated date of delivery provided when you submit your Order is an estimate only and is subject to change once Cadillac has processed your order. The estimated delivery date may also be impacted by supply shortages and shipping delays outside of Cadillac’s control. 
                            </p>
                        </div> 
                        {/* <div className='terms-conditions-section'>
                            <h5>CHARGING</h5>
                            <p>
                                [Placeholder for final charging]
                            </p>
                        </div>  */}
                        <div className='terms-conditions-section'>
                            <h5>CONTRACT OF SALE</h5>
                            <p>
                                These Terms are not a vehicle purchase agreement and submitting your Order does not guarantee delivery of any vehicle within any particular time period. These Terms do not require you to make a vehicle purchase. 
                            </p>
                            <p>
                                In order to purchase the Configured Vehicle, Cadillac will provide you with a contract of sale (“Contract of Sale”). The Contract of Sale will be made available to you via your account. The Contract of Sale must be signed by you within 10 days. Once the Contract of Sale is signed by you, Cadillac will provide you with confirmation of your Order. 
                            </p>
                        </div> 
                        <div className='terms-conditions-section'>
                            <h5>THIRD PARTIES</h5>
                            <p>
                                Cadillac may engage third parties to perform all or any portion of its obligations relating to these Terms. 
                            </p>
                        </div> 
                        {/* <div className='terms-conditions-section'>
                            <h5>MISCELLANEOUS</h5>
                            <p>
                                Cadillac may engage third parties to perform all or any portion of its obligations relating to these Terms.
                            </p>
                            <p>
                                These Terms are governed by the state and federal laws of Australia / laws of New Zealand.
                            </p>
                        </div>  */}
                        <div className='terms-conditions-section'>
                            <h5>CHARGING PARTNERS</h5>
                            <div className='sub-section-box'>
                                <p className='sub-section-title'>
                                    Offers
                                </p>
                                <p>
                                    When you purchase a Cadillac vehicle your vehicle comes with complimentary access to Chargefox public chargers for a period of 1 year from the date you receive your Cadillac vehicle. 
                                </p>
                                <span>
                                    When placing this order you may have selected one of the following Cadillac charging offers: 
                                </span>
                                <ul className='list-number'>
                                    <li>1. two (2) years of complimentary access to Chargefox public chargers (in addition to the first year of public complimentary charging offered on all Cadillac vehicles); or</li>
                                    <li>2. one (1) complimentary 7.4kW Autel MaxiCharger AC Lite home charging unit provided and installed by JET Charge.</li>
                                </ul>
                            </div>
                            <div className='sub-section-box'>
                                <p className='sub-section-title'>
                                    Information sharing with charging partners
                                </p>
                                <p>
                                    In order to take advantage of the Cadillac charging offers, Cadillac will be required to share the Vehicle Identification Number (VIN) and your name and contact details with its third party electric vehicle charging partners so that the partner can provide you with the charging offer. The charging partners are Chargefox Pty Ltd (public charging services) and JET Charge Pty Ltd (home charging services). 
                                </p>
                            </div>
                            <div className='sub-section-box'>
                                <p className='sub-section-title'>
                                    Terms and conditions of charging partners
                                </p>
                                <p>
                                    Please be aware that you will be required to agree to the terms and conditions, fair use policy and privacy policy of each of these charging partners in order to receive their charging services.
                                </p>
                                <p>
                                    If one of our charging partners becomes insolvent or otherwise ceases to operate, Cadillac will contact you and the applicable offer will come to an end. 
                                </p>
                            </div>
                            <div className='sub-section-box'>
                                <p className='sub-section-title'>
                                    Home charging offer - Australia
                                </p>
                                <p>
                                    If you elected to install a complimentary home charger, Cadillac will be required to share your first and last name, provided email address and post code with JET Charge to enable them to contact you and provide you with this home charging hardware and installation.
                                </p>
                                <p>
                                    JET Charge will be in touch with you directly via email to arrange a virtual assessment – the provision of relevant images of your property to JET Charge (or a site visit if required). This option includes complimentary standard installation. The standard installation scope includes a single-phase, dedicated electrical circuit with up to 15 metres of copper cable, installation of an isolation switch near the charging unit and installation of electrical circuit protection. More information on standard installation with JET Charge can be <a onClick={() => toJetchange()}>found here</a>. If the infrastructure available at your home does not meet the criteria for a standard installation, your installation will be treated as nonstandard. Cadillac will cover costs for nonstandard installs up to the value of a standard installation, and JET Charge will provide you with a quote for any additional costs or variations. Apartment installations are excluded from this offer.
                                </p>
                                <p>
                                    You acknowledge and agree that JET Charge is solely responsible for providing the home charging unit and the installation services in accordance with their terms and conditions, which can be found <a onClick={() => toJetchange()}>here</a>. Cadillac is not liable for any loss, cost, expense, liability or demand of any nature whatsoever caused directly or indirectly by the home charging unit or any services provided by JET Charge. 
                                </p>
                                <p>
                                    Cadillac makes no representations or warranties as to the suitability or compatibility of JET Charge products or services for your requirements.
                                </p>
                            </div>
                            <div className='sub-section-box'>
                                <p className='sub-section-title'>
                                    Public charging offer – Australia 
                                </p>
                                <p>
                                    All Cadillacs come with 12 months free public charging. Cadillac will be required to share your personal or company details such as, the Vehicle Identification Number (VIN), registration and your selected charging inclusion (1 or 3 years) with Chargefox Pty Ltd (Chargefox) to enable them to provide you with the charging offer. In order to activate this offer with Chargefox, you need to register with Chargefox as a Chargefox user and may also be required to share additional information and must agree with their terms and conditions at time of activating this charging inclusion (typically this will be when you take delivery of your vehicle and download their application). This also applies if you opt for an additional 2 years of public charging (totalling 3 years). Please contact the Cadillac Sales team prior to your delivery to opt out of the default charging inclusion. Chargefox subscriptions will remain with the vehicle in the event of a change of ownership. A subscription can only be activated by one nominated owner at any time.
                                </p>
                                <p>
                                    Notwithstanding that your purchase of a Cadillac vehicle entitles you to complimentary charging, if you connect your vehicle to a charger within the Chargefox network and leave the car connected to the charger once it is fully charged which prevents other customers from using the charger, you may be charged idle fees in line with Chargefox’s terms and conditions. In addition to theses terms and conditions, your use of the Chargefox network is subject to the Chargefox network terms and conditions, which can be accessed <a onClick={() => toChargeFox()}>here</a>.
                                </p>
                                <p>
                                    For the avoidance of any doubt, you are solely responsible to pay such idle fees, Cadillac will not pay idle fees.
                                </p>
                                <p>
                                    The complimentary charging offer must not be used for commercial purposes including if you operate any form of rideshare business using your Cadillac vehicle, including but not limited to Uber, DiDi, taxi, courier or other commercial use or any other use that is not in accordance with Chargefox’s fair use policy or network terms and conditions. Chargefox will require payment for charging services and/or suspend your registration if you attempt to charge your Cadillac vehicle whilst using your vehicle or Chargefox network for the aforementioned purposes. Please refer to Chargefox terms and conditions for further details.
                                </p>
                                <p>
                                    You acknowledge and agree that the charging services are provided by Chargefox through its Chargefox network of chargers. Cadillac is not liable for any loss, cost, expense, liability or demand of any nature whatsoever caused directly or indirectly by any public charging facilities or any services provided by Chargefox. 
                                </p>
                            </div>
                        </div> 
                        <br/>
                        <br/>
                        <p>
                            These Terms are governed by the state and federal laws of Australia / laws of New Zealand.
                        </p>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default TermsConditionsDialog