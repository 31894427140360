import { getCMSAssets } from 'helpers/Uri';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react'
import { isNil } from 'lodash';
import { Button, Card } from 'react-bootstrap';
import ShareDialog from './ShareDialog';
import SaveDialog from './SaveDialog';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalPage } from 'stores/MainRightPage';
import { IAppState } from 'model/IAppState';
import * as checkoutViewModel from 'model/IChekcoutViewModel';
import * as vehicleModel from 'model/IVehicle';
import * as rdEnum from 'helpers/enum';
import * as rdUri from 'helpers/Uri';
import * as selectedDeliveryStore from 'stores/UserSelectedDelivery';
import { setScrollPosition } from 'stores/ScrollPosition';
import DriveawayPriceDialog from 'components/DriveawayPriceDialog';
import { useSatellite } from 'helpers/useSatellite';

const Checkout: React.FC<checkoutViewModel.IMenuClickToScrollProps> = ({ scrollToSection, checkoutFormPage }) => {

    const dispatch = useDispatch();
    const satellite = useSatellite();

    //stores
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });
    const customerTypeFromStore = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const paymentOptionsFromStore = useSelector((state: IAppState) => {
        return state.paymentOptions;
    });
    const linkSourceFromStore = useSelector((state: IAppState) => {
        return state?.linkSource;
    });

    const handleToCheckoutForm = () => {
        checkoutFormPage();   //call main page toWaitlistPage()
        // dispatch(setScrollPosition('checkoutForm'))

        if (linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink || selectedVehicleFromStore.HasStock) {
            satellite.trackEvent("config-end");
        }

    }

    const getDeliveryAddress = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery) {
            return selectedDeliveryFromStore.SelectedDeliveryFullAddress;
        }
        else {
            return selectedDeliveryFromStore.SelectedPickupLocation?.LocationAddress;
        }
    }

    const getDeliveryPrice = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery) {
            let price = selectedDeliveryFromStore.DeliveryPrice;
            if (!isNil(price)) {
                return rdUri.currencyFormat(price);
            }
        }
        return ""
    }

    const optionsTemplate = (optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(selectedVehicleFromStore?.Options) && selectedVehicleFromStore?.Options.length > 0) {
            let selectedOptions = selectedVehicleFromStore?.Options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions?.map((addsOnItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='adds-on-item-panel' key={optionType + "_" + index} >
                            <span>{addsOnItem?.OptionName}</span>
                            <span>{rdUri.currencyFormat(addsOnItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<React.Fragment key="empty"></React.Fragment>);
        }
        else {
            template.push(
                <div className='inner-item' key={`inner-item-${optionType}`}>
                    <span>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</span>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    return (
        <div className='checkout-container'>
            <div className='review-order-container'>
                <h3 className='main-page-section-title order-review'>ORDER REVIEW</h3>
                <div className='drive-away-header-panel'>
                    <p className='review-order-desc'>Your Drive Away Price is calculated based on your selected Registration Type in metropolitan areas in your selected State / Territory.</p>
                    <DriveawayPriceDialog source='order-review' />
                </div>
                <div className='car-panel'>
                    <h6>YOUR SELECTION</h6>
                    <div className='inner-item'>
                        <span>Variant</span>
                        <div className="adds-on-item-panel">
                            <span>{selectedVehicleFromStore?.VariantName}</span>
                            <span>{rdUri.currencyFormat(selectedVehicleFromStore?.VariantBasePrice)}</span>
                        </div>
                    </div>
                    <div className='inner-item'>
                        <span>Exterior</span>
                        <div className="adds-on-item-panel">
                            <span>{selectedVehicleFromStore?.Exterior}</span>
                            <span>{rdUri.currencyFormat(selectedVehicleFromStore?.ExteriorPrice)}</span>
                        </div>
                    </div>
                    <div className='inner-item'>
                        <span>Interior</span>
                        <div className="adds-on-item-panel">
                            <span>{selectedVehicleFromStore?.Interior}</span>
                            <span>{rdUri.currencyFormat(selectedVehicleFromStore?.InteriorPrice)}</span>
                        </div>
                    </div>
                    {optionsTemplate(rdEnum.enumVehicleOptionType.Accessories)}
                    {optionsTemplate(rdEnum.enumVehicleOptionType.Charge)}
                    <div className='edit-box' >
                        <span onClick={() => scrollToSection('variant')}
                            className="stat-text-link"
                            data-dtm="checkout:order review:your selection"
                        >
                            Edit
                            <img alt='' src={`${getCMSAssets()}/checkout/arrow-black.svg`} />
                        </span>
                    </div>
                </div>

                <div className='payment-panel'>
                    <h6>PAYMENT</h6>
                    <div className='inner-item'>
                        <span>Customer Type</span>
                        <p>{customerTypeFromStore}</p>
                    </div>
                    <div className='inner-item'>
                        <span>Payment method</span>
                        <p>{paymentOptionsFromStore?.PaymentOption}</p>
                    </div>
                    <div className='edit-box' >
                        <span onClick={() => scrollToSection('payment')}
                            className="stat-text-link"
                            data-dtm="checkout:order review:payment"
                        >
                            Edit
                            <img alt='' src={`${getCMSAssets()}/checkout/arrow-black.svg`} />
                        </span>
                    </div>
                </div>

                <div className='delivery-panel'>
                    <h6>{selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? "DELIVERY" : "In-store Collection"}</h6>
                    <div className='inner-item'>
                        <span>Location</span>
                        <p>{getDeliveryAddress()}</p>
                    </div>
                    <div className='inner-item'>
                        <span>Preferred option</span>
                        <div className='adds-on-item-panel'>
                            <span>{selectedDeliveryFromStore.SelectedDeliveryOption}</span>
                            <span>{getDeliveryPrice()}</span>
                        </div>
                    </div>
                    <div className='inner-item'>
                        <span>Estimated delivery</span>
                        {
                             !selectedVehicleFromStore.HasStock && linkSourceFromStore != rdEnum.enumLinkSource.PrivateInterestLink
                             ?
                             <p>Waitlisted</p>
                                :
                                <p>{vehicleFromStore?.SelectedInterior?.Available}</p>
                        }

                    </div>
                    <div className='edit-box'>
                        <span onClick={() => scrollToSection('delivery')}
                            className="stat-text-link"
                            data-dtm={"checkout:order review:" + (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? "delivery" : "in-store collection")}>
                            Edit
                            <img alt='' src={`${getCMSAssets()}/checkout/arrow-black.svg`} />
                        </span>
                    </div>
                </div>
            </div>

            <div className='take-break-container'>
                <div className='take-break-title'>
                    Taking a break? 
                </div>
                {/*<div className='take-break-desc'>*/}
                {/*    Share your LYRIQ configuration with a unique link.*/}
                {/*</div>*/}
                <div className='take-break-btn-panel'>
                    <SaveDialog />
                    <ShareDialog />
                </div>
            </div>

            <div className='checkout-btn-panel'>
                {
                    linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                    ?
                    <p className='checkout-reminder'>Important: You're about to proceed to checkout. So we can hold your design, you won't be able to edit your LYRIQ after this point. Please check your configuration before proceeding. </p>
                    :
                    selectedVehicleFromStore.HasStock
                        ?
                        <p className='checkout-reminder'>Important: You're about to proceed to checkout. So we can hold your design, you won't be able to edit your LYRIQ after this point. Please check your configuration before proceeding. </p>
                        :
                        <></>
                }
                <Button className='checkout-btn stat-button-link' onClick={() => handleToCheckoutForm()} data-dtm="checkout">
                    {
                        linkSourceFromStore == rdEnum.enumLinkSource.PrivateInterestLink
                            ?
                            <h6>CONTINUE TO CHECKOUT</h6>
                            :
                            selectedVehicleFromStore.HasStock
                                ?
                                <h6>CONTINUE TO CHECKOUT</h6>
                                :
                                <h6>JOIN THE WAITLIST</h6>
                    }
                </Button>
            </div>

        </div>
    )
}

export default Checkout