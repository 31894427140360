import * as availableInventoryModel from 'model/IAvailableInventory';

export const getAvailableInventoryFilter = (): availableInventoryModel.IAvailableInventoryFilter => {
    let initAvailableInventory: availableInventoryModel.IAvailableInventoryFilter = {
        SelectedVariantsID: [],
        SelectedVariantsName: [],
        SelectedExteriorID: "",
        SelectedExteriorName: "",
        SelectedInteriorID: "",
        SelectedInteriorName: "",
    };

    return initAvailableInventory;
}


export const getAvailableRequest = (): availableInventoryModel.IAvailableInventoryRequest => {
    let initAvailableInventory: availableInventoryModel.IAvailableInventoryRequest = {
        Country: null,
        State: null,
        Postcode: null,
        Suburb: null,
        CustomerType: "",
        Variants: [],
        Exteriors: [],
        Interiors: [],
        DeliveryOption: null,
        Distance: null,
        Sort: null,
    };

    return initAvailableInventory;
}