import { getBackendPath } from 'helpers/Uri';

export const getCountryAndState = async (): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/CountryState/get-country-and-state`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};
