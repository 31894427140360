import * as docModel from 'model/IDocuments';

export const SET_ORDERDOCUMENTS = 'setOrderDocuments';
export const setOrderDocumentsAction = (docs: docModel.IDocumentItem[] | null) =>
({
    type: SET_ORDERDOCUMENTS,
    docs,
} as const);

export const CLEAR_ORDERDOCUMENTS = 'clearOrderDocuments';
export const clearOrderDocumentsAction = () =>
({
    type: CLEAR_ORDERDOCUMENTS
} as const);

export type OrderDocumentsActions =
    ReturnType<typeof setOrderDocumentsAction>
    | ReturnType<typeof clearOrderDocumentsAction>;

export const orderDocuemntsReducer = (
    state: docModel.IDocumentItem[] | null  = null,
    action: OrderDocumentsActions
): docModel.IDocumentItem[] | null => {
    switch (action.type) {
        case CLEAR_ORDERDOCUMENTS: {
            return null;
        }
        case SET_ORDERDOCUMENTS: {
            return action.docs;
        }
        //default:
        //    return state;
    }
    return state;
};