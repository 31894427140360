import { Store, createStore, combineReducers, applyMiddleware, compose, AnyAction } from 'redux';
import { sessionReducer, countryStateReducer } from "stores/Session";
import { customerTypeReducer } from "stores/CustomerType";
import { scrollPositionReducer } from 'stores/ScrollPosition';
import { mainSectionReducer } from 'stores/ScrollPosition';
import { mainRightPageReducer } from 'stores/MainRightPage'
import { selectedDeliveryReducer } from 'stores/UserSelectedDelivery';
import { selectedVehicleReducer } from 'stores/UserSelectedVehicleConfig';
import { paymentOptionsReducer } from 'stores/PaymentOptions';
import { checkoutCustomerReducer } from 'stores/CheckoutCustomer';
import { orderCustomerDetailsReducer } from 'stores/OrderCustomerDetails';
import { orderDetailsReducer } from 'stores/OrderDetails';
import { orderScheduleDeliveryReducer } from 'stores/OrderScheduleDelivery';
import { vehicleReducer } from 'stores/Vehicle';
import { defaultConfigReducer } from 'stores/DefaultConfig';
import { countryStatesReducer } from 'stores/CountryStateList';
import { linkSourceReducer } from 'stores/LinkSource';
import { orderDetailPageDataVersionReducer } from 'stores/OrderDetailPageDataVersion';
import { orderDocuemntsReducer } from 'stores/OrderDocuments';
import { IAppState } from 'model/IAppState';
import * as docModel from 'model/IDocuments';

import {
    loadState,
    saveState,
    clearStorageOnDeployment,
} from 'services/localStorage';


import _ from 'lodash';
import { isShowScrollBarReducer } from './ScrollBarDisplay';
import { mainPageNumberReducer } from './MainPageNumber';
import { globalDropdownReducer } from './GlobalDropdown';


//store interface
const appReducer = combineReducers<IAppState>({
    session: sessionReducer,
    countryInfo: countryStateReducer,
    scrollPosition: scrollPositionReducer,
    isShowScrollBar: isShowScrollBarReducer,
    mainSection: mainSectionReducer,
    mainPageNumber: mainPageNumberReducer,
    customerType: customerTypeReducer,
    mainRightPage: mainRightPageReducer,
    globalDropdown: globalDropdownReducer,
    selectedDelivery: selectedDeliveryReducer,
    selectedVehicle: selectedVehicleReducer,
    paymentOptions: paymentOptionsReducer,
    checkoutCustomer: checkoutCustomerReducer,
    orderCustomerDetails: orderCustomerDetailsReducer,
    orderDetails: orderDetailsReducer,
    orderScheduleDelivery: orderScheduleDeliveryReducer,
    orderDocuments: orderDocuemntsReducer,
    vehicle: vehicleReducer,
    defautConfig: defaultConfigReducer,
    countryStateList: countryStatesReducer,
    linkSource: linkSourceReducer,
    orderDetailDataVersion: orderDetailPageDataVersionReducer,
});

export const LOGOUT_CLEAR = 'logout_clear';
export const logoutClearAction = (updatedState: IAppState) =>
({
    type: LOGOUT_CLEAR,
    updatedState
} as const);
type ClearActions = ReturnType<typeof logoutClearAction>;
export const rootReducer = (
    state: any,
    action: AnyAction | ClearActions
) => {
    if (action.type === LOGOUT_CLEAR) {
        return appReducer(action.updatedState, { type: undefined });
    }

    return appReducer(state, action);
};

export function configureStore(): Store<IAppState> {
    // if no localStorage
    //console.log("store")
    //clearStorageOnDeployment(5);

    const url = window.location.href;
    if (url.includes("/inventory") || url.includes("/test-drive")
        || url.includes("/my-orders") || url.includes("/profile") || url.includes("/privacy")) {
        clearStorageOnDeployment(5);

        localStorage.setItem("paymentID", "");
        localStorage.setItem("order", "");
        localStorage.setItem("billing", "");
    }
    else {
        localStorage.clear();
    }

    

    const persistedState = loadState();
    const store = createStore(rootReducer,
        persistedState,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

    store.subscribe(
        _.throttle(() => {
            saveState(store.getState());
        }, 100),
    );

    return store;
}
