import { IChargingDialogProps } from 'model/IChargingDialogProps';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as vehicleModel from 'model/IVehicle';
import * as selectedConfigStore from 'stores/UserSelectedVehicleConfig';
import { IAppState } from 'model/IAppState';
import { currencyFormat } from 'helpers/Uri';
import { isNil } from 'lodash';

const ImportantInfoDialog = () => {
    const dispatch = useDispatch();

    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const [isOpen, setIsOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const handleDialogHide = () => {
        setIsOpen(false);
    }

    const toPrivacy = () => {
        window.open('https://www.mtf.co.nz/privacy-statement/')
    }

    const toRatesFees = () => {
        window.open('https://www.mtf.co.nz/resources/interest-rates-and-fees/')
    }

    return (
        <>
            <div className='important-info-box'>
                <span className='important-info-link' onClick={() => {
                    handleDialogOpen();
                }}><sup>1,&nbsp;2</sup>Important Information</span>.
                <span>&nbsp;Overseas model shown.</span>
            </div>

            <Dialog 
                header="" className='important-info-dialog' visible={isOpen} position='bottom'
                onHide={() => handleDialogHide()}
                draggable={false} resizable={true}
            >
                <div className='title-panel'>
                    <h1>IMPORTANT INFORMATION</h1>
                </div>
                <br/>
                {/* <p className='info-paragraph'>
                    <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. Actual drive away price may differ depending on your individual circumstances. Price does not take into account any discount, rebate or promotion that may be available from time to time. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. Vehicle registration includes 12 months’ registration fees, 12 months’ CTP insurance and any other associated charges in accordance with country, state and/or territory legislation. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to final prices, taxes etc. The website may vary from market to market and is subject to change without notice.
                </p>
                <br/>
                <p className='info-paragraph'>
                    Finance provided to approved applicants by Plenti Finance Pty Limited ABN 82 636 759 861.
                </p>
                <br/>
                <p className='info-paragraph'>
                    <sup>2</sup>The comparison rate is based on a secured car loan of $30,000 repaid over 60 months at an interest rate of 1.99% p.a.. WARNING: This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Finance provided to approved applicants by Plenti Finance Pty Limited ABN 82 636 759 861.                
                </p>
                <p className='info-paragraph'>
                    Terms, conditions and fees apply to any loan. Any calculations made by this calculator are dependent on the accuracy of the information provided, are intended to be used as a guide only, must not be relied on for any reason as conclusive, and do not constitute a quote. Your application will be subject to a credit approval process and lending criteria. Cadillac will not store the information provided in this calculator. Cadillac and their finance partner do not accept any responsibility for any loss, costs, or liability directly or indirectly incurred by persons who rely on any amount calculated by this calculator.
                </p>
                <p className='info-paragraph'>
                    Order and apply for finance on an eligible Cadillac vehicle by 31 August 2024 and take delivery by 30 September 2024 to qualify. Valid only for eligible purchases of new Cadillac configurations for finance loan terms up to 5 years. Not eligible with balloon repayments. Credit subject to financier's approval. Terms, conditions, fees and charges apply Interest rates are subject to change.
                </p>
                <p className='info-paragraph'>
                    This information does not constitute financial advice and you should consider whether it is appropriate to your circumstances before you act in reliance on it. For detailed advice and to discuss finance options tailored to your needs to determine the best finance option for your circumstances, we recommend speaking with a licensed independent financial advisor.
                </p>
                <br/>
                <p className='info-paragraph'>
                    <sup>3</sup>Availability is an estimate only and subject to change without notice. More accurate timing will be provided upon order and closer to the time of delivery.
                </p> */}
                 <p className='info-paragraph'>
                    <sup>1</sup>This is the drive away price for the selected country, state or territory. It is based on the owner being a ‘rating one’ driver aged 40 with a good driving record. The drive away price displayed is inclusive of the vehicle purchase price, goods and services tax (GST), luxury car tax (LCT), stamp duty, compulsory third party insurance, registration, a plate fees and a GST-inclusive regional delivery fee if applicable. The final drive away price may differ depending on your individual circumstances. The price does not take into account any valid discount, rebate or promotion that may be available from time to time. Each country, state or territory may offer additional registration types which are not covered in this configurator, therefore your individual situation may vary and have an impact on the final Drive Away price. Cadillac reserves the right to vary the Drive away price at its discretion, including for changes in specifications or features. The final Drive Away price will be provided to you upon checkout.
                </p>
                {/* <p className='info-paragraph'>
                    <sup>2</sup>This monthly repayment has been estimated using a calculator developed by Cadillac in partnership with finance partners referenced below as a guide only. The repayment is indicative and intended for illustrative purposes only. Cadillac will not store the information provided in this calculator. This repayment estimate should not be considered a loan application or an offer of finance. This repayment estimate does not take into account your personal circumstances or any fees and charges or government taxes which may apply.

                </p>
                {
                    selectedLocationFromStore?.Country?.name == 'Australia'
                    ?
                    <p className='info-paragraph'>
                        [Placeholder for Plenti Disclaimer – waiting on final version]
                    </p>
                    :
                    <p className='info-paragraph'>
                        Finance is provided by MTF Finance subject to its approval process, lending criteria and checks. Terms, conditions and fees apply to any offer of finance. Any personal information we collect about you may be shared with MTF Finance and will be treated in accordance with <a onClick={() => toPrivacy()}>MTF Finance’s privacy statement</a>. Calculator information is for illustrative purposes only and must not be relied upon for any reason. The information on this page is not financial advice or an offer of finance. Interest rates are subject to change. Your interest rate may be lower or higher depending on your credit history and other factors. For more information see <a onClick={() => toRatesFees()}>rates, fees and charges</a> to understand what borrowing may cost you. We recommend that you seek independent financial advice for your unique circumstances. Cadillac and MTF Finance do not accept any responsibility for any direct or indirect loss, cost, or liability incurred by persons who rely on any calculator information.
                    </p>
                } */}
                <p className='info-paragraph'>
                    {/* <sup>3</sup>Availability and delivery dates are estimates only. Many factors impact vehicle delivery. Cadillac reserves the right to make changes to availability and delivery dates displayed throughout this configurator without notice. */}
                    <sup>2</sup>Availability and delivery dates are estimates only. Many factors impact vehicle delivery. Cadillac reserves the right to make changes to availability and delivery dates displayed throughout this configurator without notice.
                </p>
                <p className='info-paragraph'>
                    The information presented in this configurator is based on data available at the time. Cadillac reserves the right to make changes to this website and any information and elements as time progresses (e.g. to design, specifications, prices, taxes, delivery dates etc). The website may vary from market to market and is subject to change.
                </p>
                {/* <p className='info-paragraph'>
                    `The information presented in this configurator is based on data available at the time. Cadillac reserves the right to make changes to this website on any information and elements as time progresses, e.g. to design, specifications, final prices, taxes, delivery dates etc. The website may vary from market to market and is subject to change without notice.
                </p> */}
                <p className='info-paragraph'>
                    {/* Vehicle shown on this page is a simulated overseas model. Actual production vehicles will vary. Vehicles shown here after are simulated local production vehicles intended for illustrative purposes. Cadillac has taken reasonable steps to ensure your digital configuration replicates the vehicle you receive in real life. Colours and other features may appear different in real life. */}
                    The vehicle shown on this page is a simulated overseas model. Actual production vehicles will vary. Vehicles shown here after are simulated local production vehicles intended for illustrative purposes only. Cadillac has taken reasonable steps to ensure your digital configuration replicates the vehicle you receive in real life, however Cadillac cannot guarantee its accuracy. Colours and other features may appear different in real life.
                </p>
            </Dialog>
        </>
    )
}

export default ImportantInfoDialog