import * as orderCustomerDetailsModel from 'model/IOrderCustomerDetails';
import * as driverLicenseModel from 'model/IDriverLicense';
import * as addressHelper from 'businessHelpers/AddressHelper';
import * as driverLicenseHelper from 'businessHelpers/DriverLicenseHelper';

export const getInitDetails = (): orderCustomerDetailsModel.IOrderCustomerDetails => {
    let initAddress: orderCustomerDetailsModel.IOrderCustomerDetails = {
        country: "",
        birthday: "" ,// undefined,
        //birthdayFormat: "",
        driverLicense: driverLicenseHelper.getInitDriverLicense(),
        customerType: "",
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        phone: "",
        secondaryPhone: "",
        abn: "",
        businessName: "",
        billingAddress: addressHelper.getInitAddress(),
        regoAddress: addressHelper.getInitAddress(),
        deliveryAddress: addressHelper.getInitAddress(),
        paymentOption: "",
        yanaCustomerID: "",
        yanaCustomerNo: "",
        yanaOrderID: "",
        yanaOrderNo: "",
        actionType: ""
    };

    return initAddress;
}

export const getInitInvalidCustomerDetails = (): orderCustomerDetailsModel.IInvalidOrderCustomerDetails => {
    let init: orderCustomerDetailsModel.IInvalidOrderCustomerDetails = {
        invalidBirthday: null,
        invalidBusinessname: null,
        invalidABN: null,
        invalidFirstname: null,
        invalidLastname: null,
        invalidEmail: null,
        invalidPhone: null,
        invalidSecondaryPhone: null,
        invalidDriverLicense: driverLicenseHelper.getInitInvalidDriverLicense(),
        invalidBillingAddress: addressHelper.getInitInvalidAddress(),
        invalidRegoAddress: addressHelper.getInitInvalidAddress(),
        invalidDeliveryAddress: addressHelper.getInitInvalidAddress(),
    };

    return init;
}