import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'helpers/authConfig';
import { isNil } from 'lodash';

export const useAzureB2C = () => {
    const { instance } = useMsal(); 
    const isAuthenticated = useIsAuthenticated();

    const signIn = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }
    const signOut = () => {
        instance.logoutRedirect();
    }

    const isAuthAndLogin = (inProgress:any) => {
        if (inProgress !== InteractionStatus.None) return;
        if (!isAuthenticated && instance.getAllAccounts.length === 0) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));
            return false;
        } else {
            return true;
        }
    }

    const isAuth= (inProgress: any) => {
        if (inProgress !== InteractionStatus.None) return;
        if (!isAuthenticated && instance.getAllAccounts.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    return {
        signIn,
        signOut,
        isAuth,
        isAuthAndLogin
    };
}
