import { IScrollBarDisplay } from "helpers/interface";

export const SET_SCROLLBAR_DISPLAY = 'SetScrollbarDisplay';
export const checkIsShowScrollBar = (isShowScrollBar: boolean) =>
    ({
        type: SET_SCROLLBAR_DISPLAY,
        isShowScrollBar
    } as const);
    
    export type IsShowScrollBarActions = ReturnType<typeof checkIsShowScrollBar>;
    export const initIsShowScrollBarState: IScrollBarDisplay = {
        isShowScrollBar: true
    }
    
    export const isShowScrollBarReducer = (
        state = initIsShowScrollBarState,
        action: IsShowScrollBarActions,
    ) => {
        switch (action.type) {
            case SET_SCROLLBAR_DISPLAY: {
                return {
                    ...state,
                    isShowScrollBar: action.isShowScrollBar,
                };
            }
            default:
            return state;
        }
    };