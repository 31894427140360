import { useEffect, useRef, useState } from "react";

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState<'small' | 'medium' | 'large' | null>(null);

    useEffect(() => {
        // Define media queries for small, medium, and large screens
        const smallScreen = window.matchMedia('(max-width: 768px)');
        const mediumScreen = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
        const largeScreen = window.matchMedia('(min-width: 1025px)');

        const handleResize = () => {
            if (smallScreen.matches) {
                setScreenSize('small');
            } else if (mediumScreen.matches) {
                setScreenSize('medium');
            } else if (largeScreen.matches) {
                setScreenSize('large');
            }
        };

        // Initial check
        handleResize();

        // Add event listeners for each media query
        smallScreen.addEventListener('change', handleResize);
        mediumScreen.addEventListener('change', handleResize);
        largeScreen.addEventListener('change', handleResize);

        return () => {
            smallScreen.removeEventListener('change', handleResize);
            mediumScreen.removeEventListener('change', handleResize);
            largeScreen.removeEventListener('change', handleResize);
        };
    }, []);

    return screenSize;
};