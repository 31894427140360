import { getFrontendUrl, getCMSAssets } from 'helpers/Uri'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';


const NoInventoryReminder = () => {
    const selectedVehicleFromStore = useSelector((state: IAppState) => {
        return state.selectedVehicle;
    });

    const navigate = useNavigate();

    const handleToInventoryPage = () => {
        let url = `${getFrontendUrl()}/inventory?inventory?shopfront=1`;
        //let url = `${getFrontendUrl()}/inventory?variant=${selectedVehicleFromStore.VariantName}`;
        //url += `&exterior=${selectedVehicleFromStore.Exterior}&interior=${selectedVehicleFromStore.Interior}`;
        //url += `&outOfStockBoxLocation=1`;  
        navigate(url);
    }

    return (
        <div className='inventory-unavailable-reminder-container'>
            <div className='inventory-unavailable-reminder'>
                {/* <p>
                    The configuration you have
                </p>
                <p>
                    selected is currently exhausted
                </p> */}
                <p>
                    Your design is currently sold out, but you can continue and join a waitlist. There are a selection of configurations available sooner
                </p>

                <div className='search-inventory stat-text-link' onClick={() => handleToInventoryPage()} data-dtm="checkout:announcement">
                    <h5>
                        View Available Inventory
                    </h5>
                    <img alt='' src={`${getCMSAssets()}/home/arrow-white.svg`} />
                </div>
            </div>
        </div>
    )
}

export default NoInventoryReminder