import ScrollToTop from 'components/ScrollToTop'
import { setCustomerTypeAction } from 'stores/CustomerType';
import { getCMSAssets, getFrontendUrl, currencyFormatWithBlank } from 'helpers/Uri'
import { IAppState } from 'model/IAppState';
import React, { useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import IsNumberVerification from 'components/IsNumberVerification';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { useMsal } from "@azure/msal-react";
import moment from 'moment';
import SaveDialog from 'pages/Main/checkout/SaveDialog';
import NoStorageReminder from 'components/NoInventoryReminder';

import * as Uri from 'helpers/Uri';
import * as rdEnum from 'helpers/enum';
import * as checkoutHelper from 'businessHelpers/CheckoutHelper';
import * as commonModel from 'model/ICommon';
import * as checkOutViewModel from 'model/ICheckoutWaitlistViewModel';
import * as selectedConfigModel from 'model/IUserSelectedConfig';
import * as orderModel from 'model/IOrder';
import * as interestModel from 'model/IInterest';
import * as paymentService from 'services/AdyenPayment';
import * as orderService from 'services/Order';
import * as interestService from 'services/Interest';
import * as checkoutCustomerStore from 'stores/CheckoutCustomer';
//import * as orderStore from 'stores/Order';

import TermsConditionsDialog from './TermsConditionsDialog';
import { Dialog } from 'primereact/dialog';

import { useAzureB2C } from 'helpers/useAzureB2C';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import PrivacyCollectionDialog from './PrivacyCollectionDialog';
import AccountGuidelines from './AccountGuidelines';

const CheckoutWaitlist = (props: any) => {
    const azureB2C = useAzureB2C();
    const { instance } = useMsal();

    //store variables
    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });
    const selectVehFromStore = useSelector((appState: IAppState) => {
        return appState.selectedVehicle;
    });
    const selectedPaymentOptions = useSelector((appState: IAppState) => {
        return appState.paymentOptions;
    });
    const customerTypeForStore = useSelector((state: IAppState) => {
        return state.customerType;
    });
    const checkOutCustomerFromStore = useSelector((state: IAppState) => {
        return state.checkoutCustomer;
    });
    const mainPageNumber = useSelector((state: IAppState) => {
        return state.mainPageNumber.mainPageNumber;
    });
    const driveawayFromStore = useSelector((state: IAppState) => {
        return state.paymentOptions.DriveAwayCost;
    });
    const linkSourceFromStore = useSelector((state: IAppState) => {
        return state?.linkSource;
    });
    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const { paymentPageStatus, toConfiguration } = props;

    const [clientType, setClientType] = useState(rdEnum.enumClientTypes.Private.Code);

    const saveDiaglogRef = useRef<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const watchlistRef = useRef<HTMLDivElement>(null);
    const joinConsentRef1 = useRef<HTMLInputElement>(null);
    const joinConsentRef2 = useRef<HTMLInputElement>(null);
    //const firstNameRef = useRef<HTMLInputElement>("");

    //const [billingAddresss, setBillingAddresss] = useState("");
    const [pageState, setPageState] = useState<checkOutViewModel.ICheckoutWaitlistProps>(checkoutHelper.getCheckoutInitPageState());
    const [reserverationSubmittingStatus, setReservationSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [confirmOrderSubmittingStatus, setConfirmOrderSubmittingStatus] = useState(rdEnum.enumProcessingStatus.Init);
    const [submittingToYana, setSubmittingToYana] = useState(rdEnum.enumProcessingStatus.Init);

    const [order, setOrder] = useState<orderModel.IOrderRequest>(checkoutHelper.getOrderInitState());
    const [placeReservationMessage, setPlaceReservationMessage] = useState<string>("");
    const [placeOrderMessage, setPlaceOrderMessage] = useState<string>("");

    const [isShowPaymentPanel, setIsShowPaymentPanel] = useState(false);

    //adyen drop in way
    const paymentContainer = useRef<HTMLDivElement>(null);
    //const [customCardInstance, setCustomCardInstance] = useState<any>();
    const customCardInstance = useRef<any>(null);
    const [adyenSession, setAdyenSession] = useState<checkOutViewModel.IAdyenSession>(checkoutHelper.getAdyenInitState());
    const [isShowAdyenRetryButton, setIsShowAdyenRetryButton] = useState(false);
    const [adyenFailedMessage, setAdyenFailedMessage] = useState("");

    //reservation time
    const reservationExpiryMin = 10;
    const reservationExendMaxCount = 2;
    const [reservationCountDownSeconds, setReservationCountDownSeconds] = useState(reservationExpiryMin * 60);   //minutes
    const [reservationCountdownStage, setReservationCountdownStage] = useState(1);
    const [reservationExtendCount, setReservationExtendCount] = useState(0);
    const [showCountdownDialog, setShowCountdownDialog] = useState(false);

    //LMCT
    const [lmctText, setLMCTText] = useState<string>("");


    //show out of stock box
    const [showOutOfStock, setShowOutOfStock] = useState<boolean>(false);


    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const [confirmed, setConfirmed] = useState({
        confirm: false,
        invalidConfirm: false,
        agreement: false,
    })

    const paymentErrorMessage = "Your credit card details have been refused, please re-enter your details.";

    //https://github.com/Adyen/adyen-web/blob/c6c19ac646a8a211d0416a462b2c2a2a722eff29/packages/lib/src/language/locales/en-US.json
    const translations = {
        "en_AU": {
            "creditCard.numberField.title": "",
            /* "creditCard.numberField.title": "Card Number *",*/
            "creditCard.numberField.placeholder": "1234 5678 9012 3456",
            "creditCard.expiryDateField.title": "",
            "creditCard.expiryDateField.placeholder": "MM/YY",
            "creditCard.cvcField.title": "",
            "creditCard.cvcField.placeholder": "CVV *",
            "creditCard.cvcField.placeholder.4digits": "CVV *",
            "creditCard.cvcField.placeholder.3digits": "CVV *",
            "creditCard.holderName": "",
            "creditCard.holderName.placeholder": "Name on card",
            "billingAddress": "",
            "country": "",
            "city": "",
            "city.errors.incomplete": "Enter Suburb",
            "state": "",
            "postalCode": "",
            "postalCode.errors.incomplete": "Enter Postcode",
            "address": "",
            "address.enterManually": "Address",
            "address.errors.incomplete": "Enter Address to continue",
        },
        "en_NZ": {
            "creditCard.numberField.title": "",
            "creditCard.numberField.placeholder": "1234 5678 9012 3456",
            "creditCard.expiryDateField.title": "",
            "creditCard.expiryDateField.placeholder": "MM/YY",
            "creditCard.cvcField.title": "",
            "creditCard.cvcField.placeholder": "CVV *",
            "creditCard.cvcField.placeholder.4digits": "CVV *",
            "creditCard.cvcField.placeholder.3digits": "CVV *",
            "creditCard.holderName": "",
            "creditCard.holderName.placeholder": "Name on card",
            "billingAddress": "",
            "country": "",
            "city": "",
            "city.errors.incomplete": "Enter Suburb",
            "state": "",
            "postalCode": "",
            "postalCode.errors.incomplete": "Enter Postcode",
            "address": "",
            "street": "",
            "address.enterManually": "Address",
            "address.errors.incomplete": "Enter Address to continue",
            "houseNumberOrName": "",
        }
    };


    useEffect(() => {
        if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
            doGetLMCT();
        }

    }, [selectedDeliveryFromStore?.Country?.code, selectedDeliveryFromStore?.PrimaryState])

    useEffect(() => {

        //todo: potential change, 
        //1. change vehicle to place a new reservation, show "Proceed payment" button
        //2. if vehicle not changed, use same orderid, reservation no, still show payment credeit card section
        //Need to thinking:  do i need to change RD payment id? it's better to change a new payment id as user could have tried one payment, and got failure
        setIsShowPaymentPanel(false);
    }, [selectedDeliveryFromStore, selectVehFromStore, selectedPaymentOptions, customerTypeForStore]);


    const doGetLMCT = async () => {
        let country = selectedDeliveryFromStore?.Country?.code || "";
        let state = selectedDeliveryFromStore?.PrimaryState;
        if (isNil(state) || state == "") {
            state = selectedDeliveryFromStore?.State?.code || "";
        }

        if (country == "" || state == "") return;
        let response = await orderService.getLMCT(country, state);
        if (response.status) {
            setLMCTText(response.data.partnerLMCT);
        }
        else {
            setLMCTText("");
        }
    }



    const doGetAdyenConfig = () => {
        //console.log(adyenSession.SessionID);
        let env = Uri.getAdyenEnv(selectedDeliveryFromStore?.Country?.code || "");
        let configuration = {
            locale: selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand ? "en_NZ" : "en_AU",
            environment: env, // "test", // env, //Uri.getAdyenEnv(selectedDeliveryFromStore?.Country?.code),   // Australia: 	live-au
            clientKey: Uri.getAdyenKey(selectedDeliveryFromStore?.Country?.code || ""),
            translations: translations,
            session: {
                id: adyenSession.SessionID, // Unique identifier for the payment session.
                sessionData: adyenSession.SessionData // The payment session data.
            },
            //setStatusAutomatically: false,  //Do not use this flag. We have to a get new session id as RD reference id need be changed for each pay action
            onPaymentCompleted: async (result: any, component: any) => {
                setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
                let localBilling = localStorage.getItem("billing");
                let paymentID = localStorage.getItem("paymentID");
                let strPaymentOrder = localStorage.getItem("order");

                let postAdyenSession: checkOutViewModel.IAdyenSession = {
                    SessionID: adyenSession.SessionID,
                    SessionData: result.sessionData,
                    SessionResult: result.sessionResult,
                    ResultCode: result.resultCode,
                    ErrorMessage: null
                };

                if (!isNil(result)) {
                    if (result.resultCode == "Authorised") {
                        setAdyenFailedMessage("");

                        if (isNil(localBilling) || localBilling == "") return;
                        if (isNil(strPaymentOrder) || strPaymentOrder == "") return;
                        let billingData: checkOutViewModel.IAdyenTempPaymentData = JSON.parse(localBilling);
                        let paymentOrder: orderModel.IOrderRequest = JSON.parse(strPaymentOrder);
                        let postOrder: orderModel.IOrderRequest = {
                            ...paymentOrder,
                            BillingAddress: billingData.Address1,
                            BillingPostcode: billingData.PostCode,
                            BillingState: billingData.State,
                            BillingSuburb: billingData.Suburb,
                            RDPaymentID: paymentID,
                            NameOnCreditCard: billingData.NameOnCard
                        };

                        doPlaceOrder(postOrder, postAdyenSession);

                    }
                    else if (result.resultCode == "Refused") {
                        if (isNil(strPaymentOrder) || strPaymentOrder == "") return;
                        let paymentOrder: orderModel.IOrderRequest = JSON.parse(strPaymentOrder);
                        let postOrder: orderModel.IOrderRequest = {
                            ...paymentOrder,
                            RDPaymentID: paymentID,
                        };
                        await doSavePaymentLog(postOrder, postAdyenSession);

                        await doGetPaymentIDs(postOrder?.OrderID, postOrder?.Country);
                        setOrder(postOrder);
                        setAdyenFailedMessage(paymentErrorMessage);
                        setIsShowAdyenRetryButton(true);
                    }
                }
            },
            beforeSubmit: (data: any, component: any, actions: any): Promise<void> => {
                //state or store changed here, are not updated in onPaymentCompleted
                //so have to use local storage to temp store billing data and card holder name.
                let tempData: checkOutViewModel.IAdyenTempPaymentData = {
                    Address1: data.billingAddress.street,
                    Address2: data.billingAddress.houseNumberOrName,
                    PostCode: data.billingAddress.postalCode,
                    Suburb: data.billingAddress.city,
                    State: data.billingAddress.stateOrProvince,
                    NameOnCard: data.paymentMethod.holderName,
                };

                localStorage.setItem("billing", JSON.stringify(tempData));
                setAdyenFailedMessage("");
                setPlaceOrderMessage("");
                return actions.resolve(data);
            },
            //onSubmit: (state: any, element: any)=> {
            //    console.log(state.data);
            //   need to setup payments api on the backend
            //},
            onChange: (state: any, component: any) => {
                //console.log('Form validity:', state.isValid);
                //console.log(state);
                //// Check specific fields
                //if (!state.valid) {
                //    Object.keys(state.valid).forEach(field => {
                //        if (!state.valid[field]) {
                //            console.log(`Invalid field: ${field}`);
                //        }
                //    });
                //}

                //if (state.data.houseNumberOrName) {
                //    // Make postal code optional
                //    if (state.data.billingAddress.houseNumberOrName === '') {
                //        delete state.data.billingAddress.houseNumberOrName;
                //    }
                //}

                //console.log(state);
                //setPaymentState(state);
                //console.log(state);
                //console.log("state on change." + JSON.stringify(state));
                //console.log("pageState in validateBillingInfo:" + JSON.stringify(pageState));
            },
            //onFieldValid: (state: any) => {
            //    console.log(state);
            //},
            onError: async (error: any, component: any) => {
                let strPaymentOrder = localStorage.getItem("order");
                if (isNil(strPaymentOrder) || strPaymentOrder == "") return;
                let paymentOrder: orderModel.IOrderRequest = JSON.parse(strPaymentOrder);
                let paymentID = localStorage.getItem("paymentID");
                let postOrder: orderModel.IOrderRequest = {
                    ...paymentOrder,
                    RDPaymentID: paymentID,
                };

                let postAdyenSession: checkOutViewModel.IAdyenSession = {
                    SessionID: adyenSession.SessionID,
                    SessionData: adyenSession.SessionData,
                    SessionResult: null,
                    ResultCode: "Error",
                    ErrorMessage: error.message
                };
                await doSavePaymentLog(postOrder, postAdyenSession);

                await doGetPaymentIDs(postOrder?.OrderID, postOrder?.Country);
                setOrder(postOrder);
                setAdyenFailedMessage(paymentErrorMessage);
                setIsShowAdyenRetryButton(true);

            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: true,
                    //billingAddressAllowedCountries: ["AU", "NZ"],
                    data: {
                        billingAddress: {
                            country: selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand ? "NZ" : "AU",
                            houseNumberOrName: "N/A"
                            //placeholder: {
                            //    //"postalCode": "Postcode",
                            //    "postalCode.placeholder": "Postcode",
                            //},
                        },
                        //placeholder: {
                        //    "postalCode": "Postcode",
                        //},
                    },
                    brands: ['mc', 'visa', 'amex']
                },
                //threeDS2: { // Web Components 4.0.0 and above: sample configuration for the threeDS2 action type
                //    challengeWindowSize: '05'
                //    // Set to any of the following:
                //    // '02': ['390px', '400px'] -  The default window size
                //    // '01': ['250px', '400px']
                //    // '03': ['500px', '600px']
                //    // '04': ['600px', '400px']
                //    // '05': ['100%', '100%']
                //},
            },
            showPayButton: false
        };

        return configuration;

    }

    const doGetPaymentIDs = async (orderID: any, country: any) => {
        let response = await paymentService.getNewPaymentIDs(orderID, country);
        if (response.status) {
            let responseData = response.data;
            setAdyenSession({
                ...adyenSession,
                SessionID: responseData.adyenSessionID,
                SessionData: responseData.adyenSessionData
            });
            localStorage.setItem("paymentID", responseData.rdPaymentTransactionID);
        }
        else {
            localStorage.setItem("paymentID", "");
            setAdyenFailedMessage(response.message);
            setAdyenSession(checkoutHelper.getAdyenInitState());
        }
    }

    const doPlaceOrder = async (order: orderModel.IOrderRequest, adyenSession: checkOutViewModel.IAdyenSession) => {
        setSubmittingToYana(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.placeOrder(order, adyenSession);
        setSubmittingToYana(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            let yanaIds = response.data.yanaOrderIDs;
            let paymentID = response.data.rdPaymentTransactionID;
            let url = `${getFrontendUrl()}/order-success/${yanaIds.yanaCustomerID}/${yanaIds.yanaCustomerNo}/${yanaIds.yanaOrderID}/${yanaIds.yanaOrderNo}/${paymentID}`;
            //console.log(url);
            navigate(url);
        }
        else {
            setPlaceOrderMessage(response.message);
        }
    }

    const doSavePaymentLog = async (order: orderModel.IOrderRequest, adyenSession: checkOutViewModel.IAdyenSession) => {
        let response = await paymentService.savePaymentLog(order, adyenSession);
        if (response.status) {

        }
        else {

        }
    }


    ///init adyen drop-in component
    useEffect(() => {
        let ignore = false;
        const doInitPayment = async (isRefresh: boolean) => {
            let adyenConfig = doGetAdyenConfig();
            const checkout = await AdyenCheckout(adyenConfig);
            if (!isNil(paymentContainer?.current) && !ignore) {
                customCardInstance.current = checkout.create('dropin', {    //securedfields, card,dropin
                    onReady: () => {
                    },
                }).mount(paymentContainer.current);

                //todo: seems to work, still need to double check
                //if (isRefresh) {
                //    customCardInstance.current.update();
                //    customCardInstance.current.remount();
                //}

                //setCustomCardInstance(customCard);
            }
        }

        if (!isNil(adyenSession?.SessionID) && adyenSession?.SessionID != ""
            && !isNil(adyenSession?.SessionData) && adyenSession?.SessionData != "") {
            if (customCardInstance.current) {
                //console.log("refresh adyen");
                doInitPayment(true);
            }
            else {
                //console.log("init payment");
                doInitPayment(false);
            }

        }

        return () => {
            ignore = true;
        }
    }, [paymentContainer.current, adyenSession]);

    setTimeout(() => {
        const streetRef = document.getElementsByName("street");
        if (streetRef != null && streetRef.length > 0) {
            streetRef[0]?.setAttribute("placeHolder", "Address");
        }
        const cityRef = document.getElementsByName("city");
        if (cityRef != null && cityRef.length > 0) {
            cityRef[0]?.setAttribute("placeHolder", "Suburb");
        }

        const postcodeRef = document.getElementsByName("postalCode");
        if (postcodeRef != null && postcodeRef.length > 0) {
            postcodeRef[0]?.setAttribute("placeHolder", "PostCode");
        }



        const houseNumberOrNameRef = document.getElementsByName("houseNumberOrName");
        if (houseNumberOrNameRef != null && houseNumberOrNameRef.length > 0) {
            houseNumberOrNameRef[0]?.setAttribute("placeHolder", "House Number");
            //houseNumberOrNameRef[0]?.nodeValue = "N/A";
        }

    }, 1000);

    //if email already exists, show confirm diaglog if go to login page
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    //const [placeOrderWithoutLogin, setPlaceOrderWithoutLogin] = useState(false);
    const [confirmPopupMessage, setConfirmPopupMessage] = useState("");


    const scrollToSectionRef = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // or 'center', 'end', 'nearest'
                //inline: 'end', // or 'start', 'center', 'end'
            });
        }
    };


    useEffect(() => {
        setClientType(customerTypeForStore || rdEnum.enumClientTypes.Private.Code);
    }, [customerTypeForStore]);

    //page load
    useEffect(() => {
        clearMessage();
    }, [mainPageNumber]);

    useEffect(() => {
        //redirect from login page
        if (!isNil(checkOutCustomerFromStore)) {
            setPageState(checkOutCustomerFromStore);
        }

    }, [checkOutCustomerFromStore])


    const clearMessage = () => {
        setPlaceReservationMessage("");
        //setPaymentFailedMessage("");

        setConfirmPopupMessage("");
        setPlaceOrderMessage("");
        setAdyenFailedMessage("");
    }


    const isNumberKey = (e: any) => {
        IsNumberVerification(e);
    }

    const validateAccount = (): boolean => {
        let isInvalid = false;

        let invalidABN = false;
        let invalidABNLength = false;
        let invalidBusinessName = false;
        if (clientType == rdEnum.enumClientTypes.Business.Code) {
            if (isNil(pageState.abn) || pageState.abn == "") {
                invalidABN = true;
                isInvalid = true;
            }
            else {
                if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
                    if (pageState.abn.length != 11) {
                        invalidABNLength = true;
                        isInvalid = true;
                    }
                }
                else {
                    if (pageState.abn.length != 13) {
                        invalidABNLength = true;
                        isInvalid = true;
                    }
                }
            }



            if (isNil(pageState.businessname) || pageState.businessname == "") {
                invalidBusinessName = true;
                isInvalid = true;
            }
        }

        let invalidFirstName = false;
        if (isNil(pageState.firstname) || pageState.firstname == "") {
            invalidFirstName = true;
            isInvalid = true;
        }

        let invalidLastName = false;
        if (isNil(pageState.lastname) || pageState.lastname == "") {
            invalidLastName = true;
            isInvalid = true;
        }

        let invalidEmail = false;
        if (isNil(pageState.email) || pageState.email == "") {
            invalidEmail = true;
            isInvalid = true;
        }
        else {
            if (!Uri.validateEmail(pageState.email)) {
                invalidEmail = true;
                isInvalid = true;
            }
        }
        let invalidConfirmEmail = false;
        if (isNil(pageState.confirmEmail) || pageState.confirmEmail == "") {
            invalidConfirmEmail = true;
            isInvalid = true;
        }
        else {
            if (!Uri.validateEmail(pageState.confirmEmail)) {
                invalidConfirmEmail = true;
                isInvalid = true;
            }
        }

        let emailMisMatch = false;
        if (!isNil(pageState.email) && pageState.email != "" && !isNil(pageState.confirmEmail) && pageState.confirmEmail != ""
            && pageState.email != pageState.confirmEmail) {
            emailMisMatch = true;
            isInvalid = true;
        }

        let invalidMobile = false;
        if (isNil(pageState.mobile) || pageState.mobile == "") {
            invalidMobile = true;
            isInvalid = true;
        }

        setPageState({
            ...pageState,
            invalidABN: invalidABN,
            invalidABNLength: invalidABNLength,
            invalidBusinessname: invalidBusinessName,
            invalidFirstname: invalidFirstName,
            invalidLastname: invalidLastName,
            invalidEmail: invalidEmail,
            invalidConfirmEmail: invalidConfirmEmail,
            invalidMobile: invalidMobile,
            emailMismatch: emailMisMatch,
        });

        return isInvalid;
    }


    //place reservation
    const onClickPaymentButton = async (withoutLogin: boolean) => {

        let invalidAccount = validateAccount();
        if (invalidAccount) return;

        setIsShowAdyenRetryButton(false);
        setAdyenFailedMessage("");
        setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Init);
        setPageState({ ...pageState, agreement: false });
        setShowOutOfStock(false);


        let orderData: orderModel.IOrderRequest = {
            Country: selectedDeliveryFromStore?.Country?.code || "AU",
            State: selectedDeliveryFromStore?.State?.code || "",
            VariantName: selectVehFromStore.VariantName || "",
            VariantPrice: selectVehFromStore.VariantBasePrice || 0,
            ExteriorName: selectVehFromStore.Exterior || "",
            ExteriorPrice: selectVehFromStore.ExteriorPrice || 0,
            InteriorName: selectVehFromStore.Interior || "",
            InteriorPrice: selectVehFromStore.InteriorPrice || 0,
            VehicleImages: [],
            VehicleThumbnail: null,
            Options: selectVehFromStore.Options || [],
            DeliveryLocationState: getDeliveryLocationState() || "",
            DeliveryLocationSuburb: getDeliveryLocationSuburb() || "",
            DeliveryLocationPostcode: getDeliveryLocationPostcode() || "",
            DeliveryLocationAddress: getDeliveryLocationFullAddress(),
            DeliveryOption: selectedDeliveryFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.HomeDelivery,
            PickupLocationName: selectedDeliveryFromStore?.PickupLocationName,
            Available: vehicleFromStore?.SelectedInterior?.Available || "",
            PaymentOption: selectedPaymentOptions?.PaymentOption || rdEnum.enumPaymentOptions.Cash,
            DriveawayCost: selectedPaymentOptions?.DriveAwayCost,
            Finance: selectedPaymentOptions?.Finance,
            Email: pageState.email,
            FirstName: pageState.firstname,
            LastName: pageState.lastname,
            Mobile: pageState.mobile,
            ABN: pageState.abn || "",
            BusinessName: pageState.businessname || "",
            CustomerType: clientType || rdEnum.enumClientTypes.Private.Code,
            BillingAddress: null,
            BillingSuburb: null,
            BillingPostcode: null,
            BillingState: null,
            RegoAddress: selectedDeliveryFromStore?.PrimaryAddress || "",
            RegoSuburb: selectedDeliveryFromStore?.PrimarySuburb || "",
            RegoPostcode: selectedDeliveryFromStore?.PrimaryPostcode || "",
            RegoState: selectedDeliveryFromStore?.PrimaryState || "",
            NameOnCreditCard: null,
            PrivacyStatement: true,
            RDAcountID: null,
            OrderID: null,
            YanaOrderReservationID: null,
            YanaOrderReservationNo: null,
            YanaOrderID: null,
            YanaOrderNo: null,
            YanaCustomerID: null,
            YanaCustomerNo: null,
            RDPaymentID: null,
            FromLinkSource: linkSourceFromStore,
            PaymentHistory: null,
            DownPayment: null,
            PaymentDueDate: null,
            FinalPaymentAmount: null,
            Total: null,
            Balance: null,
            PaymentAmount: null,
        };
        //return;
        let activeAccount = instance.getActiveAccount();
        //let hasLogin = !isNil(activeAccount) || withoutLogin;

        clearMessage();
        setReservationSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.placeReservation(orderData, true);   //scope changed:no need to check email exists anymore
        setReservationSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            let responseOrderData = response.data.order;

            orderData = {
                ...orderData,
                RDAcountID: responseOrderData.rdAccountID,
                OrderID: responseOrderData.orderID,
                YanaOrderReservationID: responseOrderData.yanaReservationID,
                YanaOrderReservationNo: responseOrderData.yanaReservationNumber,
                RDPaymentID: responseOrderData.rdPaymentTransactionID,
                PaymentAmount: responseOrderData.paymentAmount,
            }
            setOrder(orderData);
            localStorage.setItem("paymentID", responseOrderData.rdPaymentTransactionID);
            localStorage.setItem("order", JSON.stringify(orderData));    //not able to get the latest order state value in adyen payment drop-in, have temp store it in localstorage

            setIsShowPaymentPanel(true);
            dispatch(checkoutCustomerStore.clearCheckoutCustomerDetailsAction());

            setAdyenSession({
                ...adyenSession,
                SessionID: responseOrderData.adyenSessionID,
                SessionData: responseOrderData.adyenSessionData
            });

            //setPlaceReservationTime(new Date());

            setReservationCountDownSeconds(response.data.reservationCountDown);
            setReservationCountdownStage(response.data.reservationCountDownStage);
            setReservationExtendCount(response.data.reservationExtensionCount);
            if (response.data.reservationCountDownStage == 2) {
                setShowCountdownDialog(true);
            }
        }
        else {

            if (response.errorCode == rdEnum.enumRDErrorCode.ExistedEmail) {
                setShowConfirmPopup(true);
                setPageState({
                    ...pageState,
                    emailDuplicated: true
                })
                setConfirmPopupMessage(response.message);
                dispatch(checkoutCustomerStore.setCheckoutCustomerDetailsAction(pageState));
            }
            else if (response.errorCode == rdEnum.enumRDErrorCode.OutOfStock) {
                setShowOutOfStock(true);
                dispatch(checkoutCustomerStore.clearCheckoutCustomerDetailsAction());
            }
            else {
                setPlaceReservationMessage(response.message);
                dispatch(checkoutCustomerStore.clearCheckoutCustomerDetailsAction());
            }
        }

    }

    const getDeliveryLocationState = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationState;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryState || "";
        }
    }

    const getDeliveryLocationPostcode = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationPostcode;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryPostcode || "";
        }
    }

    const getDeliveryLocationSuburb = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationSuburb;
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliverySuburb || "";
        }
    }

    const getDeliveryLocationFullAddress = () => {
        if (selectedDeliveryFromStore.SelectedDeliveryOption == rdEnum.enumDeliveryOptions.Pickup) {
            return selectedDeliveryFromStore?.SelectedPickupLocation?.LocationAddress || "";
        }
        else {
            return selectedDeliveryFromStore?.SelectedDeliveryFullAddress || "";
        }

    }


    const onClickWaitlist = async () => {
        let invalidAccount = validateAccount();
        if (invalidAccount) return;

        let interestData: interestModel.IInterest = {
            Country: selectedDeliveryFromStore?.Country?.code || "",
            State: selectedDeliveryFromStore?.State?.code || "",
            VariantName: selectVehFromStore.VariantName || "",
            VariantBasePrice: selectVehFromStore.VariantBasePrice || 0,
            ExteriorName: selectVehFromStore.Exterior || "",
            ExteriorPrice: selectVehFromStore.ExteriorPrice || 0,
            InteriorName: selectVehFromStore.Interior || "",
            InteriorPrice: selectVehFromStore.InteriorPrice || 0,
            PaymentOption: selectedPaymentOptions?.PaymentOption || rdEnum.enumPaymentOptions.Cash,
            Email: pageState.email,
            FirstName: pageState.firstname,
            LastName: pageState.lastname,
            Mobile: pageState.mobile,
            ABN: pageState.abn || "",
            BusinessName: pageState.businessname || "",
            CustomerType: clientType || rdEnum.enumClientTypes.Private.Code,
            DeliveryAddress: getDeliveryLocationFullAddress(),
            DeliveryLocationState: getDeliveryLocationState() || "",
            DeliveryLocationSuburb: getDeliveryLocationSuburb() || "",
            DeliveryLocationPostcode: getDeliveryLocationPostcode() || "",
            DeliveryOption: selectedDeliveryFromStore?.SelectedDeliveryOption || rdEnum.enumDeliveryOptions.Pickup,
            EstDeliveryDate: vehicleFromStore?.SelectedInterior?.Available || "",
            PrivacyStatement: confirmed?.confirm,
            Marketing: confirmed?.agreement,
            InterestType: rdEnum.enumInterestType.WaitingList,
            Comment: pageState.comment,
            WebUniqueKey: null,
            Options: selectVehFromStore.Options || [],
            VehicleThumbnail: ""
        };

        clearMessage();
        setReservationSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await interestService.saveInterest(interestData, true);
        setReservationSubmittingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            //console.log(response.data);
            //let waitlistNo = encodeURIComponent(response.data.yanaWaitlistNumber);
            let url = `order-waitlisted/${response.data.webUniqueKey}`;
            navigate(url);
        }
        else {
            setPlaceReservationMessage(response.message);
        }
    }

    const handleBackToConfiguration = () => {
        if (submittingToYana != rdEnum.enumProcessingStatus.Processing) {
            toConfiguration();
            setIsShowPaymentPanel(false);
        }
    }

    const openPrivacy = () => {
        window.open('https://www.cadillacanz.com/au-en/privacy')
    }

    const toLearnMore = () => {
        window.open('https://sd.gm.com/')
    }

    const toThirdParty = () => {
        window.open('https://www.adyen.com/en_AU/')
    }

    const proceedPaymentJoinWaitlistButtonTemplate = () => {

        if (reserverationSubmittingStatus == rdEnum.enumProcessingStatus.Processing) {
            return (
                <Button type="button" className='progress-btn' disabled={true}>
                    <ProgressSpinner
                        style={{ width: '16px', height: '16px' }}
                        strokeWidth="5"
                        animationDuration=".8s"
                    />
                </Button>
            );
        }
        if (mainPageNumber == rdEnum.enumPage.JoinWaitList) {
            return (
                <Button className="generic-button stat-button-link" data-dtm="your info"
                    disabled={!confirmed?.confirm || false}
                    style={{ border: 'none', background: !confirmed?.confirm || false ? '#CCCCCC' : '#282828' }}
                    onClick={() => onClickWaitlist()}>
                    <h6>JOIN THE WAITLIST</h6>
                </Button>
            );
        }
        else {
            return (
                <Button className="generic-button stat-button-link" data-dtm="your info"
                    onClick={() => onClickPaymentButton(false)}>
                    <h6>PROCEED WITH PAYMENT</h6>
                </Button>
            );
        }

    }

    const handleCopy = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();  // Prevents copying
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();  // Prevents pasting
    };

    const handleCut = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();  // Prevents cutting
    };

    setTimeout(() => {
        let chargingCheckbox = document.getElementsByName("cb-ack-last");
        for (let i = 0; i < chargingCheckbox.length; i++) {
            chargingCheckbox[i].setAttribute('data-dtm', 'checkout');
            chargingCheckbox[i].classList.add('stat-checkbox');
        }

    }, 200);


    const doSetReservationCountdown = async () => {
        let response = await orderService.setReservationCountdown(order.Country);
        if (response.status) {
            setReservationCountDownSeconds(response.data.reservationCountDown);
            setReservationCountdownStage(response.data.reservationCountDownStage);
        }
    }


    useEffect(() => {
        if (reservationCountDownSeconds > 0) {
            const interval = setInterval(() => {
                //console.log(mainPageNumber);
                //console.log(isShowPaymentPanel);
                //console.log(confirmOrderSubmittingStatus);
                if (mainPageNumber == rdEnum.enumPage.CheckoutPayment && isShowPaymentPanel
                    && confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Init) {
                    let newCountdownSecond = reservationCountDownSeconds - 1;
                    if (newCountdownSecond == 0) {
                        if (reservationCountdownStage == 1) {
                            if (reservationExtendCount >= reservationExendMaxCount) {
                                setShowCountdownDialog(false);
                                let url = Uri.getFrontendOrigin();
                                window.location.href = url;
                            }
                            else {
                                //call get popup count down
                                setShowCountdownDialog(true);
                                doSetReservationCountdown();
                            }
                        }
                        else {
                            //extend popup is showning up, which means it's in extend period
                            //then refresh the page
                            setShowCountdownDialog(false);
                            let url = Uri.getFrontendOrigin();
                            window.location.href = url;
                        }
                    }

                    setReservationCountDownSeconds(newCountdownSecond);
                }

            }, 1000);

            return () => clearInterval(interval); // Clean up interval on component unmount
        }
    }, [reservationCountDownSeconds, isShowPaymentPanel]);

    const countDownTimeTemplate = (sec: any) => {
        const minutes = Math.floor(sec / 60);
        const seconds = sec % 60;
        return (<div><span className='time-text'>{minutes}</span> minutes <span className='time-text'>{seconds}</span> seconds</div>);
    };

    return (
        <React.Fragment>
            <div className={classNames({
                'account-details-container': true,
                "submitting-to-yana": submittingToYana == rdEnum.enumProcessingStatus.Processing,
            })} ref={watchlistRef}>
                {
                    mainPageNumber == 2
                        ?
                        <div className='back-to-configuration stat-button-link'
                            data-dtm={mainPageNumber == rdEnum.enumPage.JoinWaitList ? "join the waitlist" : "account details"}
                            onClick={() => handleBackToConfiguration()}>
                            <img alt='' src={`${getCMSAssets()}/checkout/arrow-gray.svg`} />
                            <h6>BACK TO CONFIGURATION</h6>
                        </div>
                        :
                        <></>
                }
                <div className='account-details'>
                    {mainPageNumber == rdEnum.enumPage.CheckoutPayment ? (<h2>ENTER ACCOUNT DETAILS</h2>) : (<h2>JOIN THE WAITLIST</h2>)}
                </div>

                {/* <div className='customer-type-selection'>
                    <div className='variant-btn-panel'>
                        <Button className={clientType == rdEnum.enumClientTypes.Private.Code ? 'variant-btn selected' : 'variant-btn'}
                            onClick={() => handleChangeType(rdEnum.enumClientTypes.Private.Code)}
                            data-pr-tooltip="Luxury"
                        >
                            {rdEnum.enumClientTypes.Private.Text}
                        </Button>
                        <Button className={clientType == rdEnum.enumClientTypes.Business.Code ? 'variant-btn selected' : 'variant-btn'}
                            onClick={() => handleChangeType(rdEnum.enumClientTypes.Business.Code)}
                            data-pr-tooltip="Sport"
                        >
                            {rdEnum.enumClientTypes.Business.Text}
                        </Button>
                    </div>
                </div> */}

                {
                    mainPageNumber == rdEnum.enumPage.JoinWaitList
                        ?
                        <div className=''>
                            <span>This particular LYRIQ configuration is  currently sold out.  Please enter your details below and to be notified when it is available to order.</span>
                        </div>
                        :
                        <></>
                }


                <div className='customer-info-container'>
                    {clientType == rdEnum.enumClientTypes.Business.Code && (
                        <React.Fragment>
                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="businessname" aria-describedby="businessname-help"
                                        {...{ autoComplete: "new" }}
                                        className={classNames({
                                            "stat-input-field": true,
                                            "p-invalid": pageState?.invalidBusinessname
                                        })}
                                        data-dtm={"your info"}
                                        placeholder=" "
                                        value={pageState.businessname}
                                        onChange={(e) => {
                                            setPageState({
                                                ...pageState,
                                                businessname: e.target.value,
                                                invalidBusinessname: isNil(e.target.value) || e.target.value == ""
                                            })
                                        }}
                                    />
                                    <label>Business Name *</label>
                                </span>
                            </div>
                            {pageState?.invalidBusinessname && (
                                <small id="businessname-help" className="p-error block">Require Valid Business Name</small>
                            )}
                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="abn" aria-describedby="abn-help"
                                        {...{ autoComplete: "new" }}
                                        keyfilter="int"
                                        data-dtm={"your info"}
                                        className={classNames({
                                            "stat-input-field": true,
                                            "p-invalid": pageState?.invalidABN
                                        })}
                                        placeholder=" "
                                        value={pageState.abn}
                                        maxLength={selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia ? 11 : 13}
                                        onChange={(e) => {
                                            setPageState({
                                                ...pageState,
                                                abn: e.target.value,
                                                invalidABN: isNil(e.target.value) || e.target.value == "",
                                                invalidABNLength: false,
                                            })
                                        }}
                                    />
                                    {
                                        selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                                            ?
                                            <label>ABN *</label>
                                            :
                                            <label>NZBN *</label>
                                    }
                                </span>
                            </div>
                            {pageState?.invalidABN && (
                                <small id="abn-help" className="p-error block">Please enter a valid&nbsp;
                                    {
                                        selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                                            ?
                                            <span>ABN</span>
                                            :
                                            <span>NZBN</span>
                                    }
                                </small>
                            )}
                            {pageState?.invalidABNLength && (
                                <small id="abn-help" className="p-error block">
                                    {
                                        selectedLocationFromStore?.Country?.code == rdEnum.enumCountryCode.Australia
                                            ?
                                            <span>ABN</span>
                                            :
                                            <span>NZBN</span>
                                    }
                                    &nbsp;length should be {selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia ? 11 : 13} digits.
                                </small>
                            )}
                        </React.Fragment>
                    )}
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <InputText id="firstname" aria-describedby="firstname-help"
                                {...{ autoComplete: "new" }}
                                className={classNames({
                                    "stat-input-field": true,
                                    "p-invalid": pageState?.invalidFirstname
                                })}
                                data-dtm="your info"
                                placeholder=" "
                                value={pageState.firstname}
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        firstname: e.target.value,
                                        invalidFirstname: isNil(e.target.value) || e.target.value == ""
                                    })
                                }}
                            />
                            <label>First Name *</label>
                        </span>
                    </div>
                    {pageState?.invalidFirstname && (
                        <small id="fristname-help" className="p-error block">Require Valid First Name</small>
                    )}
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <InputText id="lastname" aria-describedby="lastname-help"
                                {...{ autoComplete: "new" }}
                                className={classNames({
                                    "stat-input-field": true,
                                    "p-invalid": pageState?.invalidLastname
                                })}
                                data-dtm="your info"
                                placeholder=" "
                                value={pageState.lastname}
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        lastname: e.target.value,
                                        invalidLastname: isNil(e.target.value) || e.target.value == ""
                                    })
                                }}
                            />
                            <label>Last Name *</label>
                        </span>
                    </div>
                    {pageState?.invalidLastname && (
                        <small id="lastname-help" className="p-error block">Require Valid Last Name</small>
                    )}
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <InputText id="email" aria-describedby="email-help"
                                {...{ autoComplete: "new" }}
                                className={classNames({
                                    "stat-input-field": true,
                                    "p-invalid": pageState?.invalidEmail
                                })}
                                data-dtm="your info"
                                placeholder=" "
                                value={pageState.email}
                                onChange={(e) => {
                                    setPageState({
                                        ...pageState,
                                        email: e.target.value,
                                        invalidEmail: isNil(e.target.value) || e.target.value == "",
                                        emailMismatch: false,
                                        emailDuplicated: false,
                                    })
                                }}
                            />
                            <label>Email *</label>
                        </span>
                    </div>
                    {pageState?.invalidEmail && (
                        <small id="email-help" className="p-error block">Require Valid Email</small>
                    )}
                    {pageState?.emailDuplicated && (
                        <small id="email-help" className="p-error block">This email is already registered</small>
                    )}
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <InputText id="confirmemail" aria-describedby="confirmemail-help"
                                {...{ autoComplete: "new" }}
                                className={classNames({
                                    "stat-input-field": true,
                                    "p-invalid": pageState?.invalidConfirmEmail
                                })}
                                placeholder=" "
                                data-dtm="your info"
                                value={pageState.confirmEmail}
                                onChange={(e: any) => {
                                    setPageState({
                                        ...pageState,
                                        confirmEmail: e.target.value,
                                        invalidConfirmEmail: isNil(e.target.value) || e.target.value == "",
                                        emailMismatch: false,
                                        emailDuplicated: false,
                                    })
                                }}
                                onCopy={handleCopy}
                                onPaste={handlePaste}
                                onCut={handleCut}
                            />
                            <label>Confirm Email *</label>
                        </span>
                    </div>
                    {pageState?.invalidConfirmEmail && (
                        <small id="confirmemail-help" className="p-error block">Require Valid Email</small>
                    )}
                    {pageState?.emailMismatch && (
                        <small id="email-help" className="p-error block">The email address and confirm email address fields do not match</small>
                    )}
                    <div className='input-item'>
                        <span className="wrapper-box">
                            <InputText id="mobile" aria-describedby="mobile-help"
                                {...{ autoComplete: "new" }}
                                className={classNames({
                                    "stat-input-field": true,
                                    "p-invalid": pageState?.invalidMobile
                                })}
                                data-dtm="your info"
                                onKeyPress={(e: any) => isNumberKey(e)}
                                placeholder=" "
                                value={pageState.mobile}
                                onChange={(e) => {
                                    let mobile = e.target.value.slice(0, 10);
                                    setPageState({
                                        ...pageState,
                                        mobile: mobile,
                                        invalidMobile: isNil(mobile) || mobile == "" || mobile.length < 9 || mobile.length > 13
                                    })
                                }}
                                maxLength={10}
                            />
                            <label>Mobile *</label>
                        </span>
                    </div>
                    {pageState?.invalidMobile && (
                        <small id="mobile-help" className="p-error block">Require Valid Mobile</small>
                    )}
                    {/*<button onClick={() => {*/}
                    {/*    //customCardInstance.current.remove();*/}
                    {/*    //customCardInstance.current.unmount();*/}
                    {/*    customCardInstance.current.update();*/}
                    {/*    customCardInstance.current.remount();*/}
                    {/*}}>adyen test</button>*/}
                    {/* {mainPageNumber == rdEnum.enumPage.JoinWaitList ? (
                        <div className='input-item'>
                            <span className="wrapper-box">
                                <InputText id="comment" aria-describedby="comment-help"
                                    placeholder=" "
                                    value={pageState.comment}
                                    onChange={(e) => {
                                        setPageState({
                                            ...pageState,
                                            comment: e.target.value,
                                        })
                                    }}
                                />
                                <label>Comment</label>
                            </span>
                        </div>
                    ) : (<></>)} */}

                </div>

                {
                    mainPageNumber != rdEnum.enumPage.CheckoutPayment
                        ?
                        <div className='checkbox-checkout-container'>
                            <div className='checkbox-container'>
                                <div className='checkbox-item'>
                                    <div className='checkbox-content'>
                                        <Checkbox
                                            inputRef={joinConsentRef1}
                                            name="cb-ack-checkout"
                                            inputId="cb-ack-checkout"
                                            onChange={(e) => {
                                                //if (isNil(pageState)) return;
                                                setConfirmed({
                                                    ...confirmed,
                                                    confirm: e.checked,
                                                    invalidConfirm: !e.checked
                                                });
                                            }}
                                            checked={confirmed?.confirm}
                                        ></Checkbox>
                                        <label htmlFor="cb-ack-checkout" className="p-checkbox-label">
                                            By registering my interest to join a waitlist, I agree to Cadillac's Privacy Policy and Privacy Collection Statement.
                                    </label>
                                    </div>
                                    {confirmed?.invalidConfirm && (
                                        <small id="lastname-help" className="p-error block">Require the confirmation of valid agreement.</small>
                                    )}
                                </div>

                                <div className='checkbox-item'>
                                    <div className='checkbox-content'>
                                        <Checkbox
                                            inputRef={joinConsentRef2}
                                            name="cb-ack-checkout-optional"
                                            inputId="cb-ack-checkout-optional"
                                            onChange={(e) => {
                                                //if (isNil(pageState)) return;
                                                setConfirmed({
                                                    ...confirmed,
                                                    agreement: e.checked,
                                                })
                                            }}
                                            checked={confirmed?.agreement}
                                        ></Checkbox>
                                        <label htmlFor="cb-ack-checkout-optional" className="p-checkbox-label">
                                            I consent to receiving direct marketing materials from Cadillac.
                                    </label>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='privacy-container'>
                            <p>Cadillac does not sell, trade or disclose your contact information to independent third parties for their independent use
                            without your permission.</p>
                            <p>
                                By continuing your test drive booking, you have accepted the&nbsp;
                                <PrivacyPolicyLink props="Privacy Policy" />
                                &nbsp;and&nbsp;
                                <a style={{fontWeight: '400', color: '#3A3A3A'}}
                                        onClick={()=> window.open("https://www.cadillacanz.com/au-en/privacy", "_blank")}
                                >Test Drive Terms and Conditions</a>
                                &nbsp;of Cadillac.
                            </p>
                        </div> */}
                        </div>
                        :
                        <></>
                }

                <div className='checkout-btn-panel' style={{ display: isShowPaymentPanel ? 'none' : '' }}>
                    {proceedPaymentJoinWaitlistButtonTemplate()}
                </div>
                {!isNil(placeReservationMessage) && placeReservationMessage != "" && (
                    <div className="payment-error" dangerouslySetInnerHTML={{ __html: placeReservationMessage }}></div>
                )}

                {showOutOfStock && (<NoStorageReminder />)}


                <div className='payment-panel' style={{ height: isShowPaymentPanel ? 'auto' : '0' }}>
                    <div className={isShowPaymentPanel ? 'payment-panel-inner show' : 'payment-panel-inner'}>
                        {
                            mainPageNumber == rdEnum.enumPage.CheckoutPayment
                                ?
                                <div className='dollars-deposit'>
                                    <div className='price-line'>
                                        <span>Driveaway Price</span>
                                        <span>{currencyFormatWithBlank(driveawayFromStore?.DriveawayPrice || null)}</span>
                                    </div>
                                    <div className='price-line deposit-line'>
                                        <span>Due Today</span>
                                        <span>{Uri.currencyFormatWithZeroBlank(order.PaymentAmount)}</span>
                                    </div>
                                    <p><li>Fully refundable order deposit</li></p>
                                </div>
                                :
                                <></>
                        }
                        {mainPageNumber == rdEnum.enumPage.CheckoutPayment && isShowPaymentPanel
                            && confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Init && (
                            <div className="reservation-count-down-wrapper">
                                <div className="count-down-icon">
                                    <img className="icon" alt="" src={`${getCMSAssets()}/checkout/info-circle.svg`} />
                                </div>
                                <div className="count-down-text">
                                    <div>We are holding your LYRIQ design for:</div>
                                    {countDownTimeTemplate(reservationCountDownSeconds)}
                                </div>
                            </div>
                        )}

                        {/*Adyen payment*/}
                        {
                            mainPageNumber == rdEnum.enumPage.CheckoutPayment
                                ?
                                <>
                                    <div id="customCard-container" ref={paymentContainer} style={{ height: "auto" }}>
                                    </div>
                                    <div className='third-party-reminder'>
                                        Cadillac uses a third party payment provider called Adyen to facilitate payment of your deposit and Cadillac will not collect or store your card information. <a className='third-party-link stat-text-link' data-dtm="checkout" onClick={() => toThirdParty()}>Learn more</a> about how Adyen processes your transaction and manages your data.
                                    </div>

                                    {isShowAdyenRetryButton && (
                                        <div className="retry-payment-button-wrapper">
                                            <Button className="generic-button retry-btn"
                                                onClick={async () => {

                                                    let response = await orderService.getReservationStatus(order.OrderID);
                                                    if (response.status) {
                                                        if (response.data == "active") {

                                                            if (customCardInstance.current.isValid) {
                                                                setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
                                                            }
                                                            customCardInstance.current.update();
                                                            customCardInstance.current.remount();
                                                            setIsShowAdyenRetryButton(false);
                                                            setAdyenFailedMessage("");

                                                            setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Init);
                                                        }
                                                        else {
                                                            if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
                                                                setPlaceReservationMessage(rdEnum.enumErrorMessage.AUReservationError);
                                                                setIsShowPaymentPanel(false);
                                                            }
                                                            else {
                                                                setPlaceReservationMessage(rdEnum.enumErrorMessage.NZReservationError);
                                                                setIsShowPaymentPanel(false);
                                                            }
                                                        }
                                                    }


                                                }}
                                            >
                                                <span>Re-try Payment</span>
                                            </Button>
                                        </div>
                                    )}
                                </>
                                :
                                <></>
                        }

                        <div className='collecting-data-container'>
                            <div className='collecting-data-panel'>
                                <img alt="" src={`${getCMSAssets()}/inventory/info.svg`} />
                                <div className='vehicle-features-panel'>
                                    <p>
                                        This vehicle features safety diagnostic data collection to help monitor, detect, and mitigate issues.
                                    </p>
                                    <a className='learn-more stat-text-link' data-dtm="checkout" onClick={() => toLearnMore()}>Learn more</a>
                                </div>
                            </div>
                        </div>

                        <div className='customer-type-checkbox'>
                            <div className="section-agree-ack">
                                <Checkbox
                                    name="cb-ack-last"
                                    inputId="cb-ack-last"
                                    onChange={(e) =>
                                        setPageState({
                                            ...pageState,
                                            agreement: e.checked,
                                            invalidAgreement: !e.checked
                                        })
                                    }
                                    checked={pageState?.agreement}
                                ></Checkbox>
                                {/*{pageState?.invalidAgreement && (*/}
                                {/*    <small id="mobile-help" className="p-error block">Require the Agreement of Guideline</small>*/}
                                {/*)}*/}

                                <label className="p-checkbox-label" >

                                    <p style={{ cursor: 'auto' }}>
                                        {/* {
                                            mainPageNumber == rdEnum.enumPage.CheckoutPayment
                                                ?
                                                <>By placing this order, I agree to the Cadillac Order Agreement, </>
                                                :
                                                <>By placing this order, I agree to the Cadillac Order Agreement, </>
                                        }
                                        &nbsp;<TermsConditionsDialog props='' /> and
                                        &nbsp;<a className=''><PrivacyPolicyLink props='Privacy Policy' /></a>,
                                        &nbsp;<a className=''>Privacy Statement</a> */}
                                        By placing this order, I have read and agree to the Cadillac Order Terms & Conditions, Cadillac's Privacy Collection Statement and Account Guidelines.
                                    </p>
                                </label>
                            </div>






                            {/*{!isNil(paymentFailedMessage) && paymentFailedMessage != "" && (*/}
                            {/*    <div className="payment-error">{paymentFailedMessage}</div>*/}
                            {/*)}*/}

                            <div className='checkbox-bottom-content'>
                                <div className='checkbox-privacy-btn-box'>
                                    <TermsConditionsDialog props="special" />
                                    <PrivacyCollectionDialog props='checkout-payment' />
                                    <AccountGuidelines />
                                    {/* <div className='checkbox-privacy-btn' onClick={() => openPrivacy()}>
                                        <span>Cadillac's Privacy Policy</span>
                                        <img alt='' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                                    </div> */}
                                    {/* <div className='checkbox-privacy-btn'>
                                        <span>Cadillac's Privacy Collection Statement</span>
                                        <img alt='' src={`${getCMSAssets()}/checkout/right-black-arrow.svg`} />
                                    </div> */}
                                </div>
                                {selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia && (
                                    <React.Fragment>
                                        <h6>Motor Trader Licence:</h6>
                                        <h6>Victoria: LMCT-1770</h6>
                                        <h6>New South Wales: MD095488</h6>
                                        {lmctText != "" && (
                                            <React.Fragment>
                                                <br />
                                                <h6>Partner Trade Licences:</h6>
                                                <h6>{lmctText}</h6>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                {selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.NewZealand && (
                                    <React.Fragment>
                                        <h6>Motor Trade Licences:</h6>
                                        <h6>New Zealand: M388717</h6>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>

                        {!isNil(placeOrderMessage) && placeOrderMessage != "" && (
                            <div className="payment-error" dangerouslySetInnerHTML={{ __html: placeOrderMessage }}></div>
                        )}

                        {!isNil(adyenFailedMessage) && adyenFailedMessage != "" && (
                            <div className="text-error">{adyenFailedMessage}</div>
                        )}
                        <div className='checkout-btn-panel'>
                            {(confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Processing || submittingToYana == rdEnum.enumProcessingStatus.Processing) && (
                                <Button type="button" className='progress-btn' disabled={true}>
                                    <ProgressSpinner
                                        style={{ width: '16px', height: '16px' }}
                                        strokeWidth="5"
                                        animationDuration=".8s"
                                    />
                                </Button>

                            )}
                            {confirmOrderSubmittingStatus == rdEnum.enumProcessingStatus.Init && (
                                <Button
                                    className="generic-button btn-confirm-order stat-button-link"
                                    data-dtm="submit"
                                    disabled={!pageState.agreement}
                                    style={{ border: 'none', backgroundColor: !pageState.agreement ? '#CCCCCC' : '#282828' }}
                                    onClick={async () => {

                                        ////check if in 20 minutes since place reservation
                                        //if (!isNil(placeReservationTime) && moment(placeReservationTime).add(reservationExpiryMin, "minutes") < moment(new Date())) {
                                        //    if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
                                        //        setPlaceReservationMessage(rdEnum.enumErrorMessage.AUReservationError);
                                        //    }
                                        //    else {
                                        //        setPlaceReservationMessage(rdEnum.enumErrorMessage.NZReservationError);
                                        //    }
                                        //    setIsShowPaymentPanel(false);
                                        //    return;
                                        //}

                                        let response = await orderService.getReservationStatus(order.OrderID);
                                        if (response.status) {
                                            if (response.data == "active") {

                                                if (customCardInstance.current.isValid) {
                                                    setConfirmOrderSubmittingStatus(rdEnum.enumProcessingStatus.Processing);
                                                }
                                                customCardInstance.current.submit();
                                            }
                                            else {
                                                if (selectedDeliveryFromStore?.Country?.code == rdEnum.enumCountryCode.Australia) {
                                                    setPlaceReservationMessage(rdEnum.enumErrorMessage.AUReservationError);
                                                    setIsShowPaymentPanel(false);
                                                }
                                                else {
                                                    setPlaceReservationMessage(rdEnum.enumErrorMessage.NZReservationError);
                                                    setIsShowPaymentPanel(false);
                                                }
                                            }
                                        }


                                    }
                                    }
                                >
                                    <span style={{ color: !pageState.agreement ? '#707070' : '#fff' }}>PLACE ORDER</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog className='checkout-confirm-dialog'
                visible={showConfirmPopup}
                style={{ width: '40%' }}
                draggable={false} resizable={true}
                onHide={() => setShowConfirmPopup(false)}>
                <h5>{confirmPopupMessage}</h5>
                <div className="button-wrapper">
                    <Button
                        onClick={() => {
                            setShowConfirmPopup(false);
                            azureB2C.signIn();
                        }}
                    >
                        <span>Yes</span>
                    </Button>

                    <Button
                        onClick={() => {
                            setShowConfirmPopup(false);
                            //setPlaceOrderWithoutLogin(true);
                            onClickPaymentButton(true);
                        }}
                    >
                        <span>No</span>
                    </Button>
                </div>
            </Dialog>

            <Dialog className='checkout-countdown-dialog'
                showHeader={false}
                visible={showCountdownDialog}
                style={{ width: '25%' }}
                draggable={false} resizable={true}
                onHide={() => setShowCountdownDialog(false)}>
                <div className="checkout-countdown-content">
                    <h5>Reminder</h5>
                    <p>Your LYRIQ design is about to be released. If you need
                    more time, you can extend below, otherwise you
                    automatically will be redirected back to the Configuration homepage.</p>
                    <div>
                        <Button
                            className="button-black"
                            onClick={async () => {
                                let response = await orderService.extendReservationCountdown(order.Country);
                                if (response.status) {
                                    setReservationCountDownSeconds(response.data.reservationCountDown);
                                    setReservationCountdownStage(response.data.reservationCountDownStage);
                                    setShowCountdownDialog(false);
                                    setReservationExtendCount(response.data.reservationExtensionCount);
                                }
                                else {
                                    if (response.message == rdEnum.enumRDErrorCode.MaxExtensionLimit) {
                                        let url = Uri.getFrontendOrigin();
                                        window.location.href = url;
                                    }
                                }
                            }}
                        >
                            Extend
                        </Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default CheckoutWaitlist