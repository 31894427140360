import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Navbar, Accordion } from 'react-bootstrap';
import { RiHomeLine } from 'react-icons/ri';
import { Button } from 'primereact/button';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from 'helpers/authConfig';



import {
    getFrontendUrl,
    getImageFileNameByID2,
    IImagesState,
    getImageLinkByID2,
    getMobileImageLinkByID2,
    getCMSAssets,
    getFrontendUrlWithSlash
} from 'helpers/Uri';

import { clearSessionAction } from "stores/Session";
import { IAppState } from 'model/IAppState';
import { enumOrdersTabName } from "helpers/enum";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { AiOutlineSearch, AiOutlineLogin, AiOutlineUser } from "react-icons/ai";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { logout } from 'services/User';

//import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { setTimeout } from 'timers';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
//import { InteractionStatus } from "@azure/msal-browser";
//import { loginRequest, b2cPolicies } from 'helpers/authConfig';
import { useAzureB2C } from 'helpers/useAzureB2C';
import { useClearUserDataStore } from 'helpers/useClearUserDataStore';
import { setGlobalDropdown } from 'stores/GlobalDropdown';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';

export const GlobalDrawer = (props: any) => {
    const { isClickable } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const azureB2C = useAzureB2C();
    const clearUserData = useClearUserDataStore();

    const [drawerVisible, setDrawerVisible] = useState(false);

    //const gaEventTracker = useAnalyticsEventTracker('Menu');

    const loginSession = useSelector((state: IAppState) => {
        return state.session;
    });

    const scrollPosition = useSelector((state: IAppState) => {
        return state.scrollPosition;
    });

    //images
    const initImage: IImagesState = {
        imageLoaded: false,
        imageList: []
    }
    const [images, setImages] = useState<IImagesState>(initImage);

    const location = useLocation();

    const isMobile = useCheckMobileScreen();

    const [activeButton, setActiveButton] = useState('lyriq');

    const toLearnMore = () => {
        window.open('https://www.cadillacanz.com/au-en/lyriq-electric-suv', '_blank');
    }

    const toConfigure = (e: any) => {
        setDrawerVisible(false);
        navigate(`${getFrontendUrlWithSlash()}`, { state: { closePreLoader: false, isFromOtherPage: false } });
        if (location.pathname == '/') {
            e.preventDefault();
            window.location.reload();
        }
    }

    const toInvertory = () => {
        setDrawerVisible(false);
        navigate(`${getFrontendUrl()}/new-vehicle-inventory`);
        dispatch(setGlobalDropdown(false, false));
        dispatch(checkIsShowScrollBar(true))
    }
    
    const toTestDrive = () => {
        setDrawerVisible(false);
        navigate(`${getFrontendUrl()}/test-drive`);
        dispatch(setGlobalDropdown(false, false));
        dispatch(checkIsShowScrollBar(true))
    }

    const toLatestNews = () => {
        window.open('https://www.cadillacanz.com/au-en/news', '_blank');
    }

    const toWhyCadillac = () => {
        window.open('https://www.cadillacanz.com/au-en/why-cadillac', '_blank');
    } 

    const toBeIconic = () => {
        window.open('https://www.cadillacanz.com/au-en/be-iconic-series', '_blank');
    }
    
    const toEVLife = () => {
        window.open('https://www.cadillacanz.com/au-en/ev-life', '_blank');
    } 

    const toUpdates = () => {
        window.open('https://www.cadillacanz.com/au-en/register', '_blank');
    } 

    const toRegisterInterest = () => {
        window.open('https://www.cadillacanz.com/au-en/register', '_blank');
    }

    const toVisitUs = () => {
        window.open('https://www.cadillacanz.com/au-en/visit-a-showroom', '_blank');
    }
    
    const toFAQs = () => {
        window.open('https://www.cadillacanz.com/au-en/frequently-asked-questions', '_blank');
    }

    const toContactUs = () => {
        window.open('https://www.cadillacanz.com/au-en/contact-us', '_blank');
    } 

    const toService = () => {
        window.open('https://www.cadillacanz.com/au-en/ownership-service', '_blank');
        setDrawerVisible(false);
    }

    const toWarranty = () => {
        window.open('https://www.cadillacanz.com/au-en/ownership-warranty', '_blank');
        setDrawerVisible(false);
    }
    
    const toSupport = () => {
        window.open('https://www.cadillacanz.com/au-en/ownership-roadside-assistance', '_blank');
        setDrawerVisible(false);
    }

    const toMyOrders = () => {
        navigate(`${getFrontendUrl()}/my-orders`);
        setDrawerVisible(false);
    }

    const toProfiles = () => {
        navigate(`${getFrontendUrl()}/profile`);
        setDrawerVisible(false);
    }

    const toPrivacy = () => {
        navigate(`${getFrontendUrl()}/privacy`);
        setDrawerVisible(false);
    }

    const onLogOut = async () => {
        setDrawerVisible(false);
        clearUserData.clearData();
        azureB2C.signOut();
    }

    const onDrawerOpen = () => {
        setDrawerVisible(true);
        // dispatch(setGlobalDropdown(false, false));
    }

    const onDrawerHide = () => {
        setDrawerVisible(false);
    }

    const customHeader = (
        <div className='pop-up-dialog-header'>
            <div className='dialog-btn-panel'>
                <Button
                    // Replace 'pi-custom-close-icon' with the desired icon class
                    onClick={() => onDrawerHide()}
                    className="p-dialog-titlebar-close"
                >
                    <img alt="" src={`${getCMSAssets()}/users/cross.svg`} />
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <div className='global-drawer-container'>
                <a className={scrollPosition.positionStatus == 'main' ?
                    // "main-online-wrapper" 
                    "hamburger-icon stat-icon-link"
                    :
                    "hamburger-icon stat-icon-link"}
                    onClick={() => {
                        if (isClickable) {
                            onDrawerOpen()
                        }
                    }}
                    data-dtm="global nav"
                    data-dtm2="hamburger menu"
                >
                    {/* <img src={`${getCMSAssets()}/menu/div.gn-misc.svg`} className="icon-user-unsigned"></img> */}
                    <img src={`${getCMSAssets()}/menu/hamburger.svg`}  />
                    {/* {scrollPosition.positionStatus == 'main' ? <span>ACCOUNT</span> : <></>} */}
                    {/* <span>Account</span> */}
                </a>
            </div>

            <Dialog header={customHeader} position='top-left' style={{ position: 'absolute', top: '0', margin: '0', width: '340px' }} draggable={false} resizable={true} closable={false}
                visible={drawerVisible} dismissableMask={true}
                onHide={() => onDrawerHide()}
                className={`drawer-dialog ${!isNil(loginSession) ? 'logged-in-height' : ''}`}
            >
                <div className='drawer-section-panel'>
                    <div className='drawer-discover-title'>
                        LYRIQ
                    </div>
                    <div className='drawer-item-card' onClick={() => toLearnMore()}>
                        <div className='left-blue-bar'></div>
                        <span>Learn More</span>
                    </div>
                    <div className='drawer-item-card' onClick={(e) => toConfigure(e)}>
                        <div className='left-blue-bar'></div>
                        <span>Build & Price</span>
                    </div>
                    <div className='drawer-item-card' onClick={() => toTestDrive()}>
                        <div className='left-blue-bar'></div>
                        <span>Test Drive</span>
                    </div>
 
                    {/* <div className='drawer-item-card' onClick={() => toCharge()}>
                        <div className='left-blue-bar'></div>
                        <span>Charge</span>
                    </div>
                    <div className='drawer-item-card' onClick={() => toOffering()}>
                        <div className='left-blue-bar'></div>
                        <span>Business offering</span>
                    </div>
                    <div className='drawer-item-card' onClick={() => toHelp()}>
                        <div className='left-blue-bar'></div>
                        <span>Help & support</span>
                    </div> */}
                </div>

                <div className='drawer-section-panel'>
                    <div className='drawer-discover-title'>
                        DISCOVER CADILLAC 
                    </div>
                    <div className='drawer-discover-card' onClick={() => toLatestNews()}>
                        <div className='left-blue-bar'></div>
                        <span>Cadillac News</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toWhyCadillac()}>
                        <div className='left-blue-bar'></div>
                        <span>Why Cadillac</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toBeIconic()}>
                        <div className='left-blue-bar'></div>
                        <span>Be Iconic Series</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toEVLife()}>
                        <div className='left-blue-bar'></div>
                        <span>Your EV Life</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toUpdates()}>
                        <div className='left-blue-bar'></div>
                        <span>Get Updates</span>
                    </div>
                    {/* <div className='drawer-discover-card' onClick={() => toRegisterInterest()}>
                        <div className='left-blue-bar'></div>
                        <span>Register Interest</span>
                    </div> */}
                    <div className='drawer-discover-card' onClick={() => toVisitUs()}>
                        <div className='left-blue-bar'></div>
                        <span>Visit Us</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toFAQs()}>
                        <div className='left-blue-bar'></div>
                        <span>FAQs</span>
                    </div>
                    <div className='drawer-discover-card' onClick={() => toContactUs()}>
                        <div className='left-blue-bar'></div>
                        <span>Contact Us</span>
                    </div>
                </div>

                <div className='drawer-section-panel'>
                    <div className='drawer-ownership-title'>
                        OWNERSHIP
                    </div>
                    <div className='drawer-ownership-card' onClick={() => toService()}>
                        <div className='left-blue-bar'></div>
                        <span>Service</span>
                    </div>
                    <div className='drawer-ownership-card' onClick={() => toWarranty()}>
                        <div className='left-blue-bar'></div>
                        <span>Warranty</span>
                    </div>
                    <div className='drawer-ownership-card' onClick={() => toSupport()}>
                        <div className='left-blue-bar'></div>
                        <span>Roadside Assistance</span>
                    </div>
                </div>
            </Dialog>
        </>
    );
};


