import { getBackendPath } from 'helpers/Uri';
import * as interestModel from 'model/IInterest';
import * as rdHelper from 'helpers/Uri';

export const saveInterest = async (
    interest: interestModel.IInterest,
    ifCallYanaAPI: boolean,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            interest: interest,
            ifCallYanaAPI: ifCallYanaAPI
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/interest/save-interest`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getInterest = async (
    linkKey: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ linkKey: linkKey}),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/interest/get-interest`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


export const getWaitlist = async (
    webkey: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            linkKey: webkey,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/interest/get-waitlist-data`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};