import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import ProvideDetails from './ProvideDetails';
import { getCMSAssets, getFrontendUrl } from 'helpers/Uri';
import ArrangePayment from './ArrangePayment';
import ScheduleDelivery from './ScheduleDelivery';
import ReviewContract from './ReviewContract';
import FinanceApplication from 'pages/Global/orders/purchasingProgress/FinanceApplication';
import * as pipelineModel from 'model/IPipeLine';
import * as rdEnum from 'helpers/enum';
import RetryPayment from './RetryPayment';
import ViewAppointment from 'pages/Global/orders/purchasingProgress/ViewAppointment';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import * as docModel from 'model/IDocuments';
import * as docService from 'services/Document';

import { enumClientTypes, enumDocumentType } from "helpers/enum";

type AccordionOpenType = {
    [key: string]: boolean;
};

const Progress = (props: any) => {

    const orderCustomerDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderCustomerDetails;
    });
    const orderDocumentsFromStore = useSelector((state: IAppState) => {
        return state.orderDocuments;
    });

    const { progressList, isSuccessWaitListedPage } = props;

    const lastPathname = window.location.pathname;


    const [lastStepCollapseHeight, setLastStepCollapseHeight] = useState(0);
    const [activeStep, setActiveStep] = useState<string>("");



    const emptyRef = useRef<any>();
    const lastStepCollapseHeightRef = useRef<any>();


    const [accordionOpen, setAccordionOpen] = useState<AccordionOpenType>({});

    useEffect(() => {
        if (progressList) {
            const accordionOpenInit: AccordionOpenType = {};
            progressList.forEach((item: any, index: any) => {
                accordionOpenInit[`btn${item.step}`] = item.pipelineBarStepStatus == 0;

                if (item.pipelineBarStepStatus == 0) {
                    //console.log("set value")
                    setActiveStep(item.step.toString());
                }
            });
            setAccordionOpen(accordionOpenInit);
        }

    }, [progressList]);


    const PurchasingStepsToggle = ({ children, eventKey, callback, isOpen, pipelineStatus }: any) => {
        //useEffect(() => {
        //    if (accordionOpen.btn6 && lastStepCollapseHeightRef.current) {
        //        const height = lastStepCollapseHeightRef.current?.offsetHeight;
        //        setLastStepCollapseHeight(height);
        //    }
        //}, [accordionOpen.btn6]);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => {
                if (callback) {
                    callback(eventKey); // Invoke the callback if provided
                }

                //console.log(eventKey);
                //set other AccordionOpenType as false
                let updatedAccordionOpen: AccordionOpenType = {};
                for (const key of Object.keys(accordionOpen)) {
                    if (key == `btn${eventKey}`) {
                        let collapseOpenState = !accordionOpen[`${key}` as keyof AccordionOpenType];
                        //if (collapseOpenState) {
                        //    setActiveEventKey(eventKey);
                        //}
                        updatedAccordionOpen[`${key}`] = collapseOpenState;
                    }
                    else {
                        updatedAccordionOpen[`${key}`] = false;
                    }
                }
                setAccordionOpen(updatedAccordionOpen);

                //setAccordionOpen({
                //    ...accordionOpen,
                //    [`btn${eventKey}` as keyof AccordionOpenType]: !accordionOpen[`btn${eventKey}` as keyof AccordionOpenType]
                //});
            }
        );

        //disabled={isNil(pipelineStatus)}
        return (
            <button

                type="button"
                onClick={decoratedOnClick}
                className='purchasing-steps-accordion-header-btn'
                style={{ cursor: 'pointer' }}
                aria-expanded={accordionOpen[`btn${eventKey}` as keyof AccordionOpenType]}
            >
                <div className='img-panel'>
                    {
                        pipelineStatus == 1 ?
                            <img alt="" src={`${getCMSAssets()}/users/tick-circle.svg`} />
                            :
                            pipelineStatus == 0 ?
                                <div className='current-step-circle'>
                                    <div className='inner-circle'>
                                        <div className='central-dot' />
                                    </div>
                                </div>
                                :
                                <div className='unfinished-dot' />
                    }
                </div>
                <span>{children}</span>
            </button>
        );
    }

    // const onDownloadContract = async () => {
    //     let findDocs = orderDocumentsFromStore.filter((m: docModel.IDocumentItem) => m.DocumentType == enumDocumentType.Contract);

    //     if (!isNil(findDocs) && findDocs.length > 0) {
    //         let requestFrontData: docModel.IDocument = {
    //             YanaCustomerID: orderCustomerDetailsFromStore?.yanaCustomerID || "",
    //             YanaCustomerNo: orderCustomerDetailsFromStore?.yanaCustomerNo || "",
    //             YanaOrderID: orderCustomerDetailsFromStore?.yanaOrderID || "",
    //             YanaOrderNo: orderCustomerDetailsFromStore?.yanaOrderNo || "",
    //             Country: orderCustomerDetailsFromStore?.country || "",
    //             Documents: [findDocs[findDocs.length - 1]]
    //         };
    //         let response = await docService.getSpecificDocuments(requestFrontData);
    //         if (response.status) {
    //             let downloadUrl = response.data[0].downloadUrl;
    //             window.open(`${response.data[0].downloadUrl}`);
    //         }
    //     }
    // }

    const onDownloadContract = async () => {
        await onDownload();
    };

    const onDownload = async () => {
        let findDocs = orderDocumentsFromStore.filter((m: docModel.IDocumentItem) => m.DocumentType == enumDocumentType.Contract);

        if (!isNil(findDocs) && findDocs.length > 0) {
            let requestFrontData: docModel.IDocument = {
                YanaCustomerID: orderCustomerDetailsFromStore?.yanaCustomerID || "",
                YanaCustomerNo: orderCustomerDetailsFromStore?.yanaCustomerNo || "",
                YanaOrderID: orderCustomerDetailsFromStore?.yanaOrderID || "",
                YanaOrderNo: orderCustomerDetailsFromStore?.yanaOrderNo || "",
                Country: orderCustomerDetailsFromStore?.country || "",
                Documents: [findDocs[findDocs.length - 1]]
            };
            let response = await docService.getSpecificDocuments(requestFrontData);
            if (response.status) {
                const link = document.createElement('a');
                link.href = response.data[0].downloadUrl;
                link.click();
            }
        }
    };

    const buttonTemplate = (stepItem: pipelineModel.IPipeLineItem) => {

        if (stepItem.buttonID == rdEnum.enumPipelineButtonID.RetryPayment) {
            return (<RetryPayment buttonText={stepItem.buttonText} />);
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.ProvideDetail) {
            if (lastPathname !== `${getFrontendUrl()}/order-success`) {
                return (<ProvideDetails buttonText={stepItem.buttonText} />);
            }
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.StartYourApplication) {
            return (<FinanceApplication />);  //todo:
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.DownloadContract) {
            return (
                <div className='variant-btn-panel'>
                    <Button className='variant-btn'
                        onClick={() => onDownloadContract()}
                    >
                        {stepItem.buttonText}
                    </Button>
                </div>
            )
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.ReviewSignYourContract) {
            return (<ReviewContract buttonText={stepItem.buttonText} />);
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.SchedulePickup) {
            return (<ScheduleDelivery isReschedule={false} buttonText={stepItem.buttonText} />);
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.ArranagePayment) {
            return (<ArrangePayment buttonText={stepItem.buttonText} />);
        }
        else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.ViewAppointment) {
            return (<ViewAppointment buttonText={stepItem.buttonText} />);
        }
        //else if (stepItem.buttonID == rdEnum.enumPipelineButtonID.ViewDelivery) {
        //    return (<ScheduleDelivery isReschedule={false} buttonText={stepItem.buttonText} />);
        //}

        return (<></>);
    }



    return (
        <div>
            {/*{ console.log(progressList)}*/}
            <Accordion className='purchasing-steps-accordion'
                style={{
                    marginLeft: '0', border: 'none',
                    minWidth: 'auto'
                }}
            >
                {
                    progressList && progressList?.map((stepItem: pipelineModel.IPipeLineItem, index: any) => {
                        return (
                            <Accordion
                                activeKey={activeStep}
                                onSelect={(key:any) => setActiveStep(key)}>
                                <Card key={stepItem.step.toString() + index}>
                                    {
                                        index != progressList?.length - 1
                                            ?
                                            <div className='steps-line'></div>
                                            :
                                            <></>
                                    }
                                    <Card.Header>
                                        <PurchasingStepsToggle
                                            eventKey={stepItem.step.toString()}
                                            pipelineStatus={stepItem.pipelineBarStepStatus}>
                                            {!isNil(stepItem.date) && (<p>{stepItem.date}</p>)}
                                            <h6 className={
                                                stepItem.pipelineBarStepStatus == '0' ? 'current-step' :
                                                    stepItem.pipelineBarStepStatus == '1' ? 'finished-step' :
                                                        stepItem.pipelineBarStepStatus == '2' ? 'unfinished-step' : ''}>
                                                {stepItem.name}
                                            </h6>
                                        </PurchasingStepsToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={stepItem.step.toString()}>
                                        <Card.Body>
                                            <span className='step-desc'>{stepItem.desc}</span>
                                            {buttonTemplate(stepItem)}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )
                    })

                }
            </Accordion>
        </div>
    )
}

export default Progress