import * as scheduleDeliveryModel from 'model/IOrderScheduleDelivery';
export const getInitScheduleDelivery = (): scheduleDeliveryModel.IOrderScheduleDelivery => {
    let init: scheduleDeliveryModel.IOrderScheduleDelivery = {
        YanaOrderID: null,
        YanaOrderNo: null,
        YanaCustomerID: null,
        YanaCustomerNo: null,
        DeliveryLocationState: null,
        DeliveryLocationSuburb: null,
        DeliveryLocationPostcode: null,
        DeliveryLocationAddress: null,
        DeliveryOption: null,
        DeliveryDate: undefined,
        DeliveryFormatDate: null,
        DeliveryTime: null,
        Country: null,
        PickupLocationName: null,
        Available: null
    };

    return init;
}