import ImageComparison from 'pages/Main/variant/ImageComparison'
import { getCMSAssets } from 'helpers/Uri'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import VariantComparison from './VariantComparison'
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen'

const ComparisonDialog = (props: any) => {

    const {pageName} = props

    const [openLearnMoreDialog, setOpenLearnMoreDialog] = useState(false);
 
    const onVisible = () => {
        setOpenLearnMoreDialog(true);
    }

    const onHide = () => {
        setOpenLearnMoreDialog(false);
    }

    const isMobile = useCheckMobileScreen();

    setTimeout(() => {
        let accordionButton = document.getElementsByClassName("accordion-button");
        for (let i = 0; i < accordionButton.length; i++) {
            accordionButton[i].setAttribute('data-dtm', 'modal:lyriq specifications');
            accordionButton[i].classList.add('stat-expand-icon');
        }
    }, 200);

    return (
        <div className='comparison-dialog-container'>
            {
                pageName == 'Configure'
                ?
                <span>
                        <a className='compare-variants stat-text-link'
                            data-dtm="variant"
                            onClick={() => onVisible()}>
                        <img alt="" src={`${getCMSAssets()}/main/text-icon-2.svg`} />
                        <span>Specifications</span>
                    </a>
                </span>
                :
                <div className='specifications-box' onClick={() => onVisible()}>
                    <span>Specifications</span>
                    <img alt="" src={`${getCMSAssets()}/inventory/info.svg`} />
                </div>
            }
            

            <Dialog className='comparison-dialog' visible={openLearnMoreDialog} style={{ width: '85%' }} onHide={() => onHide()} draggable={false} resizable={true}>
                {/* <div className='comaprison-scroll-container'> */}
                    <div className='comparison-container'>
                        <div className='comparison-title-box'>
                            <h5>SPECIFICATIONS</h5>
                            <div className='comparison-title'>
                                <h1>LYRIQ</h1>
                                <p>Model Year 2025</p>
                            </div>
                        </div>
                    </div>
                    <div className='standard-equipment-container'>
                        <div >
                            <Accordion className='equipments-accordion' defaultActiveKey={['0', '1']} alwaysOpen >
                                <Accordion.Item eventKey="0">
                                    <div className='accordion-title'>Variant differences</div>
                                    <Accordion.Body>
                                        <p className='differences-title'>Learn what makes LYRIQ Luxury and Sport variants uniquely iconic</p>
                                        <VariantComparison />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    {/* <Accordion.Header>Standard features</Accordion.Header> */}
                                    <div className='accordion-title'>Standard features</div>
                                    <Accordion.Body>
                                        <p className='differences-title'>Explore the impressive features that come standard with LYRIQ Luxury and Sport variants</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Infotainment</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='infotainment-section'>
                                            <div className='infotainment-left-panel'>
                                                <p>Curved 33" diagonal advanced colour LED display<sup>1</sup></p>
                                                <p>Wireless Apple CarPlay® phone projection<sup>2</sup></p>
                                                <p>AKG 19-speaker premium audio system with noise cancellation and built in front-row head rest speakers</p>
                                                <p>Wireless phone charging<sup>5</sup></p>
                                            </div>
                                            <div className='infotainment-right-panel'>
                                                <p>Rotary controller with jog functionality</p>
                                                <p>Wireless Android Auto™ phone projection<sup>3</sup></p>
                                                <p>USB ports<sup>4</sup>, type-C full-function (3 ports) and charge-only (2 ports)</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Safety & Driver Assistance<sup>7</sup></Accordion.Header>
                                    <Accordion.Body>
                                        <div className='safety-driver-section'>
                                            <div className='safety-driver-left-panel'>
                                                <p>Airbags<sup>6</sup>, Dual-stage frontal, seat-mounted side-impact for driver and front passenger; knee for driver and front passenger; head-curtain for all outboard seating positions with rollover protection</p>
                                                <p>Adaptive Cruise Control</p>
                                                <p>Buckle to Drive</p>
                                                <p>Front and Rear Park Assist</p>
                                                <p>Front Pedestrian and Cyclist Braking</p>
                                                <p>HD Surround Vision</p>
                                                <p>Lane Keep Assist with Lane Departure Warning</p>
                                                <p>Rear Camera Mirror (with washer)</p>
                                                <p>Side Bicyclist Alert</p>
                                                <p>Second Row Seatbelt Indicator</p>
                                            </div>
                                            <div className='safety-driver-right-panel'>
                                                <p>Adaptive Headlight System</p>
                                                <p>Blind Zone Steering Assist</p>
                                                <p>Enhanced Automatic Parking Assist</p>
                                                <p>Forward Collision Alert</p>
                                                <p>HD Rear Vision Camera</p>
                                                <p>Intersection Automatic Emergency Braking</p>
                                                <p>LED Reflective Windshield Collision Alert</p>
                                                <p>Rear Pedestrian Alert</p>
                                                <p>Theft Deterrent Alarm System</p>
                                                <p>Tyre Pressure Monitoring System</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Interior & Comfort</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='interior-section'>
                                            <div className='interior-left-panel'>
                                                <p>Heated Steering Wheel</p>
                                                <p>Remote Keyless Entry</p>
                                                <p>126-colour interior ambient lighting with dual-zone customisation</p>
                                                <p>4-way power lumbar adjustment for driver and front passenger seats</p>
                                                <p>Heated and ventilated driver and front passenger seats</p>
                                                <p>Seatback recline position second row seating</p>
                                                <p>Illuminated front door sill plates</p>
                                                <p>Driver and front passenger visors with illuminated vanity mirrors</p>
                                                <p>Power windows with Express-up/down on all</p>
                                                <p>Tri-zone climate control with dedicated digital rear control panel</p>
                                            </div>
                                            <div className='interior-right-panel'>
                                                <p>Push Button Start</p>
                                                <p>Power tilting and telescoping steering column</p>
                                                <p>8-way power adjustable driver and front passenger seats</p>
                                                <p>Power massage driver and front passenger seats</p>
                                                <p>Heated outboard second row passenger seats</p>
                                                <p>60/40 one-touch, flat-folding second row</p>
                                                <p>Power sliding dual panel sunroof with power sunshade</p>
                                                <p>Front floor mats</p>
                                                <p>Rear cargo area cover</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Exterior Design</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='exterior-section'>
                                            <div className='exterior-left-panel'>
                                                <p>Daytime Running Lamps (DRLs) and Adaptive LED Headlamp System housed in advanced vertical slimline design</p>
                                                <p>Laminated front side glass with solar glazing</p>
                                                <p>Automatic headlamp leveling system</p>
                                                <p>Cornering lamps</p>
                                                <p>Wheel lugs, locking</p>
                                                <p>LED tail lamps and rear turn signal indicators</p>
                                                <p>Self-sealing tyres (275/45R21)</p>
                                            </div>
                                            <div className='exterior-right-panel'>
                                                <p>Front and rear lighting choreography for approach and walk-away</p>
                                                <p>Rear privacy glass</p>
                                                <p>Innovative 3D Etched Black Crystal Shield (front grille)</p>
                                                <p>Hands-free liftgate (open and close) with programmable memory height and Cadillac crest projection light</p>
                                                <p>Exterior mirrors with heating, power adjustability, power-folding and driver-side auto dimming and integrated turn signal indicators</p>
                                                <p>Acoustic laminated windshield with heated glass, in wiper part position</p>
                                                <p>Obsidian/dark (Sport) or Luxury/silver (Luxury) chrome accents</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Electric Performance</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='electric-drive-section'>
                                            <div className='electric-drive-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>System</h6>
                                                    <p>Dual-motor All Wheel Drive (AWD)</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Motor</h6>
                                                    <p>Permanent magnet, bar wound</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Near-instant Torque</h6>
                                                    <p>610 nM<sup>8</sup></p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Max Speed</h6>
                                                    <p>210 km/h<sup>8</sup></p>
                                                </div>
                                            </div>
                                            <div className='electric-drive-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Braking Efficiency</h6>
                                                    <p>Regenerative braking, featuring Regen on Demand® and One Pedal Driving® </p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Power</h6>
                                                    <p>388 kW<sup>8</sup></p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Final Drive Ratio</h6>
                                                    <p>2109.87:1  (front)</p>
                                                    <p>10.49:1 (rear km/h)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>0-100 km/h</h6>
                                                    <p>5.3 seconds<sup>8</sup></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Battery & Charging</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='battery-charging-section'>
                                            <div className='battery-charging-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Battery Rated Energy</h6>
                                                    <p>102 kWh</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Type</h6>
                                                    <p>Rechargeable energy storage system comprising multiple linked modules</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Range & Efficiency</h6>
                                                    <p>530 kilometers in combined electric range (WLTP)<sup>9</sup></p>
                                                </div>
                                               
                                                <div className='parameter-item last-line'>
                                                    <h6>190 kW DC Fast Charge</h6>
                                                    <p>Charge up to 80% in 30 minutes<sup>10 11</sup></p>
                                                </div>
                                            </div>
                                            <div className='battery-charging-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Battery Chemistry</h6>
                                                    <p>Lithium-ion NCMA cathode, blended graphite anode</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Combined Electric Energy Consumption</h6>
                                                    <p>22.5 kWh / 100 km</p>
                                                </div>
                                               
                                                {/* <div className='parameter-item'>
                                                    <h6>X.X kW Included Home Charging Cable</h6>
                                                    <p>Up to X kilometers range per hour of charge time<sup>10</sup></p>
                                                </div> */}
                                                <div className='parameter-item'>
                                                    <h6>7.4 kW Single Phase AC Charger</h6>
                                                    <p>Up to 43 kilometers of range per hour of charge time<sup>10 11</sup></p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>22 kW Three Phase AC Charger</h6>
                                                    <p>Up to 94 kilometers of range per hour of charge time<sup>10 11</sup></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                              
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Exterior dimensions</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='exterior-dimensions-section'>
                                            <div className='exterior-dimensions-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Wheelbase</h6>
                                                    <p>3094 mm</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Overall Length</h6>
                                                    <p>4996 mm (front)</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Ground Clearance</h6>
                                                    <p>117 mm</p>
                                                </div>
                                            </div>
                                            <div className='exterior-dimensions-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Overall Width</h6>
                                                    <p>2207 mm (with mirrors)</p>
                                                </div>
                                               
                                                <div className='parameter-item'>
                                                    <h6>Overall Height</h6>
                                                    <p>1623 mm</p>
                                                </div>
                                            </div>
                                            {
                                                !isMobile
                                                ?
                                                <img alt="" className='image' src={`${getCMSAssets()}/exterior/exterior-dimensions.svg`} />
                                                :
                                                <img alt="" className='image' src={`${getCMSAssets()}/exterior/m-exterior-dimensions.svg`} />
                                            }
                                            
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>Interior dimensions</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='interior-dimensions-section'>
                                            <div className='interior-dimensions-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Headroom</h6>
                                                    <p>980 mm (front)</p>
                                                    <p>957 mm (rear)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Shoulder Room</h6>
                                                    <p>1497 mm (front)</p>
                                                    <p>1488 mm (rear)</p>
                                                </div>
                                            </div>
                                            <div className='interior-dimensions-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Legroom</h6>
                                                    <p>1124 mm (front - max)</p>
                                                    <p>1006 mm (rear)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Hip Room</h6>
                                                    <p>1436 mm (front)</p>
                                                    <p>1372 mm (rear)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>Chassis & Suspension</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='chassis-suspension-section'>
                                            <div className='chassis-suspension-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Front & Rear Suspension</h6>
                                                    <p>5-link independent suspension with Passive-Plus Premium Dampers</p>
                                                </div>
                                               
                                                <div className='parameter-item'>
                                                    <h6>Brake Type</h6>
                                                    <p>Brembo® performance front brakes</p>
                                                </div>
                                                <div className='parameter-item '>
                                                    <h6>Turning Circle</h6>
                                                    <p>12.1 m (curb-to-curb)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Driving Modes</h6>
                                                    <p>Tour, Snow/Ice, Sport & My Mode (selectable on demand)</p>
                                                </div>
                                            </div>
                                            <div className='chassis-suspension-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Vehicle Control</h6>
                                                    <p>Traction control and Automatic Vehicle Hold</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Brake Rotor Size</h6>
                                                    <p style={{margin: '0'}}>390 mm (front) FRT Fixed Caliper Disc</p>
                                                    <p>345 mm (rear) RR Sliding Caliper Disc</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Steering</h6>
                                                    <p>Continuously Variable Electric Power Steering (EPS)</p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>Weights & capacities<sup>12</sup></Accordion.Header>
                                    <Accordion.Body>
                                        <div className='weights-capacities-section'>
                                            <div className='weights-capacities-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Base Curb Weight - Minimum</h6>
                                                    <p>2774 kg</p>
                                                </div>
                                                <div className='parameter-item'>
                                                    <h6>Gross Vehicle Weight</h6>
                                                    <p>3200 kg</p>
                                                </div>
                                               
                                            </div>
                                            <div className='weights-capacities-right-panel'>
                                               
                                                <div className='parameter-item'>
                                                    <h6>Passenger Volume</h6>
                                                    {/* <h6>Passenger Volume (L)</h6> */}
                                                    <p>793 L (behind rear seat)</p>
                                                    <p>1722 L (rear seatbacks folded)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Passenger Volume</h6>
                                                    <p>2976 L</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>Warranty & Service</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='warranty-section'>
                                            <div className='warranty-left-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Vehicle</h6>
                                                    <p>5 Years, unlimited kilometers<sup>13</sup></p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Service Intervals</h6>
                                                    <p>12 months / 12,000 km (whichever comes first)</p>
                                                    <p>5 years, standard service included<sup>14</sup></p>
                                                </div>
                                            </div>
                                            <div className='warranty-right-panel'>
                                                <div className='parameter-item'>
                                                    <h6>Battery</h6>
                                                    <p>8 Years, 160,000 kilometer (whichever comes first)</p>
                                                </div>
                                                <div className='parameter-item last-line'>
                                                    <h6>Premium Roadside Assistance</h6>
                                                    <p>5 years, complimentary premium roadside assistance<sup>15</sup></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <div className='disclaimer-container'>
                                <p>Vehicles shown throughout this experience are simulated local production vehicles intended for illustrative purposes. Cadillac has taken reasonable steps to ensure your digital configuration replicates the vehicle you receive in real life. Colours and other features may appear different in real life.</p>

                                <p><sup>1</sup> Full functionality requires compatible Bluetooth® and smartphone, and USB connectivity for some devices.</p>

                                <p><sup>2</sup> Vehicle user interface is a product of Apple® and its terms of use and privacy policies apply. Requires compatible iPhone® and data plan rates apply. Apple CarPlay, Siri, iPhone and Apple Music are trademarks of Apple, Inc., registered in the U.S. and other countries. Use of Apple products requires acceptance of any applicable terms and conditions. Data plan rates apply.</p>

                                <p><sup>3</sup> Vehicle user interface is a product of Google and its terms of use and privacy policies apply. To use Android Auto on your car display, you’ll need an Android phone running Android 6 or higher, an active data plan, and the Android Auto app. Google, Android and Android Auto are trademarks of Google LLC. Use of Google products requires acceptance of any applicable terms and conditions. Data plan rates apply.</p>

                                <p><sup>4</sup> Not compatible with all devices.</p>

                                <p><sup>5</sup> The system wirelessly charges one compatible mobile device. Some phones have built-in wireless charging technology and others require a special adaptor/back cover. To check for phone or other device compatibility consult your carrier.</p>

                                <p><sup>6</sup> Always use seat belts and child restraints. Children are safer when properly secured in a rear seat in the appropriate child restraint. See your vehicle Owner’s Manual for more information.</p>

                                <p><sup>7</sup> Safety or driver assistance features are no substitute for the driver’s responsibility to operate the vehicle in a safe manner. The driver should remain attentive to traffic, surroundings and road conditions at all times. Visibility, weather and road conditions may affect feature performance. Read the vehicle’s owner’s manual for more important safety limitations and information.</p>

                                <p><sup>8</sup> Figures based on GM testing in the United States in controlled environments. Actual figures may vary and are subject to environmental conditions.</p>

                                <p><sup>9</sup> The range of electric vehicles is determined using a uniform measurement procedure (WLTP). These results are not guaranteed. Range and energy consumption may vary under real-world conditions depending on driving style and various external factors. It is therefore not always possible to achieve the range according to WLTP.</p>

                                <p><sup>10</sup> Actual charge times will vary based on battery condition, output of charger, vehicle settings and outside temperature. See the vehicle's Owner’s Manual for additional limitations.</p>

                                <p><sup>11</sup> Home charging requires professionally installed, dedicated charge station.</p>

                                <p><sup>12</sup> Cargo and load capacity restricted by weight and distribution. Subject to change pending final vehicle certification for Australia and New Zealand.</p>

                                {/* <p><sup>13</sup> Placeholder for warranty disclaimer.</p>

                                <p><sup>14</sup> Placeholder for standard service disclaimer.</p>

                                <p><sup>15</sup> Placeholder for roadside assistance disclaimer.</p> */}

                                <p><sup>13</sup> <sup>14</sup> <sup>15</sup>View important information and terms & conditions relating to service, roadside assistance, and warranties in the ownership section of the Cadillac Australia & New Zealand website.</p>
                            </div>
                        </div>
                    </div>
                {/* </div>     */}
            </Dialog>
        </div>
    )
}

export default ComparisonDialog