import { useEffect } from 'react';

// Define types for `_satellite` if not already defined
//declare global {
//    interface Window {
//        _satellite: {
//            track: (event: string, data?: Record<string, any>) => void;
//            getVar: (name: string) => any;
//            [key: string]: any; // Include any other methods you may use
//        };
//    }
//}

export const useSatellite = () => {
    const trackEvent = (event: string) => {
        if (window._satellite) {
            window._satellite.track(event);
        } else {
            console.warn('Satellite is not defined.');
        }
    };

    const getVariable = (name: string) => {
        if (window._satellite) {
            return window._satellite.getVar(name);
        }
        return undefined;
    };

    return { trackEvent, getVariable };
};
