//used by components in order details page
import * as orderModel from 'model/IOrder';

export const SET_ORDERDETAILS = 'SetOrderDetails';
export const setOrderDetailsAction = (orderDetails: orderModel.IOrderRequest | null) =>
({
    type: SET_ORDERDETAILS,
    orderDetails,
} as const);

export const CLEAR_ORDERDETAILS = 'clearOrderDetails';
export const clearOrderDetailsAction = () =>
({
    type: CLEAR_ORDERDETAILS
} as const);

export type OrderDetailsActions =
    ReturnType<typeof setOrderDetailsAction>
    | ReturnType<typeof clearOrderDetailsAction>;

export const orderDetailsReducer = (
    state: orderModel.IOrderRequest  | null = null,
    action: OrderDetailsActions
): orderModel.IOrderRequest | null => {
    switch (action.type) {
        case CLEAR_ORDERDETAILS: {
            return null;
        }
        case SET_ORDERDETAILS: {
            return action.orderDetails;
        }
    }
    return state;
};