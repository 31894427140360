import * as testDriveModel from 'model/ITestDrive';

export const getInitTestDrive = (): testDriveModel.ITestDrive => {
    let init: testDriveModel.ITestDrive = {
        yanaCustomerID: null,
        yanaCustomerNo: null,
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        country: {
            code: "",
            name: ""
        },
        location: {
            Country: "",
            Name: "",
            Address: "",
        },
        model: {
            ModelID: "",
            Model: ""
        },
        variant: {
            ModelID: "",
            VariantID: "",
            Variant: ""
        },
        date: undefined,
        formatDate: null,
        selectedTime: null,
        confirm: null,
        agreement: null,
        transaction: null,
        yanaTestDriveID: null,
        yanaTestDriveNo: null
    };
    return init;
}


export const getInitLocation = (): testDriveModel.ITestDriveLocation => {
    let init: testDriveModel.ITestDriveLocation = {
        Country: "",
        Name: "",
        Address: ""
    };

    return init;
}

export const getInitModel = (): testDriveModel.ITestDriveModel => {
    let init: testDriveModel.ITestDriveModel = {
        ModelID: "",
        Model: ""
    };

    return init;
}

export const getInitVariant = (): testDriveModel.TestDriveVariant => {
    let init: testDriveModel.TestDriveVariant = {
        ModelID: "",
        VariantID: "",
        Variant: ""
    };

    return init;
}


export const getInitInvalidTestDrive = (): testDriveModel.IInvalidTestDrive => {
    let init: testDriveModel.IInvalidTestDrive = {
        invalidFirstname: null,
        invalidLastname: null,
        invalidEmail: null,
        invalidMobile: null,
        invalidCountry: null,
        invalidLocation: null,
        invalidModel: null,
        invalidVariant: null,
        //invalidDate: null,
        //invalidTime: null,
        invalidConfirm: null,
    };

    return init;
}