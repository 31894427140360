import { MainLayoutPage } from 'LayoutPages';
import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import GlobalTheme from './Global/GlobalTheme';
import NewVehicleInventory from './Global/inventory/NewVehicleInventory';

interface IDialogOpen {
    isGlobalDialogOpen: boolean
}

export const GlobalPage = (props: IDialogOpen) => {

    const {isGlobalDialogOpen} = props

    return (
        
            <div className='global-container'>
                
                {/* <GlobalMenu activeBtn='configure' /> */}
                
                <GlobalTheme isGlobalDialogOpen={isGlobalDialogOpen}/>

                {/* <PaymentOptions paymentType='Cadillac Finance' ref={childRef} /> */}
            </div>
       
    );
};
