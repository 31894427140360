import { getBackendPath } from 'helpers/Uri';
import * as paymentOptionsModel from 'model/IPaymentOptions';
import * as rdHelper from 'helpers/Uri';

export const getDriveAwayCost = async (
    request: paymentOptionsModel.IDriveAwayRequest,
    abortSignal?: any,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        credentials: 'include',
        signal: abortSignal,
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/get-driveaway-cost`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getFinanceFilter = async (
    request: paymentOptionsModel.IFinanceFilterRequest,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/get-finance-filters`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getFinanceTermOptions = async (
    country: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Country: country
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/get-finance-terms`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};


export const getFinanceBallonOptions = async (
    country: string,
    termOptionID: number
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Country: country,
            TermOptionID: termOptionID
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/get-finance-balloonoptions`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const getFrequency = async (
    country: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Country: country,
        }),
        credentials: 'include',
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/get-frequency`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const calculateFinance = async (
    request: paymentOptionsModel.IFinanceRequest,
    abortSignal?: any,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        credentials: 'include',
        signal: abortSignal,
    };
    const response = await fetch(
        `${getBackendPath()}/api/PaymentOptions/calculate-finance`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};