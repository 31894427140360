export const SET_ORDERDETAILPAGEDATAVERSION = 'Set_OrderDetailPageDataVersion';
export const setOrderDetailPageDataVersionAction = () =>
({
    type: SET_ORDERDETAILPAGEDATAVERSION,
} as const);

export const Clear_ORDERDETAILPAGEDATAVERSION = 'Clear_OrderDetailPageDataVersion';
export const clearOrderDetailPageDataVersionAction = () =>
({
    type: Clear_ORDERDETAILPAGEDATAVERSION,
} as const);

type OrderDetailPageVersionActions =
    | ReturnType<typeof setOrderDetailPageDataVersionAction>
    | ReturnType<typeof clearOrderDetailPageDataVersionAction>;

export const orderDetailPageDataVersionReducer = (
    state = 0,
    action: OrderDetailPageVersionActions,
) => {
    switch (action.type) {
        case SET_ORDERDETAILPAGEDATAVERSION: {
            return state + 1;
        }
        case Clear_ORDERDETAILPAGEDATAVERSION: {
            return 0;
        }
    }

    return state;
};
