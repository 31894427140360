import { getBackendPath } from 'helpers/Uri';
import * as profileModel from 'model/IUserProfile';
import * as rdHelper from 'helpers/Uri';

export const setSession = async (
    email: string
    //yanaCustomerID: string,
    //yanaCustomerNo: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email,
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/set-session`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};

export const getSession = async (): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({}),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/get-session`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


export const clearSession = async (): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({}),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/clear-session`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


export const getProfile = async (
    yanaCustomerID: string,
    yanaCustomerNo: string
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            yanaCustomerID: yanaCustomerID,
            yanaCustomerNo: yanaCustomerNo,
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/get-profile`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};

export const saveProfile = async (
    userProfile: profileModel.IUserProfile,
    privacy: profileModel.IUserPrivacy,
    isUpdateProfile: boolean,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            userProfile: userProfile,
            privacy: privacy,
            isUpdateProfile: isUpdateProfile
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/save-profile`,
        requestOptions,
    );
    const json = await response.json();
    rdHelper.noActivityRedirectHomePage(json);
    return json;
};



//todo: delete below code
export const validateEmail = async (
    token: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            token: token,
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/validate-signup-email`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


export const logout = async (): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({}),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/log-out`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


export const sendForgotPasswordEmail = async (
    email:string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/reset-password`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};



export const validateResetPasswordToken = async (
    token: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            token: token
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/validate-reset-token`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};



export const updatePassword = async (
    email: string,
    oldPassword: string,
    newPassword: string,
): Promise<any> => {
    const requestOptions: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email,
            oldPassword: oldPassword,
            newPassword: newPassword
        }),
    };
    const response = await fetch(
        `${getBackendPath()}/api/User/update-password`,
        requestOptions,
    );
    const json = await response.json();
    return json;
};


