import { getCMSAssets } from 'helpers/Uri';
import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { isNil } from 'lodash';

import { GlobalMenu } from 'scene/Layout/GlobalMenu';
import { MainLayoutPage } from 'LayoutPages';
import ProgressSteps from '../Global/orders/purchasingProgress/ProgressSteps';

import * as orderModel from 'model/IOrder';
import * as configModel from 'model/IUserSelectedConfig';
import * as vehicleModel from 'model/IVehicle';
import * as pipelineModel from 'model/IPipeLine';
import * as paymentOptionsModel from 'model/IPaymentOptions';

import * as orderService from 'services/Order';

import * as rdUri from 'helpers/Uri';
import * as rdEnum from 'helpers/enum';
import { Footer } from 'scene/Layout/Footer';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'model/IAppState';
import { useMsal } from "@azure/msal-react";
import { useSatellite } from 'helpers/useSatellite';
import { useScreenSize } from 'helpers/useSreenSize';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';

const OrderPlacedSuccess = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const satellite = useSatellite();
    const screenSize = useScreenSize();

    const dispatch = useDispatch();

    const { yanaCustomerID, yanaCustomerNo, yanaOrderID, yanaOrderNo, rdRefNo } = useParams();

    const [order, setOrder] = useState<orderModel.IOrderRequest | null>(null);
    const [financeLink, setFinanceLink] = useState("");
    const [showContactMessage, setShowContactMessage] = useState(true);

    // const [stepsList, setStepsList] = useState<pipelineModel.IPipeLineItem[]>([]);
    const [currentStep, setCurrentStep] = useState(1);

    const [isOpen, setIsOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsOpen(true);
    }

    const onHide = () => {
        setIsOpen(false);
    };

    var currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    //The 3 attributes: stepsList, currentStep, navPage need to pass props to PurchasingProgress Component
    // const stepsList = [
    //    { step: '0', name: 'Order Placed', date: '09/09/2024' },
    //    { step: '1', name: 'Provide Your Details', date: '10/09/2024' },
    //    { step: '2', name: 'Sign Your Contract', date: '15/09/2024' },
    //    { step: '3', name: 'Order Confirmed', date: '20/09/2024' },
    // ]

    const stepsList = [
        { step: '0', name: 'Order Placed', pipelineBarStepStatus: 1, date: '' },
        { step: '1', name: 'Provide Your Details', pipelineBarStepStatus: 0, desc: "To complete your order, we'll need some additional information.", date: '' },
        { step: '2', name: 'Sign Your Contract', pipelineBarStepStatus: 2, date: '' },
        { step: '3', name: 'Order Confirmed', pipelineBarStepStatus: 2, date: '' },
    ];

    const selectedDeliveryFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });

    const doGetOrder = async () => {
        setShowContactMessage(false);
        setFinanceLink("");
        let response = await orderService.getOrderDetailsByYanaIDs(yanaCustomerID, yanaCustomerNo, yanaOrderID, yanaOrderNo);
        if (response.status) {
            let orderData = response.data;
            let orderFromAPI: orderModel.IOrderRequest = {
                Country: orderData?.orderVehicle?.country,
                State: orderData?.orderVehicle?.state,
                VariantName: orderData?.orderVehicle?.variantName,
                VariantPrice: orderData.orderVehicle.variantPrice,
                ExteriorName: orderData?.orderVehicle?.exteriorName,
                ExteriorPrice: orderData?.orderVehicle?.exteriorPrice,
                InteriorName: orderData?.orderVehicle?.interiorName,
                InteriorPrice: orderData?.orderVehicle?.interiorPrice,
                VehicleImages: orderData?.orderVehicle?.variantPicture,
                VehicleThumbnail: orderData?.orderVehicle?.vehicleThumbnail,
                Options: getOrderOptions(orderData?.orderVehicle?.options),
                DeliveryLocationState: orderData?.delivery?.deliveryLocationState,
                DeliveryLocationSuburb: orderData?.delivery?.deliveryLocationSuburb,
                DeliveryLocationPostcode: orderData?.delivery?.deliveryLocationPostcode,
                DeliveryLocationAddress: orderData?.delivery?.deliveryLocationAddress,
                DeliveryOption: orderData?.delivery?.deliveryOption,
                PickupLocationName: orderData?.delivery?.pickupLocationName,
                Available: orderData?.delivery?.available,
                PaymentOption: orderData?.paymentOption,
                DriveawayCost: {
                    LuxuryCarTax: orderData?.driveAwayCost?.luxuryCarTax,
                    LocalStampDuty: orderData?.driveAwayCost?.localStampDuty,
                    LocalRegistrationCost: orderData?.driveAwayCost?.localRegistrationCost,
                    LocalCTP: orderData?.driveAwayCost?.localCTP,
                    LocalPlateFee: orderData?.driveAwayCost?.localPlateFee,
                    HomeDelivery: orderData?.driveAwayCost?.homeDelivery,
                    DriveawayPrice: orderData?.driveAwayCost?.driveawayPrice,
                    OnRoadFee: orderData?.driveAwayCost?.onRoadFeeSubtotal,
                    SubTotal: orderData?.driveAwayCost?.subTotal,
                    VariantName: "",
                    VariantID: "",
                    VariantPrice: 0,
                    ExteriorID: "",
                    ExteriorName: "",
                    ExteriorPrice: 0,
                    InteriorID: "",
                    InteriorName: "",
                    InteriorPrice: 0,
                    GST: orderData?.driveAwayCost?.gst,
                    RoadUserChargers: orderData?.driveAwayCost?.roadUserChargers,
                    TyreStewardshipFee: orderData?.driveAwayCost?.tyreStewardshipFee,
                    Options: [],
                },
                Finance: null,
                Email: orderData?.orderCustomer?.email,
                FirstName: orderData?.orderCustomer?.firstName,
                LastName: orderData?.orderCustomer?.lastName,
                Mobile: orderData?.orderCustomer?.mobile,
                ABN: orderData?.orderCustomer?.abn,
                BusinessName: orderData?.orderCustomer?.businessName,
                CustomerType: orderData?.orderCustomer?.customerType,
                BillingAddress: orderData?.orderCustomer?.billingAddress,
                BillingSuburb: orderData?.orderCustomer?.billingSuburb,
                BillingPostcode: orderData?.orderCustomer?.billingPostCode,
                BillingState: orderData?.orderCustomer?.billingState,
                RegoAddress: orderData?.orderCustomer?.regoAddress,
                RegoSuburb: orderData?.orderCustomer?.regoSuburb,
                RegoPostcode: orderData?.orderCustomer?.regoPostCode,
                RegoState: orderData?.orderCustomer?.regoState,
                NameOnCreditCard: null,
                PrivacyStatement: true,
                RDAcountID: orderData?.rdAccountID,
                OrderID: orderData?.orderID,
                YanaOrderReservationID: null,
                YanaOrderReservationNo: null,
                YanaOrderID: orderData.yanaOrderIDs.yanaOrderID,
                YanaOrderNo: orderData.yanaOrderIDs.yanaOrderNo,
                YanaCustomerID: orderData.yanaOrderIDs.yanaCustomerID,
                YanaCustomerNo: orderData.yanaOrderIDs.yanaCustomerNo,
                RDPaymentID: null,
                FromLinkSource: null,
                PaymentHistory: null,
                DownPayment: null,
                PaymentDueDate: null,
                FinalPaymentAmount: null,
                Total: orderData.orderVehicle.totalPrice,
                Balance: orderData.currentBalance,
                PaymentAmount: null,
            };
            setOrder(orderFromAPI);

            setFinanceLink(orderData.linkToFinanceApplication);

            setCurrentStep(orderData.currentStepYanaValue);
            let pipelines: pipelineModel.IPipeLineItem[] = [];
            let pipeLineData = orderData.pipeLines;
            if (!isNil(pipeLineData) && pipeLineData.length > 0) {
                pipeLineData.map((item: any) => {
                    pipelines.push({
                        stepNo: item.pipelineNumber,
                        step: item.pipelineBarStepNumber,
                        name: item.pipelineName,
                        desc: item.pipelineDesc,
                        date: item.completeDate,
                        pipelineBarStepStatus: item.pipelineBarStepStatus,
                        buttonID: item.buttonID,
                        buttonText: item.buttonText,
                    });
                });
            }


            //let email: string = activeAccount?.idTokenClaims?.email as string;
            //rdUri.pushToDataLayer({
            //    'event': 'order complete',
            //    'landType': 'land from save',
            //    'userStatus': !isNil(email) && email != "" ? 'loggedIn' : 'loggedOut',
            //    'exteriorColour': orderFromAPI.ExteriorName,
            //    'modelVariant': orderFromAPI.VariantName,
            //    'paymentType': orderFromAPI.PaymentOption,
            //    'deliveryPostcode': orderFromAPI.DeliveryLocationPostcode
            //});


            //let pageName = "";
            //if (order?.Country == rdEnum.enumCountryCode.Australia) {
            //    pageName = "ca:oc:au:en:t1:order-success:2025-lyriq";
            //}
            //else if (order?.Country == rdEnum.enumCountryCode.NewZealand) {
            //    pageName = "ca:oc:nz:en:t1:order-success:2025-lyriq";
            //}

            //if (window.digitalData && !isNil(order?.Country) && order?.Country != "") {
            //    window.digitalData = {
            //        pageInfo: { pageName: pageName },
            //        internalCampaignInfo: {},
            //        articleInfo: {},
            //        visitorProfile: {},
            //        vehicleInfo: {},
            //        toolsInfo: {}
            //    };
            //}

        }
        else {
            setShowContactMessage(true);
        }
    }


    //const getFinance = (orderFinace: any): paymentOptionsModel.IFinance | null => {


    //}

    const getOrderOptions = (optionData: any): vehicleModel.IVehicleOptions[] | null => {
        let configs: vehicleModel.IVehicleOptions[] = [];
        optionData.map((item: any) => {
            configs.push({
                YanaOptionID: "",
                OptionType: item.optionType,
                OptionName: item.optionName,
                OptionPrice: item.optionPrice,
                OptionPicture: [],
                OptionPictureMobile: [],
                OptionDesc: item.optionDesc,
                ChargeType: item.chargeType,
                Disable: false
            });
        });
        return configs;
    }

    useEffect(() => {

        if (!isNil(yanaCustomerID) && !isNil(yanaCustomerID) && !isNil(yanaOrderID) && !isNil(yanaOrderNo)) {
            doGetOrder();
        }

    }, [yanaCustomerID, yanaCustomerNo, yanaOrderID, yanaOrderNo]);

    useEffect(() => {
        dispatch(checkIsShowScrollBar(true));
    }, []);
    useEffect(() => {
        satellite.trackEvent("config-ty");
    }, []);

    const showPrice = (price: number | null | undefined) => {
        if (price == null || price == undefined || price == 0)
            return "Included";
        return currencyFormatter.format(price);
    }

    const optionsTemplate = (options: vehicleModel.IVehicleOptions[] | null | undefined, optionType: string) => {
        let optionItemsTemplate: any[] = [];
        if (!isNil(options) && options != undefined && options.length > 0) {
            let selectedOptions = options.filter(m => m.OptionType == optionType);
            if (!isNil(selectedOptions) && selectedOptions.length > 0) {
                selectedOptions.map((optionItem: vehicleModel.IVehicleOptions, index: any) => {
                    optionItemsTemplate.push(
                        <div className='details-desc' key={optionType + "_" + index}>
                            <p>{optionItem?.OptionName}</p>
                            <span>{showPrice(optionItem?.OptionPrice)}</span>
                        </div>
                    );
                })
            }
        }

        let template: any[] = [];
        if (optionItemsTemplate.length == 0) {
            template.push(<></>);
        }
        else {
            template.push(
                <div className='details-item'>
                    <div className='details-subtitle'>{optionType == rdEnum.enumVehicleOptionType.Charge ? rdEnum.enumVehicleOptionTypeName.Charge : rdEnum.enumVehicleOptionTypeName.Accessories}</div>
                    {optionItemsTemplate}
                </div>
            );
        }
        return template;
    }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        // Function to update the state with current window size
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup to avoid memory leaks
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setWindowDigitalData();
    }, [order, windowSize]);

    const setWindowDigitalData = () => {

        let pageName = "";
        if (order?.Country == rdEnum.enumCountryCode.Australia) {
            pageName = "ca:oc:au:en:t1:lyriq-configurator:2025-lyriq:ordersuccess";
        }
        else if (order?.Country == rdEnum.enumCountryCode.Australia) {
            pageName = "ca:oc:nz:en:t1:lyriq-configurator:2025-lyriq:ordersuccess";
        }




        let email: string = activeAccount?.idTokenClaims?.email as string;
        window.digitalData = {
            pageInfo: {
                pageName: pageName,
                siteSectionsLevel1: "lyriq-configurator",
                siteSectionsLevel2: "2025-lyriq",
                siteSectionsLevel3: "ordersuccess",
                siteSectionsLevel4: "undefined",
                siteSectionsLevel5: "lyriq-configurator:2025-lyriq:ordersuccess",
                universalID: "AB52350",
                url: window.location.href,
                seoStrategyPageName: "Shop Now | Cadillac",
                pageType: "tool",
                languageSelected: "english",
                siteName: "quantum_au",
                brand: "Cadillac",
                country: selectedDeliveryFromStore.Country?.code,
                region: "oc",
                renderedExperience: screenSize,
                viewport: window.innerWidth + 'x' + window.innerHeight,
                orientation: window.innerWidth > window.innerHeight ? "landscape" : "portrait"
            },
            internalCampaignInfo: {},
            articleInfo: {},
            visitorProfile: {
                userProfileStateAuthentication: !isNil(email) && email != "" ? 'loggedIn' : 'loggedOut',
                userLoginStart: !isNil(email) && email != "" ? true : false,
                configProducts: null,
                configExtColor: "",
                configIntColorUpholstery: "",
                configModelPackageVersion: "",
                engine: "",
                transmission: ""
            },
            vehicleInfo: {
                vehicleType: "suv",
                modelName: "lyriq",
                modelYear: "2025",
                bodyStyle: "lyriq",
                trimLevel: order?.VariantName,
                condition: "new",
                vin: "",
            },
            toolsInfo: {
                configProducts: order?.VariantName,
                configExtColor: order?.ExteriorName,
                configIntColorUpholstery: order?.InteriorName || "",
                configModelPackageVersion: "",
                engine: "",
                transmission: "",
                wheels: "AWD",
                options: "",
                dealerName: "",
                dealerId: "",
                individualProductPrice: "",
                configuratorStartPrice: order?.DriveawayCost?.DriveawayPrice,
                configuratorEndPrice: order?.DriveawayCost?.DriveawayPrice,
            }
        };

    }


    if (showContactMessage) {
        return (
            <MainLayoutPage sceneId='container-has-header'>
                <GlobalMenu />
                <div className='purchasing-procedure-container'>
                    {/*<div className='successful-header' style={{ aspectRatio: '1440/500' }}></div>*/}
                    <div className='success-contact-title'>
                        <h1>Please contact cadillac</h1>
                        <h1>Concierge on</h1>
                        <h1>1800 cadillac (223455)</h1>
                        <p>Your Reference number is {rdRefNo}</p>
                    </div>

                </div>
                <Footer />
            </MainLayoutPage>
        );

    }
    else {
        return (
            <MainLayoutPage sceneId='container-has-header'>
                <GlobalMenu />

                <div className='purchasing-procedure-container'>
                    <div className='successful-header' style={{ aspectRatio: '1440/500' }}>

                        <div className='success-title'>
                            <h1>THANK YOU</h1>
                            <h1>{order?.FirstName}</h1>
                        </div>
                        <div className='success-info'>
                            <h4>Order ID: {order?.YanaOrderNo}</h4>
                            <p>
                                Your order has been placed successfully, and a confirmation email has been sent.
                                <br/>
                                This can take up to 15 minutes to arrive in your inbox.
                            </p>
                            <br />
                            <p>PLEASE CHECK YOUR EMAIL</p>
                        </div>
                        {/* <div className='overlap-panel'></div> */}

                        {/* <img alt="" className='purchased-vehicle order-placed' src={`${getCMSAssets()}/home/Luxury-Argent Silver Metallic.png`} /> */}
                        <div className='purchased-vehicle order-placed'>
                            <img alt="" className='' style={{ zIndex: 99 }}
                                src={order?.VehicleThumbnail || ""} />

                            {financeLink != "" && (
                                <div className='variant-btn-panel'>
                                    <Button className='variant-btn'
                                        onClick={() => handleDialogOpen()
                                            // if (!isNil(financeStatus.FinanceApplicationStepLink) && financeStatus.FinanceApplicationStepLink != '') {
                                            //     window.open(financeStatus.FinanceApplicationStepLink)
                                            // }
                                        }
                                    >
                                        {/* {financeStatus?.FinanceApplicationStepName} */}
                                        BEGIN FINANCE APPLICATION
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='success-steps'>
                            {/* <img alt="" className='purchased-vehicle order-placed' src={`${getCMSAssets()}/success&waitlist/RDtosupply.png`} /> */}
                            <div className='success-steps-title'>
                                <h4>WHAT'S NEXT?</h4>
                                <p>Keep your eye on your emails and order tracker for future updates.</p>
                            </div>
                            <ProgressSteps progressList={stepsList} isSuccessWaitListedPage={true} />
                            {/* <div className='variant-btn-panel'>
                            <Button className='variant-btn'
                                // onClick={(e) =>  handleChangeType(e)}
                                data-pr-tooltip="Luxury"
                            >
                                BEGIN YOUR FINANCE APPLICATION
                            </Button>
                        </div> */}

                        </div>

                        {/* {financeLink != "" && (
                            <a className="success-finance-wrapper" target="_blank" href={financeLink || ""}>
                                BEGIN YOUR FINANCE APPLICATION
                            </a>
                        )} */}
                        <div className='black-bg-panel'></div>
                    </div>

                    <div className='procedure-container'>
                        <div className='procedure-title'>
                            YOUR ORDER
                    </div>
                        <div className='procedure-subtitle'>
                            Here’s a short summary of your LYRIQ selections.
                    </div>
                        {/* <div className='procedure-map'>
                        Here’s a short summary of your LYRIQ selections.
                    </div> */}
                    </div>

                    {/* <div className='procedure-container'>
                    <div className='procedure-title'>
                        NEXT STEPS
                    </div>
                    <div className='procedure-subtitle'>
                        Here’s a short summary of your LYRIQ order and next steps:
                    </div>
                    <div className='procedure-map'>
                        
                    </div>
                </div> */}

                    {/* <PurchasingProgress progressStage={stepsList} step={currentStep} navPage={navPage} /> */}

                    <div className='purchasing-details'>
                        <div className='selection-panel'>
                            <div className='section-title'>
                                YOUR SELECTION
                        </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Variant</div>
                                <div className='details-desc'>
                                    <p>{order?.VariantName}</p>
                                    <span>{showPrice(order?.VariantPrice)}</span>
                                </div>
                            </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Exterior</div>
                                <div className='details-desc'>
                                    <p>{order?.ExteriorName}</p>
                                    <span>{showPrice(order?.ExteriorPrice)}</span>
                                </div>
                            </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Interior</div>
                                <div className='details-desc'>
                                    <p>{order?.InteriorName}</p>
                                    <span>{showPrice(order?.InteriorPrice)}</span>
                                </div>
                            </div>

                            {optionsTemplate(order?.Options, rdEnum.enumVehicleOptionType.Accessories)}
                            {optionsTemplate(order?.Options, rdEnum.enumVehicleOptionType.Charge)}
                        </div>


                        <div className='finance-panel'>
                            <div className='section-title'>
                                FINANCE
                        </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Customer type</div>
                                <div className='details-desc'>
                                    <p>{order?.CustomerType}</p>
                                </div>
                            </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Payment type</div>
                                <div className='details-desc'>
                                    <p>{order?.PaymentOption}</p>
                                </div>
                            </div>

                            <div className='details-item'>
                                <div className='details-desc' style={{ fontSize: '14px' }}>
                                    You can view your full order summary and more from within your account.
                                </div>
                            </div>
                        </div>


                        <div className='delivery-panel'>
                            <div className='section-title'>
                                {order?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery ? "DELIVERY" : "IN-STORE COLLECTION"}
                            </div>


                            <div className='details-item'>
                                <div className='details-subtitle'>Location</div>
                                <div className='details-desc'>
                                    <p>{order?.DeliveryLocationAddress}</p>
                                </div>
                            </div>

                            <div className='details-item'>
                                <div className='details-subtitle'>Preferred Option</div>
                                <div className='details-desc'>
                                    <p>{order?.DeliveryOption}</p>
                                    {!isNil(order?.DriveawayCost?.HomeDelivery) && order?.DeliveryOption == rdEnum.enumDeliveryOptions.HomeDelivery && (<p>{rdUri.currencyFormat(order?.DriveawayCost?.HomeDelivery || 0)}</p>)}
                                </div>
                            </div>


                            <div className='details-item'>
                                <div className='details-subtitle'>Estimated date</div>
                                <div className='details-desc'>
                                    <p>{order?.Available}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {financeLink != "" && (
                    <div className='success-disclaimer-container'>
                        <p>
                            {/* <i> */}
                            Credit subject to financier's approval. Terms, conditions, fees and charges apply. Rates, repayments and fees are estimates and are subject to change at any time. Credit provided by Plenti Finance Pty Limited ACN 636 759 861 (supported by its servicer, Plenti RE Limited ACN 166 646 635). Plenti RE Limited holds Australian Financial Services Licence number 449176 and Australian Credit Licence number 449176.
                            {/* </i> */}
                        </p>
                    </div>
                )}
               
                <Dialog header='NOTICE' className='finance-application-dialog' position='bottom' visible={isOpen} draggable={false} resizable={true} onHide={() => onHide()}>
                    <div className='title-panel'>
                        {
                            order?.Country == rdEnum.enumCountryCode.Australia
                            ?
                            <p>To commence your application. we'll be transferring you to our finance partner, Plenti. This next page is managed by Plenti.</p>
                            :
                            <p>To commence your application, we'll be transferring you to our finance partner, MTF. This page is managed by MTF.</p>
                        }
                    </div>
                    <div className='variant-btn-panel'>
                        <Button className='variant-btn'
                            onClick={() => {
                                if (!isNil(financeLink) && financeLink != '') {
                                    window.open(financeLink)
                                }
                            }}
                        >
                            CONTINUE
                        </Button>
                    </div>
                </Dialog>

                <Footer />
            </MainLayoutPage>
        )
    }
}

export default OrderPlacedSuccess