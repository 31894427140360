import React, {
    createRef,
    useRef,
    useLayoutEffect,
    useState,
    useContext,
    createContext,
    useEffect
} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { MainLayoutPage } from 'LayoutPages';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';

import {
    getFrontendUrl,
    getCMSAssets,
} from 'helpers/Uri';
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { MainMenu } from 'scene/Layout/MainMenu';

import { classNames } from 'primereact/utils';
import { isNil } from 'lodash';
import { Footer } from 'scene/Layout/Footer';
import { MaintenanceMessage } from 'scene/Layout/MaintenanceMessage';
/*import { useForm } from 'react-hook-form';*/
import { useTranslation } from "react-i18next";


import { validateEmail } from 'services/User';
import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';

interface SignInState {
    verifyCode: string,
    invalidCode: boolean | null,
}

export const EmailVerficationPage = () => {
    const gaEventTracker = useAnalyticsEventTracker('Email Verfication');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { token } = useParams();

    const [errorMessage, setErrorMessage] = useState("");

    const initPageState: SignInState = {
        verifyCode: "",
        invalidCode: false,
    }

    const [pageState, setPageState] = useState({
        verifyCode: "",
        invalidCode: false,
    })


    useEffect(() => {

        const doValidateEmail = async () => {
            let request = await validateEmail(token);

            if (request.status) {
                navigate(`${getFrontendUrl()}/sign-in`);
            }
            else {
                setErrorMessage(request.message);
            }
        }
        if (!isNil(token) && token != "") {
            doValidateEmail();
        }
    }, [token]);



    const handleGoBack = () => {
        navigate(`${getFrontendUrl()}/sign-in`);
    }

    const handleGoBackForgot = () => {
        navigate(`${getFrontendUrl()}/forgot-password`);
    }

    const handleSubmit = () => {
        navigate(`${getFrontendUrl()}/reset-password`);
    }

    return (
        <>

            <div className="page-container-fullsize password-issue-container">
                <div className='user-left-panel'>
                    <img alt="" className='image' src={`${getCMSAssets()}/users/sign-in-up.png`} />
                </div>
                <div className='user-right-panel' >
                    <div className='right-inner-container'>
                        <div className='logo-title-container'>
                            <img alt="" className='logo-image' onClick={() => handleGoBack()} src={`${getCMSAssets()}/users/Cadillac-Logo.svg`} />
                            <h5 className='sign-in-title'>Already have an account? <a className='sign-in-link' onClick={() => handleGoBack()}>Sign in</a></h5>
                        </div>

                        <div className='handle-password-container'>
                            <div className='handle-password-title'>
                                WE'VE EMAILED YOU A CODE
                                </div>
                            <div className='handle-password-subtitle'>
                                We've sent an email with a verification code to . To keep your account safe, this verification code expires in 30 minutes.
                                </div>
                            <div className='input-item'>
                                <span className="wrapper-box">
                                    <InputText id="email" aria-describedby="email-help"
                                        className={classNames({ "p-invalid": pageState?.invalidCode })}
                                        placeholder=" "
                                        value={pageState.verifyCode}
                                        onChange={(e) => {
                                            setPageState({
                                                ...pageState,
                                                verifyCode: e.target.value,
                                                invalidCode: isNil(e.target.value) || e.target.value == ""
                                            })
                                        }}
                                    />
                                    <label>Verification Code</label>
                                </span>
                                {/*{pageState?.invalidCode && (*/}
                                {/*    <small id="email-help" className="p-error block">{t(`${languageState.LanguageScreenName}:required_email`)}</small>*/}
                                {/*)}*/}
                            </div>
                        </div>

                        <div className='handle-password-btn-panel'>

                            <Button onClick={() => handleSubmit()}><span>SUBMIT</span></Button>

                            <Button onClick={() => handleGoBackForgot()}><span>RESEND EMAIL</span></Button>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}