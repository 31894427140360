import { getCMSAssets } from 'helpers/Uri'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import CarCarouselDialog, { CarouselDialogRef } from './CarCarouselDialog'
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { IAppState } from 'model/IAppState';
import { classNames } from 'primereact/utils'

const CarCarousel = (props: any) => {

    const { variantAWD, exteriorColor, interiorColor, carouselInterval, images, mobileImages, isUseImages } = props;

    const isMobile = useCheckMobileScreen();


    const [vehicle, setVehicle] = useState({
        variantAWD: "",
        exteriorColor: "",
        interiorColor: ""
    });

    useEffect(() => {
        setVehicle({
            variantAWD: variantAWD,
            exteriorColor: exteriorColor,
            interiorColor: interiorColor
        })
    }, [])

    const vehicleFromStore = useSelector((state: IAppState) => {
        return state.vehicle;
    });

    const getVariantImages = (index: number) => {
        return vehicleFromStore?.SelectedVariant?.VariantPicture != null && vehicleFromStore?.SelectedVariant?.VariantPicture.length > index
            ? vehicleFromStore?.SelectedVariant?.VariantPicture[index] : "";
    }
    const getExteriorImages = (index: number) => {
        return vehicleFromStore?.SelectedExterior?.ExteriorPicture != null && vehicleFromStore?.SelectedExterior?.ExteriorPicture.length > index
            ? vehicleFromStore?.SelectedExterior?.ExteriorPicture[index] : "";
    }


    const getInteriorImages = (index: number) => {
        //if (isMobile) {
        //    return vehicleFromStore?.SelectedInterior?.InteriorPictureMobile != null && vehicleFromStore?.SelectedInterior?.InteriorPictureMobile.length > index
        //        ? vehicleFromStore?.SelectedInterior?.InteriorPictureMobile[index] : "";
        //}
        //else {
        return vehicleFromStore?.SelectedInterior?.InteriorPicture != null && vehicleFromStore?.SelectedInterior?.InteriorPicture.length > index
            ? vehicleFromStore?.SelectedInterior?.InteriorPicture[index] : "";
        //}
    }


    const carouselDialogRef = useRef<CarouselDialogRef | null>(null);

    const directionButtons = (direction: any) => {
        return (
            <span
                aria-hidden="true"
                className={direction === "Next" ? "button-next" : "button-prev"}
            >
                <img className={classNames({
                    "image": true,
                    "stat-arrow-next": true
                })}
                    data-dtm="gallery"
                    src={`${getCMSAssets()}/main/${direction}-round.svg`}
                    alt={direction === "Next" ? "next arrow" : "previous arrow"} />
            </span>
        );
    };

    const handleOpen = (pageNumber: number) => {
        if (carouselDialogRef.current) {
            carouselDialogRef.current.handleOpen(pageNumber);


        }
    };


    setTimeout(() => {
        let indicators = document.getElementsByClassName("carousel-indicators");
        for (let i = 0; i < indicators.length; i++) {
            indicators[i].setAttribute('data-dtm', 'gallery');
            indicators[i].classList.add('stat-dots');
        }
    }, 200);


    if (isUseImages) {
        if (!isMobile && !isNil(images) && images.length > 0) {
            return (
                <>
                    <Carousel
                        nextIcon={directionButtons("Next")}
                        prevIcon={directionButtons("Prev")}
                        interval={carouselInterval}
                    >
                        {images.map((item: any, index: number) => {
                            return (
                                <Carousel.Item onClick={() => handleOpen(index)} key={item + index}>
                                    <img className="image" src={item} />
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </>
            );
        }
        else if (isMobile && !isNil(mobileImages) && mobileImages.length > 0) {
            return (
                <>
                    <Carousel
                        nextIcon={directionButtons("Next")}
                        prevIcon={directionButtons("Prev")}
                        interval={carouselInterval}
                    >
                        {mobileImages.map((item: any, index: number) => {
                            return (
                                <Carousel.Item onClick={() => handleOpen(index)} key={item + index}>
                                    <img className="image" src={item} />
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </>
            );

        }
        else {
            return (<></>);
        }
    }
    return (
        <>
            {/*todo: sample data. will remove it later*/}
            <Carousel
                nextIcon={directionButtons("Next")}
                prevIcon={directionButtons("Prev")}
                interval={carouselInterval}
            >
                <Carousel.Item
                // onClick={() => handleOpen(0)}
                >
                    <img className="image" src={getExteriorImages(0)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(1)}
                >
                    <img className="image" src={getExteriorImages(1)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(2)}
                >
                    <img className="image" src={getExteriorImages(2)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(3)}
                >
                    <img className="image" src={getExteriorImages(3)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(4)}
                >
                    <img className="image" src={getInteriorImages(0)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(5)}
                >
                    <img className="image" src={getInteriorImages(1)} />
                </Carousel.Item>
                <Carousel.Item
                // onClick={() => handleOpen(6)}
                >
                    <img className="image" src={getInteriorImages(2)} />
                </Carousel.Item>
            </Carousel>

            <CarCarouselDialog slideNumber={0} vehicle={vehicle} ref={carouselDialogRef} />
        </>
    )
}

export default CarCarousel