import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Navbar, Accordion } from 'react-bootstrap';
import { RiHomeLine } from 'react-icons/ri';

import {
    getFrontendUrl,
    getImageFileNameByID2,
    IImagesState,
    getImageLinkByID2,
    getMobileImageLinkByID2,
    getCMSAssets,
    getServiceUrlPara
} from 'helpers/Uri';

import { clearSessionAction } from "stores/Session";
import { IAppState } from 'model/IAppState';
import { enumOrdersTabName } from "helpers/enum";
import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { isNil } from 'lodash';
import { AiOutlineSearch, AiOutlineLogin, AiOutlineUser } from "react-icons/ai";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { logout } from 'services/User';
import * as orderService from 'services/Order';

//import { useAnalyticsEventTracker } from 'helpers/useAnalyticsEventTracker';
import { Menu } from 'primereact/menu';
import { UserPopUp } from 'scene/Users/UserPopUp';
import { setScrollPosition } from 'stores/ScrollPosition';
import { GlobalDrawer } from './GlobalDrawer';
import GlobalSelectVehicle from './GlobalSelectVehicle';
import { setGlobalDropdown } from 'stores/GlobalDropdown';
import GlobalShop from './GlobalShop';
import { checkIsShowScrollBar } from 'stores/ScrollBarDisplay';
import { useCheckTabletScreen } from 'helpers/useCheckTabletScreen';


export const GlobalMenu = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { activeBtn } = props;
    const [activeButton, setActiveButton] = useState('');
    const [isHasOrder, setIsHasOrder] = useState(false);
    const [isClickable, setIsClickable] = useState(true);
    const [shopDivHeight, setShopDivHeight] = useState(0);

    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabletScreen();

    const menuRef = useRef<any>(null);
    const vehicleRef = useRef<any>(null);
    const shopRef = useRef<any>(null);

    useEffect(() => {
        let timeoutId: number | undefined;;

        if (activeBtn != 'configure') {
            setIsClickable(true);
        } else {
            setIsClickable(false);
            // Re-enable clicks after 2.3 seconds
            timeoutId = window.setTimeout(() => {
                setIsClickable(true);
            }, 2500);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [activeBtn]);

    //Calculate the shop div height to set its top value
    useEffect(() => {
        if (shopRef.current) {
            setShopDivHeight(shopRef.current.offsetHeight);
        }
    }, [isMobile]);

    const menuGlobalBtn = useSelector((state: IAppState) => {
        return state.globalDropdown
    })

    //const gaEventTracker = useAnalyticsEventTracker('Menu');

    const loginSession = useSelector((state: IAppState) => {
        return state.session;
    });

    const sessionFromStore = useSelector((state: IAppState) => {
        return state.session;
    });

    useEffect(() => {
        setIsHasOrder(false);
        if (!isNil(sessionFromStore?.YanaCustomerID) && sessionFromStore?.YanaCustomerID != ""
            && !isNil(sessionFromStore?.YanaCustomerNo) && sessionFromStore?.YanaCustomerNo != "") {
            doGetMyOrders(sessionFromStore?.YanaCustomerID || "",
                sessionFromStore?.YanaCustomerNo || "");
        }

    }, [sessionFromStore]);

    const doGetMyOrders = async (yanaCustomerID: string, yanaCustomerNo: string) => {
        let response = await orderService.getOrderByYanaCustomerID(yanaCustomerID, yanaCustomerNo);
        if (response.status) {
            if (!isNil(response.data) && response.data.length > 0) {
                setIsHasOrder(true);
            }
        }
        else {
            setIsHasOrder(false);
        }
    }

    const scrollPosition = useSelector((state: IAppState) => {
        return state.scrollPosition;
    });

    const checkMainSection = useSelector((state: IAppState) => {
        return state.mainSection
    });

    useEffect(() => {
        setActiveButton(activeBtn);
    }, [activeBtn]);

    const onChangeActiveBtn = (activeBtnValue: string) => {
        setActiveButton(activeBtnValue);
        dispatch(setGlobalDropdown(false, false))
    }

    //Except click outside Shop component and Menu & Drawer components, Shop panel will hide the collapse
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if ((menuGlobalBtn.shop == true && shopRef.current && !shopRef.current.contains(event.target))
                && menuRef.current && !menuRef.current.contains(event.target)
            ) {
                onChangeActiveBtn('');
                dispatch(checkIsShowScrollBar(true))
            }

            if ((menuGlobalBtn.vehicles == true && vehicleRef.current && !vehicleRef.current.contains(event.target))
                && menuRef.current && !menuRef.current.contains(event.target)
            ) {
                onChangeActiveBtn('');
                dispatch(checkIsShowScrollBar(true))
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onChangeActiveBtn]);

    return (
        <>
            <div className="global-menu-container" ref={menuRef} style={{
                position: "sticky"
                // activeBtn != 'configure' ? 'fixed' : 'sticky'
                , width: '100%', backgroundColor: '#FCFCFC'
            }}>

                <div className='global-menu-panel' style={{ backgroundColor: '#FCFCFC' }}>
                    <Row className="global-menu page-container-content ">
                        {
                            !isMobile && !isTablet
                                ?
                                <Col sm={12} className="menu-items" style={{ backgroundColor: '#FCFCFC' }}>
                                    <div className='side-container'>
                                        {/* <img src={`${getCMSAssets()}/menu/hamburger.svg`}  /> */}
                                        <GlobalDrawer isClickable={isClickable} />
                                        {/* <button className={`certainty-panel ${activeButton === 'lyriq' ? 'active' : ''}`} 
                                        onClick={() => {
                                            if (isClickable) setActiveButton('lyriq')
                                        }
                                    }>LYRIQ</button>
                                    <button className={`certainty-panel ${activeButton === 'configure' ? 'active' : ''}`} 
                                        onClick={() => {
                                            setActiveButton('configure');
                                            navigate(`/${getFrontendUrlWithSlash()}`, { state: { closePreLoader: false, isFromOtherPage: false  } });
                                        }}
                                    >CONFIGURE</button>
                                    <button className={`certainty-panel ${activeButton === 'test' ? 'active' : ''}`} 
                                        onClick={() => {
                                            if (isClickable) {
                                                setActiveButton('test');
                                                navigate(`/${getFrontendVirtualDir()}/test-drive`)
                                            }
                                        }
                                    }>TEST DRIVE</button>
                                    <button className={`certainty-panel ${activeButton === 'inventory' ? 'active' : ''}`} 
                                        onClick={() => {
                                            if (isClickable && loginSession != null && isHasOrder) {
                                            
                                                navigate(`/${getFrontendVirtualDir()}/inventory`)
                                            } else if (isClickable) {
                                                setActiveButton('inventory');
                                                navigate(`/${getFrontendVirtualDir()}/new-vehicle-inventory`)
                                            }
                                        }
                                    }>INVENTORY</button> */}

                                        <button className={`certainty-panel stat-text-link global-dropdown ${activeButton === 'vehicles' ? 'active' : ''}`}
                                            data-dtm="global nav"
                                            onClick={() => {
                                                if (isClickable) {
                                                    if (activeButton == 'vehicles') {
                                                        setActiveButton('');
                                                        dispatch(setGlobalDropdown(false, false));
                                                        dispatch(checkIsShowScrollBar(true));
                                                    } else {
                                                        setActiveButton('vehicles');
                                                        dispatch(setGlobalDropdown(true, false));
                                                        dispatch(checkIsShowScrollBar(false));
                                                    }
                                                }
                                            }
                                            }>VEHICLES</button>
                                        <button className={`certainty-panel stat-text-link global-dropdown ${activeButton === 'shop' ? 'active' : ''}`}
                                            data-dtm="global nav"
                                            onClick={() => {
                                                if (isClickable) {
                                                    if (activeButton == 'shop') {
                                                        setActiveButton('');
                                                        dispatch(setGlobalDropdown(false, false));
                                                        dispatch(checkIsShowScrollBar(true));
                                                    } else {
                                                        setActiveButton('shop');
                                                        dispatch(setGlobalDropdown(false, true));
                                                        dispatch(checkIsShowScrollBar(false));
                                                    }
                                                }
                                                // navigate(`/${getFrontendVirtualDir()}`, { state: { closePreLoader: false } });
                                            }}
                                        >SHOP</button>
                                    </div>

                                    <div
                                        // state={{ closePreLoader: true, isFromOtherPage: true  }}
                                        className="menu-logo stat-logo"
                                        data-dtm="global nav"
                                        onClick={() => {
                                            dispatch(setScrollPosition('global'));
                                            // navigate(`/`, { state: { closePreLoader: false, isFromOtherPage: true  } });
                                            window.open('https://www.cadillacanz.com/au-en');
                                        }}
                                    >
                                        <img src={`${getCMSAssets()}/menu/Cadillac-Logo.svg`} alt='cadillac logo'></img>
                                    </div>

                                    <UserPopUp isClickable={isClickable} />

                                </Col>
                                :
                                <>
                                    <Col sm={12} className="menu-items">

                                        <GlobalDrawer isClickable={isClickable} />

                                        <div
                                            // state={{ closePreLoader: true, isFromOtherPage: true  }}
                                            data-dtm="global nav"
                                            className="stat-logo menu-logo"
                                            onClick={() => {
                                                dispatch(setScrollPosition('global'));
                                                window.open('https://www.cadillacanz.com/au-en');
                                            }}
                                        >
                                            <img src={`${getCMSAssets()}/menu/Cadillac-Logo.svg`} alt="cadillac logo"></img>
                                        </div>

                                        <UserPopUp isClickable={isClickable} />



                                    </Col>
                                    <div className='side-container'>
                                        <button className={`certainty-panel stat-text-link global-dropdown ${activeButton === 'vehicles' ? 'active' : ''}`}
                                            data-dtm="global nav"
                                            onClick={() => {
                                                if (isClickable) {
                                                    if (activeButton == 'vehicles') {
                                                        setActiveButton('')
                                                        dispatch(setGlobalDropdown(false, false))
                                                    } else {
                                                        setActiveButton('vehicles');
                                                        dispatch(setGlobalDropdown(true, false))
                                                    }
                                                }
                                            }
                                            }>VEHICLES</button>
                                        <button className={`certainty-panel stat-text-link global-dropdown ${activeButton === 'shop' ? 'active' : ''}`}
                                            data-dtm="global nav"
                                            onClick={() => {
                                                if (isClickable) {
                                                    if (activeButton == 'shop') {
                                                        setActiveButton('')
                                                        dispatch(setGlobalDropdown(false, false))
                                                    } else {
                                                        setActiveButton('shop');
                                                        dispatch(setGlobalDropdown(false, true))
                                                    }
                                                }
                                                // navigate(`/${getFrontendVirtualDir()}`, { state: { closePreLoader: false } });
                                            }}
                                        >SHOP</button>
                                    </div>
                                </>
                        }

                    </Row>
                </div>

            </div>

            <div ref={vehicleRef} className={menuGlobalBtn.vehicles == false ? 'global-select-vehicle' : 'global-select-vehicle active'}
                style={{ width: menuGlobalBtn.vehicles == false ?  `calc(100% - 15px)` : '100%', display: menuGlobalBtn.vehicles == false ? '0' : '1'}}
            >
                <GlobalSelectVehicle onChangeActiveBtn={onChangeActiveBtn} />
            </div>

            <div ref={shopRef} className={menuGlobalBtn.shop == false ? 'global-shop' : 'global-shop active'}
                style={{top: isTablet? `calc(-${shopDivHeight}px + 140px)` : `calc(-${shopDivHeight}px + 106px)`, 
                        width: menuGlobalBtn.shop == false ?  `calc(100% - 15px)` : '100%',
                        opacity: menuGlobalBtn.shop == false ? '0' : '1'
                }}
            >
                <GlobalShop />
            </div>
           
        </>
    );
};